import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, SimpleChanges } from '@angular/core';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { PartAndPrice } from '@models/PartAndPrice';
import { DataModel, FMResponseModel, PartsFieldDataModel } from '@models/api-models/FMResponseModel';
import { OrderItemModel } from '@models/order-item';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import { BrandModeService } from '@services/brandmode.service';
import { ConfigurationService } from '@services/configuration.service';
import { DataApiService } from '@services/data-api.service';
import { EnvService } from '@services/env.service';
import { EventsService } from '@services/events.service';
import { ImageService } from '@services/image.service';
import { OrderItemService } from '@services/order-item.service';
import { StateService } from '@services/state.service';
import { AppState } from '@shared/app-state';
import { BaseCustomizerComponent } from '@shared/base-customizer-component';
import { firstValueFrom } from 'rxjs';

@Component({
	selector: 'app-parts',
	templateUrl: './parts-menu-item.html',
	styleUrls: ['./parts-menu-item.scss']
})
export class PartsMenuItemComponent extends BaseCustomizerComponent {

	parts: DataModel<PartsFieldDataModel>[];

	partsAndPrices: PartAndPrice[] = [];


	loading: boolean;
	partsState: any;

	public selectedParts: string[] = [];

	showPrices: boolean;
	loadingPrice: boolean;
	pricesLoaded: boolean;
	isPrecious: boolean = false;

	constructor(
		public dataApiService: DataApiService,
		public imageService: ImageService,
		private brandModeService: BrandModeService,
		private env: EnvService,
		public eventsService: EventsService,
		private http: HttpClient,
		private stateService: StateService,
		private orderItemService: OrderItemService,
		public configurationService: ConfigurationService,
		private appState: AppState
	) {
		super(eventsService, dataApiService, configurationService);
	}

	async initData() {
		this.loading = true;

		this.partsState = this.stateService.state$;
		this.isPrecious = this.brandModeService.isPrecious;
		if (this.partsState) {
			this.selectedParts = this.partsState.value;
		}

		let request = { 'productLine': this.modelGroup.ProductLineCode, 'modelName': this.model.Code };

		let response: FMResponseModel<PartsFieldDataModel> = await firstValueFrom(
			this.http.post<FMResponseModel<PartsFieldDataModel>>(
				(await this.env.DataApiurl()) + '/parts/productmodelparts',
				request,
				{ headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8') }
			)
		);

		if (response && response.Data) {
			this.parts = response.Data.sort((a, b) => a.FieldData.Sort - b.FieldData.Sort);

			this.partsAndPrices = response.Data.sort((a, b) => a.FieldData.Sort - b.FieldData.Sort).map(p => new PartAndPrice(p.FieldData.PartType, p.FieldData.Engravable === 1));
		}

		this.loading = false;

		let x = this.appState.priceSetting.getValue();
		this.showPrices = x.PriceSetting === PriceSettingEnum.AllowSuggestedRetailPrice;

		if (this.showPrices) {
			this.getPrice();
		}
	}

	onChanges(changes: SimpleChanges): void {
	}

	isChecked(part: string) {
		if (this.selectedParts && this.selectedParts.includes) {
			return this.selectedParts.includes(part);
		}
	}

	togglePart(part: any) {
		let result: string[] = null;

		if (!this.selectedParts) {
			this.selectedParts = [];
		}

		let existing = this.selectedParts.findIndex(p => p === part);

		// if the selected part is in the list, slice it out
		if (existing > -1) {
			result = this.selectedParts.slice(0, existing).concat(this.selectedParts.slice(existing + 1));
		}
		// else add it
		else {
			this.selectedParts.push(part);
			result = this.selectedParts;
		}

		this.selectedParts = result;

		this.stateService.state$.next(this.selectedParts);

		this.updateParts(this.selectedParts);
	}

	// load prices for all parts
	public async getPrice() {
		this.pricesLoaded = false;

		if (this.parts) {
			this.loadingPrice = true;

			const orderItem: OrderItemModel = OrderItemModel.MapFromVariants(
				null,
				-1,
				false,
				1,
				'',
				'',
				'',
				this.configuration,
				this.modelGroup,
				this.model,
				this.modelSize,
				this.variants,
				this.hasFixtures,
				this.hasForms,
				false,
				this.discount?.key,
				this.discount?.percentage,
				TempleInclinationEnum.standard,
				this.brandModeService.isPrecious,
				this.age,
				this.gender,
				this.firstPair,
				null
			);

			orderItem.Parts = this.parts.map(p => p.FieldData.PartType).join(';');

			let priceResponse = await this.orderItemService.getPriceFromConfiguration(orderItem);

			if (priceResponse && priceResponse.Data && priceResponse.Data.ResponseItems) {
				for (let response of priceResponse.Data.ResponseItems) {
					let pnp = this.partsAndPrices.find(p => p.name === response.Part)

					if (pnp) {
						pnp.priceItem = response;
					}
				}

				this.pricesLoaded = true;
			}
		}

		this.loadingPrice = false;
	}

}
