import { AllAssortmentsModel } from '@models/all-assortments-model.interface';
import { AssortmentLineModel } from '@models/assortment-line-model';
import { AssortmentModel } from '@models/assortment-model';
import { Type } from 'class-transformer';
import { AvailabilityModel } from "../availability-model";

export class ConfigurationOrderApimodel extends AvailabilityModel implements AllAssortmentsModel {
    Id: number;
    Uuid: string;
    Release: Date;
    Code: string;
    ModelConfigId: number;

    @Type(() => AssortmentLineModel)
    public AssortmentLines: Array<AssortmentLineModel>;

    public get AllAssortments(): Array<AssortmentModel> {
        return this.AssortmentLines.map(x => x.Assortment).distinct((x,y) => x.Code === y.Code);
    }
}
