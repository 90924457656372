import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { ComponentVariantGroup } from '@models/ComponentVariantGroup';
import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';
import { ComponentVariantChangeModel } from '@models/component-variant-change';
import { ErrorModel } from '@models/error';
import { LimitedEditionData } from '@models/limited-edition-data';
import { VariantColorModel } from '@models/variant-color';
import { ConfigurationService } from '@services/configuration.service';
import { DataApiService } from '@services/data-api.service';
import { EventsService } from '@services/events.service';
import { ImageService } from '@services/image.service';
import { LanguageService } from '@services/language.service';
import { OrdersDataService } from '@services/orders-data.service';
import { SorterService } from '@services/sorter.service';
import { AngleConstants } from '@shared/angle-constants';
import { BaseCustomizerComponent } from '@shared/base-customizer-component';
import { SubmenuTransition } from '@shared/transitions/submenu.transition';

@Component({
	selector: 'app-temple-design-grouped',
	templateUrl: './temple-design-grouped.component.html',
	styleUrls: ['./temple-design-grouped.component.scss'],
	animations: [
		SubmenuTransition()
	]
})
export class TempleDesignGroupedComponent extends BaseCustomizerComponent implements OnInit, AfterViewInit {

	public groups: ComponentVariantGroup[] = []

	activeFinish: string;

	templeTypes: string[] = [];
	activeTempleType: string;


	// TODO: REVIEW Input - if they are needed and implemented

	@Input() option: any;
	@Input() optionsId: string;
	@Input() matchColor: number = 0;
	optionsText: string = 'Description';
	optionsDescription2: string = 'Description2';
	@Input() optionsDiscontinued: string;
	@Input() optionsImage?: (option: any) => string;
	@Input() imageClass: string;
	@Input() colorsAvailableForMatching: Array<VariantColorModel> = null;
	@Input() currentColor: VariantColorModel;
	@Input() colorMatchMode: boolean;
	@Input() editMode: boolean;

	// @Output() matchColors: EventEmitter<VariantColorModel> = new EventEmitter();
	@Output() selectedChange = new EventEmitter<ComponentVariantFromApiModel>();

	currentGroup: any;

	states: Map<string, boolean> = new Map<string, boolean>();
	noneMatchingFinishSelected: boolean;

	public finishErrors: Array<ErrorModel> = [];

	group: ComponentVariantGroup
	selected: ComponentVariantFromApiModel
	tempSelected: ComponentVariantFromApiModel;
	tempGroup: ComponentVariantGroup;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private sortingService: SorterService,
		private imageService: ImageService,
		private changeDetectorRef: ChangeDetectorRef,
		private translateService: LanguageService,
		private ordersDataService: OrdersDataService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	ngAfterViewInit(): void {
		this.changeDetectorRef.detectChanges();
	}

	async initData() {
		this.events.showLoader(true);

		await this.fetchData();

		this.setImageSliderByName(AngleConstants.Perspective);

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	async fetchData() {
		this.getSelectedVariant.VariantViewed = true;

		const finishIndicator = this.getSelectedVariant.Code.substring(this.getSelectedVariant.Code.indexOf(';') + 1);
		this.activeFinish = finishIndicator;

		const checkIfLimited = this.getSelectedVariant.GroupDescription.Fallback === '' ? 'limited' : this.getSelectedVariant.GroupDescription.Fallback; // limited has no fallback

		this.activeTempleType = checkIfLimited;

		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, this.getSelectedVariant.Component.Uuid);

		// ***** FIND DISTINCT TEMPLETYPES; precious metal temples, horn temples etc
		apidata.forEach(cv => {
			let fallbackText = cv.GroupDescription.Fallback;

			if (fallbackText && !this.templeTypes.includes(fallbackText)) {
				this.templeTypes.push(fallbackText);
			}
		});

		// ***** GROUP TEMPLES BY GROUP (FALLBACK TEXT); 700, 78, 945 etc.
		const groupedTemples: { [key: string]: ComponentVariantFromApiModel[]; } = apidata.reduce(function (r, a) {
			const key = a.GroupOrder + '###' + a.Group.Fallback;

			r[key] = r[key] || [];
			r[key].push(a);

			return r;
		}, Object.create(null));

		// ***** CREATE VIEW MODELS FOR EACH GROUP
		Object.entries(groupedTemples)
			.sort((a, b) => a[0].localeCompare(b[0]))
			.forEach(g => {
				let group = new ComponentVariantGroup();
				group.name = g[0].substring(g[0].lastIndexOf('###') + 3);
				group.allDesigns = g[1];

				let grossList: any[] = [];

				g[1].sort((a, b) => a.Order.localeCompare(b.Order)).forEach(d => {
					let n = d.Code.substring(0, d.Code.indexOf(';'));
					if (!grossList.find(e => e.Code.substring(0, d.Code.indexOf(';')) === n)) {
						grossList.push(d);
					}
				});

				group.designs = grossList.filter(d => (d.GroupDescription.Fallback && d.GroupDescription.Fallback === this.activeTempleType));

				let exist = group.designs.find(d => d.Id === this.getSelectedVariant.Id);
				if (exist) {
					group.selectedDesign = exist;
				}
				else {
					group.selectedDesign = group.designs[0];
				}

				group.isExpanded = false;

				this.states.set(group.name, true);

				this.collapseGroup(group);

				this.groups.push(group);
			});

		await this.RunDisablingSystem(apidata);
		const designs = apidata.sort(this.sortingService.sortBy(ComponentVariantFromApiModel));

		// *** Add limited edition data to designs ***

		const limitedEditionData = await this.ordersDataService.getLimitedEditionData();

		if (limitedEditionData && limitedEditionData.Data) {
			designs.forEach(d => {
				const id = d.Component.Code + d.Group.Fallback;
				const limited = limitedEditionData.Data.find(led => led.FieldData.ComponentVariantGroupCode === id);

				if (limited) {
					d.limitedEditionData = new LimitedEditionData(id, limited.FieldData.QtyAvailable, limited.FieldData.QtyTotal);
				}
			});
		}
	}

	getImage(variant: ComponentVariantFromApiModel) {
		let tempVariant = (variant instanceof ComponentVariantFromApiModel) ? variant.ToComponentVariantModel() : variant;

		if (tempVariant.Brand === BrandEnum.Titanium) {
			return this.imageService.GetVariantImage(tempVariant);
		}
		else {
			if (tempVariant && tempVariant.Group.Fallback) {
				return this.imageService.GetVariantPreciousImage(tempVariant, tempVariant.Group.Fallback);
			}
		}
	}

	onSelected(selected: ComponentVariantFromApiModel, group: ComponentVariantGroup) {
		this.tempSelected = selected;
		this.tempGroup = group;

		this.finishErrors = [];
		this.events.showLoader(true);

		let selectedFinish = this.getSelectedVariant.Code.substring(this.getSelectedVariant.Code.indexOf(';') + 1);

		let newVariant = (selected instanceof ComponentVariantFromApiModel) ? selected.ToComponentVariantModel(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true) : selected;
		let wantedFinish = newVariant.Code.substring(newVariant.Code.indexOf(';') + 1);

		let finishAvailable = group.allDesigns.some(d => d.Code === wantedFinish);

		if (!finishAvailable && (selectedFinish !== wantedFinish)) {
			let errorModel = new ErrorModel();
			let m1 = this.translateService.instant(`MATERIAL.${selectedFinish}`);
			let m2 = this.translateService.instant(`MATERIAL.${wantedFinish}`);
			errorModel.translateName = this.translateService.instant('CUSTOMISER.EVENTS.MATERIAL_CHANGED', { m1, m2 });
			this.finishErrors.push(errorModel);

			this.noneMatchingFinishSelected = true;
		}
		else {
			this.tempSelected = null;
			this.tempGroup = null;

			this.selected = selected;
			this.group = group;

			this.save(true);
		}

		this.events.showLoader(false);
	}

	save($event: any) {
		if ($event) {
			// this is a little hack to enable resetting of values in case user cancels switch to other variant
			// when confirm modal is shown this component doesn't know what action the user takes
			if (this.tempSelected && this.tempGroup) {
				this.selected = this.tempSelected;
				this.group = this.tempGroup;

				this.tempSelected = null;
				this.tempGroup = null;
			}

			let newVariant = (this.selected instanceof ComponentVariantFromApiModel) ? this.selected.ToComponentVariantModel(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true) : this.selected;
			const componentVariantChangeModel = new ComponentVariantChangeModel(this.getSelectedVariant, newVariant, this.selected?.VariantSizes);

			this.updateVariantDesign(componentVariantChangeModel);

			this.group.selectedDesign = this.selected;

			const finishIndicator = this.selected.Code.substring(newVariant.Code.indexOf(';') + 1);
			this.activeFinish = finishIndicator;

			this.collapseAllGroups();

			this.noneMatchingFinishSelected = false;
		}
	}

	rejectFinishChange($event: any) {
		if (!$event) {
			this.noneMatchingFinishSelected = false;
		}
	}

	public matchColorsClicked() {
		// this.matchColors.emit();
	}

	expandGroup(group: any) {
		if (this.currentGroup && this.currentGroup !== group) {
			this.collapseGroup(this.currentGroup)
		}

		this.currentGroup = group;

		group.isExpanded = true;

		setTimeout(() => {
			this.states.set(group.name, false);
		}, 1);
	}

	collapseGroup(group: any) {
		setTimeout(() => {
			this.states.set(group.name, true);
		}, 1);
	}

	collapseAllGroups() {
		this.groups.forEach(g => this.collapseGroup(g));
	}

	filterTempleTypeDisplay(templeType: string) {
		this.activeTempleType = templeType;

		this.groups.forEach(g => {
			let grossList: any[] = [];

			g.allDesigns.sort((a, b) => a.Order.localeCompare(b.Order)).forEach(d => {
				let n = d.Code.substring(0, d.Code.indexOf(';'));
				if (!grossList.find(e => e.Code.substring(0, d.Code.indexOf(';')) === n)) {
					grossList.push(d);
				}
			});

			g.designs = grossList.filter(d => (d.GroupDescription.Fallback && d.GroupDescription.Fallback === this.activeTempleType));

			let exist = g.designs.find(d => d.Id === this.getSelectedVariant.Id);
			if (exist) {
				g.selectedDesign = exist;
			}
			else {
				g.selectedDesign = g.designs[0];
			}

			g.isExpanded = false;
		});
	}

	public getState(group: any): string {
		const currentState = this.states.get(group.name);

		if (currentState) {
			return "collapse";
		}
		else {
			return "expand";
		}
	}

}
