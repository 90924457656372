export class StartKitModel {
    constructor(
        public ProductLineCode: string,
        public ItemNo: string | null,
        public VariantCode: string,
        public Description: string,
        public KidTeen: boolean,
        public ToolsIncluded: boolean
    ) { }
}
