export class ColorLineModel {
    Id: number;
    Uuid: string;
    Code: string;
    Description: string;
    CombinedColors: boolean;

    public static createCopy(fromModel: ColorLineModel): ColorLineModel {
        const colorLineModel = new ColorLineModel();
        colorLineModel.Id = fromModel?.Id;
        colorLineModel.Uuid = fromModel?.Uuid;
        colorLineModel.Description = fromModel?.Description;
        colorLineModel.Code = fromModel?.Code;
        colorLineModel.CombinedColors = fromModel?.CombinedColors;
        return fromModel ? colorLineModel : null;
    }
}
