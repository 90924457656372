import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'cstmsr-temple-design-by-finish',
	template: `
        <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
            <select-box *ngIf="designs.length"
				[editMode]="state === customizerStateEnum.EDITORDER"
				[imageClass]="'temple-design'"
				[optionsImage]="getImage"
				[options]="designs"
				optionsText="Description"
				optionsId="Id"
				[selected]="getSelectedVariant"
				(selectedChange)="onSelected($event)"
				[optionsDiscontinued]="'Discontinued'"
				(matchColors)="matchColors(null)"
				[colorMatchMode]="true"
				[colorsAvailableForMatching]="allPossibleColorsOnOtherSelectedVariants"
				[currentColor]="getSelectedVariant.VariantColor"
				[variantOrModelIsNew]="modelIsNew"
				[matchColor]="menu.matchColor"
			>
            </select-box>
        </customizer-component-header>
    `
})
export class TempleDesignByFinishComponent extends BaseCustomizerComponent implements OnInit {

	public designs: Array<ComponentVariantFromApiModel> = [];

	constructor(
		public events: EventsService,
		private imageService: ImageService,
		public apiService: DataApiService,
		public sorter: SorterService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		await this.fetchData();
		this.setImageSliderByName(AngleConstants.Perspective);

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	getImage = (variant: ComponentVariantFromApiModel) => {
		return this.imageService.GetVariantPreciousImage(variant.ToComponentVariantModel(""), this.modelGroup.ProductLineCode);
	};

	onSelected(selected: ComponentVariantFromApiModel) {
		this.events.showLoader(true);
		const newVariant = selected.ToComponentVariantModel(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true);
		this.updateVariantDesign(new ComponentVariantChangeModel(this.getSelectedVariant, newVariant, selected.VariantSizes));
	}

	async fetchData() {
		this.getSelectedVariant.VariantViewed = true;

		const finishIndicator = this.getSelectedVariant.Code.substring(this.getSelectedVariant.Code.indexOf(';'), this.getSelectedVariant.Code.length);

		let apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, this.getSelectedVariant.Component.Uuid);
		apidata = apidata.filter(a => a.Code.indexOf(finishIndicator) > -1);

		await this.RunDisablingSystem(apidata);
		this.designs = apidata.sort(this.sorter.sortBy(ComponentVariantFromApiModel));

	}

}
