import { Injectable } from '@angular/core';
import { ModelgroupConstants } from '@shared/modelgroup-constants';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BrandConstants, BrandEnum } from '@infrastructure/brand.enum';
import { CameraEnum } from '@infrastructure/camera.enum';
import { SourceTypeEnum } from '@infrastructure/sourcetype.enum';
import { ImageAndThumb } from '@models/limited-edition-model';
import { MiscImageModel } from '@models/misc-image-model';
import { VariantSizeModel } from '@models/variant-size';
import { AppState } from '@shared/app-state';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ModelModel } from 'src/_models/api-models/model';
import { ImageGetModel, ImageModel } from 'src/_models/image';
import { ModelGroupModel } from 'src/_models/model-group';
import { ComponentVariantModel } from '../_models/component-variant';
import { OrderItemModel } from '../_models/order-item';
import { AngleConstants } from '../shared/angle-constants';
import { ComponentCodeConstants } from '../shared/component-constants';
import { BrandModeService } from './brandmode.service';
import { EnvService } from './env.service';
import { OfflineService } from './offline.service';

export enum ImageSize {
	"High" = "high",
	"Medium" = "medium",
	"Low" = "low"
}

export enum Background {
	"Default" = "default",
	"None" = "none",
	"Precious" = "precious"
}

export enum ConfigurationMode {
	"undefined",
	"display" = "display",
	"enduser" = "enduser"
}

@Injectable()
export class ImageService {
	public SupportsWebP: boolean = true;

	private baseImageUrl: string
	private baseImageCdnUrl: string

	constructor(private deviceDetector: DeviceDetectorService,
		private offlineService: OfflineService,
		private env: EnvService,
		private brandModeService: BrandModeService,
		private appState: AppState) {
		if (this.deviceDetector.browser == "Safari") {
			this.SupportsWebP = false;
			this.checkWebP();
		}

		this.env.ImageUrl().then(u => {
			this.baseImageUrl = u;
		})

		this.env.ImageCdnUrl().then(u => {
			this.baseImageCdnUrl = u;
		})
	}
	private checkWebP() {
		const webP = new Image();
		const ref = this;
		webP.onload = webP.onerror = (e: Event) => {
			ref.SupportsWebP = webP.height === 2;
		};
		webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
	}

	public GetFrontImage(model: ModelModel, modelGroupCode: string, configurationCode: string, variants: Array<ComponentVariantModel>,
		size: ImageSize | string, background: Background = Background.Default, usePreciousCheck: boolean = true): string {

		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		const imageGetModel = this.GetImageModel(model, modelGroupCode, configurationCode, variants, AngleConstants.Front);
		imageGetModel.ComponentColorList = this.GetComponentParameters(variants);
		imageGetModel.Angle = AngleConstants.Front;

		return this.GetImageUrl(imageGetModel, size, background, usePreciousCheck);
	}

	public GetPerspectiveImage(model: ModelModel, modelGroupCode: string, configurationCode: string, variants: Array<ComponentVariantModel>, size: ImageSize | string, background: Background): string {
		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		const imageGetModel = this.GetImageModel(model, modelGroupCode, configurationCode, variants, AngleConstants.Perspective);
		imageGetModel.ComponentColorList = this.GetComponentParameters(variants);
		imageGetModel.Angle = AngleConstants.Perspective;

		return this.GetImageUrl(imageGetModel, size, background);
	}

	public GetSideImage(model: ModelModel, modelGroupCode: string, configurationCode: string, variants: Array<ComponentVariantModel>, size: ImageSize | string, background: Background): string {
		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		const imageGetModel = this.GetImageModel(model, modelGroupCode, configurationCode, variants, AngleConstants.Side);
		imageGetModel.ComponentColorList = this.GetComponentParameters(variants);
		imageGetModel.Angle = AngleConstants.Side;

		return this.GetImageUrl(imageGetModel, size, background);
	}

	public GetDetailImage(model: ModelModel, modelGroupCode: string, configurationCode: string, variants: Array<ComponentVariantModel>, size: ImageSize | string): string {
		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		const imageGetModel = this.GetImageModel(model, modelGroupCode, configurationCode, variants, AngleConstants.Perspective);
		imageGetModel.ComponentColorList = this.GetComponentParameters(variants);
		imageGetModel.Angle = AngleConstants.Detail;

		return this.GetImageUrl(imageGetModel, size, Background.None);
	}

	public GetConfigurationDetailImage(model: ModelModel, modelGroupCode: string, configurationCode: string, variants: Array<ComponentVariantModel>, size: ImageSize | string) {
		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		const imageGetModel = this.GetImageModel(model, modelGroupCode, configurationCode, variants, AngleConstants.Perspective);
		imageGetModel.ComponentColorList = this.GetComponentParameters(variants);
		imageGetModel.Angle = AngleConstants.Detail;

		return this.GetImageUrl(imageGetModel, size, Background.None);
	}

	public GetSliderImages(modelgroup: ModelGroupModel, model: ModelModel, modelGroupCode: string, modelConfigurationCode: string, variants: Array<ComponentVariantModel>): Array<ImageModel> {
		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		return this.GetSliderImagesPrivate(modelgroup, model, modelGroupCode, modelConfigurationCode, variants, ImageSize.High, Background.Default);
	}

	public GetSliderThumbImages(modelgroup: ModelGroupModel, model: ModelModel, modelGroupCode: string, modelConfigurationCode: string, variants: Array<ComponentVariantModel>) {
		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		if (!this.offlineService.isOnlineValue) {
			return this.GetSliderImages(modelgroup, model, modelGroupCode, modelConfigurationCode, variants);
		}
		return this.GetSliderImagesPrivate(modelgroup, model, modelGroupCode, modelConfigurationCode, variants, ImageSize.Low, Background.None);
	}

	private GetSliderImagesPrivate(modelgroup: ModelGroupModel, model: ModelModel, modelGroupCode: string, modelConfigurationCode: string, variants: Array<ComponentVariantModel>, size: ImageSize, background: Background) {
		variants = this.adjustForBrandMode(variants, model.PresentAsPrecious);

		if (modelgroup.IsRimless) {
			return [
				new ImageModel(AngleConstants.Front, this.GetFrontImage(model, modelGroupCode, modelConfigurationCode, variants, size, background)),
				new ImageModel(AngleConstants.Side, this.GetSideImage(model, modelGroupCode, modelConfigurationCode, variants, size, background)),
			];
		}

		return [
			new ImageModel(AngleConstants.Perspective, this.GetPerspectiveImage(model, modelGroupCode, modelConfigurationCode, variants, size, background)),
			new ImageModel(AngleConstants.Front, this.GetFrontImage(model, modelGroupCode, modelConfigurationCode, variants, size, background)),
		];
	}

	public GetOrderItemImage(orderItem: OrderItemModel, angle: AngleConstants, size: ImageSize | string = ImageSize.Medium, usePreciousCheck: boolean = true) {
		const model = new ImageGetModel();
		model.Angle = angle;
		model.ModelGroupCode = orderItem.ModelGroupCode;
		model.ModelCode = orderItem.ModelCode;
		model.ConfigurationCode = orderItem.ModelConfigurationCode;
		model.presentAsPrecious = orderItem.IsPrecious;
		model.ComponentVariantCode = [];
		model.ComponentColorList = orderItem.OrderLines.map(x => [x.ComponentCode, x.ColorCode, x.OriginalComponentColor && x.OriginalComponentColor.Color && x.OriginalComponentColor.Color.ColorLine ? x.OriginalComponentColor.Color.ColorLine.Code : x.ComponentVariantCode] as [string, string, string]);

		const defaultTemple = orderItem.OrderLines.find((obj) => obj.ComponentCode == ComponentCodeConstants.Temple);
		model.ComponentVariantCode.push(defaultTemple ? { variantCode: defaultTemple.ComponentVariantCode, componentCode: defaultTemple.ComponentCode } : { variantCode: "dummy", componentCode: "dummy" }); // temple part is needed in url, whether we show it or not

		const bridge = orderItem.OrderLines.find(x => x.ComponentCode == ComponentCodeConstants.Bridge);
		if (bridge && model.Angle == AngleConstants.Front) {
			model.ComponentVariantCode.push({ variantCode: bridge.ComponentVariantCode, componentCode: bridge.ComponentCode });
		}

		const innerrim = orderItem.OrderLines.find(x => x.ComponentCode == ComponentCodeConstants.Innerrim);
		if (innerrim) {
			model.ComponentVariantCode.push({ variantCode: innerrim.ComponentVariantCode, componentCode: innerrim.ComponentCode });
		}
		let background = Background.None;
		return this.GetImageUrl(model, undefined, background, usePreciousCheck);
	}

	public GetComponentDetailImage(variantCode: string, variantSizeModel: VariantSizeModel): string {
		let size: ImageSize | string = ImageSize.Low;
		if (variantSizeModel.Size) {
			size = variantSizeModel.Size;
		}

		return `${this.getBaseUrl(size)}/component/${variantCode}/?size=${size}`
			+ (this.SupportsWebP ? "&format=webp" : "")
			+ (this.brandModeService.isPrecious ? "&background=precious" : "");
	}

	public GetComponentDetailPreciousImage(componentCode: string, variantCode: string, variantSizeModel: VariantSizeModel, modelGroupCode: string): string {
		let size: ImageSize | string = ImageSize.Low;
		if (variantSizeModel.Size) {
			size = variantSizeModel.Size;
		}

		return `${this.getBaseUrl(size)}/${BrandConstants.PRECIOUS}/${modelGroupCode.toLowerCase()}/${componentCode.toLowerCase()}/${variantCode.toLowerCase()}${size}?`
			+ (this.brandModeService.isPrecious ? "background=" + BrandConstants.PRECIOUS : "");
	}

	public GetNoGemstoneImage(modelGroupCode: string): string {
		let size: ImageSize | string = ImageSize.Low;
		const noGemstoneImageUrl = `${this.getBaseUrl(size)}/${BrandConstants.PRECIOUS}/${modelGroupCode.toLowerCase()}/gemstone/plain?`
			+ (this.brandModeService.isPrecious ? "background=" + BrandConstants.PRECIOUS : "");

		return noGemstoneImageUrl;
	}

	public GetVariantImage(variant: ComponentVariantModel | ComponentVariantFromApiModel, size: ImageSize | string = ImageSize.Low): string {
		let variantCode = variant.Code; //.replace(';POL', '').replace(';SAT', '');

		let url = `${this.getBaseUrl(size)}/component/${variant.Component.Code}/${variantCode}?size=${!this.offlineService.isOnlineValue ? ImageSize.Low : size}`
			+ (this.SupportsWebP ? "&format=webp" : "")
			+ (this.brandModeService.isPrecious ? "&background=" + BrandConstants.PRECIOUS : "");

		return url;
	}

	public GetVariantPreciousImage(variant: ComponentVariantModel, modelGroupCode: string, size: ImageSize | string = ImageSize.Low): string {
		let code = variant.Code.substring(0, variant.Code.lastIndexOf(';')).toLocaleLowerCase();
		let finish = variant.Code.substring(variant.Code.lastIndexOf(';')).toLocaleLowerCase();

		const url = `${this.getBaseUrl(size)}/${BrandConstants.PRECIOUS}/${variant.Component.Code.toLowerCase()}/${code + finish}?size=${!this.offlineService.isOnlineValue ? ImageSize.Low : size}`
			+ (this.brandModeService.isPrecious ? "&background=" + BrandConstants.PRECIOUS : "");

		return url;
	}

	public GetFinishImage(finishName: string, size: ImageSize | string = ImageSize.Low): string {
		finishName = finishName.replace(';', '').toLowerCase();

		return `${this.getBaseUrl(size)}/${BrandConstants.PRECIOUS}/finish/${finishName}?size=${!this.offlineService.isOnlineValue ? ImageSize.Low : size}`
			+ (this.brandModeService.isPrecious ? "&background=" + BrandConstants.PRECIOUS : "");
	}

	public GetComponentInfoImage(variant: ComponentVariantFromApiModel | ComponentVariantModel, type: 'variant' | 'size' | 'color', modelGroupCode?: string) {
		if (modelGroupCode) {
			return `./assets/images/${variant.Component.Code.toLowerCase()}/${modelGroupCode}_${type}_info.svg`;
		} else {
			return `./assets/images/${variant.Component.Code.toLowerCase()}/${type}_info.png`;
		}
	}

	public GetVariantInfoImage(variant: ComponentVariantFromApiModel | ComponentVariantModel) {
		return `./assets/icons/${variant.Code}.svg`;
	}

	public GetProductlineImages(ModelgroupCode: string, mode: ConfigurationMode = ConfigurationMode.undefined, size: ImageSize | string = ImageSize.Low): string {
		let preciousIndicator = '';

		if (this.brandModeService.isPrecious) {
			preciousIndicator = 'Precious_';
		}

		let url = `${this.getBaseUrl(size)}/productlines/${preciousIndicator}${ModelgroupCode}` + (mode != ConfigurationMode.undefined ? `/${mode}` : "") + `?size=${size}`
		url += this.SupportsWebP ? "&format=webp" : "";

		return url;
	}

	public GetInspirationGroupImages(inspirationGroup: string, size: ImageSize | string = ImageSize.Low): string {
		let preciousIndicator = '';

		if (this.brandModeService.isPrecious) {
			preciousIndicator = 'precious_';
		}

		const url = `${this.getBaseUrl(size)}/inspiration/Group/${preciousIndicator}${inspirationGroup}` + (this.SupportsWebP ? "?format=webp" : "");
		return url;
	}

	public GetInspirationImage(inspirationGroup: string, uuid: string, size: ImageSize | string = ImageSize.Low): string {
		return `${this.getBaseUrl(size)}/inspiration/Photo/${inspirationGroup}/${uuid}` + (this.SupportsWebP ? "?format=webp" : "");
	}

	public GetColorImages(colorLine: string, colorCode: string, size: ImageSize | string = ImageSize.Low): string {
		return `${this.getBaseUrl(size)}/color/${colorLine}/${colorCode}?size=${ImageSize.Low}` + (this.SupportsWebP ? "&format=webp" : "");
	}

	public GetMiscImage(eCommerceNo: string, imageNumber: number, size: string = "Small"): string {
		return `${this.getBaseUrl(size)}/product/${eCommerceNo}/${imageNumber}/${size}` + (this.SupportsWebP ? "?format=webp" : "");
	}

	public GetMiscCategoryImage(category: string, size: ImageSize | string = ImageSize.Low): string {
		let preciousIndicator = '';

		if (this.brandModeService.isPrecious) {
			preciousIndicator = 'precious_';
		}

		// let imageUrl = `${this.getBaseUrl(size)}/product/category/${preciousIndicator}${category}?size=${size}` + (this.SupportsWebP ? "&format=webp" : "");
		let imageUrl = `${this.getBaseUrl(size)}/product/group/${preciousIndicator}${category}?size=${size}` + (this.SupportsWebP ? "&format=webp" : "");

		return imageUrl;
	}

	public GetMiscGroupImage(group: string, category: string, size: ImageSize | string = ImageSize.Low): string {
		let preciousIndicator = '';

		if (this.brandModeService.isPrecious) {
			preciousIndicator = 'precious_';
		}

		return `${this.getBaseUrl(size)}/product/group/${category}/${preciousIndicator}${group}?size=${size}` + (this.SupportsWebP ? "&format=webp" : "");
	}

	public GetGrooveColorPenImage(size: ImageSize | string = ImageSize.Low): string {
		return `${this.getBaseUrl(size)}/product/GrooveColourPen/1` + (this.SupportsWebP ? "?format=webp" : "");
	}

	public GetTempleInclinationStandardInfoImage() {
		if (this.brandModeService.isPrecious) {
			return `./assets/images/illustration-temple-inclination-standard-precious.svg`;
		}
		return `./assets/images/illustration-temple-inclination-standard.svg`;
	}


	public GetTempleInclinationLowInfoImage() {
		if (this.brandModeService.isPrecious) {
			return `./assets/images/illustration-temple-inclination-low-precious.svg`;
		}
		return `./assets/images/illustration-temple-inclination-low.svg`;
	}

	public GetMiscImageModels(eCommerceNo: string, imageCount: number) {
		const imageModelArrays: Array<Array<MiscImageModel>> = new Array<Array<MiscImageModel>>();

		if (!imageCount || imageCount == 0) {
			const imageModel = this.getMiscImageModel(this.GetMiscImage("", 0), eCommerceNo, 0);
			imageModelArrays.push([imageModel]);
		}
		else if (imageCount == 3) {
			const imageArray = new Array<MiscImageModel>();

			for (let counter = 1; counter < imageCount + 1; counter++) {
				const imageModel = this.getMiscImageModel(this.GetMiscImage(eCommerceNo, counter), eCommerceNo, counter);
				imageArray.push(imageModel);
			}

			imageModelArrays.push(imageArray);
		}
		else {
			let imageArray = new Array<MiscImageModel>();

			for (let counter = 1; counter < imageCount + 1; counter++) {
				if (imageCount == 1) {
					const imageModel = this.getMiscImageModel(this.GetMiscImage(eCommerceNo, counter), eCommerceNo, counter);
					imageModelArrays.push([imageModel]);
				}

				if ((counter % 2) == 0) {
					const imageModel = this.getMiscImageModel(this.GetMiscImage(eCommerceNo, counter), eCommerceNo, counter);
					imageArray.push(imageModel);
					imageModelArrays.push(imageArray);
					imageArray = new Array<MiscImageModel>();
				}
				else {
					const imageModel = this.getMiscImageModel(this.GetMiscImage(eCommerceNo, counter), eCommerceNo, counter);
					imageArray.push(imageModel);
				}
			}
		}

		return imageModelArrays;
	}

	private adjustForBrandMode(variants: Array<ComponentVariantModel>, presentAsPrecious: boolean): Array<ComponentVariantModel> {
		if ((this.brandModeService.isPrecious && this.appState.showPreciousTheme.getValue()) || presentAsPrecious) {
			variants = variants.filter(v => v.Brand == BrandEnum.Precious || v.Brand == BrandEnum.TitaniumAndPrecious || !v.Brand);
		}
		else {
			variants = variants.filter(v => v.Brand == BrandEnum.Titanium || v.Brand == BrandEnum.TitaniumAndPrecious || !v.Brand);
		}

		return variants
	}

	private getMiscImageModel(src: string, eCommerceNo: string, imageCount: number) {
		const imageModel = new MiscImageModel();
		imageModel.Src = src;
		imageModel.ECommerceNo = eCommerceNo;
		imageModel.ImageCount = imageCount;
		return imageModel;
	}

	public GetPersistedImageUrl(itemUuid: string, sourceType: SourceTypeEnum, camera: CameraEnum): string {
		let url = this.baseImageCdnUrl;
		url += "/persisted"
		url += "/"
		url += sourceType.toString() + "/"
		url += itemUuid + "/"
		url += camera.toString();
		url += "?showerror=false";
		return url;
	}

	public getPartsIcon(partName: string) {
		let url = this.baseImageCdnUrl;
		url += '/product/group/parts/';
		url += partName;

		return url;
	}

	private GetImageUrl(imageGetModel: ImageGetModel, size: ImageSize | string = ImageSize.Low, background: Background = Background.Default, usePreciousCheck: boolean = true): string {
		if (((this.brandModeService.isPrecious && this.appState.showPreciousTheme.getValue()) || imageGetModel.presentAsPrecious)
			&& imageGetModel.ModelGroupCode !== ModelgroupConstants.STRIP3P) {
			background = Background.Precious;
		}

		let url = this.getBaseUrl(size)
			+ "/" + "model"
			+ "/" + imageGetModel.ModelGroupCode
			+ "/" + (this.getImageModelCode(imageGetModel))
			+ "/" + imageGetModel.Angle;

		if (background === Background.Precious && usePreciousCheck) {
			url = this.getBaseUrl(size)
				+ "/" + BrandConstants.PRECIOUS
				+ "/" + "model"
				+ "/" + (this.getImageModelCode(imageGetModel));

			return url;
		}
		else if (!usePreciousCheck) {
			background = Background.None;
		}

		let finishParameter = '';
		let variantCode = '';

		imageGetModel.ComponentVariantCode.forEach(code => {
			if (imageGetModel.ModelGroupCode == ModelgroupConstants.STRIP3P || imageGetModel.ModelGroupCode == ModelgroupConstants.SPIRIT) {
				if (imageGetModel.Angle === AngleConstants.Front && code.componentCode === ComponentCodeConstants.Temple) { return; }
				if (imageGetModel.Angle === AngleConstants.Front && code.componentCode === ComponentCodeConstants.BridgeClip) { return; }

				if (imageGetModel.Angle === AngleConstants.Side && code.componentCode === ComponentCodeConstants.Front) { return; }
				if (imageGetModel.Angle === AngleConstants.Side && code.componentCode === ComponentCodeConstants.BridgeClip) { return; }

			}
			variantCode = code.variantCode

			if (variantCode.indexOf(';') > -1) {
				finishParameter = variantCode.substring(code.variantCode.indexOf(';'), code.variantCode.length);
				variantCode = variantCode.replace(finishParameter, '');
			}
			url += "/" + variantCode;

		});

		url += "?";

		if (imageGetModel.ComponentColorList) {
			imageGetModel.ComponentColorList.sortBy(x => x[0]).forEach(componentColorItem => {

				if (imageGetModel.ModelGroupCode == ModelgroupConstants.STRIP3P || imageGetModel.ModelGroupCode == ModelgroupConstants.SPIRIT) {
					if (imageGetModel.Angle === AngleConstants.Front && componentColorItem[0] === ComponentCodeConstants.Temple) { return; }
					if (imageGetModel.Angle === AngleConstants.Front && componentColorItem[0] === ComponentCodeConstants.BridgeClip) { return; }

					if (imageGetModel.Angle === AngleConstants.Side && componentColorItem[0] === ComponentCodeConstants.Front) { return; }
					if (imageGetModel.Angle === AngleConstants.Side && componentColorItem[0] === ComponentCodeConstants.BridgeClip) { return; }
					if (imageGetModel.Angle === AngleConstants.Side && componentColorItem[0] === ComponentCodeConstants.Bridge) { return; }
				}
				if (componentColorItem[0] === ComponentCodeConstants.Front) {
					finishParameter = '';
				}
				if (componentColorItem[0] === ComponentCodeConstants.Lenses) {
					url += componentColorItem[0] + "=" + componentColorItem[1] + componentColorItem[2] + finishParameter + "&";
				} else {
					url += componentColorItem[0] + "=" + componentColorItem[1] + finishParameter + "&";
				}
			});
		}

		url += "CONF=" + imageGetModel.ConfigurationCode;
		url += "&size=" + size;
		url += background !== Background.Default ? `&background=${background}` : "";
		url += this.SupportsWebP ? "&format=webp" : "";

		return url;
	}

	private getImageModelCode(imageGetModel: ImageGetModel) {
		if (imageGetModel.Angle === AngleConstants.Side && (imageGetModel.ModelGroupCode === ModelgroupConstants.STRIP3P || imageGetModel.ModelGroupCode === ModelgroupConstants.SPIRIT)) {
			return 2000;
		}

		return imageGetModel.ModelCode;
	}

	private getBaseUrl(size: string) {
		return size === ImageSize.High ? this.baseImageUrl : this.baseImageCdnUrl;
	}

	private GetImageModel(model: ModelModel, modelGroupCode: string, configurationCode: string, variants: Array<ComponentVariantModel>, angle: AngleConstants): ImageGetModel {
		const imageGetModel = new ImageGetModel();
		imageGetModel.ModelGroupCode = modelGroupCode;
		imageGetModel.ModelCode = model.Code;
		imageGetModel.ConfigurationCode = configurationCode;
		imageGetModel.ComponentVariantCode = [];
		imageGetModel.presentAsPrecious = model.PresentAsPrecious;

		const defaultTemple = variants.find((obj) => obj.Component.Code == ComponentCodeConstants.Temple);
		if (defaultTemple) {

			imageGetModel.ComponentVariantCode.push({ variantCode: defaultTemple.Code, componentCode: defaultTemple.Component.Code });
		}

		const defaultInnerrim = variants.find((obj) => obj.Component.Code == ComponentCodeConstants.Innerrim);
		if (defaultInnerrim) {
			imageGetModel.ComponentVariantCode.push({ variantCode: defaultInnerrim.Code, componentCode: defaultInnerrim.Component.Code });
		}

		const bridge = variants.find(x => x.Component.Code == ComponentCodeConstants.Bridge);

		if (bridge && angle == AngleConstants.Front) {
			imageGetModel.ComponentVariantCode.push({ variantCode: bridge.Code, componentCode: bridge.Component.Code });
		}

		return imageGetModel;
	}

	private GetComponentParameters(componentVariantModels: Array<ComponentVariantModel>): Array<[string, string, string]> {
		const componentColorList: Array<[string, string, string]> = new Array<[string, string, string]>();

		if (componentVariantModels.length > 0) {
			componentVariantModels.forEach(variantModel => {
				const componentName = variantModel.Component.Code;

				if (variantModel.VariantColor) {
					const defaultColor = variantModel.VariantColor;

					if (componentName == ComponentCodeConstants.Lenses) {
						componentColorList.push([componentName, defaultColor.Color.Code, variantModel.VariantColor.Color.ColorLine.Code]);
					} else {
						componentColorList.push([componentName, defaultColor.Color.Code, variantModel.Code]);
					}
				}
			});
		}

		return componentColorList;
	}

	public async GetLimited(code: string) {
		let iat = new ImageAndThumb(
			`${this.getBaseUrl(null)}/precious/limited/large/${code}.png` + (this.SupportsWebP ? "?format=webp" : ""),
			`${this.getBaseUrl(null)}/precious/limited/small/${code}.png` + (this.SupportsWebP ? "?format=webp" : "")
		)

		return iat;
	}

	public GetProductlineImagesByBrand(ModelgroupCode: string, isPrecious: boolean = false): string {
		let preciousIndicator = isPrecious ? 'Precious_' : '';

		const url = `${this.getBaseUrl(ImageSize.Low)}/productlines/${preciousIndicator}${ModelgroupCode}`;
		return url + `?size=${ImageSize.Low}` + (this.SupportsWebP ? "&format=webp" : "");
	}

	public GetPartsTileImage(): string {
		let preciousIndicator = '';

		if (this.brandModeService.isPrecious) {
			preciousIndicator = 'Precious_';
		}

		let url = `${this.getBaseUrl(ImageSize.Low)}/productlines/${preciousIndicator}parts?size=low`;
		url += this.SupportsWebP ? "&format=webp" : "";

		return url;
	}

}
