import { Type } from "class-transformer";
import { ComponentVariantModel } from './component-variant';
import { VariantColorModel } from './variant-color';
import { VariantSizeModel } from './variant-size';

export class ComponentBindingModel {
    Id: number;
    Uuid: string;
    ComponentVariantUuid: string;
    VariantSizeUuid: string;
    VariantColorUuid: string;
    ComponentDependencyUuid: string;
    ComponentId: number;
    ConfigurationId: number;
    ComponentDependencyId: number;

    @Type(() => VariantColorModel)
    VariantColor: VariantColorModel;

    @Type(() => VariantSizeModel)
    VariantSize: VariantSizeModel;

    @Type(() => ComponentVariantModel)
    ComponentVariant: ComponentVariantModel;

    public hasVariantVariantSizeAndVariantColor() {
        return this.ComponentVariant != null && this.VariantSize != null && this.VariantColor != null;
    }

    public isMissingVariantColor() {
        return this.ComponentVariant != null && this.VariantSize != null && this.VariantColor == null;
    }

    public isMissingVariantSizeAndVariantColor() {
        return this.ComponentVariant != null && this.VariantSize == null && this.VariantColor == null;
    }

    public isMissingVariantVariantSizeAndVariantColor() {
        return this.ComponentVariant == null && this.VariantSize == null && this.VariantColor == null;
    }
}
