import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { BaseOrderItem } from './base-order-item';
import { EmptyDiscountModel, IDiscountValidation, IOrderItemDiscount } from './iorderitem-discountvalidation';
import { MiscExtendedText, MiscModel } from "./misc-model";
import { OrderItemMiscType } from './order-item-misc-type';
import { PriceItem } from './price-model';
import { StartKitModel } from './start-kit-model';

export class OrderItemMiscState {
    constructor() {
        this.SyncErrors = 0;
    }

    public Uuid: string;
    public Ecommerce: string;
    public EcommerceNoUnique: string;
    public EcommerceVariantCode: string;
    public NeedApprovalOnWebOrder: boolean;
    public CreatedAt: Date;
    public MiscModel: MiscModel;
    public Amount: number;
    public Reference: string;
    public Description: string;
    public CustomerNo: string;
    public ProductLineCode: string;
    public KidTeen: boolean;
    public ToolsIncluded: boolean;
    public Type: OrderItemMiscType;
    public OrderId?: number;
    public DiscountCode: string;
    public DiscountPercent: number;
    public Selected: boolean = false;
    public SyncState: SyncStateEnum;
    public SyncOrderItemGuid: string;
    public SyncErrors: number;
    public Price: PriceItem;
    public PriceJson: string;
}

export class OrderItemMiscModel extends BaseOrderItem implements IDiscountValidation {
    constructor(private state: OrderItemMiscState) {
        super();
    }

    public getState = () => this.state;

    public DisountValidationState(): IOrderItemDiscount { return new EmptyDiscountModel(); }

    public get Uuid(): string {
        return this.state.Uuid;
    }

    public set Selected(value: boolean) {
        this.state.Selected = value;
    }

    public get Selected(): boolean {
        return this.state.Selected;
    }

    public get Ecommerce(): string {
        return this.state.Ecommerce;
    }
    public get EcommerceNoUnique(): string {
        return this.state.EcommerceNoUnique;
    }
    public get EcommerceVariantCode(): string {
        return this.state.EcommerceVariantCode;
    }
    public get NeedApprovalOnWebOrder(): boolean {
        return this.state.NeedApprovalOnWebOrder;
    }
    public get CreatedAt(): Date {
        return this.state.CreatedAt;
    }
    public get MiscModel(): MiscModel {
        return this.state.MiscModel;
    }
    public set MiscModel(model: MiscModel) {
        this.state.MiscModel = model;
    }
    public get Amount(): number {
        return this.state.Amount;
    }
    public set Amount(value: number) {
        this.state.Amount = value;
    }
    public get Reference(): string {
        return this.state.Reference;
    }
    public set Reference(reference: string) {
        this.state.Reference = reference;
    }
    public get MiscModelReference(): string {
        return this.state.Reference;
    }

    public get Description(): string {
        return this.state.Description;
    }
    public get CustomerNo(): string {
        return this.state.CustomerNo;
    }
    public set CustomerNo(no: string) {
        this.state.CustomerNo = no;
    }
    public get ProductLineCode(): string {
        return this.state.ProductLineCode;
    }
    public get KidTeen(): boolean {
        return this.state.KidTeen;
    }
    public get ToolsIncluded(): boolean {
        return this.state.ToolsIncluded;
    }
    public get Type(): OrderItemMiscType {
        return this.state.Type;
    }
    public get OrderId(): number {
        return this.state.OrderId;
    }
    public set OrderId(value: number) {
        this.state.OrderId = value;
    }

    public get DiscountCode(): string {
        return this.state.DiscountCode;
    }
    public set DiscountCode(value: string) {
        this.state.DiscountCode = value;
    }
    public get DiscountPercent(): number {
        return this.state.DiscountPercent;
    }
    public set DiscountPercent(value: number) {
        this.state.DiscountPercent = value;
    }
    public get MaximumOrderQty() {
        return this.state.MiscModel?.MaximumOrderQty ?? 0;
    }
    public get StockKeepingUnitExtendedTexts(): MiscExtendedText[] {
        return this.state.MiscModel?.StockKeepingUnitExtendedTexts ?? [];
    }
    
    public get SyncState(): SyncStateEnum {
        return this.state.SyncState;
    }

    public set SyncState(value: SyncStateEnum) {
        this.state.SyncState = value;
    }
    
    public get SyncOrderItemGuid(): string {
        return this.state.SyncOrderItemGuid;
    }

    public set SyncOrderItemGuid(value: string) {
        this.state.SyncOrderItemGuid = value;
    }
    
    public get Price(): PriceItem {
        return this.state.Price;
    }

    public set Price(value: PriceItem) {
        this.state.Price = value;
    }
    
    public get PriceJson(): string {
        return this.state.PriceJson;
    }

    public set PriceJson(value: string) {
        this.state.PriceJson = value;
    }

    public static Create(
        orderId: number,
        reference: string,
        amount: number,
        customerNo: string,
        kidTeen: boolean,
        productlineCode : string,
        toolsIncluded: boolean,
        type: OrderItemMiscType,
        discountCode: string,
        discountPercent: number,
        miscModel: MiscModel) {

        let state = new OrderItemMiscState();
        state.Reference = reference;
        state.Amount = amount;
        state.Ecommerce = miscModel.EcommerceNo;
        state.EcommerceVariantCode = miscModel.EcommerceVariantCode;
        state.EcommerceNoUnique = miscModel.EcommerceNoUnique;
        state.Description = miscModel.Description;
        state.CustomerNo = customerNo;
        state.KidTeen = kidTeen;
        state.ToolsIncluded = toolsIncluded;
        state.ProductLineCode = productlineCode;
        state.Type = type;
        state.OrderId = orderId;
        state.DiscountCode = discountCode;
        state.DiscountPercent = discountPercent;
        state.MiscModel = miscModel;

        return new OrderItemMiscModel(state);
    }

    public static CreateFromStartkit(
        orderId: number,
        reference: string,
        amount: number,
        customerNo: string,
        kidTeen: boolean,
        productlineCode : string,
        toolsIncluded: boolean,
        type: OrderItemMiscType,
        discountCode: string,
        discountPercent: number,
        startKit: StartKitModel) {

        let state = new OrderItemMiscState();
        state.Reference = reference;
        state.Amount = amount;
        state.Ecommerce = startKit.ItemNo;
        state.EcommerceVariantCode = startKit.VariantCode;
        state.EcommerceNoUnique = startKit.ItemNo;
        state.Description = startKit.Description;
        state.CustomerNo = customerNo;
        state.KidTeen = kidTeen;
        state.ToolsIncluded = toolsIncluded;
        state.ProductLineCode = productlineCode;
        state.Type = type;
        state.OrderId = orderId;
        state.DiscountCode = discountCode;
        state.DiscountPercent = discountPercent;

        return new OrderItemMiscModel(state);
    }

    public static DeepClone(orderItemMiscModel : OrderItemMiscModel) {

        let state = new OrderItemMiscState();
        state.Reference = orderItemMiscModel.Reference;
        state.Amount = orderItemMiscModel.Amount;
        state.Ecommerce = orderItemMiscModel.Ecommerce;
        state.EcommerceVariantCode = orderItemMiscModel.EcommerceVariantCode;
        state.EcommerceNoUnique = orderItemMiscModel.EcommerceNoUnique;
        state.Description = orderItemMiscModel.Description;
        state.CustomerNo = orderItemMiscModel.CustomerNo;
        state.KidTeen = orderItemMiscModel.KidTeen;
        state.ToolsIncluded = orderItemMiscModel.ToolsIncluded;
        state.ProductLineCode = orderItemMiscModel.ProductLineCode;
        state.Type = orderItemMiscModel.Type;
        state.OrderId = orderItemMiscModel.OrderId;
        state.DiscountCode = orderItemMiscModel.DiscountCode;
        state.DiscountPercent = orderItemMiscModel.DiscountPercent;
        state.MiscModel = orderItemMiscModel.MiscModel;

        return new OrderItemMiscModel(state);
    }

    public isAnythingDiscontinued(): boolean {
        return this.MiscModel == null;
    }
}
