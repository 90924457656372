import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { ComponentVariantModel } from '../_models/component-variant';
import { IObservableStore } from './interfaces/observable-store.interface';

@Injectable()
export class LocalstorageService implements IObservableStore {
  constructor(
    private storage: LocalStorageService
  ) {}

  public get<T>(key: string): T {
    const obj = this.storage.retrieve(key);
    return obj as T;
  }

  public set(key: string, data: any): void {
    this.storage.store(key, data);
  }

  public clear(key?: string): void {
    this.storage.clear(key);
  }

  public onChange<T>(key: string): Observable<T> {
    return this.storage.observe(key);
  }

  addComponentVariantModel(component: ComponentVariantModel) {
    const allComponents: Array<ComponentVariantModel> = this.storage.retrieve('components') || [];
    const idx = allComponents.findIndex(x => x.Uuid == component.Uuid);
    if (idx >= 0) {
      allComponents[idx] = component;
    } else {
      allComponents.push(component);
    }
    this.storage.store('components', allComponents);
  }

}
