import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LogLevelEnum } from '@infrastructure/logLevel.enum';
import { AppState } from '@shared/app-state';
import { Table } from 'dexie';
import { CookieService } from 'ngx-cookie-service';
import { IndexDbService } from './index-db.service';
import { LoggerService } from './logger.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class StorageErrorsService {

	constructor(
		public indexedDbService: IndexDbService,
		private loggerService: LoggerService,
		public userService: UserService,
		public appState: AppState,
		public cookieService: CookieService,
	) {
	}

	public async sendToLog() {
		const user = await this.userService.GetUser();

		if (user) {
			const content = await this.getContentForLog();
			let eventId = user.Uuid;
			let userId = `${user.Name}(${user.DeliveryEmail}) - ${user.CustomerNo}`;

			if (user.AliasImitator !== '') {
				userId = `${user.AliasImitator} @$ ${userId}`
			}

			let logEvent = {
				message: `${user.Name} has storage problems - all key entries from indexedDb logged `,
				logLevel: LogLevelEnum.Warning,
				extraPropertiesToLog: {
					['indexedDb Store/key content']: JSON.stringify(content)
				},
				eventId: eventId,
				userId: userId
			}

			await this.loggerService.sendClientLog(logEvent);
		}
	}

	public async getContentForLog(): Promise<ContentSummary> {
		let tablesObject = await this.indexedDbService.getTables();
		let totalSize: number = 0;
		let contents: Array<Content> = [];

		for (let index = 0; index < tablesObject?.length; index++) {
			const store = tablesObject[index];
			let tableName = store?.name;
			let keys = await this.indexedDbService.getKeys(tableName);

			if (keys && keys.length > 0) {
				for (let index = 0; index < keys.length; index++) {
					const key = keys[index];
					let data = await this.indexedDbService.get(tableName, key);
					contents.push(
						{
							content: null,
							name: key,
							size: JSON.stringify(data).length
						});

					totalSize = totalSize + JSON.stringify(data).length;
				}
			}
		}

		return { contents: contents, totalSize: totalSize };
	}

	public async setStorageAlertCookie() {
		const ttl = new Date();
		ttl.setTime(new Date().getTime() + 10 * 60 * 1000); // add 10 minutes
		const useSecure = environment.production;
		this.cookieService.set('storageAlert', 'activeStorageAlert', ttl, '/', this.getHostname, useSecure, "Lax");
	}

	private get getHostname() {
		return window.location.hostname == "localhost" ? "" : window.location.hostname;
	}

}

export class TableContent {
	public table: Table;
	public keys: Array<Content>
	public currentKey?: Content
}

export class Content {
	public name: string;
	public content: any
	public size: number
}

export class ContentSummary {
	public contents: Array<Content>
	public totalSize: number
}
