import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { UserTypeConstant } from '@shared/user-type-constants';
import { WebResourceConstants } from '@shared/webresource-constants';
import { PermissionConstants } from "../shared/permission-constants";
import { OrderIdsModel } from './orderIds-model';

export class UserDataModel {

	Uuid: string;
	RecordId: string;
	Name: string;
	Alias: string;
	CustomerName: string;
	CustomerNo: string;
	UserPermissions: Array<PermissionConstants> = null;
	ProductLinesPermissions: Array<string> = null;
	WebResources: Array<string> = null;
	ThreeDeeProductLines: Array<string> = null;
	Assortments: Array<string> = [];
	Type: UserTypeConstant;
	OrderIds: Array<OrderIdsModel>;
	LoginRegistered: Date;
	Active: boolean;
	AdminOnChain: boolean = false;
	LastVersionCheck: number = Date.now();
	DeliveryEmail: string;
	OrderingOnBehalfOfNewPartner: boolean = false;
	IsPreciousPartner: boolean = false
	IsLiteLogin: boolean = false;
	PriceSettingAllowed: PriceSettingEnum;
	PriceSettingDisplay: PriceSettingEnum;
	IsDistributor: boolean = false;
	CustomerCountryCodes: Array<string> = [];
	AliasImitator: string;

	public get hasSkuConsult() {
		return this.WebResources.some(x => x == WebResourceConstants.SkuConsult);
	}

	public get hasMandatoryClientInfo() {
		return this.WebResources.some(x => x == WebResourceConstants.MandatoryClientInfo);
	}

	public get canOrderOnBehalf() {
		return this.UserPermissions?.some(x => x == PermissionConstants.CanOrderOnBehalf);
	}

	public get canOrderOnBehalfOfChainCustomer(): boolean {
		return this.UserPermissions?.some(x => x == PermissionConstants.CanOrderOnBehalfOfChainCustomer);
	}

	public get canEditConfigs() {
		return this.UserPermissions?.some(x => x == PermissionConstants.CanEditManualConfigurations);
	}

	public get isAdmin(): boolean {
		return this.UserPermissions?.some(x => x == PermissionConstants.IsAdmin);
	}

	public get canUseNonBlockingOfflineAddToBag() {
		return this.UserPermissions?.some(x => x == PermissionConstants.CanUseNonBlockingOfflineAddToBag);
	}

	public get newCustomerModuleEnabled() {
		return this.UserPermissions?.some(x => x == PermissionConstants.NewCustomerModuleEnabled);
	}

	public get preciousModuleEnabled() {
		return this.UserPermissions?.some(x => x == PermissionConstants.PreciousModuleEnabled);
	}

	public get priceModuleEnabled() {
		return this.UserPermissions?.some(x => x == PermissionConstants.PriceModuleEnabled);
	}

	public canOrder(customerChosen: boolean): boolean {
		if (!this.UserPermissions.some(y => y == PermissionConstants.PlaceOrder) && customerChosen && !this.IsDistributor) {
			return false;
		}
		else if (!customerChosen && !this.IsDistributor && !this.UserPermissions.some(y => y == PermissionConstants.IsAdmin) && (this.Type === UserTypeConstant.Consultant || this.Type === UserTypeConstant.Employee)) {
			return false;
		}
		else if (this.UserPermissions.some(y => y == PermissionConstants.PlaceTestOrder || y == PermissionConstants.PlaceOrder) ||
			(this.UserPermissions.some(y => y == PermissionConstants.CanOrderOnBehalf || y === PermissionConstants.CanOrderOnBehalfOfChainCustomer) && customerChosen)
		) {
			return true;
		}

		return false;
	}

	public canOnlyTestOrder(customerChosen: boolean): boolean {
		if (this.UserPermissions.some(y => y == PermissionConstants.PlaceTestOrder) &&
			!this.UserPermissions.some(y => y == PermissionConstants.PlaceOrder)) {

			if (this.UserPermissions.some(z => z == PermissionConstants.CanOrderOnBehalf || z === PermissionConstants.CanOrderOnBehalfOfChainCustomer) && customerChosen) {
				return false;
			}

			return true;
		}

		return false;
	}

	hasAllProductlines(productLines: Array<string>): boolean {
		let result = true;

		productLines.forEach(p => {
			if (!this.ProductLinesPermissions.find(plp => plp == p)) {
				result = false;
			}
		});

		return result;
	}
}

export class SignalrUser {

	ConnectionId: string;
	Uuid: string;
	Name: string;
	Alias: string;
	CustomerName: string;
	CustomerNo: string;
	ConnectionStart: Date;
	LastActivity: Date;
	UserAgent: string;
	IpAddress: string;
	AppVersion: string;
	AliasImitator: string;
	UserType: UserTypeConstant;
	NotificationMessage: string;
	SendNotification: boolean;
	NotificationWasSent: boolean;

}
