import { PriceSettingEnum } from "@infrastructure/price-setting.enum";

export class PriceSettingModel {
    
    constructor(priceSetting: PriceSettingEnum, priceSettingDescription: string) {
        this.PriceSetting = priceSetting;      
        this.PriceSettingDescription = priceSettingDescription
    }
    PriceSetting: PriceSettingEnum;
    PriceSettingDescription: string;
}
