import ValidationError from "./validation-error";

export default class ValidationResult {
    private errorsArray = new Array<ValidationError>();
    public addError(error: ValidationError) {
        this.errorsArray.push(error);
    }
    public removeError(key: string) {
        this.errorsArray = this.errorsArray.filter(x => x.element != key);
    }

    public clearErrors() {
        this.errorsArray = new Array<ValidationError>();
    }
    public get isValid() {
        return !this.errorsArray.length;
    }

    public get errors(): Array<ValidationError> {
        return this.errorsArray;
    }
}
