import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CameraEnum } from '@infrastructure/camera.enum';
import { MathEnum } from '@infrastructure/math-state-enum';
import { OrderCanceledError } from '@infrastructure/order-canceled-error';
import { OrderItemAskForOrderIdError } from '@infrastructure/order-item-ask-for-order-id-error';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { SourceTypeEnum } from '@infrastructure/sourcetype.enum';
import { AddOrderItemResultModel } from '@models/add-order-item-result-model';
import { DataModel, FrameRequestFieldDataModel, PartsFieldDataModel } from '@models/api-models/FMResponseModel';
import { OrderItemModel } from '@models/order-item';
import { OrderItemState } from '@models/order-item-state';
import { TranslateService } from '@ngx-translate/core';
import { BagCountService } from '@services/bag-count.service';
import { EventsService } from '@services/events.service';
import { ImageService } from '@services/image.service';
import { OrderItemService } from '@services/order-item.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { OrderService } from '@services/order.service';
import { UserService } from '@services/user.service';
import { ModelsService } from '@services/v2/models.service';
import { AppState } from '@shared/app-state';
import { CustomizerStateConstants } from '@shared/customizer-state-constants';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { UserTypeConstant } from '@shared/user-type-constants';
import { PartAndPrice } from '../../../_models/PartAndPrice';

export class FrameRequestAndDataContainer {
	frame: FrameRequestFieldDataModel;
	parts: DataModel<PartsFieldDataModel>[];
}

@Component({
	selector: 'app-model-viewer',
	templateUrl: './model-viewer.component.html',
	styleUrls: ['./model-viewer.component.scss'],
	animations: [FadeInOutTransition(0.3, 500, 500, 'fadeInOutLowOpacity')
	]
})
export class ModelViewerComponent implements OnInit {

	public currentCustomizationState: CustomizerStateConstants;

	loading: boolean = false;

	frameRequestAndDataContainer: FrameRequestAndDataContainer;

	partsAndPrices: PartAndPrice[] = [];

	selectedParts: string[] = [];
	addedOverlay: string;
	showAddedOverlay: boolean;
	imageUrl: string;

	showPrices: boolean;
	loadingPrice: boolean = false;
	pricesLoaded: boolean;

	productDescription: string;
	engraving: string;

	constructor(
		public imageService: ImageService,
		private bagCountService: BagCountService,
		private modelsService: ModelsService,
		private events: EventsService,
		private orderService: OrderService,
		private orderOnBehalfService: OrderOnBehalfService,
		private router: Router,
		private userService: UserService,
		private translateService: TranslateService,
		private appState: AppState,
		private orderItemService: OrderItemService
	) {
		let cn = this.router.getCurrentNavigation();

		if (cn) {
			this.frameRequestAndDataContainer = JSON.parse(JSON.stringify(cn.extras.state.data));

			this.engraving = this.frameRequestAndDataContainer.frame.Engraving;

			let pd = this.frameRequestAndDataContainer.frame.ProductDescriptions.find(pd => pd.LanguageId === translateService.currentLang);

			if (pd) {
				this.productDescription = pd.Description;
			}

			this.partsAndPrices = this.frameRequestAndDataContainer.parts.sort((a, b) => a.FieldData.Sort - b.FieldData.Sort).map(p => new PartAndPrice(p.FieldData.PartType, p.FieldData.Engravable === 1));

			this.imageUrl = this.imageService.GetPersistedImageUrl(this.frameRequestAndDataContainer.frame.ConfigUUID, SourceTypeEnum.ORDERS, CameraEnum.PERSPECTIVE);
		}

		let x = this.appState.priceSetting.getValue();
		this.showPrices = x.PriceSetting === PriceSettingEnum.AllowSuggestedRetailPrice;

		if (this.showPrices) {
			this.getPrice();
		}

		this.orderOnBehalfService.customerAsObservable().subscribe(c => this.getPrice());
	}

	ngOnInit(): void {
		if (!this.frameRequestAndDataContainer) {
			this.router.navigateByUrl('parts');
		}
	}

	togglePart(part: any) {
		let result: string[] = [];

		let existing = this.selectedParts.findIndex(p => p === part);

		if (existing > -1) {
			result = this.selectedParts.slice(0, existing).concat(this.selectedParts.slice(existing + 1));
		}
		else {
			this.selectedParts.push(part);
			result = this.selectedParts;
		}

		this.selectedParts = result;
	}

	disableCheckbox(part: PartAndPrice) {

		const pairSelected = this.selectedParts.some(selected => selected.includes('Temple_P'));

		const leftOrRightSelected = this.selectedParts.some(selected => selected.includes('_L') || selected.includes('_R'));

		const leftOrRightPart = part.name.includes('_L') || this.selectedParts.includes('_R');
		const pairPart = part.name.includes('Temple_P');

		if ((pairSelected && leftOrRightPart) || (leftOrRightSelected && pairPart)) {
			return true
		}

		return false
	}

	showEngaving(part: PartAndPrice) {

		const isSelectedPartChecked = this.selectedParts.find(x => x === part.name)
		return part.engravable && isSelectedPartChecked
	}

	async addToBag() {
		let tempUser = await this.userService.getUserData();
		let tempModelGroup = await this.modelsService.getModelGroupFromName(this.frameRequestAndDataContainer.frame.ProductLine);
		let tempModel = await this.modelsService.getModelFromName(tempUser, tempModelGroup, this.frameRequestAndDataContainer.frame.ModelId);

		// populate minimum required properties
		let orderItemState = new OrderItemState();
		orderItemState.Amount = 1;
		orderItemState.SerialNumber = this.frameRequestAndDataContainer.frame.SerialNumber;
		orderItemState.SkipUserCheck = true;
		orderItemState.Engraving = this.engraving;

		if (tempModelGroup) {
			orderItemState.ModelGroupCode = tempModelGroup.Code;
			orderItemState.ModelGroupDescription = tempModelGroup.Description.Fallback;
			orderItemState.ProductLineCode = tempModelGroup.ProductLineCode;
		}
		else {
			orderItemState.ModelGroupCode = '0';
			orderItemState.ModelGroupDescription = '0';
			orderItemState.ProductLineCode = '0';
		}

		if (tempModel) {
			orderItemState.ModelCode = tempModel.Code;
			orderItemState.ModelConfigurationCode = tempModel.DefaultModelConfigurationCode;
			orderItemState.ModelSizeCode = tempModel.ModelSizeCodeModels.find(mscm => mscm.Default).ModelsizeCode;
		}
		else {
			orderItemState.ModelCode = this.frameRequestAndDataContainer.frame.ModelId;
			orderItemState.ModelSizeCode = '0#0';
			orderItemState.ModelConfigurationCode = '0';
		}

		const orderItem = new OrderItemModel(orderItemState)

		for (let part of this.selectedParts) {
			orderItem.Parts = part;

			try {
				const result = await this.orderService.AddFramesToOrder(orderItem, false, true);
				this.bagCountService.countUpdate(1, MathEnum.Addition, true);
				this.setAddedToBag(result);
			}
			catch (e) {
				if (e instanceof OrderCanceledError) {
				}
				else if (e instanceof OrderItemAskForOrderIdError) {
				}
				else {
					throw e;
				}
			}
			finally {
				this.events.showLoader(false);
			}
		};
	}

	public async setAddedToBag(result: AddOrderItemResultModel) {
		this.currentCustomizationState = CustomizerStateConstants.ADDEDORDER;
		const customer = this.orderOnBehalfService.customerSnapshot();
		const user = await this.userService.GetUser();
		const isAddingToTestBag = !customer && user.canOrderOnBehalf && user.Type === UserTypeConstant.Consultant;

		let addedToBagText;

		if (isAddingToTestBag) {
			addedToBagText = this.translateService.instant(`CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDTO`);
			addedToBagText += "<span class='test-bag-warning'>" + this.translateService.instant(`CUSTOMIZER.COMPONENTS.CONFIGURATION.YOURTESTBAG`, { count: 1 }) + "</span>"
		}
		else {
			addedToBagText = this.translateService.instant(`CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDFRAMETOBAG`, { count: 1 });
		}

		// if (result.startkitAdded) { // we now add startkit when placing order
		// 	addedToBagText += "<br />" + this.translateService.instant(`CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDSTARTKITTOBAG`, { count: 1 });
		// }

		if (customer) {
			addedToBagText += "<br /><b>" + customer.Name + "</b>";
		}

		this.triggerOverlay(addedToBagText);
	}

	public triggerOverlay(text: string) {
		this.addedOverlay = text;
		this.showAddedOverlay = true;
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		new Promise(resolve => setTimeout(resolve, 3000)).then(x => {
			this.showAddedOverlay = false;
		});
	}

	public async getPrice() {
		if (this.frameRequestAndDataContainer && this.frameRequestAndDataContainer.frame) {
			this.pricesLoaded = false;

			this.loadingPrice = true;

			let orderItemState = new OrderItemState();
			orderItemState.ModelCode = this.frameRequestAndDataContainer.frame.ModelId;
			orderItemState.ModelConfigurationCode = 'XYZ';
			orderItemState.ModelGroupCode = 'XYZ';
			orderItemState.ModelGroupDescription = 'XYZ';
			orderItemState.ModelSizeCode = '0#0';
			orderItemState.ProductLineCode = this.frameRequestAndDataContainer.frame.ProductLine;
			orderItemState.SerialNumber = this.frameRequestAndDataContainer.frame.SerialNumber;
			orderItemState.SkipUserCheck = true;
			orderItemState.IsFullFrame = false;

			const orderItem: OrderItemModel = new OrderItemModel(orderItemState);

			let priceResponse = await this.orderItemService.getPriceFromConfiguration(orderItem);

			for (let response of priceResponse.Data.ResponseItems) {
				let pnp = this.partsAndPrices.find(p => p.name === response.Part)

				if (pnp) {
					pnp.priceItem = response;
				}
			}

			this.loadingPrice = false;

			this.pricesLoaded = true;
		}
	}

}
