import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TagModel } from '../_models/tag';
import { SessionService } from './session.service';

@Injectable()
export class TagsService implements OnDestroy {

	private showSubject: BehaviorSubject<boolean>;
	private updater: BehaviorSubject<boolean>;

	private tags: BehaviorSubject<Array<TagModel>>;
	private hboxFrom: BehaviorSubject<number>;
	private hboxTo: BehaviorSubject<number>;

	private readonly hboxFromStore = "tagsHboxFrom";
	private readonly hboxToStore = "tagsHboxTo";
	private readonly tagsStore = "tags";
	private readonly updaterStore = "tagsUpdater";
	private readonly showStore = "tagsShow";
	private readonly selectedTag = "selectedTag";

	private subscriptions: Array<Subscription> = [];

	public modalIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(public sessionService: SessionService) {
		this.tags = new BehaviorSubject<Array<TagModel>>(sessionService.get<TagModel[]>(this.tagsStore) || []);
		this.hboxFrom = new BehaviorSubject<number>(sessionService.get<number>(this.hboxFromStore));
		this.hboxTo = new BehaviorSubject<number>(sessionService.get<number>(this.hboxToStore));
		this.showSubject = new BehaviorSubject<boolean>(sessionService.get<boolean>(this.showStore) || false);
		this.updater = new BehaviorSubject<boolean>(sessionService.get<boolean>(this.updaterStore) || false);

		this.subscriptions.push(this.tags.subscribe(tags => {
			sessionService.set(this.tagsStore, tags);
		}));

		this.subscriptions.push(this.hboxFrom.subscribe(hboxFrom => {
			if (hboxFrom) {
				sessionService.set(this.hboxFromStore, hboxFrom);
			}
			else {
				sessionService.clear(this.hboxFromStore);
			}
		}));

		this.subscriptions.push(this.hboxTo.subscribe(hboxTo => {
			if (hboxTo) {
				sessionService.set(this.hboxToStore, hboxTo);
			}
			else {
				sessionService.clear(this.hboxToStore);
			}
		}));

		this.subscriptions.push(this.updater.subscribe(update => {
			sessionService.set(this.updaterStore, update);
		}));

		this.subscriptions.push(this.showSubject.subscribe(showSubject => {
			sessionService.set(this.showStore, showSubject);
		}));
	}

	public get SelectedTag(): TagModel {
		return this.sessionService.get<TagModel>(this.selectedTag);
	}

	public set SelectedTag(tag: TagModel) {
		this.sessionService.set(this.selectedTag, tag);
	}

	public updateObservable() {
		return this.updater.asObservable();
	}

	public triggerUpdate(update: boolean = true) {
		this.updater.next(update);
	}

	public addTag(tag: TagModel) {
		const data = this.tags.value;

		if (!data.some(x => x.Code == tag.Code)) {
			data.push(tag);
			this.tags.next(data);
		}
	}

	public setTags(tags: Array<TagModel>) {
		this.tags.next(tags);
	}

	public removeTag(id: number) {
		this.tags.next(this.tags.value.filter(x => x.Id != id));
	}

	public clearTags() {
		this.tags.next([]);
		this.setHboxFrom(null);
		this.setHboxTo(null);
	}

	public getTagsSnapshot() {
		return this.tags.value;
	}

	public showTags(show: boolean) {
		this.showSubject.next(show);
	}

	public showTagsSnapshot() {
		return this.showSubject.value;
	}

	public shouldShowObservable() {
		return this.showSubject.asObservable();
	}

	public tagsObservable(): Observable<TagModel[]> {
		return this.tags.asObservable();
	}

	public setHboxFrom(hbox: number) {
		this.hboxFrom.next(hbox);
	}

	public setHboxTo(hbox: number) {
		this.hboxTo.next(hbox);
	}

	public getHboxFromSnapshot() {
		return this.hboxFrom.value;
	}

	public getHboxToSnapshot() {
		return this.hboxTo.value;
	}

	public HboxFromObservable() {
		return this.hboxFrom.asObservable();
	}

	public HboxToObservable() {
		return this.hboxTo.asObservable();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(sub => {
			sub.unsubscribe();
		});
	}

}
