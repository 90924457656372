import { Component, OnInit } from '@angular/core';
import { BaseModal } from '@modals/base-modal';
import { OfflinePage } from '@models/offline-page';
import { ImageService } from '@services/image.service';
import { PreloadService } from '@services/preload.service';
import { UserService } from '@services/user.service';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';

@Component({
	selector: 'load-data-modal',
	styleUrls: ['./load-data.component.scss'],
	templateUrl: 'load-data.component.html'
})
export class LoadDataComponent extends BaseModal implements OnInit {

	public currentPage: number = 0;
	public offlineTourPages: Array<OfflinePage> = [];

	constructor(
		breadCrumbService: BreadcrumbService,
		public preloadService: PreloadService,
		private imageService: ImageService,
		private userService: UserService
	) {
		super(breadCrumbService);
	}

	async ngOnInit() {
		this.buildTourPages();

		await this.preloadService.loadData();
	}

	private buildTourPages() {
		const pages1: Array<OfflinePage> = [
			new OfflinePage('now downloading offline content...', 'click next to learn about available offline features', []),
			new OfflinePage('what is available offline?', 'you can customise all models',
				['/assets/offline/offline-page2-image1.png',
					'/assets/offline/offline-page2-image2.png',
					'/assets/offline/offline-page2-image3.png'])
		];

		if (this.userService.UserCanUseNonBlockingOfflineAddTobag()) {
			pages1.push(new OfflinePage('what is available offline?', 'you can add items to the bag, and you can view the contents of your bag',
				['/assets/offline/offline-page4-image1.png',
					'/assets/offline/offline-page4-image2.png']))
		} else {
			pages1.push(new OfflinePage('what is unavailable offline?', 'a network connection is required to add something to the bag and also to view the contents of your bag',
				['/assets/offline/offline-page4-image1.png',
					'/assets/offline/offline-page4-image2.png']))
		}

		const pages2: Array<OfflinePage> = [
			new OfflinePage('what is unavailable offline?', 'a network connection is required for the image to change',
				['/assets/offline/offline-page3-image1.png']),
			new OfflinePage('what is unavailable offline?', 'a network connection is required to use the search and filter functions',
				['/assets/offline/offline-page5-image1.png',
					this.imageService.GetInspirationGroupImages("filters")
				]),
			new OfflinePage('what is unavailable offline?', 'a network connection is required to browse frames from screenshow, monthly inspiration, featured on VM kit and my collection',
				[this.imageService.GetInspirationGroupImages("screenshows"),
				this.imageService.GetInspirationGroupImages("inspiration"),
				this.imageService.GetInspirationGroupImages("campaign"),
				this.imageService.GetInspirationGroupImages("favorites")
				]),
			new OfflinePage('how long can I be offline?', 'to guarantee availability of our products, downloaded offline content expires after some time. expired offline content must be updated by downloading offline content again. you will be alerted when new content is available. after new content is available, you have 48 hours to update your offline content. if you do not download new offline content, the customiser will return to its normal online state, and you will lose your offline capabilities until you download offline content again.', [])
		];

		this.offlineTourPages.push(...pages1.concat(pages2));
	}

}
