import { Component, Input } from '@angular/core';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { IconClickTransition } from '@shared/transitions/icon-click.transition';

@Component({
	selector: 'icon-bag',
	styleUrls: ['./icon-bag.component.scss'],
	animations: [IconClickTransition()],
	template:
		`
			<ng-container [ngSwitch]="syncState">
				<i *ngSwitchCase="syncStateEnum.SyncedWithDatabase" class="fa-light fa-bag-shopping fa-xl"></i>
				<i *ngSwitchCase="syncStateEnum.Pending" class="fa-light fa-rotate fa-xl fa-spin"></i>
				<i *ngSwitchCase="syncStateEnum.Running" class="fa-light fa-rotate fa-xl fa-spin"></i>
				<i *ngSwitchCase="syncStateEnum.Failed" class="fa-light fa-rotate-exclamation fa-xl"></i>
			</ng-container>
		`
})
export class IconBagComponent {

	syncStateEnum = SyncStateEnum;

	@Input() clickCount: number;
	@Input() isSyncing: boolean;
	@Input() syncState: SyncStateEnum;

}
