import { LanguageService } from '@services/language.service';

export class TempleInclination {
    value: number;
    inclination: string;
}

export enum TempleInclinationEnum {
    standard = 0,
    low = 1
}

export function templeInclinationToSelectList(languageService: LanguageService): Array<TempleInclination> {
    const templeInclinationList: Array<TempleInclination> = [];

    for (const value in Object.keys(TempleInclinationEnum)) {
        if (TempleInclinationEnum.hasOwnProperty(value)) {
            const valueAsNumber = Number(value);
            templeInclinationList.push({
                inclination: getTempleInclinationEnumTranslation(valueAsNumber, languageService),
                value: Number(valueAsNumber)
            });
          }
    }

    return templeInclinationList;
}

export function getTempleInclinationEnumTranslation(templeInclination: TempleInclinationEnum, languageService: LanguageService): string {
    switch (templeInclination) {
        case TempleInclinationEnum.standard:
            return languageService.instant("BAG.TEMPLE.STANDARD");
        case TempleInclinationEnum.low:
            return languageService.instant("BAG.TEMPLE.LOW");
        default:
            return "";
    }
}