import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import "./extensions/array-extensions";
import "./extensions/date-extensions";
import "./extensions/string-extensions";

if (environment.production) {
	enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

const setup: (ref: NgModuleRef<AppModule>) => void = (ref => {
	if (!environment.production) { return; }
});

bootstrap().then(x => setup(x)).catch(err => console.debug(err));
