import { IValueTag } from './value-tag.interface';

export abstract class DataTag<T> implements IValueTag<T> {
    private data: T;
    abstract readonly name: string;

    constructor() {
    }

    getData(): T {
        return this.data;
    }

    setData(data: T) {
        this.data = data;
    }
}
