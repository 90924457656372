import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ColorModel } from 'src/_models/color';
import { ComponentBindingModel } from 'src/_models/component-binding';
import { ComponentDependencyModel } from 'src/_models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { ColorJunctionConstants } from "../../../shared/dependency-constants";
import { MenuConstants } from "../../../shared/menu-constants";
import { IDependencyCalculator } from "../dependency-calculator.interface";

export class ColorJunctionDependencyCalculator implements IDependencyCalculator {

	constructor(
		private getChooseableVariant: (componentUuid: string, variantUuid: string, variantSizeUuid: string) => Promise<Array<ComponentVariantFromApiModel>>
	) { }

	async getChanges(dependency: ComponentDependencyModel, bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel, oppositChosenVariants: Array<ComponentVariantModel>): Promise<Array<ComponentVariantChangeModel>> {
		const changePromises: Array<ComponentVariantChangeModel> = [];

		// THIS WORKS - BUT ISN'T USED :(

		// for (let oppositChosenVariant of oppositChosenVariants) {
		// 	if (dependency.ColorJunction !== ColorJunctionConstants.All) {
		// 		switch (dependency.ColorJunction) {
		// 			case ColorJunctionConstants.Color:
		// 				changePromises.push(await this.colorJunctionColor(variant, oppositChosenVariant));
		// 				break;
		// 			case ColorJunctionConstants.ColorGroup:
		// 				changePromises.push(await this.colorJunctionColorGroup(variant, oppositChosenVariant));
		// 				break;
		// 		}
		// 	}
		// }

		// THIS DOESN'T WORK - BUT IS USED :(

		oppositChosenVariants.forEach(async (oppositChosenVariant) => {
			// Then go through each opposite variant and check if it matches colorjunction
			if (dependency.ColorJunction !== ColorJunctionConstants.All) {
				switch (dependency.ColorJunction) {
					case ColorJunctionConstants.Color:
						changePromises.push(await this.colorJunctionColor(variant, oppositChosenVariant));
						break;
					case ColorJunctionConstants.ColorGroup:
						changePromises.push(await this.colorJunctionColorGroup(variant, oppositChosenVariant));
						break;
				}
			}
		});

		return changePromises;
	}

	private async colorJunctionColor(variant: ComponentVariantModel, oppositChosenVariant: ComponentVariantModel): Promise<ComponentVariantChangeModel> {
		if (variant.VariantColor.Color.Id != oppositChosenVariant.VariantColor.Color.Id) {
			console.debug("ColorJunction 'Color' encountered:", variant, oppositChosenVariant);
			const x = await this.findColorReplacement(oppositChosenVariant, variant.VariantColor.Color);
			console.debug("Replacement found:", x);
			const result = new ComponentVariantChangeModel(oppositChosenVariant, x, null);
			result.MenuTypesWithWarning.push(MenuConstants.COLOR);
			return result;
		} else {
			return null;
		}
	}

	private async colorJunctionColorGroup(variant: ComponentVariantModel, oppositChosenVariant: ComponentVariantModel) {
		if (
			variant &&
			variant.VariantColor &&
			variant.VariantColor.Color &&
			variant.VariantColor.Color.ColorGroup &&
			variant.VariantColor.Color.ColorGroup.Id &&
			oppositChosenVariant &&
			oppositChosenVariant.VariantColor &&
			oppositChosenVariant.VariantColor.Color &&
			oppositChosenVariant.VariantColor.Color.ColorGroup &&
			oppositChosenVariant.VariantColor.Color.ColorGroup.Id &&
			variant.VariantColor.Color.ColorGroup.Id != oppositChosenVariant.VariantColor.Color.ColorGroup.Id
		) {
			console.debug("ColorJunction 'ColorGroup' encountered:", variant, oppositChosenVariant);

			const colorGroupReplacement = await this.findColorGroupReplacement(oppositChosenVariant, variant);

			console.debug("Replacement found:", colorGroupReplacement);

			const result = new ComponentVariantChangeModel(oppositChosenVariant, colorGroupReplacement, null);
			result.MenuTypesWithWarning.push(MenuConstants.COLOR);

			return result;
		}

		return null;
	}

	private async findColorReplacement(variant: ComponentVariantModel, color: ColorModel): Promise<ComponentVariantModel> {
		const x = await this.getChooseableVariant(variant.Component.Uuid, variant.Uuid, variant.VariantSize.Uuid);
		const result = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
		result.VariantColor = x[0].FindColorReplacement(color);
		return result;
	}

	private async findColorGroupReplacement(variant: ComponentVariantModel, opposite: ComponentVariantModel) {
		const x = await this.getChooseableVariant(variant.Component.Uuid, variant.Uuid, variant.VariantSize.Uuid);
		const result = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);

		result.VariantColor = x[0].FindColorGroupReplacement(variant, opposite);

		return result;
	}

}
