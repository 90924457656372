import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandModeService } from '@services/brandmode.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { OrdersDataService } from '@services/orders-data.service';
import { ModelsService } from '@services/v2/models.service';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { Observable } from 'rxjs';
import { ConfigurationMode, ImageService } from 'src/_services/image.service';
import { LoginService } from 'src/_services/login.service';
import { TitleService } from 'src/_services/title.service';
import { DisplayEnduserModalComponent } from '../../../_modals/display-enduser/display-enduser.component';
import { MiscModel } from '../../../_models/misc-model';
import { MiscModelCategory } from '../../../_models/misc-model-category';
import { MiscModelGroup } from '../../../_models/misc-model-group';
import { ModalService } from '../../../_services/modal.service';
import { NavigateService } from '../../../_services/navigate.service';
import { SorterService } from '../../../_services/sorter.service';
import { BasePage } from '../../../infrastructure/base-page';
import { EnduserDisplayService } from '@services/enduserdisplay.service';

@Component({
	templateUrl: "misc-group.component.html",
	styleUrls: ['./misc-group.component.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class MiscGroupPageComponent extends BasePage implements OnInit {

	public chosenMiscModel: MiscModelGroup;
	public chosenMiscCategory: MiscModelCategory;
	public miscModels: Array<MiscModel>;
	public enduserDisplayClickedObserveable: Observable<ConfigurationMode>;
	public displayEnduserModalComponent: any = DisplayEnduserModalComponent;
	public hasModelsLoaded: boolean = false;
	category: string;
	group: string;

	constructor(
		navigateService: NavigateService,
		loginService: LoginService,
		titleService: TitleService,
		modalService: ModalService,
		private modelsService: ModelsService,
		public imageService: ImageService,
		private sorterService: SorterService,
		private router: Router,
		private brandModeService: BrandModeService,
		private enduserDisplayService: EnduserDisplayService,
		private orderDataService: OrdersDataService,
		private orderOnBehalfService: OrderOnBehalfService,
		private activatedRoute: ActivatedRoute
	) {
		super(loginService, titleService, modalService, navigateService);
	}

	async ngOnInit() {
		this.enduserDisplayClickedObserveable = this.enduserDisplayService.enduserDisplayClickedObservable();

		// console.log(this.router.routerState.snapshot);

		let segments = this.router.routerState.snapshot.url.split('/');

		if (segments.length === 3) {
			this.router.navigate(['tools']);
		}
		else {
			this.category = decodeURI(segments[2]);
			this.group = decodeURI(segments[4]);
	
			if (this.category === '' && this.group === '') {
				this.router.navigate(['/']);
				return;
			}
	
			this.titleService.setTitle(this.group);
	
			await this.getMiscModels();
		}


		// this.activatedRoute.url.subscribe(async s => {
		// 	if (s.length === 4) {
		// 		this.category = s[1].path;
		// 		this.group = s[3].path;

		// 		if (this.category === '' && this.group === '') {
		// 			this.router.navigate(['/']);
		// 			return;
		// 		}

		// 		this.titleService.setTitle(this.group);

		// 		await this.getMiscModels();
		// 	}
		// });
	}

	async getMiscModels() {
		const customerNo = await this.orderOnBehalfService.getCustomerOrUserNo();
		const ordersInBag = await this.orderDataService.getOrdersData(customerNo)
		const miscModels = await this.modelsService.getMiscModels();

		let locationCodeFilter = this.brandModeService.isPrecious ? "WEBP" : "WEB";

		this.miscModels = miscModels
			.filter(item =>
				// item.ItemCategoryDescription.toLowerCase() == this.category &&
				item.ItemGroupDescription.toLowerCase() == this.group &&
				item.LocationCode === locationCodeFilter)
			.sort((a, b) => this.sorterService.sortByMisc(a, b));

		if (ordersInBag.length > 0) {
			this.miscModels.forEach(miscModel => {
				ordersInBag.map(o => {
					miscModel.ItemExistsInBag = o.OrderItemMiscs.findIndex(i => i.EcommerceNoUnique === miscModel.EcommerceNoUnique) > -1;
				});
			});
		}
		else {
			this.miscModels.forEach(miscModel => {
				miscModel.ItemExistsInBag = false;
			});
		}

		this.hasModelsLoaded = true;
	}

}
