import { OrderStatus } from "./order-status";

export class OrderInfoModel {
    CustomerNo: string;
    CustomerName: string;
    CustomerEmail: string;
    ConsultantUuid: string | null;
    Alias: string;
    Address: string;
    LastChangedAt: Date | string;
    OrderInfos: OrderInfo[];
}

export class OrderInfo {
    Id: number;
    Uuid: string;
    CreatedAt: Date | string;
    LastChangedAt: Date | string;
    OrderItemsCount: number;
    OrderItemMiscsCount: number;
    Status: OrderStatus;
    Notes: string;
    NotesCustomerService: string;
    ShipToId: number | null;
    AxId: number | null;
    EarliestDeliveryDate: Date | string | null;
    VisitDate: Date | string | null;
    FairId: number;
    FairName: string;
    AllowPartialShipment: boolean;
    PaymentTerms: string;
    CompletedDate: Date | string | null;
    NoVisit: boolean;
    Caution: string;
}
