import { Component, SimpleChanges } from '@angular/core';

import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { VariantColorModel } from 'src/_models/variant-color';
import { EventsService } from 'src/_services/events.service';
import { DataApiService } from '../../../../_services/data-api.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'cstmsr-frame-color',
	template: `
    <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
        <customizer-colors-auto-update *ngIf="selectableVariants.length"
            [selected]="getSelectedVariant.VariantColor"
            [selectedColorGroup]="currentColorGroup"
            [variants]="selectableVariants"
            [editMode]="state === customizerStateEnum.EDITORDER"
            [matchableColors]="matchableColors"
            [useColorGroup]="menu.contentGrouping"
            (colorMatchChange)="matchColors($event)"
            (selectedColorGroupChange)="onSelectGroup($event)"
            (selectedColorChange)="onSelect($event)"
            [(scrollPosition)]="menu.menuDetails.scrollPosition"
            [variantOrModelIsNew]="variantOrModelIsNew"
            [matchColor]="menu.matchColor"
            >
        </customizer-colors-auto-update>
    </customizer-component-header>
    <precious-metal-info></precious-metal-info>
    `
})
export class FrameColorComponent extends BaseCustomizerComponent {

	selectableVariants: Array<ComponentVariantFromApiModel> = [];

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		this.selectableVariants = await this.fetchData();

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
		this.setImageSliderByName(AngleConstants.Perspective);
	}

	async fetchData(): Promise<Array<ComponentVariantFromApiModel>> {

		const variants = this.getCurrentVariants;
		let variantsFromApi = new Array<ComponentVariantFromApiModel>();

		for (const variant of variants) {
			const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid, variant.VariantSize.Uuid);
			variant.VariantColorViewed = true;
			variantsFromApi = variantsFromApi.concat(apidata);
		}

		await this.RunDisablingSystem(variantsFromApi);

		return variantsFromApi;
	}

	onSelect(color: VariantColorModel) {
		this.events.showLoader(true);

		this.getCurrentVariants.forEach(variant => {
			const currentVariants = this.selectableVariants.find(x => x.Code == variant.Code);
			const selectedColor = currentVariants.VariantSizes[0].VariantColors.find(x => x.ColorUuid == color.ColorUuid);

			const newVariant = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
			const oldVariant = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
			newVariant.VariantColor = selectedColor;
			this.updateVariant(new ComponentVariantChangeModel(oldVariant, newVariant, null));
		});

	}

}
