import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModelInformationComponent } from './model-infomation-component';
import { ModelItemComponent } from './model-item/model-item.component';

@NgModule({
    declarations: [
        ModelItemComponent,
        ModelInformationComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        SharedModule
    ],
    providers: [
    ],
    bootstrap: [
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    exports: [
        ModelItemComponent,
        ModelInformationComponent
    ]
})
export class ModelInformationModule { }
