import { Type } from "class-transformer";
import { ColorGroupModel } from './color-group';
import { ColorLineModel } from './color-line';
import { ColorTypeModel } from './color-type';
import { DataTranslationModel } from './data-translation-model';

export class ColorModel {
    constructor()
    {
    }
    Id: number;
    Uuid: string;
    Code: string;

    @Type(() => DataTranslationModel)
    Description: DataTranslationModel;

    @Type(() => DataTranslationModel)
    Description2: DataTranslationModel;
    Status: number;

    @Type(() => ColorLineModel)
    ColorLine: ColorLineModel;

    @Type(() => ColorGroupModel)
    ColorGroup: ColorGroupModel;

    @Type(() => ColorTypeModel)
    ColorType: ColorTypeModel;

    public get LastSplitColor(): string {
        const split = this.Code.split('/');
        return split[split.length - 1];
    }

    public get FirstSplitColor(): string {
        const split = this.Code.split('/');
        return split[0];
    }

    public get IsSplit(): boolean {
      return this.Code.includes('/');
    }

    public IsAlike(otherColor: ColorModel): boolean {
        if(this.Code == otherColor.Code ||
        this.LastSplitColor == otherColor.LastSplitColor ||
        this.FirstSplitColor == otherColor.FirstSplitColor ||
        this.LastSplitColor == otherColor.FirstSplitColor ||
        this.FirstSplitColor == otherColor.LastSplitColor) {
            return true;
        } else {
            return false;
        }
    }

    public static createCopy(fromModel: ColorModel) : ColorModel {
        if(fromModel) {
            const newColor = new ColorModel();
            newColor.Id = fromModel.Id;
            newColor.Uuid = fromModel.Uuid;
            newColor.Code = fromModel.Code;
            newColor.Status = fromModel.Status;
            newColor.Description = new DataTranslationModel(fromModel.Description.TranslationKey, fromModel.Description.Fallback);
            newColor.Description2 = new DataTranslationModel(fromModel.Description2.TranslationKey, fromModel.Description2.Fallback);

            newColor.ColorLine = ColorLineModel.createCopy(fromModel.ColorLine);
            newColor.ColorGroup = ColorGroupModel.createCopy(fromModel.ColorGroup);
            newColor.ColorType = ColorTypeModel.createCopy(fromModel.ColorType);

            return newColor;
        }
        else {
            return null;
        }
    }
}
