import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconBagComponent } from './components/icon-bag/icon-bag.component';
import { IconClipOnCaseComponent } from './components/icon-clip-on-case.component';
import { IconFormsComponent } from './components/icon-forms/icon-forms.component';
import { IconInfoComponent } from './components/icon-info.component';
import { IconLogOutComponent } from './components/icon-log-out/icon-log-out.component';
import { IconOptionsDropdownComponent } from './components/icon-options-dropdown/icon-options-dropdown.component';
import { IconSearchComponent } from './components/icon-search/icon-search.component';
import { IconTagFilterComponent } from './components/icon-tag-filter/icon-tag-filter';
import { IconFavoritComponent } from './components/icon-favorite/icon-favorite.component';
import { IconFavoritEmptyComponent } from './components/icon-favorite-empty/icon-favorite-empty.component';
import { IconArrowLeftComponent } from './components/icon-arrow-left/icon-arrow-left.component';
import { IconConsultantFavoriteComponent } from './components/icon-consultant-favorite/icon-consultant-favorite.component';
import { IconSyncComponent } from './components/icon-sync/icon-sync.component';
import { IconBagSyncActiveComponent } from './components/icon-bag-sync/icon-bag-sync-active.component';
import { IconBagSyncFailedComponent } from './components/icon-bag-sync/icon-bag-sync-failed.component';
import { IconNotificationComponent } from './components/icon-notification/icon-notification';
import { IconMyCollectionsComponent } from './components/icon-my-collections/icon-my-collections.component';


@NgModule({
  declarations: [
    IconClipOnCaseComponent,
    IconSearchComponent,
    IconBagComponent,
    IconLogOutComponent,
    IconTagFilterComponent,
    IconOptionsDropdownComponent,
    IconInfoComponent,
    IconFormsComponent,
    IconFavoritComponent,
    IconFavoritEmptyComponent,
    IconArrowLeftComponent,
    IconConsultantFavoriteComponent,
    IconSyncComponent,
    IconBagSyncActiveComponent,
    IconBagSyncFailedComponent,
    IconNotificationComponent,
    IconMyCollectionsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    IconClipOnCaseComponent,
    IconSearchComponent,
    IconBagComponent,
    IconLogOutComponent,
    IconTagFilterComponent,
    IconOptionsDropdownComponent,
    IconInfoComponent,
    IconFormsComponent,
    IconFavoritComponent,
    IconFavoritEmptyComponent,
    IconArrowLeftComponent,
    IconConsultantFavoriteComponent,
    IconSyncComponent,
    IconBagSyncActiveComponent,
    IconBagSyncFailedComponent,
    IconNotificationComponent,
    IconMyCollectionsComponent
  ]
})
export class IconsModule { }
