import { Injectable } from '@angular/core';
import { BrandConstants, BrandEnum } from '@infrastructure/brand.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IndexDbService } from './index-db.service';
import { SessionService } from './session.service';
import { StoreService } from './store.service';
import { UserService } from './user.service';

@Injectable()
export class BrandModeService {

	private currentBrandMode: BehaviorSubject<BrandEnum>;
	private preciousModeClicked = new Subject<BrandEnum>();
	private readonly sessionName = "brandmode";
	public showPreciousTabs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public currentBrand: BehaviorSubject<BrandEnum> = new BehaviorSubject<BrandEnum>(BrandEnum.Titanium);
	public isTitanium: boolean = true;
	public isPrecious: boolean = false;
	private configurationsByModelSizeKey: string = `modelsize.configuration`;

	constructor(sessionService: SessionService,
		private userService: UserService,
		private indexedDbService: IndexDbService
	) {
		this.currentBrandMode = new BehaviorSubject<BrandEnum>(sessionService.get<BrandEnum>(this.sessionName) || BrandEnum.Titanium);

		this.currentBrandMode.subscribe((x) => {
			sessionService.set(this.sessionName, x);
			this.currentBrand.next(x as BrandEnum);
			this.isTitanium = (x as BrandEnum) == BrandEnum.Titanium;
			this.isPrecious = (x as BrandEnum) == BrandEnum.Precious;
		});

		this.userService.currentUser.subscribe(user => {
			const currentConfiguration = sessionService.get<BrandEnum>(this.sessionName);
			if (user) {
				this.showPreciousTabs.next(user.preciousModuleEnabled);
				if (!currentConfiguration) {
					this.setDefaultBrandMode();
				}
			}
		});
	}

	public brandModeChangedObservable(): Observable<BrandEnum> {
		return this.currentBrandMode.asObservable();
	}

	public preciousModeClickedObservable(): Observable<BrandEnum> {
		return this.preciousModeClicked.asObservable();
	}

	public toggleBrandMode(brand: BrandEnum = BrandEnum.Titanium): void {
		this.currentBrandMode.next(brand);
		this.resetConfigurations();
	}

	public setDefaultBrandMode(): void {
		this.currentBrandMode.next(BrandEnum.Titanium);
	}

	public currentBrandValue(): BrandEnum {
		return this.currentBrandMode.value;
	}

	public currentBrandStringValue(): string {
		return this.currentBrandMode.value === BrandEnum.Titanium ? BrandConstants.TITANIUM : BrandConstants.PRECIOUS;
	}

	public async resetConfigurations() {
		await this.indexedDbService.clear<any>(StoreService.storeName, this.configurationsByModelSizeKey);
	}

}
