export class LimitedEditionModel {

	id: number;
	uuid: string;
	description: string;
	code: string;

	totalNumber: number;
	availableNumber: number;

	images: ImageAndThumb[] = [];
	componentId: number;
	fullCode: string;
	componentTypeId: number;

	name: string;
	designDescription: string;
	numImages: number;

}

export class ImageAndThumb {

	isActive: string = 'inactive';

	constructor(public image: string, public thumbnail: string) { }

}
