import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { ManualConfigComparer } from "@infrastructure/manualConfig/manualConfigComparer";
import { OrderCanceledError } from '@infrastructure/order-canceled-error';
import { OrderItemAskForOrderIdError } from '@infrastructure/order-item-ask-for-order-id-error';
import { ComponentVariantModel } from '@models/component-variant';
import { CustomizerMenuPartModel } from '@models/customizer-menu-part';
import { CustomizerParams } from '@models/customizer-params';
import { CustomizerSummaryModel } from "@models/customizer-summary-model";
import { InspirationGroupModel } from '@models/inspiration-group';
import { ManualConfig } from '@models/manualconfig';
import { ModelGroupModel } from '@models/model-group';
import { OrderItemModel } from "@models/order-item";
import { StartKitModel } from '@models/start-kit-model';
import { UserDataModel } from '@models/user-data-model';
import { BrandModeService } from "@services/brandmode.service";
import { ConfigurationService } from '@services/configuration.service';
import { EnduserDisplayService } from '@services/enduserdisplay.service';
import { EventsService } from '@services/events.service';
import { Background, ImageService, ImageSize } from '@services/image.service';
import { ManualConfigService } from "@services/manual-config.service";
import { MenuService } from '@services/menu.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { OrderService } from '@services/order.service';
import { UserService } from "@services/user.service";
import { FeaturedGroupConstant } from '@shared/featured-group-constant';
import { PermissionConstants } from '@shared/permission-constants';
import { FadeInOutTransition } from "@shared/transitions/fade-in-out.transition";
import { UserTypeConstant } from "@shared/user-type-constants";
import { BehaviorSubject } from 'rxjs';
import { CustomizerSummaryModelFactory } from 'src/_factories/CustomizerSummaryModelFactory';

@Component({
	selector: "featured-model",
	templateUrl: 'featured-model.html',
	styleUrls: ['./featured-model.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class FeaturedModelComponent implements OnChanges {

	@Input() allManualConfigGroups: Array<InspirationGroupModel>;
	@Input() featuredConfiguration: ManualConfig;
	@Input() inspirationGroup: InspirationGroupModel;
	@Input() userPermissions: UserDataModel;
	@Input() hasInspirationEditPermission: boolean;
	@Input() isDisplay: boolean;
	@Input() showAsFrame: boolean = false;
	@Input() isSavingToBag: boolean = false;
	@Input() showAddedToBag: boolean = false;
	@Input() isAddingToTestBag: boolean = false;
	@Input() showStartKitAddedToBag: boolean = false;
	@Input() presentAsPrecious: boolean = false;
	@Output() hasBeenDeleted: EventEmitter<ManualConfig> = new EventEmitter();
	@Output() toCustomizer: EventEmitter<{ featuredConfiguration: ManualConfig, modelgroup: ModelGroupModel, customizerParams: CustomizerParams }> = new EventEmitter();
	@Output() public applyFavoriteChangeEmitter: EventEmitter<void> = new EventEmitter();
	@Output() public applyFavoriteCollectionChangeEmitter: EventEmitter<boolean> = new EventEmitter();

	public modelGroup: ModelGroupModel;
	public variants: Array<ComponentVariantModel> = [];
	public imageUrl: string;
	public imageUrlRimlessSide: string;
	public menuComponents: Array<CustomizerMenuPartModel>;
	public summaryModels: Array<CustomizerSummaryModel>;

	public hasStatus: boolean;
	public showNoStatus: boolean;
	public showDeletePrompt: boolean;
	public showMyCollectionsOverlay: boolean;
	public showStartKitModal: boolean;
	public showCustomizerParamsOverlay: boolean;
	public startkitChooserEmitter: EventEmitter<StartKitModel>;
	public featuredGroupConstant = FeaturedGroupConstant;

	public IsGlobal: boolean;
	public isReleased: boolean;
	public availableForDisplay: boolean;

	public showOrderChooserModal: boolean;

	public customizerParams: CustomizerParams = new CustomizerParams();

	public userCanAddParamsToCustomizer: boolean = false;
	public isReady: boolean;
	public options: Intl.DateTimeFormatOptions = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' };
	manualConfigComparer: ManualConfigComparer;

	constructor(private imageService: ImageService,
		private menuService: MenuService,
		private eventsService: EventsService,
		private enduserDisplayService: EnduserDisplayService,
		public orderOnBehalfService: OrderOnBehalfService,
		private userService: UserService,
		private addtobagService: OrderService,
		private manualConfigService: ManualConfigService,
		public brandModeService: BrandModeService,
		private configurationService: ConfigurationService
	) {
		this.manualConfigComparer = new ManualConfigComparer();
	}

	async ngOnChanges() {
		this.hasStatus = this.featuredConfiguration.Model.HasStatus(this.userPermissions);
		this.variants = this.featuredConfiguration.ManualConfigVariants.map(t => t.GetVariant());
		this.setAvailableForDisplay();
		this.modelGroup = this.featuredConfiguration.ModelGroup.ModelGroupChildren.length ? this.featuredConfiguration.ModelGroup.FullFrameChildModelGroup : this.featuredConfiguration.ModelGroup;
		await this.setMenuData();
		this.buildSummaryModels();

		this.IsGlobal = this.inspirationGroup?.Type != FeaturedGroupConstant.Favorite;

		this.isReleased = new Date() > this.featuredConfiguration.Release;

		this.setImageUrls();

		this.userCanAddParamsToCustomizer = this.userPermissions.UserPermissions.some(x => x == PermissionConstants.IsAdmin || x == PermissionConstants.CanOrderOnBehalf);

		this.isReady = true;
	}

	private async setMenuData() {
		const menus = await this.menuService.getMenu(this.modelGroup);
		this.menuComponents = this.menuService.ConvertMenus(menus);
	}

	private setImageUrls() {
		this.featuredConfiguration.Model.PresentAsPrecious = this.presentAsPrecious;
		if (this.showAsFrame) {
			if (this.modelGroup.IsRimless) {
				this.imageUrl = this.imageService.GetFrontImage(this.featuredConfiguration.Model, this.modelGroup.Code, this.featuredConfiguration.ModelConfiguration.ModelConfigurationCode, this.variants, ImageSize.Medium, Background.Default);
				this.imageUrlRimlessSide = this.imageService.GetSideImage(this.featuredConfiguration.Model, this.modelGroup.Code, this.featuredConfiguration.ModelConfiguration.ModelConfigurationCode, this.variants, ImageSize.Medium, Background.Default);
			} else {
				this.imageUrl = this.imageService.GetPerspectiveImage(this.featuredConfiguration.Model, this.modelGroup.Code, this.featuredConfiguration.ModelConfiguration.ModelConfigurationCode, this.variants, ImageSize.Medium, Background.Default);
			}
		} else {
			this.imageUrl = this.imageService.GetInspirationImage(this.inspirationGroup.ImageId, this.featuredConfiguration.ImageCode);
		}
	}

	private setAvailableForDisplay() {
		const sizes = this.variants.map(x => x.VariantSize);
		const colors = this.variants.map(x => x.VariantColor);
		this.availableForDisplay = this.variants.every(x => x.AvailableForDisplay) &&
			sizes.every(x => x.AvailableForDisplay) &&
			colors.every(x => x.AvailableForDisplay);
	}

	async removeInspirationConfiguration() {
		this.showDeletePrompt = true;
	}


	public favoriteDeleted() {
		if (this.inspirationGroup.Type == FeaturedGroupConstant.Favorite) {
			this.hasBeenDeleted.emit(this.featuredConfiguration);
		}
	}

	public deleteInspirationConfiguration() {
		this.eventsService.showLoader(true);

		this.manualConfigService.deleteInspirationConfigurations([this.featuredConfiguration]).then(() => {
			this.hasBeenDeleted.emit(this.featuredConfiguration);
			this.eventsService.showLoader(false);
		});

	}

	public async goToCustomise() {
		if (!this.hasStatus) {
			this.showNoStatusOverlay();
		} else {
			this.toCustomizer.emit({ featuredConfiguration: this.featuredConfiguration, modelgroup: this.modelGroup, customizerParams: this.customizerParams });
		}
	}

	public async addToBag(orderId: number = null) {
		const customer = this.orderOnBehalfService.customerSnapshot();
		const user = await this.userService.GetUser();

		if (!this.hasStatus) {
			this.showNoStatusOverlay();
		}
		else {
			let mcv = this.featuredConfiguration.ManualConfigVariants.find(mcv => mcv.ComponentVariantModel.Component.Code === 'TEMPLE');

			const modelId = this.featuredConfiguration.Model.Id;
			const modelSize = this.featuredConfiguration.ModelSize;
			const modelConfigUuid = this.featuredConfiguration.ModelConfiguration.ModelConfigurationUuid;
			const modelGroup = this.featuredConfiguration.ModelGroup;
			const componentId = mcv.ComponentVariantModel.Component.Uuid; // currentTemple?.Component?.Uuid
			const componentVariantUuid = mcv.ComponentVariantModel.Uuid // currentTemple?.Uuid;
			const componentSizeUuid = mcv.ComponentSizeModel.Uuid // currentTemple?.VariantSize?.Uuid;

			let availableCases = await this.configurationService.getCases(modelId, modelSize, modelConfigUuid, modelGroup, componentId, componentVariantUuid, componentSizeUuid);

			let chosenCase = availableCases?.find(t => t.Default)?.MiscModel ?? availableCases?.first()?.MiscModel;

			const orderItem = OrderItemModel.MapFromVariants(
				null,
				orderId,
				this.enduserDisplayService.IsDisplay(),
				1,
				"",
				"",
				"",
				this.featuredConfiguration.ModelConfiguration,
				this.modelGroup,
				this.featuredConfiguration.Model,
				this.featuredConfiguration.ModelSize,
				this.variants,
				this.customizerParams.hasFixtures,
				this.customizerParams.hasForms,
				false,
				null,
				null,
				this.featuredConfiguration.TempleInclination,
				this.featuredConfiguration.IsPrecious,
				null,
				null,
				null,
				chosenCase
			);

			this.addtobagService.AddFramesToOrder(orderItem, this.modelGroup.KidTeen, false, (x) => {
				this.showStartKitModal = true;
				this.startkitChooserEmitter = x;
			})
				.then((x) => {
					this.showStartKitAddedToBag = x.startkitAdded;
					this.resetCustomizerParams();
					this.isAddingToTestBag = !customer && user.canOrderOnBehalf && user.Type === UserTypeConstant.Consultant;
					this.showAddedToBagOverlay();
				})
				.catch((e) => {
					if (e instanceof OrderCanceledError) {
					}
					else if (e instanceof OrderItemAskForOrderIdError) {
						this.showOrderChooserModal = true;
					}
				})
				.finally(() => {
					this.eventsService.showLoader(false);
				});
		}
	}

	private buildSummaryModels() {
		this.summaryModels = CustomizerSummaryModelFactory.buildSummaryModels(this.variants);
	}

	public async addToBagOrderSelected(orderId: number) {
		this.showOrderChooserModal = false;
		if (orderId != null) {
			this.addToBag(orderId);
		}
	}

	public async cancelOrderChooser() {
		this.showOrderChooserModal = false;
	}

	public startKitChosen(startkit: StartKitModel) {
		this.showStartKitModal = false;
		this.startkitChooserEmitter.emit(startkit);
	}

	public cancelDeleteInspirationConfiguration() {
		this.showDeletePrompt = false;
	}

	public cancelFavorite() {
		this.showMyCollectionsOverlay = false;
	}
	public applyFavoriteChange(favoriteUuid: BehaviorSubject<string>) {
		this.featuredConfiguration.FavoriteUuid.next(favoriteUuid.getValue());
		this.applyFavoriteChangeEmitter.emit();
	}

	public showMyCollections() {
		this.showMyCollectionsOverlay = true;
	}

	public applyFavoriteCollectionChange(isInCollection: boolean) {
		this.featuredConfiguration.IsInCollection = isInCollection;
		this.applyFavoriteCollectionChangeEmitter.emit(isInCollection);
	}


	public applyRemovedFromFavoriteCollectionChange() {
		this.featuredConfiguration.HasBeenDeleted.next(true);
	}

	public canSetCustomizerParams(): boolean {
		if (this.userCanAddParamsToCustomizer) {
			if (this.modelGroup.HasFixtures || this.modelGroup.HasForms) {
				return true;
			}
		}
		return false;
	}

	public toggleCustomizerParamsOverlay() {
		if (this.userCanAddParamsToCustomizer) {
			this.showCustomizerParamsOverlay = !this.showCustomizerParamsOverlay;
			if (!this.showCustomizerParamsOverlay) {
				this.resetCustomizerParams();
			}
		}
	}

	private resetCustomizerParams() {
		this.showCustomizerParamsOverlay = false;
		this.customizerParams.hasForms = false;
		this.customizerParams.hasFixtures = false;
	}

	private showNoStatusOverlay() {
		this.showNoStatus = true;
		setTimeout(() => this.showNoStatus = false, 4500);
	}

	private showAddedToBagOverlay() {
		this.showAddedToBag = true;
		setTimeout(() => this.showAddedToBag = false, 2000);
	}
}
