import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SettingModel, SettingsService } from '@services/v2/settings.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

export const SettingsConstants = {
	OrderSynchronizerServiceEnabled: "OrderSynchronizerServiceEnabled"
}

@Component({
	selector: 'cstmsr-service-settings',
	standalone: true,
	imports: [MatSlideToggleModule, MatDialogModule],
	templateUrl: './service-settings.component.html',
	styleUrls: ['./service-settings.component.scss']
})
export class ServiceSettingsComponent implements OnInit {

	isOrderSynchronizerServiceEnabled: boolean;

	@ViewChild('serviceEnabledToggle') ref: MatSlideToggle;

	constructor(
		public dialog: MatDialog,
		private settingsService: SettingsService
	) { }

	async ngOnInit() {
		let x: SettingModel = null;

		try {
			x = await this.settingsService.getSetting(SettingsConstants.OrderSynchronizerServiceEnabled);
		}
		catch (error) {
			// ok... service not enabled
		}

		if (x && x.Value === 'true') {
			this.isOrderSynchronizerServiceEnabled = true;
		}
		else {
			this.isOrderSynchronizerServiceEnabled = false;
		}
	}

	openDialog(event$: any) {
		let newState = event$.checked;

		const dialogConfig = new MatDialogConfig();

		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;

		dialogConfig.data = event$;

		dialogConfig.width = '500px';
		dialogConfig.height = '300px';

		const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

		dialogRef.afterClosed().subscribe((result: boolean) => {
			if (result) {
				const model = new SettingModel(SettingsConstants.OrderSynchronizerServiceEnabled, newState ? 'true' : 'false');
				this.settingsService.updateSetting(model)
				this.isOrderSynchronizerServiceEnabled = newState;
			}
			else {
				this.ref.checked = !newState;
				this.isOrderSynchronizerServiceEnabled = !newState;
			}
		});
	}

}
