import { Injectable } from '@angular/core';
import { MathEnum } from '@infrastructure/math-state-enum';
import { BagUpdateModel } from '@models/bag-update-model';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { LocalstorageService } from './localstorage.service';
import { OrderOnBehalfService } from './order-on-behalf.service';
import { OrdersDataService } from './orders-data.service';
import { QueueItemService } from './queue-item.service';
import { ServiceUtils } from './utils/service.utils';
import { LoginService } from './login.service';

@Injectable()
export class BagCountService extends BaseService {

	private bagCountKey = "bagCountKey"
	public bagCountUpdatedSubject = new Subject<BagUpdateModel>();
	isLoggedIn: Observable<boolean>;

	constructor(private serviceUtils: ServiceUtils,
		private orderOnBehalfService: OrderOnBehalfService,
		private localStorage: LocalstorageService,
		private queueItemService: QueueItemService,
		private ordersDataService: OrdersDataService,
		private loginService: LoginService,
	) {
		super();

		this.isLoggedIn = this.loginService.IsLoggedInObservable();

		this.subscriptions.push(this.orderOnBehalfService.customerAsObservable().subscribe(async () => {
			this.isLoggedIn.subscribe(async r => {
				if (r) {
					const chosenCustomerOrUserNo = await this.orderOnBehalfService.getCustomerOrUserNo();
					this.countUpdateFromBackend(chosenCustomerOrUserNo);
				}
			});
		}));

		this.subscriptions.push(this.ordersDataService.orderDataChanged.subscribe(async customerNo => {
			await this.countUpdateFromBackend(customerNo);
		}));
	}

	private saveCount(orderItemCount: number, orderMiscItemCount: number): void {
		const updateModel = new BagUpdateModel(orderItemCount, orderMiscItemCount);
		this.localStorage.set(this.bagCountKey, updateModel);
		this.bagCountUpdatedSubject.next(updateModel);
	}

	public async countUpdateFromBackend(customerNo: string) {
		const queuedOrderItems = await this.queueItemService.getReadyOrderItemsInQueue(customerNo, false);
		const queuedOrderMiscItems = await this.queueItemService.getReadyMiscOrderItemsInQueue(customerNo, false);
		const queuedOrderItemAmount = queuedOrderItems?.filter(x => x.CustomerNo).sum(item => item.Amount);
		const queuedOrderMiscItemAmount = queuedOrderMiscItems?.sum(item => item.Amount);

		this.ordersDataService.getOrderIds(customerNo).then(orderIdsModel => {
			if (orderIdsModel) {
				const framesAmount = orderIdsModel.sum(y => y.getFullFramesAmount());
				const miscAmount = orderIdsModel.sum(y => y.getMiscAmount());
				this.saveCount(framesAmount + queuedOrderItemAmount, miscAmount + queuedOrderMiscItemAmount);
			}
		})
			.catch(error => {
				if (this.serviceUtils.errorIsOffline(error)) {
					this.saveCount(0, 0);

				} else {
					throw error;
				}
			});
	}

	public countUpdate(count: number, updateType: MathEnum, isFrame: boolean): void {
		let currentBagCount = this.localStorage.get<BagUpdateModel>(this.bagCountKey);

		if (isNaN(currentBagCount.FullFrames)) {
			currentBagCount.FullFrames = 0;
		}

		if (isFrame) {
			if (updateType == MathEnum.Addition) {
				currentBagCount.FullFrames = currentBagCount.FullFrames + count;
			}
			else {
				currentBagCount.FullFrames = currentBagCount.FullFrames - count;
			}
		}
		else {
			if (updateType == MathEnum.Addition) {
				currentBagCount.AccessoriesAndTools = currentBagCount.AccessoriesAndTools + count;
			}
			else {
				currentBagCount.AccessoriesAndTools = currentBagCount.AccessoriesAndTools - count;
			}
		}

		this.saveCount(currentBagCount.FullFrames, currentBagCount.AccessoriesAndTools);
	}

}
