export class AssortmentModel {
    public Code: string;
    public Description: string;


    public static createCopy(fromModel: AssortmentModel): AssortmentModel {
        const newAssortmentModel = new AssortmentModel();
        newAssortmentModel.Code = fromModel?.Code;
        newAssortmentModel.Description = fromModel?.Description;
        return fromModel ? newAssortmentModel : null;
    }
}