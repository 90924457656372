export class ColorTypeModel {
    Id: number;
    Uuid: string;
    Code: string;
    Description: string;

    public static createCopy(fromModel: ColorTypeModel): ColorTypeModel {
        const colorTypeModel = new ColorTypeModel();
        colorTypeModel.Id = fromModel?.Id;
        colorTypeModel.Uuid = fromModel?.Uuid;
        colorTypeModel.Description = fromModel?.Description;
        colorTypeModel.Code = fromModel?.Code;
        return fromModel ? colorTypeModel : null;
    }
}
