import { AllAssortmentsModel } from '@models/all-assortments-model.interface';
import { AssortmentLineModel } from './assortment-line-model';
import { AssortmentModel } from './assortment-model';
import { ComponentModel } from './component';
import { SimpleComponentVariantModel } from './component-variant';
import { DataTranslationModel } from './data-translation-model';
import { VariantColorModel } from './variant-color';
import { SimpleVariantSizeModel } from './variant-size';
import { OrderLineState } from './order-line-state';

export class OrderLine implements AllAssortmentsModel {

	constructor(private state: OrderLineState) {
	}

	public getState = () => this.state;

	public get Id(): number {
		return this.state.Id;
	}

	public get Uuid(): string {
		return this.state.Uuid;
	}

	public get ComponentVariantDescription(): DataTranslationModel {
		return this.state.OriginalComponentVariant?.Description || DataTranslationModel.GetFallbackOnlyModel(this.state.ComponentVariantDescription);
	}

	public get ComponentVariantDescription2(): DataTranslationModel {
		return this.state.OriginalComponentVariant?.Description2 || DataTranslationModel.GetFallbackOnlyModel(this.state.ComponentVariantDescription2);
	}

	public get ComponentSizeDescription(): string {
		return this.state.ComponentSizeDescription;
	}

	public get ColorDescription(): DataTranslationModel {
		return this.state.OriginalComponentColor?.Color?.Description || DataTranslationModel.GetFallbackOnlyModel(this.state.ColorDescription);
	}

	public get ColorGroupDescription(): DataTranslationModel {
		return this.state.OriginalComponentColor?.Color?.ColorGroup?.Description || DataTranslationModel.GetFallbackOnlyModel(this.state.ColorGroupDescription);
	}

	public get ComponentDescription(): string {
		return this.state.ComponentDescription;
	}

	public get ComponentCode(): string {
		return this.state.ComponentCode;
	}

	public get ComponentVariantCode(): string {
		return this.state.ComponentVariantCode;
	}

	public get ComponentSizeCode(): string {
		return this.state.ComponentSizeCode;
	}

	public get ColorCode(): string {
		return this.state.ColorCode;
	}

	public get ColorGroupCode(): string {
		return this.state.ColorGroupCode;
	}

	public get ComponentVariantUuid(): string {
		return this.state.ComponentVariantUuid;
	}

	public get ComponentSizeUuid(): string {
		return this.state.ComponentSizeUuid;
	}

	public get ComponentColorUuid(): string {
		return this.state.ComponentColorUuid;
	}

	public get ComponentUuid(): string {
		return this.state.ComponentUuid;
	}

	public get ComponentVariantBypass(): boolean {
		return this.state.ComponentVariantBypass;
	}

	public get ComponentSizeBypass(): boolean {
		return this.state.ComponentSizeBypass;
	}

	public get SplittingData(): boolean {
		return this.state.SplittingData;
	}

	public get Optional(): boolean {
		return this.state.Optional;
	}

	public get OriginalComponent(): ComponentModel {
		return this.state.OriginalComponent;
	}

	public get OriginalComponentVariant(): SimpleComponentVariantModel {
		return this.state.OriginalComponentVariant;
	}

	public get OriginalComponentSize(): SimpleVariantSizeModel {
		return this.state.OriginalComponentSize;
	}

	public get OriginalComponentColor(): VariantColorModel {
		return this.state.OriginalComponentColor;
	}

	public get AllAssortments(): Array<AssortmentModel> {
		const result = new Array<AssortmentModel>();
		this.OriginalComponentVariant?.AllAssortments.distinct((x, y) => x.Code === y.Code).forEach(x => result.push(x));
		this.OriginalComponentSize?.AllAssortments.distinct((x, y) => x.Code === y.Code).forEach(x => result.push(x));
		this.OriginalComponentColor?.AllAssortments.distinct((x, y) => x.Code === y.Code).forEach(x => result.push(x));

		return result;
	}

	public EngravingAvailable(): boolean {
		return this.state.EngravingPossible > 0 || this.OriginalComponentVariant?.EngravingPossible > 0;
	}

	public get AllAssortmentLines(): Array<AssortmentLineModel> {
		const result = new Array<AssortmentLineModel>();
		this.OriginalComponentVariant?.AssortmentLines.forEach(x => result.push(x));
		this.OriginalComponentSize?.AssortmentLines.forEach(x => result.push(x));
		this.OriginalComponentColor?.AssortmentLines.forEach(x => result.push(x));

		return result;
	}

	isDiscontinued(): boolean {
		return this.isComponentVariantDiscontinued()
			|| this.isComponentSizeDiscontinued()
			|| this.isComponentColorDiscontinued();
	}

	isComponentVariantDiscontinued() {
		return this.ComponentVariantCode && (!this.OriginalComponentVariant || this.OriginalComponentVariant.isDiscontinued());
	}

	isComponentSizeDiscontinued() {
		return this.ComponentSizeCode && (!this.OriginalComponentSize || this.OriginalComponentSize.isDiscontinued());
	}

	isComponentColorDiscontinued() {
		return this.ColorCode && (!this.OriginalComponentColor || this.OriginalComponentColor.isDiscontinued());
	}

	isNew() {
		return this.isComponentVariantNew()
			|| this.isComponentSizeNew()
			|| this.isComponentColorNew();
	}

	isComponentVariantNew() {
		return this.OriginalComponentVariant && this.OriginalComponentVariant.New;
	}

	isComponentSizeNew() {
		return this.OriginalComponentSize && this.OriginalComponentSize.New;
	}

	isComponentColorNew() {
		return this.OriginalComponentColor && this.OriginalComponentColor.New;
	}

}
