import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../_services/login.service';

@Injectable()
export class AuthGuard  {
    constructor(private loginService: LoginService, private router: Router) { }

    async canActivate() {        
        if (this.loginService.IsLoggedIn() || this.loginService.AllowUnauthenticatedAccess()) {
            return true;
        } else {
            await this.router.navigate(['/', 'login']);
            return false;
        }
    }
}
