import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEventsService } from './interfaces/events.service.interface';

@Injectable()
export class EventsService implements IEventsService {

    private loadingCount: boolean = false;
    private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public showLoadingOverlay$: Observable<boolean> = this.isLoading$.asObservable();

    private showModal$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public willShowModal$: Observable<any> = this.showModal$.asObservable();

    private scrollSource$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public scrollPosition$ = this.scrollSource$.asObservable();

    private groupIndexSource$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public groupIndex$ = this.groupIndexSource$.asObservable();

    private customizerRefSource$: BehaviorSubject<HTMLElement> = new BehaviorSubject<HTMLElement>(null);
    public customizerRef$ = this.customizerRefSource$.asObservable();

    public errorsWereReset = new EventEmitter<boolean>();

    constructor() { }

    showLoader(ifLoading: boolean, delay : number = 500) {
        if (!ifLoading) {
            this.loadingCount = false;
            this.isLoading$.next(ifLoading);
        } else {
            this.loadingCount = true;
            setTimeout(() => {
                if (this.loadingCount) {
                    this.isLoading$.next(ifLoading);
                }
            }, delay);
        }
    }

    showModal(modalObject: any) {
        this.showModal$.next(modalObject);
    }

    changeScrollPos(position: number) {
        this.scrollSource$.next(position);
    }

    changeGroupIndex(index: number) {
        this.groupIndexSource$.next(index);
    }

    changeCustomizerRef(el: HTMLElement) {
        this.customizerRefSource$.next(el);
    }

}
