export class DataTranslationModel {
    constructor(
        public TranslationKey: string,
        public Fallback: string
    ) {}

    public static GetFallbackOnlyModel(fallbackText: string) {
        return new DataTranslationModel("", fallbackText);
    }

    toString = () => `${this.Fallback}`;
}
