import { Injectable } from '@angular/core';

import { LanguageService } from '@services/language.service';
import { GenericInfoModalComponent } from '../_modals/generic/generic-info.modal';
import { EventsService } from '../_services/events.service';
import { ModalService } from '../_services/modal.service';
import { NavigateService } from '../_services/navigate.service';

@Injectable()
export class CanCustomizeGuard  {
    constructor(private navigate: NavigateService, private modalService: ModalService, private eventService: EventsService, private translateService: LanguageService) { }

    async canActivate() {
        if (await this.navigate.canNavigateToCustomizer()) {
            return true;
        }
        this.eventService.showLoader(false);

        const input = {
            title: this.translateService.instant(`CUSTOMIZER.UPGRADE-COLLECTION.HEADER`),
            contentPart1: "",
            contentPart2: "",
            contentPart3: this.translateService.instant(`CUSTOMIZER.UPGRADE-COLLECTION.BODY`)
        };

        this.modalService.create(GenericInfoModalComponent, input);

        return false;
    }
}
