import { Component, OnInit, SimpleChanges } from '@angular/core';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { ModelGroupModel } from 'src/_models/model-group';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { CustomizerStateConstants } from '../../../../shared/customizer-state-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'clip-on-component',
	styleUrls: ['./clip-on.component.scss'],
	templateUrl: './clip-on.component.html'
})
export class ClipOnComponent extends BaseCustomizerComponent implements OnInit {

	public designs: Array<ComponentVariantFromApiModel> = [];
	public noDesign: boolean = false;
	public onlyClipOnChecked: boolean = false;
	public isEditOrder = CustomizerStateConstants.EDITORDER;

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		return true;
	}

	constructor(
		public events: EventsService,
		private imageService: ImageService,
		public apiService: DataApiService,
		public sorter: SorterService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		const x = await this.fetchData();

		if (!x) {
			this.noDesign = true;
		} else {
			this.designs = x;
		}

		this.onlyClipOnChecked = this.variants.length == 1 ? true : false;

		this.events.showLoader(false);
		this.setImageSliderByName(AngleConstants.Perspective);
	}

	onChanges(changes: SimpleChanges): void {
	}

	getImage = (variant: ComponentVariantFromApiModel) => this.imageService.GetVariantImage(variant.ToComponentVariantModel(""));

	onSelected(selected: ComponentVariantFromApiModel) {
		const old = this.getSelectedVariant;

		const newVariant = selected != null
			? selected.ToComponentVariantModel(old ? old.Engraving : "", old ? old.OrderLineUuid : null, true)
			: null;

		if (newVariant) {
			newVariant.VariantSize = selected.VariantSizes[0];
			newVariant.VariantColor = selected.VariantSizes[0].VariantColors[0];
		}

		this.updateVariant(new ComponentVariantChangeModel(old, newVariant, null));
	}

	setClipOnOnly(clipOnOnly: boolean) {
		this.onlyClipOnChecked = clipOnOnly;
		this.updateClipOnOnly(clipOnOnly);
	}

	async fetchData() {
		const variant = this.getSelectedVariant;

		if (variant) {
			variant.VariantViewed = true;
		}

		const res = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, this.menu.menuComponents[0].ComponentUuids);

		await this.RunDisablingSystem(res);

		return res.sort(this.sorter.sortBy(ComponentVariantFromApiModel));
	}

}
