import { Component, Input } from '@angular/core';
import { IconClickTransition } from '@shared/transitions/icon-click.transition';

@Component({
    selector: 'icon-search',
    styleUrls: ['./icon-search.component.scss'],
    animations: [
        IconClickTransition()
    ],
    template:
    `
	<i class="fa-light fa-magnifying-glass fa-xl"></i>
	`
})
export class IconSearchComponent {
    @Input() clickCount: number;
}
