import { EventEmitter, Injectable } from '@angular/core';
import { VersionType } from '@infrastructure/versionType.enum';
import { OrderIdsModel } from '@models/orderIds-model';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject } from 'rxjs';
import { CustomerLocationCheckInModalComponent } from '../_modals/customer-location-checkin/customer-location-checkin.component';
import { OrderOnBehalfModalComponent } from '../_modals/order-on-behalf/order-on-behalf.component';
import { CustomerModel, CustomerOrderRelevantModel, CustomerType } from '../_models/customer';
import { BaseService } from './base.service';
import { CustomerDataService } from './customer-data.service';
import { EnduserDisplayService } from './enduserdisplay.service';
import { EnvService } from './env.service';
import { EventsService } from './events.service';
import { IndexDbService } from './index-db.service';
import { IOrderOnBehalfService } from './interfaces/order-on-behalf.service.interface';
import { LocationCheckInService } from './location-checkin.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';
import { OrdersDataService } from './orders-data.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';
import { ServiceUtils } from './utils/service.utils';

@Injectable()
export class OrderOnBehalfService extends BaseService implements IOrderOnBehalfService {

	private readonly sessionKey = "currentCustomer";

	emitter: EventEmitter<CustomerModel>;

	public constructor(
		private modalService: ModalService,
		private userService: UserService,
		private sessionService: SessionService,
		private enduserDisplayService: EnduserDisplayService,
		private eventService: EventsService,
		private locationCheckInService: LocationCheckInService,
		private indexDbService: IndexDbService,
		private ordersDataService: OrdersDataService,
		private customerDataService: CustomerDataService,
		private offlineService: OfflineService,
		private serviceUtils: ServiceUtils,
		private env: EnvService,
	) {
		super();

		this.subscriptions.push(this.ordersDataService.orderDataChanged.subscribe(async () => {
			// we have to clear customerdata instantanious when backend order data changes.
			await this.clearOrderRelevantData();

			// after order data change, we reload data in background. If the reload is done, "updateOrderRelevantData" will not make and backend call. Thats why we wait.
			setTimeout(() => {
				this.updateOrderRelevantData();
			}, 5000);
		}));

		this.emitter = new EventEmitter<CustomerModel>();

		this.subscriptions.push(this.emitter.subscribe(async (x: CustomerModel) => {
			await this.setCustomer(x);
		}));
	}

	private customerSubject = new BehaviorSubject<CustomerModel>(plainToClass(CustomerModel, this.sessionService.get<CustomerModel>(this.sessionKey)));

	public customerAsObservable = () => this.customerSubject.asObservable();

	public customerSnapshot = () => this.customerSubject.getValue();

	public async triggerSetCustomerModal() {
		this.modalService.create<OrderOnBehalfModalComponent>(OrderOnBehalfModalComponent, { customerChange: this.emitter });
	}
	public resetCustomer = () => this.customerSubject.next(null);
	public async setCustomer(customer: CustomerModel) {
		if (customer) {
			const emitter = new EventEmitter<boolean>();

			let customerType = customer.Type;

			let user = await this.userService.currentUser$.getValue();

			if (user.IsDistributor) {
				customerType = CustomerType.OrderOnBehalf;
			}

			emitter.subscribe(async x => {
				this.eventService.showLoader(true);
				let customerData: CustomerModel = null
				let token = null
				let street = null;
				let address = null;
				let shipToName = null;

				if (customer.IsNewPartner) {
					token = customer.Token;
					street = customer?.Street;
					address = customer?.Address;
					shipToName = customer?.ShipToName;
					token = await this.customerDataService.GetCustomerToken(customer);
				}
				else {
					customerData = await this.customerDataService.getCustomerData(customer.No);
					street = customerData?.Street
					address = customerData?.Address;
					shipToName = customerData?.ShipToName;
					token = customerData.Token;
				}

				const customerModel = new CustomerModel(customer.No, customerType, customer.SalesDistrictType, customer.Name, street, shipToName,
					customer.IsPlusPartner, customer.IsPreciousPartner, customer.IsBlocked, customer.Email,
					customer.EmailAddresses, customerData?.ShipToAddresses, customerData?.ProductLinesPermissions,
					customerData?.Assortments, token, [], [], address, customer.IsNewPartner, false, "");

				const shouldPurge = this.shouldPurge(this.customerSnapshot(), customerModel);

				if (shouldPurge) {
					await this.indexDbService.clearCache(VersionType.Frames);
					this.sessionService.clear();
				}

				await this.updateOrderRelevantData(customerModel);

				this.eventService.showLoader(false);

				if (shouldPurge) {
					window.location.reload();
				}
			});

			if (!customer.IsOrderDeeplinkMode) {
				this.triggerLocationCheckInModal(customer, emitter);
			}
			else {
				emitter.next(true);
			}
		}
		else {
			await this.setUserAndCustomerData(null, true);
			await this.updateOrderRelevantData(null);
			this.modalService.close(OrderOnBehalfModalComponent);
		}
	}

	// This is used, because we had a wide spread problem in our codebase, where we use customer?.No or empty string.
	// We should never use empty string. User has a customerno aswell, and we should use that, which is why this method exits
	public async getCustomerOrUserNo() {
		let customer = this.customerSnapshot();
		let user = await this.userService.GetUser();

		if (customer || user) {
			return customer ? customer.No : user.CustomerNo;
		}
	}

	public async getCurrentActiveOrderIds(): Promise<Array<OrderIdsModel>> {
		let customer = this.customerSnapshot();
		let user = await this.userService.GetUser();

		if (customer) {
			if (!customer.OrderIds || !user.canUseNonBlockingOfflineAddToBag) {
				await this.updateOrderRelevantData(customer);
				customer = this.customerSnapshot();
			}

			return customer.OrderIds;
		}
		else {
			if (!user.OrderIds || !user.canUseNonBlockingOfflineAddToBag) { // this check is currently important since the user is often updated directly from the Api (UserService.setUser()) and thus contains no orderIds (these are set by a diffent API call).
				await this.updateOrderRelevantData();
				user = await this.userService.GetUser();
			}

			return user.OrderIds;
		}
	}

	public async hasStartkitOnOrders(productLineCode: string): Promise<boolean> {
		let customer = this.customerSnapshot();

		if (!customer.StartkitsOnActiveOrders || customer.StartkitsOnActiveOrders.length === 0) {
			await this.updateOrderRelevantData();
			customer = this.customerSnapshot();
		}

		return customer.StartkitsOnActiveOrders.some(sk => sk === productLineCode);
	}

	public async clearOrderRelevantData() {
		const currentCustomer = this.customerSnapshot();

		if (!currentCustomer) {
			let currentUser = await this.userService.GetUser();
			currentUser.OrderIds = null;
			this.userService.setUser(currentUser);
		}
		else {
			currentCustomer.StartkitsOnActiveOrders = null;
			currentCustomer.OrderIds = null;
			this.setUserAndCustomerData(currentCustomer, false);
		}
	}

	public async setUserAndCustomerData(customer: CustomerModel, customerChanged: boolean) {
		this.sessionService.set(this.sessionKey, customer);

		if (customerChanged) {
			this.customerSubject.next(customer);

			if (customer?.IsNewPartner) {
				this.customerDataService.createNewCustomerInStore(customer);
			}
		}

		let currentUser = await this.userService.GetUser();
		if (currentUser.canOrderOnBehalfOfChainCustomer) { // Impersonation
			const user = await this.userService.getUserData();
			this.userService.setUser(user);
		}

		if (customer && !customer.isConsultantOrderOnBehalf) {
			this.enduserDisplayService.setConfigurationMode(true);
		}
	}

	private shouldPurge(oldCustomer: CustomerModel, newCustomer: CustomerModel): boolean {
		return oldCustomer && oldCustomer.Type === CustomerType.ChainCustomer || newCustomer && newCustomer.Type === CustomerType.ChainCustomer;
	}

	private triggerLocationCheckInModal(customer: CustomerModel, emitter: EventEmitter<boolean>) {
		if (customer && customer.Type == CustomerType.OrderOnBehalf && !this.locationCheckInService.locationCheckInExistsForToday(customer) && this.offlineService.isOnlineValue) {
			const modal = this.modalService.create<CustomerLocationCheckInModalComponent>(CustomerLocationCheckInModalComponent, { customer });

			const sub = modal.subscribe(ref => {
				if (ref.hostView.destroyed) {
					emitter.next(true);
					sub.unsubscribe();
				}
			});
		}
		else {
			this.modalService.close(OrderOnBehalfModalComponent);
			emitter.next(true);
		}
	}

	private async getUserOrderRelevantData(userCustomerNo: string): Promise<CustomerOrderRelevantModel> {
		const orderOnUser = await this.ordersDataService.getFromOrdersStore(userCustomerNo);

		let orderRelevantData: CustomerOrderRelevantModel;

		if (orderOnUser) {
			orderRelevantData = new CustomerOrderRelevantModel(orderOnUser);
		}
		else {
			try {
				const result = await this.offlineService.getDataWithOfflineCheck<CustomerOrderRelevantModel>(`${await this.env.baseUrl()}/Account/Userdata/OrderRelevantData`);
				orderRelevantData = plainToClass(CustomerOrderRelevantModel, result);
			}
			catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					return new CustomerOrderRelevantModel(null);
				}
				else {
					throw error;
				}
			}
		}
		return orderRelevantData;
	}

	public async updateOrderRelevantData(newCustomer: CustomerModel = null) {
		const currentCustomer = newCustomer ? newCustomer : this.customerSnapshot();
		let currentUser = await this.userService.GetUser();

		if (!currentCustomer) {
			try {
				const userOrderData = await this.getUserOrderRelevantData(currentUser.CustomerNo);
				currentUser.OrderIds = userOrderData.OrderIds;
				this.userService.setUser(currentUser);
				currentUser.OrderingOnBehalfOfNewPartner = false;

			} catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					//ignore. Dont need to set anything
				} else {
					throw error;
				}
			}
		}
		else {
			if (currentCustomer?.IsNewPartner) {

				currentCustomer.ProductLinesPermissions = [];
				currentCustomer.Assortments = currentUser.Assortments;
				currentCustomer.StartkitsOnActiveOrders = [];
				currentUser.OrderingOnBehalfOfNewPartner = true;
			}
			else {
				currentUser.OrderingOnBehalfOfNewPartner = false;
			}


			const result = await this.offlineService.getDataWithOfflineCheck<CustomerOrderRelevantModel>(`${await this.env.baseUrl()}/Account/Customer/${currentCustomer.No}/OrderRelevantData`);
			const customerOrderData = plainToClass(CustomerOrderRelevantModel, result);

			try {
				currentCustomer.StartkitsOnActiveOrders = customerOrderData.StartkitsOnActiveOrders;
				currentCustomer.OrderIds = customerOrderData.OrderIds;
				this.setUserAndCustomerData(currentCustomer, newCustomer !== null);
			}
			catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					//ignore. Dont need to set anything
				}
				else {
					throw error;
				}
			}
		}
	}

}
