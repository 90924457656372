import { Component, OnInit } from "@angular/core";

@Component({
	templateUrl: "copyright.component.html",
	selector: "copyright",
	styleUrls: ["./copyright.component.scss"]
})
export class CopyrightComponent implements OnInit {

	public year: number;

	ngOnInit(): void {
		this.year = (new Date()).getFullYear();
	}

}
