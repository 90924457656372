import { Type } from "class-transformer";
import { AddressModel } from "./address";
import { OrderState } from './order-state';
import { OrderIdsModel } from "./orderIds-model";
import { SalesDistrictType } from "./sales-district";

export class CustomerModel {

	constructor(
        public No: string,
        public Type: CustomerType,
        public SalesDistrictType: SalesDistrictType,
        public Name: string,
        public Street: string,
        public ShipToName: string,
        public IsPlusPartner: boolean,
        public IsPreciousPartner: boolean,
        public IsBlocked: boolean,
        public Email: string,
        public EmailAddresses: Array<string>,
        public ShipToAddresses: Array<AddressModel>,
        public ProductLinesPermissions: Array<string>,
        public Assortments: Array<string>,
        public Token: string,
        public StartkitsOnActiveOrders: Array<string>,
        public OrderIds: Array<OrderIdsModel>,
        public Address: string,
        public IsNewPartner: boolean,
        public IsOrderDeeplinkMode: boolean,
        public CountryCode: string,
    ) {
    }

    public get AddressStringified() {
        return this.Address ? ', ' + this.Address.replace(/\r/g, ', ') : '';
    }

    public get isConsultantOrderOnBehalf() {
        return this.Type === CustomerType.OrderOnBehalf;
    }

    hasProductLineAccess(productLineCode: string): boolean {
        return this.ProductLinesPermissions.some(x => x === productLineCode);
    }

}

export class CustomerAddressModel {

	constructor(
        public Name: string,
        public Street: string,
        public CountryCode: string,
        public StateCode: string,
        public CountyCode: string,
        public City: string,
        public PostalCode: string
    ) { }

    toString() {
        return `${this.Street}`;
    }

}

export class CustomerOrderRelevantModel {

    constructor(orders : OrderState[]) {    
        if (orders){
            this.StartkitsOnActiveOrders = orders.selectMany(o => o.OrderItems).map(oi => oi.ProductLineCode);
            this.OrderIds = orders.map(o => new OrderIdsModel(o));
        }
    }

    public StartkitsOnActiveOrders: Array<string> = []

    @Type(() => OrderIdsModel)
    public OrderIds: Array<OrderIdsModel> = []
}

export enum CustomerType {
    OrderOnBehalf,
    ChainCustomer
}
