import packageInfo from '../../package.json';

export const environment = {
	production: true,
	dataUrl: null,
	imageUrl: null,
	imageCDNUrl: null,
	version: packageInfo.version,
	hmr: false,
	appInsightsKey: null
};
