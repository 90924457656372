import { SortedBagItem } from '@app/bag/components/order/order.component';
import { FirstTimeDeliveryCalculator } from '@infrastructure/FirstTimeDeliveryCalculator';
import { isLatinCharacters } from '@infrastructure/latin-character-test';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { LanguageService } from '@services/language.service';
import { DiscountConstants } from '@shared/discount-constants';
import { ProductlineConstants } from '@shared/productline-constants';
import { toDateStringWithUserOffset } from '@shared/utils/date-helper';
import moment from 'moment';
import { AddressModel } from './address';
import { StartkitMappingResponse } from './api-models/startkit-mapping-response-model';
import { BagItemModel } from './bag-item-model';
import { CustomerModel } from './customer';
import { DataTranslationModel } from './data-translation-model';
import { DateResult } from './date-result';
import { DiscountModelFactory, RCDiscountModel } from './discounts/discount-model';
import { FairModel } from './fair-model';
import { ModelGroupModel } from './model-group';
import { OrderCompleteModel } from './order-complete-model';
import { IOrderConfirmationEmail } from './order-confirmation-email';
import { OrderItemModel } from "./order-item";
import { OrderItemMiscModel } from "./order-item-misc-model";
import { OrderItemMiscType } from './order-item-misc-type';
import { OrderState } from './order-state';
import { OrderStatus } from './order-status';
import { OrderUpdateModel } from './order-update-model';
import { SalesDistrictType } from './sales-district';
import { TranslateModel } from './translate-model';
import { UserDataModel } from './user-data-model';
import { log } from 'console';

export class Order {

	public constructor(private state: OrderState) {
		this.VisitDateForValidation = new DateResult(this.state.VisitDate);
		this.EarliestDeliveryDateForValidation = new DateResult(this.state.EarliestDeliveryDate);
	}

	newCustomerMinimumOrderItemCount = 40;
	reestablishedCustomerMinimumOrderItemCount = 50;
	nonpreciousCustomerMinimumOrderItemCount = 4;

	public getState = () => Object.assign(new OrderState(), this.state);

	public get Id(): number {
		return this.state.Id;
	}

	public get Uuid(): string {
		return this.state.Uuid;
	}

	public get ConsultantUuid(): string {
		return this.state.ConsultantUuid;
	}

	public get TestOrder(): boolean {
		return this.state.TestOrder;
	}

	public set TestOrder(value: boolean) {
		this.state.TestOrder = value;
	}

	public get OrderItemsCount(): number {
		return this.state.OrderItems.length;

		// return this.OrderItems.length + this.OrderItemMiscs.length;
	}

	public get OrderItemsCountNotOnHoldActions(): number {
		if (!this.anySelected)
			return 0;
		return this.CurrentSelectedOrderItems().length + this.CurrentSelectedMiscOrderItems().length;
	}

	public get AxId(): number {
		return this.state.AxId;
	}

	public get CustomerNo(): string {
		return this.state.CustomerNo;
	}

	public get CustomerName(): string {
		return this.state.CustomerName;
	}

	public get Alias(): string {
		return this.state.Alias;
	}

	public get CheckedOrderConfirmationEmailAddressesString(): string {
		return this.state.CheckedOrderConfirmationEmailAddressesString?.replace(",", "\n");
	}

	public get CustomerEmail(): string {
		return this.state.CustomerEmail;
	}

	public get ConfirmationEmails(): Array<IOrderConfirmationEmail> {
		return this.state.ConfirmationEmails;
	}

	public get Notes(): string {
		return this.state.Notes;
	}

	public set Notes(value: string) {
		this.state.Notes = value;
	}

	public get NotesCustomerService(): string {
		return this.state.NotesCustomerService;
	}

	public set NotesCustomerService(value: string) {
		this.state.NotesCustomerService = value;
	}


	mappedOrderItems: ReadonlyArray<OrderItemModel> = null;

	public get OrderItems(): ReadonlyArray<OrderItemModel> {
		// if (!this.mappedOrderItems) {
		// 	this.invalidateOrderItems();
		// }

		// return this.mappedOrderItems;

		return this.state.OrderItems.map(x => new OrderItemModel(x));
	}

	public set OrderItems(items: ReadonlyArray<OrderItemModel>) {
		this.state.OrderItems = items.map(x => x.getState());

		// this.invalidateOrderItems();
	}

	invalidateOrderItems() {
		this.mappedOrderItems = this.state.OrderItems.map(x => new OrderItemModel(x));
	}

	public get OrderItemMiscs(): ReadonlyArray<OrderItemMiscModel> {
		return this.state.OrderItemMiscs.map(x => new OrderItemMiscModel(x));
	}

	public set OrderItemMiscs(items: ReadonlyArray<OrderItemMiscModel>) {
		this.state.OrderItemMiscs = items.map(x => x.getState());
	}

	public addOrderMiscItem(orderMiscItem: OrderItemMiscModel) {
		this.OrderItemMiscs = [...this.OrderItemMiscs, orderMiscItem];
	}

	public addOrderItem(orderItem: OrderItemModel) {
		this.OrderItems = [...this.OrderItems, orderItem];

		// this.invalidateOrderItems();
	}

	public removeOrderItemFromState(orderItem: (OrderItemModel | OrderItemMiscModel)) {
		if (orderItem instanceof OrderItemModel) {
			this.OrderItems = this.OrderItems.filter(x => (orderItem.SyncOrderItemGuid && x.SyncOrderItemGuid != orderItem.SyncOrderItemGuid) ||
				(orderItem.Uuid && x.Uuid != orderItem.Uuid));

				// this.invalidateOrderItems();
			}
		else {
			this.OrderItemMiscs = this.OrderItemMiscs.filter(x => (orderItem.SyncOrderItemGuid && x.SyncOrderItemGuid != orderItem.SyncOrderItemGuid) ||
				(orderItem.Uuid && x.Uuid != orderItem.Uuid));
		}
	}

	public get Status(): OrderStatus {
		return this.state.Status;
	}

	public get IsLocked(): boolean {
		return (this.state.Status === OrderStatus.Request || this.state.Status === OrderStatus.Completed)
			&& !this.state.ShowOrderConfirmation;
	}

	public get IsVirtual(): boolean {
		return this.state.Virtual;
	}

	public get FullAddress(): string {
		return this.state.FullAddress;
	}

	public set FullAddress(value: string) {
		this.state.FullAddress = value;
	}

	public get OrderAddress(): string {
		return this.state.Address;
	}

	public set Address(address: AddressModel) {
		this.state.FullAddress = address?.FullAddress;
		this.state.ShipToId = address?.ShipToId;
		this.state.ShipToName = address?.Name;
	}

	public set Fair(fair: FairModel) {
		this.state.FairId = fair?.FairId;
		this.state.FairName = fair?.FairName;
	}

	public get ShipToName(): string {
		return this.state.ShipToName;
	}

	public set ShipToName(value: string) {
		this.state.ShipToName = value;
	}

	public get VisitDate(): Date {
		return this.state.VisitDate;
	}

	public set VisitDate(value: Date) {
		this.state.VisitDate = value;
	}

	public get EarliestDeliveryDate(): Date {
		return this.state.EarliestDeliveryDate;
	}

	public set EarliestDeliveryDate(value: Date) {
		this.state.EarliestDeliveryDate = value;
	}

	public get CompletedDate(): Date {
		return this.state.CompletedDate;
	}

	public set CompletedDate(value: Date) {
		this.state.CompletedDate = value;
	}

	public get ShipToId(): number {
		return this.state.ShipToId;
	}

	public set ShipToId(value: number) {
		this.state.ShipToId = value;
	}

	public get FairName(): string {
		return this.state.FairName;
	}

	public set FairName(value: string) {
		this.state.FairName = value;
	}

	public get FairId(): number {
		return this.state.FairId;
	}

	public set FairId(value: number) {
		this.state.FairId = value;
	}

	public get AllowPartialShipment(): boolean {
		return this.state.AllowPartialShipment;
	}

	public set AllowPartialShipment(value: boolean) {
		this.state.AllowPartialShipment = value;
	}

	public get PaymentTerms(): string {
		return this.state.PaymentTerms;
	}

	public set PaymentTerms(value: string) {
		this.state.PaymentTerms = value;
	}

	public get NoVisit(): boolean {
		return this.state.NoVisit;
	}

	public set NoVisit(value: boolean) {
		this.state.NoVisit = value;
	}

	public get IsNewPartner(): boolean {
		return this.state.IsNewPartner;
	}

	public set IsNewPartner(value: boolean) {
		this.state.IsNewPartner = value;
	}

	public get SalesDistrictType(): SalesDistrictType {
		return this.state.SalesDistrictType;
	}

	public set SalesDistrictType(salesDistrictType: SalesDistrictType) {
		this.state.SalesDistrictType = salesDistrictType;
	}

	public VisitDateForValidation: DateResult;
	public EarliestDeliveryDateForValidation: DateResult;

	public get Signature(): string {
		return this.state.Signature;
	}

	public set Signature(value: string) {
		this.state.Signature = value;
	}

	public getOrderName(index: number): string {
		if (this.state.Virtual) {
			return "offline";
		}
		else {
			const name = this.AxId > 0 ? this.AxId : index + 1;
			return name.toString();
		}
	}

	public getTotalAmount(): number {
		return this.OrderItems.map(x => x).sum(x => x.Amount) + this.OrderItemMiscs.map(x => x).sum(x => x.Amount);
	}

	public CurrentSelectedOrderItems() {
		if (this.anySelected) {
			return this.OrderItems.filter(o => o.Selected);
		}

		return this.OrderItems.map(x => x);
	}

	public CurrentSelectedMiscOrderItems() {
		if (this.anySelected) {
			return this.OrderItemMiscs.filter(o => o.Selected);
		}

		return this.OrderItemMiscs.map(x => x);
	}

	public checkForNonPreciousPartnerCustomerMinimum(customer: CustomerModel, ordersWithSameVisit: Array<Order>) {
		const orderItemsWithSameVisit = ordersWithSameVisit?.selectMany(x => x.framesSelected());
		const orderItems = this.framesSelected().concat(orderItemsWithSameVisit).filter(o => o.IsPrecious);
		const result: Array<{ amount: number, orderId: number }> = [];

		if (customer.IsPreciousPartner || orderItems.length === 0) {
			return result;
		}

		let totalFramesCount = orderItems.reduce((sum, current) => sum + current.Amount, 0);

		if (totalFramesCount < this.nonpreciousCustomerMinimumOrderItemCount) {
			orderItems.forEach(o => {
				result.push({
					amount: this.nonpreciousCustomerMinimumOrderItemCount - totalFramesCount,
					orderId: o.OrderId
				});
			})
		}

		return result;
	}

	public checkForNewOrReestablishedCustomerMinimum(customer: CustomerModel, ordersWithSameVisit: Array<Order>) {
		const orderItemsWithSameVisit = ordersWithSameVisit?.selectMany(x => x.framesSelected()).filter(o => !o.IsPrecious);
		const orderItems = this.framesSelected().concat(orderItemsWithSameVisit);
		const result: Array<{ amount: number, orderId: number }> = [];

		if (!customer.IsNewPartner && !customer.IsBlocked && customer.IsPlusPartner || orderItems.length === 0) {
			return result;
		}

		let minimumRequired = !customer.IsPlusPartner && !customer.IsNewPartner ? this.reestablishedCustomerMinimumOrderItemCount : this.newCustomerMinimumOrderItemCount;
		let totalFramesCount = orderItems.reduce((sum, current) => sum + current.Amount, 0);

		if (totalFramesCount < minimumRequired) {
			orderItems.forEach(o => {
				result.push({
					amount: minimumRequired - totalFramesCount,
					orderId: o.OrderId
				});
			})
		}

		return result;
	}

	public checkForMinimum(customerProductLineStatuses: Array<string>, ordersWithSameVisit: Array<Order>) {
		const orderItemsWithSameVisitWithoutDiscount = ordersWithSameVisit?.selectMany(x => x.framesSelected()).filter(o => !o.IsPrecious);
		const orderitemsWithoutDiscount = this.framesSelected().filter(x => !x.IsPrecious && x.OriginalModelGroup?.FullFrame && !x.isSingleComponent());
		const allOrderItemsWithoutDiscount = orderitemsWithoutDiscount.concat(orderItemsWithSameVisitWithoutDiscount);

		const orderItemsWithSameVisit = ordersWithSameVisit?.selectMany(x => x.framesSelected());
		const orderItems = this.framesSelected().concat(orderItemsWithSameVisit).filter(o => !o.IsPrecious);

		const productLines = orderItems.filter(x => x.SyncState === SyncStateEnum.SyncedWithDatabase && x.OriginalProductLine?.MinimumInitialPurchase > 0 && !customerProductLineStatuses?.some(pl => pl === x.ProductLineCode))
			.map(x => x.OriginalProductLine)
			.distinct((x, y) => x.Code === y.Code);

		const result: Array<{ productLine: DataTranslationModel, amount: number, orderId: number }> = [];

		if (orderItems.length === 0) {
			return result;
		}

		productLines.forEach(pl => {
			const orderItemsInProductline = allOrderItemsWithoutDiscount.filter(x => x.ProductLineCode === pl.Code);
			const amount = allOrderItemsWithoutDiscount.filter(x => x.ProductLineCode === pl.Code && !x.containsDiscount()).sum(x => x.Amount);

			if (amount < pl.MinimumInitialPurchase) {
				orderItemsInProductline.forEach(x => {
					result.push({
						productLine: pl.Description,
						amount: pl.MinimumInitialPurchase - amount,
						orderId: x.OrderId
					});
				});
			}
		});

		return result;
	}

	public checkForCorrectDistrict(currentOrder: Order) {
		const result: Array<{ orderId: number }> = [];

		if (currentOrder.SalesDistrictType === SalesDistrictType.OtherDistricts) {
			result.push({
				orderId: currentOrder.Id
			});
		}

		return result;
	}

	calculateRcDiscountPercentRequirement(ordersWithSameVisit: Array<Order>): Array<{ ValidationError: boolean, BilledFullFramesRequired: number, BilledFullFramesInBag: number, OrderId: number }> {
		const orderItemsWithSameVisit = ordersWithSameVisit?.selectMany(x => x.framesSelected());
		const orderItems = this.framesSelected().concat(orderItemsWithSameVisit);

		const fullFrameOrderItems = orderItems.filter(item => item.OriginalModelGroup?.FullFrame);
		const framesWithRcDiscount = fullFrameOrderItems.filter(x => x.DiscountCode && x.DiscountCode == DiscountConstants.RC && x.DiscountPercent === 100);

		const totalDiscount = framesWithRcDiscount.sum(item => item.DiscountPercent * item.Amount);
		const discountRoundedToNearstHundreds = Math.ceil(totalDiscount / 100) * 100;

		const numberOfFramesRequired = (discountRoundedToNearstHundreds / 100) * RCDiscountModel.RC100PercentMinFramesCount;
		const billedFullFramesInBag = fullFrameOrderItems.filter(x => x.DiscountCode === null).sum(item => item.Amount);

		const orderIdsWithRcDiscount = framesWithRcDiscount.map(f => f.OrderId);
		const currentOrderIdsByVisitDate = orderItems.map(o => o.OrderId).distinct();
		const ordersWithRcError = currentOrderIdsByVisitDate.filter(id => orderIdsWithRcDiscount.includes(id));

		return ordersWithRcError.map(oId => ({
			ValidationError: billedFullFramesInBag < numberOfFramesRequired,
			BilledFullFramesRequired: numberOfFramesRequired,
			BilledFullFramesInBag: billedFullFramesInBag,
			OrderId: oId
		}));
	}

	private framesWithfirstTimeDeliverySelectedWithDiscount(ordersWithSameVisit: Array<Order>, customer: CustomerModel, discountCodes: Array<string>): Array<{ OrderId: number }> {
		const orderItemsWithSameVisit = ordersWithSameVisit?.selectMany(x => x.framesSelected());
		const frameItemsSelected = this.framesSelected().concat(orderItemsWithSameVisit);
		const firstTimeDeliveryFramesSelected = frameItemsSelected.filter(item => item.isFirstTimeDelivery(customer));
		const firstTimeDeliveryFramesSelectedWithDiscount = firstTimeDeliveryFramesSelected.filter(o => o.DiscountCode != null && discountCodes.includes(o.DiscountCode));

		return firstTimeDeliveryFramesSelectedWithDiscount.map(o => ({ OrderId: o.OrderId }));
	}

	private framesWithRCAndNotAllowedValidation(customer: CustomerModel, languageModel: LanguageService): TranslateModel[] {
		const framesWithRcDiscount = this.framesSelected().filter(x => x.DiscountCode == DiscountConstants.RC)

		const startKitsErrors = framesWithRcDiscount.filter(x => !customer.ProductLinesPermissions.some(t => t === x.ProductLineCode))
			.map(x => new TranslateModel("BAG.RCNOTALLOWED"));

		const productLineErrors = framesWithRcDiscount.filter(x => x.ProductLineCode === ProductlineConstants.HORN)
			.map(x => new TranslateModel("BAG.RCNOTALLOWONPRODUCTLINE", { name: languageModel.instantTranslateData(x.OriginalProductLine?.Description) }));

		return [...startKitsErrors, ...productLineErrors];
	}

	public get isThereAnythingDiscontinued(): boolean {
		return this.OrderItems.some(x => x.SyncState === SyncStateEnum.SyncedWithDatabase && x.isAnythingDiscontinued());
	}

	public get isThereAnythingNotSynced(): boolean {
		return this.OrderItems.some(x => x.SyncState !== SyncStateEnum.SyncedWithDatabase);
	}

	public get isThereAnythingWithSfDiscount(): boolean {
		const frameOrderItems = this.framesSelected();
		return frameOrderItems.some(x => x.DiscountCode && x.DiscountCode == DiscountConstants.SF);
	}

	framesSelected() {
		return this.CurrentSelectedOrderItems();
	}

	bagItemsExcludingStartkitsSelected() {
		return [...this.CurrentSelectedOrderItems(), ...this.CurrentSelectedMiscOrderItems().filter(x => x.Type != OrderItemMiscType.StartKit)];
	}

	private isDateInRange(date: Date, dateRange: Array<Date>) {
		return !!dateRange.find(x => x.getFullYear() == date.getFullYear() && x.getMonth() == date.getMonth() && x.getDate() == date.getDate());
	}

	public ValidateEarliestDeliveryDate(dateResult: DateResult): string {
		if (dateResult && dateResult.selectedDate) {
			const mindate = new Date();
			mindate.setHours(0, 0, 0, 0);

			if (mindate && mindate > dateResult.selectedDate) {
				return "BAG.EARLIESTDELIVERYDATE.PASTDATEERROR";
			}

			const maxMoment = moment().add(6, 'M');

			if (dateResult.selectedDate > maxMoment.milliseconds(999).toDate()) {
				return "BAG.EARLIESTDELIVERYDATE.MAXDATEERROR";
			}
			else if (dateResult.validationError) {
				return dateResult.errorText != "" ? dateResult.errorText : 'BAG.EARLIESTDELIVERYDATE.INVALIDDATEERROR';
			}
		}

		return null;
	}

	public ValidateVisitdate(dateResult: DateResult): string {
		if (dateResult && dateResult.selectedDate) {
			const maxdate = new Date();
			maxdate.setDate(maxdate.getDate());

			const dateRange = this.Fair ? this.Fair.Days : null;

			if (maxdate && maxdate < dateResult.selectedDate) {
				return "BAG.VISITDATE.FUTUREDATEERROR";
			}
			else if ((dateRange ? dateRange.length : null) && !this.isDateInRange(dateResult.selectedDate, dateRange)) {
				return "BAG.VISITDATE.DATEOUTOFRANGE";
			}

			const minMomont = moment().subtract(6, 'M').milliseconds(0);

			if (dateResult.selectedDate < minMomont.toDate()) {
				return "BAG.VISITDATE.MINDATEERROR";
			}
			else if (dateResult.validationError) {
				return dateResult.errorText != "" ? dateResult.errorText : 'BAG.VISITDATE.INVALIDDATEERROR';
			}
		}

		return null;
	}

	public validatePlaceOrderRequirements(customer: CustomerModel, user: UserDataModel, multipleOrderSharingVisitdate: boolean, startkitMappings: StartkitMappingResponse[]) {
		const placeOrderErrorList = new Array<TranslateModel>();

		const visitdateError = this.ValidateVisitdate(this.VisitDateForValidation);

		if (visitdateError) {
			placeOrderErrorList.push(new TranslateModel(visitdateError));
		}

		const earliestdateError = this.ValidateEarliestDeliveryDate(this.EarliestDeliveryDateForValidation);

		if (earliestdateError) {
			placeOrderErrorList.push(new TranslateModel(earliestdateError));
		}

		if (multipleOrderSharingVisitdate) {
			if (this.anySelected) {
				placeOrderErrorList.push(new TranslateModel("BAG.MULTIPLEORDERSONHOLD"));
			}
		}

		if (!user.canOnlyTestOrder(customer != null) && (this.ShipToId === null && !customer?.IsNewPartner)) {
			placeOrderErrorList.push(new TranslateModel("BAG.CHOOSEADDRESS"));
		}

		if (this.bagItemsExcludingStartkitsSelected().length === 0 && user.canOrder(customer != null) && user.hasAllProductlines(this.OrderItems.map(x => x.ProductLineCode))) {
			placeOrderErrorList.push(new TranslateModel('BAG.NOORDERTOORDER'));
		}

		if (user.canOrder(customer != null) && !user.hasAllProductlines(this.OrderItems.map(x => x.ProductLineCode))) {
			placeOrderErrorList.push(new TranslateModel('BAG.MISSINGPRODUCTLINES'));
		}

		if (!user.canOrder(customer != null) && !user.canOnlyTestOrder(customer != null)) {
			placeOrderErrorList.push(new TranslateModel('BAG.CANNOTORDER'));
		}

		if (customer && customer.isConsultantOrderOnBehalf && !this.VisitDate && !this.NoVisit && !user.IsDistributor) {
			if (customer.IsPlusPartner) {
				placeOrderErrorList.push(new TranslateModel("BAG.VISITDATE.NOVISITERROR"));
			}
			else {
				placeOrderErrorList.push(new TranslateModel("BAG.VISITDATE.NOVISITERROR2"));
			}
		}

		if (customer && customer.isConsultantOrderOnBehalf && this.NoVisit && this.isThereAnythingWithSfDiscount) {
			placeOrderErrorList.push(new TranslateModel("BAG.SFDISCOUNTNOVISIT"));
		}

		if (customer && customer.isConsultantOrderOnBehalf && this.mixedFirstTimeDeliveryAndCurrentProductLines(customer)) {
			placeOrderErrorList.push(new TranslateModel("BAG.MIXEDFIRSTTIMEDELIVERANDNORMAL"));
		}

		if (customer && this.bagContainsMixedLinjestatus(startkitMappings)) {

			placeOrderErrorList.push(new TranslateModel("BAG.MIXEDSTARTKITSANDPRODUCTLINES"));
		}

		if (customer && this.bagContainsPreciousNoTitaniumAndIsntPreciousPartner(customer)) {
			placeOrderErrorList.push(new TranslateModel("BAG.MIXEDPRECIOUSANDTITANIUMWHENNOTBEINGPRECIOUSPARTNER"));
		}

		if (this.isThereAnythingDiscontinued) {
			placeOrderErrorList.push(new TranslateModel("BAG.ITEMDISCONTINUED"));
		}

		if (this.isThereAnythingNotSynced) {
			placeOrderErrorList.push(new TranslateModel("BAG.ITEMSYNCING"));
		}

		if (this.OrderItems.some(oi => oi.IsPrecious) && !this.Signature) {
			placeOrderErrorList.push(new TranslateModel("BAG.PLACE-SIGNATURE-VALIDATION-ERROR"));
		}

		this.validateDiscount(placeOrderErrorList);
		this.validateEngraving(placeOrderErrorList);

		return placeOrderErrorList;
	}

	public validateNonBlockingOrderRequirements(
		customer: CustomerModel,
		ordersWithSameVisit: Array<Order>,
		languageModel: LanguageService
	) {
		const placeOrderErrorList = ordersWithSameVisit.map(o => ({ translateModel: new Array<TranslateModel>(), orderId: o.Id }));
		placeOrderErrorList.push({ translateModel: new Array<TranslateModel>(), orderId: this.Id });

		const districtErrors = this.checkForCorrectDistrict(this);

		districtErrors.forEach(m => {
			const orderRequirementElem = placeOrderErrorList.find(e => e.orderId == m.orderId);

			if (orderRequirementElem) {
				orderRequirementElem.translateModel.push(new TranslateModel("BAG.NOTPRIMARYSALESDISTRICT"));
			}
		});

		if (customer) {
			const nonPreciousPartnerMinimumValidationErrors = this.checkForNonPreciousPartnerCustomerMinimum(customer, ordersWithSameVisit);

			nonPreciousPartnerMinimumValidationErrors.forEach(m => {
				const orderRequirementElem = placeOrderErrorList.find(e => e.orderId == m.orderId);

				if (orderRequirementElem) {
					orderRequirementElem.translateModel.push(new TranslateModel("BAG.MINIMUMNOTMET.NONPRECIOUSPARTNER", { amount: m.amount, customer: customer.Name }));
				}
			});

			if (this.OrderItemMiscs && this.OrderItemMiscs.filter(o => o.MiscModel?.NeedApprovalOnWebOrder).length > 0 || this.bagContainsPreciousWithDiscount()) {
				const orderRequirementElem = placeOrderErrorList.find(e => e.orderId == this.Id);

				if (orderRequirementElem) {
					orderRequirementElem.translateModel.push(new TranslateModel("BAG.ONREQUEST.NEEDS.APPROVAL"));
				}
			}

			const newCustomerMinimumValidationErrors = this.checkForNewOrReestablishedCustomerMinimum(customer, ordersWithSameVisit);
			newCustomerMinimumValidationErrors.forEach(m => {
				const orderRequirementElem = placeOrderErrorList.find(e => e.orderId == m.orderId);

				if (orderRequirementElem) {
					orderRequirementElem.translateModel.push(new TranslateModel("BAG.MINIMUMNOTMET.NEWCUSTOMER", { amount: m.amount, customer: customer.Name }));
				}
			});


			const minimunValidationErrors = this.checkForMinimum(customer.ProductLinesPermissions, ordersWithSameVisit);
			minimunValidationErrors.forEach(m => {
				const orderRequirementElem = placeOrderErrorList.find(e => e.orderId == m.orderId);
				if (orderRequirementElem) {
					orderRequirementElem.translateModel.push(new TranslateModel("BAG.MINIMUMNOTMET", { productLineDescription: (languageModel.instantTranslateData(m.productLine)), amount: m.amount, customer: customer.Name }));
				}
			});

			const rcRequirementCalculation = this.calculateRcDiscountPercentRequirement(ordersWithSameVisit);
			rcRequirementCalculation.forEach(m => {
				const orderRequirementElem = placeOrderErrorList.find(e => e.orderId == m.OrderId);
				if (m.ValidationError && orderRequirementElem) {
					orderRequirementElem.translateModel.push(new TranslateModel("BAG.FULLFRAMESREQUIREMENTFORRCDISCOUNT", { missingFramesCount: m.BilledFullFramesRequired - m.BilledFullFramesInBag, minFramesCount: RCDiscountModel.RC100PercentMinFramesCount }));
				}
			});

			const orderIdsWithFirsttimeDeliveryAndDiscount = this.framesWithfirstTimeDeliverySelectedWithDiscount(ordersWithSameVisit, customer, [DiscountConstants.CC, DiscountConstants.RC]);
			orderIdsWithFirsttimeDeliveryAndDiscount.forEach(m => {
				const orderRequirementElem = placeOrderErrorList.find(e => e.orderId == m.OrderId);
				if (orderRequirementElem) {
					orderRequirementElem.translateModel.push(new TranslateModel("BAG.FIRSTTIMEDELIVERYWITHDISCOUNT"));
				}
			});

			placeOrderErrorList.find(e => e.orderId == this.Id).translateModel.push(...this.framesWithRCAndNotAllowedValidation(customer, languageModel));
			ordersWithSameVisit
				.forEach(o => placeOrderErrorList
					.find(e => e.orderId == o.Id).translateModel.push(...o.framesWithRCAndNotAllowedValidation(customer, languageModel)));
		}

		return placeOrderErrorList.filter(e => e.translateModel.length > 0);
	}

	public validateEngraving(placeOrderErrorList: Array<TranslateModel>) {
		this.framesSelected().forEach(o => {
			if (o.Engraving && !isLatinCharacters(o.Engraving)) {
				placeOrderErrorList.push(new TranslateModel("CUSTOMIZER.COMPONENTS.ENGRAVING.NOTLATINERROR"));
			}
			else if (o.Engraving && o.Engraving?.startsWithEmptyString()) {
				placeOrderErrorList.push(new TranslateModel("BAG.ENGRAVINGEMPTYSTRING"));
			}
		});
	}

	public validateDiscount(placeOrderErrorList: Array<TranslateModel>) {
		this.framesSelected().forEach(o => {
			if (o.DiscountCode) {
				const discount = DiscountModelFactory.CreateDiscountModel(o.DiscountCode, o.DiscountPercent);
				const validationResult = discount.validate(o.DisountValidationState());

				if (!validationResult.isValid) {
					placeOrderErrorList.push(...validationResult.errors.map(e => new TranslateModel(e.error)));
				}
			}
		});

		this.CurrentSelectedMiscOrderItems().forEach(o => {
			if (o.DiscountCode) {
				const discount = DiscountModelFactory.CreateDiscountModel(o.DiscountCode, o.DiscountPercent);
				const validationResult = discount.validate(o.DisountValidationState());

				if (!validationResult.isValid) {
					placeOrderErrorList.push(...validationResult.errors.map(e => new TranslateModel(e.error)));
				}
			}
		});
	}

	public clearSelection(): void {
		this.OrderItems.forEach(o => o.Selected = false);
		this.OrderItemMiscs.forEach(o => o.Selected = false);
	}

	public removeAllNotSelected() {
		if (this.anySelected) {
			this.state.OrderItems = this.OrderItems.filter(x => !x.Selected).map(x => x.getState());
			this.state.OrderItemMiscs = this.OrderItemMiscs.filter(x => !x.Selected).map(x => x.getState());
		}
	}

	public get anySelected(): boolean {
		return this.OrderItems.some(x => x.Selected) || this.OrderItemMiscs.some(x => x.Selected);
	}

	// public getSortedBagItems(allModelgroups: ReadonlyArray<ModelGroupModel>): ReadonlyArray<{ productLineCode: string, isPrecious: boolean, orderitemGroupdescription: DataTranslationModel | string, items: Array<BagItemModel> }> {
	public getSortedBagItems(allModelgroups: ReadonlyArray<ModelGroupModel>): ReadonlyArray<SortedBagItem> {
		// const sortedBagItems = new Array<{ productLineCode: string, isPrecious: boolean, orderitemGroupdescription: DataTranslationModel | string, items: Array<BagItemModel> }>();
		const sortedBagItems = new Array<SortedBagItem>();

		// find all distinct productline names, join precious state
		const distinctProductlineCodes = this.OrderItems
			.filter(oi => !oi.Parts)
			.sortBy(x => x.OriginalModelGroup?.SortDescription)
			.map(x => x.IsPrecious + '#' + x.ProductLineCode + '#' + x.ModelGroupCode)
			.distinct();

		distinctProductlineCodes.forEach(preciousAndProductLineCode => {
			const isPrecious = preciousAndProductLineCode.substring(0, preciousAndProductLineCode.indexOf('#')) === 'true';
			const productlineCode = preciousAndProductLineCode.substring(preciousAndProductLineCode.indexOf('#') + 1, preciousAndProductLineCode.lastIndexOf('#'));
			const modelGroupCode = preciousAndProductLineCode.substring(preciousAndProductLineCode.lastIndexOf('#') + 1);

			const modelgroup = allModelgroups.find(x => x.ProductLineCode === productlineCode && x.Code === modelGroupCode);

			const frameBagItems = this.OrderItems
				.filter(x => 
					x.ProductLineCode === productlineCode && 
					x.IsPrecious === isPrecious && 
					x.ModelGroupCode === modelGroupCode && 
					(!x.Parts || x.Parts.length === 0)
				)
				.sortByDescending(x => x.OriginalModel?.Order || x.getState().Order);

			const newItem = {
				orderitemGroupdescription: isPrecious ? 'precious ' + modelgroup.PreciousDescription.Fallback : modelgroup.ProductLineDescription.Fallback,
				productLineCode: productlineCode,
				isPrecious: isPrecious,
				isPart: false,
				items: new Array<BagItemModel>()
			};

			frameBagItems.forEach(frameBagItem => {
				const bagItem = BagItemModel.FromOrderItem(frameBagItem);
				newItem.items.push(bagItem);
			});

			sortedBagItems.push(newItem);
		});

		const partBagItems = this.OrderItems.filter(oi => oi.Parts);

		if (partBagItems && partBagItems.length > 0) {
			const newItem = {
				orderitemGroupdescription: 'xparts',
				productLineCode: 'yparts',
				isPrecious: false,
				isPart: true,
				items: new Array<BagItemModel>()
			};

			partBagItems.forEach(partBagItem => {
				const bagItem = BagItemModel.FromOrderItem(partBagItem);
				newItem.items.push(bagItem);
			});

			sortedBagItems.push(newItem);
		}

		this.OrderItemMiscs
			.filter(x => x.Type === OrderItemMiscType.StockKeepingUnit)
			.sortBy(x => x.MiscModel?.ItemCategoryDescription)
			.forEach(order => {
				let existingBagItems = sortedBagItems.find(x => typeof x.orderitemGroupdescription === "string" && x.orderitemGroupdescription === order.MiscModel?.ItemCategoryDescription);

				if (!existingBagItems) {
					existingBagItems = {
						orderitemGroupdescription: order.MiscModel?.ItemCategoryDescription,
						productLineCode: order.MiscModel?.ItemCategoryDescription,
						isPrecious: false,
						items: new Array<BagItemModel>(),
						isPart: false
					};

					sortedBagItems.push(existingBagItems);
				}

				const bagItem = BagItemModel.FromOrderItem(order);
				existingBagItems.items.push(bagItem);
			});

		//console.warn('sortedBagItems', sortedBagItems);

		return sortedBagItems;
	}

	public getOnHoldList() {
		const orderItemsOnHoldMap = new Array<{ uuid: string, onHold: boolean }>();

		this.OrderItemMiscs.forEach(bagItem => {
			const uuid = bagItem.Uuid;
			orderItemsOnHoldMap.push({ uuid, onHold: bagItem.Selected });
		});

		this.OrderItems.forEach(bagItem => {
			const uuid = bagItem.Uuid;
			orderItemsOnHoldMap.push({ uuid, onHold: bagItem.Selected });

			const frameStartKit = this.OrderItemMiscs.filter(x => x.ProductLineCode == bagItem.ProductLineCode);
			frameStartKit.forEach(startkit => {
				startkit.Selected = bagItem.Selected;
				const uuid2 = startkit.Uuid;
				orderItemsOnHoldMap.push({ uuid: uuid2, onHold: bagItem.Selected });
			});
		});

		return orderItemsOnHoldMap;
	}

	public getCollapsedList() {
		const orderItemsCollapsedMap = new Array<{ uuid: string, collapsed: boolean }>();

		this.OrderItems.forEach(bagItem => {
			if (bagItem.IsFullFrame) {
				const uuid = bagItem.Uuid;
				orderItemsCollapsedMap.push({ uuid, collapsed: bagItem.Collapsed });
			}

			if (bagItem.SyncState !== SyncStateEnum.SyncedWithDatabase) {
				const uuid = bagItem.SyncOrderItemGuid;
				orderItemsCollapsedMap.push({ uuid, collapsed: bagItem.Collapsed });
			}
		});

		return orderItemsCollapsedMap;
	}

	bagContainsMixedLinjestatus(startkitMappings: StartkitMappingResponse[]): boolean {
		const currentOrders = this.CurrentSelectedOrderItems();
		// find all orders with a startkit mapping + filter out dublicate ProductlineCodes
		const orderitemsWithLinjeStatus = currentOrders.filter(x => startkitMappings.find(y => x.ProductLineCode === y.ProductLineCode && !y.Ordered)).map(x => x.ProductLineCode).filter((v, i, a) => a.indexOf(v) == i).length;
		const orderitemsWithoutLinjeStatus = currentOrders.length - orderitemsWithLinjeStatus;

		return (orderitemsWithLinjeStatus > 0 && orderitemsWithoutLinjeStatus > 0) || (orderitemsWithLinjeStatus > 1);
	}

	private bagContainsPreciousWithDiscount(): boolean {
		const totalSelectedPreciousFramesWithDiscountCount = this.CurrentSelectedOrderItems().filter(o => o.IsPrecious && o.DiscountCode).length;
		return totalSelectedPreciousFramesWithDiscountCount > 0
	}

	private bagContainsPreciousNoTitaniumAndIsntPreciousPartner(customer: CustomerModel): boolean {
		const totalSelectedPreciousFramesCount = this.CurrentSelectedOrderItems().filter(o => o.IsPrecious).reduce((sum, current) => sum + current.Amount, 0);
		const totalSelectedTitaniumFramesCount = this.CurrentSelectedOrderItems().filter(o => !o.IsPrecious).reduce((sum, current) => sum + current.Amount, 0);

		if (totalSelectedPreciousFramesCount > 0) {

			return !customer.IsPreciousPartner && totalSelectedTitaniumFramesCount > 0
		}
		else {
			return false;
		}
	}

	mixedFirstTimeDeliveryAndCurrentProductLines(customer: CustomerModel): boolean {
		const frameItemsSelected = this.CurrentSelectedOrderItems();
		const firstTimeDeliveryFrames = frameItemsSelected.some(item => item.isFirstTimeDelivery(customer));
		const framesWithAssortments = frameItemsSelected.some(item => !item.isFirstTimeDelivery(customer));
		return framesWithAssortments && firstTimeDeliveryFrames;
	}

	private validateAndSetAddress(shipToId: number, shipToNaame: string, addresses: AddressModel[]) {
		const address = addresses?.find(i => i.ShipToId == shipToId);
		if (address && (shipToId == null || shipToNaame == null)) {
			this.Address = address;
		}
		else if (!address && addresses?.length > 0) {
			this.Address = addresses[0]
		}
	}

	public convertToUpdateModel() {
		const model = new OrderUpdateModel();

		if (this.EarliestDeliveryDate) {
			model.EarliestDeliveryDate = new Date(this.EarliestDeliveryDate.getTime() - (this.EarliestDeliveryDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		}

		model.EarliestDeliveryDateWithUserOffset = toDateStringWithUserOffset(this.EarliestDeliveryDate)
		model.Notes = this.Notes;
		model.NotesCustomerService = this.NotesCustomerService;
		model.PaymentTerms = this.PaymentTerms;

		if (this.VisitDate) {
			model.VisitDate = new Date(this.VisitDate.getTime() - (this.VisitDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		}

		model.VisitDateWithUserOffset = toDateStringWithUserOffset(this.VisitDate)
		model.FairId = this.FairId;
		model.FairName = this.FairName;
		model.AllowPartialShipment = this.AllowPartialShipment;
		model.FullAddress = this.FullAddress;
		model.ShipToName = this.ShipToName;
		model.ShipToId = this.ShipToId;
		model.NoVisit = this.NoVisit;
		model.CheckedConfirmationEmailAddresses = this.ConfirmationEmails?.filter(t => t.Checked).map(t => t.Email);
		model.Signature = this.Signature;

		return model;
	}

	public convertToOrderCompleteModel(customer: CustomerModel, user: UserDataModel, addresses: AddressModel[], languageModel: LanguageService): OrderCompleteModel {
		const result = new OrderCompleteModel();
		result.Id = this.Id;
		result.NoVisit = this.NoVisit;
		result.IsNewPartner = customer?.IsNewPartner;
		result.Notes = this.Notes;
		result.NotesCustomerService = this.NotesCustomerService;

		this.validateAndSetAddress(this.ShipToId, this.ShipToName, addresses);

		const order: OrderState = this.getState();

		if (order.ConsultantUuid) {
			let appendToNotes = '';

			if (customer?.IsNewPartner) {
				appendToNotes = 'NEW\n';

				if (customer.Name) {
					appendToNotes += languageModel.instant("NEW.PARTNER.PARTNERNAME") + ': ' + customer.Name + '\n';
				}

				if (customer.Street) {
					appendToNotes += languageModel.instant("NEW.PARTNER.PARTNERADDRESS") + ': ' + customer.Street + '\n';
				}

				if (customer.ShipToName) {
					appendToNotes += languageModel.instant("NEW.PARTNER.FIRSTTIMEDELIVERYADDRESS") + ': ' + customer.ShipToName;
				}
			}
			else if (customer?.IsBlocked) {
				appendToNotes = 'RED\n';
			}
			else if (!customer?.IsPlusPartner) {
				appendToNotes = 'YELLOW\n';
			}

			if (result.NotesCustomerService) {
				result.NotesCustomerService += '\n' + appendToNotes;
			}
			else {
				result.NotesCustomerService = appendToNotes;
			}
		}

		result.OrderItems = this.CurrentSelectedOrderItems().map(x => x.getState());
		result.AllOrderItemUuids = this.OrderItems.map(x => x.Uuid);
		result.AllOrderItemMiscUuids = this.OrderItemMiscs.map(x => x.Uuid);

		if (this.EarliestDeliveryDate) {
			result.EarliestDeliveryDate = new Date(this.EarliestDeliveryDate.getTime() - (this.EarliestDeliveryDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		}

		// result.EarliestDeliveryDate = toLocalDateString(this.EarliestDeliveryDate);
		result.EarliestDeliveryDateWithUserOffset = toDateStringWithUserOffset(this.EarliestDeliveryDate)
		result.FairId = this.FairId;
		result.FairName = this.FairName;
		result.FullAddress = this.FullAddress;
		result.PaymentTerms = this.PaymentTerms;
		result.ShipToId = this.ShipToId;
		result.ShipToName = this.ShipToName;
		result.AllowPartialShipment = this.AllowPartialShipment;
		result.TestOrder = this.TestOrder;
		result.Uuid = this.Uuid;
		// result.VisitDate = toLocalDateString(this.VisitDate);
		if (this.VisitDate) {
			result.VisitDate = new Date(this.VisitDate.getTime() - (this.VisitDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		}
		result.VisitDateWithUserOffset = toDateStringWithUserOffset(this.VisitDate)
		result.ConfirmationEmails = this.ConfirmationEmails?.filter(e => e.Checked).map(e => e.Email);

		result.OrderItems.forEach(orderItem => {
			const model = this.OrderItems.find(x => x.Id === orderItem.Id);
			orderItem.New = model.isAnythingNew();
			orderItem.FirstTimeDelivery = FirstTimeDeliveryCalculator.IsFirstTimeDelivey(model.AllAssortments, customer ? customer.Assortments : user.Assortments, model.IsRimless, !model.Demo);
		});

		result.signature = this.Signature;

		return result;
	}

}
