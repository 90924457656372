import { Component, Input } from '@angular/core';

@Component({
	selector: 'icon-favorite-empty',
	styleUrls: ['./icon-favorite-empty.component.scss'],
	template: `
		<i class="fa-light fa-heart fa-lg"></i>
    `
})
export class IconFavoritEmptyComponent {
	@Input() isFavorite: string;
}
