export enum ModelgroupConstants {
    RIM = "RIM",
    STRIP9700 = "9700",
    SUN = "8000",
    KidTeen = "KIDTEEN",
    SPIRIT = "SPI",
    STRIP3P = "STRIP3P",
    STRIP9800 = "9800",
    BUFFALO = "1800",
    TREEBUFFALO = "1800BT"
}
