import { ComponentVariantModel } from './component-variant';
import { DataTranslationModel } from './data-translation-model';

export class ComponentVariantViewmodel {
    prefixTranslate: string;
    colorCode: string;
    description: DataTranslationModel;
    description2: DataTranslationModel;
    componentCode: string;

    constructor(componentVariantModel: ComponentVariantModel, prefixTranslate: string) {
        this.colorCode = componentVariantModel.VariantColor.Color.Code;
        this.description = componentVariantModel.VariantColor.Color.Description;
        this.description2 = componentVariantModel.VariantColor.Color.Description2;
        this.componentCode = componentVariantModel.Component.Code;

        this.prefixTranslate = prefixTranslate;
    }
}
