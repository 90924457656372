import { Component, OnInit, SimpleChanges } from '@angular/core';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { EventsService } from 'src/_services/events.service';
import { ConfigurationPlusModel } from '../../../../_models/configuration-plus-model';
import { ConfigurationViewModel } from '../../../../_models/configuration-view-model';
import { ImageService, ImageSize } from '../../../../_services/image.service';
import { SorterService } from '../../../../_services/sorter.service';
import { ModelGroupModel } from './../../../../_models/model-group';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	template: `
    <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
        <configuration-list
          [modelGroup]="modelGroup"
          [configs]="configPlusModel"
          [chosenVariants]="variants"
          [selected]="configuration"
          [image]="getImage"
          [editMode]="state === customizerStateEnum.EDITORDER"
          [(scrollPosition)]="menu.menuDetails.scrollPosition"
          (selectedChange)="setSelectedConfig($event)"
          [variantOrModelIsNew]="modelIsNew"
        ></configuration-list>
    </customizer-component-header>
  `
})

export class ConfigurationPlusComponent extends BaseCustomizerComponent implements OnInit {

	public configPlusModel: Array<ConfigurationViewModel>;

	constructor(
		public events: EventsService,
		private imageService: ImageService,
		private sorterService: SorterService,
		public configurationService: ConfigurationService
	) {
		super(events, null, configurationService);
	}

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		if (modelGroup.IsConfigurationBased) {
			return configurations.length > 0;
		}
		else {
			return configurations.filter(x => x.Fixed == true).length > 0;
		}
	}

	initData(): void {
	}

	onChanges(changes: SimpleChanges): void {
	}

	setSelectedConfig(model: ConfigurationPlusModel) {
		this.events.showLoader(true);
		this.updateConfiguration(model);
	}

	async ngOnInit() {
		this.events.showLoader(true);

		this.configPlusModel = await this.fetchConfigurations();

		this.events.showLoader(false);
	}

	getImage = (configuration: ConfigurationPlusModel) => {
		return this.imageService.GetConfigurationDetailImage(this.model, this.model.ModelGroupCode, configuration.configurationModel.ModelConfigurationCode, configuration.variants, ImageSize.Low);
	}

	async fetchConfigurations(): Promise<Array<ConfigurationViewModel>> {
		let models = new Array<ConfigurationViewModel>();

		let configurationModels = this.configurations;
		if (!this.modelGroup.IsConfigurationBased && !this.modelGroup.IsBothConfigAndVariantBased) {
			configurationModels = configurationModels.filter(config => config.Fixed == true);
		}

		const configurationWithVariants = await this.configurationService.getConfigurationsWithVariants(configurationModels,
			this.configuration,
			this.model,
			this.modelGroup,
			this.variants);

		configurationWithVariants.forEach(config => {
			models.push(new ConfigurationViewModel(this.modelGroup, config, true));
		});

		models = models.sort((a, b) => this.sorterService.sortByConfiguration(a.configurationModel.configurationModel, b.configurationModel.configurationModel));
		return models;
	}

}
