<div class="modal-body">
	<span class="close" (click)="closeAndDestroy()">
		<i class="fa-light fa-xmark"></i>
	</span>

	<div class="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-lg-6 col-lg-offset-3 client-selector" *ngIf="isReady">

		<div class="actions">
			<div class="pt-4">
				<checkbox *ngIf="hasAnyNonPartners" (checkedChange)="toogleExPartners($event)" [checked]="showExPartners" [label]="'ORDERONBEHALF.SHOW.EX' | translate"></checkbox>
				<checkbox *ngIf="hasAnyPreciousPartners" (checkedChange)="tooglePreciousPartners($event)" [checked]="showPreciousPartners" [label]="'ORDERONBEHALF.SHOW.PRECIOUS' | translate"></checkbox>
			</div>
			<div class="push" *ngIf="(offlineService.isOnlineSubject | async) && newCustomerModuleEnabled">
				<div class="loadingShopInfo">
					<ng-container *ngIf="isDoingShopInfoLookup">
						<dcg-image class="loader" [src]="'/assets/animations/Framespinner.gif'"></dcg-image>
						<span>{{'NEW.PARTNER.CHECKSHOPINFO' | translate}}</span>
					</ng-container>
					<button class="btn btn-secondary ml-5" (click)="shopInfoLookup(); newPartnerMode = false" [disabled]="isDoingShopInfoLookup">
						<span class="fa-light fa-arrows-rotate mr-2"></span> {{'NEW.PARTNER.UPDATEACCOUNTLIST' | translate}}
					</button>
				</div>
			</div>
		</div>

		<selectable-input *ngIf="shopInfoCustomers && !isDoingShopInfoLookup"
			class="customer-dropdown static"
			[startWithFocus]="true"
			[districtClass]="districtClass"
			[showSearchField]="false" [stayOpen]="true"
			[options]="shopInfoCustomers ? shopInfoCustomers : []"
			[optionsText]="getNewCustomerSelectString"
			[searchFunction]="getCustomerFilter"
			[optionGroupHeader]="newCustomersOptionGroupHeader"
			[optionGroupCount]="newCustomersOptionGroupCount"
			[optionalText]=""
			(valueChange)="onSelect($event)"
		>
		</selectable-input>

		<ng-container *ngIf="firstLookUpDone">
			<div class="fullrow mb-4" *ngIf="!isDoingShopInfoLookup && !shopInfoCustomers || (shopInfoCustomers && shopInfoCustomers.length === 0)">
				<em>{{'NEW.PARTNER.NOACCOUNTSWEREFOUND' | translate}}</em>
			</div>
			<div class="actions mb-4" *ngIf="!isDoingShopInfoLookup">
				<div class="push">
					<span>{{'NEW.PARTNER.DIDNTFINDACCOUNT' | translate}}</span>
					<button class="btn btn-secondary ml-5" (click)="newPartnerMode = !newPartnerMode" [disabled]="isDoingShopInfoLookup">
						<i class="fa-light fa-plus mr-2"></i> {{'NEW.PARTNER.CREATEACCOUNT' | translate}}
					</button>
				</div>
			</div>
			<ng-container *ngIf="newPartnerMode">
				<div class="flex-grid">
					<div class="flex-col pt-1 pr-3">{{'NEW.PARTNER.PARTNERNAME' | translate}}</div>
					<div class="break"></div>
					<div class="flex-col"><input type="text" [(ngModel)]="newPartnerName" class="form-control" /></div>
				</div>
				<div class="flex-grid">
					<div class="flex-col pt-1 pr-3">{{'NEW.PARTNER.PARTNERADDRESS' | translate}}</div>
					<div class="break"></div>
					<div class="flex-col"><input type="text" [(ngModel)]="newPartnerAddress" class="form-control" /></div>
				</div>
				<div class="flex-grid">
					<div class="flex-col pt-1 pr-3">{{'NEW.PARTNER.FIRSTTIMEDELIVERYADDRESS' | translate}} ({{'OPTIONAL' | translate}})</div>
					<div class="break"></div>
					<div class="flex-col"><input type="text" [(ngModel)]="newPartnerFirstTimeDeliveryAddress" class="form-control" /></div>
				</div>
				<div class="flex-grid">
					<div class="flex-col pt-1 pr-3">{{'NEW.PARTNER.COUNTRY' | translate}}</div>
					<div class="break"></div>
					<div class="flex-col" style="position: relative;">
						<select class="form-control" [(ngModel)]="newPartnerCountryCode">
							<option *ngFor="let country of countries" [ngValue]="country.CountryCode">
								{{country.CountryName}}
							</option>
						</select>
					</div>
				</div>
				<div class="flex-grid">
					<div class="flex-col push">
						<button class="btn btn-cta" (click)="selectNewPartner()" [disabled]="newPartnerName?.length < 4 || newPartnerAddress?.length < 4">{{'CREATE' | translate}}</button>
					</div>
				</div>
			</ng-container>
		</ng-container>

		<input (focusin)="onFocus()" [class.open]="customersOwnDistrict.length + customersOtherDistricts.length" type="text" [ngModel]="searchWord" (input)="filterCustomers($event.target.value)" (keypress)="onKeyPress($event)" class="form-control" #input />
		<selectable-input class="customer-dropdown static" [startWithFocus]="true" [districtClass]="districtClass" [showSearchField]="false" [stayOpen]="true" [options]="customersOwnDistrict" [optionsText]="getCustomerSelectString" [searchFunction]="getCustomerFilter" [optionGroupHeader]="optionGroupHeader" [optionGroupCount]="optionGroupCount" [optionalText]="user.canOrderOnBehalf && !user.IsDistributor ? ('ORDERONBEHALF.TESTFOR' | translate: {'name': user.Name}) : user.Name" (valueChange)="onSelect($event)"></selectable-input>
		<selectable-input class="customer-dropdown static" [startWithFocus]="false" [districtClass]="districtClass" [showSearchField]="false" [stayOpen]="true" [options]="customersOtherDistricts" [optionsText]="getCustomerSelectString" [searchFunction]="getCustomerFilter" [optionGroupHeader]="optionGroupHeader" [optionGroupCount]="optionGroupCount" (valueChange)="onSelect($event)" *ngIf="showOtherDistricts"></selectable-input>
	</div>
</div>