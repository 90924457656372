export { };

declare global {
    interface String {
        startsWithEmptyString(): boolean;
    }
}

String.prototype.startsWithEmptyString = function() {
    return /^\s+/.test(this);
}