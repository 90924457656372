import { FirstTimeDeliveryCalculator } from '@infrastructure/FirstTimeDeliveryCalculator';
import { BrandEnum } from '@infrastructure/brand.enum';
import { FirstPair } from '@infrastructure/firstPair.enum';
import { Gender } from '@infrastructure/gender.enum';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { ComponentCodeConstants } from './../shared/component-constants';
import { ConfigurationOrderApimodel } from './api-models/configuration-order-apimodel';
import { ModelModel } from './api-models/model';
import { ModelOrderApimodel } from './api-models/model-order-apimodel';
import { ModelgroupOrderApimodel } from './api-models/modelgroup-order-apimodel';
import { ModelsizeOrderApimodel } from './api-models/modelsize-order-apimodel';
import { ProductlineOrderApimodel } from './api-models/product-line';
import { AssortmentLineModel } from './assortment-line-model';
import { BaseOrderItem } from './base-order-item';
import { ComponentVariantModel } from './component-variant';
import { ConfigurationModel } from './configuration';
import { CustomerModel } from './customer';
import { DataTranslationModel } from './data-translation-model';
import { IDiscountValidation, IOrderItemDiscount, OrderItemToDiscountModel } from './iorderitem-discountvalidation';
import { MiscModel } from './misc-model';
import { ModelGroupModel } from './model-group';
import { ModelSizeModel } from './model-size';
import { OrderLine } from './order-line';
import { OrderLineState } from './order-line-state';
import { PriceItem } from './price-model';
import { TempleInclinationEnum } from './variant-temple-inclination';
import { OrderItemState } from './order-item-state';

export class OrderItemModel extends BaseOrderItem implements IDiscountValidation {

	constructor(private state: OrderItemState) {
		super();
	}

	public get Id(): number {
		return this.state.Id;
	}

	public get CustomerNo(): string {
		return this.state.CustomerNo;
	}

	public set CustomerNo(value: string) {
		this.state.CustomerNo = value;
	}

	public set Selected(value: boolean) {
		this.state.Selected = value;
	}

	public get Selected() {
		return this.state.Selected;
	}

	public set Collapsed(value: boolean) {
		this.state.Collapsed = value;
	}

	public get Collapsed(): boolean {
		return this.state.Collapsed;
	}

	public get Uuid(): string {
		return this.state.Uuid;
	}

	public get CreatedAt(): Date {
		return this.state.CreatedAt;
	}

	public get Reference(): string {
		return this.state.Reference;
	}
	public set Reference(value: string) {
		this.state.Reference = value;
	}

	public get Engraving(): string {
		return this.state.Engraving;
	}

	public set Engraving(value: string) {
		this.state.Engraving = value;
	}

	public get Amount(): number {
		return this.state.Amount;
	}

	public set Amount(amount: number) {
		this.state.Amount = amount;
	}

	public get Comment(): string {
		return this.state.Comment;
	}

	public set Comment(value: string) {
		this.state.Comment = value;
	}

	public get OrderId(): number {
		return this.state.OrderId;
	}

	public set OrderId(value: number) {
		this.state.OrderId = value;
	}

	public get Demo(): boolean {
		return this.state.Demo;
	}

	public get Username(): string {
		return this.state.Username;
	}

	public get IsRimless(): boolean {
		return this.state.IsRimless;
	}

	public get IsFullFrame(): boolean {
		return this.state.IsFullFrame;
	}

	public get MultiplePossibleConfigurations(): boolean {
		return this.state.MultiplePossibleConfigurations;
	}

	public get ProductLineUuid(): string {
		return this.state.ProductLineUuid;
	}

	public get ProductLineCode(): string {
		return this.state.ProductLineCode;
	}

	public get HasForms(): boolean {
		return this.state.HasForms;
	}

	public set HasForms(value: boolean) {
		this.state.HasForms = value;
	}

	public get HasFixtures(): boolean {
		return this.state.HasFixtures;
	}

	public set HasFixtures(value: boolean) {
		this.state.HasFixtures = value;
	}

	public get FirstTimeDelivery(): boolean {
		return this.state.FirstTimeDelivery;
	}

	public set FirstTimeDelivery(value: boolean) {
		this.state.FirstTimeDelivery = value;
	}

	public get DiscountCode(): string {
		return this.state.DiscountCode;
	}
	public set DiscountCode(value: string) {
		this.state.DiscountCode = value;
	}
	public get DiscountPercent(): number {
		return this.state.DiscountPercent;
	}
	public set DiscountPercent(value: number) {
		this.state.DiscountPercent = value;
	}

	public get ModelGroupUuid(): string {
		return this.state.ModelGroupUuid;
	}
	public get ModelGroupCode(): string {
		return this.state.ModelGroupCode;
	}

	public get FirstPair(): FirstPair {
		return this.state.FirstPair;
	}

	public set FirstPair(firstPair: FirstPair) {
		this.state.FirstPair = firstPair;
	}

	public get Age(): string {
		return this.state.Age;
	}

	public set Age(age: string) {
		this.state.Age = age;
	}

	public get Gender(): Gender {
		return this.state.Gender;
	}

	public set Gender(gender: Gender) {
		this.state.Gender = gender;
	}

	public get ModelGroupDescription(): DataTranslationModel {
		return this.OriginalModelGroup ? this.OriginalModelGroup.Description : DataTranslationModel.GetFallbackOnlyModel(this.state.ModelGroupDescription);
	}

	public get ModelUuid(): string {
		return this.state.ModelUuid;
	}

	public get ModelCode(): string {
		return this.state.ModelCode;
	}

	public get IdenticalModelsizeUuid(): string {
		return this.state.IdenticalModelsizeUuid;
	}

	public get ModelSizeUuid(): string {
		return this.state.ModelSizeUuid;
	}

	public get ModelSizeCode(): string {
		return this.state.ModelSizeCode;
	}

	public get ConfigurationUuid(): string {
		return this.state.ConfigurationUuid;
	}

	public get ModelConfigurationCode(): string {
		return this.state.ModelConfigurationCode;
	}

	public get ModelConfigurationUuid(): string {
		return this.state.ModelConfigurationUuid;
	}

	public get ModelConfigurationDescription(): string {
		return this.state.ModelConfigurationDescription;
	}

	public get New(): boolean {
		return this.state.New;
	}

	public set New(value: boolean) {
		this.state.New = value;
	}

	public get OriginalProductLine(): ProductlineOrderApimodel {
		return this.state.OriginalProductLine;
	}

	public get OriginalModelGroup(): ModelgroupOrderApimodel {
		return this.state.OriginalModelGroup;
	}

	public get OriginalModel(): ModelOrderApimodel {
		return this.state.OriginalModel;
	}

	public get OriginalModelSize(): ModelsizeOrderApimodel {
		return this.state.OriginalModelSize;
	}

	public get OriginalConfiguration(): ConfigurationOrderApimodel {
		return this.state.OriginalConfiguration;
	}

	public get IsPrecious(): boolean {
		return this.state.IsPrecious;
	}

	public set IsPrecious(value: boolean) {
		this.state.IsPrecious = value;
	}

	public get OrderLines(): Array<OrderLine> {
		return this.state.OrderLines.map(x => new OrderLine(x));
	}

	public set OrderLines(value: Array<OrderLine>) {
		this.state.OrderLines = value.map(x => x.getState());
	}

	public get SyncState(): SyncStateEnum {
		return this.state.SyncState;
	}

	public set SyncState(value: SyncStateEnum) {
		this.state.SyncState = value;
	}

	public get SyncOrderItemGuid(): string {
		return this.state.SyncOrderItemGuid;
	}

	public set SyncOrderItemGuid(value: string) {
		this.state.SyncOrderItemGuid = value;
	}

	public get LowTempleInclinationPossible(): number {
		return this.state.LowTempleInclinationPossible;
	}

	public set LowTempleInclinationPossible(value: number) {
		this.state.LowTempleInclinationPossible = value;
	}

	public get TempleInclination(): TempleInclinationEnum {
		return this.state.TempleInclination;
	}

	public set TempleInclination(value: TempleInclinationEnum) {
		this.state.TempleInclination = value;
	}

	public get CaseItemNo(): string {
		return this.state.CaseItemNo;
	}

	public set CaseItemNo(value: string) {
		this.state.CaseItemNo = value;
	}

	public get CaseItemVariantCode(): string {
		return this.state.CaseItemVariantCode;
	}

	public set CaseItemVariantCode(value: string) {
		this.state.CaseItemVariantCode = value;
	}
	public get Brand(): BrandEnum {
		return this.state.Brand;
	}
	public set Brand(value: BrandEnum) {
		this.state.Brand = value;
	}

	public get Price(): PriceItem {
		return this.state.Price;
	}
	public set Price(value: PriceItem) {
		this.state.Price = value;
	}

	public get PriceJson(): string {
		return this.state.PriceJson;
	}
	public set PriceJson(value: string) {
		this.state.PriceJson = value;
	}

	public get IsLimited(): boolean {
		return this.state.IsLimited;
	}

	public set IsLimited(value: boolean) {
		this.state.IsLimited = value;
	}

	public get SkipUserCheck(): boolean {
		return this.state.SkipUserCheck;
	}

	public set SkipUserCheck(value: boolean) {
		this.state.SkipUserCheck = value;
	}

	public get Parts(): string {
		return this.state.Parts;
	}

	public set Parts(value: string) {
		this.state.Parts = value;
	}

	public get SerialNumber(): string {
		return this.state.SerialNumber;
	}

	public set SerialNumber(value: string) {
		this.state.SerialNumber = value;
	}

	get AllAssortments(): Array<AssortmentLineModel> {
		let allAssortmentlines: Array<AssortmentLineModel> = [];

		allAssortmentlines = allAssortmentlines.concat(this.OriginalModel?.AssortmentLines);
		allAssortmentlines = allAssortmentlines.concat(this.OriginalModelGroup?.AssortmentLines);
		allAssortmentlines = allAssortmentlines.concat(this.OriginalModelSize?.AssortmentLines);
		allAssortmentlines = allAssortmentlines.concat(this.OriginalConfiguration?.AssortmentLines);
		allAssortmentlines = allAssortmentlines.concat(this.OrderLines?.selectMany(t => t.AllAssortmentLines));

		return allAssortmentlines.filter(a => a != null).distinct((x, y) => x.Id === y.Id);
	}

	public static DeepClone(item: OrderItemModel): OrderItemModel {
		const orderState = new OrderItemState();
		orderState.Amount = item.Amount;
		orderState.Engraving = item.Engraving;
		orderState.TempleInclination = item.TempleInclination;
		orderState.LowTempleInclinationPossible = item.LowTempleInclinationPossible;
		orderState.Comment = item.Comment;
		orderState.Reference = item.Reference;

		orderState.DiscountCode = item.DiscountCode;
		orderState.DiscountPercent = item.DiscountPercent;

		orderState.HasFixtures = item.HasFixtures;
		orderState.HasForms = item.HasForms;

		orderState.OrderId = item.OrderId;

		orderState.ConfigurationUuid = item.ConfigurationUuid;

		orderState.CustomerNo = item.CustomerNo;

		orderState.ModelConfigurationCode = item.ModelConfigurationCode;
		orderState.ModelConfigurationUuid = item.ModelConfigurationUuid;

		orderState.Age = item.Age;
		orderState.Gender = item.Gender;
		orderState.FirstPair = item.FirstPair;

		orderState.ModelConfigurationDescription = item.ModelConfigurationDescription;

		orderState.ModelGroupCode = item.ModelGroupCode;
		orderState.ModelGroupUuid = item.ModelGroupUuid;
		orderState.ModelGroupDescription = item.ModelGroupDescription.Fallback;

		orderState.ProductLineCode = item.ProductLineCode;
		orderState.ProductLineUuid = item.ProductLineUuid;

		orderState.ModelUuid = item.ModelUuid;
		orderState.ModelCode = item.ModelCode;

		orderState.ModelSizeUuid = item.ModelSizeUuid;
		orderState.ModelSizeCode = item.ModelSizeCode;

		orderState.CaseItemNo = item.CaseItemNo;
		orderState.CaseItemVariantCode = item.CaseItemVariantCode;

		orderState.Demo = item.Demo;
		orderState.IsRimless = item.IsRimless;
		orderState.IsFullFrame = item.IsFullFrame;
		orderState.MultiplePossibleConfigurations = item.MultiplePossibleConfigurations;
		orderState.SyncState = item.SyncState;
		orderState.IsPrecious = item.IsPrecious;

		orderState.IsLimited = item.IsLimited;
		orderState.Parts = item.Parts;
		orderState.SerialNumber = item.SerialNumber;

		orderState.OrderLines = [];

		item.OrderLines.forEach(v => {
			const variant = v.getState();
			const line = new OrderLineState();
			line.ComponentCode = variant.ComponentCode;
			line.ComponentDescription = variant.ComponentDescription;
			line.ComponentUuid = variant.ComponentUuid;
			line.ComponentVariantCode = variant.ComponentVariantCode;
			line.ComponentVariantDescription = variant.ComponentVariantDescription;
			line.ComponentVariantDescription2 = variant.ComponentVariantDescription2;
			line.ComponentVariantUuid = variant.ComponentVariantUuid;

			line.ComponentVariantBypass = variant.ComponentVariantBypass;
			line.SplittingData = variant.SplittingData;
			line.Optional = variant.Optional;

			line.ComponentSizeCode = variant.ComponentSizeCode;
			line.ComponentSizeDescription = variant.ComponentSizeDescription;
			line.ComponentSizeUuid = variant.ComponentSizeUuid;
			line.ComponentSizeBypass = variant.ComponentSizeBypass;

			line.ColorCode = variant.ColorCode;
			line.ColorDescription = variant.ColorDescription;
			line.ComponentColorUuid = variant.ComponentColorUuid;

			line.ColorGroupCode = variant.ColorGroupCode;
			line.ColorGroupDescription = variant.ColorGroupDescription;

			orderState.OrderLines.push(line);
		});

		return new OrderItemModel(orderState);
	}

	public static MapFromVariants(
		existingOrderItemUuid: string,
		orderId: number,
		isDemo: boolean,
		amount: number,
		comment: string,
		reference: string,
		engraving: string,
		configuration: ConfigurationModel,
		modelGroup: ModelGroupModel,
		model: ModelModel,
		modelSize: ModelSizeModel,
		variants: Array<ComponentVariantModel>,
		hasFixtures: boolean,
		hasForms: boolean,
		isSingleOrderitem: boolean,
		discount: string,
		discountPercentage: number,
		templeInclination: TempleInclinationEnum,
		isPrecious: boolean,
		age?: string,
		gender?: Gender,
		firstPair?: FirstPair,
		chosenCase?: MiscModel,
		isLimited?: boolean,
		parts?: string,
		serialNumber?: string
	): OrderItemModel {
		const orderItem = new OrderItemState();
		orderItem.OrderId = orderId;
		orderItem.Uuid = existingOrderItemUuid;
		orderItem.Amount = amount;
		orderItem.Engraving = isSingleOrderitem ? null : engraving;
		orderItem.Comment = comment;
		orderItem.Reference = reference;
		orderItem.Order = model.Order;
		orderItem.TempleInclination = templeInclination;
		orderItem.LowTempleInclinationPossible = model.LowTempleInclinationPossible;

		orderItem.ConfigurationUuid = configuration.Uuid;
		orderItem.ModelConfigurationCode = configuration.ModelConfigurationCode;
		orderItem.ModelConfigurationUuid = configuration.ModelConfigurationUuid;

		if (configuration.ModelConfigurationDescription) {
			orderItem.ModelConfigurationDescription = configuration.ModelConfigurationDescription + ": " + configuration.ModelConfigurationDescription2;
		}
		else {
			orderItem.ModelConfigurationDescription = configuration.ModelConfigurationDescription2;
		}

		orderItem.ModelGroupCode = modelGroup.Code;
		orderItem.ModelGroupUuid = modelGroup.Uuid;
		orderItem.ModelGroupDescription = modelGroup.Description.Fallback;

		orderItem.ProductLineCode = modelGroup.ProductLineCode;
		orderItem.ProductLineUuid = modelGroup.ProductLineUuid;

		orderItem.Gender = gender;
		orderItem.FirstPair = firstPair;
		orderItem.Age = age;

		orderItem.DiscountCode = discount;
		orderItem.DiscountPercent = discountPercentage;

		orderItem.ModelUuid = model.Uuid;
		orderItem.ModelCode = model.Code;

		orderItem.ModelSizeUuid = modelSize.Uuid;
		orderItem.ModelSizeCode = modelSize.Code;

		orderItem.Demo = isDemo;
		orderItem.IsRimless = modelGroup.IsRimless;
		orderItem.IsFullFrame = modelGroup.FullFrame;
		orderItem.HasFixtures = hasFixtures;
		orderItem.HasForms = hasForms;
		orderItem.MultiplePossibleConfigurations = modelGroup.IsConfigurationBased;
		orderItem.IsPrecious = isPrecious;
		orderItem.OrderLines = [];

		orderItem.IsLimited = isLimited;
		orderItem.Parts = parts;
		orderItem.SerialNumber = serialNumber;

		if (chosenCase) {
			orderItem.CaseItemNo = chosenCase.EcommerceNo;
			orderItem.CaseItemVariantCode = chosenCase.EcommerceVariantCode;
		}

		variants.forEach(variant => {
			const line = new OrderLineState();
			line.Uuid = variant.OrderLineUuid;
			line.ComponentCode = variant.Component.Code;
			line.ComponentDescription = variant.Component.Description;
			line.ComponentUuid = variant.Component.Uuid;
			line.ComponentVariantCode = variant.Code;
			line.ComponentVariantDescription = variant.Description.Fallback;
			line.ComponentVariantDescription2 = variant.Description2.Fallback;
			line.ComponentVariantUuid = variant.Uuid;
			line.ComponentVariantBypass = variant.Bypass;
			line.SplittingData = variant.SplittingData != null;
			line.Optional = variant.Optional;

			if (variant.VariantSize) {
				line.ComponentSizeCode = variant.SplittingData ? `R:${variant.SplittingData.right}/L:${variant.SplittingData.left}` : variant.VariantSize.Size;
				line.ComponentSizeDescription = variant.SplittingData ? `R:${variant.SplittingData.right}/L:${variant.SplittingData.left}` : variant.VariantSize.Description;
				line.ComponentSizeUuid = variant.VariantSize.Uuid;
				line.ComponentSizeBypass = variant.VariantSize.Bypass;
			}

			if (variant.VariantColor) {
				line.ColorCode = variant.VariantColor.Color.Code;
				line.ColorDescription = variant.VariantColor.Color.Description.Fallback;
				line.ComponentColorUuid = variant.VariantColor.Uuid;

				if (variant.VariantColor.Color.ColorGroup) {
					line.ColorGroupCode = variant.VariantColor.Color.ColorGroup.Code;
					line.ColorGroupDescription = variant.VariantColor.Color.ColorGroup.Description.Fallback;
				}
			}

			orderItem.OrderLines.push(line);
		});

		return new OrderItemModel(orderItem);
	}

	public getState = () => this.state;

	public DisountValidationState(): IOrderItemDiscount {
		return new OrderItemToDiscountModel(this.state);
	}

	public clone() {
		return new OrderItemModel(Object.assign(new OrderItemState(), this.getState()));
	}

	isFirstTimeDelivery(customer: CustomerModel): boolean {
		const result = FirstTimeDeliveryCalculator.IsFirstTimeDelivey(this.AllAssortments, customer.Assortments, this.IsRimless, !this.Demo);

		return result;
	}

	public isProductLineDiscontinued(): boolean {
		return (!this.OriginalProductLine || this.OriginalProductLine.isDiscontinued()) &&
			this.SyncState === SyncStateEnum.SyncedWithDatabase;
	}

	public isModelGroupDiscontinued(): boolean {
		return (!this.OriginalModelGroup || this.OriginalModelGroup.isDiscontinued()) &&
			this.SyncState === SyncStateEnum.SyncedWithDatabase;
	}

	public isModelDiscontinued(): boolean {
		if (this.SerialNumber) {
			return false;
		}

		return (!this.OriginalModel || this.OriginalModel.isDiscontinued()) &&
			this.SyncState === SyncStateEnum.SyncedWithDatabase;
	}

	public isModelSizeDiscontinued(): boolean {
		return (!this.OriginalModelSize || this.OriginalModelSize.isDiscontinued()) &&
			this.SyncState === SyncStateEnum.SyncedWithDatabase;
	}

	public isConfigurationDiscontinued(): boolean {
		// console.log('OriginalConfiguration', this.Id, this.OriginalConfiguration);

		const isDiscontinued = (!this.OriginalConfiguration || this.OriginalConfiguration.isDiscontinued()) && this.SyncState === SyncStateEnum.SyncedWithDatabase;

		return isDiscontinued;
	}

	public isAnythingDiscontinued(): boolean {
		if (this.SerialNumber) {
			return false;
		}

		// console.debug('DISCONTINUED', this.Id, this.isProductLineDiscontinued(), this.isModelGroupDiscontinued(), this.isModelDiscontinued(), this.isModelSizeDiscontinued(), this.isConfigurationDiscontinued());

		let isProductLineDiscontinued = this.isProductLineDiscontinued();
		let isModelGroupDiscontinued = this.isModelGroupDiscontinued();
		let isModelDiscontinued = this.isModelDiscontinued();
		let isModelSizeDiscontinued = this.isModelSizeDiscontinued();
		let isConfigurationDiscontinued = this.isConfigurationDiscontinued();
		let orderLinesDisc = this.OrderLines.some(x => x.isDiscontinued());

		// if (orderLinesDisc) {
		// 	console.log(this.OrderLines);
		// }

		let result = isProductLineDiscontinued
			|| isModelGroupDiscontinued
			|| isModelDiscontinued
			|| isModelSizeDiscontinued
			|| isConfigurationDiscontinued
			|| orderLinesDisc;

		// let result = this.isProductLineDiscontinued()
		// 	|| this.isModelGroupDiscontinued()
		// 	|| this.isModelDiscontinued()
		// 	|| this.isModelSizeDiscontinued()
		// 	|| this.isConfigurationDiscontinued()
		// 	|| this.OrderLines.some(x => x.isDiscontinued());

		return result;
	}

	public isProductLineNew(): boolean {
		return this.OriginalProductLine && this.OriginalProductLine.New;
	}

	public isModelGroupNew(): boolean {
		return this.OriginalModelGroup && this.OriginalModelGroup.New;
	}

	public isModelNew(): boolean {
		if (this.IsRimless && !this.Demo) {
			return false;
		}
		else {
			return this.OriginalModel && this.OriginalModel.New;
		}
	}

	public isModelSizeNew(): boolean {
		if (this.IsRimless && !this.Demo) {
			return false;
		}
		else {
			return this.OriginalModelSize && this.OriginalModelSize.New;
		}
	}

	public isConfigurationNew(): boolean {
		if (this.IsRimless && !this.Demo) {
			return false;
		}
		else {
			return this.OriginalConfiguration && this.OriginalConfiguration.New;
		}
	}

	public isAnythingNew(): boolean {
		return this.isProductLineNew()
			|| this.isModelGroupNew()
			|| this.isModelNew()
			|| this.isModelSizeNew()
			|| this.isConfigurationNew()
			|| this.OrderLines.some(x => x.isNew());
	}

	public shouldShowSarAcryLens() {
		const t = this.OrderLines.find(x => x.ComponentCode == ComponentCodeConstants.Lenses && (x.ColorCode == "SAR" || x.ColorCode == "ACRY"));
		return t ? t.ColorCode : null;
	}

	get isClipOnOnly(): boolean {
		return this.isSingleComponent() && this.containsComponent(ComponentCodeConstants.Clipon);
	}

	public isSingleComponent(): boolean {
		return this.OrderLines.length == 1;
	}

	public containsComponent(type: ComponentCodeConstants): boolean {
		return this.OrderLines.some(ordrline => ordrline.ComponentCode == type);
	}

	public containsDiscount(): boolean {
		return this.DiscountCode != null && this.DiscountCode.length > 0;
	}

	public MapToModelSize(): ModelSizeModel {
		if (this.SyncState === SyncStateEnum.SyncedWithDatabase) {
			const modelSize = new ModelSizeModel(this.OriginalModelSize?.Discontinued, this.OriginalModelSize?.New);
			modelSize.Uuid = this.ModelSizeUuid;
			modelSize.Code = this.ModelSizeCode;
			modelSize.Hbox = this.OriginalModelSize?.Hbox;
			return modelSize;
		}
		else {
			const modelSize = new ModelSizeModel(this.OriginalModelSize?.Discontinued, this.OriginalModelSize?.New);
			modelSize.Uuid = this.ModelSizeUuid;
			modelSize.Code = this.ModelSizeCode;

			if (this.ModelSizeCode && this.ModelSizeCode.indexOf('#') > -1) {
				modelSize.Hbox = Number(this.ModelSizeCode.substring(0, this.ModelSizeCode.indexOf('#')));
			}

			return modelSize;
		}
	}

	public EngravingMaxLength(): number {
		const templeComponent = this.OrderLines.find(o => o.OriginalComponent.Code == ComponentCodeConstants.Temple);
		return templeComponent ? templeComponent.OriginalComponentVariant?.EngravingPossible : 0;
	}

	public MapToConfiguration(configuration: ConfigurationModel): ConfigurationModel {
		return configuration;
	}

}
