import { LanguageService } from '@services/language.service';
import { StartkitMappingResponse } from './api-models/startkit-mapping-response-model';
import { CustomerModel } from './customer';
import { Order } from './order';
import { OrderStatus } from './order-status';
import { TranslateModel } from './translate-model';
import { UserDataModel } from './user-data-model';

export class OrdersModel {

	private orders: Array<Order>;
	private currentSelectedOrder: Order;

	constructor(orders: Array<Order>) {
		this.orders = orders;
	}

	public get allOrders() {
		return this.orders;
	}

	public set currentOrder(order: Order) {
		this.currentSelectedOrder = order;
	}

	public get currentOrder(): Order {
		return this.currentSelectedOrder;
	}

	public removeOrder(order: Order) {
		this.orders = this.allOrders.filter(x => x.Id !== order.Id);
	}

	public addOrder(order: Order) {
		this.allOrders.push(order);
	}

	public getIndexOfOrder(order: Order) {
		return this.orders.indexOf(order);
	}

	public getIndexOfOrderId(orderId: number) {
		return this.orders.indexOf(this.orders.find(x => x.Id === orderId));
	}

	public get length() {
		return this.allOrders.length;
	}

	AnyLocked(): boolean {
		return this.orders.some(x => x.IsLocked);
	}

	public canBeOrdered(customer: CustomerModel, user: UserDataModel, startinkitMappings: StartkitMappingResponse[]) {
		const ordersToOrder = this.ordersSharingVisitdate;

		if (ordersToOrder.length === 0) {
			return false;
		}

		return this.validateBlockingOrderRequirements(customer, user, startinkitMappings);
	}

	private getOrdersOnSameVisitDate(visitDate: Date, orderStatus: OrderStatus) {
		return this.allOrders.filter(x => x.VisitDate != null && !x.NoVisit && x.VisitDate.isDateMonthYearEqual(visitDate) && !x.IsLocked);
	}

	private getOrdersSharingNoVisit(orderStatus: OrderStatus) {
		return this.allOrders.filter(x => x.NoVisit === true && !x.IsLocked);
	}

	get ordersSharingVisitdate(): Array<Order> {
		if (!this.currentSelectedOrder) {
			return [];
		}
		else if (this.currentSelectedOrder.anySelected) {
			return [this.currentSelectedOrder];
		}

		if (this.currentSelectedOrder.VisitDate && !this.currentSelectedOrder.NoVisit) {
			return this.getOrdersOnSameVisitDate(this.currentSelectedOrder.VisitDate, this.currentSelectedOrder.Status)
		}
		else if (this.currentSelectedOrder.NoVisit === true) {
			return this.getOrdersSharingNoVisit(this.currentSelectedOrder.Status)
		}
		else {
			return [this.currentSelectedOrder];
		}
	}

	public validateBlockingOrderRequirements(customer: CustomerModel, user: UserDataModel, startinkitMappings: StartkitMappingResponse[]): Array<{ orderId: number, validationResults: Array<TranslateModel> }> {
		if (!this.currentOrder) {
			return [];
		}
		else {
			const allOrdersSharingVisitdate = this.ordersSharingVisitdate;

			const errorTranslationModels = new Array<{ orderId: number, validationResults: Array<TranslateModel> }>();

			allOrdersSharingVisitdate.forEach(order => {
				const validations = order.validatePlaceOrderRequirements(customer, user, allOrdersSharingVisitdate.length > 1, startinkitMappings);

				if (validations.length) {
					errorTranslationModels.push({
						orderId: order.Id,
						validationResults: validations
					});
				}
			});

			return errorTranslationModels;
		}
	}

	public validateNonBlockingOrderRequirements(customer: CustomerModel, user: UserDataModel, languageService: LanguageService): Array<{ orderId: number, validationResults: Array<TranslateModel> }> {
		if (!this.currentOrder) {
			return [];
		}
		else {
			const allOrdersSharingVisitdate = this.ordersSharingVisitdate;
			const errorTranslationModel = this.currentOrder.validateNonBlockingOrderRequirements(customer, allOrdersSharingVisitdate.filter(x => x.Uuid != this.currentOrder.Uuid), languageService);

			if (errorTranslationModel.length) {
				const validationErrors = errorTranslationModel.map(e => ({
					orderId: e.orderId,
					validationResults: e.translateModel
				}));

				return validationErrors;
			}

			return [];
		}
	}

}
