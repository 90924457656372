import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EnvService } from './env.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService {
    appInsights: Promise<ApplicationInsights>;
    constructor(envService: EnvService) {

        this.appInsights = envService.ApplicationInsightsKey().then(insightskey => {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: insightskey,
                    enableAutoRouteTracking: true // option to log all route changes
                }
            });
            if(environment.production){
                appInsights.loadAppInsights();
            }
            return appInsights;
        }).catch((e) => {
            console.debug(e);
            return null;
        });

    }

    async setUserId(authUserId: string) {
        if(environment.production){
            (await this.appInsights)?.setAuthenticatedUserContext(authUserId);
        }
    }
    logPageView(name?: string, url?: string) { // option to call manually
        this.executeWhenAppInsightsReady(appInsights => {
            appInsights.trackPageView({
                name: name,
                uri: url
            })
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.executeWhenAppInsightsReady(appInsights => {
            appInsights.trackEvent({ name: name }, properties);
        });
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.executeWhenAppInsightsReady(appInsights => {
            appInsights.trackMetric({ name: name, average: average }, properties);
        });
    }

    logException(exception: Error, severityLevel?: number) {
        this.executeWhenAppInsightsReady(appInsights => {
            appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.executeWhenAppInsightsReady(appInsights => {
            appInsights.trackTrace({ message: message }, properties);
        });
    }

    private executeWhenAppInsightsReady(toExecute: (appInsights: ApplicationInsights) => void) {
        this.appInsights.then((ai) => {
            if(ai){
                toExecute(ai);
            }
        })
    }

}