import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'list-group',
    styleUrls: ['./list-group.component.scss'],
    template: `
        <div class="list-group {{districtClass}}" [hidden]="!showList">
            <button
                type="button"
                class="list-group-item"
                (mousedown)="onSelect(null)"
                *ngIf="optionalText"
            >{{optionalText}}</button>
            <ng-container *ngFor="let search of searches; let index = index">
            <div class="optionGroupHeader pt-5 p-2" *ngIf="optionGroupCount > 1 && optionGroupHeader(search, searches, index)">
                {{optionGroupHeader(search, searches, index)}}
            </div>
            <button
                type="button"
                class="list-group-item"
                
                [class.selected]="defaultValue && optionsText(search) === optionsText(defaultValue)"
                (mousedown)="onSelect(search)"
            >{{optionsText(search)}}</button>
            </ng-container>
        </div>
    `
})
export class ListGroupComponent<T> {
    @Input() optionalText?: string;
    @Input() districtClass?: string;
    @Input() optionsText: (t: T) => string;
    @Input() optionGroupHeader: (t: T, tArr: Array<T>, index: number) => string;
    @Input() optionGroupCount: number;
    @Input() defaultValue: T;
    @Input() stayOpen = false;
    @Input() showList = this.stayOpen;
    @Input() searches: Array<T> = [];
    @Output() listClicked = new EventEmitter<T>();

    onSelect(elem: T) {
        this.listClicked.emit(elem);
    }


}
