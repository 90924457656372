import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { ModelsService } from '@services/v2/models.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { ModelGroupModel } from 'src/_models/model-group';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ModalService } from '../../../../_services/modal.service';
import { NavigateService } from '../../../../_services/navigate.service';
import { CustomizerStateConstants } from '../../../../shared/customizer-state-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'extra-innerrim',
	styleUrls: ['./extra-innerrim.scss'],
	template: `
      <customizer-component-header class="header"
                                  [menu]="menu"
                                  (updateCollapsedOnComponent)="updateCollapsed($event)"
                                  [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.EXTRAINNERRIM.HEADER'">
        <div class="flex flex-grow flex-column has-padding-horizontal-30px">
            <div class="flex flex-grow flex-column inline-scroll">
                <p *ngIf="!showInfoWhenNewOrder" [innerHTML]="'CUSTOMIZER.COMPONENTS.EXTRAINNERRIM.INFO1' | translate | lindbergFont"></p>
                <div *ngIf="!showInfoWhenNewOrder" class="innerrim-container">
                    <dcg-image class="innerrim-picture" [src]="'/assets/images/extra_innerrim/extra_innerrim.png'" [alt]="'CUSTOMIZER.COMPONENTS.EXTRAINNERRIM.INFO1' | translate"></dcg-image>
                </div>
                <p *ngIf="showInfoWhenNewOrder" [innerHTML]="'CUSTOMIZER.COMPONENTS.EXTRAINNERRIM.INFO3' | translate | lindbergFont"></p>
                <p [innerHTML]="'CUSTOMIZER.COMPONENTS.EXTRAINNERRIM.INFO2' | translate | lindbergFont"></p>
            </div>
            <div>
                <button (click)="goToExtraInnerRim()" class="add-rims-button btn btn-primary btn-block">{{'CUSTOMIZER.COMPONENTS.EXTRAINNERRIM.ADDRIMS' | translate}}</button>
                <button *ngIf="showInfoWhenNewOrder || state === customizerStateConstants.ADDEDORDER" id="norims" (click)="noRims()" class="add-rims-button btn btn-secondary btn-block">{{'CUSTOMIZER.COMPONENTS.EXTRAINNERRIM.NORIMS' | translate}}</button>
            </div>
        </div>
      </customizer-component-header>

    `
})
export class ExtraInnerrimComponent extends BaseCustomizerComponent implements OnChanges {

	public showInfoWhenNewOrder: boolean = false;
	public customizerStateConstants = CustomizerStateConstants;

	constructor(
		public events: EventsService,
		public navigateService: NavigateService,
		public modalService: ModalService,
		public dataService: DataApiService,
		private modelsService: ModelsService,
		public configurationService: ConfigurationService
	) {
		super(events, dataService, configurationService);
	}

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		return true;
	}

	async initData() {
	}

	onChanges(changes: SimpleChanges): void {
		if (changes.state && changes.state.currentValue != CustomizerStateConstants.NEWORDER) {
			this.showInfoWhenNewOrder = false;
		}
	}

	async goToExtraInnerRim() {
		if (this.state == CustomizerStateConstants.NEWORDER && !this.showInfoWhenNewOrder) {
			this.showInfoWhenNewOrder = true;
			return;
		}

		this.events.showLoader(true);
		const modelGroup = await this.modelsService.getModelGroup(this.modelGroup.ParentModelGroupUuid);

		const modelGroupChild = modelGroup.ModelGroupChildren.find(x => !x.FullFrame);

		await this.navigateService.navigateToCustomizer(modelGroupChild, this.model, this.modelSize);
	}

	async noRims() {
		this.updateCurrentMainComponent(null);
	}

}
