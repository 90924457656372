import { ComponentBindingModel } from 'src/_models/component-binding';
import { ComponentDependencyModel } from 'src/_models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { IDependencyCalculator } from "./dependency-calculator.interface";

export class BindingMatchingDependencyCalculator implements IDependencyCalculator {
    constructor(private filterBindings: (x: Array<ComponentBindingModel>, variant: ComponentVariantModel) => Array<ComponentBindingModel>, private oppositeDependencyCalculators: Array<IDependencyCalculator>) {
    }

    async getChanges(
        dependency: ComponentDependencyModel,
        bindings: Array<ComponentBindingModel>,
        variant: ComponentVariantModel,
        oppositChosenVariants: Array<ComponentVariantModel>,
        variantSelectionToCheckAgainst: Array<ComponentVariantModel>): Promise<Array<ComponentVariantChangeModel>> {
        const bindingsFound = this.filterBindings(bindings, variant);
        if (!bindingsFound.length) {
            return [];
        }

        return await this.getOppositeVariantChanges(dependency, bindings, variant, oppositChosenVariants, variantSelectionToCheckAgainst, bindingsFound);
    }

    private async getOppositeVariantChanges(
        dependency: ComponentDependencyModel,
        bindings: Array<ComponentBindingModel>,
        variantChange: ComponentVariantModel,
        oppositChosenVariants: Array<ComponentVariantModel>,
        variantSelectionToCheckAgainst: Array<ComponentVariantModel>,
        lastBindings: Array<ComponentBindingModel>): Promise<Array<ComponentVariantChangeModel>> {
        const result: Array<ComponentVariantChangeModel> = [];

        const newOpposites = variantSelectionToCheckAgainst.filter(x => bindings.some(b => b.ComponentId === x.Component.Id));

        for (const opposite of newOpposites.filter(vari => !lastBindings.some(x => x.ComponentId === vari.Component.Id))) {
            let changes: Array<ComponentVariantChangeModel>;
            for (const calculator of this.oppositeDependencyCalculators) {
                changes = await calculator.getChanges(dependency, bindings, opposite, newOpposites, variantSelectionToCheckAgainst, lastBindings);
                if (changes.length) {
                    result.push(...changes);
                    break;
                }
            }
        }

        return result;
    }
}
