import { animate, keyframes, style, transition, trigger } from "@angular/animations";

export function BadgePopTransition() {
    return trigger('newOrderTransition', [
        transition("void => *", [animate(0)]),
        transition('* => *', [
            animate(300, keyframes([
                // gør lille
                style({
                    height: "18px",
                    width: "18px",
                    fontSize: "10px",
                    offset: 0.20
                }),
                // gør stor
                style({
                    height: "22px",
                    width: "22px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    offset: 0.90
                }),
                // gør normal
                style({
                    offset: 1
                }),
            ]))
        ])
      ]);
}
