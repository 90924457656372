import { Component, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ModelsService } from '@services/v2/models.service';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { VariantColorModel } from 'src/_models/variant-color';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantModel } from '../../../../_models/component-variant';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { MiscModel } from '../../../../_models/misc-model';
import { DataApiService } from '../../../../_services/data-api.service';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'groove-color',
	styleUrls: ['./groove-color.scss'],
	template: `
    <customizer-component-header *ngIf="!matchedGrooveColour" [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
        <customizer-colors-auto-update *ngIf="selectableVariants.length"
            [selected]="getSelectedVariant.VariantColor"
            [selectedColorGroup]="currentColorGroup"
            [variants]="selectableVariants"
            [matchableMisc]="miscModels"
            [editMode]="state === customizerStateEnum.EDITORDER"
            [matchableColors]="matchableColors"
            [useColorGroup]="menu.contentGrouping"
            (miscMatchChange)="buyGroovePen($event)"
            (selectedColorGroupChange)="onSelectGroup($event)"
            (selectedColorChange)="onSelect($event)"
            [(scrollPosition)]="menu.menuDetails.scrollPosition"
            [variantOrModelIsNew]="variantOrModelIsNew"
            [matchColor]="menu.matchColor">
        </customizer-colors-auto-update>
    </customizer-component-header>
    <precious-metal-info></precious-metal-info>
    
    <customizer-upselling *ngIf="matchedGrooveColour"
        [miscModel]="matchedGrooveColour"
        [reference]="this.reference"
        [menu]="this.menu"
        (clicked)="closeUpselling()"
    ></customizer-upselling>
  `
})
export class GrooveColorComponent extends BaseCustomizerComponent {

	selectableVariants: Array<ComponentVariantFromApiModel> = [];
	miscModels: Array<MiscModel> = [];
	matchedGrooveColour: MiscModel;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		public imageService: ImageService,
		private modelsService: ModelsService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		this.selectableVariants = await this.fetchData();
		console.warn('this.selectableVariants', this.selectableVariants);

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	async fetchData(): Promise<Array<ComponentVariantFromApiModel>> {
		const variant = this.getSelectedVariant;
		variant.VariantColorViewed = true;

		this.miscModels = await this.modelsService.getMiscModels();
		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid, variant.VariantSize.Uuid);


		await this.RunDisablingSystem(apidata);
		return apidata;
	}

	onSelect(color: VariantColorModel) {
		this.events.showLoader(true);

		const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		newVariant.VariantColor = color;
		newVariant.NotSelected = color == null;
		this.updateVariant(new ComponentVariantChangeModel(oldVariant, newVariant, null));
	}

	buyGroovePen(miscModel: MiscModel) {
		this.matchedGrooveColour = miscModel;
	}

	closeUpselling() {
		this.matchedGrooveColour = null;
	}

}
