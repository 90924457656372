import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeZh from '@angular/common/locales/zh';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { VisibleDirective } from '@directives/visible.directive';
import { SimpleModelModelTagsCompositeHandler } from '@infrastructure/tags/handlers/simple-model-model-tags-composite-handler';
import { TranslateModule } from '@ngx-translate/core';
import { DataTranslatePipe } from '@pipes/data-translate.pipe';
import { DateToLocalPipe } from '@pipes/date.pipe';
import { FirstWordMobilePipe } from '@pipes/first-word-mobile.pipe';
import { FixBreaksPipe } from '@pipes/fix-breaks.pipe';
import { ImagePipe } from '@pipes/image.pipe';
import { LindbergFontPipe } from "@pipes/lindberg-font.pipe";
import { ModelToImageUrlPipe } from '@pipes/model-to-image-url.pipe';
import { ModelGroupToProductlineImage } from '@pipes/modelGroupToProductlineImage.pipe';
import { NbspPipe } from '@pipes/nbsp.pipe';
import { NOWStyleSmallPipe } from '@pipes/now-style-small.pipe';
import { NOWStylePipe } from '@pipes/now-style.pipe';
import { NOWTitlePipe } from '@pipes/now-title.pipe';
import { PluralTranslatePipe } from '@pipes/plural-translate.pipe';
import { RemoveWhiteSpacePipe } from '@pipes/removewhitespace.pipe';
import { ReplaceHashPipe } from '@pipes/replace-hash.pipe';
import { SafeHtml } from '@pipes/safeHtml.pipe';
import { StartkitMappingPipe } from '@pipes/startkit-mapping.pipe';
import { StartkitPipe } from '@pipes/startkit.pipe';
import { MomentModule } from 'ngx-moment';
import { OnlineStatusModule } from 'ngx-online-status';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PreloadDirective } from 'src/_directives/preload.directive';
import { IconsModule } from './../icons/icons.module';
import { AlertComponent } from './components/alert/alert.component';
import { AvailabilityComponent } from './components/availability/availability-text.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ColorSelectorFullComponent } from "./components/color-selector-full/color-selector-full.component";
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { CustomizerDiscountsComponent } from './components/customizer-discounts/customizer-discounts.component';
import { CustomizerPriceComponent } from './components/customizer-price/customizer-price.component';
import { DatePickerComponent } from "./components/date-picker/date-picker.component";
import { DCGImageComponent } from './components/dcg-image/dcg-image';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input';
import { DropDownListComponent } from './components/dropdown-input/dropdown-list/dropdown-list.component';
import { ErrorSummaryComponent } from './components/error-summary/error-summary.component';
import { FadeContainerComponent } from './components/fade-container/fade-container.component';
import { FavoriteCollectionOverlayComponent } from './components/favorite-collection-overlay/favorite-collection-overlay.component';
import { FavoriteCollectionSaveOverlayComponent } from './components/favorite-collection-save-overlay/favorite-collection-save-overlay.component';
import { FeaturedModelOverlayComponent } from './components/featured-model-overlay/featured-model-overlay.component';
import { FirstTimeDeliveryComponent } from './components/first-time-delivery/first-time-delivery.component';
import { DCGSliderComponent } from './components/image-slider/dcg-slider';
import { InViewLazyLoadComponent } from './components/image-slider/inview-lazyload-component';
import { InputAddonComponent } from './components/input-addon/input-addon.component';
import { InputComponent } from './components/input/input.component';
import { ItemTemplateComponent } from './components/item-template/item-template.component';
import { ListGroupComponent } from './components/list-group/list-group.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay';
import { MenuOverlayComponent } from './components/menu-overlay/menu-overlay';
import { MiniProfilerComponent } from './components/mini-profiler.component';
import { MiscButtonsComponent } from './components/misc-buttons/misc-buttons.component';
import { MiscContentComponent } from './components/misc-content-component/misc-content.component';
import { MiscImageComponent } from './components/misc-image-component/misc-image.component';
import { MiscListItemComponent } from './components/misc-list-item/misc-list-item';
import { NotificationComponent } from "./components/notification/notification.component";
import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { OrderingInfoComponent } from './components/ordering-info';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PagerComponent } from './components/pager/pager.component';
import { PlusMinusComponent } from "./components/plus-minus/plus-minus.component";
import { PopupComponent } from './components/popup/popup.component';
import { PriceViewerComponent } from './components/price-viewer/price-viewer.component';
import { ProductTitleComponent } from './components/product-title/product-title.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SelectableButtonComponent } from './components/selectable-button/selectable-button';
import { SelectableInputComponent } from './components/selectable-input/selectable-input';
import { SiteTitleComponent } from './components/site-title';
import { SkeletonScreenComponent } from './components/skeleton-screen/skeleton-screen.component';
import { SlideItemComponent } from './components/slide-item';
import { SliderComponent } from './components/slider/slider.component';
import { RatioWrapperComponent } from './ratio-wrapper/ratio-wrapper.component';
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeJa, 'ja');
registerLocaleData(localeZh, 'za');
registerLocaleData(localeKo, 'ko');

@NgModule({
	declarations: [
		DCGImageComponent,
		ColorSelectorComponent,
		ColorSelectorFullComponent,
		ReplaceHashPipe,
		PlusMinusComponent,
		ImagePipe,
		ModelGroupToProductlineImage,
		SafeHtml,
		NOWStylePipe,
		NOWStyleSmallPipe,
		NOWTitlePipe,
		DateToLocalPipe,
		ModelToImageUrlPipe,
		NotificationComponent,
		DatePickerComponent,
		DCGSliderComponent,
		AlertComponent,
		LoadingOverlayComponent,
		SiteTitleComponent,
		SlideItemComponent,
		AvailabilityComponent,
		OrderingInfoComponent,
		PageHeaderComponent,
		PopupComponent,
		SearchInputComponent,
		VisibleDirective,
		FixBreaksPipe,
		MenuOverlayComponent,
		CopyrightComponent,
		ListGroupComponent,
		SelectableInputComponent,
		SelectableButtonComponent,
		DropdownInputComponent,
		DropDownListComponent,
		MiniProfilerComponent,
		ItemTemplateComponent,
		LindbergFontPipe,
		InViewLazyLoadComponent,
		CheckboxComponent,
		PreloadDirective,
		MiscListItemComponent,
		FeaturedModelOverlayComponent,
		MiscButtonsComponent,
		MiscContentComponent,
		MiscImageComponent,
		ProductTitleComponent,
		RatioWrapperComponent,
		ButtonComponent,
		InputComponent,
		InputAddonComponent,
		ErrorSummaryComponent,
		PluralTranslatePipe,
		FirstTimeDeliveryComponent,
		CustomizerDiscountsComponent,
		DataTranslatePipe,
		SkeletonScreenComponent,
		NotificationsDropdownComponent,
		CustomizerPriceComponent,
		FavoriteCollectionOverlayComponent,
		FavoriteCollectionSaveOverlayComponent,
		PagerComponent,
		FadeContainerComponent,
		PriceViewerComponent,
		NbspPipe,
		RemoveWhiteSpacePipe,
		FirstWordMobilePipe,
		StartkitMappingPipe,
		StartkitPipe,
		SliderComponent
	],
	providers: [
		SimpleModelModelTagsCompositeHandler
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		UiSwitchModule,
		TranslateModule,
		MomentModule,
		RouterModule,
		IconsModule,
		OnlineStatusModule,
		MatSliderModule
	],
	bootstrap: [],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
	exports: [
		DCGImageComponent,
		ColorSelectorComponent,
		ColorSelectorFullComponent,
		ReplaceHashPipe,
		PlusMinusComponent,
		ImagePipe,
		ModelGroupToProductlineImage,
		SafeHtml,
		NOWStylePipe,
		DateToLocalPipe,
		NOWStyleSmallPipe,
		ModelToImageUrlPipe,
		NOWTitlePipe,
		NotificationComponent,
		DatePickerComponent,
		DCGSliderComponent,
		AlertComponent,
		LoadingOverlayComponent,
		SiteTitleComponent,
		SlideItemComponent,
		AvailabilityComponent,
		OrderingInfoComponent,
		PageHeaderComponent,
		PopupComponent,
		SearchInputComponent,
		VisibleDirective,
		FixBreaksPipe,
		MenuOverlayComponent,
		CopyrightComponent,
		SelectableInputComponent,
		SelectableButtonComponent,
		DropdownInputComponent,
		MiniProfilerComponent,
		ItemTemplateComponent,
		LindbergFontPipe,
		InViewLazyLoadComponent,
		CheckboxComponent,
		PreloadDirective,
		MiscListItemComponent,
		FeaturedModelOverlayComponent,
		MiscButtonsComponent,
		MiscContentComponent,
		MiscImageComponent,
		ProductTitleComponent,
		RatioWrapperComponent,
		IconsModule,
		ButtonComponent,
		InputComponent,
		InputAddonComponent,
		ErrorSummaryComponent,
		PluralTranslatePipe,
		FirstTimeDeliveryComponent,
		CustomizerDiscountsComponent,
		DataTranslatePipe,
		SkeletonScreenComponent,
		FavoriteCollectionOverlayComponent,
		FavoriteCollectionSaveOverlayComponent,
		CustomizerPriceComponent,
		PagerComponent,
		PriceViewerComponent,
		NbspPipe,
		RemoveWhiteSpacePipe,
		FirstWordMobilePipe,
		StartkitMappingPipe,
		StartkitPipe,
		MatSliderModule
	]
})
export class SharedModule { }
