import { environment } from './environments/environment';

export const AppConstants = {
	partnerSite: 'https://lindberg.com/login',
	newsUrl: 'https://partner.lindberg.com/Umbraco/Api/LDCGApi/Pages',
	technicalInfoUrl: 'https://lnk.lindberg.com/BDB802',
	trackerUrl: "https://stats.lindberg.com/",
	websupportUrl: 'https://partner.lindberg.com/websupport?origin=customiser',
	trackerId: 6,
	languages: ['en', 'zh', 'ja', 'ko', 'de', 'es', 'fr', 'it'],
	version: environment.version,
	allowedUnauthenticatedUrlParts: ['orderconfirmation']
};
