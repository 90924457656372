import { Type } from "class-transformer";
import { ComponentModel } from './component';
import { SimpleComponentVariantModel } from './component-variant';
import { VariantColorModel } from './variant-color';
import { SimpleVariantSizeModel } from './variant-size';

export class OrderLineState {

	public Id: number;
	public Uuid: string;
	public ComponentVariantDescription: string;
	public ComponentVariantDescription2: string;
	public ComponentSizeDescription: string;
	public ColorDescription: string;
	public ColorGroupDescription: string;
	public ComponentDescription: string;

	public ComponentCode: string;
	public ComponentVariantCode: string;
	public ComponentSizeCode: string;
	public ColorCode: string;
	public ColorGroupCode: string;

	public ComponentVariantUuid: string;
	public ComponentSizeUuid: string;
	public ComponentColorUuid: string;
	public ComponentUuid: string;

	public ComponentVariantBypass: boolean;
	public ComponentSizeBypass: boolean;

	public SplittingData: boolean;
	public Optional: boolean;
	public EngravingPossible: number;

	@Type(() => ComponentModel)
	public OriginalComponent: ComponentModel;

	@Type(() => SimpleComponentVariantModel)
	public OriginalComponentVariant: SimpleComponentVariantModel;

	@Type(() => SimpleVariantSizeModel)
	public OriginalComponentSize: SimpleVariantSizeModel;

	@Type(() => VariantColorModel)
	public OriginalComponentColor: VariantColorModel;

}
