import { Injectable } from '@angular/core';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { WhichOrderEnum } from '@infrastructure/which-order-enum';
import { OrderCreateModel } from '@models/api-models/order-create-model';
import { OrderItemMiscState } from '@models/order-item-misc-model';
import { OrderItemState } from '@models/order-item-state';
import QueuedOrderItemStateChangedData from '@models/queued-order-item-state-changed-data';
import { Mutex } from 'async-mutex';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseService } from './base.service';
import { CustomerDataService } from './customer-data.service';
import { DataApiService } from './data-api.service';
import { LoginService } from './login.service';
import { MenuService } from './menu.service';
import { OfflineService } from './offline.service';
import { OrderOnBehalfService } from './order-on-behalf.service';
import { OrdersDataService } from './orders-data.service';
import { QueueItemService } from './queue-item.service';
import { UserService } from './user.service';
import { ServiceUtils } from './utils/service.utils';

@Injectable({
    providedIn: 'root'
})

export class OrderSyncService extends BaseService {
    private queuedOrderItemStateChanged = new Subject<object>();

    private orderItemQueueSyncRunDone = new Subject<object>();

    private maxErrorsBeforeCancel: number = 0;
    private syncMutex = new Mutex();
    constructor(
        private dataApiService: DataApiService,
        private customerDataService: CustomerDataService,
        private queueItemService: QueueItemService,
        private loginService: LoginService,
        private userService: UserService,
        private ordersDataService: OrdersDataService,
        private offlineService: OfflineService,
        private serviceUtils: ServiceUtils,
        private menuService: MenuService,
        private onBehalfService: OrderOnBehalfService
    ) {
        super();
        this.subscriptions.push(this.queueItemService.orderItemPutInStore.asObservable()
            .pipe(debounceTime(200))
            .subscribe(async () => {
                console.debug("ordersync startet: item added to queue");
                await this.HandleSyncOrderItems();
            }));

        this.subscriptions.push(this.offlineService.isOnlineSubject
            .pipe(debounceTime(200)).subscribe(async () => {
                console.debug("ordersync startet: offline/online event happend");
                await this.HandleSyncOrderItems();
            }))

        this.userService.OrderSyncDisabledObservable()
            .pipe(debounceTime(200)).subscribe(async () => {
                console.debug("ordersync startet: ordersync option changed event");
                await this.HandleSyncOrderItems();
            });

        this.subscriptions.push(this.loginService.LoggedInUserObservable().subscribe((user) => {
            if (user && !user.IsLiteLogin) {
                this.LoadOrdersToCache();
            }
        }));
    }

    public QueuedOrderItemStateChangedObservable() {
        return this.queuedOrderItemStateChanged.asObservable();
    }

    public OrderItemQueueSyncRunDone() {
        return this.orderItemQueueSyncRunDone.asObservable();
    }

    private async HandleSyncOrderItems() {
        const release = await this.syncMutex.acquire();
        try {
            await this.SyncOrderItems();
        } finally {
            release();
        }
    }

    private raiseQueuedOrderItemStateChanged(itemFinished: boolean, orderId: number, isMiscItem: boolean) {
        this.queuedOrderItemStateChanged.next(new QueuedOrderItemStateChangedData(itemFinished, orderId, isMiscItem));
    }

    private async SyncOrderItems() {

        if (this.offlineService.isOnlineValue && this.loginService.IsLoggedIn() && this.userService.UserCanUseNonBlockingOfflineAddTobag() && !this.userService.OrderSyncDisabled()) {

            const userCustomerNo = (await this.userService.GetUser()).CustomerNo;
            const queuedOrderItems = await this.queueItemService.getReadyOrderItemsInQueue(null, true);
            const customersWithChanges: Array<string> = [];
            let newlyCreatedOrderId: number = null;

            for (let i = 0; i < queuedOrderItems?.length; i++) {
                var queuedOrderItem = queuedOrderItems[i];
                queuedOrderItem.SyncState = SyncStateEnum.Running;
                await this.queueItemService.updateOrderItemInQueue(queuedOrderItem);

                this.raiseQueuedOrderItemStateChanged(false, queuedOrderItem.OrderId, false);

                try {
                    if (queuedOrderItem.OrderId === WhichOrderEnum.CreateNewOrder) {
                        let orderNumber;
                        if (newlyCreatedOrderId) {
                            orderNumber = newlyCreatedOrderId
                        } else {
                            const canOnlyTestOrder = await this.userService.UserCanOnlyPlaceTestorder(queuedOrderItem.CustomerNo !== userCustomerNo);
                            orderNumber = await this.ordersDataService.createNewOrder(queuedOrderItem.CustomerNo !== userCustomerNo ? OrderCreateModel.OrderOnBehalfCreateModel(queuedOrderItem.CustomerNo, null) : new OrderCreateModel(canOnlyTestOrder), queuedOrderItem.CustomerNo);
                            newlyCreatedOrderId = orderNumber;
                        }

                        const orderItemNewOrderNumber: OrderItemState = Object.assign({}, queuedOrderItem);
                        orderItemNewOrderNumber.OrderId = orderNumber;

                        await this.queueItemService.removeOrderItemInQueue(queuedOrderItem);
                        queuedOrderItem = orderItemNewOrderNumber;
                        await this.queueItemService.createOrderItemInQueue(queuedOrderItem);
                    }

                    if (queuedOrderItem.OrderId === WhichOrderEnum.ChooseFirstOrder) {
                        const existingOrders = (await this.dataApiService.getOrderIds(queuedOrderItem.CustomerNo)).filter(x => !x.IsLocked);
                        const firstExistingOrder = existingOrders?.first();

                        let newOrderId = firstExistingOrder?.Id;
                        if (!newOrderId) {
                            const canOnlyTestOrder = await this.userService.UserCanOnlyPlaceTestorder(queuedOrderItem.CustomerNo !== userCustomerNo);
                            newOrderId = await this.ordersDataService.createNewOrder(queuedOrderItem.CustomerNo !== userCustomerNo ? OrderCreateModel.OrderOnBehalfCreateModel(queuedOrderItem.CustomerNo, null) : new OrderCreateModel(canOnlyTestOrder), queuedOrderItem.CustomerNo);
                        }

                        const orderItemNewOrderNumber: OrderItemState = Object.assign({}, queuedOrderItem);
                        orderItemNewOrderNumber.OrderId = newOrderId;

                        await this.queueItemService.removeOrderItemInQueue(queuedOrderItem);
                        queuedOrderItem = orderItemNewOrderNumber;
                        await this.queueItemService.createOrderItemInQueue(queuedOrderItem);
                    }

                    const newOrderItem = await this.ordersDataService.postOrderItem(queuedOrderItem, true);
                    await this.ordersDataService.addOrderItemToOrderStore(newOrderItem);

                    queuedOrderItem.SyncState = SyncStateEnum.SyncedWithDatabase;
                    await this.queueItemService.updateOrderItemInQueue(queuedOrderItem);

                    customersWithChanges.push(queuedOrderItem.CustomerNo);

                    this.raiseQueuedOrderItemStateChanged(true, queuedOrderItem.OrderId, false);

                } catch (error) {
                    if (this.serviceUtils.errorIsOffline(error)) {
                        console.debug('Offline state occured in sync process. Ignoring error');
                        queuedOrderItem.SyncState = SyncStateEnum.Pending;
                    } else {
                        console.error('SyncOrderItems', error);
                        queuedOrderItem.SyncErrors += 1;
                        if (queuedOrderItem.SyncErrors > this.maxErrorsBeforeCancel) {
                            queuedOrderItem.SyncState = SyncStateEnum.Failed;
                        }
                    }

                    await this.queueItemService.updateOrderItemInQueue(queuedOrderItem);

                    this.raiseQueuedOrderItemStateChanged(false, queuedOrderItem.OrderId, false);
                }
            }

            const queuedMiscOrderItems = await this.queueItemService.getReadyMiscOrderItemsInQueue(null, true);
            for (let i = 0; i < queuedMiscOrderItems?.length; i++) {
                let queuedMiscOrderItem = queuedMiscOrderItems[i];
                queuedMiscOrderItem.SyncState = SyncStateEnum.Running;
                await this.queueItemService.updateMiscOrderItemInQueue(queuedMiscOrderItem);

                this.raiseQueuedOrderItemStateChanged(false, queuedMiscOrderItem.OrderId, true);

                try {
                    if (queuedMiscOrderItem.OrderId === WhichOrderEnum.CreateNewOrder) {
                        let orderNumber;
                        if (newlyCreatedOrderId) {
                            orderNumber = newlyCreatedOrderId;
                        } else {
                            const canOnlyTestOrder = await this.userService.UserCanOnlyPlaceTestorder(queuedMiscOrderItem.CustomerNo !== userCustomerNo);
                            orderNumber = await this.ordersDataService.createNewOrder(queuedMiscOrderItem.CustomerNo !== userCustomerNo ? OrderCreateModel.OrderOnBehalfCreateModel(queuedMiscOrderItem.CustomerNo, null) : new OrderCreateModel(canOnlyTestOrder), queuedMiscOrderItem.CustomerNo);
                            newlyCreatedOrderId = orderNumber;
                        }

                        const miscOrderItemNewOrderNumber: OrderItemMiscState = Object.assign({}, queuedMiscOrderItem);
                        miscOrderItemNewOrderNumber.OrderId = orderNumber;

                        await this.queueItemService.removeMiscOrderItemInQueue(queuedMiscOrderItem);
                        queuedMiscOrderItem = miscOrderItemNewOrderNumber;
                        await this.queueItemService.createMiscOrderItemInQueue(queuedMiscOrderItem);
                    }

                    if (queuedMiscOrderItem.OrderId === WhichOrderEnum.ChooseFirstOrder) {
                        const existingOrders = (await this.dataApiService.getOrderIds(queuedMiscOrderItem.CustomerNo)).filter(x => !x.IsLocked);
                        const firstExistingOrder = existingOrders?.first();

                        let newOrderId = firstExistingOrder?.Id;
                        if (!newOrderId) {
                            const canOnlyTestOrder = await this.userService.UserCanOnlyPlaceTestorder(queuedMiscOrderItem.CustomerNo !== userCustomerNo);
                            newOrderId = await this.ordersDataService.createNewOrder(queuedMiscOrderItem.CustomerNo !== userCustomerNo ? OrderCreateModel.OrderOnBehalfCreateModel(queuedMiscOrderItem.CustomerNo, null) : new OrderCreateModel(canOnlyTestOrder), queuedMiscOrderItem.CustomerNo);
                        }

                        const miscOrderItemNewOrderNumber: OrderItemMiscState = Object.assign({}, queuedMiscOrderItem);
                        miscOrderItemNewOrderNumber.OrderId = newOrderId;

                        await this.queueItemService.removeMiscOrderItemInQueue(queuedMiscOrderItem);
                        queuedMiscOrderItem = miscOrderItemNewOrderNumber;
                        await this.queueItemService.createMiscOrderItemInQueue(queuedMiscOrderItem);
                    }

                    const newMiscOrderItem = await this.ordersDataService.postMiscItemFromState(queuedMiscOrderItem, true);
                    await this.ordersDataService.addMiscOrderItemToOrderStore(newMiscOrderItem);

                    queuedMiscOrderItem.SyncState = SyncStateEnum.SyncedWithDatabase;
                    await this.queueItemService.updateMiscOrderItemInQueue(queuedMiscOrderItem);

                    customersWithChanges.push(queuedMiscOrderItem.CustomerNo);

                    this.raiseQueuedOrderItemStateChanged(true, queuedMiscOrderItem.OrderId, true);
                } catch (error) {
                    if (this.serviceUtils.errorIsOffline(error)) {
                        console.debug('Offline state occured in sync process. Ignoring error');
                        queuedMiscOrderItem.SyncState = SyncStateEnum.Pending;
                    } else {
                        console.error('SyncOrderItems (misc)', error);

                        queuedMiscOrderItem.SyncErrors += 1;
                        if (queuedMiscOrderItem.SyncErrors > this.maxErrorsBeforeCancel) {
                            queuedMiscOrderItem.SyncState = SyncStateEnum.Failed;
                        }
                    }

                    await this.queueItemService.updateMiscOrderItemInQueue(queuedMiscOrderItem);

                    this.raiseQueuedOrderItemStateChanged(false, queuedMiscOrderItem.OrderId, true);
                }
            }

            await this.onBehalfService.updateOrderRelevantData();
            this.orderItemQueueSyncRunDone.next(null);
        }
    }

    public async LoadOrdersToCache() {
        if (this.userService.UserCanUseNonBlockingOfflineAddTobag()) {
            try {
                const allCustomers = await this.customerDataService.getCustomersData(false);
                if (allCustomers?.length > 0 && allCustomers.first().isConsultantOrderOnBehalf) {
                    await this.dataApiService.getStartKits(); // need to load startkits to indexeddb to be able to add to bag offline
                }

                await this.dataApiService.getOwnAddresses();

                const customersWithOrders = await this.customerDataService.getCustomersData(true);
                const customerNosWithOrders = customersWithOrders.map(c => c.No);
                const userCustomerNo = await (await this.userService.GetUser()).CustomerNo

                await this.ordersDataService.getOrdersData(userCustomerNo);
                customerNosWithOrders.push(userCustomerNo);

                for (const customerNo of customerNosWithOrders) {
                    await this.ordersDataService.getOrdersData(customerNo);
                }

                await this.menuService.getMenus();
            } catch (error) {
                // ignore errors
            }
        }
    }
}
