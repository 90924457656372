<div class="featured-container" *ngIf="isReady || showPageInitializingSkeleton" [@fadeInOutTransition]>
    <ng-container *ngIf="isReady">
        <notification [notifier]="enduserDisplayClickedObserveable" [modal]="displayEnduserModalComponent">
            <div text>
                <span class="font-medium">{{'HOME.ENDUSERDISPLAY.READMORE1' | translate}} </span>
                <span>{{'HOME.ENDUSERDISPLAY.READMORE2' | translate}}</span>
            </div>
        </notification>
        <div class="tag-header p-3 pt-5" *ngIf="favoriteCollections.length > 0">
            <h2>{{'MY.COLLECTIONS' | translate}}</h2>
            <span class="mr-2 mb-4 filter-button" *ngFor="let favoriteCollection of favoriteCollections">
                <ui-button
					[buttonType]="'button'"
					[buttonStyle]="'secondary'"
					[size]="'sm'"
					[block]="false"
                    [active]="favoriteCollection.Selected"
					(click)="selectFavoriteCollection(favoriteCollection)"
				>
                    <!-- <icon-favorite-empty *ngIf="favoriteCollection.Icon"></icon-favorite-empty> -->
                    <span [ngClass]="{'has-icon': favoriteCollection.Icon }">
                        {{favoriteCollection.Name}}
                        <ng-container *ngIf="favoriteCollection.HitCount > 0">
							({{favoriteCollection.HitCount}})
                        </ng-container>
                    </span>
                </ui-button>
            </span>
        </div>
    </ng-container>

    <ng-container *ngIf="!isReady && showPageInitializingSkeleton">
        <div class="tag-skeleton"></div>
    </ng-container>

    <div class="container-fluid group">
        <div class="content" *ngIf="!showPageInitializingSkeleton || isReady">
            <div class="row mt-5">
                <div class="col-xs-4">
                    <h1 *ngIf="isFavorites">{{selectedFavoriteCollection.Name}}</h1>
                    <h1 *ngIf="!isFavorites" [innerHTML]="inspirationGroup.Name | translate | lindbergFont"></h1>
                </div>
                <div class="col-xs-8 pt-1">
                    <div class="admin-functions-container">
                        <div>
                            <div class="refresh-page mr-2" *ngIf="refreshOptionText">
                                {{refreshOptionText}}
                                <button (click)="refresh()" class="btn btn-primary-xs ml-2 mr-2">
                                    <i class="fa-light fa-arrows-rotate"></i>
                                </button>
                            </div>
                            <ng-container *ngIf="selectedFavoriteCollection && selectedFavoriteCollection.Id !== 0">

                                <div class="edit-favorite-collection-input mr-5" *ngIf="editFavoriteCollection">
                                    <input type="text" [(ngModel)]="selectedFavoriteCollection.Name" maxlength="20"
                                        placeholder="favorite collection name" class="form-control mr-2">
                                    <button (click)="saveFavoriteCollection(selectedFavoriteCollection); "
                                        class="btn btn-cta btn-xs">
                                        {{ 'COMMON.SAVE' | translate }}
                                    </button>
                                </div>

                                <div class="delete-favorite-collection-prompt mr-5"
                                    *ngIf="deleteFavoriteCollectionPrompt">
                                    {{ 'COMMON.DELETE' | translate }} '{{selectedFavoriteCollection.Name}}'?
                                    <button (click)="deleteFavoriteCollection(selectedFavoriteCollection)"
                                        class="btn btn-cta btn-xs ml-2 mr-2">
                                        {{ 'COMMON.YES' | translate }}
                                    </button>
                                    <button (click)="deleteFavoriteCollectionPrompt = false"
                                        class="btn btn-secondary btn-xs">
                                        {{ 'COMMON.NO' | translate }}
                                    </button>
                                </div>

								<button class="btn btn-secondary btn-xs mr-1 p-1"
                                    (click)="editFavoriteCollection = !editFavoriteCollection; deleteFavoriteCollectionPrompt = false">
                                    <i class="fa-solid fa-pencil edit-favorite-collection"></i>
                                </button>

                                <button class="btn btn-secondary btn-xs mr-3 p-1"
                                    (click)="deleteFavoriteCollectionPrompt = !deleteFavoriteCollectionPrompt; editFavoriteCollection = false">
                                    <i class="fa-solid fa-trash delete-favorite-collection"></i>
                                </button>
                            </ng-container>

							<button class="btn btn-cta btn-xs mr-3"
                                [disabled]="manualConfigsFuture.length === 0 && manualConfigs.length === 0"
                                *ngIf="isFavorites" (click)="addAllToBag()">
                                {{ 'MY.COLLECTIONS.ADDALLTOBAG' | translate }}
                            </button>

							<div class="hidden-function" *ngIf="hasInspirationEditPermission && adminModeEnabled">
                                <small class="text-muted">{{'FEATURED.HIDDEN' | translate}}</small>
                                <ui-switch class="ui-switch" [checked]="inspirationGroup.IsHidden"
                                    (change)="toggleHide($event)" size='small' class="ui-switch-margins"></ui-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

			<div class="row mb-3">
                <div class="col-xs-8 col-lg-10 mb-4">
                    <ng-container *ngIf="modelgroups.length > 1">
                        <span class="mr-2 mt-2 filter-button {{modelgroup.KidTeen ? 'kidteen' : ''}}"
                            *ngFor="let modelgroup of modelgroups">
                            <ui-button [buttonType]="'button'" [buttonStyle]="'secondary'" [size]="'xs'" [block]="false"
                                [active]="selectedModelGroup?.Code === modelgroup.Code" (click)="selectModelGroup(modelgroup)">
                                <span>
                                    <ng-container
                                        *ngIf="modelgroup.ShortDescription?.Fallback && modelgroup.ProductLineCode !== 'TT'">
                                        {{modelgroup.ShortDescription}}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="!modelgroup.ShortDescription?.Fallback || modelgroup.ProductLineCode === 'TT'">
                                        {{modelgroup.Description}}
                                    </ng-container>
                                    <ng-container *ngIf="modelgroup.KidTeen"> - {{'BREADCRUMBS.KIDTEEN' | translate}}
                                    </ng-container>
                                </span>
                            </ui-button>
                        </span>
                    </ng-container>
                </div>
                <div class="col-xs-4 col-lg-2 mb-4">
                    <span class="mr-2 mt-2 pull-right freetext-search">
                        <input #searchfield type="search" class="form-control input-sm freetext-search-input"
                            placeholder="{{ 'MY.COLLECTIONS.SEARCH' | translate}}" [(ngModel)]="freeTextSearchword"
                            maxlength="37" (keypress)="onKeyPress($event)" />
                        <span class="search-button" (click)="search()">
                            <i *ngIf="!searching && !freeTextSearchword" class="fa-light fa-magnifying-glass"></i>
                            <i *ngIf="!searching && freeTextSearchword" class="fa-light fa-xmark" (click)="freeTextSearchword=null;getInspirations()"></i>
                            <dcg-image *ngIf="searching" class="loader" [src]="'/assets/animations/Framespinner.gif'"></dcg-image>
                        </span>
                    </span>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">

                    <ng-container *ngIf="manualConfigsFuture.length === 0 && manualConfigs.length === 0 && hasLoadedModels">
                        <div class="favorites-empty" *ngIf="isFavorites">
                            <ng-container *ngIf="!freeTextSearchword">
                                <span>{{'FEATURED.NOFAVORITES.TEXT1' | translate}}</span>
                                <icon-favorite-empty class="mr-2"></icon-favorite-empty>
                                <span>{{'COMMON.OR' | translate}}</span>
                                <icon-my-collections class="ml-3" style="display: inline-block;"
                                    [isInCollection]="true">
                                </icon-my-collections>
                                <span>{{'FEATURED.NOFAVORITES.TEXT2' | translate}}</span>
                            </ng-container>
                            <ng-container *ngIf="freeTextSearchword">
                                <span>{{'FEATURED.NOFAVORITES.TEXT3' | translate}}</span>
                            </ng-container>
                        </div>

                        <div class="favorites-empty" *ngIf="!isFavorites">
                            <span>{{'FEATURED.EMPTYCOLLECTION' | translate}}</span>
                        </div>
                    </ng-container>

					<skeleton-screen [hasLoaded]="!skeletonScreens || hasLoadedModels" [screens]="skeletonScreens"
                        screenClass="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 {{ showAsFrame ? 'inspiration-group' : '' }}">

                        <div class="scroller-section" infiniteScroll [infiniteScrollDistance]="1"
                            [infiniteScrollThrottle]="50" [infiniteScrollContainer]="'.container-fluid'"
                            (scrolled)="onScroll()" [fromRoot]="true">
                            <div class="inspirations-container" *ngIf="manualConfigsFuture.length > 0">
                                <ng-container *ngFor="let inspiration of manualConfigsFuture">
                                    <ng-container
                                        *ngIf="inspiration.Model && inspiration.ModelSize && inspiration.ModelConfiguration">
                                        <featured-model class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                                            [userPermissions]="userData"
                                            [hasInspirationEditPermission]="hasInspirationEditPermission && adminModeEnabled"
                                            [allManualConfigGroups]="allInspirationGroups"
                                            [featuredConfiguration]="inspiration" [inspirationGroup]="inspirationGroup"
                                            [isDisplay]="isDisplay" [showAsFrame]="showAsFrame"
                                            [isSavingToBag]="inspiration.IsSavingToBag"
                                            [showAddedToBag]="inspiration.ShowAddedToBag"
                                            [isAddingToTestBag]="inspiration.IsAddingToTestBag"
                                            [showStartKitAddedToBag]="inspiration.ShowStartKitAddedToBag"
                                            [isInCollection]="inspiration.IsInCollection"
                                            (hasBeenDeleted)="deleteInspirationConfiguration($event)"
                                            (toCustomizer)="onNavigateToCustomizer($event)"
                                            (applyFavoriteChangeEmitter)="applyFavoriteChange()"
                                            (applyFavoriteCollectionChangeEmitter)="applyFavoriteCollectionChange()">
                                        </featured-model>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="inspirations-container" *ngIf="manualConfigs.length > 0">
                                <ng-container *ngFor="let inspiration of manualConfigs">
                                    <ng-container
                                        *ngIf="inspiration.Model && inspiration.ModelSize && inspiration.ModelConfiguration">
                                        <featured-model class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
                                            [userPermissions]="userData"
                                            [hasInspirationEditPermission]="hasInspirationEditPermission && adminModeEnabled"
                                            [allManualConfigGroups]="allInspirationGroups"
                                            [featuredConfiguration]="inspiration" [inspirationGroup]="inspirationGroup"
                                            [isDisplay]="isDisplay" [showAsFrame]="showAsFrame"
                                            [isSavingToBag]="inspiration.IsSavingToBag"
                                            [showAddedToBag]="inspiration.ShowAddedToBag"
                                            [isAddingToTestBag]="inspiration.IsAddingToTestBag"
                                            [showStartKitAddedToBag]="inspiration.ShowStartKitAddedToBag"
                                            [isInCollection]="inspiration.IsInCollection"
                                            (hasBeenDeleted)="deleteInspirationConfiguration($event)"
                                            (toCustomizer)="onNavigateToCustomizer($event)"
                                            (applyFavoriteChangeEmitter)="applyFavoriteChange()"
                                            (applyFavoriteCollectionChangeEmitter)="applyFavoriteCollectionChange()">
                                        </featured-model>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="fetchingNewItems" class="spinner">
                            <img loading="lazy" src="/assets/animations/Framespinner.gif" height="64" width="64" />
                        </div>
                    </skeleton-screen>
                </div>
            </div>
        </div>
        <div *ngIf="isReady">
            <copyright></copyright>
        </div>
    </div>
</div>