import { Component, SimpleChanges } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { TempleInclination, TempleInclinationEnum, templeInclinationToSelectList } from '@models/variant-temple-inclination';
import { BrandModeService } from '@services/brandmode.service';
import { ConfigurationService } from '@services/configuration.service';
import { ImageService } from '@services/image.service';
import { LanguageService } from '@services/language.service';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { VariantSizeModel } from 'src/_models/variant-size';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
    selector: 'temple-length',
    templateUrl: 'temple-length.html',
    styleUrls: ['temple-length.scss'],
    animations: [
        FadeInOutTransition()
    ]
})

export class TempleLengthComponent extends BaseCustomizerComponent {

    public sizes: Array<VariantSizeModel> = [];
    public templeInclinationList: Array<TempleInclination> = [];
    public selectedLengthIndex: number = 0;
    public selectedTempleInclinationIndex: number;
    public showInfo: boolean;

    brandMode: BrandEnum;

    public brandEnum: typeof BrandEnum = BrandEnum;

    constructor(
        public events: EventsService,
        public apiService: DataApiService,
        private sorter: SorterService,
        private imageService: ImageService,
        private languageService: LanguageService,
        private brandModeService: BrandModeService,
        public configurationService: ConfigurationService
    ) {
        super(events, apiService, configurationService);
    }

    onChanges(changes: SimpleChanges): void {
        this.selectedLengthIndex = this.sizes.findIndex(x => x.Uuid == this.getSelectedVariant.VariantSize.Uuid);
        this.selectedTempleInclinationIndex = Number(this.selectedTempleInclination);
    }

    async initData() {
        this.events.showLoader(true);

        this.setImageSliderByName(AngleConstants.Perspective);
        this.sizes = await this.fetch();
        this.selectedLengthIndex = this.sizes.findIndex(x => x.Uuid == this.getSelectedVariant.VariantSize.Uuid);

        this.selectedTempleInclinationIndex = Number(this.selectedTempleInclination);
        this.templeInclinationList = templeInclinationToSelectList(this.languageService);

        this.brandModeService.currentBrand.subscribe(cb => {

            this.brandMode = cb;
        });

        this.events.showLoader(false);
    }

    public templeInclinationStandardInfoImage = () => {
        return this.imageService.GetTempleInclinationStandardInfoImage();
    }

    public templeInclinationLowInfoImage = () => {
        return this.imageService.GetTempleInclinationLowInfoImage();
    }

    onSizeSelect(index: number) {
        this.events.showLoader(true);

        const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        newVariant.VariantSize = this.sizes[index];
        this.updateComponentSize(new ComponentVariantChangeModel(oldVariant, newVariant, null));
    }

    onInclinationSelect(index: number) {
        this.events.showLoader(true);
        this.updateTempleInclination(index as TempleInclinationEnum);
    }

    async fetch(): Promise<Array<VariantSizeModel>> {
        const variant = this.getSelectedVariant;
        variant.VariantSizeViewed = true;

        const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid);
        await this.RunDisablingSystem(apidata);
        const variantSizes = apidata[0].VariantSizes.sort(this.sorter.sortBy(VariantSizeModel));

        return variantSizes;
    }
    getVoid = () => {
        return null;
    }
}
