export class OrderUpdateModel {

	public Id: number;
	public Uuid: string;
	public Notes: string;
	public NotesCustomerService: string;
	public VisitDate: string;
	public VisitDateWithUserOffset: string;
	public EarliestDeliveryDate: string;
	public EarliestDeliveryDateWithUserOffset: string;
	public FairId: number;
	public FairName: string;
	AllowPartialShipment: boolean;
	PaymentTerms: string;
	FullAddress: string;
	ShipToName: string;
	ShipToId: number;
	NoVisit: boolean;
	CheckedConfirmationEmailAddresses: string[];
	public Signature: string;

}
