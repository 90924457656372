import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SelectableInputBaseComponent<T> implements OnInit, OnDestroy {
    @Input() options: Array<T>;
    @Input() defaultValue: T;
    @Input() optionalText?: string;
    @Input() districtClass?: string;
    @Input() optionsText: (t: T) => string;
    @Input() optionGroupHeader: (t: T) => string;
    @Input() searchFunction: (searchString: string, t: T) => boolean;
    @Input() stayOpen = false;
    @Input() startWithFocus: boolean = false;
    @Output() valueChange = new EventEmitter<T>();

    public subscriptions: Array<Subscription> = [];
    public trigger = new Subject<string>();
    public searches: Array<T> = [];
    public value: string;
    public showList: boolean = this.stayOpen;

    @HostListener('focusout', ["$event"])
    public onFocusOut(e: FocusEvent) {
        if(!this.stayOpen) {
            this.showList = false;
        }
    }

    public ngOnInit() {
        setTimeout(() => {
            if (!this.startWithFocus) {
                this.resetSearchValueToSelectedValue();
            }
        }, 0);
        this.subscriptions.push(this.trigger.pipe(debounceTime(300)).pipe(distinctUntilChanged()).subscribe(x => {
            this.setSearchData(x);
            this.value = x;
        }));
    }

    public resetSearchValueToSelectedValue() {
        this.value = this.defaultValue ? this.optionsText(this.defaultValue) : this.optionalText;
    }

    public startSearch(text: string) {
        this.trigger.next(text);
    }

    public setSearchData(searchString: string) {
        const result =  searchString.length > 0
        ? this.findOptions(searchString)
        : this.options;

        this.searches = result || [];
    }

    private findOptions(text: string) {
        return this.options.filter(x => {
            return this.searchFunction(text, x);
        });
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    abstract onSelect(search: T): void;
}
