import { Component, Input, OnInit } from '@angular/core';
import { VariantColorModel } from '@models/variant-color';
import { ImageService } from '@services/image.service';
import { ComponentCodeConstants } from '@shared/component-constants';

@Component({
    selector: 'customizer-color-view-item',
    templateUrl: 'customizer-color-view-item.html',
    styleUrls: ['./customizer-color-view-item.scss']
})
export class CustomizerColorViewItemComponent implements OnInit {
    @Input() color: VariantColorModel;

    public extraTallColor: boolean;
    public mediumTallColor: boolean;
    public showComponentName: boolean;
    public modelGroups: string;

    constructor(public imageService: ImageService) {
    }

    ngOnInit() {
        this.extraTallColor = this.color.ComponentCode && this.color.ComponentCode == ComponentCodeConstants.Lenses || this.color.ComponentCode == ComponentCodeConstants.Clipon;

        // Future feature
        // if (this.color.AvailableOnModelgroups && this.color.AvailableOnModelgroups.length > 0) {
        //     this.modelGroups = this.color.AvailableOnModelgroups.join(", ");
        // }

        if (this.color.ComponentCode == ComponentCodeConstants.Clipon && this.color.ComponentCode) {
            this.showComponentName = true;
        }
    }
}
