<div class="wrapper">
<div id="dropdown-container" [@displayAnim]="state">
    <ul id="container-list">
        <li (click)="gotoOrderInfo()">
            <p>{{ 'ORDERS' | translate }}</p>
        </li>
        <li (click)="ShowDelivery()">
            <p>{{ 'OPTIONS-DROPDOWN.DELIVERY-TIMES' | translate }}</p>
        </li>
        <li (click)="ShowLegal()">
            <p>{{ 'OPTIONS-DROPDOWN.LEGAL' | translate }}</p>
        </li>
        <li (click)="ShowLanguages()">
            <p>
                {{'OPTIONS-DROPDOWN.LANGUAGE' | translate:{ language: 'OPTIONS-DROPDOWN.LANGUAGES.' + currentLanguage | translate  } }}
            </p>
            <i class="fa-light fa-angle-right"></i>
            <ul id="language-list" class="second-level-menu" [ngStyle]="{'display':currentMenu !== 'languages' ? 'none' : 'inherit' }">
                <ng-container *ngFor="let language of availableLanguages">
                    <li [attr.lang]="language" (click)="changeLanguage(language, $event)" [ngClass]="{'selected': language === currentLanguage}">
                        <i *ngIf="language === currentLanguage" class="fa-light fa-check"></i>
                        <p>{{'OPTIONS-DROPDOWN.LANGUAGES.' + language | translate}}</p>
                    </li>
                </ng-container>
            </ul>
        </li>
        <li (click)="ShowPriceSettings()" *ngIf="appState.priceModuleEnabled.getValue() && currentPriceSetting">
            <p>
                {{'OPTIONS-DROPDOWN.PRICE' | translate }}: {{currentPriceSetting?.PriceSettingDescription | translate}}
            </p>
            <i class="fa-light fa-angle-right"></i>
            <ul id="price-settings-list" class="second-level-menu" [ngStyle]="{'display':currentMenu !== 'price' ? 'none' : 'inherit' }">
                <ng-container *ngFor="let priceSetting of priceSettings">
                    <li (click)="changePriceSetting(priceSetting, $event)" [ngClass]="{'selected': priceSetting.PriceSetting === currentPriceSetting.PriceSetting}">
                        <i *ngIf="priceSetting.PriceSetting === currentPriceSetting.PriceSetting" class="fa-light fa-check"></i>
                        <p>{{priceSetting.PriceSettingDescription | translate}}</p>
                    </li>
                </ng-container>
            </ul>
        </li>
        <li (click)="ShowSupport()">
            <p>{{ 'OPTIONS-DROPDOWN.SUPPORT' | translate }}</p>
        </li>
        <li *ngIf="showAdminModeToggle" (click)="AdminModeToggle()">
            <p>{{adminModelEnabled ? 'disable admin mode' : 'enable admin mode'}}</p>
        </li>
        <li *ngIf="showAdminModeToggle" (click)="goToServiceManager()">
            <p>service manager...</p>
        </li>
        <li (click)="ShowLogOut()">
            <p>{{ 'HOMEFUNCTIONS.LOGOUT' | translate }}</p>
        </li>
    </ul>
</div>
</div>