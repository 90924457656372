<div class="gemstone">
    <customizer-component-header [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.GEMSTONE.NAME' | translate"
        [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">

        <div class="customizer-item" (click)="toggleGemstoneOptions()"
            [ngClass]="{'is-selected': selectedSizeIndex === -1 && !selectedColor}">

            <div class="contentContainer">

                <div class="content">
                    <span class="name">{{'CUSTOMIZER.COMPONENTS.GEMSTONE.NOGEMSTONE' | translate}}</span>
                </div>

                <div class="img" *ngIf="noGemstoneImage">
                    <dcg-image [src]="noGemstoneImage"
                        alt="{{'CUSTOMIZER.COMPONENTS.GEMSTONE.NOGEMSTONE' | translate}}"></dcg-image>
                </div>
            </div>
        </div>

    </customizer-component-header>

    <div class="mt-4" *ngIf="sizes.length">
        <customizer-component-header [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.GEMSTONE.CARAT' | translate"
            [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
            <customizer-list class="bordered" [noneChoiceTranslation]="noneChoiceTranslation"
                [selectedIndex]="selectedSizeIndex" (selectedChange)="onSelect($event, selectedColor)" [items]="sizes"
                [name]="'Description'" [discontinued]="'Discontinued'"
                [editMode]="state === customizerStateEnum.EDITORDER" [variantOrModelIsNew]="variantOrModelIsNew"
                [(scrollPosition)]="menu.menuDetails.scrollPosition" [image]="getImage">
            </customizer-list>
        </customizer-component-header>
    </div>

    <div class="mt-4" *ngIf="selectedSizeIndex !== -1 && selectableVariants.length" [ngClass]="{'disabled-color': selectedSizeIndex === -1}">
        <customizer-component-header [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.GEMSTONE.COLOR' | translate"
            [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
            <customizer-colors-auto-update [selected]="getSelectedVariant.VariantColor"
                [showNoneChoiceOption]="showNoneChoiceOption" [selectedColorGroup]="currentColorGroup"
                [variants]="selectableVariants" [editMode]="state === customizerStateEnum.EDITORDER"
                [matchableColors]="matchableColors" [useColorGroup]="menu.contentGrouping"
                (colorMatchChange)="matchColors($event)" (selectedColorGroupChange)="onSelectGroup($event)"
                (selectedColorChange)="selectedSizeIndex > -1 ? onSelect(selectedSizeIndex, $event) : null"
                [variantOrModelIsNew]="variantOrModelIsNew" *ngIf="selectedSizeIndex > -1"
                [matchColor]="menu.matchColor">
            </customizer-colors-auto-update>
        </customizer-component-header>
    </div>
</div>