import { BrandEnum } from '@infrastructure/brand.enum';
import { AllAssortmentsModel } from '@models/all-assortments-model.interface';
import { AssortmentLineModel } from '@models/assortment-line-model';
import { AssortmentModel } from '@models/assortment-model';
import { OrderItemModel } from '@models/order-item';
import { Type } from "class-transformer";
import { AvailabilityModel } from "../availability-model";
import { ComponentVariantModel } from '../component-variant';
import { UserDataModel } from '../user-data-model';
import { ModelOrderApimodel } from './model-order-apimodel';
import { ModelSizeCodes } from './model-size-codes';

export class ModelModel extends AvailabilityModel implements AllAssortmentsModel {
    Id: number;
    Uuid: string;
    Code: string;
    Order: string;
    Status: boolean;
    ProductLineId: number;
    ProductLineUuid: string;
    ProductLineCode: string;
    ModelGroupId: number;
    ModelGroupUuid: string;
    ModelGroupDescription: string;
    ModelGroupDescriptionPrecious: string;
    ModelGroupCode: string;
    private ModelGroupShortDescription: string;
    IsKid: boolean;
    DefaultModelSizeId: number;
    DefaultConfigurationCode: string;
    DefaultModelConfigurationCode: string;
    ModelSizeCodeModels: Array<ModelSizeCodes>;
    IsRimless: boolean;
    LowTempleInclinationPossible: number;
    Brand: BrandEnum;

    @Type(() => AssortmentLineModel)
    AssortmentLines: Array<AssortmentLineModel>;

    // define if new choices exist on model
    public NewSizeComponentCodes: Array<string>;
    public NewSizeComponentCodesPrecious: Array<string>;
    public NewColorComponentCodes: Array<string>;
    public NewComponentCodes: Array<string>;
    public NewComponentCodesPrecious: Array<string>;

    // Note: There is no NewColorComponentCodesPrecious because there are no real colors right now for precious frames

    @Type(() => ComponentVariantModel)
    DefaultVariantModels: Array<ComponentVariantModel>;

    @Type(() => AssortmentLineModel)
    DefaultConfigurationAssortmentLines: Array<AssortmentLineModel>;

    @Type(() => AssortmentLineModel)
    DefaultModelSizeAssortmentLines: Array<AssortmentLineModel>;
    PresentAsPrecious: boolean = false;

    get ModelSizeCodes(): Array<string> {
        return this.ModelSizeCodeModels.map(x => x.ModelsizeCode).sort();
    }

    get getModelGroupShortDescription(): string {
        return this.ModelGroupShortDescription ? this.ModelGroupShortDescription : this.ModelGroupDescription;
    }

    HasStatus(userPermission: UserDataModel): boolean {
        return userPermission.ProductLinesPermissions.some(x => x == this.ProductLineCode);
    }

    public toString = () => `${this.Code} (uuid: ${this.Uuid})`;

    get AllAssortments(): Array<AssortmentModel> {
        const result = new Array<AssortmentModel>();
        this.AssortmentLines.map(x => x.Assortment).distinct((x,y) => x.Code === y.Code).forEach(x => result.push(x));
        this.DefaultVariantModels?.map(x => x.AllAssortments).forEach(x => x.forEach(y => result.push(y)));

        this.DefaultModelSizeAssortmentLines?.map(x => x.Assortment).distinct((x,y) => x.Code === y.Code).forEach(x => result.push(x));
        this.DefaultConfigurationAssortmentLines?.map(x => x.Assortment).distinct((x,y) => x.Code === y.Code).forEach(x => result.push(x));

        return result.distinct((x,y) => x.Code === y.Code);
    }

    public static createCopy(fromModel: ModelModel): ModelModel {
        if(fromModel) {
            const model = new ModelModel(fromModel.Discontinued, fromModel.New);
            model.Id = fromModel.Id;
            model.Uuid = fromModel.Uuid;
            model.Code = fromModel.Code;
            model.Order = fromModel.Order;
            model.Status = fromModel.Status;
            model.ProductLineUuid = fromModel.ProductLineUuid;
            model.ProductLineId = fromModel.ProductLineId;
            model.ProductLineCode = fromModel.ProductLineCode;
            model.ModelGroupUuid = fromModel.ModelGroupUuid;
            model.ModelGroupId = fromModel.ModelGroupId;
            model.ModelGroupDescription = fromModel.ModelGroupDescription;
            model.ModelGroupDescriptionPrecious = fromModel.ModelGroupDescriptionPrecious;
            model.ModelGroupCode = fromModel.ModelGroupCode;
            model.ModelGroupShortDescription = fromModel.ModelGroupShortDescription;
            model.IsKid = fromModel.IsKid;
            model.DefaultModelSizeId = fromModel.DefaultModelSizeId;
            model.DefaultConfigurationCode = fromModel.DefaultConfigurationCode;
            model.DefaultModelConfigurationCode = fromModel.DefaultModelConfigurationCode;
            model.ModelSizeCodeModels = fromModel.ModelSizeCodeModels;
            model.IsRimless = fromModel.IsRimless;
            model.AssortmentLines = fromModel.AssortmentLines?.map(x => AssortmentLineModel.createCopy(x));
            model.NewSizeComponentCodes = fromModel.NewSizeComponentCodes;
            model.NewColorComponentCodes = fromModel.NewColorComponentCodes;
            model.NewComponentCodes = fromModel.NewComponentCodes;
            model.NewComponentCodesPrecious = fromModel.NewComponentCodesPrecious;
            model.NewSizeComponentCodesPrecious = fromModel.NewSizeComponentCodesPrecious;
            model.LowTempleInclinationPossible = fromModel.LowTempleInclinationPossible;
            model.DefaultVariantModels = fromModel.DefaultVariantModels?.map(x => ComponentVariantModel.createCopyFromPlain(x));
            model.DefaultConfigurationAssortmentLines = fromModel.DefaultConfigurationAssortmentLines?.map(x => AssortmentLineModel.createCopy(x));
            model.DefaultModelSizeAssortmentLines = fromModel.DefaultModelSizeAssortmentLines?.map(x => AssortmentLineModel.createCopy(x));
            model.Brand = fromModel.Brand;
            return model;
        }
        else {
            return null;
        }

    }

    public static mapFromApiModel(fromModel: ModelOrderApimodel, isRimless: boolean): ModelModel{
        if(fromModel) {
            const model = new ModelModel(fromModel.Discontinued, fromModel.New);
            model.Id = fromModel.Id;
            model.Uuid = fromModel.Uuid;
            model.Code = fromModel.Code;
            model.Order = fromModel.Order;
            model.IsRimless = isRimless;
            model.ProductLineCode = fromModel.ProductLineCode;
            model.LowTempleInclinationPossible = fromModel.LowTempleInclinationPossible;
            model.AssortmentLines = fromModel.AssortmentLines?.map(x => AssortmentLineModel.createCopy(x));

            return model;
        }
        else {
            return null;
        }
    }

    public static mapFromStoreModel(fromModel: OrderItemModel): ModelModel{
        if(fromModel) {
            const model = new ModelModel(null, fromModel.New);
            model.Id = fromModel.Id;
            model.Uuid = fromModel.Uuid;
            model.Code = fromModel.ModelCode;
            model.IsRimless = fromModel.IsRimless;
            model.ProductLineCode = fromModel.ProductLineCode;

            return model;
        }
        else {
            return null;
        }
    }
}
