import { AssortmentLineModel } from '@models/assortment-line-model';
import { AssortmentCalculator } from './assortment-calculator';


export class FirstTimeDeliveryCalculator {
    public static IsFirstTimeDelivey(assortmentLines: Array<AssortmentLineModel>, assortments: Array<string>, isRimless: boolean, isClient: boolean): boolean {

        const missingAssortments = AssortmentCalculator.FindMissingAssortments(assortmentLines.map(t => t.Assortment).distinct((x, y) => x.Code === y.Code), assortments);

        let isFirstTimeDelivery = missingAssortments.length > 0;

        if (isRimless && isClient) {
            const missingAssortmentLines = assortmentLines.filter(t => missingAssortments.some(k => k.Code === t.Assortment.Code));

            if (missingAssortmentLines.filter(t => !t.IsOnlyRelatedToModel()).length === 0) {
                isFirstTimeDelivery = false;
            }
        };

        return isFirstTimeDelivery;
    }
}
