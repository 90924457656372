<div class="modal-body" *ngIf="isReady">
	<button class="close" (click)="closeAndDestroy()">
		<i class="fa-light fa-xmark"></i>
	</button>

	<div *ngIf="locationError === false" class="col-xs-10 customer-location-checkin-body cy-location-prompt">
		<h2 [innerText]="'MODAL.CHECKIN.HEADER'  | translate: {'customerName': customer.Name}"></h2>
		<div [innerHTML]="'MODAL.CHECKIN.SUBTITLE' | translate"></div>
		<div class="action-panel">
			<button (click)="locationCheckIn(true)" class="btn btn-secondary" type="button" [innerText]="'COMMON.YES' | translate"></button>
			<button (click)="locationCheckIn(false)" class="btn btn-secondary" type="button" [innerText]="'COMMON.NO' | translate"></button>
		</div>
	</div>

	<div *ngIf="locationError" class="col-xs-10 customer-location-checkin-body cy-location-disabled-prompt">
		<h2 [innerText]="'MODAL.CHECKIN.LOCATIONDISABLED.HEADER' | translate"></h2>
		<div [innerHTML]="'MODAL.CHECKIN.LOCATIONDISABLED.SUBTITLE' | translate"></div>
		<div class="action-panel">
			<button (click)="locationCheckIn(isVisitingCustomer)" class="btn btn-secondary" type="button" [innerText]="'MODAL.CHECKIN.RETRY' | translate"></button>
			<button (click)="continueWithoutLocation()" class="btn btn-secondary" type="button" [innerText]="'MODAL.CHECKIN.CONTINUE' | translate"></button>
		</div>
	</div>

</div>