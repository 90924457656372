import { Pipe, PipeTransform } from '@angular/core';
import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';
import { ModelModel } from '@models/api-models/model';
import { ImageService } from '@services/image.service';

@Pipe({
	name: 'modelToImageUrl'
})
export class ModelToImageUrlPipe implements PipeTransform {


	constructor(
		private imageService: ImageService
	) {
	}

	transform(model: ModelModel | ComponentVariantFromApiModel) {

		let imgUrl: string;


		if ('Bypass' in model) {

			imgUrl = this.imageService.GetVariantImage(model, 'medium')
		}
		else {

			imgUrl = this.imageService.GetFrontImage(model, model.ModelGroupCode, model.DefaultModelConfigurationCode, model.DefaultVariantModels, 'medium')
		}

		return imgUrl

	}

}
