import { Injectable } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { CaseAlternativeGroupLineModel } from '@models/api-models/case-alternative-groupline';
import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';
import { ModelModel } from '@models/api-models/model';
import { ComponentVariantModel } from "@models/component-variant";
import { ConfigurationModel, ConfigurationModelall } from '@models/configuration';
import { ConfigurationPlusModel } from '@models/configuration-plus-model';
import { MiscModelViewmodel } from '@models/misc-model-viewmodel';
import { ModelGroupModel } from '@models/model-group';
import { ModelSizeModel } from '@models/model-size';
import { VariantColorFromApiModel } from '@models/variant-color-from-api';
import { VariantSizeFromApiModel } from '@models/variant-size-from-api';
import { plainToClass } from 'class-transformer';
import { ComponentVariantFromApiFactory } from 'src/_factories/ComponentVariantFromApiFactory';
import { BrandModeService } from './brandmode.service';
import { EnduserDisplayService } from './enduserdisplay.service';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { OfflineService } from './offline.service';
import { StoreService } from './store.service';
import { ModelsService } from './v2/models.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private variantDataUrl: string = `/componentvariant?`;
    private configurationsByModelSizeKey: string = `modelsize.configuration`;
    private configurationDataUrl: string = `/configuration/`;

    constructor(
        private modelsService: ModelsService,
        private enduserDisplayService: EnduserDisplayService,
        private env: EnvService, private storeService: StoreService,
        private brandModeService: BrandModeService,
        private indexedDbService: IndexDbService,
        private offlineService: OfflineService
    ) { }
    public async getVariants(modelconfigUuid: string, modelgroup: ModelGroupModel, componentUuid?: string, componentVariantUuid?: string, componentSizeUuid?: string): Promise<Array<ComponentVariantFromApiModel>> {
        return this.getVariantsForModel(modelconfigUuid, modelgroup, componentUuid, componentVariantUuid, componentSizeUuid, this.enduserDisplayService.IsDisplay());
    }
    public async getVariantFromFixedConfiguration(fixedConfigUuid: string, baseConfigUuid: string, modelgroup: ModelGroupModel, chosenVariants: Array<ComponentVariantModel>, useExistingColor: boolean = false): Promise<Array<ComponentVariantFromApiModel>> {
        const variantsFromFixedConfig = await this.getVariants(fixedConfigUuid, modelgroup);
        const variantsFromBaseConfig = await this.getVariants(baseConfigUuid, modelgroup);

        const variants = new Array<ComponentVariantFromApiModel>();

        if (!modelgroup.IsConfigurationBased && !modelgroup.IsBothConfigAndVariantBased) {
            variantsFromFixedConfig.forEach(fixedVariant => {
                const currentChosenVariant = chosenVariants.find(x => x.Component.Code == fixedVariant.Component.Code);

                const fixedSize = fixedVariant.VariantSizes.find(vs => vs.Default) ?? fixedVariant.VariantSizes[0];
                const fixedColor = fixedSize?.VariantColors.find(vc => vc.Default) ?? fixedSize.VariantColors[0];

                const correspondingVariant = variantsFromBaseConfig.find(vfb => vfb.Uuid == currentChosenVariant?.Uuid);

                if (correspondingVariant) {
                    const correspondingVariantSize = currentChosenVariant ? correspondingVariant.VariantSizes.find(vs => vs.Size == currentChosenVariant.VariantSize.Size)
                        : correspondingVariant.VariantSizes.find(vs => vs.Size == fixedSize.Size);
                    const correspondingVariantColor = correspondingVariantSize.VariantColors.find(vc => vc.ColorUuid == fixedColor.ColorUuid);
                    correspondingVariantSize.VariantColors = correspondingVariantColor ? [correspondingVariantColor] : [];
                    correspondingVariant.VariantSizes = correspondingVariantSize ? [correspondingVariantSize] : [];
                    variants.push(correspondingVariant);
                }
            });
        }
        else {
            variantsFromFixedConfig.filter(vfb => vfb.Default).forEach(variant => {
                const currentChosenVariant = chosenVariants.find(x => x.Component.Code == variant.Component.Code);

                let size: VariantSizeFromApiModel;
                let color: VariantColorFromApiModel;
                if (currentChosenVariant) {
                    size = variant.VariantSizes.find(vs => vs.Size == currentChosenVariant.VariantSize.Size);

                    if (size && (modelgroup.IsConfigurationBased || useExistingColor)) {
                        color = size.VariantColors.find(vc => vc.Color.Code == currentChosenVariant.VariantColor?.Color.Code);
                    }
                }

                if (!size) {
                    size = variant.VariantSizes.find(vs => vs.Default) || variant.VariantSizes[0];
                }

                if (!color && !variant.Optional) {
                    color = size.VariantColors.find(vc => vc.Default);
                }

                size.VariantColors = color ? [color] : [];
                variant.VariantSizes = size ? [size] : [];
                variants.push(variant);
            });
        }

        const result = variants.map((x) => ComponentVariantFromApiFactory.createCopy(x));
        return result;
    }
    public async getConfigurationsWithVariants(configs: Array<ConfigurationModel>,
        baseConfig: ConfigurationModel,
        model: ModelModel,
        modelGroup: ModelGroupModel,
        chosenVariants: Array<ComponentVariantModel>): Promise<Array<ConfigurationPlusModel>> {

        let configPlus = new Array<ConfigurationPlusModel>();
        for (const config of configs) {
            const configPlusModel = new ConfigurationPlusModel(config);

            const completeVariants = await this.getVariantFromFixedConfiguration(config.ModelConfigurationUuid, baseConfig.ModelConfigurationUuid, modelGroup, chosenVariants);
            const variants = new Array<ComponentVariantModel>();
            completeVariants.forEach(completeVariant => {
                const selectedVariant = model.DefaultVariantModels.find(x => x.Component.Code == completeVariant.Component.Code);
                const componentToCopy = completeVariant.ToComponentVariantModel(
                    selectedVariant ? selectedVariant.Engraving : "", selectedVariant ? selectedVariant.OrderLineUuid : "");
                const newSelectedVariant = Object.assign(new ComponentVariantModel(componentToCopy.Discontinued, componentToCopy.New), componentToCopy);

                newSelectedVariant.VariantSize = completeVariant.VariantSizes[0];
                newSelectedVariant.VariantColor = completeVariant.VariantSizes[0].VariantColors[0];

                if (newSelectedVariant.VariantColor) {
                    variants.push(newSelectedVariant);
                }
            });

            configPlusModel.variants = variants;
            configPlusModel.Discontinued = configPlusModel.configurationModel.Discontinued;
            configPlusModel.variants.forEach(variant => {
                if (variant.Discontinued < configPlusModel.Discontinued) {
                    configPlusModel.Discontinued = variant.Discontinued;
                }
            });

            configPlus.push(configPlusModel);
        }

        return configPlus;
    }


    public async getVariantsWithDisplayFilter(modelconfigUuid: string, modelgroup: ModelGroupModel, componentUuid: string, componentVariantUuid: string, componentSizeUuid: string, display: boolean): Promise<Array<ComponentVariantFromApiModel>> {
        return this.getVariantsForModel(modelconfigUuid, modelgroup, componentUuid, componentVariantUuid, componentSizeUuid, display);
    }



    private async getVariantsForModel(modelconfigUuid: string, modelgroup: ModelGroupModel, componentUuid: string, componentVariantUuid: string, componentSizeUuid: string, isDisplay: boolean): Promise<Array<ComponentVariantFromApiModel>> {
        const url = (await this.env.baseUrl()) + this.variantDataUrl + `requestModel.configurationUuid=${modelconfigUuid}&requestModel.ModelgroupUuid=${modelgroup.modelGroupDataUuid}`;
        let variants = await this.storeService.getOrSetFromStore<Array<ComponentVariantFromApiModel>>(url);

        if (componentUuid) {
            variants = variants.filter(x => x.Component.Uuid == componentUuid);
        }

        if (componentVariantUuid) {
            variants = variants.filter(x => x.Uuid == componentVariantUuid);
        }

        if (componentSizeUuid) {
            variants.forEach(x => x.VariantSizes = x.VariantSizes.filter(size => size.Uuid == componentSizeUuid));
        }

        if (this.brandModeService.isPrecious) {
            variants = variants.filter(model => model.Brand == BrandEnum.Precious || model.Brand == BrandEnum.TitaniumAndPrecious);
        }
        else {
            variants = variants.filter(model => model.Brand == BrandEnum.Titanium || model.Brand == BrandEnum.TitaniumAndPrecious);
        }

        if (isDisplay) {
            variants = variants.filter(x => x.AvailableForDisplay == isDisplay);

            variants.forEach(variant => {
                variant.VariantSizes = variant.VariantSizes.filter(x => x.AvailableForDisplay == isDisplay);

                variant.VariantSizes.forEach(size => {
                    size.VariantColors = size.VariantColors.filter(x => x.AvailableForDisplay == isDisplay);
                });
            });
        }

        const result = variants.map((x) => ComponentVariantFromApiFactory.createCopy(x));

        return result;
    }

    public async getCases(modelid: number, modelsize: ModelSizeModel, modelconfigUuid: string, modelgroup: ModelGroupModel, componentUuid: string, componentVariantUuid: string, componentSizeUuid: string): Promise<Array<MiscModelViewmodel>> {

        let variants = await this.getVariants(modelconfigUuid, modelgroup, componentUuid, componentVariantUuid, componentSizeUuid);

        const variantCases = variants.selectMany(t => t.Cases);
        const componentSizeCases = variants.selectMany(x => x.VariantSizes.selectMany(x => x.Cases));

        let finalCases: Array<CaseAlternativeGroupLineModel>;

        finalCases = componentSizeCases.filter(x => x.ModelId == modelid && x.ModelSizeId == modelsize.Id);

        if (finalCases.length === 0) {
            finalCases = componentSizeCases.filter(x => x.ModelId == modelid && !x.ModelSizeId);
        }

        if (finalCases.length === 0) {
            finalCases = variantCases.filter(x => x.ModelSizeId == modelsize.Id);
        }

        if (finalCases.length === 0) {
            finalCases = variantCases.filter(x => x.ModelId == modelid);
        }

        if (finalCases.length === 0) {
            finalCases = variantCases;
        }

        const distinctCases = finalCases.distinct((x, y) => x.Id === y.Id);

        const miscModels = await this.modelsService.getMiscModels(true);

        const miscViewModels: MiscModelViewmodel[] = [];

        distinctCases.forEach(c => {
            const miscCase = miscModels.find(t => t.EcommerceNo == c.ItemNo && t.EcommerceVariantCode == c.ItemVariantCode);

            if (miscCase) {
                miscViewModels.push(new MiscModelViewmodel(miscCase, c.Default));
            }
        })

        return miscViewModels;
    }

    public async setAllConfigurations() {
        const allConfigs = await this.storeService.getOrSetFromStore<Array<ConfigurationModel>>(`${await this.env.baseUrl()}/allConfigurations`);

        const groupedConfigs = allConfigs.reduce((r, a) => {
            r[a.ModelSizeUuid] = r[a.ModelSizeUuid] || [];
            r[a.ModelSizeUuid].push(a);
            return r;
        }, Object.create(null));

        const Allconfigs = new Array<ConfigurationModelall>();

        // eslint-disable-next-line guard-for-in
        for (const modelsizeUuid in groupedConfigs) {
            const configs = groupedConfigs[modelsizeUuid] as Array<ConfigurationModel>;

            Allconfigs.push({ configsOnModelsize: configs, modelSizeUuid: modelsizeUuid });
        }

        await this.indexedDbService.replaceAndSave<Array<ConfigurationModelall>>(StoreService.storeName, Allconfigs, this.configurationsByModelSizeKey);

        return Allconfigs;
    }

    public async getConfigurations(modelSizeUuid: string, fixed: boolean = null): Promise<Array<ConfigurationModel>> {

        let modelsizeConfigurationGroups: Array<ConfigurationModelall> = null;

        modelsizeConfigurationGroups = await this.indexedDbService.get<any>(StoreService.storeName, this.configurationsByModelSizeKey);

        if (!modelsizeConfigurationGroups) {
            modelsizeConfigurationGroups = [];
        }

        let result = modelsizeConfigurationGroups.find(t => t.modelSizeUuid == modelSizeUuid)?.configsOnModelsize;
        result = result?.filter(x => x.IsPrecious === this.brandModeService.isPrecious);

        if (!result || result.length === 0) {
            result = await this.getConfigurationForModelSizeUuid(modelSizeUuid);
            modelsizeConfigurationGroups.push({ configsOnModelsize: result, modelSizeUuid });

            await this.indexedDbService.replaceAndSave<Array<ConfigurationModelall>>(StoreService.storeName, modelsizeConfigurationGroups, this.configurationsByModelSizeKey);
        }

        return result.map((x) => plainToClass(ConfigurationModel, x));
    }

    private async getConfigurationForModelSizeUuid(modelSizeUuid: string, fixed: boolean = null): Promise<Array<ConfigurationModel>> {
        let result: Array<ConfigurationModel> = null;
        result = await this.offlineService.getDataWithOfflineCheck<Array<ConfigurationModel>>((await this.env.baseUrl()) + this.configurationDataUrl + modelSizeUuid);

        result = result.filter(x => x.ModelSizeUuid === modelSizeUuid);
        result = result.filter(x => x.IsPrecious === this.brandModeService.isPrecious);

        if (fixed) {
            result = result.filter(re => re.Fixed == fixed);
        }

        return result;
    }

}
