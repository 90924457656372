<div class="container">
	<div class="content">
		<h5>service settings</h5>
		<div class="service">
			<div class="text">
				<div class="service-name">
					order synchronizer service
				</div>
				<div class="description">
					this service synchronizes orders between the Customiser and AX via FileMaker where it is given its official web order id
					<br><br>
					<b>warning!</b> please do not change this setting unless you are absolutely sure you know what you are doing!
				</div>
			</div>
			<div class="vr"></div>
			<div class="slider">
				<mat-slide-toggle #serviceEnabledToggle [checked]="isOrderSynchronizerServiceEnabled" (change)="openDialog($event)">{{isOrderSynchronizerServiceEnabled ? 'enabled' : 'disabled'}}</mat-slide-toggle>
			</div>
		</div>
	</div>
</div>