import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SubscribingComponent implements OnDestroy {
    public subscriptions = new Array<Subscription>();

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            if(!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }
}
