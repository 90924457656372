import { Injectable } from '@angular/core';
import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';

@Injectable({
	providedIn: 'root'
})
export class PreciousFinishService {

	constructor() { }

	public removeDuplicatesInVariantsList(data: ComponentVariantFromApiModel[]) {
		console.debug('data', data);

		// take all that have ;
		const allThatHaveSemi = [...new Set(data.filter(item => item.Code.includes(';')).map(item => item))];
		console.debug('allThatHaveSemi', allThatHaveSemi);

		// ... and the rest
		const allThatDontHaveSemi = [...new Set(data.filter(x => !x.Code.includes(';')).map((item,) => item))];

		// remove duplets (same before ;) = distinct precious
		const distinctList = [... new Set(allThatHaveSemi.map(item => {
			item.Code = item.Code.substring(0, item.Code.lastIndexOf(';'));
			return item;
		}))];
		console.debug('distinctList', distinctList);

		// find original
		const netListFromData = [...new Set(distinctList.map(item => data.find(d => d.Code.startsWith(item.Code))))];
		console.debug('netListFromData', netListFromData);

		// create list from distinct list joined with rest
		let finalList = [...netListFromData, ...allThatDontHaveSemi];
		console.debug(finalList)

		return finalList;
	}

}
