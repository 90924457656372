import { PriceSettingEnum } from '@infrastructure/price-setting.enum';

export class UserPatchModel {

	Uuid: string;
	RecordId: string;
	PriceSettingDisplay: PriceSettingEnum;

	constructor(recordId: string, uuid: string) {
		this.RecordId = recordId;
		this.Uuid = uuid;
	}
}
