import { ElementRef } from '@angular/core';

export class Paging {
  public size: number;
  public page: number;
  public totalPages: number;
  public totalCount = 0;
  public maxPageCount: number;
  public target: ElementRef;
  public pageArray: number[] = [];

  constructor(pageSize: number = 12, currentPage: number = 1) {
    this.size = pageSize;
    this.page = currentPage;
    this.totalPages = this.getMaxPageCount();
    this.pageArray = [];
  }

  public getMaxPageCount() {
    if (this.showPaging() === false || this.size === 0) {
      return 0;
    }
    const maxPages = Math.ceil(this.totalCount / this.size);
    return maxPages;
  }
  public showPaging(): boolean {
    return this.totalCount > this.size && this.size > 0;
  }
  public update(totalCount: number): void {
    this.pageArray = [];

    this.totalCount = totalCount;
    this.totalPages = this.getMaxPageCount();

    let startPage = 1;
    if (this.page > startPage + 2) {
      startPage = this.page - 2;
    }
    let endPage = startPage + this.totalPages;
    if (endPage > this.totalPages) {
      endPage = this.totalPages;
    }
    if (endPage - startPage > 5) {
      endPage = startPage + 5;
    }
    for (let index = startPage; index <= endPage; index++) {
      this.pageArray.push(index);
    }
  }
}