import { HttpEventType } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LogLevelEnum } from "@infrastructure/logLevel.enum";
import { VersionType } from "@infrastructure/versionType.enum";
import { ModelReponseWithVariantsModelFromApi } from "@models/api-models/ModelReponseWithVariantsModelFromApi";
import { ConfigurationModelall } from "@models/configuration";
import { MiscModel } from "@models/misc-model";
import { ModelGroupModel } from "@models/model-group";
import { ConfigurationService } from "@services/configuration.service";
import { ComponentCodeConstants } from "@shared/component-constants";
import { HttpOfflineError } from "@shared/http-offline-error";
import { ProductlineConstants } from "@shared/productline-constants";
import { BehaviorSubject, Observable } from "rxjs";
import { AppConstants } from "src/app.constants";
import { IndexeddbError } from './../shared/indexeddb-error';
import { DataApiService } from "./data-api.service";
import { Background, ImageService, ImageSize } from "./image.service";
import { IndexDbService } from "./index-db.service";
import { LanguageService } from "./language.service";
import { LocalstorageService } from "./localstorage.service";
import { LoggerService } from "./logger.service";
import { ManualConfigService } from "./manual-config.service";
import { OrderSyncService } from "./ordersync.service";
import { ServerInfoService } from './server-info.service';
import { ModelsService } from './v2/models.service';

@Injectable({
	providedIn: 'root'
})
export class PreloadService {

	public preloadDate$: Observable<string>;
	private loading = new BehaviorSubject(false);
	public loading$ = this.loading.asObservable();

	private indexedDbError = new BehaviorSubject(false);
	public indexedDbError$ = this.indexedDbError.asObservable();

	private loadingText = new BehaviorSubject<string>("");
	public loadingText$ = this.loadingText.asObservable();

	private wakeLock = null;

	private modelgroups: Array<ModelGroupModel> = [];
	private configurations: Array<ConfigurationModelall> = [];
	private images: Set<string> = new Set<string>();

	private readonly preloadDateKey: string = 'PreloadDate';

	constructor(
		private dataApi: DataApiService,
		private indexDbService: IndexDbService,
		private storage: LocalstorageService,
		private imageService: ImageService,
		private languageService: LanguageService,
		private orderSyncSerice: OrderSyncService,
		private manualConfigService: ManualConfigService,
		private loggerService: LoggerService,
		private serverInfoService: ServerInfoService,
		private modelsService: ModelsService,
		private configurationService: ConfigurationService
	) {
		this.preloadDate$ = storage.onChange(this.preloadDateKey);
	}

	async loadData() {
		try {
			let logEvent = {
				message: `Download offline content initiated`,
				logLevel: LogLevelEnum.Information,
				eventId: ``
			}

			this.loggerService.sendClientLog(logEvent);

			console.debug("Checking for wakeLock availability");

			if ('wakeLock' in navigator) {
				console.debug("WakeLock enabled");
				this.wakeLock = await (navigator as any).wakeLock.request('screen');
			}

			this.loading.next(true);
			this.loadingText.next("initial data");

			await this.loadColors();
			await this.loadConfigurations();
			await this.manualConfigService.reloadManualConfigsToCache();

			this.loadingText.next("loading customers and bag data");
			await this.orderSyncSerice.LoadOrdersToCache();

			this.loadingText.next("accessories - requesting data");
			await this.getMiscModels();

			this.loadingText.next("languages - requesting data");
			await this.loadlanguages();

			this.loadingText.next("models - requesting data");
			await this.loadModelGroups();
		}
		catch (error) {
			if (this.wakeLock) {
				await this.releaseWakeLock();
			}

			if (error instanceof IndexeddbError) {
				this.indexedDbError.next(true);
			}
			else if (error.message == "Failed to fetch") {
				throw new HttpOfflineError();
			}
			else {
				throw error;
			}

			this.loading.next(false);
		}
	}

	private async getMiscModels() {
		const models = await this.modelsService.getMiscModels(true);
		const categories = new Array<{ name: string, groups: Array<string> }>();

		for (const model of models) {
			const imageCount = await this.serverInfoService.getMiscImageCount(model.EcommerceNoUnique);

			if (!categories.some(x => x.name === model.ItemCategoryDescription)) {
				categories.push({ name: model.ItemCategoryDescription, groups: new Array() });
			}

			const category = categories.find(x => x.name === model.ItemCategoryDescription);

			if (!category.groups.some(x => x === model.ItemGroupDescription)) {
				category.groups.push(model.ItemGroupDescription);
			}

			this.imageService.GetMiscImageModels(model.EcommerceNoUnique, imageCount).selectMany(x => x).forEach(x => {
				this.images.add(x.Src);
			});
		}

		for (const category of categories) {
			if (category.name && category.name.length > 0) {
				this.images.add(this.imageService.GetMiscCategoryImage(category.name.toLowerCase()));

				for (const group of category.groups) {
					if (group && group.length > 0) {
						this.images.add(this.imageService.GetMiscGroupImage(group, category.name.toLowerCase()));
					}
				}
			}
		}

		const allCases = await this.dataApi.getAllCases();
		const miscModelCases: MiscModel[] = [];

		allCases.forEach(c => {
			const miscCase = models.find(t => t.EcommerceNo == c.ItemNo && t.EcommerceVariantCode == c.ItemVariantCode);

			if (miscCase) {
				miscModelCases.push(miscCase);
			}
		})

		for (const miscCase of miscModelCases) {
			const caseImg = this.imageService.GetMiscImage(miscCase.EcommerceNoUnique, 1, "Large");
			this.images.add(caseImg);
		}
	}

	private async loadlanguages() {
		const languages = this.languageService.getLanguages();

		for (const lang of languages) {
			await this.dataApi.getDataTranslations(lang);
		}
	}

	private async loadModelGroups() {
		this.modelgroups = await this.modelsService.loadAllModelgroups();

		for (const mg of this.modelgroups) {
			for (const innerMg of mg.ModelGroupChildren) {
				this.modelgroups.push(innerMg);
			}
		}

		await this.loadModelgroupDataRecursive(this.modelgroups[0]);
	}

	private async loadConfigurations() {
		this.configurations = await this.configurationService.setAllConfigurations();
	}

	private async loadColors() {
		const colors = await this.dataApi.SearchColors("");
		const colorImages = colors.map(color => this.imageService.GetColorImages(color.Color.ColorLine.Code, color.Color.Code));
		colorImages.forEach(img => this.images.add(img));
	}

	private async loadModelgroupDataRecursive(modelgroup: ModelGroupModel) {
		this.loadingText.next(this.languageService.instantTranslateData(modelgroup.Description) + " - " + "requesting data");
		const modeldataRequest = await this.dataApi.loadDataRequests(modelgroup);

		await this.loadModelGroupImage(modelgroup);

		if (modeldataRequest) {
			modeldataRequest.subscribe(async event => {
				if (event.type === HttpEventType.DownloadProgress) {
					this.loadingText.next(this.languageService.instantTranslateData(modelgroup.Description) + " - " + "downloading data");
				}
				else if (event.type == HttpEventType.Response) {
					await this.modelsService.setModelsWithAllData(modelgroup, event.body);
					await this.loadModelImages(modelgroup, event.body);
					await this.loadModelGroupDataRecursiveInternal(modelgroup);
				}
			});
		}
		else {
			await this.loadModelGroupDataRecursiveInternal(modelgroup);
		}
	}

	private async loadModelGroupImage(modelgroup: ModelGroupModel) {
		await this.getImage(this.imageService.GetProductlineImages(modelgroup.Code));
	}

	private async loadModelImages(modelgroup: ModelGroupModel, models: ModelReponseWithVariantsModelFromApi) {
		for (const model of models.ModelReponseModels) {
			const variants = model.DefaultVariantModels;

			// images for both slider and thumbnails for slider
			this.imageService.GetSliderImages(modelgroup, model, modelgroup.Code, model.DefaultModelConfigurationCode, variants).map(x => x.url).forEach(x => {
				this.images.add(x);
			});

			// models menu for rimless
			if (modelgroup.IsRimless) {
				this.images.add(this.imageService.GetFrontImage(model, modelgroup.Code, model.DefaultModelConfigurationCode, variants, ImageSize.Medium, Background.None));
			}

			// clip-on image
			if (variants.some(v => v.Component.Code === ComponentCodeConstants.Clipon)) {
				this.images.add(this.imageService.GetFrontImage(model, modelgroup.Code, model.DefaultModelConfigurationCode, variants.filter(v => v.Component.Code === ComponentCodeConstants.Clipon), ImageSize.Low, Background.None));
			}

			// models page
			this.images.add(this.imageService.GetFrontImage(model, modelgroup.Code, model.DefaultModelConfigurationCode, variants, ImageSize.Medium));

			// fetches too many (would like to filter on menu data, but we can't because menu won't show if it's they are working on variant or size or color)
			for (const variant of models.ComponentVariantModels) {
				if (!variant.Bypass) {
					this.images.add(this.imageService.GetVariantImage(variant));
				}
			}

			const modelsizes = await this.dataApi.getSizes(modelgroup.ProductLineUuid, modelgroup.Uuid, model.Uuid);

			const configurations = this.configurations.selectMany(x => x.configsOnModelsize).filter(x => modelsizes.some(t => t.Uuid === x.ModelSizeUuid));

			for (var i = 0; configurations.length > i; i++) {
				const config = configurations[i];

				if (variants.some(v => v.Component.Code === ComponentCodeConstants.Innerrim)) {
					this.images.add(this.imageService.GetDetailImage(model, modelgroup.Code, model.DefaultModelConfigurationCode, [], ImageSize.Low));
				}

				// todo: Find another way to do this
				if (config.Fixed || modelgroup.ProductLineCode === ProductlineConstants.MOF) {
					this.images.add(this.imageService.GetConfigurationDetailImage(model, model.ModelGroupCode, config.ModelConfigurationCode, [], ImageSize.Low));

					if (config.Fixed && (modelgroup.IsConfigurationBased || modelgroup.IsBothConfigAndVariantBased)) {

						const configurationWithVariants = await this.configurationService.getConfigurationsWithVariants([config], config, model, modelgroup, model.DefaultVariantModels)

						const fixedConfImage = this.imageService.GetDetailImage(model, modelgroup.Code, config.ModelConfigurationCode, configurationWithVariants[0].variants, ImageSize.Low);

						this.images.add(fixedConfImage);
					}
					else {
						const fixedConfImage = this.imageService.GetDetailImage(model, modelgroup.Code, config.ModelConfigurationCode, model.DefaultVariantModels, ImageSize.Low);
						this.images.add(fixedConfImage);
					}
				}
			}
		}
	}

	private getVersion() {
		const currentVersion = this.indexDbService.getLocalVersion(VersionType.Frames);
		return ((currentVersion ? currentVersion.Id : null) || AppConstants.version);
	}

	private async getImage(url: string) {
		return await fetch(url + (url.indexOf("?") != -1 ? "&" : "?") + "v=" + this.getVersion());
	}

	private async loadModelGroupDataRecursiveInternal(modelgroup: ModelGroupModel) {
		this.modelgroups = this.modelgroups.filter(x => x.Uuid != modelgroup.Uuid);

		if (this.modelgroups.length > 0) {
			await this.loadModelgroupDataRecursive(this.modelgroups[0]);
		}
		else {
			this.loadingText.next("downloading images");
			const images = Array.from(this.images);

			for (let index = 0; index < images.length; index++) {
				const image = images[index];
				this.loadingText.next(`downloading image ${index + 1}/${images.length}`);
				await this.getImage(image);
				this.images.delete(image);
			}

			if (this.wakeLock) {
				await this.releaseWakeLock();
			}

			this.storage.set(this.preloadDateKey, new Date());

			this.loading.next(false);
		}
	}

	private async releaseWakeLock() {
		console.debug("WakeLock releasing");
		await this.wakeLock.release();
		this.wakeLock = null;
		console.debug("WakeLock released");
	}

}
