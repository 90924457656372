import { Injectable } from '@angular/core';
import { LocationError } from '@models/location/location-error';
import { LocationPermissionError } from '@models/location/location-permission-error';
import { LocationUpdate } from '@models/location/location-update';
import { ILocationService } from './interfaces/location.service.interface';

@Injectable()
export class LocationService implements ILocationService {

    getDeviceLocation(): Promise<LocationUpdate> {
        return new Promise<LocationUpdate>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                success => resolve(this.getSuccessResponse(success)),
                error => reject(this.getError(error)),
                { timeout: 10000 });
        });
    }

    private getSuccessResponse(position: GeolocationPosition): LocationUpdate {
        return new LocationUpdate(position.coords.latitude, position.coords.longitude);
    }

    private getError(error: GeolocationPositionError) {
        if(error.PERMISSION_DENIED > -1) {
            return new LocationPermissionError();
        }

        return new LocationError(error.message);
    }
}
