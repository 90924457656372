import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { BaseModal } from '../base-modal';

@Component({
	selector: 'legal-modal',
	styleUrls: ['./legal.component.scss'],
	template: `
        <div class="modal-body">
        <button class="close" (click)="closeAndDestroy()">
            <i class="fa-light fa-xmark"></i>
        </button>
        <div class="legal-item">
            <div>
                <h3>{{content.name}}</h3>
                <div [innerHTML]="content.header | lindbergFont"></div>
                <div [innerHTML]="content.content | lindbergFont"></div>
                <br>
            </div>
        </div>
        </div>
    `
})
export class LegalComponent extends BaseModal {

	content: { name: string, header: string, content: string } = null;

	constructor(
		breadcrumbService: BreadcrumbService
	) {
		super(breadcrumbService);
	}

}
