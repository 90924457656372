import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "@services/env.service";
import { firstValueFrom } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class SettingsService {

	public baseUrl: () => Promise<string> = async () => (await this.env.DataApiurl()) + '/v2/';

	constructor(
		private http: HttpClient,
		private env: EnvService
	) { }

	async getSetting(key: string) {
		return await firstValueFrom(this.http.get<SettingModel>(await this.baseUrl() + 'settings/?key=' + key));
	}

	async updateSetting(model: SettingModel) {
		return await firstValueFrom(this.http.patch<SettingModel>(await this.baseUrl() + 'settings/update', model));
	}

}

export class SettingModel {

	constructor(public Key: string, public Value: string | null) {}

}