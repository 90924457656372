import { Injectable } from '@angular/core';
import { OrderItemModel } from '@models/order-item';
import { OrderItemState } from '@models/order-item-state';
import { OrderItemMiscModel, OrderItemMiscState } from '@models/order-item-misc-model';
import { IndexDbService } from './index-db.service';
import { UserService } from './user.service';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { Mutex } from 'async-mutex';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QueueItemService {
    constructor(
        private indexedDbService: IndexDbService,
        private userService: UserService
    ) {
    }

    public static readonly orderQueueStoreName = "orderQueueStore";
    private static readonly orderItemsKey = "orderItems";
    private static readonly orderMiscItemsKey = "orderMiscItemsKey";
    private orderQueueStoreMutex = new Mutex();

    public orderItemPutInStore = new Subject();

    public async getSyncStateOnCustomer(customerNo : string) : Promise<SyncStateEnum>{
        const queuedOrderItemsOnCustomer = await this.getOrderItemsInQueue(customerNo, false);
        const queuedOrderMiscItemsOnCustomer = await this.getMiscOrderItemsInQueue(customerNo, false);

        if (queuedOrderItemsOnCustomer?.some(qo => qo.SyncState == SyncStateEnum.Running) ||
            queuedOrderMiscItemsOnCustomer?.some(qo => qo.SyncState == SyncStateEnum.Running)){
                return SyncStateEnum.Running;
        }

        if (queuedOrderItemsOnCustomer?.some(qo => qo.SyncState == SyncStateEnum.Failed) ||
            queuedOrderMiscItemsOnCustomer?.some(qo => qo.SyncState == SyncStateEnum.Failed)){
                return SyncStateEnum.Failed;
        }

        if (queuedOrderItemsOnCustomer?.some(qo => qo.SyncState == SyncStateEnum.Pending) ||
            queuedOrderMiscItemsOnCustomer?.some(qo => qo.SyncState == SyncStateEnum.Pending)){
                return SyncStateEnum.Pending;
        }

        return SyncStateEnum.SyncedWithDatabase;
    }

    public async getQueuedOrderItemsFromOrder(customerNo : string, orderId: number) : Promise<OrderItemState[]>{
        let key = this.getQueuedOrderItemKey(customerNo, orderId);

        const orderItemsFromIndexedDb = (await this.getFromQueueStore<Array<OrderItemState>>(key));
        return orderItemsFromIndexedDb;
    }

    public async getQueuedMiscOrderItemsFromOrder(customerNo : string, orderId: number) : Promise<OrderItemMiscState[]> {
        let key = this.getQueuedOrderMiscItemKey(customerNo, orderId);

        const orderMiscItemsFromIndexedDb = await this.getFromQueueStore<Array<OrderItemMiscState>>(key);

        return orderMiscItemsFromIndexedDb;
    }

    public async deleteOrderItemFromStore(orderItem: (OrderItemModel | OrderItemMiscModel)): Promise<boolean> {

        if (!orderItem.SyncOrderItemGuid) {
            return new Promise((resolve) => {
                console.error('missing orderItem.SyncOrderItemGuid', orderItem.SyncOrderItemGuid, orderItem)
                resolve(false);
            });
        }

        let queueKey : string;
        if (orderItem instanceof OrderItemMiscModel) {
            queueKey = this.getQueuedOrderMiscItemKey(orderItem.CustomerNo, orderItem.OrderId);
        }
        else{
            queueKey = this.getQueuedOrderItemKey(orderItem.CustomerNo, orderItem.OrderId);
        }

        let existingOrderItems = await this.getFromQueueStore<any>(queueKey);

        let orderItemIndexToRemove = existingOrderItems.findIndex(o => o.SyncOrderItemGuid === orderItem.SyncOrderItemGuid);
        if (orderItemIndexToRemove < 0) {
            return new Promise((resolve) => {
                console.error('orderItemIndexToRemove', orderItemIndexToRemove)
                resolve(false);
            });
        }

        existingOrderItems.splice(orderItemIndexToRemove, 1);
        await this.saveToQueueStore(existingOrderItems, queueKey);

        return new Promise((resolve) => {
            resolve(true);
        });
    }

    public async getReadyOrderItemsInQueue(customerNo : string, getAcrossUserCustomers : boolean): Promise<OrderItemState[]> {
        let orderItemsFromIndexedDb = await this.getOrderItemsInQueue(customerNo, getAcrossUserCustomers);
        return orderItemsFromIndexedDb?.filter(oi => oi.SyncState !== SyncStateEnum.SyncedWithDatabase &&
                                                    oi.SyncState !== SyncStateEnum.Failed);
    }

    public async createOrderItemInQueue(orderItem: OrderItemState): Promise<void> {

        let orderItems: OrderItemState[] = [];
        const release = await this.orderQueueStoreMutex.acquire();
        try {
            let key = this.getQueuedOrderItemKey(orderItem.CustomerNo, orderItem.OrderId);

            let existingOrderItems = await this.getFromQueueStore<OrderItemState[]>(key);

            if (existingOrderItems) {
                orderItems = existingOrderItems;
            }

            orderItems.push(orderItem);

            await this.saveToQueueStore(orderItems, key);
        } finally {
            release();
        }
    }

    public async updateOrderItemInQueue(orderItem: OrderItemState): Promise<void> {

        const release = await this.orderQueueStoreMutex.acquire();
        try {
            let key = this.getQueuedOrderItemKey(orderItem.CustomerNo, orderItem.OrderId);

            const orderItems = await this.getFromQueueStore<Array<OrderItemState>>(key);

            const newOrderItems = orderItems.filter(item => item.SyncOrderItemGuid != orderItem.SyncOrderItemGuid);

            newOrderItems.push(orderItem);

            await this.saveToQueueStore(newOrderItems, key);
        } finally {
            release();
        }
    }

    public async removeOrderItemInQueue(orderItem: OrderItemState): Promise<void> {

        const release = await this.orderQueueStoreMutex.acquire();
        try {
            let key = this.getQueuedOrderItemKey(orderItem.CustomerNo, orderItem.OrderId);

            const orderItems = await this.getFromQueueStore<Array<OrderItemState>>(key);

            const newOrderItems = orderItems.filter(item => item.SyncOrderItemGuid != orderItem.SyncOrderItemGuid);

            await this.saveToQueueStore(newOrderItems, key);
        } finally {
            release();
        }
    }

    public async getReadyMiscOrderItemsInQueue(customerNo : string, getAcrossUserCustomers : boolean): Promise<OrderItemMiscState[]> {
        let orderItemsFromIndexedDb = await this.getMiscOrderItemsInQueue(customerNo, getAcrossUserCustomers);
        return orderItemsFromIndexedDb?.filter(oi => oi.SyncState !== SyncStateEnum.SyncedWithDatabase &&
                                                    oi.SyncState !== SyncStateEnum.Failed);
    }

    public async createMiscOrderItemInQueue(orderMiscItem : OrderItemMiscState): Promise<void> {

        let orderItems: OrderItemMiscState[] = [];
        const release = await this.orderQueueStoreMutex.acquire();
        try {
            const key = this.getQueuedOrderMiscItemKey(orderMiscItem.CustomerNo, orderMiscItem.OrderId);

            let existingOrderItems = await this.getFromQueueStore<OrderItemMiscState[]>(key);

            if (existingOrderItems) {
                orderItems = existingOrderItems;
            }

            orderItems.push(orderMiscItem);

            await this.saveToQueueStore(orderItems, key);
        } finally {
            release();
        }
    }

    public async updateMiscOrderItemInQueue(orderItem: OrderItemMiscState): Promise<void> {

        const release = await this.orderQueueStoreMutex.acquire();
        try {
            const key = this.getQueuedOrderMiscItemKey(orderItem.CustomerNo, orderItem.OrderId);

            const orderItems = await this.getFromQueueStore<Array<OrderItemMiscState>>(key);

            const newOrderItems = orderItems.filter(item => item.SyncOrderItemGuid != orderItem.SyncOrderItemGuid);

            newOrderItems.push(orderItem);

            await this.saveToQueueStore(newOrderItems, key);
        } finally {
            release();
        }
    }


    public async removeMiscOrderItemInQueue(orderItem: OrderItemMiscState): Promise<void> {
        const release = await this.orderQueueStoreMutex.acquire();
        try {
            const key = this.getQueuedOrderMiscItemKey(orderItem.CustomerNo, orderItem.OrderId);

            const orderItems = await this.getFromQueueStore<Array<OrderItemMiscState>>(key);

            const newOrderItems = orderItems.filter(item => item.SyncOrderItemGuid != orderItem.SyncOrderItemGuid);

            await this.saveToQueueStore(newOrderItems, key);
        } finally {
            release();
        }
    }

    private async getMiscOrderItemsInQueue(customerNo : string, getAcrossUserCustomers : boolean): Promise<OrderItemMiscState[]> {

        const key = QueueItemService.orderMiscItemsKey + '_';

        const orderItems = await this.getFromQueueStoreByKeyPrefix(key);

        let orderItemsFromIndexedDb = orderItems?.selectMany(t => t.data) as Array<OrderItemMiscState>;

        if (!getAcrossUserCustomers){
            const orderCustomerNo = customerNo != null ? customerNo : (await this.userService.GetUser())?.CustomerNo;
            orderItemsFromIndexedDb = orderItemsFromIndexedDb?.filter(orderItem => orderItem.CustomerNo == orderCustomerNo);
        }

        return orderItemsFromIndexedDb;
    }

    private async getOrderItemsInQueue(customerNo : string, getAcrossUserCustomers : boolean): Promise<OrderItemState[]> {

        const key = QueueItemService.orderItemsKey + '_';

        const orderItems = await this.getFromQueueStoreByKeyPrefix(key);

        let orderItemsFromIndexedDb = orderItems?.selectMany(t => t.data) as Array<OrderItemState>;


        if (!getAcrossUserCustomers) {
            const orderCustomerNo = customerNo != null ? customerNo : (await this.userService.GetUser())?.CustomerNo;
            orderItemsFromIndexedDb = orderItemsFromIndexedDb?.filter(orderItem => orderItem.CustomerNo == orderCustomerNo);
        }

        return orderItemsFromIndexedDb;
    }

    private getQueuedOrderItemKey(customerNo : string, orderId : number){
        if (customerNo) {
            return QueueItemService.orderItemsKey + '_' + customerNo + '_' + orderId;
        }
        else {
            return QueueItemService.orderItemsKey + '_' + orderId;
        }
    }

    private getQueuedOrderMiscItemKey(customerNo : string, orderId : number){
        if (customerNo) {
            return QueueItemService.orderMiscItemsKey + '_' + customerNo + '_' + orderId;
        }
        else {
            return QueueItemService.orderMiscItemsKey + '_' + orderId;
        }
    }

    private async saveToQueueStore<T>(result: T, url: string) {
        await this.indexedDbService.replaceAndSave<T>(QueueItemService.orderQueueStoreName, result, url);
    }

    private async getFromQueueStore<T>(url: string) {

        return await this.indexedDbService.get<T>(QueueItemService.orderQueueStoreName, url);
    }

    private async getFromQueueStoreByKeyPrefix(key: string) {

        return await this.indexedDbService.getByKeyPrefix<any>(QueueItemService.orderQueueStoreName, key);
    }

}
