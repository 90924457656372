import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lindbergFont' })
export class LindbergFontPipe implements PipeTransform {
	transform(source: string, medium: boolean = false): string {
		if (source && source.replace) {
			return source.replace(/lindberg/gi, `<span class="lindbergfont ${medium ? "font-medium" : ""}">LINDBERG</span>`);
		}
	}
}
