import { Injectable } from '@angular/core';
import { IndexDbService } from './index-db.service';
import { OfflineService } from './offline.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  public static readonly storeName = "preload";

  constructor(
    private indexedDbService: IndexDbService,
    private offlineService: OfflineService
  ) { }




  public async getOrSetFromStore<T>(url: string, data: T = null): Promise<T> {
    let result: T = null;

    if (!data) {
      result = await this.indexedDbService.get<any>(StoreService.storeName, url);
    }

    if (!result) {
      if (!data) {
        result = await this.offlineService.getDataWithOfflineCheck<T>(url);
      }
      else {
        result = data;
      }

      if (this.indexedDbService.databaseIsOpen) {
        await this.indexedDbService.replaceAndSave<T>(StoreService.storeName, result, url);
      }
    }

    return result;
  }


}
