import { OrderItemMiscModel } from './order-item-misc-model';
import { OrderItemMiscType } from './order-item-misc-type';

export class MiscItemCreateModel {
    constructor(
        public Reference: string,
        public Amount: number,
        public Ecommerce: string,
        public EcommerceVariantCode: string,
        public EcommerceNoUnique: string,
        public Description: string,
        public CategoryDescription: string,
        public CustomerNo: string,
        public KidTeen: boolean,
        public ToolsIncluded: boolean,
        public ProductLineCode: string,
        public Type: OrderItemMiscType,
        public OrderId: number,
        public DiscountCode: string,
        public DiscountPercent: number
    ) { }

    public static Create(orderItemMiscModel: OrderItemMiscModel): MiscItemCreateModel {
        const createModel = new MiscItemCreateModel(
            orderItemMiscModel.Reference,
            orderItemMiscModel.Amount,
            orderItemMiscModel.Ecommerce,
            orderItemMiscModel.EcommerceVariantCode,
            orderItemMiscModel.EcommerceNoUnique,
            orderItemMiscModel.Description, orderItemMiscModel.MiscModel?.ItemCategoryDescription,
            orderItemMiscModel.CustomerNo,
            orderItemMiscModel.KidTeen,
            orderItemMiscModel.ToolsIncluded,
            orderItemMiscModel.ProductLineCode,
            orderItemMiscModel.Type,
            orderItemMiscModel.OrderId,
            orderItemMiscModel.DiscountCode,
            orderItemMiscModel.DiscountPercent
        );
        return createModel;
    }
}
