import { Component } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorReportService } from '@services/error-report.service';
import { AppState } from '@shared/app-state';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { NavigateService } from '../_services/navigate.service';
import { BaseModal } from './base-modal';

@Component({
    selector: 'error-modal',
    styleUrls: ['./error-modal.scss'],
    template: `
        <div class="error-message">
                
            <button class="close" (click)="closeAndDestroy()">
                <i class="fa-light fa-xmark"></i>
            </button>
            <h3><i class="icon-info-red icon" (click)="onCancel(false)"></i>Error</h3>
            <p>{{errorMessage}}</p>
            <div>
                <p>If this problem persists, please contact LINDBERG directly.</p>
            </div>
            <br />
            <div class="text-center">
                <button (click)="openWebSupport()" class="btn btn-primary mr-2">
                    {{"COMMON.CREATEERRORREPORT" | translate}}
                </button>
                <button (click)="onCancel()" class="btn btn-secondary">
                    home
                </button>
            </div>
        </div>
        <a href="https://beian.miit.gov.cn/" target="_blank" *ngIf="appState.showChineseLicenseId" class="chineseLicense">沪ICP备18032619号-1</a>
    `
})

export class ErrorComponent extends BaseModal {
    errorMessage: string = null;
    errorMessageTechnical: string = null;
    errorKey: string = null;

    constructor(public router: Router, public navigateService: NavigateService, breadcrumbService: BreadcrumbService, 
        private errorReportService: ErrorReportService, public zone: NgZone, public appState: AppState) {
        super(breadcrumbService);
     }

    async onCancel(gotoFrontpage: boolean = true) {
        this.closeAndDestroy();
        if(gotoFrontpage){
            await this.router.navigate(["/"]);
            window.location.reload();
        }
    }

    async openWebSupport() {
        const currentUrl = this.router.url;
        await this.errorReportService.openWebSupport(this.errorKey, this.errorMessageTechnical ?? this.errorMessage, currentUrl);
    }
    
}
