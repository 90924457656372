import { ComponentCodeConstants } from '@shared/component-constants';
import { MenuConstants } from '@shared/menu-constants';
import { ComponentBindingModel } from 'src/_models/component-binding';
import { ComponentDependencyModel } from 'src/_models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { IDependencyCalculator } from "../dependency-calculator.interface";

export class FindNewChangeByComponentDependencyCalculator implements IDependencyCalculator {

    constructor() {
    }

    async getChanges(
        dependency: ComponentDependencyModel,
        bindings: Array<ComponentBindingModel>,
        variant: ComponentVariantModel,
        oppositChosenVariants: Array<ComponentVariantModel>,
        variantSelectionToCheckAgainst: Array<ComponentVariantModel>): Promise<Array<ComponentVariantChangeModel>> {
        if(!bindings.some(binding => binding.ComponentId === variant.Component.Id && binding.ComponentVariant == null)) {
            return [];
        }

        console.debug(`Finding new change by component for variant: ${variant.Code}`);

        const res = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
        res.VariantColor = null;
        res.NotSelected = true;

        const result = new ComponentVariantChangeModel(variant, res, null);
        result.MenuTypesWithWarning.push(MenuConstants.COLOR);
        if (variant.Component.Code == ComponentCodeConstants.Gemstone) {
            result.MenuTypesWithWarning.push(MenuConstants.GEMSTONE);
        }
        if (variant.Component.Code == ComponentCodeConstants.Nosepad) {
            result.MenuTypesWithWarning.push(MenuConstants.DESIGN);
        }

        return [result];
    }
}
