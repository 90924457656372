import { Component, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ModelgroupConstants } from '@shared/modelgroup-constants';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { VariantSizeModel } from '../../../../_models/variant-size';
import { SorterService } from '../../../../_services/sorter.service';
import { FadeInOutTransition } from '../../../../shared/transitions/fade-in-out.transition';
import { ImageService } from './../../../../_services/image.service';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'bridge-size',
	styleUrls: ['./bridgesize.component.scss'],
	animations: [
		FadeInOutTransition()
	],
	template: `
        <div class="flex flex-align-end">
            <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)" [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.BRIDGESIZE.NAME'"></customizer-component-header>
            <icon-info (click)="showInfo = !showInfo" class="header-icon"></icon-info>
        </div>
        <customizer-list *ngIf="sizes.length"
                        class="bordered"
                        [selectedIndex]="selectedIndex"
                        (selectedChange)="onSelect($event)"
                        [items]="sizes"
                        [name]="'Size'"
                        [discontinued]="'Discontinued'"
                        [(scrollPosition)]="menu.menuDetails.scrollPosition"
                        [editMode]="state === customizerStateEnum.EDITORDER"
                        [variantOrModelIsNew]="variantOrModelIsNew"
                        [image]="getVoid">
        </customizer-list>
        <div *ngIf="showInfo" [@fadeInOutTransition] class="spacer flex flex-column flex-center">
            <h6>{{ 'CUSTOMIZER.COMPONENTS.BRIDGESIZE.INFO' | translate }}</h6>
            <dcg-image class="illustration-image" [src]="infoImage()" [alt]="'CUSTOMIZER.COMPONENTS.BRIDGESIZE.INFO' | translate"></dcg-image>
        </div>
    `
})
export class BridgeSizeComponent extends BaseCustomizerComponent {

	public sizes: Array<VariantSizeModel> = [];
	public selectedIndex: number = 0;
	public showInfo: boolean = false;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private sorter: SorterService,
		public imageService: ImageService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
	}

	async onChanges(changes: SimpleChanges) {
		this.events.showLoader(true);
		const sizesFromApi = await this.fetch();

		if (sizesFromApi) {
			const sizesFromApiUuids = sizesFromApi.map(x => x.Uuid);
			const currentSizeUuids = this.sizes.map(x => x.Uuid);
			const newSizes = sizesFromApiUuids.filter(x => currentSizeUuids.every(y => y != x));

			if (newSizes.length > 0) {
				this.sizes = sizesFromApi;
			}

			if (this.sizes.length) {
				this.selectedIndex = this.sizes.findIndex(x => x.Uuid == this.getSelectedVariant.VariantSize.Uuid);
			}
		}

		this.events.showLoader(false);
	}

	public infoImage = () => {
		return this.imageService.GetComponentInfoImage(this.getSelectedVariant, 'size',
			this.modelGroup.Code == ModelgroupConstants.STRIP3P || this.modelGroup.Code == ModelgroupConstants.SPIRIT ? this.modelGroup.Code : undefined);
	}

	onSelect(index: number) {
		this.events.showLoader(true);

		const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		newVariant.VariantSize = this.sizes[index];
		this.updateComponentSize(new ComponentVariantChangeModel(oldVariant, newVariant, null));
	}

	async fetch(): Promise<Array<VariantSizeModel>> {
		const variant = this.getSelectedVariant;

		let variantSizes: Array<VariantSizeModel>;
		if (variant) {
			variant.VariantSizeViewed = true;

			const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, variant.Component.Uuid, variant.Uuid);

			await this.RunDisablingSystem(apidata);

			variantSizes = apidata.length ? apidata[0].VariantSizes.filter(x => !x.isDisabled).sort(this.sorter.sortBy(VariantSizeModel)) : [];
		}

		return variantSizes;
	}
	getVoid = () => {
		return null;
	}

}
