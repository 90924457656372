import { DataTag } from "./data-tag";

export class Tag<T> extends DataTag<T> {
    private tagName: string;
    private tagId: any;

    constructor(id: any, name: string) {
        super();
        this.tagName = name;
        this.tagId = id;
    }

    get name() {
        return this.tagName;
    }

    get id() {
        return this.tagId;
    }
}
