import { BrandEnum } from '@infrastructure/brand.enum';
import { FirstPair } from '@infrastructure/firstPair.enum';
import { Gender } from '@infrastructure/gender.enum';
import { Type } from 'class-transformer';
import { ConfigurationOrderApimodel } from './api-models/configuration-order-apimodel';
import { ModelOrderApimodel } from './api-models/model-order-apimodel';
import { ModelgroupOrderApimodel } from './api-models/modelgroup-order-apimodel';
import { ModelsizeOrderApimodel } from './api-models/modelsize-order-apimodel';
import { ProductlineOrderApimodel } from './api-models/product-line';
import { MiscModel } from './misc-model';
import { OrderLineState } from './order-line-state';
import { PriceItem } from './price-model';
import { TempleInclinationEnum } from './variant-temple-inclination';

export class OrderItemState {

	public Id: number;
	public CustomerNo: string;
	public Uuid: string;
	public SyncOrderItemGuid: string;
	public CreatedAt: Date;
	public Reference: string;
	public Engraving: string;
	public Amount: number;
	public Comment: string;
	public Demo: boolean;
	public OrderId: number;
	public Username: string;
	public IsRimless: boolean;
	public IsFullFrame: boolean;
	public MultiplePossibleConfigurations: boolean;
	public ProductLineUuid: string;
	public ProductLineCode: string;
	public HasForms: boolean;
	public HasFixtures: boolean;
	public FirstTimeDelivery: boolean;

	public FirstPair: FirstPair;
	public Age: string;
	public Gender: Gender;

	public DiscountCode: string;
	public DiscountPercent: number;

	public ModelGroupUuid: string;
	public ModelGroupCode: string;
	public ModelGroupDescription: string;

	public ModelUuid: string;
	public ModelCode: string;

	public IdenticalModelsizeUuid: string;
	public ModelSizeUuid: string;
	public ModelSizeCode: string;

	public ConfigurationUuid: string;

	public ModelConfigurationCode: string;
	public ModelConfigurationUuid: string;
	public ModelConfigurationDescription: string;
	public TempleInclination: TempleInclinationEnum;

	public CaseItemNo: string;
	public CaseItemVariantCode: string;
	public Brand: BrandEnum;

	public New: boolean;
	public Selected: boolean = false;
	public Collapsed: boolean = false;
	public LowTempleInclinationPossible: number;
	public Order: string;
	public Price: PriceItem;
	public IsPrecious: boolean;
	public PriceJson: string;

	@Type(() => MiscModel)
	public Case: MiscModel;

	@Type(() => ProductlineOrderApimodel)
	public OriginalProductLine: ProductlineOrderApimodel;

	@Type(() => ModelgroupOrderApimodel)
	public OriginalModelGroup: ModelgroupOrderApimodel;

	@Type(() => ModelOrderApimodel)
	public OriginalModel: ModelOrderApimodel;

	@Type(() => ModelsizeOrderApimodel)
	public OriginalModelSize: ModelsizeOrderApimodel;

	@Type(() => ConfigurationOrderApimodel)
	public OriginalConfiguration: ConfigurationOrderApimodel;

	@Type(() => OrderLineState)
	public OrderLines: Array<OrderLineState>;
	
	public SyncState: number;
	public SyncErrors: number;

	public IsLimited: boolean;

	public SkipUserCheck: boolean;

	public Parts: string;
	
	public SerialNumber: string;

	constructor() {
		this.SyncErrors = 0;
	}

}
