import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OfflineService } from '@services/offline.service';
import { StorageErrorsService } from '@services/storage-errors.service';
import { AppState } from '@shared/app-state';
import { CookieService } from 'ngx-cookie-service';

@Component({
	selector: 'alert',
	templateUrl: "alert.component.html",
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

	public showAlert = false;
	public alertMessage = '';
	public showAlertCount = 0;

	constructor(
		private offlineService: OfflineService,
		private translateService: TranslateService,
		public appState: AppState,
		public cookieService: CookieService,
		public storageErrorsService: StorageErrorsService,
	) {
		this.offlineService.offlineErrorOccured.subscribe(() => {
			this.showAlert = true;
			this.alertMessage = this.translateService.instant("ALERT.OFFLINE");
		})

		this.appState.storageErrorOccured.subscribe(async s => {

			const activeStorageAlert = this.cookieService.get('storageAlert') !== 'activeStorageAlert';

			if (activeStorageAlert && this.showAlertCount === 0) {
				this.showAlertCount++;
				await this.storageErrorsService.sendToLog();
				await this.storageErrorsService.setStorageAlertCookie();
			}
		});
	}

	public closeAlert() {
		this.showAlert = false;

	}

}
