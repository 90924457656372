import { Component, SimpleChanges } from '@angular/core';
import { BrandModeService } from '@services/brandmode.service';
import { ConfigurationService } from '@services/configuration.service';
import { VariantColorModel } from 'src/_models/variant-color';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { ComponentVariantModel } from '../../../../_models/component-variant';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { DataApiService } from '../../../../_services/data-api.service';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'cstmsr-lower-rim-color',
	template: `
      <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
          <customizer-colors-auto-update *ngIf="selectableVariants.length"
              [selected]="getSelectedVariant.VariantColor"
              [selectedColorGroup]="currentColorGroup"
              [variants]="selectableVariants"
              [editMode]="state === customizerStateEnum.EDITORDER"
              [matchableColors]="matchableColors"
              [useColorGroup]="menu.contentGrouping"
              (colorMatchChange)="matchColors($event)"
              (selectedColorGroupChange)="onSelectGroup($event)"
              (selectedColorChange)="onSelect($event)"
              [(scrollPosition)]="menu.menuDetails.scrollPosition"
              [extendedInformation]="extendedInformation"
              [extendedInformationTable]="false"
              [variantOrModelIsNew]="variantOrModelIsNew"
              [matchColor]="menu.matchColor"
          >
          </customizer-colors-auto-update>
      </customizer-component-header>
      <precious-metal-info></precious-metal-info>
    `
})

export class LowerRimColorComponent extends BaseCustomizerComponent {

	public selectableVariants: Array<ComponentVariantFromApiModel> = [];
	public extendedInformation: boolean = false;

	constructor(
		public events: EventsService,
		public api: DataApiService,
		public imageService: ImageService,
		private brandmodeService: BrandModeService,
		public configurationService: ConfigurationService
	) {
		super(events, api, configurationService);
		this.extendedInformation = this.brandmodeService.isPrecious;
	}

	async initData() {
		this.events.showLoader(true);

		this.selectableVariants = await this.fetchData();

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	async fetchData(): Promise<Array<ComponentVariantFromApiModel>> {
		const variant = this.getSelectedVariant;
		variant.VariantColorViewed = true;

		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid, variant.VariantSize.Uuid);
		await this.RunDisablingSystem(apidata);
		return apidata;
	}

	onSelect(color: VariantColorModel) {
		this.events.showLoader(true);

		const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		newVariant.VariantColor = color;
		this.updateVariant(new ComponentVariantChangeModel(oldVariant, newVariant, null));
	}

}
