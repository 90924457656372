import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FeaturedExpiredPageComponentComponent } from '@app/featured-expired-page-component/featured-expired-page-component.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FeaturedPageComponent } from 'src/app/featured/featured';
import { SharedModule } from '../shared/shared.module';
import { CustomizerSharedModule } from './../customizer-shared/customizer-shared.module';
import { routes } from './featured.routes';

@NgModule({
	declarations: [
		FeaturedPageComponent,
		FeaturedExpiredPageComponentComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		TranslateModule,
		MomentModule,
		SharedModule,
		RouterModule.forChild(routes),
		UiSwitchModule,
		CustomizerSharedModule,
		InfiniteScrollModule
	],
	providers: [
	],
	bootstrap: [
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
	exports: [
	]
})
export class FeaturedModule { }
