import { Component, EventEmitter } from '@angular/core';
import { BaseModal } from '@modals/base-modal';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';

@Component({
	selector: 'app-order-chooser-modal',
	templateUrl: './order-chooser-modal.component.html',
	styleUrls: ['./order-chooser-modal.component.scss']
})
export class OrderChooserModalComponent extends BaseModal {

	orderSelect: EventEmitter<number> = new EventEmitter();

	constructor(
		breadcrumbService: BreadcrumbService,
		public orderOnBehalfService: OrderOnBehalfService
	) {
		super(breadcrumbService);
	}

	onSelect(orderId: number) {
		this.orderSelect.emit(orderId);
		this.closeAndDestroy();
	}

}
