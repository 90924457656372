import { Type } from "class-transformer";
import { ComponentVariantModel } from "./component-variant";
import { VariantColorModel } from "./variant-color";
import { VariantSizeModel } from "./variant-size";

export class ManualConfigVariants {
    Id: number;
    Uuid: string;

    @Type(() => ComponentVariantModel)
    ComponentVariantModel: ComponentVariantModel;

    @Type(() => VariantSizeModel)
    ComponentSizeModel: VariantSizeModel;

    @Type(() => VariantColorModel)
    ComponentColorModel: VariantColorModel;

    public GetVariant(): ComponentVariantModel {
        const componentVariant = Object.assign(new ComponentVariantModel(this.ComponentVariantModel.Discontinued, this.ComponentVariantModel.New), this.ComponentVariantModel);
        const componentSize = Object.assign(new VariantSizeModel(this.ComponentSizeModel.Discontinued, this.ComponentSizeModel.New), this.ComponentSizeModel);
        const componentColor = Object.assign(new VariantColorModel(this.ComponentColorModel.Discontinued, this.ComponentColorModel.New), this.ComponentColorModel);

        componentVariant.VariantColor = componentColor;
        componentVariant.VariantSize = componentSize;
        return componentVariant;
    }
}
