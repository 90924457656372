import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';

@Component({
	selector: "checkbox",
	templateUrl: 'checkbox.component.html',
	styleUrls: ['./checkbox.component.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class CheckboxComponent {

	@HostBinding('style.pointer-events') get pEvents(): string {
		if (this.disabled) {
			return 'none';
		}
		return 'auto';
	}

	@Input() checked: boolean;
	@Input() disabled: boolean;
	@Input() hasInfo?: boolean;
	@Input() label: string;
	@Input() radio: boolean = false;
	@Input() icon: any;
	@Input() isPrecious?: boolean = false;

	@Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

	public showInfo: boolean = false;

	public toggleInfo() {
		this.showInfo = !this.showInfo;
	}

	public toggleChecked() {
		this.checkedChange.emit(!this.checked);
	}

}
