<span class="flex flex-column">
	<span *ngIf="this.newText" class='font-medium' [ngClass]="{'largeFont': newLargeText, 'new': true}">
		{{newText}}
	</span>
	<span>
		<span class="discontinued" *ngIf="newDate && !omitDiscontinuedLabel">
			<ng-container *ngIf="discontinuedModel?.discontinuedprefixText">{{ discontinuedModel.discontinuedprefixText }}</ng-container>
			{{ 'COMMON.DISCONTINUED' | translate: {date: (newDate | dateToLocal)} }}
		</span>
	</span>
	<app-first-time-delivery [model]="AllAssortments"></app-first-time-delivery>
</span>