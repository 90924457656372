<div class="tag-span">
	<div class="filter-content">
		<div class="tags">
			<div>{{'TAGFILTER.FILTERS' | translate}}:</div>

			<div *ngFor="let tag of tags | async">
				<tag class="tag" (click)="tagClicked(tag)" [code]="tag.Description | dataTranslate | translate"></tag>
			</div>

			<tag class="tag" *ngIf="hbox" [code]="hbox" (click)="clearHbox()"></tag>
		</div>

		<div class="actions">
			<span class="clear" *ngIf="((tagsService.modalIsOpen | async) === false) && ((tags | async).length || hbox)" (click)="onEdit()">{{'TAGFILTER.EDITFILTERS' | translate}}</span>
			<span class="clear" *ngIf="(tags | async).length || hbox" (click)="onClear()">{{'TAGFILTER.CLEARFILTERS' | translate}}</span>
		</div>
	</div>
</div>
