<div class="flex flex-column flex-center" *ngIf="isReady">
    <h4>{{'BAG.TOWHICHORDER' | translate}}</h4>
    <div class="flex flex-column flex-grow">
        <ui-button
            [buttonType]="'button'"
            [buttonStyle]="'primary'"
            [block]="true"
            [disabled]="order.IsLocked"
            (click)="onOrderChosen(order.Id)"
            *ngFor="let order of orders; let i = index"
        >{{ 'BAG.ORDERNAME' | translate: { count: getOrderName(order, i)} }}</ui-button>
        <ui-button
            [buttonType]="'button'"
            [buttonStyle]="'primary'"
            [block]="true"
            (click)="onOrderChosen(null)"
        >{{ 'BAG.NEWORDER' | translate }}</ui-button>
    </div>
</div>