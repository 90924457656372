import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { ImageService } from 'src/_services/image.service';
import { OrderItemModel } from '../../_models/order-item';
import { AngleConstants } from '../../shared/angle-constants';
import { BaseModal } from '../base-modal';
@Component({
    selector: 'discontinued-order-modal',
    templateUrl:'discontinued-order.modal.html',
    styleUrls: ['./discontinued-order.modal.scss']
})
export class DiscontinuedOrderModalComponent extends BaseModal {
    discontinuedData: Array<OrderItemModel>;

    constructor(private imageService: ImageService, breadcrumbService: BreadcrumbService) {
        super(breadcrumbService);
     }

    getImage = (item: OrderItemModel): string => this.imageService.GetOrderItemImage(item, item.IsRimless ? AngleConstants.Front : AngleConstants.Perspective);

}
