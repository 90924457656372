import { AfterViewInit, Component, SimpleChanges } from '@angular/core';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { ModelGroupModel } from 'src/_models/model-group';
import { EventsService } from 'src/_services/events.service';
import { DataApiService } from '../../../../_services/data-api.service';
import { ImageService } from '../../../../_services/image.service';
import { ComponentCodeConstants } from '../../../../shared/component-constants';
import { CustomizerMenuPartModel } from './../../../../_models/customizer-menu-part';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	template: `
  <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
        <div id="case-list-container" class="inline-scroll" *ngIf="availableCases && availableCases.length">
            <case-list-item class="configuration-items" *ngFor="let case of availableCases"
                                (click)="onSelect(case)"
								[attr.id]="case.EcommerceNoUnique"
                                [ngClass]="{'is-selected': selectedCase !== null && case['EcommerceNoUnique'] === selectedCase['EcommerceNoUnique']}"
                                [miscModel]="case"
                                [image]="getImage"
                                [selected]="selectedCase !== null && case['EcommerceNoUnique'] === selectedCase['EcommerceNoUnique']"
                                [editMode]="state === customizerStateEnum.EDITORDER"
                                [variantOrModelIsNew]="variantOrModelIsNew"
                                >
            </case-list-item>
        </div>
    </customizer-component-header>
  `
})
export class CaseComponent extends BaseCustomizerComponent implements AfterViewInit {

	public selectedIndex: number = 0;
	public availableCases: Array<MiscModel> = [];

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private imageService: ImageService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	ngAfterViewInit(): void {
		setTimeout(() => this.scrollIntoView(), 500);
	}

	private scrollIntoView() {
		if (this.selectedCase) {
			const parent = document.getElementById('case-list-container');

			if (parent) {
				const item = parent.querySelector('[id="' + this.selectedCase.EcommerceNoUnique + '"]');

				if (item) {
					item.scrollIntoView({ behavior: 'smooth' });
				}
			}
		}
	}

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		return modelGroup.FullFrame && chosenCase != null;
	}

	async initData() {
		this.events.showLoader(true);

		this.availableCases = await this.fetchCases();

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {

	}

	onSelect(model: MiscModel) {
		this.events.showLoader(true);
		this.updateCase(model);
	}

	getImage = (miscModelViewmodel: MiscModel) => {
		return this.imageService.GetMiscImage(miscModelViewmodel.EcommerceNoUnique, 1, "Large");
	}

	async fetchCases(): Promise<Array<MiscModel>> {
		const currentTemple = this.variants.find(x => x.Component.Code == ComponentCodeConstants.Temple);

		const cases = await this.configurationService.getCases(this.model.Id, this.modelSize, this.configuration.ModelConfigurationUuid, this.modelGroup, currentTemple.Component.Uuid, currentTemple.Uuid, currentTemple.VariantSize.Uuid);

		return cases.map(t => t.MiscModel);
	}

}
