import { Routes } from '@angular/router';
import { AuthGuard } from 'src/_guards/auth.guard';
import { MiscCategoryPageComponent } from './misc-category/misc-category.component';
import { MiscGroupPageComponent } from './misc-group/misc-group.component';

export const routes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		data: {
			breadcrumb: ':categoryCode'
		},
		children: [
			{
				path: '',
				component: MiscCategoryPageComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'group/:groupCode',
				component: MiscGroupPageComponent,
				canActivate: [AuthGuard],
				data: {
					breadcrumb: ':groupCode'
				}
			}
		]
	},
];
