import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ModelModel } from '@models/api-models/model';
import { ComponentVariantModel } from '@models/component-variant';
import { ConfigurationModel } from '@models/configuration';
import { CustomerModel } from '@models/customer';
import { InspirationGroupModel } from '@models/inspiration-group';
import { ManualConfigCollectionModel } from '@models/manual-config-collection-model';
import { ManualConfigCreateModel } from '@models/manual-config-create';
import { ModelSizeModel } from '@models/model-size';
import { OrderItemModel } from '@models/order-item';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import { BrandModeService } from '@services/brandmode.service';
import { EventsService } from '@services/events.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { Background, ImageService, ImageSize } from '@services/image.service';
import { LanguageService } from '@services/language.service';
import { ManualConfigService } from '@services/manual-config.service';
import { NavigateService } from '@services/navigate.service';
import { SessionService } from '@services/session.service';
import { UserService } from '@services/user.service';
import { FeaturedGroupConstant, ManualConfigGroupConstant } from '@shared/featured-group-constant';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import moment from 'moment';

@Component({
  selector: 'favorite-collection-overlay',
  templateUrl: 'favorite-collection-overlay.component.html',
  styleUrls: ['./favorite-collection-overlay.component.scss'],
  animations: [
    FadeInOutTransition(0.95)
  ]
})
export class FavoriteCollectionOverlayComponent implements OnInit {
  @HostBinding('@fadeInOutTransition') fade = '';

  @Output() public applyFavoriteCollectionChangeEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() public applyRemovedFromFavoriteCollectionChange: EventEmitter<void> = new EventEmitter();
  @Output() public cancelChange: EventEmitter<void> = new EventEmitter();

  favoriteCollections: Array<ManualConfigCollectionModel> = [];
  favoriteCollection: ManualConfigCollectionModel;
  inspirationGroup: InspirationGroupModel;
  showNameInput: boolean = false;
  favoriteCollectionName: string;
  feedbackAfterSaving: string = null;
  bagItemsWereSaved: boolean = false;

  @Input() savingBagItems: boolean;
  @Input() customer: CustomerModel;
  @Input() bagItemsToSaveToFavoriteCollection: Array<OrderItemModel>;
  @Input() configuration: ConfigurationModel;
  @Input() modelSize: ModelSizeModel;
  @Input() model: ModelModel;
  @Input() variants: Array<ComponentVariantModel>;
  @Input() manualconfigGroups: Array<InspirationGroupModel>;
  @Input() templeInclination: TempleInclinationEnum;
  @Input() showClose: boolean = false;

  public selectedFavoriteCollections: Array<ManualConfigCollectionModel> = [];
  private readonly favoriteCollectionKey = "featured_favoriteCollection";

  constructor(
    private eventsService: EventsService,
    private manualConfigService: ManualConfigService,
    private sessionService: SessionService,
    private userService: UserService,
    private navigateService: NavigateService,
    private languageService: LanguageService,
    private brandModeService: BrandModeService,
    private imageService: ImageService,
    private googleAnalyticsService: GoogleAnalyticsService,
    ) {
  }
  async ngOnInit(): Promise<void> {

    if (!this.savingBagItems) {
      await this.loadFavoriteCollections();
    } else {
      if (this.customer) {
        this.favoriteCollectionName = this.languageService.instant('BAG.CUSTOMERBAG', { customerName: this.customer.Name })
      } else {
        const user = await this.userService.GetUser()
        this.favoriteCollectionName = this.languageService.instant('BAG.CUSTOMERBAG', { customerName: user.Name })
      }
      this.favoriteCollectionName += ' ' + moment().format("MM-DD-YYYY HH:mm:ss")
    }
  }

  public async loadFavoriteCollections(reload: boolean = false) {
    this.favoriteCollections = (await this.manualConfigService.getManualConfigCollections(reload)).filter(m => m.Id > 0);
    await this.updateFavorites();
  }

  public onCancel() {
    this.cancelChange.emit();
  }

  public async saveBagItemsToFavoriteCollection() {

    const favoriteCollection = new ManualConfigCollectionModel();
    favoriteCollection.Name = this.favoriteCollectionName
    this.favoriteCollection = await this.manualConfigService.saveFavoriteCollection(favoriteCollection);

    this.eventsService.showLoader(true);
    const now = new Date();
    const releasedate = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
    releasedate.setUTCFullYear(now.getFullYear(), now.getUTCMonth(), now.getUTCDate());

    this.bagItemsToSaveToFavoriteCollection.forEach(async bagItem => {
      const model: ModelModel = new ModelModel(null, false)
      const configuration: ConfigurationModel = new ConfigurationModel(null, false);
      const modelSize: ModelSizeModel = new ModelSizeModel(null, false);
      const variants: Array<ComponentVariantModel> = bagItem.OrderLines.map(x => ComponentVariantModel.createComponentVariantModelFromOrderLine(x, bagItem.Brand));
      const templeInclination = bagItem.TempleInclination;
      model.Id = bagItem.OriginalModel.Id
      configuration.ModelConfigId = bagItem.OriginalConfiguration.ModelConfigId;
      modelSize.Id = bagItem.OriginalModelSize.Id

      this.inspirationGroup = this.manualconfigGroups.find(x => x.Type == FeaturedGroupConstant.Favorite);
      const manualconfigGroupId = this.inspirationGroup?.Id;

      let imageUrls: Array<string> = [];

      const manualConfiguration = ManualConfigCreateModel.CreateFromData(manualconfigGroupId,
        releasedate,
        model,
        configuration,
        modelSize,
        variants,
        templeInclination,
        this.favoriteCollection.Id,
        this.brandModeService.isPrecious,
        imageUrls);

      await this.manualConfigService.createInspirationConfiguration(manualConfiguration);
    });

    this.bagItemsWereSaved = true;
    this.eventsService.showLoader(false);


    const user = this.userService.currentUser$.getValue();
    if (user && this.bagItemsToSaveToFavoriteCollection.length > 0) {
      await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', `${user.Uuid} saved ${this.bagItemsToSaveToFavoriteCollection.length} bag items to favorite collection '${favoriteCollection.Name}'`, 20);
    }
  }

  public async gotoFavoriteCollection(favoriteCollection: ManualConfigCollectionModel) {

    this.sessionService.set(this.favoriteCollectionKey, favoriteCollection);

    this.navigateService.navigateToFeatured(this.inspirationGroup);

    this.onCancel();
  }

  public async toggleFavoriteCollection(favoriteCollection: ManualConfigCollectionModel) {
    let newFavoriteCollection = false;
    if (!favoriteCollection) {
      favoriteCollection = new ManualConfigCollectionModel();
      favoriteCollection.Name = this.favoriteCollectionName
      favoriteCollection = await this.manualConfigService.saveFavoriteCollection(favoriteCollection);
      newFavoriteCollection = true;
    }
    this.manualConfigService.clearManualConfig(ManualConfigGroupConstant.Favorites);
    if (!favoriteCollection.CurrentFavoriteUuid) {
      if (newFavoriteCollection) {
        await this.loadFavoriteCollections(true);
      }
      this.showNameInput = false;
      this.favoriteCollectionName = '';
      await this.MakeFavorite(favoriteCollection);
      this.applyFavoriteCollectionChangeEmitter.emit(true);
      this.feedbackAfterSaving = this.languageService.instant('MY.COLLECTIONS.FRAME.WAS.SAVED')
    } else {
      await this.RemoveFavorite(favoriteCollection.CurrentFavoriteUuid);
      this.applyFavoriteCollectionChangeEmitter.emit(false);
      this.feedbackAfterSaving = this.languageService.instant('MY.COLLECTIONS.FRAME.WAS.REMOVED')
    }
    setTimeout(() => {
      this.feedbackAfterSaving = null;
    }, 3500);
  }

  public async MakeFavorite(favoriteCollection: ManualConfigCollectionModel = null) {

    this.eventsService.showLoader(true);
    const now = new Date();
    const releasedate = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
    releasedate.setUTCFullYear(now.getFullYear(), now.getUTCMonth(), now.getUTCDate());

    const manualconfigGroupId = this.manualconfigGroups.find(x => x.Type == FeaturedGroupConstant.Favorite).Id;

    let imageUrls: Array<string> = [];
    if (this.model.IsRimless) {
      imageUrls.push(this.imageService.GetFrontImage(this.model, this.model.ModelGroupCode, this.configuration.ModelConfigurationCode,
        this.variants, ImageSize.Medium, Background.Default));
      imageUrls.push(this.imageService.GetSideImage(this.model, this.model.ModelGroupCode, this.configuration.ModelConfigurationCode,
        this.variants, ImageSize.Medium, Background.Default));

    } else {
      imageUrls.push(this.imageService.GetPerspectiveImage(this.model, this.model.ModelGroupCode,
        this.configuration.ModelConfigurationCode, this.variants, ImageSize.Medium, Background.Default));
    }

    const manualConfiguration = ManualConfigCreateModel.CreateFromData(manualconfigGroupId,
      releasedate,
      this.model,
      this.configuration,
      this.modelSize,
      this.variants,
      this.templeInclination,
      favoriteCollection?.Id,
      this.brandModeService.isPrecious,
      imageUrls);

    await this.manualConfigService.createInspirationConfiguration(manualConfiguration);
    await this.updateFavorites(true);
    this.eventsService.showLoader(false);

    const user = this.userService.currentUser$.getValue();
    let message = `${user.Uuid} added a ${this.model.Code} to favorite collection '${favoriteCollection.Name}'`
    if (user) {
      await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', message, 40);
    }
  }

  public async RemoveFavorite(currentFavoriteUuid: string) {

    this.eventsService.showLoader(true);

    await this.manualConfigService.deleteInspirationConfigurationFromUuid(currentFavoriteUuid);
    let favoriteCollectionName = '';
    this.favoriteCollections.filter(f => f.CurrentFavoriteUuid === currentFavoriteUuid).forEach(favoriteCollection => {
      favoriteCollection.CurrentFavoriteUuid = null;
      favoriteCollectionName = favoriteCollection.Name;
    });

    const user = this.userService.currentUser$.getValue();
    let message = `${user.Uuid} removed an item from favorite collection '${favoriteCollectionName}'`;
    if (user) {
      await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', message, 10);
    }

    this.eventsService.showLoader(false);

  }

  public async updateFavorites(reload: boolean = false) {

    await this.manualConfigService.clearManualConfigCacheIfNeeded(ManualConfigGroupConstant.Favorites);

    const variantStrings = (await this.manualConfigService.getVariantStrings(reload)).filter(m => m.ManualConfigCollectionId > 0);
    const currentVariantString = await this.manualConfigService.getVariantString(this.model, this.variants);

    this.favoriteCollections.forEach(favoriteCollection => {

      // console.warn('variantStrings', variantStrings, currentVariantString, 
      // variantStrings.find(v => v.VariantString === currentVariantString 
      //   && v.ManualConfigCollectionId === favoriteCollection.Id));

      favoriteCollection.CurrentFavoriteUuid = variantStrings.find(v => v.VariantString === currentVariantString
        && v.ManualConfigCollectionId === favoriteCollection.Id)?.ManualConfigurationUuid
    });
  }


}