import { Injectable } from '@angular/core';
import { DataTranslationModel } from '@models/data-translation-model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { DataApiService } from './data-api.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';
@Injectable()
export class LanguageService {
    public $onLangChange: Observable<string>;
    private onLangChangeSubscription = new Subject<string>();

    constructor(private translateService: TranslateService,
        private sessionService: SessionService,
        private dataApiService: DataApiService,
        private userService: UserService) {
        translateService.onLangChange.subscribe((x: LangChangeEvent) => {
            this.onLangChangeSubscription.next(x.lang);
        });
        this.$onLangChange = this.onLangChangeSubscription.asObservable();
    }

    public addLanguages(langs: Array<string>) {
        this.translateService.addLangs(langs);
    }

    public setDefaultLang(lang: string) {
        this.translateService.setDefaultLang(lang);
    }

    public async useLanguage(lang: string) {
        await this.translateService.use(lang).toPromise();
        await this.ensureDataTranslations(lang);
        this.sessionService.set("language", lang);
    }

    public getLanguages(): Array<string> {
        return this.translateService.getLangs();
    }

    public get currentLanguage(): string {
        return this.translateService.currentLang;
    }

    public get defaultLanguage(): string {
        return this.translateService.defaultLang;
    }

    public instantWithDefaultLanguage(key: string, interpolateParams?: any): string {
        if (this.defaultLanguage == this.currentLanguage) {
            const translation = this.instant(key, interpolateParams);
            return translation;
        } else {
            const translations = this.translateService.getTranslation(this.defaultLanguage);
            const translatedText: string = this.translateService.getParsedResult(translations, key, interpolateParams);
            return translatedText;
        }
    }

    public instant(key: string | Array<string>, interpolateParams?: any): string {
        return this.translateService.instant(key, interpolateParams);
    }

    public instantTranslateData(t: DataTranslationModel, interpolateParams?: any): string {
        if (t?.TranslationKey) {
            const translation = this.translateService.instant(t.TranslationKey, interpolateParams);
            if (translation && translation !== t.TranslationKey) {
                return translation;
            }
        }

        if (!t.Fallback) {
            return "";
        }

        return this.translateService.instant(t.Fallback, interpolateParams);
    }

    public hasTranslation(translationKey: string) {
        const currentTranslations = this.translateService.translations[this.translateService.currentLang];
        if (!currentTranslations) {
            return false;
        }
        const t = currentTranslations[translationKey];
        return t !== undefined;
    }

    public get(key: string | Array<string>, interpolateParams?: any) {
        return this.translateService.get(key, interpolateParams);
    }

    public async setSessionOrBrowserLanguage() {
        const sessionLanguage = this.sessionService.get<string>("language");
        if (sessionLanguage) {
            await this.translateService.use(sessionLanguage).toPromise();
            await this.ensureDataTranslations(sessionLanguage);
        } else {
            const browserLang = this.translateService.getBrowserLang();
            if (this.translateService.getLangs().some(k => k == browserLang)) {
                await this.useLanguage(browserLang);
            } else {
                await this.ensureDataTranslations(this.defaultLanguage);
                await this.useLanguage(this.defaultLanguage);
            }
        }
    }

    private async ensureDataTranslations(lang: string) {
        const user = await this.userService.GetUser();
        if (user) {
            const dataTranslations = await this.dataApiService.getDataTranslations(lang);
            this.translateService.setTranslation(lang, dataTranslations, true);
        }
    }
}
