import { Type } from "class-transformer";
import { SorterService } from '../_services/sorter.service';
import IDisableable from '../infrastructure/disableable.interface';
import { AssortmentLineModel } from './assortment-line-model';
import { VariantColorModel } from './variant-color';
import { VariantColorFromApiModel } from './variant-color-from-api';
import { VariantSizeModel } from "./variant-size";
import { CaseAlternativeGroupLineModel } from "./api-models/case-alternative-groupline";

export class VariantSizeFromApiModel extends VariantSizeModel implements IDisableable {
    @Type(() => VariantColorFromApiModel)
    VariantColors: Array<VariantColorFromApiModel>;

    @Type(() => CaseAlternativeGroupLineModel)
    Cases: Array<CaseAlternativeGroupLineModel>;

    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _isDisabled: boolean;

    public isDisabledByVariants = new Array<{ component: string; variant: string; size: string; color: string; }>();
    public set isDisabled(value: boolean) {
        this._isDisabled = value;
        this.VariantColors?.forEach(x => x.isDisabled = value);
    }

    public get isDisabled() {
        return this._isDisabled;
    }

    public FindColorReplacement(colorModel: VariantColorModel, sorter: SorterService) {
        if(this.VariantColors.length == 0) {
            throw new Error("Cannot find any color replacement because there are no colors");
        }

        let color = this.VariantColors.find(x => x.Color.Code == colorModel.Color.Code);
        if (!color) {
            color = this.VariantColors.sort(sorter.sortBy(VariantColorModel))[0];
        }

        return color;
    }

    public ToVariantSizeModel(): VariantSizeModel {
        return Object.assign(new VariantSizeModel(this.Discontinued, this.New), this);
    }

    clearDisabled() {
        this.isDisabled = false;
        this.isDisabledByVariants = [];
        this.VariantColors.forEach(x => {
            x.clearDisabled();
        });
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static createCopy(fromModel: VariantSizeFromApiModel): VariantSizeFromApiModel {
        const newObj = new VariantSizeFromApiModel(fromModel.Discontinued ? new Date(fromModel.Discontinued) : null, fromModel.New);
        newObj.isDisabled = fromModel.isDisabled;
        newObj.Id = fromModel.Id;
        newObj.Uuid = fromModel.Uuid;
        newObj.Size = fromModel.Size;
        newObj.Bypass = fromModel.Bypass;
        newObj.Description = fromModel.Description;
        newObj.Release = fromModel.Release ? new Date(fromModel.Release) : null;
        newObj.Order = fromModel.Order;
        newObj.Status = fromModel.Status;

        newObj.AssortmentLines = fromModel.AssortmentLines?.map(x => AssortmentLineModel.createCopy(x));
        newObj.VariantColors = fromModel.VariantColors?.map(x => VariantColorFromApiModel.createCopy(x));
        newObj.Cases = fromModel.Cases?.map(x => CaseAlternativeGroupLineModel.createCopy(x));

        return newObj;
    }
}
