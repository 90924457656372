import { Component, SimpleChanges } from '@angular/core';

import { BrandModeService } from '@services/brandmode.service';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { VariantColorModel } from 'src/_models/variant-color';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from '../../../../shared/base-customizer-component';

@Component({
	template: `
    <div class="gemstone">
      <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
        
        <div *ngIf="!selectableVariants.length" class="has-padding-horizontal-30px">
        {{'CUSTOMIZER.COMPONENTS.GEMSTONE.NO.COLORS' | translate}}
        </div>

          <customizer-colors-auto-update *ngIf="selectableVariants.length"
              [selected]="getSelectedVariant.VariantColor"
              [selectedColorGroup]="currentColorGroup"
              [variants]="selectableVariants"
              [editMode]="state === customizerStateEnum.EDITORDER"
              [matchableColors]="matchableColors"
              [useColorGroup]="menu.contentGrouping"
              (colorMatchChange)="matchColors($event)"
              (selectedColorGroupChange)="onSelectGroup($event)"
              (selectedColorChange)="onSelect($event)"
              [(scrollPosition)]="menu.menuDetails.scrollPosition"
              [extendedInformation]="extendedInformation"
              [extendedInformationTable]="false"
              [variantOrModelIsNew]="variantOrModelIsNew"
              [matchColor]="menu.matchColor"
              [showNoneChoiceOption]="false"
          >
          </customizer-colors-auto-update>
      </customizer-component-header>
      </div>
    `
})
export class GemstoneColorComponent extends BaseCustomizerComponent {

	public selectableVariants: Array<ComponentVariantFromApiModel> = [];
	public extendedInformation: boolean = false;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private brandmodeService: BrandModeService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
		this.extendedInformation = this.brandmodeService.isPrecious;
	}

	async initData() {
		this.events.showLoader(true);

		this.selectableVariants = await this.fetchData();

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
		this.setImageSliderByName(AngleConstants.Perspective);
	}

	async fetchData(): Promise<Array<ComponentVariantFromApiModel>> {
		const variant = this.getSelectedVariant;
		variant.VariantColorViewed = true;
		console.warn('variant', variant);

		let apidata = [];
		if (variant.VariantSize) {
			apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid, variant.VariantSize.Uuid);
		}

		await this.RunDisablingSystem(apidata);
		return apidata;
	}

	onSelect(color: VariantColorModel) {
		this.events.showLoader(true);

		const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		newVariant.VariantColor = color;
		newVariant.NotSelected = false;
		this.updateVariant(new ComponentVariantChangeModel(oldVariant, newVariant, null));
	}

}
