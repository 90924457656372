import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'ui-button',
    template: `
        <button
            class="btn"
            [type]="buttonType"
            [disabled]="disabled"
            [ngClass]="['btn-' + buttonStyle, size ? 'btn-' + size : '']"
            [class.btn-block]="block"
            [class.btn-short]="short"
            [class.toggled]="active"
        >
            <ng-content></ng-content>
        </button>
    `,
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
    @Input() block: boolean;
    @Input() short: boolean;
    @Input() disabled: boolean;
    @Input() active: boolean;
    @Input() buttonStyle: 'default'|'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark'|'cta' = 'secondary';
    @Input() buttonType: 'button'|'submit'|'reset' = 'button';
    @Input() size: 'xs'|'sm'|'lg'|null = null;
}
