import { Component } from '@angular/core';

@Component({
	selector: 'favorite-collection-save-overlay',
	templateUrl: './favorite-collection-save-overlay.component.html',
	styleUrls: ['./favorite-collection-save-overlay.component.scss']
})
export class FavoriteCollectionSaveOverlayComponent {

	constructor() { }

}
