<customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)" class="inline-scroll">
    <div class="inline-scroll">
        <div class="order-overview has-padding-horizontal-30px">
            <div class="reference-input customizer-input">
                <ng-container *ngIf="showOverlay">
                    <menu-overlay
                        [mainOverlayText]="null"
                        [secondOverlayText]="'CUSTOMIZER.CHANGES.BAGUPDATED' | translate"
                        (overlayClicked)="null"
                    >
                    </menu-overlay>
                </ng-container>
                <input class="form-control"
                    type="text"
                    [placeholder]="translateService.instant('CUSTOMIZER.COMPONENTS.REFERENCE.ORDERREF')"
                    [maxlength]="maxlength"
                    [formControl]="referenceControl"/>
                <textarea class="form-control"
                    [placeholder]="translateService.instant('CUSTOMIZER.COMPONENTS.REFERENCE.OTHER')"
                    [formControl]="commentControl"
                    rows="4"
                    oninput='this.style.height = "";this.style.height = this.scrollHeight +1 + "px"'>
                </textarea>
            </div>
        </div>
        <div class="order-overview has-padding-horizontal-30px">
            <customizer-forms-fixtures *ngIf="variants.length > 1 && modelGroup.FullFrame && (modelGroup.HasFixtures || modelGroup.HasForms)"
                [hasFixtures]="hasFixtures"
                [hasForms]="hasForms"
                [modelSize]="modelSize"
                [modelGroup]="modelGroup"
                [model]="model"
                (modelSizeChange)="updateSize($event)"
                (hasFixturesChange)="updateHasFixtures($event)"
                (hasFormsChange)="updateHasForms($event)"
            >
            </customizer-forms-fixtures>
        </div>
        <div class="reference-discounts"
            *ngIf="(onBehalfOfService.customerAsObservable() | async)?.isConsultantOrderOnBehalf"
        >
            <div class="order-overview row has-padding-horizontal-30px">
                <customizer-discounts
                    [elemClass]="['col-xs-7']"
                    [discount]="discount"
                    [discountErrors]="discountErrors"
                    [discountsList]="discountsList"
                    [widths]="['col-xs-12']"
                    (discountChange)="updateDiscount($event)"
                ></customizer-discounts>
            </div>
            <div class="order-overview row has-padding-horizontal-30px">
                <error-summary class="col-xs-12"
                    [errors]="discountErrors"
                ></error-summary>
            </div>
        </div>
        <div
            class="order-overview has-padding-horizontal-30px"
            *ngIf="(enduserService.enduserDisplayChangedObservable() | async) === clientConfigurationMode"
        >
            <app-customizer-statistics
                [age]="age"
                [gender]="gender"
                [firstPair]="firstPair"
                [showMandatoryMessage]="showMandatoryMessage"
                (ageChange)="updateAge($event)"
                (firstPairChange)="updateFirstPair($event)"
                (genderChange)="updateGender($event)"
            ></app-customizer-statistics>
        </div>
    </div>
</customizer-component-header>


