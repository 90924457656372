import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MiscGroup } from '@app/home/home';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { CustomiserNotification, Link, NotificationState, NotificationType } from '@models/customiser-notification-model';
import { CustomizerParams } from '@models/customizer-params';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import { AppState } from '@shared/app-state';
import { UrlConstants } from '@shared/url-constants';
import { UserTypeConstant } from '@shared/user-type-constants';
import { plainToClass } from 'class-transformer';
import { Guid } from 'guid-typescript';
import { ModelModel } from 'src/_models/api-models/model';
import { ConfigurationModel } from 'src/_models/configuration';
import { InspirationGroupModel } from 'src/_models/inspiration-group';
import { ModelGroupModel } from 'src/_models/model-group';
import { ModelSizeModel } from 'src/_models/model-size';
import { MiscModelCategory } from '../_models/misc-model-category';
import { MiscModelGroup } from '../_models/misc-model-group';
import { OrderItemModel } from '../_models/order-item';
import { ComponentVariantModel } from './../_models/component-variant';
import { AppConstants } from './../app.constants';
import { BrandModeService } from './brandmode.service';
import { BreadcrumbService } from './breadcrumb.service';
import { CustomizerService } from './customizer.service';
import { DataApiService } from './data-api.service';
import { EnduserDisplayService } from './enduserdisplay.service';
import { EnvService } from './env.service';
import { ExternalSearchService } from './external-search.service';
import { IndexDbService } from './index-db.service';
import { LanguageService } from './language.service';
import { LoginService } from './login.service';
import { NotificationService } from './notification.service';
import { OrderOnBehalfService } from './order-on-behalf.service';
import { SessionService } from './session.service';
import { SignalRService } from './signal-r.service';
import { StoreService } from './store.service';
import { UserService } from './user.service';
import { ServiceUtils } from './utils/service.utils';
import { ModelsService } from './v2/models.service';

@Injectable()
export class NavigateService {

	private lastVersionCheck: number = Date.now();
	private timeToWaitUntilNextCheck: number = 10 * 60 * 1000; // 10 minutes in miliseconds
	private unfinishedOrdersCheckInterval: number = 3 * 24 * 60 * 60 * 1000; // 3 days in miliseconds
	private lastUnfinishedOrdersCheck: number = Date.now();
	private lastUnfinishedOrdersCheckKey = 'LastUnfinishedOrdersCheck';
	public baseUrl: () => Promise<string> = async () => await this.env.DataApiurl();

	constructor(private router: Router,
		private serviceUtils: ServiceUtils,
		private activatedRoute: ActivatedRoute,
		private sessionService: SessionService,
		private dataApiService: DataApiService,
		private indexDbService: IndexDbService,
		private userService: UserService,
		private enduserDisplayService: EnduserDisplayService,
		private translateService: LanguageService,
		private location: Location,
		private externalSearchService: ExternalSearchService,
		private customizerService: CustomizerService,
		private orderOnBehalfService: OrderOnBehalfService,
		private breadcrumbService: BreadcrumbService,
		private loginService: LoginService,
		private notificationService: NotificationService,
		private signalRService: SignalRService,
		private env: EnvService,
		private appState: AppState,
		private brandModeService: BrandModeService,
		private modelsService: ModelsService,
	) { }

	public async navigate(commands: Array<string>, extras?: NavigationExtras, parts?: boolean) {
		const currentUser = await this.userService.GetUser();

		if (currentUser.IsLiteLogin) {
			this.loginService.liteLogout();
			this.loginService.Logout();
			window.location.href = '/login';
		}

		let qp = null;

		if (commands && commands.length > 0 && commands[commands.length - 1]?.includes('?')) {
			let lastCommand = commands[commands.length - 1];
			const q = lastCommand.substring(lastCommand.indexOf('?'));
			const p = q.substring(q.indexOf('=') + 1)
			qp = { queryParams: { p: p } }
			commands[commands.length - 1] = lastCommand.substring(0, lastCommand.indexOf('?'));
		}

		if (!qp && parts) {
			qp = { queryParams: { p: parts } }
		}

		if (currentUser.LastVersionCheck) {
			this.lastVersionCheck = currentUser.LastVersionCheck;
		}

		if (this.sessionService.get(this.lastUnfinishedOrdersCheckKey)) {
			this.lastUnfinishedOrdersCheck = this.sessionService.get(this.lastUnfinishedOrdersCheckKey);
		}
		else {
			this.sessionService.set(this.lastUnfinishedOrdersCheckKey, Date.now());
		}

		if (!currentUser.IsLiteLogin && (this.lastUnfinishedOrdersCheck + this.unfinishedOrdersCheckInterval) < Date.now()) {
			await this.triggerUnfinishedOrdersNotification();
		}

		if ((this.lastVersionCheck + this.timeToWaitUntilNextCheck) < Date.now()) {
			const versionsFromApi = await this.dataApiService.getVersions();

			console.debug('Checking if IndexedDb is old, expire various local data from indexedDb (on navigation)');

			const wasAnyExpired = await this.indexDbService.checkDatabaseValidityVersion(versionsFromApi);

			if (commands[0] === undefined)
				commands[0] = '/';
			if (qp) {
				await this.router.navigate(commands, qp);
			}
			else if (extras) {
				await this.router.navigate(commands, extras);
			}
			else {
				await this.router.navigate(commands);
			}

			if (wasAnyExpired) {
				window.location.reload();
			}
			else {
				try {
					this.refreshUserFromNetwork();
					this.expireNewCustomers();
				}
				catch (error) {
					if (this.serviceUtils.errorIsOffline(error)) {
						//Ignore and wait on user update
					}
					else {
						throw error;
					}
				}

				this.lastVersionCheck = Date.now();
			}
		}
		else {
			if (commands[0] === undefined)
				commands[0] = '/';
			if (qp) {
				await this.router.navigate(commands, qp);
			}
			else if (extras) {
				await this.router.navigate(commands, extras);
			}
			else {
				await this.router.navigate(commands);
			}
		}
	}

	private async refreshUserFromNetwork() {
		const userRefreshData = await this.userService.getUserDataFromLindberg();

		this.appState.priceModuleEnabled.next(userRefreshData.User.priceModuleEnabled && userRefreshData.User.PriceSettingAllowed !== PriceSettingEnum.AllowNone)

		this.loginService.createLoginCookie(userRefreshData.token);

		if (userRefreshData.User.Active) {
			userRefreshData.User.LastVersionCheck = Date.now();
			this.userService.setUser(userRefreshData.User);

			await this.signalRService.WarnAboutOtherConnectedClientsForCurrentUser();
		}
		else { // FileMaker says user is no longer active => Log user out
			this.loginService.Logout(true);
		}

		await this.env.NewerVersionExist();
	}

	private async expireNewCustomers() {
		this.indexDbService.clear(StoreService.storeName, `${await this.baseUrl()}/Account/NewCustomers`)
	}

	private async triggerUnfinishedOrdersNotification() {
		const freshUnfinishedOrders = await this.dataApiService.getUnfinishedOrders(true);
		const currentUser = await this.userService.GetUser();
		const unfinishedOrdersCount = freshUnfinishedOrders.length;

		if (unfinishedOrdersCount > 0) {
			const customiserNotification = new CustomiserNotification(
				Guid.create().toString(),
				currentUser.Uuid,
				this.translateService.instant('NOTIFICATIONS.HEADER.UNFINISHED.ORDERS'),
				(unfinishedOrdersCount === 1 ? this.translateService.instant('NOTIFICATIONS.BODY.UNFINISHED.ORDER').replace('{count}', unfinishedOrdersCount.toString()) : this.translateService.instant('NOTIFICATIONS.BODY.UNFINISHED.ORDERS').replace('{count}', unfinishedOrdersCount.toString())) +
				' ' + this.translateService.instant('NOTIFICATIONS.BODY.CLICK.TO.VIEW'),
				NotificationState.New,
				NotificationType.UnfinishedOrders
			);

			customiserNotification.Link = new Link('/bag/order-info', this.translateService.instant('NOTIFICATIONS.LINKTEXT.VIEW.ORDERS'));

			await this.notificationService.saveNotification(customiserNotification);
		}

		this.lastUnfinishedOrdersCheck = Date.now();
		this.sessionService.set(this.lastUnfinishedOrdersCheckKey, this.lastUnfinishedOrdersCheck);
	}

	private set miscCategory(category: MiscModelCategory) {
		this.sessionService.set('miscCategory', category);
	}

	public getMiscCategory(): MiscModelCategory {
		let miscCategory = this.sessionService.get<MiscModelCategory>('miscCategory');

		if (!miscCategory) {
			const root: ActivatedRoute = this.activatedRoute.root;
			const miscModelCategory = this.breadcrumbService.getParameterFromBreadcrumb(root, UrlConstants.CATEGORYCODE);
			miscCategory = { Category: miscModelCategory, CategoryForDisplay: miscModelCategory, groups: [] };
			this.miscCategory = miscCategory;
		}

		return miscCategory ? plainToClass(MiscModelCategory, miscCategory) : null;
	}

	private set miscGroup(group: MiscModelGroup) {
		this.sessionService.set('miscGroup', group);
	}

	public getMiscGroup(): MiscModelGroup {
		let miscGroup = this.sessionService.get<MiscModelGroup>('miscGroup');

		if (!miscGroup) {
			const root: ActivatedRoute = this.activatedRoute.root;
			const miscModelGroup = this.breadcrumbService.getParameterFromBreadcrumb(root, UrlConstants.GROUPCODE);
			miscGroup = { Group: miscModelGroup, GroupForDisplay: miscModelGroup }
			this.miscGroup = miscGroup;
		}

		return miscGroup ? plainToClass(MiscModelGroup, miscGroup) : null;
	}

	private set modelGroupData(modelGroup: ModelGroupModel) {
		this.sessionService.set('modelGroup', modelGroup);
	}

	public async setModelGroupData(modelGroup: ModelGroupModel) {
		this.modelGroupData = modelGroup;
	}

	public async getModelGroupData(): Promise<ModelGroupModel> {
		let modelgroup = this.sessionService.get<ModelGroupModel>('modelGroup');

		if (!modelgroup) {
			const root: ActivatedRoute = this.activatedRoute.root;
			const modelgroupCodeFromUrl = this.breadcrumbService.getModelgroupFromBreadcrumb(root);
			const modelgroupNameUrl = this.breadcrumbService.getModelgroupName(root);

			modelgroup = await this.modelsService.getModelGroupFromName(modelgroupCodeFromUrl);

			if (modelgroupNameUrl && modelgroupNameUrl.length > 0) {
				modelgroup = modelgroup.ModelGroupChildren.find(x => x.Description.Fallback == modelgroupNameUrl);
			}
		}

		return modelgroup ? plainToClass(ModelGroupModel, modelgroup) : null;
	}

	private set inspirationGroupData(inspirationGroup: InspirationGroupModel) {
		this.sessionService.set('inspirationGroup', inspirationGroup);
	}

	public getInspirationGroupData(): InspirationGroupModel {
		const model = this.sessionService.get<InspirationGroupModel>('inspirationGroup');
		return model ? plainToClass(InspirationGroupModel, model) : null;
	}

	private set searchTerm(term: string) {
		this.sessionService.set('searchTerm', term);
	}

	public getSearchTerm(): string {
		const searchTerm = this.sessionService.get<string>('searchTerm');
		return searchTerm;
	}

	public async navigateToModelList(modelGroup: ModelGroupModel, parentModelGroup?: ModelGroupModel, partsMode?: boolean) {
		const user = await this.userService.GetUser();
		this.modelGroupData = modelGroup;
		const modelGroupName = modelGroup.ShortDescription.Fallback || modelGroup.Description.Fallback;

		if (parentModelGroup) {
			await this.navigate([this.brandModeService.currentBrandStringValue(), 'group', parentModelGroup.ShortDescription.Fallback || parentModelGroup.Description.Fallback, modelGroupName, 'models']);
		}
		else if (modelGroup.KidTeen) {
			await this.navigate(['kid-teen', 'group', modelGroupName, 'models']);
		}
		else if (modelGroup.IsRimless) {
			const canCustomiseProduct = user.hasAllProductlines([modelGroup.ProductLineCode]);

			if (!canCustomiseProduct) {
				await this.navigate([this.brandModeService.currentBrandStringValue(), 'group', modelGroupName, 'models']);
			}
			else {
				if (partsMode) {
					await this.navigateToCustomiserParts(modelGroup);
				}
				else {
					await this.navigateToCustomizer(modelGroup);
				}
			}
		}
		else if (modelGroup.ModelGroupChildren && modelGroup.ModelGroupChildren.length) {
			await this.navigate([this.brandModeService.currentBrandStringValue(), 'group', modelGroupName]);
		}
		else {
			if (partsMode) {
				await this.navigate([this.brandModeService.currentBrandStringValue(), 'group', modelGroupName, 'models?p=' + partsMode]);
			}
			else {
				await this.navigate([this.brandModeService.currentBrandStringValue(), 'group', modelGroupName, 'models']);
			}
		}
	}

	public async navigateToFeatured(inspirationGroup: InspirationGroupModel) {
		this.inspirationGroupData = inspirationGroup;
		await this.navigate(['featured', this.translateService.instant(inspirationGroup.Name)]);
	}

	public async navigateToKidTeen() {
		await this.navigate(['kid-teen']);
	}

	public async navigateToBag() {
		await this.navigate(['bag']);
	}

	public async navigateToLogin() {
		await this.navigate(['login']);
	}

	public navigateToTechnicalInfo() {
		window.open(AppConstants.technicalInfoUrl, '_blank');
	}

	public async navigateToHome(zone?: NgZone) {
		if (zone) {
			await zone.run(async () => { await this.navigate(['/']); });
		}
		else {
			await this.navigate(['/']);
		}
	}

	public async navigateToHomeWithSearchModal(zone?: NgZone) {
		await this.navigateToHome(zone);
		const savedSearchTerm = this.getSearchTerm();

		if (savedSearchTerm) {
			this.externalSearchService.search(savedSearchTerm);
		}
	}

	public async navigateToMiscCategory(miscModelCategory: MiscModelCategory) {
		this.miscCategory = miscModelCategory;
		await this.navigate(['accessories', miscModelCategory.CategoryForDisplay]);
	}

	public async navigateToParts() {
		await this.navigate(['parts']);
	}

	public async navigateToKeringEyewareBrands() {
		await this.navigate(['kering-eyeware-brands']);
	}

	public async navigateToLimited() {
		await this.navigate(['limited']);
	}

	public async navigateToCollectors() {
		await this.navigate(['collectors']);
	}

	public async navigateToUnika() {
		await this.navigate(['unika']);
	}

	public async navigateToMiscGroup(miscModelCategory: MiscModelCategory, miscModelGroup: MiscModelGroup) {
		this.miscCategory = miscModelCategory;
		this.miscGroup = miscModelGroup;
		await this.navigate(['accessories', miscModelCategory.CategoryForDisplay, 'group', miscModelGroup.GroupForDisplay]);
	}

	public async navigateToMiscGroup2(group: MiscGroup) {
		if (group.special) {
			if (group.link?.startsWith('http')) {
				window.open(group.link, '_blank');
			}
			else {
				await this.navigate([group.link]);
			}
		}
		else {
			await this.navigate(['tools', group.category.name.toLowerCase(), 'group', group.name]);
		}
	}

	public async navigateToMiscGroupByName(miscModelCategory: string, miscModelGroup: string) {
		this.miscCategory = { Category: miscModelCategory, CategoryForDisplay: miscModelCategory, groups: [] };
		this.miscGroup = { Group: miscModelGroup, GroupForDisplay: miscModelGroup };
		await this.navigate(['accessories', miscModelCategory, 'group', miscModelGroup]);
	}

	public async navigateToSpareParts() {
		await this.navigate(['tools', 'parts', 'group', 'parts']);
	}

	public async navigateToCustomiserParts(modelGroup: ModelGroupModel) {
		await this.navigateToCustomizer(modelGroup, null, null, null, null, null, null, null, true);
	}

	public async navigateToCustomizer(
		modelGroup: ModelGroupModel,
		model?: ModelModel,
		modelsize?: ModelSizeModel,
		templeInclination?: TempleInclinationEnum,
		configuration?: ConfigurationModel,
		orderItem?: OrderItemModel,
		allVariants?: Array<ComponentVariantModel>,
		customizerParams?: CustomizerParams,
		parts?: boolean
	): Promise<void> {
		this.customizerService.setCustomizerRouteParams(modelGroup, model, modelsize, templeInclination, orderItem, allVariants, configuration?.ModelConfigurationCode, customizerParams);

		// necessary because of 'HasModelGroupDataGuard'. This navigation method is called directly when entering
		// from a search result (not a manual configuration).
		this.modelGroupData = modelGroup;

		const modelGroupName = modelGroup.ShortDescription.Fallback || modelGroup.Description.Fallback;

		if (orderItem) {
			this.enduserDisplayService.setConfigurationMode(orderItem.Demo, false);
		}

		let options: Array<string> = [];

		if (modelGroup.ParentModelGroupId) {
			options = [this.brandModeService.currentBrandStringValue(), 'group', modelGroup.ParentModelGroupShortDescription.Fallback || modelGroup.ParentModelGroupDescription.Fallback, modelGroupName, 'models', model.Code];
		}
		else if (modelGroup.KidTeen) {
			options = ['kid-teen', 'group', modelGroupName, 'models', model.Code];
		}
		else if (modelGroup.IsRimless) {
			options = model ? [this.brandModeService.currentBrandStringValue(), 'group', modelGroupName, 'rimless', model.Code] : [this.brandModeService.currentBrandStringValue(), 'group', modelGroupName, 'rimless'];
		}
		else {
			options = [this.brandModeService.currentBrandStringValue(), 'group', modelGroupName, 'models', model.Code];
		}

		await this.navigate(options, null, parts);
	}

	public async navigateToCondensedCustomizer(modelGroup: ModelGroupModel,
		model: ModelModel,
		modelsize?: ModelSizeModel,
		templeInclination?: TempleInclinationEnum,
		configuration?: ConfigurationModel,
		orderItem?: OrderItemModel,
		searchTerm?: string,
		inspirationGroup?: InspirationGroupModel,
		customizerParams?: CustomizerParams) {
		this.customizerService.setCustomizerRouteParams(modelGroup, model, modelsize, templeInclination, orderItem, null, configuration?.ModelConfigurationCode, customizerParams);
		this.searchTerm = searchTerm;

		this.inspirationGroupData = inspirationGroup;

		if (orderItem) {
			this.enduserDisplayService.setConfigurationMode(orderItem.Demo);
		}

		await this.navigate(['searchresults', modelGroup.Code]);
	}

	public async UpdateUrl(oldModelCode: string, newModelCode: string): Promise<void> {
		const url = this.location.path().replace(oldModelCode, newModelCode);
		this.location.go(url);
	}

	public async canNavigateToCustomizer() {
		const modelgroup = await this.getModelGroupData();
		const user = await this.userService.GetUser();
		const customer = this.orderOnBehalfService.customerSnapshot();

		if (!modelgroup) {
			return false;
		}

		let canCustomiseProduct: boolean

		if (user.Type === UserTypeConstant.Customer && customer) {
			canCustomiseProduct = customer.hasProductLineAccess(modelgroup.ProductLineCode);
			return canCustomiseProduct;
		}

		canCustomiseProduct = user.hasAllProductlines([modelgroup.ProductLineCode]);

		return canCustomiseProduct;
	}

	public async canNavigateToCustomizerWithModelgroup(modelgroup: ModelGroupModel) {
		const user = await this.userService.GetUser();

		if (!modelgroup) {
			return false;
		}

		const canCustomiseProduct = user.hasAllProductlines([modelgroup.ProductLineCode]);

		return canCustomiseProduct;
	}

}
