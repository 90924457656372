export class OfflinePage
{
    headline: string;
    text: string;
    imageUrls: Array<string>
    constructor(headline: string, text: string, imageUrls: Array<string>) {
        this.headline = headline;
        this.text = text;
        this.imageUrls = imageUrls;
    }
}