import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ColorModel } from '@models/color';
import { VariantColorModel } from '@models/variant-color';
import { BrandModeService } from '@services/brandmode.service';

@Component({
	selector: 'app-combined-color-name',
	templateUrl: './combined-color-name.component.html',
	styleUrls: ['./combined-color-name.component.scss']
})
export class CombinedColorNameComponent implements OnChanges {

	@Input() color: ColorModel;
	@Input() variantColor: VariantColorModel;
	@Input() forceDescriptionOnly: boolean = false;

	public showCombined: boolean;
	public isPrecious: boolean = false;

	constructor(
		private brandModeService: BrandModeService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.isPrecious = this.brandModeService.isPrecious;

		if (!this.forceDescriptionOnly) {
			this.showCombined = changes.color.currentValue.Code.toLowerCase() != changes.color.currentValue.Description.Fallback.toLowerCase();
		}
	}

}
