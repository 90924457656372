<notification [notifier]="enduserDisplayClickedObserveable" [modal]="displayEnduserModalComponent">
    <div text>
        <span class="font-medium">{{'HOME.ENDUSERDISPLAY.READMORE1' | translate}} </span>
        <span>{{'HOME.ENDUSERDISPLAY.READMORE2' | translate}}</span>
    </div>
</notification>
<div class="misc-items dark" [@fadeInOutTransition]>
    <page-header class="page-header">{{group}}</page-header>
    <div class="misc-items-container">
        <skeleton-screen [hasLoaded]="hasModelsLoaded" [screens]=8 screenClass="misc-item misc-group">
            <ng-container *ngFor="let miscModel of miscModels">
                <misc-list-item class="misc-item"
                    [model]="miscModel">
                </misc-list-item>
            </ng-container>
        </skeleton-screen>
    </div>
    <div>
        <copyright></copyright>
    </div>
</div>