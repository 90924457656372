
import { Component, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { VariantColorModel } from 'src/_models/variant-color';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { SorterService } from '../../../../_services/sorter.service';
import { ComponentVariantsGrouped } from '../components-variants-grouped';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'lens-color',
	styleUrls: ['./lens-color.scss'],
	template: `
    <div *ngIf="lensGroups.length" class="inline-scroll">
        <ng-container *ngFor="let lensGroup of lensGroups">
            <customizer-component-header
                                        *ngIf="lensGroup"
                                        [menu]="menu"
                                        [showMenuDescription]="false"
                                        [overrideTranslateName]="lensGroup.Design"
                                        [showTranslateDecription]="false"
                                        (updateCollapsedOnComponent)="updateCollapsed($event)">
                <customizer-colors-auto-update
                    [selected]="getSelectedVariant.VariantColor"
                    [selectedColorGroup]="currentColorGroup"
                    [variants]="lensGroup.ComponentVariants"
                    [extendedInformation]="true"
                    [specialColorText]="'CUSTOMIZER.COMPONENTS.LENSES.SPECIAL'"
                    [imageClass]="'lens'"
                    [editMode]="state === customizerStateEnum.EDITORDER"
                    [matchableColors]="matchableColors"
                    [useColorGroup]="lensGroup.ContentGrouping"
                    (colorMatchChange)="matchColors($event)"
                    (selectedColorGroupChange)="onSelectGroup($event)"
                    (selectedColorChange)="onSelect($event)"
                    [variantOrModelIsNew]="variantOrModelIsNew"
                    [matchColor]="menu.matchColor">
                </customizer-colors-auto-update>
            </customizer-component-header>
            <precious-metal-info></precious-metal-info>
        </ng-container>
    </div>
    `
})
export class LensColorComponent extends BaseCustomizerComponent {

	public lensGroups: Array<ComponentVariantsGrouped> = [];

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		public sorter: SorterService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		await this.fetchData();

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	async fetchData() {
		const variant = this.getSelectedVariant;
		variant.VariantColorViewed = true;

		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, variant.Component.Uuid, null, null);
		await this.RunDisablingSystem(apidata);

		const groupedLenses: { [key: string]: ComponentVariantFromApiModel[]; } = apidata.reduce(function (r, a) {
			r[a.Group.Fallback] = r[a.Group.Fallback] || [];
			r[a.Group.Fallback].push(a);
			return r;
		}, Object.create(null));

		let lensGroupsEntries = Object.entries(groupedLenses).sort(this.sortGroupName);
		const lenses = lensGroupsEntries.map(data => {
			const allColors = data[1]?.map(d => d.VariantSizes?.map(v => v.VariantColors))[0][0];
			let contentGrouping = this.menu.contentGrouping && allColors?.length > 1
			return new ComponentVariantsGrouped(data[0], data[1], contentGrouping);
		})

		this.lensGroups = lenses;

		this.matchableColors = await this.getMatchableColors();
	}

	sortGroupName(a, b) {
		if (a[0] === b[0]) {
			return 0;
		}
		else {
			return (a[0] < b[0]) ? -1 : 1;
		}
	}

	onSelect(color: VariantColorModel) {
		this.events.showLoader(true);

		const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const componentVariantsMap = this.lensGroups.map(l => l.ComponentVariants);

		const selectableVariants: Array<ComponentVariantFromApiModel> = [];
		componentVariantsMap.forEach(componentVariants => {
			selectableVariants.push(...componentVariants);
		});

		let selectedVariant = selectableVariants.find(x => x.VariantSizes.map(y => y.VariantColors)
			.reduce((prev, next) => prev.concat(next))
			.some(y => y.Color.Code == color.Color.Code));


		const newVariant = selectedVariant.ToComponentVariantModel(oldVariant.Engraving);
		newVariant.VariantColor = color;
		newVariant.VariantSize = selectedVariant.VariantSizes.find(x => x.Size == oldVariant.VariantSize.Size);
		newVariant.VariantColorViewed = this.getSelectedVariant.VariantColorViewed;
		newVariant.VariantViewed = true;
		this.updateVariant(new ComponentVariantChangeModel(oldVariant, newVariant, null));
	}

}
