import { Type } from 'class-transformer';

export class SimpleModelModel {
    Id: number;
    Uuid: string;
    Code: string;

    @Type(() => Date)
    Discontinued?: Date;
    @Type(() => Date)
    ModelGroupDiscontinued?: Date;
    @Type(() => Date)
    Release: Date;
    @Type(() => Date)
    ModelGroupRelease: Date;

    Order: string;
    IsKid: boolean;
    IsRimless: boolean;
    ModelGroupId: number;
    ModelGroupCode: string;
    ModelGroupDescription: string;
}
