import { DataTranslationModel } from '@models/data-translation-model';
import { AvailabilityModel } from '../availability-model';

export class ProductlineOrderApimodel extends AvailabilityModel {
    public HasForms: boolean;
    public HasFixtures: boolean;
    public LineStatusMandatory: boolean;
    public MinimumInitialPurchase: number;
    public Code: string;
    public Description: DataTranslationModel;
}
