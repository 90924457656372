import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { EventsService } from 'src/_services/events.service';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { VariantSizeModel } from '../../../../_models/variant-size';
import { DataApiService } from '../../../../_services/data-api.service';
import { ImageService } from '../../../../_services/image.service';
import { SorterService } from '../../../../_services/sorter.service';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'temple-clip-length',
	styleUrls: ['./temple-clip-length.scss'],
	template: `
        <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
            <div *ngIf="sizes.length">
                <div class="form-group has-padding-horizontal-30px flex" *ngIf="getSelectedVariant.SplittingAllowed && sizes.length > 1">
                    <small>{{"CUSTOMIZER.COMPONENTS.CLIPLENGTH.DIFFERENTLENGTHS" | translate}}</small>
                    <div class="flex-grow">
                        <ui-switch [checked]="getSelectedVariant.SplittingData !== null && getSelectedVariant.SplittingData !== undefined" (change)="toggle($event)" size='small' class="pull-right"></ui-switch>
                    </div>
                </div>
                <customizer-list
                    class="bordered"
                    *ngIf="!getSelectedVariant.SplittingData"
                    [selectedIndex]="selectedIndex"
                    (selectedChange)="onSelect($event)"
                    [items]="sizes"
                    [name]="'Size'"
                    [discontinued]="'Discontinued'"
                    [editMode]="state === customizerStateEnum.EDITORDER"
                    [variantOrModelIsNew]="variantOrModelIsNew"
                    [image]="getVoid"
                ></customizer-list>
                <div *ngIf="getSelectedVariant.SplittingData" class="different-lengths">
                    <div>
                        <span class="side">{{ "COMMON.LEFT" | translate }}</span>
                        <customizer-list class="bordered"
                                        [selectedIndex]="selectedIndex"
                                        (selectedChange)="setSize('left', $event)"
                                        [items]="sizes"
                                        [name]="'Size'"
                                        [discontinued]="'Discontinued'"
                                        [editMode]="state === customizerStateEnum.EDITORDER"
                                        [variantOrModelIsNew]="variantOrModelIsNew"
                                        [image]="getVoid">
                        </customizer-list>
                    </div>
                    <div>
                        <span class="side">{{ "COMMON.RIGHT" | translate }}</span>
                        <customizer-list class="bordered"
                                        [selectedIndex]="selectedIndexRight"
                                        (selectedChange)="setSize('right', $event)"
                                        [items]="sizes"
                                        [name]="'Size'"
                                        [discontinued]="'Discontinued'"
                                        [editMode]="state === customizerStateEnum.EDITORDER"
                                        [variantOrModelIsNew]="variantOrModelIsNew"
                                        [image]="getVoid">
                        </customizer-list>
                    </div>
                </div>
                <div class="customizer-group" *ngIf="variantList.length > 1">
                    <checkbox *ngFor="let vari of variantList" [checked]="getSelectedVariant.Id === vari.Id" (checkedChange)="selectVariant(vari)" [label]="vari.Description| dataTranslate|translate">
                        <dcg-image info-content *ngIf="!vari.Default" [src]="imageService.GetVariantInfoImage(vari)"></dcg-image>
                        <availability-text class="availability" [variantOrModelIsNew]="variantOrModelIsNew" [date]="vari.Discontinued" [new]="vari.New" [newLargeText]="false" [useNewDetails]="false"></availability-text>
                    </checkbox>
                </div>
            </div>
        </customizer-component-header>
    `
})
export class TempleClipLengthComponent extends BaseCustomizerComponent implements OnInit {

	public sizes: Array<VariantSizeModel> = [];
	public selectedIndex: number = 0;
	public selectedIndexRight: number = 0;
	public variantList: Array<ComponentVariantFromApiModel>;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		public imageService: ImageService,
		private sorter: SorterService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		this.variantList = await this.fetchVariants();

		if (this.variantList.some(cv => cv.Code.includes(';'))) {
			this.overWriteVariantsList();
		}

		this.sizes = this.fetch();

		const variant = this.getSelectedVariant;
		if (variant.SplittingAllowed && this.getSelectedVariant.SplittingData) {
			this.selectedIndex = this.sizes.findIndex(x => x.Size == this.getSelectedVariant.SplittingData.left);
			this.selectedIndexRight = this.sizes.findIndex(x => x.Size == this.getSelectedVariant.SplittingData.right);
		} else {
			this.selectedIndex = this.sizes.findIndex(x => x.Uuid == this.getSelectedVariant.VariantSize.Uuid);
		}

		this.events.showLoader(false);
	}

	overWriteVariantsList() {
		const unique = [...new Set(this.variantList.map(item => item.Code))];

		const variants = [... new Set(unique.map(item => item.substring(0, item.lastIndexOf(';'))))];

		if (variants.length <= 1) {
			let t = this.variantList[0];
			this.variantList = [];
			this.variantList.push(t);
		}
	}

	async onChanges(changes: SimpleChanges) {
		const variant = this.getSelectedVariant;

		if (changes.variants && !changes.variants.firstChange) {
			this.sizes = await this.fetch();
		}

		let x = this.getSelectedVariant;
		let y = this.getSelectedVariant.SplittingData;

		if (variant.SplittingAllowed && this.getSelectedVariant.SplittingData) {
			this.selectedIndex = this.sizes.findIndex(x => x.Size == this.getSelectedVariant.SplittingData.left);
			this.selectedIndexRight = this.sizes.findIndex(x => x.Size == this.getSelectedVariant.SplittingData.right);
		} else {
			this.selectedIndex = this.sizes.findIndex(x => x.Uuid == this.getSelectedVariant.VariantSize.Uuid);
		}

	}

	onSelect(index: number) {
		this.events.showLoader(true);

		const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		newVariant.VariantSize = this.sizes[index];
		this.updateComponentSize(new ComponentVariantChangeModel(oldVariant, newVariant, null));
	}

	selectVariant(selectedVariant: ComponentVariantFromApiModel) {
		if (selectedVariant.Id == this.getSelectedVariant.Id) {
			return; // prevents unnecessary run of script, if you select what is already selected
		}

		this.events.showLoader(true);
		this.updateVariant(selectedVariant.ConvertToChangeModel(this.getSelectedVariant, this.sorter));
	}

	fetch(): Array<VariantSizeModel> {
		const variant = this.getSelectedVariant;
		variant.VariantSizeViewed = true;

		return this.variantList.find(x => x.Id == variant.Id).VariantSizes.sort(this.sorter.sortBy(VariantSizeModel));
	}

	async fetchVariants(): Promise<Array<ComponentVariantFromApiModel>> {
		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, this.getSelectedVariant.Component.Uuid, null);
		await this.RunDisablingSystem(apidata);

		return apidata;
	}

	setSize(side: string, index: number) {
		this.events.showLoader(true);
		const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);

		newVariant.VariantSize = this.sizes[index];

		if (side == 'left') {
			newVariant.SplittingData = { left: this.sizes[index].Size, right: newVariant.SplittingData.right };
		} else {
			newVariant.SplittingData = { left: newVariant.SplittingData.left, right: this.sizes[index].Size };
		}

		this.updateComponentSize(new ComponentVariantChangeModel(oldVariant, newVariant, null));
	}

	toggle(event: boolean) {
		this.events.showLoader(true);

		const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		newVariant.VariantSize = this.sizes[this.selectedIndex];

		if (event) {
			newVariant.SplittingData = { left: newVariant.VariantSize.Size, right: newVariant.VariantSize.Size };
		} else {
			newVariant.SplittingData = null;
		}

		this.updateComponentSize(new ComponentVariantChangeModel(this.getSelectedVariant, newVariant, null));
	}
	getVoid = () => {
		return null;
	}

}
