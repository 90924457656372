import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'firstWordMobile'
})
export class FirstWordMobilePipe implements PipeTransform {

	transform(value: string, width: number): unknown {
		if (!value) { return ''; }

		return width < 1023 ? value.split('&nbsp;')[0] : value;
	}

}
