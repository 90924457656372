import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DataTranslationModel } from '@models/data-translation-model';
import { TagModel } from '@models/tag';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { ModalService } from '@services/modal.service';
import { TagsService } from '@services/tags.service';
import { UserService } from '@services/user.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { TagFilterComponent } from '../tag-filter.component';

@Component({
	selector: 'app-model-filter',
	templateUrl: './model-filter.component.html',
	styleUrls: ['./../tag-filter.component.scss', './model-filter.component.scss']
})
export class ModelFilterComponent extends TagFilterComponent implements OnDestroy {

	public hbox?: string = null;
	private hboxFrom: Observable<number>;
	private hboxTo: Observable<number>;
	private hboxSub: Subscription;

	constructor(
		tagsService: TagsService,
		router: Router,
		userService: UserService,
		googleAnalyticsService: GoogleAnalyticsService,
		modalService: ModalService
	) {
		super(tagsService, router, userService, googleAnalyticsService, modalService);
	}

	public init() {
		this.hboxFrom = this.tagsService.HboxFromObservable();
		this.hboxTo = this.tagsService.HboxToObservable();

		this.hboxSub = combineLatest([this.hboxFrom, this.hboxTo]).subscribe(([hboxFrom, hboxTo]) => {
			this.setHbox(hboxFrom, hboxTo);
		});

		this.setHbox(this.tagsService.getHboxFromSnapshot(), this.tagsService.getHboxToSnapshot());


		// It appears that tags fetched from the tags service (state storage) are not properly cast to TagModels but only to json
		// The data translation pipe relies on the specific class type, hence this little transmogrification...
		// BTW this sucks... The ngx-storage service SHOULD return deserialized classes and not just JSON...

		let tagDataFromService = this.tagsService.tagsObservable();

		tagDataFromService.subscribe(tagData => {
			let tempTagContainer: TagModel[] = [];

			tagData.forEach(tag => {
				let dtm: DataTranslationModel = new DataTranslationModel('', '');
				Object.assign(dtm, tag.Description);

				let tm: TagModel = new TagModel();
				Object.assign(tm, tag);

				tm.Description = dtm;

				tempTagContainer.push(tm);
			});

			this.tags = new Observable<Array<TagModel>>((observable) => {
				observable.next(tempTagContainer);
			});
		});
	}

	private setHbox(hboxFrom?: number, hboxTo?: number) {
		this.hbox = hboxFrom && hboxTo ? `${hboxFrom}-${hboxTo}` : null;
	}

	ngOnDestroy() {
		if (this.hboxSub) {
			this.hboxSub.unsubscribe();
		}
	}

	clearHbox() {
		this.tagsService.setHboxFrom(null);
		this.tagsService.setHboxTo(null);
	}

}
