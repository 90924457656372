import { Injectable } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { ModelGroupModel } from '@models/model-group';
import { plainToClass } from 'class-transformer';
import { CustomizerMenuPartModel } from '../_models/customizer-menu-part';
import { MenuModel } from '../_models/menu';
import { MenuComponentModel } from '../_models/menu-component';
import * as Components from './../app/dynamic/components';
import { BrandModeService } from './brandmode.service';
import { EnvService } from './env.service';
import { StoreService } from './store.service';

@Injectable()
export class MenuService {
    constructor(private brandModeService: BrandModeService, private storeService: StoreService, private env: EnvService) { }

    public ConvertMenus(menus: Array<MenuModel>): Array<CustomizerMenuPartModel> {
        const result: Array<CustomizerMenuPartModel> = [];

        menus.forEach((menu) => {
            if (this.brandModeService.isTitanium && menu.SubMenus.length === 1) {
                menu = menu.SubMenus[0];
            }

            // todo: remove this - 126871
            if (menu.Name == "CombinedColor") {
                menu.Name = null;
            }

            const resultMenu = this.getMenuItem(menu.Id,
                menu.Name,
                menu.TranslateName,
                menu.TranslateDescription,
                menu.Icon,
                null,
                null,
                menu.MenuComponentModels,
                menu.ContentGrouping,
                menu.Name == "ExtraInnerrim",
                menu.MatchColor); // todo: remove this - 126871

            const subMenus = menu.SubMenus?.filter(m => m.Brand == BrandEnum.TitaniumAndPrecious || m.Brand === this.brandModeService.currentBrandValue())

			resultMenu.subComponents = subMenus 
				? subMenus.map(sm => this.getMenuItem(sm.Id, sm.Name, sm.TranslateName, sm.TranslateDescription, sm.Icon, [], resultMenu, sm.MenuComponentModels, sm.ContentGrouping, false, sm.MatchColor)) 
				: [];

            if (menu.Name == "ExtraInnerrim") { // todo: remove this - 126871
                result.push(this.getMenuItem(0, "Reference", "MISCELLANEOUS", null, "reference", [], null, [], false, false, 0));
            }

            result.push(resultMenu);
        });

        if(this.brandModeService.isTitanium){
            result.push(this.getMenuItem(1, "Case", "CASE", null, "accessories",  [], null, [], false, false, 0));
        }
        if (!menus.some(x => x.Name == "ExtraInnerrim")) { // todo: remove this - 126871
            result.push(this.getMenuItem(0, "Reference", "MISCELLANEOUS", null, "reference", [], null, [], false, false, 0));
        }

        return result;
    }

    public getMenuItem(
        id: number,
        menuName: string,
        translateName: string,
        translateDecription: string,
        icon: string,
        subComponents: Array<CustomizerMenuPartModel>,
        parentMenu: CustomizerMenuPartModel,
        menuComponents: Array<MenuComponentModel>,
        contentGrouping: boolean,
        useForUpselling: boolean,
        matchColor: number
      ): CustomizerMenuPartModel {
          return Object.assign(new CustomizerMenuPartModel(), {
              id,
              name: menuName,
              translateName,
              translateDescription: translateDecription,
              isVisited: false,
              isVisiting: false,
              icon,
              component: Components[menuName + "Component"],
              subComponents,
              parentMenu,
              menuComponents,
              collapsed: true,
              contentGrouping,
              scrollPosition: null,
              useForUpselling,
              matchColor
          });
      }

      public async getMenu(modelgroup: ModelGroupModel): Promise<Array<MenuModel>> {
		const data = await this.getMenus();
		const result = data.filter(m => m.ModelGroupUuid == modelgroup.Uuid &&
			(m.Brand == BrandEnum.TitaniumAndPrecious || m.Brand === this.brandModeService.currentBrandValue())).map((x) => plainToClass(MenuModel, x));
		return result;
	}

	public async getMenuByUuids(modelgroupUuids: string[]): Promise<Array<MenuModel>> {
		const data = await this.getMenus();
		const result = data.filter(m => modelgroupUuids.some(id => m.ModelGroupUuid == id)).map((x) => plainToClass(MenuModel, x));
		return result;
	}

	public async getMenus() {
		const data = await this.storeService.getOrSetFromStore<Array<MenuModel>>(`${await this.env.baseUrl()}/menus`);
		return data;
	}
}
