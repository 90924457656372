import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VersionType } from '@infrastructure/versionType.enum';
import { AddressModel } from '@models/address';
import { ModelReponseWithVariantsModelFromApi } from '@models/api-models/ModelReponseWithVariantsModelFromApi';
import { CaseAlternativeGroupLineModel } from '@models/api-models/case-alternative-groupline';
import { ModelModel } from '@models/api-models/model';
import { OrderCreateModel } from '@models/api-models/order-create-model';
import { CustomerModel } from '@models/customer';
import { CCDiscountModel, DiscountModel, RCDiscountModel, SFDiscountModel } from '@models/discounts/discount-model';
import { FairModel } from '@models/fair-model';
import { MiscItemCreateModel } from '@models/misc-item-create';
import { ModelGroupModel } from '@models/model-group';
import { ModelSizeModel } from '@models/model-size';
import { Order } from '@models/order';
import { OrderCompleteModel } from '@models/order-complete-model';
import { OrderInfoModel } from '@models/order-info.model';
import { OrderItemMiscModel, OrderItemMiscState } from '@models/order-item-misc-model';
import { OrderItemType } from '@models/order-item-type';
import { OrderState } from '@models/order-state';
import { OrderUpdateModel } from '@models/order-update-model';
import { OrderIdsModel } from '@models/orderIds-model';
import { OrdersModel } from '@models/orders';
import { PriceModel, PriceModelStatus, PriceResponse } from '@models/price-model';
import { StartKitModel } from '@models/start-kit-model';
import { TagModel } from '@models/tag';
import { VariantColorModel } from '@models/variant-color';
import { VersionModel } from '@models/version-model';
import { AppState } from '@shared/app-state';
import { UserTypeConstant } from '@shared/user-type-constants';
import { plainToClass } from "class-transformer";
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { MenuService } from './menu.service';
import { OfflineService } from './offline.service';
import { ServerInfoService } from './server-info.service';
import { StoreService } from './store.service';
import { UserService } from './user.service';
import { ServiceUtils } from './utils/service.utils';
import { ModelsService } from './v2/models.service';

@Injectable({
	providedIn: 'root'
})
export class DataApiService {

	constructor(
		private userService: UserService,
		private appState: AppState,
		private storeService: StoreService,
		private indexedDbService: IndexDbService,
		private offlineService: OfflineService,
		private serviceUtils: ServiceUtils,
		private modelsService: ModelsService,
		private env: EnvService,
		private menuService: MenuService,
		private serverInfoService: ServerInfoService

	) {
	}

	public async getDataTranslations(lang: string) {
		const t = await this.storeService.getOrSetFromStore<Array<{ Key: string, Value: string }>>(`${await this.env.baseUrl()}/Translations/${lang.toUpperCase()}`);
		const target = {};
		t.forEach(key => target[key.Key] = key.Value);
		return target;
	}

	public async loadDataRequests(modelgroup: ModelGroupModel): Promise<Observable<HttpEvent<ModelReponseWithVariantsModelFromApi>>> {
		try {
			this.indexedDbService.ignoreIndexedDb = false;

			if (modelgroup.ParentModelGroupUuid) {
				await this.modelsService.getModelGroup(modelgroup.ParentModelGroupUuid);
			}

			await this.menuService.getMenu(modelgroup);
			await this.getAllSizes(modelgroup.ProductLineUuid, modelgroup.Uuid);
			await this.modelsService.getVariantsOnModelgroup(modelgroup);
			return this.modelsService.getModelsWithAllDataRequest(modelgroup);
		}
		catch (error) {
			throw error;
		}
		finally {
			this.indexedDbService.ignoreIndexedDb = true;
		}
	}

	public async getStartKits(): Promise<Array<StartKitModel>> {
		const result = await this.storeService.getOrSetFromStore<Array<StartKitModel>>(`${await this.env.baseUrl()}/StartKits`);
		return result.map((x) => plainToClass(StartKitModel, x));
	}

	public async getOwnAddresses(): Promise<Array<AddressModel>> {
		const result = await this.storeService.getOrSetFromStore<Array<AddressModel>>(`${await this.env.baseUrl()}/Account/Addresses/`);
		return result;
	}

	public async getDiscounts(customer: CustomerModel, isEnduser: boolean): Promise<Array<DiscountModel>> {
		return new Promise((resolve, reject) => {
			if (!customer || !customer.isConsultantOrderOnBehalf) {
				resolve([]);
				return;
			}

			const discounts = [new CCDiscountModel(), new RCDiscountModel()];

			if (isEnduser) {
				discounts.push(new SFDiscountModel());
			}

			resolve(discounts);
		});
	}

	public async getFairs(): Promise<Array<FairModel>> {

		const fairsData = await this.offlineService.getDataWithOfflineCheck<Array<FairModel>>(`${await this.env.baseUrl()}/Fairs/`);

		const fairs = fairsData.map(x => plainToClass(FairModel, x));
		return fairs;
	}

	public async SearchColors(search: string): Promise<Array<VariantColorModel>> {
		const allColorsResult = await this.storeService.getOrSetFromStore<Array<VariantColorModel>>(`${await this.env.baseUrl()}/colors`);

		const filteredColorsResult = allColorsResult.filter(x => x.Color.Code?.toLowerCase().startsWith(search?.toLowerCase()))
			.map(x => plainToClass(VariantColorModel, x));

		return filteredColorsResult;
	}

	public async searchModels(search?: string, tags?: Array<TagModel>, hboxFrom?: number, hboxTo?: number): Promise<Array<ModelModel>> {
		let tagUrl = `&requestModel.hboxFrom=${hboxFrom || ""}&requestModel.hboxTo=${hboxTo || ""}`;

		tags = tags || [];
		tags.forEach(tag => {
			tagUrl += "&requestModel.tags=" + tag.Id;
		});

		const result = await this.storeService.getOrSetFromStore<Array<ModelModel>>(`${await this.env.baseUrl()}/models/search?requestModel.searchCriteria=${search || ""}${tagUrl}`);
		return result.map((x) => ModelModel.createCopy(x));
	}

	public async getSizes(productLineUuid: string, modelGroupUuid: string, modelUuid: string): Promise<Array<ModelSizeModel>> {
		const result = await this.getAllSizes(productLineUuid, modelGroupUuid);
		return result.filter(x => x.ModelUuid == modelUuid);
	}

	private async getAllSizes(productLineUuid: string, modelGroupUuid: string): Promise<Array<ModelSizeModel>> {
		const result = await this.storeService.getOrSetFromStore<Array<ModelSizeModel>>(`${await this.env.baseUrl()}/modelsize?requestModel.productLineUuid=${productLineUuid}&requestModel.modelGroupUuid=${modelGroupUuid}`);
		return result.map((x) => plainToClass(ModelSizeModel, x));
	}

	public async getAllCases() {
		let allCases = await this.storeService.getOrSetFromStore<Array<CaseAlternativeGroupLineModel>>(`${await this.env.baseUrl()}/cases`);

		return allCases;
	}

	public async completeOrder(orders: Array<OrderCompleteModel>): Promise<void> {
		await this.offlineService.postWithOfflineHandling<void>(`${await this.env.baseUrl()}/orders/complete`, orders);
	}

	public async requestOrder(orders: Array<OrderCompleteModel>): Promise<void> {
		await this.offlineService.postWithOfflineHandling<void>(`${await this.env.baseUrl()}/orders/request`, orders);
	}

	public async createNewOrder(order: OrderCreateModel): Promise<number> {
		const result = await this.offlineService.postWithOfflineHandling<number>(`${await this.env.baseUrl()}/orders/createNew`, order);
		return result;
	}

	public async setOrderData(orderId: number, model: OrderUpdateModel): Promise<void> {
		await this.offlineService.postWithOfflineHandling<number>(`${await this.env.baseUrl()}/orders/${orderId}`, model);
	}

	public async getOrderIds(customerNo: string): Promise<Array<OrderIdsModel>> {

		const orders = await this.offlineService.getDataWithOfflineCheck<Array<OrderIdsModel>>(`${await this.env.baseUrl()}/orders/ActiveOrderIds/${customerNo || ""}`);

		if (orders) {
			return orders.map(x => plainToClass(OrderIdsModel, x));
		}

		return null;
	}

	public async getOrders(customerNo: string): Promise<Array<OrderState>> {
		return await this.offlineService.getDataWithOfflineCheck<Array<OrderState>>(`${await this.env.baseUrl()}/orders/active/${customerNo || ""}`);
	}

	public async postMiscItemFromState(orderItemMiscModelState: OrderItemMiscState): Promise<OrderItemMiscState> {
		const orderItemMiscModel = new OrderItemMiscModel(orderItemMiscModelState);
		return await this.postMiscItem(orderItemMiscModel);
	}

	public async postMiscItem(orderItemMiscModel: OrderItemMiscModel): Promise<OrderItemMiscState> {
		let miscItem: MiscItemCreateModel = MiscItemCreateModel.Create(orderItemMiscModel);

		const result = await this.offlineService.postWithOfflineHandling<OrderItemMiscState>(`${await this.env.baseUrl()}/orders/OrderItemMisc/Create`, miscItem);

		return result;
	}

	public async deleteOrderMiscItem(uuid: string): Promise<void> {
		return await this.offlineService.deleteWithOfflineHandling<void>(`${await this.env.baseUrl()}/orders/OrderItemMisc/${uuid}`);
	}

	public async getTags(): Promise<Array<TagModel>> {
		const result = await this.storeService.getOrSetFromStore<Array<TagModel>>(`${await this.env.baseUrl()}/tags`);
		return result.map((x) => plainToClass(TagModel, x));
	}



	public async getPrice(orderItemType: OrderItemType, orderItemUuid: string): Promise<PriceResponse> {
		let priceModel = new PriceResponse();

		try {
			if (this.appState.priceModuleEnabled.getValue()) {
				priceModel = await this.offlineService.postWithOfflineHandling<PriceResponse>(`${await this.env.baseUrl()}/price/${orderItemType}/${orderItemUuid}`, {})

				if (!priceModel.Data) {
					priceModel.Status = new PriceModelStatus(priceModel.Status.statusCode, priceModel.Status.message);
				}
			}
			else {
				priceModel.Data = new PriceModel()
				priceModel.Data.ResponseItems = []
			}

			return priceModel;
		}
		catch (err) {
			console.error('getPrice error', err);

			priceModel.Status = new PriceModelStatus(err.status, err.error.substring(0, err.error.indexOf('\r\n')));

			return priceModel;
		}
	}

	public async updatePricesOnOrder(orderUuid: string): Promise<PriceResponse> {
		return await this.offlineService.postWithOfflineHandling<PriceResponse>(`${await this.env.baseUrl()}/updateAndReturnPrices/${orderUuid}`, {})
	}

	public async getVersions(): Promise<Array<VersionModel>> {
		const endpoint = `${await this.env.baseUrl()}/versions`;

		try {
			const versions = await this.offlineService.getDataWithOfflineCheck<Array<VersionModel>>(endpoint);
			return versions;
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				let versions: Array<VersionModel> = [];
				versions.push(this.indexedDbService.getLocalVersion(VersionType.Frames));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.ToolsAndAccessories));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.Customers));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.QueueOrderItems));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.StartKits));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.Orders));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.ManualConfig));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.Cases));
				versions.push(this.indexedDbService.getLocalVersion(VersionType.Translations));
				return versions;
			}

			throw error;
		}
	}

	public async getOrder(userUuid: string, orderUuid: string, customerNo: string): Promise<OrdersModel> {
		const order = await this._getOrder(customerNo, orderUuid);

		const user = await this.userService.GetUser();

		if (userUuid !== Guid.EMPTY && order.ConsultantUuid && (order.ConsultantUuid.toLocaleLowerCase() !== userUuid.toLocaleLowerCase()) &&
			user?.Type !== UserTypeConstant.Employee) {
			return null;
		}

		const orders: Array<Order> = [];
		const orderState = plainToClass(OrderState, order);
		orderState.ShowOrderConfirmation = true;
		orders.push(new Order(orderState));

		return new OrdersModel(orders);
	}

	public async getUnfinishedOrders(fromNetwork: boolean = false): Promise<Array<OrderInfoModel>> {
		const url = `${await this.env.baseUrl()}/orders/unfinished`;
		let result = await (this.storeService.getOrSetFromStore<Array<OrderInfoModel>>(url));

		if (fromNetwork) {
			result = await (this.serverInfoService.getNetworkFirst<Array<OrderInfoModel>>(url));
		}

		return result.map((x) => plainToClass(OrderInfoModel, x));
	}

	public async getOrderChunkAsync(skip: number, take: number, searchString: string = null) {
		let url = `${await this.env.baseUrl()}/orders/chunk/?skip=${skip}&take=${take}`;

		if (searchString) {
			url += `&searchString=${searchString}`;
		}

		let result = await (this.serverInfoService.getNetworkFirst<Array<OrderInfoModel>>(url));

		return result.map((x) => plainToClass(OrderInfoModel, x));
	}


	public async getOrderByAxId(axId: string): Promise<Array<OrderInfoModel>> {
		return await this.serverInfoService.getNetworkFirst<Array<OrderInfoModel>>(`${await this.env.baseUrl()}/order/${axId}`);
	}

	private async _getOrder(customerNo: string, orderUuid: string): Promise<OrderState> {
		return await this.serverInfoService.getNetworkFirst<OrderState>(`${await this.env.baseUrl()}/orderconfirmation/${customerNo}/${orderUuid}`);
	}

	public async deleteOrder(id: number): Promise<void> {
		const result = await this.offlineService.deleteWithOfflineHandling<void>(`${await this.env.baseUrl()}/orders/${id}`);
		return result;
	}

}
