
import { Component, EventEmitter, HostBinding, Output } from "@angular/core";
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';

@Component({
    selector: "featured-model-overlay",
    templateUrl: 'featured-model-overlay.component.html',
    styleUrls: ['./featured-model-overlay.component.scss'],
    animations: [
        FadeInOutTransition(0.95)
    ]
})
export class FeaturedModelOverlayComponent {
    @HostBinding('@fadeInOutTransition') fade = '';

    @Output() public applyChange: EventEmitter<void> = new EventEmitter();
    @Output() public cancelChange: EventEmitter<void> = new EventEmitter();

    public onApply() {
        this.applyChange.emit();
    }

    public onCancel() {
        this.cancelChange.emit();
    }
}
