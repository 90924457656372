import { PriceItem } from "./price-model";

export class PartAndPrice {

	priceItem: PriceItem;

	constructor(public name: string, public engravable: boolean) {
	}

}
