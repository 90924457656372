import { animate, keyframes, style, transition, trigger } from "@angular/animations";

export function IconClickTransition() {
    return trigger('iconClickTransition', [
        transition('* => *', [
            animate(300, keyframes([
                // gør lille
                style({
                    height: "11px",
                    offset: 0.1
                }),
                // gør normal
                style({
                    height: "21px",
                    offset: 0.3
                }),
                // gør normal
                style({
                    height: "21px",
                    offset: 1
                }),
            ]))
        ])
      ]);
}
