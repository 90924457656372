export const UrlConstants = {
    MODEL: "modelName",
    MODELGROUP: "modelGroupCode",
    MODELGROUPNAME: "modelGroupName",
    RIMLESS: "rimless",
    INSPIRATIONGROUP: "inspirationGroup",
    CATEGORYCODE: "categoryCode",
    GROUPCODE: "groupCode",
    BRANDMODE: "brandMode",
};
