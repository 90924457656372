import { Injectable } from '@angular/core';

import { OrderItemModel } from '@models/order-item';
import { OrderItemMiscModel } from '@models/order-item-misc-model';
import { OrderItemState } from '@models/order-item-state';
import { PriceModel, PriceModelStatus, PriceResponse } from '@models/price-model';
import { AppState } from '@shared/app-state';
import { plainToClass } from 'class-transformer';
import { EnvService } from './env.service';
import { OfflineService } from './offline.service';


@Injectable({
    providedIn: 'root'
})
export class OrderItemService {

    constructor(

        private offlineService: OfflineService,
        private env: EnvService,
        private appState: AppState,


    ) {

    }


    async moveOrderMiscItem(Uuid: string, orderId: number) {
        await this.offlineService.postWithOfflineHandling<number>(`${await this.env.baseUrl()}/orders/OrderItemMisc/${Uuid}/MoveTo/${orderId}`, {});
    }

    async moveOrderItem(Uuid: string, orderId: number) {
        await this.offlineService.postWithOfflineHandling<number>(`${await this.env.baseUrl()}/orders/OrderItem/${Uuid}/MoveTo/${orderId}`, {});
    }

    public async editOrderItem(orderItem: OrderItemModel): Promise<OrderItemModel> {
        const state = await this.offlineService.postWithOfflineHandling<OrderItemState>(`${await this.env.baseUrl()}/orders/orderItem/edit`, orderItem.getState());
        return new OrderItemModel(plainToClass(OrderItemState, state));
    }

	public async editOrderMiscItem(orderItem: OrderItemMiscModel): Promise<void> {
		await this.offlineService.postWithOfflineHandling<void>(`${await this.env.baseUrl()}/orders/OrderItemMisc/edit`, orderItem.getState());
	}

    public async getPriceFromConfiguration(orderItem: OrderItemModel): Promise<PriceResponse> {
		let priceModel = new PriceResponse();

		try {
			if (this.appState.priceModuleEnabled.getValue()) {
				priceModel = await this.offlineService.postWithOfflineHandling<PriceResponse>(`${await this.env.baseUrl()}/priceFromConfiguration`, orderItem.getState());

				if (!priceModel?.Data) {
					priceModel.Status = new PriceModelStatus(priceModel.Status?.statusCode, priceModel.Status?.message);
				}
			}
			else {
				priceModel.Data = new PriceModel()
				priceModel.Data.ResponseItems = []
			}

			return priceModel;
		}
		catch (err) {
			console.error('getPrice error', err);

			if (err.error && err.error.substring && err.error.indexOf) {
				priceModel.Status = new PriceModelStatus(err.status, err.error.substring(0, err.error.indexOf('\r\n')));
			}

			return priceModel;
		}
	}

	public async postItem(orderItemCreateModel: OrderItemState): Promise<OrderItemState> {
		const result = await this.offlineService.postWithOfflineHandling<OrderItemState>(`${await this.env.baseUrl()}/orders/OrderItem/Create`, orderItemCreateModel);
		return result;
	}
	
	public async deleteOrderItem(uuid: string): Promise<void> {
        const result = await this.offlineService.deleteWithOfflineHandling<void>(`${await this.env.baseUrl()}/orders/orderItem/${uuid}`);
        return result;
    }

}
