import { Type } from "class-transformer";
import { ModelModel } from "./api-models/model";
import { ConfigurationModel } from "./configuration";
import { ManualConfigVariants } from "./manualconfigvariant";
import { ModelSizeModel } from "./model-size";
import { ModelGroupModel } from './model-group';
import { TempleInclinationEnum } from "./variant-temple-inclination";
import { BehaviorSubject } from "rxjs";
import { CustomizerSummaryModel } from "./customizer-summary-model";

export class ManualConfig {
    Id: number;
    Uuid: string;
    Group: number;

    @Type(() => Date)
    Release: Date;

    ImageCode: string;

    @Type(() => ModelGroupModel)
    ModelGroup: ModelGroupModel;

    @Type(() => ModelModel)
    Model: ModelModel;

    @Type(() => ModelSizeModel)
    ModelSize: ModelSizeModel;

    @Type(() => ConfigurationModel)
    ModelConfiguration: ConfigurationModel;

    @Type(() => ManualConfigVariants)
    ManualConfigVariants: Array<ManualConfigVariants>;

    TempleInclination?: TempleInclinationEnum;
    ManualConfigCollectionId: number;
    FavoriteUuid: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    IsSavingToBag: boolean;
    ShowAddedToBag: boolean;
    IsAddingToTestBag: boolean;
    ShowStartKitAddedToBag: boolean;
    HasBeenDeleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    IsInCollection: boolean;
    IsPrecious: boolean;
    InvalidatedAt: Date;
    InvalidatedReason: string;
    SummaryModels: Array<CustomizerSummaryModel>;
    HitCount: number;
    imageUrl: string;
    imageUrlRimlessSide: string;
}

export class ManualConfigHitCountModel {
    ManualConfigCollectionId: number;
    ModelGroupId: number;
    HitCount: number;
}
