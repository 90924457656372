export enum VersionType {
    Frames = 0,
    ToolsAndAccessories = 1,
    Customers = 2,
    QueueOrderItems = 3,
    StartKits = 4,
    Orders = 5,
    ManualConfig = 6,
    Cases = 7,
    Translations = 8
}
