import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { BreadcrumbService } from "../_services/breadcrumb.service";
import { ModalContainer } from './modal.component';

@Directive()
export abstract class BaseModal extends ModalContainer implements OnDestroy {

	public subscriptions: Array<Subscription> = [];

	constructor(
		breadcrumbService: BreadcrumbService
	) {
		super();

		this.subscriptions.push(breadcrumbService.breadcrumbClicked$.subscribe(() => this.closeAndDestroy()));
	}

	public closeAndDestroy(all: boolean = false) {
		this.closeModal(all);
		this.destroy(all);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

}
