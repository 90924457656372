import { Type } from "class-transformer";
import { CustomizerMenuPartModel } from './customizer-menu-part';

export class ErrorModel {
    public translateName: string;
    public translateName2: string;

    @Type(() => CustomizerMenuPartModel)
    public menu: CustomizerMenuPartModel;
    public menuId: number;
}
