import { AssortmentModel } from '@models/assortment-model';

export class AssortmentCalculator
{
    public static FindMissingAssortments(model: Array<AssortmentModel>, assortments: Array<string>): AssortmentModel[] {
        const result = new Array<AssortmentModel>();

        if(model && assortments) {
            model.forEach(assort => {
                if(!assortments.some(x => x === assort.Code)) {
                    result.push(assort);
                }
            });
        }

        return result;
    }
}