import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { CountryModel } from '@models/country-model';
import { CustomerModel, CustomerType } from '@models/customer';
import { SalesDistrictType } from '@models/sales-district';
import { BrandModeService } from '@services/brandmode.service';
import { CustomerDataService } from '@services/customer-data.service';
import { LanguageService } from '@services/language.service';
import { OfflineService } from '@services/offline.service';
import { UserTypeConstant } from '@shared/user-type-constants';
import { Guid } from 'guid-typescript';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { Subject } from 'rxjs';
import { UserDataModel } from 'src/_models/user-data-model';
import { BreadcrumbService } from '../../_services/breadcrumb.service';
import { EventsService } from '../../_services/events.service';
import { UserService } from '../../_services/user.service';
import { BaseModal } from '../base-modal';

@Component({
	selector: 'order-on-behalf-modal',
	templateUrl: 'order-on-behalf.component.html',
	styleUrls: ['./order-on-behalf.component.scss']
})
export class OrderOnBehalfModalComponent extends BaseModal implements OnInit {

	customerChange: EventEmitter<CustomerModel> = new EventEmitter();
	public allCustomers: Array<CustomerModel> = [];
	public shopInfoCustomers: Array<CustomerModel>;
	public countryCodes: Array<CountryModel>;
	public customersOwnDistrict: Array<CustomerModel> = [];
	public customersOtherDistricts: Array<CustomerModel> = [];
	public user: UserDataModel;
	public isReady: boolean = false;
	public showOtherDistricts: boolean = false;
	public showExPartners = false;
	public showPreciousPartners = false;
	public hasAnyNonPartners = false;
	public hasAnyPreciousPartners = false;
	public filteredCustomer: Array<CustomerModel> = [];
	public districtClass: string = '';
	public searchWord: string;
	public ownDistrictHeader: string
	public otherDistrictsHeader: string
	public optionGroupCount: number
	public newCustomersOptionGroupCount: number
	public newPartnerMode: boolean = false;
	public isDoingShopInfoLookup: boolean = false;
	public newPartnerName: string
	public newPartnerAddress: string
	public newPartnerFirstTimeDeliveryAddress: string
	public newPartnerCountryCode: string
	public newCustomerModuleEnabled: boolean = false
	public countries: Array<CountryModel> = []
	public debouncer: Subject<string> = new Subject<string>();
	@ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement>;

	constructor(
		breadcrumbService: BreadcrumbService,
		private userService: UserService,
		private eventService: EventsService,
		private customerDataService: CustomerDataService,
		public offlineService: OfflineService,
		public languageService: LanguageService,
		private brandModeService: BrandModeService
	) {
		super(breadcrumbService);

		this.languageService.get("ORDERONBEHALF.OWNDISTRICT").subscribe((x) => {
			this.ownDistrictHeader = x;
		});

		this.languageService.get("ORDERONBEHALF.OTHERDISTRICTS").subscribe((x) => {
			this.otherDistrictsHeader = x;
		});
	}

	async ngOnInit() {
		this.eventService.showLoader(true);
		this.user = await this.userService.GetUser();
		this.newCustomerModuleEnabled = await this.userService.NewCustomerModuleEnabled();
		this.allCustomers = await this.customerDataService.getCustomersData(false);

		this.optionGroupCount = this.allCustomers.some(x => x.SalesDistrictType === SalesDistrictType.OtherDistricts) ? 2 : 1;

		if (this.user.Type === UserTypeConstant.Consultant) {
			if (this.user.IsDistributor) {
				this.customersOwnDistrict = this.allCustomers.filter(x => x.IsPlusPartner && !x.IsBlocked);
			}
			else {
				this.customersOwnDistrict = this.allCustomers.filter(x => x.IsPlusPartner && !x.IsBlocked && x.SalesDistrictType === SalesDistrictType.OwnDistrict);
				this.customersOtherDistricts = this.allCustomers.filter(x => x.IsPlusPartner && !x.IsBlocked && x.SalesDistrictType === SalesDistrictType.OtherDistricts);
				this.showOtherDistricts = this.customersOtherDistricts.length > 0;

				if (this.showOtherDistricts) {
					this.districtClass = "district-mode"
				}
			}
		}
		else {
			this.customersOwnDistrict = this.allCustomers.filter(x => x.IsPlusPartner && !x.IsBlocked);
		}

		this.hasAnyNonPartners = this.allCustomers.some(x => !x.IsPlusPartner);
		this.hasAnyPreciousPartners = this.brandModeService.isPrecious && this.allCustomers.some(x => x.IsPreciousPartner);
		this.filteredCustomer = this.allCustomers.filter(x => x.IsPlusPartner && !x.IsBlocked);

		setTimeout(() => {
			this.isReady = true;
			this.eventService.showLoader(false);
		}, 1000);
	}

	onConfirmAndLogout(): void {
		this.closeAndDestroy();
	}

	onConfirmAndContinue(): void {
		this.closeAndDestroy();
	}

	getCustomerSelectString = (model: CustomerModel) => `${model.IsBlocked ? "** " : (!model.IsPlusPartner ? "* " : "")}${model.Name}, ${model.No}, ${model.AddressStringified}`;

	getNewCustomerSelectString = (model: CustomerModel) => `+ ${model.Name}, ${model.No}`;

	public newCustomersOptionGroupHeader = (currentCustomer: CustomerModel, customers: Array<CustomerModel>, index: number) => {
		return index === 0 ? this.languageService.instant("NEW.PARTNER.NEW.ACCOUNTS") : '';
	};

	public optionGroupHeader = (currentCustomer: CustomerModel, customers: Array<CustomerModel>, index: number) => {
		let header: string = '';

		if (this.user.Type === UserTypeConstant.Consultant) {
			const previousCustomer = customers[index - 1];

			if (currentCustomer) {
				if (!previousCustomer || currentCustomer.SalesDistrictType !== previousCustomer.SalesDistrictType) {
					header = currentCustomer.SalesDistrictType === SalesDistrictType.OwnDistrict ? this.ownDistrictHeader : this.otherDistrictsHeader;
				}
			}
		}

		return header;
	};

	getCustomerFilter = (text: string, model: CustomerModel) => {
		const filterText = text?.toLowerCase().trim().replace(',', '');

		const modelText = this.getCustomerSelectString(model)?.toLowerCase();

		return modelText.indexOf(filterText) > -1;
	}

	countUnique(iterable) {
		return new Set(iterable).size;
	}

	searchFunctionVoid = (text: string, model: CustomerModel) => {
		return false;
	}

	async onSelect(customer: CustomerModel) {
		this.customerChange.emit(customer);
	}

	async selectCountryCode(countryCode: string) {
		this.newPartnerCountryCode = countryCode;
	}

	public firstLookUpDone: boolean = false;

	async shopInfoLookup() {
		this.isDoingShopInfoLookup = true;
		this.shopInfoCustomers = await this.customerDataService.getShopInfoCustomers();
		this.countryCodes = await this.customerDataService.getCountryCodes();
		this.countries = [];

		if (this.countryCodes.length > 0) {
			i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/" + this.languageService.currentLanguage + ".json"));

			this.countryCodes.forEach(countryCode => {
				this.countries.push({ CountryCode: countryCode.CountryCode, CountryName: i18nIsoCountries.getName(countryCode.CountryCode, this.languageService.currentLanguage) });
			});

			this.newPartnerCountryCode = this.countries[0].CountryCode;
		}

		this.newCustomersOptionGroupCount = 2;

		this.shopInfoCustomers.forEach(shopInfo => {
			shopInfo.IsNewPartner = true;

			if (this.countryCodes.length > 0 && (!shopInfo.CountryCode || shopInfo.CountryCode === '')) {
				shopInfo.CountryCode = this.countryCodes[0].CountryCode;
			}
		});

		setTimeout(() => {
			this.isDoingShopInfoLookup = false;
			this.firstLookUpDone = true;
		}, 500);
	}

	async selectNewPartner() {
		let customerNo = Guid.create().toString().substring(0, 18);
		let customer = new CustomerModel(customerNo, CustomerType.OrderOnBehalf, SalesDistrictType.None,
			this.newPartnerName, this.newPartnerAddress, this.newPartnerFirstTimeDeliveryAddress, false, false, false,
			"", [], [], [], [], '', [], [], '', true, false, this.newPartnerCountryCode);
		customer.Name = this.newPartnerName;
		customer.Street = this.newPartnerAddress;
		customer.ShipToName = this.newPartnerFirstTimeDeliveryAddress;
		customer.CountryCode = this.newPartnerCountryCode;
		customer.Token = await this.customerDataService.GetCustomerToken(customer);

		this.customerChange.emit(customer);
	}

	public toogleExPartners(showEx: boolean) {
		this.showExPartners = showEx;
		this.filterCustomers(this.searchWord);
	}

	public tooglePreciousPartners(showPrecious: boolean) {
		this.showPreciousPartners = showPrecious;
		this.filterCustomers(this.searchWord);
	}

	public filterCustomers(text: string) {
		this.searchWord = text;

		if (!this.searchWord) {
			this.searchWord = '';
		}

		this.customersOwnDistrict = this.allCustomers.filter(x => x.SalesDistrictType === SalesDistrictType.OwnDistrict);

		if (this.user.IsDistributor) {
			this.customersOwnDistrict = this.allCustomers.filter(x => x.SalesDistrictType === SalesDistrictType.None);
		}

		let searchwords = this.searchWord?.toLowerCase().split(' ');

		if (searchwords.length > 0 && searchwords[0] !== '') {
			this.customersOwnDistrict = this.filterCustomersBySearch(searchwords, this.customersOwnDistrict);
		}

		if (this.user.Type === UserTypeConstant.Consultant && !this.user.IsDistributor) {
			this.customersOtherDistricts = this.allCustomers.filter(x => x.SalesDistrictType === SalesDistrictType.OtherDistricts);

			if (searchwords.length > 0 && searchwords[0] !== '') {
				this.customersOtherDistricts = this.filterCustomersBySearch(searchwords, this.customersOtherDistricts);
			}
		}

		if (!this.showExPartners) {
			this.customersOwnDistrict = this.customersOwnDistrict.filter(x => x.IsPlusPartner && !x.IsBlocked);
			this.customersOtherDistricts = this.customersOtherDistricts.filter(x => x.IsPlusPartner && !x.IsBlocked)
		}

		if (this.showPreciousPartners) {
			this.customersOwnDistrict = this.customersOwnDistrict.filter(x => x.IsPreciousPartner && !x.IsBlocked);
			this.customersOtherDistricts = this.customersOtherDistricts.filter(x => x.IsPreciousPartner && !x.IsBlocked)
		}

		if (!this.userService.NewCustomerModuleEnabled()) {
			this.customersOwnDistrict = this.customersOwnDistrict.filter(x => !x.IsBlocked);
			this.customersOtherDistricts = this.customersOtherDistricts.filter(x => !x.IsBlocked)
		}
	}

	public filterCustomersBySearch(searchwords: string[], arrayToSearchIn: CustomerModel[]): CustomerModel[] {
		let tempArrayForSearch = [];

		searchwords.forEach(searchword => {
			let firsttimerun = tempArrayForSearch.length === 0;

			if (firsttimerun) {
				tempArrayForSearch = arrayToSearchIn
			}

			tempArrayForSearch = tempArrayForSearch.filter(x =>
				this.getCustomerSelectString(x)?.toLowerCase().indexOf(searchword) > -1);
		});

		return tempArrayForSearch;
	}

	public onFocus() {
		setTimeout(() => {
			this.input.nativeElement.select();
			this.input.nativeElement.setSelectionRange(0, this.input.nativeElement.value.length);
		});
	}

	public onKeyPress(event: KeyboardEvent) {
		if (event.key == "Enter") {
			if (this.customersOwnDistrict.length) {
				this.onSelect(this.customersOwnDistrict[0]);
			}
		}
	}

}
