export class PriceResponse {
	Data: PriceModel;
	Status: Status;
}

export class PriceModel {
	ResponseItems: PriceItem[];
}

export class Status {
	statusCode: string;
	message: string;
	priceRequestJson: string;
}

export class PriceItem {

	constructor(json: any = null) {
		if (json) {
			let priceItem = JSON.parse(json)

			if (priceItem) {
				// lower case sourceId
				if (priceItem.sourceId) {
					this.sourceId = priceItem.sourceId;
					this.currencyCode = priceItem.currencyCode;
					this.retailCurrencyCode = priceItem.retailCurrencyCode;
					this.unitPrice = priceItem.unitPrice;
					this.retailUnitPrice = priceItem.retailUnitPrice;
					this.netPrice = priceItem.netPrice;
					this.RetailNetPrice = priceItem.retailNetPrice;
					this.retailNetPrice = priceItem.retailNetPrice;
					this.quantity = priceItem.quantity;
					this.discountPct = priceItem.discountPct;
					this.axError = priceItem.axError;
					this.priceFound = priceItem.priceFound;
					this.retailPriceFound = priceItem.retailPriceFound;
					this.lookupTimestamp = priceItem.lookupTimestamp;
					this.status = priceItem.status;
					this.markupAmount = priceItem.markupAmount;
					this.markupTxt = priceItem.markupTxt;
					this.isPriceStale = this.isPriceStaleCheck();
					this.quantityNoPrice = 0;
				}
				// upper case SourceId
				else if (priceItem.SourceId) {
					this.axError = priceItem.AxError;
					this.currencyCode = priceItem.CurrencyCode;
					this.discountPct = priceItem.DiscountPct;
					this.isPriceStale = this.isPriceStaleCheck();
					this.lookupTimestamp = priceItem.LookupTimestamp;
					this.markupAmount = priceItem.MarkupAmount;
					this.markupTxt = priceItem.MarkupTxt;
					this.netPrice = priceItem.NetPrice;
					this.priceFound = priceItem.PriceFound;
					this.quantity = priceItem.Quantity;
					this.quantityNoPrice = 0;
					this.retailCurrencyCode = priceItem.RetailCurrencyCode;
					this.retailNetPrice = priceItem.RetailNetPrice;
					this.RetailNetPrice = priceItem.RetailNetPrice;
					this.retailPriceFound = priceItem.RetailPriceFound;
					this.retailUnitPrice = priceItem.RetailUnitPrice;
					this.sourceId = priceItem.SourceId;
					this.status = priceItem.Status;
					this.unitPrice = priceItem.UnitPrice;
				}
				else if (priceItem.data && priceItem.data.responseItems && priceItem.data.responseItems.length > 0) {
					this.axError = priceItem.data.responseItems[0].axError;
					this.currencyCode = priceItem.data.responseItems[0].currencyCode;
					this.discountPct = priceItem.data.responseItems[0].discountPct;
					this.lookupTimestamp = priceItem.data.responseItems[0].lookupTimestamp;
					this.isPriceStale = this.isPriceStaleCheck();
					this.markupAmount = priceItem.data.responseItems[0].markupAmount;
					this.markupTxt = priceItem.data.responseItems[0].markupTxt;
					this.netPrice = priceItem.data.responseItems[0].netPrice;
					this.priceFound = priceItem.data.responseItems[0].priceFound;
					this.quantity = priceItem.data.responseItems[0].quantity;
					this.quantityNoPrice = 0;
					this.retailCurrencyCode = priceItem.data.responseItems[0].retailCurrencyCode;
					this.retailNetPrice = priceItem.data.responseItems[0].retailNetPrice;
					this.RetailNetPrice = priceItem.data.responseItems[0].retailNetPrice;
					this.retailPriceFound = priceItem.data.responseItems[0].retailPriceFound;
					this.retailUnitPrice = priceItem.data.responseItems[0].retailUnitPrice;
					this.sourceId = priceItem.data.responseItems[0].sourceId;
					this.status = priceItem.data.responseItems[0].status;
					this.unitPrice = priceItem.data.responseItems[0].unitPrice;
				}
				else {
					this.axError = priceItem.axError;
					this.currencyCode = priceItem.currencyCode;
					this.discountPct = priceItem.discountPct;
					this.isDoingPriceQuery = priceItem.isDoingPriceQuery;
					this.isPriceStale = priceItem.isPriceStale;
					this.lookupTimestamp = priceItem.lookupTimestamp;
					this.markupAmount = priceItem.markupAmount;
					this.markupTxt = priceItem.markupTxt;
					this.netPrice = priceItem.netPrice;
					this.priceFound = priceItem.priceFound;
					this.quantity = priceItem.quantity;
					this.quantityNoPrice = priceItem.quantityNoPrice;
					this.retailCurrencyCode = priceItem.retailCurrencyCode;
					this.retailNetPrice = priceItem.retailNetPrice;
					this.retailPriceFound = priceItem.retailPriceFound;
					this.retailUnitPrice = priceItem.retailUnitPrice;
					this.sourceId = priceItem.sourceId;
					this.unitPrice = priceItem.unitPrice;
				}
			}
		} else {
			this.quantity = 0;
			this.unitPrice = 0;
			this.netPrice = 0;
			this.retailUnitPrice = 0;
			this.RetailNetPrice = 0;
		}
	}

	sourceId: string;
	currencyCode: string;

	retailCurrencyCode: string;

	unitPrice: number;
	retailUnitPrice: number;
	netPrice: number;
	RetailNetPrice: number;
	retailNetPrice: number;

	quantity: number;
	quantityNoPrice: number;
	discountPct: number;
	axError: boolean;
	priceFound: boolean;
	retailPriceFound: boolean;
	status: Status;
	lookupTimestamp: Date
	isDoingPriceQuery: boolean;
	isPriceStale: boolean;
	markupAmount: number;
	markupTxt: string;

	Part: string;
	PriceFound: boolean;
	NetPrice: number;
	CurrencyCode: string;
	RetailCurrencyCode: string;


	public isPriceStaleCheck(): boolean {
		let lastPriceLookupStaleThreshold: number = 24 * 60 * 60 * 1000; // 24 hours in miliseconds
		if (this.lookupTimestamp && (new Date(this.lookupTimestamp).getTime() + lastPriceLookupStaleThreshold < Date.now())) {
			//console.debug(`Last price lookupTimestamp was stale ${this.lookupTimestamp} => updatePrice`)
			return true;
		}
		return false;
	}

}

export class PriceModelStatus {

	constructor(statusCode: string, message: string) {
		this.statusCode = statusCode;
		this.message = message;
	}

	statusCode: string;
	message: string;
	priceRequestJson: string;

}
