import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BrandModeService } from '@services/brandmode.service';

@Component({
	selector: 'cstmsr-customiser-tab-bar',
	templateUrl: 'customiser-tab-bar.component.html',
	styleUrls: ['customiser-tab-bar.component.scss']
})
export class CustomiserTabBarComponent implements OnInit {

	@Input() activeTab: string;
	@Output() activeTabChanged: EventEmitter<string> = new EventEmitter<string>();

	public preciousAvailable: boolean = false;
	public windowWidth: number;

	constructor(
		private brandModeService: BrandModeService
	) {
	}
	ngOnInit(): void {
		this.preciousAvailable = this.brandModeService.showPreciousTabs.getValue();
		this.windowWidth = window.innerWidth;

	}
	@HostListener('window:resize', ['$event'])
	sizeChange(event) {
		this.windowWidth = event.target.innerWidth;
	}

	setActiveTab(name: string) {
		if (name !== this.activeTab) {
			this.activeTab = name;

			this.activeTabChanged.emit(name);
		}
	}
}
