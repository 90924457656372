import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'ConfirmDialogComponent',
	standalone: true,
	imports: [MatDialogModule, MatButtonModule],
	templateUrl: 'confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: boolean,
		private dialogRef: MatDialogRef<ConfirmDialogComponent>
	) { }

	ok() {
		this.dialogRef.close(true);
	}

	cancel() {
		this.dialogRef.close(false);
	}

}
