import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogLevelEnum } from '@infrastructure/logLevel.enum';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { VersionType } from '@infrastructure/versionType.enum';
import * as signalR from "@microsoft/signalr";
import { GenericConfirmModalComponent } from '@modals/generic-confirm/generic-confirm.modal';
import { CustomiserNotification, NotificationState, NotificationType } from '@models/customiser-notification-model';
import { SignalrUser } from '@models/user-data-model';
import { UserRefreshModel } from '@models/user-refresh-model';
import { ModalService } from '@services/modal.service';
import { AppState } from '@shared/app-state';
import { plainToClass } from 'class-transformer';
import { Guid } from 'guid-typescript';
import moment from 'moment';
import { AppConstants } from 'src/app.constants';
import { ApplicationInsightsService } from './application-insights.service';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { LanguageService } from './language.service';
import { LoggerService } from './logger.service';
import { LoginService } from './login.service';
import { NotificationService } from './notification.service';
import { OrderOnBehalfService } from './order-on-behalf.service';
import { OrdersDataService } from './orders-data.service';
import { ServerInfoService } from './server-info.service';
import { SorterService } from './sorter.service';
import { UserService } from './user.service';
import { ModelsService } from './v2/models.service';

@Injectable({
	providedIn: 'root'
})
export class SignalRService {

	constructor(private router: Router,
		private env: EnvService,
		private modelsService: ModelsService,
		private loginService: LoginService,
		private userService: UserService,
		private notificationService: NotificationService,
		private sorterService: SorterService,
		private indexDbService: IndexDbService,
		private loggerService: LoggerService,
		private ordersDataService: OrdersDataService,
		private modalService: ModalService,
		private onBehalfService: OrderOnBehalfService,
		private languageService: LanguageService,
		private applicationInsightsService: ApplicationInsightsService,
		private appState: AppState,
		private serverInfoService: ServerInfoService
	) {
	}

	public hubConnection: signalR.HubConnection

	public baseUrl: () => Promise<string> = async () => await this.env.DataApiurl();

	public startConnection = async (initiatorType: string) => {
		let appVersion = this.env.getVersion();

		if (!appVersion || appVersion?.indexOf('#') > -1) {
			appVersion = AppConstants.version;
		}

		// this.hubConnection = new signalR.HubConnectionBuilder()
		// 	.withUrl(`${await this.baseUrl()}/userRefresh`, {
		// 		accessTokenFactory: () => this.loginService.GetToken(),
		// 		headers: { "appVersion": appVersion }
		// 	})
		// 	.withAutomaticReconnect()
		// 	.configureLogging(signalR.LogLevel.Information)
		// 	.build();

		this.hubConnection
			.start()
			.then(async () => {
				await this.logUsersVersion(initiatorType);
				this.appState.signalrConnectionId.next(this.hubConnection.connectionId);
			})
			.catch(err => console.error('Error while starting the connection: ' + err))
	}

	public closeConnection = async () => {
		if (this.hubConnection && this.hubConnection.state === "Connected") {
			this.hubConnection
				.stop()
				.then(() => {
					this.appState.signalrConnectionId.next(null);
				})
				.catch(err => {
					this.appState.signalrConnectionId.next(null);
					console.error('Error while stopping connection: ' + err)
				})
		}
	}
	public addSignalrListener = async (initiatorType: string) => {
		await this.startConnection(initiatorType);

		this.hubConnection.on('broadcastBumpVersion', async (versionType: number) => {
			this.indexDbService.clearCache(versionType)
			this.indexDbService.clearLocalVersion(versionType);
		});

		this.hubConnection.on('broadcastCacheClearing', async (versionType: number) => {
			this.indexDbService.clearCache(versionType)
			this.indexDbService.clearLocalVersion(versionType);
		});

		this.hubConnection.on('broadcastNotification', async (customiserNotification: CustomiserNotification) => {
			const currentUser = this.userService.currentUser$.value;

			if (customiserNotification.UserUuid === Guid.EMPTY || customiserNotification.UserUuid?.toLowerCase() === currentUser.Uuid?.toLowerCase()) {
				await this.notificationService.saveNotification(customiserNotification);
			}
		});

		this.hubConnection.on('broadcastOrderUpdate', async ({ connectionId, customerNo }) => {
			const isBag = this.router.url.startsWith('/bag');

			if (this.hubConnection.connectionId !== connectionId && isBag) {

				const currentUser = this.userService.currentUser$.value;
				let currentUserCustomerNo = currentUser.CustomerNo;
				const customer = this.onBehalfService.customerSnapshot();

				if (customer) {
					currentUserCustomerNo = customer.No;
				}

				if (currentUserCustomerNo === customerNo) {
					await this.ordersDataService.removeOrdersOnCustomerInStore(customerNo);

					const emitter = new EventEmitter<boolean>();
					emitter.subscribe(async () => {
						document.location.href = '/bag';
					});
					// const message = `${currentUser.Name} was shown modal with '${this.languageService.instant("BAG.YOUHAVECHANGEDTHEBAG")}'-message)`;
					const message = `Modal 'You have changed the contents...' was shown to ${currentUser.Name}`;
					let logEvent = {
						message,
						logLevel: LogLevelEnum.Warning,
						extraPropertiesToLog: {
							'userAgent': navigator.userAgent
						}
					}

					await this.loggerService.sendClientLog(logEvent);

					this.applicationInsightsService.logEvent(message);

					this.modalService.create(GenericConfirmModalComponent, {
						title: 'BAG.YOUHAVECHANGEDTHEBAG', contentPart1: 'BAG.CLICKOKTORELOADBAG', emitter
					});
				}
			}
		});

		this.hubConnection.on('broadcastUserRefresh', async (userRefreshData: UserRefreshModel) => {

			const currentUser = this.userService.currentUser$.value;

			const customer = this.onBehalfService.customerSnapshot();

			const doAssortmentAndLineStatusCheck = !customer && userRefreshData.User.Uuid?.toLowerCase() === currentUser.Uuid?.toLowerCase();

			if (!doAssortmentAndLineStatusCheck) {
			}
			else {

				this.appState.priceModuleEnabled.next(userRefreshData.User.priceModuleEnabled && userRefreshData.User.PriceSettingAllowed !== PriceSettingEnum.AllowNone)

				this.loginService.createLoginCookie(userRefreshData.token);

				if (userRefreshData.User.Active) {
					userRefreshData.User.LastVersionCheck = Date.now();

					if (currentUser.Assortments.join('|') !== userRefreshData.User.Assortments.join('|')) {
						this.indexDbService.clearCache(VersionType.Frames)
						this.indexDbService.clearLocalVersion(VersionType.Frames);
					}

					if (currentUser.ProductLinesPermissions.join('|') !== userRefreshData.User.ProductLinesPermissions.join('|')) {
						let changedProductLines = await this.getChangedProductLines(currentUser.ProductLinesPermissions, userRefreshData.User.ProductLinesPermissions);


						if (changedProductLines.length > 0) {
							let productLineAdded = await this.getProductLineChangedText(changedProductLines, "added");
							if (productLineAdded) {
								this.SendLineStatusChangeNotification(productLineAdded);
							}
						}
					}

					const user = await this.userService.getUserData();

					await this.userService.setUser(user);

				} else { // FileMaker says user is no longer active => Log user out
					await this.loginService.Logout(true);
				}
			}
		});
	}

	public async GetConnectedUsers(): Promise<Array<SignalrUser>> {
		let connectedUsers = await this.getConnectedUsers();
		connectedUsers = connectedUsers.sort(this.sorterService.sortBy(SignalrUser, "desc"));
		return connectedUsers
	}

	public async WarnAboutOtherConnectedClientsForCurrentUser(): Promise<void> {
		let connectedUsers = await this.getOtherConnectedClientsForCurrentUser();
		const currentUser = this.userService.currentUser$.value;

		if (connectedUsers.length > 1 && currentUser) {
			const message = `${currentUser.Alias} - ${currentUser.Name} (${currentUser.CustomerNo}) has multiple clients (${connectedUsers.length})`;
			console.warn(message, connectedUsers);

			var lastActivityDates: Array<string> = [];
			var userAgents: Array<string> = [];
			var ipAddresses: Array<string> = [];

			connectedUsers.forEach(connectedUser => {
				lastActivityDates.push(moment(connectedUser.LastActivity).format("MM-DD-YYYY HH:mm:ss"));
				userAgents.push(connectedUser.UserAgent);
				ipAddresses.push(connectedUser.IpAddress);
			});

			let userId = currentUser.Uuid;
			let logEvent = {
				message,
				logLevel: LogLevelEnum.Warning,
				extraPropertiesToLog: {
					'lastActivities': lastActivityDates.join("\n"),
					'userAgents': userAgents.join("\n"),
					'ipAddresses': ipAddresses.join("\n"),
				},
				eventId: userId,
				userId: `${currentUser.Name}(${currentUser.DeliveryEmail}) - ${currentUser.CustomerNo}`
			}

			await this.loggerService.sendClientLog(logEvent);

			this.applicationInsightsService.logEvent(message, {
				'lastActivities': lastActivityDates.join("\n"),
				'userAgents': userAgents.join("\n"),
				'ipAddresses': ipAddresses.join("\n"),
			});
		}
	}

	private async SendLineStatusChangeNotification(productLineText: string) {
		const currentUser = this.userService.currentUser$.value;

		const customiserNotification = new CustomiserNotification(
			Guid.create().toString(),
			currentUser.Uuid,
			this.languageService.instant("NOTIFICATIONS.BODY.CHANGES-TO-LINE-STATUS"),
			productLineText,
			NotificationState.New,
			NotificationType.CustomiserServiceMessage
		)

		await this.notificationService.saveNotification(customiserNotification);
	}

	private async logUsersVersion(initiatorType: string) {
		try {
			const currentUser = this.userService.currentUser$.value;
			let appVersion = this.env.getVersion();
			let appBuild = AppConstants.version;

			if (currentUser) {
				let message = `${currentUser.Alias} - ${currentUser.Name} (${currentUser.CustomerNo}) is using version: ${appVersion}; appBuild: ${appBuild}`;

				if (currentUser.AliasImitator !== '') {
					message = `${currentUser.AliasImitator} @$ ${message}`
				}

				let eventId = currentUser.Uuid;
				let userId = `${currentUser.Name}(${currentUser.DeliveryEmail}) - ${currentUser.CustomerNo}`;

				if (currentUser.AliasImitator !== '') {
					userId = `${currentUser.AliasImitator} @$ ${userId}`
				}

				let logEvent = {
					message: message,
					logLevel: LogLevelEnum.Information,
					extraPropertiesToLog: {
						'userAgent': navigator.userAgent,
						'initiatorType': initiatorType
					},
					eventId: eventId,
					userId: userId
				}

				await this.loggerService.sendClientLog(logEvent);
				this.applicationInsightsService.logEvent(message);
			}
		}
		catch (logerror) {
			console.error("Error in logUsersVersion", logerror);
		}
	}

	private async getProductLineChangedText(changedProductLines: ChangedProductLine[], addedOrRemoved: string = "added"): Promise<string> {
		return new Promise(async (resolve, reject) => {
			let productLineChangedText = '';

			if (changedProductLines.length > 0) {
				let counter = 1;

				let relevantChangedProductLines = changedProductLines.filter(pl => (pl.added && addedOrRemoved === "added") || (pl.removed && addedOrRemoved === "removed"));
				if (relevantChangedProductLines.length === 0) {
					return resolve(null);
				}

				for (let index = 0; index < relevantChangedProductLines.length; index++) {
					const changedProductLine = relevantChangedProductLines[index];

					let modelgroup = await this.modelsService.getModelGroupFromProductLineCode(changedProductLine.productLineName);
					if (modelgroup) {
						productLineChangedText += ' ' + modelgroup.ProductLineDescription.Fallback;
						if (counter !== relevantChangedProductLines.length) {
							productLineChangedText += ','
						}
						counter++;
					}
				}

				productLineChangedText = this.languageService.instant('PRODUCTLINES.STATUS.CHANGED', { lines: productLineChangedText });
			}

			return resolve(productLineChangedText);
		});
	}

	private async getChangedProductLines(oldProductLinesPermissions: string[], newProductLinesPermissions: string[]): Promise<ChangedProductLine[]> {
		let changedProductLines: ChangedProductLine[] = [];

		newProductLinesPermissions.forEach(newProductLine => {
			if (oldProductLinesPermissions.indexOf(newProductLine) < 0) {
				changedProductLines.push(new ChangedProductLine(newProductLine, true, false))
			}
		});

		oldProductLinesPermissions.forEach(oldProductLine => {
			if (newProductLinesPermissions.indexOf(oldProductLine) < 0) {
				changedProductLines.push(new ChangedProductLine(oldProductLine, false, true))
			}
		});

		return changedProductLines
	}

	private async getConnectedUsers(): Promise<Array<SignalrUser>> {
		let url = `${await this.env.baseUrl()}/signalr/listeners`;
		const connectedUsers = await this.serverInfoService.getNetworkFirst<Array<SignalrUser>>(url);
		return connectedUsers.map(x => plainToClass(SignalrUser, x));
	}

	private async getOtherConnectedClientsForCurrentUser(): Promise<Array<SignalrUser>> {
		let url = `${await this.env.baseUrl()}/signalr/mylisteners`;
		const connectedUsers = await this.serverInfoService.getNetworkFirst<Array<SignalrUser>>(url);
		return connectedUsers.map(x => plainToClass(SignalrUser, x));
	}
}

export class ChangedProductLine {

	public productLineName: string
	public added: boolean = false
	public removed: boolean = false

	constructor(productLineName: string, added: boolean, removed: boolean) {
		this.productLineName = productLineName;
		this.added = added;
		this.removed = removed;
	}

}
