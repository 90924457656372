<div class="flex flex-grow">
    <div class="flex-grow">
        <label [class.disabled]="disabled" [class.checked]="checked" (click)="!disabled ? toggleChecked(): null" class="flex">
            <input disabled [checked]="checked" type="checkbox" />
            <div class="checkbox-img" [class.radio-img]="radio"></div>
			<div *ngIf="icon" class="checkbox-icon" ><img [src]="icon" [class.isPrecious]="isPrecious" /></div>
            <div *ngIf="label" [innerHTML]="label"></div>
            <ng-content></ng-content>
        </label>
    </div>
    <i *ngIf="hasInfo" class="fa-light fa-circle-info" (click)="toggleInfo()"></i>
</div>
<div class="checkbox-info-content" *ngIf="showInfo" [@fadeInOutTransition]>
    <ng-content select="[info-content]"></ng-content>
</div>