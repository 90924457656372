import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigateService } from '@services/navigate.service'

@Injectable()
export class HasModelGroupDataGuard  {
    constructor(private navigateService: NavigateService, private router: Router) { }

    async canActivate() {
        if (this.navigateService.getModelGroupData()) {
            return true;
        } else {
            await this.router.navigate(['/']);
            return false;
        }
    }
}
