import { EventEmitter, Injectable } from '@angular/core';
import { MathEnum } from '@infrastructure/math-state-enum';
import { OrderItemAskForOrderIdError } from '@infrastructure/order-item-ask-for-order-id-error';
import { WhichOrderEnum } from '@infrastructure/which-order-enum';
import { StartKitsModalComponent } from '@modals/start-kits-modal/start-kits-modal.component';
import { AddOrderItemResultModel } from '@models/add-order-item-result-model';
import { OrderCreateModel } from '@models/api-models/order-create-model';
import { StartkitMapping } from '@models/api-models/startkit-mapping.interface';
import { CustomerModel } from '@models/customer';
import { OrderItemModel } from '@models/order-item';
import { OrderItemMiscModel } from '@models/order-item-misc-model';
import { OrderItemState } from '@models/order-item-state';
import { OrderIdsModel } from '@models/orderIds-model';
import { StartKitModel } from '@models/start-kit-model';
import { Subject } from 'rxjs';
import { OrderCanceledError } from '../infrastructure/order-canceled-error';
import { BagCountService } from './bag-count.service';
import { BrandModeService } from './brandmode.service';
import { DataApiService } from './data-api.service';
import { EventsService } from './events.service';
import { ModalService } from './modal.service';
import { OrderOnBehalfService } from './order-on-behalf.service';
import { OrdersDataService } from './orders-data.service';
import { QueueItemService } from './queue-item.service';
import { UserService } from './user.service';
import { StartkitMappingService } from './v2/startkit-mapping.service';

@Injectable({
	providedIn: 'root'
})
export class OrderService {

	private addToBagSubject = new Subject();

	constructor(private apiService: DataApiService,
		private queueItemService: QueueItemService,
		private events: EventsService,
		private bagCountService: BagCountService,
		private modalService: ModalService,
		private onBehalfService: OrderOnBehalfService,
		private userService: UserService,
		private orderDataService: OrdersDataService,
		private brandmodeService: BrandModeService,
		private startkitMappingService: StartkitMappingService
	) { }

	public addToBagObserveable() {
		return this.addToBagSubject.asObservable();
	}

	public async AddMiscToBag(orderItemMiscModel: OrderItemMiscModel): Promise<boolean> {
		orderItemMiscModel.CustomerNo = (await this.onBehalfService.getCustomerOrUserNo());

		const canUseNonBlockingOfflineAddToBag = this.userService.UserCanUseNonBlockingOfflineAddTobag();

		if (canUseNonBlockingOfflineAddToBag) {
			if (orderItemMiscModel.OrderId == null) {
				orderItemMiscModel.OrderId = await this.getIdOnCurrentOrder();
			}
		}
		else {
			this.events.showLoader(true);

			if (orderItemMiscModel.OrderId < 1) {
				const customer = this.onBehalfService.customerSnapshot();
				orderItemMiscModel.OrderId = await this.getOrCreateOrder(customer, orderItemMiscModel.OrderId);
			}
		}

		await this.orderDataService.createOrderMiscItem(orderItemMiscModel);
		this.bagCountService.countUpdate(orderItemMiscModel.Amount, MathEnum.Addition, false);
		this.addToBagSubject.next(true);

		this.events.showLoader(false);

		return true;
	}
	private async UpdateStartkitMapping(orderItem: OrderItemModel, skm: StartKitModel) {

		const startkitMapping: StartkitMapping = {
			CustomerNo: orderItem.getState().CustomerNo,
			Ordered: false,
			ProductLineCode: skm.ProductLineCode,
			StartkitItemNo: skm.ItemNo,
		}

		this.startkitMappingService.postStartkitMapping(startkitMapping);
	}

	public async AddFramesToOrder(
		orderItem: OrderItemModel,
		isKidTeen: boolean,
		bypassStartKitCheck: boolean = false,
		overrideStartKitModal?: (event: EventEmitter<StartKitModel>) => void
	): Promise<AddOrderItemResultModel> {
		orderItem.CustomerNo = (await this.onBehalfService.getCustomerOrUserNo());

		return new Promise<AddOrderItemResultModel>(async (resolve, reject) => {
			const customer = this.onBehalfService.customerSnapshot();
			const user = this.userService.currentUser$.value;

			try {
				this.events.showLoader(true);

				if (!orderItem.OrderId || orderItem.OrderId < 1) {
					orderItem.OrderId = await this.getOrCreateOrder(customer, orderItem.OrderId);
				}
			}
			catch (e) {
				if (e instanceof OrderItemAskForOrderIdError) {
					reject(new OrderItemAskForOrderIdError());
					return;
				}
			}

			if (customer && (customer.isConsultantOrderOnBehalf || user.IsDistributor) && !bypassStartKitCheck) {
				await this.checkForStartKitNeed(customer, orderItem, isKidTeen, overrideStartKitModal, resolve, reject);
			}
			else {
				await this.createOrderItem(orderItem, null);
				resolve(new AddOrderItemResultModel(false));
			}
		});
	}

	private async checkForStartKitNeed(
		customer: CustomerModel,
		orderItem: OrderItemModel,
		isKidTeen: boolean,
		overrideStartKitModal: (event: EventEmitter<StartKitModel>) => void,
		resolve: (value?: AddOrderItemResultModel | PromiseLike<AddOrderItemResultModel>) => void,
		reject: (reason?: any) => void
	) {
		const emitter: EventEmitter<StartKitModel> = new EventEmitter();

		emitter.subscribe(async (skm: StartKitModel) => {
			if (skm === undefined) {
				reject(new OrderCanceledError());
				emitter.unsubscribe();
				return;
			}

			await this.createOrderItem(orderItem, customer);

			if (skm) {
				await this.UpdateStartkitMapping(orderItem, skm);

			}

			emitter.unsubscribe();
			resolve(new AddOrderItemResultModel(skm !== null));
		});

		const accessToProductLine = customer.hasProductLineAccess(orderItem.getState().ProductLineCode);
		const hasOrdersWithStartkit = await this.onBehalfService.hasStartkitOnOrders(orderItem.getState().ProductLineCode);
		const queuedOrderItems: Array<OrderItemState> = await this.queueItemService.getReadyOrderItemsInQueue(orderItem.CustomerNo, false);
		const hasQueuedOrderItemsWithSameProductLine = queuedOrderItems.some(t => t.ProductLineCode === orderItem.getState().ProductLineCode && !t.IsPrecious);

		if (!accessToProductLine && !hasOrdersWithStartkit && !hasQueuedOrderItemsWithSameProductLine && this.brandmodeService.isTitanium) {
			if (overrideStartKitModal) {
				overrideStartKitModal(emitter);
			}
			else {
				const params = {
					productLineCode: orderItem.getState().ProductLineCode,
					kidTeen: isKidTeen,
					resultChange: emitter
				};

				this.modalService.create(StartKitsModalComponent, params);
			}
		}
		else {
			await this.createOrderItem(orderItem, customer);
			resolve(new AddOrderItemResultModel(false));
		}
	}

	//Obsolete
	private async getOrCreateOrder(customer: CustomerModel, whichOrderEnum: WhichOrderEnum) {
		const canOnlyTestOrder = await this.userService.UserCanOnlyPlaceTestorder(customer != null);
		const customerOrUserNo = (await this.onBehalfService.getCustomerOrUserNo());

		if (whichOrderEnum == WhichOrderEnum.CreateNewOrder) {
			const orderId = await this.orderDataService.createNewOrder(customer ? OrderCreateModel.OrderOnBehalfCreateModel(customer.No, null) : new OrderCreateModel(canOnlyTestOrder), customerOrUserNo);
			return orderId;
		}
		else {
			const ordersModel = (await this.apiService.getOrderIds(customerOrUserNo));
			const orders = ordersModel.filter(x => !x.IsLocked);

			if (orders.length > 1) {
				throw new OrderItemAskForOrderIdError();
			}
			else if (orders.length === 1) {
				return orders[0].Id;
			}
			else {
				const orderId = await this.orderDataService.createNewOrder(customer ? OrderCreateModel.OrderOnBehalfCreateModel(customer.No, null) : new OrderCreateModel(canOnlyTestOrder), customerOrUserNo);
				return orderId;
			}
		}
	}

	public async getIdOnCurrentOrder() {
		var orders: OrderIdsModel[] = [];
		orders = await this.onBehalfService.getCurrentActiveOrderIds();
		orders = orders.filter(x => !x.IsLocked);

		if (orders.length > 1) {
			throw new OrderItemAskForOrderIdError();;
		}
		else if (orders.length === 1) {
			return orders[0].Id;
		}
		else {
			return WhichOrderEnum.ChooseFirstOrder;
		}
	}

	private async createOrderItem(orderItem: OrderItemModel, customer: CustomerModel) {
		const state = orderItem.getState();
		await this.orderDataService.createOrderItem(state);

		this.bagCountService.countUpdate(orderItem.Amount, MathEnum.Addition, true);
		this.addToBagSubject.next(true);
	}
}
