import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ComponentModel } from '@models/component';
import { ComponentVariantChangeModel } from '@models/component-variant-change';
import { ConfigurationService } from '@services/configuration.service';
import { LanguageService } from '@services/language.service';
import { MenuService } from '@services/menu.service';
import { ComponentCodeConstants } from '@shared/component-constants';
import { CustomizerStateConstants } from '@shared/customizer-state-constants';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from '../../../../shared/base-customizer-component';
import { FinishModel } from '../components-variants-grouped';

@Component({
	selector: 'cstmsr-finish-temple',
	template: `    
		<customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)" [overrideTranslateName]="finishHeader">
			<div class="finishes">
				<div class="customizer-item no-pointer" 
				[ngClass]="{'is-selected': selectedFinish?.finishIndicator === finish.finishIndicator}"
				*ngFor="let finish of finishes">
					<div class="inner-column pointer pl-5" (click)="onSelected(finish)">
						<div class="image" *ngIf="getImage">
							<dcg-image class="dcg-image" [src]="getFinishImage(finish.finishIndicator)"></dcg-image>
						</div>
						<h6 class="m-0">{{finish.name}}</h6>
					</div>
					
					<ng-container *ngIf="selectedFinish?.code === finish.code && showOverlay">
						<menu-overlay
							[secondOverlayText]="secondOverlayText">
						</menu-overlay>
					</ng-container>
				</div>
				<div class="help-text" *ngIf="notCanceled && helpText" [innerHTML]="helpText"></div>
			</div>
		</customizer-component-header>
    `
})
export class FinishTempleComponent extends BaseCustomizerComponent implements OnInit {

	public designs: Array<ComponentVariantFromApiModel> = [];
	public finishes: Array<FinishModel> = [];
	public selectedFinish: FinishModel;
	public finishHeader: string = '';
	public helpText: string = null;
	public secondOverlayText: string = null;
	public editMode: boolean = false;
	public showOverlay: boolean = false;
	notCanceled: boolean = true;

	constructor(
		public events: EventsService,
		private imageService: ImageService,
		public apiService: DataApiService,
		public sorter: SorterService,
		public languageService: LanguageService,
		private menuService: MenuService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);

		this.events.errorsWereReset.subscribe(x => {
			this.notCanceled = x;
		});
	}

	async initData() {
		this.events.showLoader(true);

		this.editMode = this.state === CustomizerStateConstants.EDITORDER;

		await this.fetchData();

		this.setImageSliderByName(AngleConstants.Perspective);

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
		let selectedVariantFinishIndicator = this.getSelectedVariant.Code.substring(this.getSelectedVariant.Code.indexOf(';'), this.getSelectedVariant.Code.length);
		this.selectedFinish = this.finishes.filter(f => f.finishIndicator === selectedVariantFinishIndicator)[0];
	}

	getImage = (variant: ComponentVariantFromApiModel) => this.imageService.GetVariantImage(variant.ToComponentVariantModel(""));

	getFinishImage = (finish: string) => this.imageService.GetFinishImage(finish);

	async fetchData() {
		const currentActiveVariant = this.variants.filter(v => v.Component.Uuid === this.menu.menuComponents[0].ComponentUuids)[0];

		console.log('currentActiveVariant', currentActiveVariant);

		const components: Array<ComponentModel> = [];

		for (let menuComponent of this.menu.menuComponents) {
			console.log('menuComponent', menuComponent);

			if (this.designs.filter(f => f.Component.Uuid === menuComponent.ComponentUuids).length === 0) {
				let variantsForComponent = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, menuComponent.ComponentUuids);
				variantsForComponent = variantsForComponent.sort(this.sorter.sortBy(ComponentVariantFromApiModel));

				console.log('variantsForComponent', variantsForComponent);
				console.log('variantsForCvariantsForComponent[0].Component.Codeomponent', variantsForComponent[0].Component.Code);

				if (variantsForComponent[0] && variantsForComponent[0].Component.Code === ComponentCodeConstants.Temple) {
					const currentActiveFinishTemple = this.variants.filter(v => v.Component.Code === ComponentCodeConstants.Temple)[0];

					console.log('currentActiveFinishTemple', currentActiveFinishTemple);

					let variantIndicator = currentActiveFinishTemple.Code.substring(0, currentActiveFinishTemple.Code.indexOf(';'));

					console.log('variantIndicator', variantIndicator);

					variantsForComponent = variantsForComponent.filter(v => v.Code.indexOf(variantIndicator) > -1);

					for (let variant of variantsForComponent) {
						let name = this.languageService.instantTranslateData(variant.Description);
						if (variant.Description2 && this.languageService.instantTranslateData(variant.Description2)) {
							name = this.languageService.instantTranslateData(variant.Description2);
						}

						const finishIndicator = variant.Code.substring(variant.Code.indexOf(';'), variant.Code.length);

						if (components.filter(c => c.Id === variant.Component.Id).length === 0) {
							components.push(variant.Component);
						}

						if (finishIndicator.indexOf(';') > -1) {
							if (this.finishes.filter(f => f.finishIndicator === finishIndicator).length === 0) {
								console.log('push');

								this.finishes.push({ code: variant.Code, name: name, components: components, finishIndicator: finishIndicator })
							}
						}
					}

					const firstOrDefaultVariant = variantsForComponent.filter(d => d.Default)[0] || variantsForComponent[0];
					this.designs.push(firstOrDefaultVariant);
				}
			}
		}

		const finishIndicator = currentActiveVariant.Code.substring(currentActiveVariant.Code.indexOf(';'), currentActiveVariant.Code.length);

		this.selectedFinish = this.finishes.filter(f => f.finishIndicator === finishIndicator)[0];

		if (currentActiveVariant) {
			let indi = currentActiveVariant.Code.substring(currentActiveVariant.Code.indexOf(';'));
			this.selectedFinish = {
				code: currentActiveVariant.Code,
				name: this.languageService.instantTranslateData(currentActiveVariant.Description),
				components: null,
				finishIndicator: indi
			};
		}

		await this.RunDisablingSystem(this.designs);

		this.finishHeader = this.languageService.instant('CUSTOMIZER.COMPONENTS.FINISH.NAME');
	}

	async poc(finish: FinishModel) {
		this.helpText = '';

		let xyz: string[] = [];

		let finishesChangeModels: Array<ComponentVariantChangeModel> = [];

		// get all menus and find the "finish temple" one
		let menu = (await this.menuService.getMenu(this.modelGroup));

		let finishMenu;

		if (menu.some(m => m.Name === 'FinishTemple')) {
			finishMenu = menu.filter(m => m.Name === 'FinishTemple')[0];
		}
		else {
			if (menu.some(m => m.Name.toLocaleLowerCase().indexOf('finish') >= 0)) {
				let finishParentMenu = menu.filter(m => m.Name.toLocaleLowerCase().indexOf('finish') >= 0)[0];

				if (finishParentMenu) {
					if (finishParentMenu.SubMenus.some(sm => sm.Name === 'FinishTemple')) {
						finishMenu = finishParentMenu.SubMenus.filter(sm => sm.Name === 'FinishTemple')[0];
					}
				}
			}
		}

		if (finishMenu) {
			// loop through all related components that this menu has
			let affectedComponentNames = finishMenu.Components;
			for (let affectedComponentName of affectedComponentNames) {
				// find the code of the currently selected component variant; RIM_BASIC for temple or RIM for lower rim
				let currentVariantForComponent = this.currentVariants.find(cv => cv.Component.Code === affectedComponentName);
				let currentVariantForComponentName = currentVariantForComponent.Code.substring(0, currentVariantForComponent.Code.indexOf(';'));

				// find the corresponding component in the list of variants
				let affectedComponentVariant = this.variants.find(m => m.Component.Code === affectedComponentName);

				// get all variants of this component
				let variantsForComponent = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, affectedComponentVariant.Component.Uuid);

				if (variantsForComponent) {
					// loop through these and find one with the desired finish
					variantsForComponent.forEach(vfc => {
						// let x = this.getCurrentVariants.find(v => v.Id === vfc.Id);
						// console.log(x);

						let mdl = vfc.Code.substring(0, vfc.Code.indexOf(';'));
						let indi = vfc.Code.substring(vfc.Code.indexOf(';'));

						// if the variant code is the right one and the finish is the desired one then add the new variant to the keepers
						if (mdl === currentVariantForComponentName && indi === finish.finishIndicator) {
							xyz.push(vfc.Component.Description.toLocaleLowerCase());
							const newVariant = vfc.ToComponentVariantModelWithColors(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true, currentVariantForComponent.VariantSize ? currentVariantForComponent.VariantSize.Size : null, currentVariantForComponent.VariantColor ? currentVariantForComponent.VariantColor.Color.Code : null);
							finishesChangeModels.push(new ComponentVariantChangeModel(currentVariantForComponent, newVariant, null));
						}
					});
				}
			};
		}

		finishesChangeModels.forEach(fcm => this.updateVariant(fcm));

		if (xyz.length > 1) {
			this.helpText = 'updating finish for ' + xyz.join(', ');
		}

		return finishesChangeModels.length > 0;
	}

	async onSelected(finish: FinishModel): Promise<boolean> {
		return this.poc(finish);

		// let returnMessage = null;
		// let finishesChangeModels: Array<ComponentVariantChangeModel> = [];
		// let updateVariants = true;

		// returnMessage = `${this.languageService.instant('CUSTOMIZER.COMPONENTS.FINISH.WASSELECTED', { finish: finish.name.toLowerCase() })}:<br />`;

		// // find color menu
		// let colorMenu = (await this.dataApiService.getMenu(this.modelGroup)).filter(m => m.Name === 'Color')[0];

		// // loop through the components of the incoming finish
		// for (let component of finish.components) {
		// 	// find the linked menu and set it to have been visited
		// 	const linkedMenuItem = colorMenu?.SubMenus.filter(s => s.Components.indexOf(component.Code) > -1)[0];
		// 	this.updateMenuVisited(linkedMenuItem)

		// 	// get variants and keep the ones with the same finish as the selected
		// 	let variantsForComponent = await this.apiService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, component.Uuid);
		// 	let variantsByFinish = variantsForComponent.filter(a => a.Code.indexOf(finish.finishIndicator) > -1);

		// 	updateVariants = variantsByFinish.length > 0;

		// 	if (updateVariants) {
		// 		// get the first in the list - but why???
		// 		let variantToSelect = variantsByFinish[0];

		// 		if (variantToSelect) {
		// 			const variantCurrentlySelected = this.variants.filter(v => v.Component.Uuid === component.Uuid)[0];

		// 			if (component.Code === ComponentCodeConstants.Temple) {
		// 				let templeDesignIndicator = variantCurrentlySelected?.Code.substring(0, variantCurrentlySelected?.Code.indexOf(';'));
		// 				variantToSelect = variantsByFinish.filter(d => d.Code.indexOf(templeDesignIndicator) > -1)[0];
		// 			}

		// 			returnMessage += `&middot; ${variantToSelect?.Component.Description.toLowerCase()}<br />`;

		// 			const newVariant = variantToSelect?.ToComponentVariantModel(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true);
		// 			finishesChangeModels.push(new ComponentVariantChangeModel(variantCurrentlySelected, newVariant, variantToSelect?.VariantSizes));
		// 		}
		// 	}
		// };

		// if (updateVariants && finishesChangeModels.length > 0) {
		// 	for (let index = 0; index < finishesChangeModels.length; index++) {
		// 		const finishesChangeModel = finishesChangeModels[index];

		// 		this.updateVariant(finishesChangeModel);
		// 	};

		// 	if (finishesChangeModels.length === 1) {
		// 		returnMessage = null;
		// 	}
		// }

		// if (this.editMode) {
		// 	this.showOverlay = true;
		// 	this.secondOverlayText = this.languageService.instant('CUSTOMIZER.CHANGES.BAGUPDATED');

		// 	new Promise(resolve => setTimeout(resolve, 2500)).then(x => {
		// 		this.showOverlay = false;
		// 	});
		// }

		// this.helpText = returnMessage;

		// return updateVariants;
	}

}
