<div [ngClass]="{'inactive': getIsActive}" [title]="getSelected === 'enduser' ? 'client' : 'display'">
	<button class="button-left" [ngClass]="{'selected': getSelected === 'enduser'}" (click)="clicked('enduser')">
		<ng-container *ngIf="useShorthand">
			{{'HOME.ENDUSERDISPLAY.ENDUSERSHORT' | translate}}
		</ng-container>
		<ng-container *ngIf="!useShorthand">
			{{'HOME.ENDUSERDISPLAY.ENDUSER' | translate}}
		</ng-container>
	</button>
	<button class="button-right" [ngClass]="{'selected': getSelected === 'display'}" (click)="clicked('display')">
		<ng-container *ngIf="useShorthand">
			{{'HOME.ENDUSERDISPLAY.DISPLAYSHORT' | translate}}
		</ng-container>
		<ng-container *ngIf="!useShorthand">
			{{'HOME.ENDUSERDISPLAY.DISPLAY' | translate}}
		</ng-container>
	</button>
</div>