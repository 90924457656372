import moment from 'moment';

export class LocationCheckInModel
{
    constructor(
        public LocalTime : Date,
        public CustomerNo : string,
        public SalesPersonCode : string,
        public LatitudeCurrent : string,
        public LongitudeCurrent : string,
        public OnSide : boolean
    ){
        this.LocalTime.toJSON = () => {return moment(this.LocalTime).format("MM-DD-YYYY HH:mm:ss");}
    }

    public static createEmptyLocationModel(localTime: Date, customerNo : string, salesPersonCode : string, onSide : boolean) : LocationCheckInModel
    {
        return new LocationCheckInModel(localTime, customerNo, salesPersonCode, "", "", onSide);
    }
}