import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
	selector: "search-input",
	templateUrl: "search-input.component.html",
	styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements AfterViewInit, OnChanges, OnDestroy {

	@Input() placeholder?: string = "";
	@Input() externalSearchTerm: string;
	@Input() searchInFocusTrigger: boolean;
	@Input() searchFocused: Subject<boolean>;
	@Output() searchChange: EventEmitter<string> = new EventEmitter();
	@Output() focusLostChange: EventEmitter<FocusEvent> = new EventEmitter();

	@ViewChild('searchfield', { static: false }) searchfield: ElementRef<HTMLInputElement>;

	public internalSearch: string = "";

	private onBlur: Subject<FocusEvent> = new Subject();
	private subscriptions: Array<Subscription> = [];

	constructor() {
		this.subscriptions.push(this.onBlur.pipe(debounceTime(500)).pipe(distinctUntilChanged()).subscribe(e => {
			this.focusLostChange.emit(e);
		}));
	}

	ngAfterViewInit() {
		this.searchfield.nativeElement.onblur = (e) => {
			this.onBlur.next(e);
		};

		this.subscriptions.push(this.searchFocused.subscribe(e => {
			this.onSearchTriggered(this.searchInFocusTrigger);
		}));

		this.onSearchTriggered(this.searchInFocusTrigger);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.externalSearchTerm) {
			this.internalSearch = this.externalSearchTerm;
		}

		if (changes.searchTriggered && !changes.searchTriggered?.firstChange) {
			this.onSearchTriggered(changes.searchTriggered.currentValue);
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	public onSearchTriggered(newValue: boolean) {
		if (newValue) {
			this.searchfield.nativeElement.focus();
			this.searchfield.nativeElement.select();
		}
	}

	public catchEnter($event: any) {
		if ($event.key === 'Enter') {
			this.setSearch(this.searchfield.nativeElement.value);
		}
	}

	public setSearch(value: string) {
		this.internalSearch = value;
		this.searchChange.emit(value);
	}

}
