
<div class="hidden-xs add-misc-to-bag-div">
    <div class="form-group">
        <plus-minus [disabled]="!canAdd || isUnsyncedItem || (isOffline | async) || itemExistsInBag" 
        [itemExistsInBag]="itemExistsInBag"
        [minValue]="1" [maxValue]="(customer | async) ? null : maxQuantity" [maxLength]="4" [value]="amount" (valueChange)="updateAmount($event)"></plus-minus>
    </div>

    <button class="btn btn-primary btn-short"
            *ngIf="showAdd"
            [disabled]="!canAdd || addToBagDisabled || isUnsyncedItem || (isOffline | async)"
            (click)="addToBag()">
            {{"CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDTOBAG" | translate}}
    </button>

    <button class="addedOverlay btn-primary btn-short"
            *ngIf="showAdd && disableAddToBag"
            (click)="addToBag()"
            @fadeInOutTransition
            disabled>
            {{"CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDTOBAG" | translate}}
    </button>

    <button class="btn delete-orderitem fa-thin fa-trash"
            *ngIf="showRemove"
            [disabled]="!canAdd || ((isOffline | async) && !isUnsyncedItem)"
            (click)="remove()">
    </button>
</div>

<div class="visible-xs">
    <div class="add-misc-to-bag-div">
        <div class="form-group">
            <plus-minus [disabled]="!canAdd || isUnsyncedItem || itemExistsInBag" [itemExistsInBag]="itemExistsInBag"
             class="form-group" [minValue]="1" [maxValue]="(customer | async) ? null : maxQuantity" [maxLength]="4" [value]="amount" (valueChange)="updateAmount($event)"></plus-minus>
        </div>

        <button class="btn btn-primary btn-short"
                *ngIf="showAdd"
                (click)="addToBag()"
                [disabled]="!canAdd || addToBagDisabled || isUnsyncedItem || (isOffline | async)">
                {{"CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDTOBAG" | translate}}
        </button>

        <button class="addedOverlay-xs btn btn-primary btn-short"
                *ngIf="showAdd && disableAddToBag"
                (click)="addToBag()"
                @fadeInOutTransition
                disabled>
                {{"CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDTOBAG" | translate}}
        </button>

        <button class="btn delete-orderitem fa-thin fa-trash"
            *ngIf="showRemove"
            [disabled]="!canAdd || ((isOffline | async) && !isUnsyncedItem)"
            (click)="remove()">
        </button>
    </div>
</div>
