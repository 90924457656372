import { Type } from 'class-transformer';
import { OrderItemState } from './order-item-state';
import { OrderItemMiscState } from './order-item-misc-model';
import { OrderStatus } from './order-status';
import { OrderState } from './order-state';

export class OrderIdsModel {
    constructor(order : OrderState) {
        if (order){ // handle plainToClass which map OrderIdsModel directly without parameter
            this.Id = order.Id;
            this.AxId = order.AxId;
            this.Status = order.Status;
    
            this.OrderItems = order.OrderItems.map(oi => new OrderIdsItemModel(oi));
            this.OrderItemMiscs = order.OrderItemMiscs.map(oim => new OrderIdsItemMiscModel(oim));
        }
    }

    public Id: number;
    public Status: OrderStatus;
    public AxId: number;

    @Type(() => OrderIdsItemModel)
    public OrderItems: Array<OrderIdsItemModel> = [];

    @Type(() => OrderIdsItemMiscModel)
    public OrderItemMiscs: Array<OrderIdsItemMiscModel> = [];

    public getFullFramesAmount(): number {
        const orderItemsFullframesAmount = this.OrderItems.filter(e => e.IsFullFrame).sum(x => x.Amount);
        return orderItemsFullframesAmount;
    }

    public getMiscAmount(): number {
        const orderMiscsItemsAmount = this.OrderItemMiscs.map(x => x).sum(x => x.Amount);
        const orderItemsNotFullFrames = this.OrderItems.filter(e => !e.IsFullFrame).sum(x => x.Amount);
        return orderMiscsItemsAmount + orderItemsNotFullFrames;
    }

    public get IsLocked(): boolean {
        return this.Status === OrderStatus.Request || this.Status === OrderStatus.Completed;
    }
}

class OrderIdsItemModel {
    constructor(orderItem : OrderItemState){
        if (orderItem) { // handle plainToClass which map OrderIdsModel directly without parameter
            this.Amount = orderItem.Amount;
            this.IsFullFrame = orderItem.IsFullFrame;
        }
    }

    public Amount: number;
    public IsFullFrame: boolean;
}

class OrderIdsItemMiscModel {
    constructor(orderMiscItem : OrderItemMiscState){
        if (orderMiscItem) { // handle plainToClass which map OrderIdsModel directly without parameter
            this.Amount = orderMiscItem.Amount;
        }
    }

    public Amount: number;
}
