import { MenuConstants } from '@shared/menu-constants';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ComponentBindingModel } from 'src/_models/component-binding';
import { ComponentDependencyModel } from 'src/_models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { IDependencyCalculator } from "../dependency-calculator.interface";

export class FindNewChangeByVariantDependencyCalculator implements IDependencyCalculator {

    constructor(private getChooseableVariant: (componentUuid: string, variantUuid: string, variantSizeUuid: string) => Promise<Array<ComponentVariantFromApiModel>>) {
    }

    async getChanges(
        dependency: ComponentDependencyModel,
        bindings: Array<ComponentBindingModel>,
        variant: ComponentVariantModel,
        oppositChosenVariants: Array<ComponentVariantModel>,
        variantSelectionToCheckAgainst: Array<ComponentVariantModel>
    ): Promise<Array<ComponentVariantChangeModel>> {
        const binding = bindings.filter(x => x.ComponentId === variant.Component.Id && x.ComponentVariant && !x.VariantColor && !x.VariantSize);

        if(!binding.length || binding.some(x => x.ComponentVariantUuid === variant.Uuid)) {
            return [];
        }

        console.debug(`Finding new change by variant for variant: ${variant.Code}`);

        const possibleVariants = await this.getChooseableVariant(variant.Component.Uuid, null, null);

        const newVariant = possibleVariants.find(x => binding.some(t => t.ComponentVariantUuid === x.Uuid)) ?? possibleVariants.find(x => x.Default) ?? possibleVariants[0];

        const res = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), newVariant);

        const size = newVariant.VariantSizes.find(x => x.Size === variant.VariantSize?.Size) ?? newVariant.VariantSizes.find( x => x.Default) ?? newVariant.VariantSizes[0];
        const color = size.VariantColors.find(x => x.Color.Code === variant.VariantColor?.Color.Code) ?? size.VariantColors.find(x => x.Default) ?? size.VariantColors[0];

        res.NotSelected = false;
        const result = new ComponentVariantChangeModel(variant, res, null);
        result.NewComponentVariant.VariantColor = color.ToVariantColor();
        result.NewComponentVariant.VariantSize = size.ToVariantSizeModel();
        result.MenuTypesWithWarning.push(MenuConstants.COLOR);

        return [result];
    }
}
