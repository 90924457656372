import { Component, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { InputComponent } from '@app/shared/components/input/input.component';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import ValidationResult from '@infrastructure/validation-result';
import { DiscountModel } from '@models/discounts/discount-model';
import { UserDataModel } from '@models/user-data-model';
import { UserService } from '@services/user.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'customizer-discounts',
    templateUrl: './customizer-discounts.component.html',
    styleUrls: ['./customizer-discounts.component.scss']
})
export class CustomizerDiscountsComponent implements OnChanges {
    @Input() discount: DiscountModel;
    @Input() elemClass: Array<string>;
    @Input() widths: Array<string>;
    @Input() discountErrors: ValidationResult;
    @Input() discountsList: Array<DiscountModel> = [];
    @Input() syncState: SyncStateEnum = SyncStateEnum.SyncedWithDatabase;
    @Input() isOffline: BehaviorSubject<boolean>;

    @Output() discountChange = new EventEmitter<DiscountModel>();
    @ViewChild('discountPercentage') percentageRef: InputComponent;
    public syncStateEnum: typeof SyncStateEnum =  SyncStateEnum;

    public percentage: number;
    public user: UserDataModel;

    constructor(public userService: UserService) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.percentage = this.discount?.percentage;
        this.user = this.userService.currentUser$.getValue();        
    }

    public setPercentage(percentage: string) {
        const parsedNumber = percentage.length ? parseInt(percentage, 10) : null;
        this.percentage = parsedNumber;
        this.discount.percentage = parsedNumber;
        this.discountChange.emit(this.discount);
    }

    public setDiscount(discount: DiscountModel) {
        if(!discount || (this.discount && this.discount.key === discount.key)) {
            this.percentage = null;
            this.discountChange.emit(null);
        } else {
            discount.percentage = this.percentage;
            this.discountChange.emit(discount);
            this.percentageRef.setFocus();
        }
    }
}
