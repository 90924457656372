import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BrandModeService } from '@services/brandmode.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { UserService } from '@services/user.service';
import { ProductlineConstants } from '@shared/productline-constants';
import { combineLatest, Subscription } from 'rxjs';

@Component({
    selector: 'app-product-title',
    templateUrl: './product-title.component.html',
    styleUrls: ['./product-title.component.scss']
})
export class ProductTitleComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() code: string;
    @Input() isProductLine: boolean;
    @Input() isPrecious: boolean;

    public codes = ProductlineConstants;
    public hasProductStatus: boolean;
    private subscriptions = new Array<Subscription>();

    constructor(
        private orderOnBehalfService: OrderOnBehalfService,
        private userService: UserService,
        private brandModeService: BrandModeService
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(combineLatest([this.orderOnBehalfService.customerAsObservable(), this.userService.currentUser])
            .subscribe(([customer, user]) => {
                if (!this.isProductLine) {
                    this.hasProductStatus = true;
                } else if (customer) {
                    if (this.brandModeService.isPrecious) {
                        this.hasProductStatus = customer.IsPreciousPartner;
                    } else {
                        this.hasProductStatus = customer.ProductLinesPermissions?.some(x => x === this.code);
                    }
                } else if (user) {
                    this.hasProductStatus = user.ProductLinesPermissions?.some(x => x === this.code);
                } else {
                    this.hasProductStatus = false;
                }
            })
        );

        this.isPrecious = this.brandModeService.isPrecious;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            if (!sub.closed) {
                sub.unsubscribe();
            }
        });
    }
}
