import { Type } from 'class-transformer';
import { AllAssortmentsModel } from './all-assortments-model.interface';
import { AssortmentLineModel } from './assortment-line-model';
import { AssortmentModel } from './assortment-model';
import { AvailabilityModel } from "./availability-model";

export class SimpleVariantSizeModel extends AvailabilityModel implements AllAssortmentsModel {

	Id: number;
	Uuid: string;
	Size: string;
	Bypass: boolean;
	Description: string;
	Release: Date;
	Order: string;
	Status: number;

	@Type(() => AssortmentLineModel)
	AssortmentLines: Array<AssortmentLineModel>;

	get AllAssortments(): Array<AssortmentModel> {
		return this.AssortmentLines.map(x => x.Assortment).distinct((x, y) => x.Code == y.Code);
	}
}

export class VariantSizeModel extends SimpleVariantSizeModel {

	Default: boolean;
	AvailableForDisplay: boolean;
	ComponentCode: string;

	public static createCopy(fromModel: VariantSizeModel, componentCode: string): VariantSizeModel {
		if (fromModel) {
			const newModel = new VariantSizeModel(fromModel.Discontinued, fromModel.New);
			newModel.Id = fromModel.Id;
			newModel.Uuid = fromModel.Uuid;
			newModel.Size = fromModel.Size;
			newModel.Bypass = fromModel.Bypass;
			newModel.Description = fromModel.Description;
			newModel.Release = fromModel.Release ? new Date(fromModel.Release) : null;
			newModel.Order = fromModel.Order;
			newModel.Status = fromModel.Status;
			newModel.ComponentCode = componentCode;
			newModel.AssortmentLines = fromModel.AssortmentLines.map(x => AssortmentLineModel.createCopy(x));
			return newModel;
		}
		else {
			return null;
		}
	}

}
