import { MiscModel } from "./misc-model";
export class MiscModelViewmodel {

    constructor(miscModel: MiscModel, isDefault: boolean) {
        this.MiscModel = miscModel;
        this.Default = isDefault;
    }

    public MiscModel: MiscModel
    public Default: boolean;
}