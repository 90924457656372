
<div class="temple-length-customizer">
    <customizer-component-header [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.TEMPLE.LENGTH' | translate" [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
        <customizer-list *ngIf="sizes.length"
                          class="bordered"
                          [selectedIndex]="selectedLengthIndex"
                          (selectedChange)="onSizeSelect($event)"
                          [items]="sizes"
                          [name]="'Size'"
                          [discontinued]="'Discontinued'"
                          [editMode]="state === customizerStateEnum.EDITORDER"
                          [variantOrModelIsNew]="variantOrModelIsNew"
                          [(scrollPosition)]="menu.menuDetails.scrollPosition"
                          [image]="getVoid"
        ></customizer-list>
    </customizer-component-header>
    
    <ng-container *ngIf="model.LowTempleInclinationPossible === 1 || (brandMode === brandEnum.Titanium && model.LowTempleInclinationPossible === 2)">
        <div class="header-container">
            <customizer-component-header [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.TEMPLE.INCLINATION' | translate" [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)"></customizer-component-header>
            <icon-info (click)="showInfo =! showInfo" class="header-icon"></icon-info>
        </div>
        <customizer-list
                    class="bordered"
                    [selectedIndex]="selectedTempleInclinationIndex"
                    (selectedChange)="onInclinationSelect($event)"
                    [items]="templeInclinationList"
                    [name]="'inclination'"
                    [editMode]="state === customizerStateEnum.EDITORDER"
                    [(scrollPosition)]="menu.menuDetails.scrollPosition"
                    [image]="getVoid"
                    ></customizer-list>
        <div *ngIf="showInfo" [@fadeInOutTransition] class="spacer flex flex-column flex-center">
            <p class="temple-inclination-info-header">{{ 'CUSTOMIZER.COMPONENTS.TEMPLE.INCLINATIONINFO.STANDARD' | translate }}</p>
            <dcg-image class="illustration-image" [src]="templeInclinationStandardInfoImage()" [alt]="'CUSTOMIZER.COMPONENTS.TEMPLE.INCLINATIONINFO.STANDARD' | translate"></dcg-image>
            <p class="temple-inclination-info-header">{{ 'CUSTOMIZER.COMPONENTS.TEMPLE.INCLINATIONINFO.LOW' | translate }}</p>
            <dcg-image class="illustration-image" [src]="templeInclinationLowInfoImage()" [alt]="'CUSTOMIZER.COMPONENTS.TEMPLE.INCLINATIONINFO.LOW' | translate"></dcg-image>
        </div>
    </ng-container>
</div>