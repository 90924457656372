import { SimpleModelModel } from "@models/api-models/simple-model-model";
import { AlphabeticalTagHandler } from './alphabetical-tag-handler';
import { KidTeenTagHandler } from './kid-teen-tag-handler';
import { ReverseIntervalTagHandler } from './reverse-interval-tag-handler';
import {  AbstractTagHandler } from './tag-handler.interface';

export class SimpleModelModelTagsCompositeHandler extends AbstractTagHandler {
    constructor() {
        super();
        const kidteenHandler = new KidTeenTagHandler();
        const intervalTagHander = new ReverseIntervalTagHandler();
        const alphabeticalHandler = new AlphabeticalTagHandler();

        super.setNext(kidteenHandler);
        kidteenHandler.setNext(intervalTagHander);
        intervalTagHander.setNext(alphabeticalHandler);
    }

    public getTags(models: Array<SimpleModelModel>) {
        return super.getTags(models);
    }
}
