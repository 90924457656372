import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ComponentModel } from '@models/component';
import { ComponentVariantChangeModel } from '@models/component-variant-change';
import { ConfigurationService } from '@services/configuration.service';
import { LanguageService } from '@services/language.service';
import { MenuService } from '@services/menu.service';
import { ComponentCodeConstants } from '@shared/component-constants';
import { CustomizerStateConstants } from '@shared/customizer-state-constants';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from '../../../../shared/base-customizer-component';
import { FinishModel } from '../components-variants-grouped';

@Component({
	template: `    
    <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)"
        [overrideTranslateName]="finishHeader">
        <div class="finishes">
            <div class="customizer-item no-pointer" 
            [ngClass]="{'is-selected': selectedFinish?.code === finish.code}"
            *ngFor="let finish of finishes">
                <div class="inner-column pointer pl-5" (click)="onSelected(finish)">
                    <div class="image" *ngIf="getImage">
                        <dcg-image class="dcg-image" [src]="getFinishImage(finish.finishIndicator)"></dcg-image>
                    </div>
                    <h6 class="m-0">{{finish.name}}</h6>
                </div>
                
                <ng-container *ngIf="selectedFinish?.code === finish.code && showOverlay">
                    <menu-overlay
                        [secondOverlayText]="secondOverlayText">
                    </menu-overlay>
                </ng-container>
            </div>
            <div class="help-text" *ngIf="helpText" [innerHTML]="helpText"></div>
        </div>
    </customizer-component-header>
    `
})
export class FinishNosepadComponent extends BaseCustomizerComponent implements OnInit {

	public designs: Array<ComponentVariantFromApiModel> = [];
	public finishes: Array<FinishModel> = [];
	public selectedFinish: FinishModel;
	public finishHeader: string = '';
	public helpText: string = null;
	public secondOverlayText: string = null;
	public editMode: boolean = false;
	public showOverlay: boolean = false;

	constructor(
		public events: EventsService,
		private imageService: ImageService,
		public apiService: DataApiService,
		public sorter: SorterService,
		public languageService: LanguageService,
		private menuService: MenuService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);
		this.editMode = this.state === CustomizerStateConstants.EDITORDER;
		await this.fetchData();
		this.setImageSliderByName(AngleConstants.Perspective);

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
		let selectedVariantFinishIndicator = this.getSelectedVariant.Code.substring(this.getSelectedVariant.Code.indexOf(';'), this.getSelectedVariant.Code.length);
		this.selectedFinish = this.finishes.filter(f => f.finishIndicator === selectedVariantFinishIndicator)[0];
	}

	getImage = (variant: ComponentVariantFromApiModel) => this.imageService.GetVariantImage(variant.ToComponentVariantModel(""));

	getFinishImage = (finish: string) => this.imageService.GetFinishImage(finish);

	async fetchData() {
		const currentActiveFinish = this.variants.filter(v => v.Component.Uuid === this.menu.menuComponents[0].ComponentUuids)[0];

		const components: Array<ComponentModel> = [];

		for (let index = 0; index < this.menu.menuComponents.length; index++) {
			const menuComponent = this.menu.menuComponents[index];

			if (this.designs.filter(f => f.Component.Uuid === menuComponent.ComponentUuids).length === 0) {
				let variantsForComponent = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, menuComponent.ComponentUuids);
				variantsForComponent = variantsForComponent.sort(this.sorter.sortBy(ComponentVariantFromApiModel));

				if (variantsForComponent[0] && variantsForComponent[0].Component.Code === ComponentCodeConstants.Nosepad) {
					const currentActiveFinishTemple = this.variants.filter(v => v.Component.Code === ComponentCodeConstants.Nosepad)[0];
					let variantIndicator = currentActiveFinishTemple.Code.substring(0, currentActiveFinishTemple.Code.indexOf(';'));
					variantsForComponent = variantsForComponent.filter(v => v.Code.indexOf(variantIndicator) > -1);

					variantsForComponent.forEach(variant => {
						let name = this.languageService.instantTranslateData(variant.Description);

						if (variant.Description2 && this.languageService.instantTranslateData(variant.Description2)) {
							name = this.languageService.instantTranslateData(variant.Description2);
						}

						const finishIndicator = variant.Code.substring(variant.Code.indexOf(';'), variant.Code.length);

						if (components.filter(c => c.Id === variant.Component.Id).length === 0) {
							components.push(variant.Component);
						}

						if (finishIndicator.indexOf(';') > -1) {
							if (this.finishes.filter(f => f.finishIndicator === finishIndicator).length === 0) {
								this.finishes.push({ code: variant.Code, name: name, components: components, finishIndicator: finishIndicator })
							}
						}
					});

					const firstOrDefaultVariant = variantsForComponent.filter(d => d.Default)[0] || variantsForComponent[0];
					this.designs.push(firstOrDefaultVariant);
				}
			}
		}

		const finishIndicator = currentActiveFinish.Code.substring(currentActiveFinish.Code.indexOf(';'), currentActiveFinish.Code.length);

		this.selectedFinish = this.finishes.filter(f => f.finishIndicator === finishIndicator)[0];

		if (currentActiveFinish) {
			this.selectedFinish = {
				code: currentActiveFinish.Code,
				name: this.languageService.instantTranslateData(currentActiveFinish.Description),
				components: null,
				finishIndicator: null
			};
		}

		await this.RunDisablingSystem(this.designs);

		this.finishHeader = this.languageService.instant('CUSTOMIZER.COMPONENTS.FINISH.NAME');
	}

	async onSelected(finish: FinishModel): Promise<boolean> {
		let returnMessage = null;
		let finishesChangeModels: Array<ComponentVariantChangeModel> = [];
		let updateVariants = true;

		returnMessage = `${this.languageService.instant('CUSTOMIZER.COMPONENTS.FINISH.WASSELECTED', { finish: finish.name.toLowerCase() })}:<br />`;

		let colorMenu = (await this.menuService.getMenu(this.modelGroup)).filter(m => m.Name === 'Color')[0];

		for (let index = 0; index < finish.components.length; index++) {
			const component = finish.components[index];

			const linkedMenuItem = colorMenu?.SubMenus.filter(s => s.Components.indexOf(component.Code) > -1)[0];

			if (linkedMenuItem) {
				this.updateMenuVisited(linkedMenuItem);
			}

			let variantsForComponent = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, component.Uuid);
			let variantsByFinish = variantsForComponent.filter(a => a.Code.indexOf(finish.finishIndicator) > -1);

			updateVariants = variantsByFinish.length > 0;

			if (updateVariants) {
				let variantToSelect = variantsByFinish[0];
				const variantCurrentlySelected = this.variants.filter(v => v.Component.Uuid === component.Uuid)[0];

				if (component.Code === ComponentCodeConstants.Temple) {
					let templeDesignIndicator = variantCurrentlySelected.Code.substring(0, variantCurrentlySelected.Code.indexOf(';'));
					variantToSelect = variantsByFinish.filter(d => d.Code.indexOf(templeDesignIndicator) > -1)[0];
				}

				returnMessage += `&middot; ${variantToSelect.Component.Description.toLowerCase()}<br />`;

				const newVariant = variantToSelect.ToComponentVariantModel(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true);
				finishesChangeModels.push(new ComponentVariantChangeModel(variantCurrentlySelected, newVariant, variantToSelect.VariantSizes));
			}
		};

		if (updateVariants && finishesChangeModels.length > 0) {
			for (let index = 0; index < finishesChangeModels.length; index++) {
				const finishesChangeModel = finishesChangeModels[index];

				this.updateVariantDesign(finishesChangeModel);
			};

			if (finishesChangeModels.length === 1) {
				returnMessage = null;
			}
		}

		if (this.editMode) {
			this.showOverlay = true;
			this.secondOverlayText = this.languageService.instant('CUSTOMIZER.CHANGES.BAGUPDATED');

			new Promise(resolve => setTimeout(resolve, 2500)).then(x => {
				this.showOverlay = false;
			});
		}

		this.helpText = returnMessage;

		return updateVariants;
	}

}
