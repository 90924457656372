<div class="summary-container spacious" *ngIf="compactMode === 'default' && brand === brandEnum.Titanium">
	<div id="hasRim" *ngIf="!model.IsRimless">
		<div class="font-medium">{{model.Code}}</div>
		<div [innerHTML]="modelSize.Code | replaceHash"></div>
	</div>
	<div id="isRimless" *ngIf="model.IsRimless">
		<div *ngIf="!isDemo">{{'ENDUSER.GARNITURE'|translate}}</div>
		<div *ngIf="isDemo" class="font-medium">{{model.Code}}</div>
		<div *ngIf="isDemo" [innerHTML]="modelSize.Hbox"></div>
	</div>

	<div class="groupedComponentSummaries" *ngFor="let componentGroup of groupedComponentSummaries">
		<div *ngFor="let componentText of componentGroup">
			<span>{{componentText | translate}}</span>
		</div>
	</div>

	<div id="configuration" *ngIf="configuration">
		<span>{{configuration}}</span>
		<ng-container *ngFor="let item of variantColorsToShow">
			<app-combined-color-name *ngIf="item.VariantColor" [color]="item.VariantColor.Color">
			</app-combined-color-name>
		</ng-container>
	</div>

	<div id="variantColorsToShow" *ngIf="!configuration && variantColorsToShow.length > 0">
		<ng-container *ngFor="let item of variantColorsToShow">
			<app-combined-color-name *ngIf="item.VariantColor" [color]="item.VariantColor.Color" [variantColor]="item.VariantColor"></app-combined-color-name>
		</ng-container>
	</div>
</div>

<!-- PRECIOUS -->
<ng-container *ngIf="compactMode === 'default' && brand === brandEnum.Precious">
	<!-- COMPACT/SUMMARY -->
	<div *ngIf="!showSummaryTable" class="summary-container spacious">
		<div id="hasRim" *ngIf="!model.IsRimless">
			<div class="font-medium">{{model.Code}}</div>
			<div [innerHTML]="modelSize.Code | replaceHash"></div>
		</div>
		<div id="isRimless" *ngIf="model.IsRimless">
			<div *ngIf="!isDemo">{{'ENDUSER.GARNITURE'|translate}}</div>
			<div *ngIf="isDemo" class="font-medium">{{model.Code}}</div>
			<div *ngIf="isDemo" [innerHTML]="modelSize.Hbox"></div>
		</div>

		<div class="groupedComponentSummaries" *ngFor="let componentGroup of groupedComponentSummaries">
			<div *ngFor="let componentText of componentGroup">
				<span>{{componentText | translate}}</span>
			</div>
		</div>

		<div id="configuration" *ngIf="configuration">
			<span>{{configuration}}</span>
			<ng-container *ngFor="let item of variantColorsToShow">
				<app-combined-color-name *ngIf="item.VariantColor" [color]="item.VariantColor.Color">
				</app-combined-color-name>
			</ng-container>
		</div>

		<div id="variantColorsToShow" *ngIf="!configuration && variantColorsToShow.length > 0">
			<ng-container *ngFor="let item of variantColorsToShow">
				<app-combined-color-name *ngIf="item.VariantColor" [color]="item.VariantColor.Color" [variantColor]="item.VariantColor"></app-combined-color-name>
			</ng-container>
		</div>

	</div>

	<!-- TABLE/DETAILS -->
	<div *ngIf="showSummaryTable" class="summary-container spacious">
		<table class="table m-5">
			<tr>
				<th>
					<div class="model-size mb-0">
						<ng-container *ngIf="!model.IsRimless">
							<span class="pr-2">{{model.Code}}</span>
							<span class="" [innerHTML]="modelSize.Code | replaceHash"></span>
						</ng-container>
						<ng-container *ngIf="model.IsRimless">
							<span *ngIf="!isDemo">{{'ENDUSER.GARNITURE'|translate}}</span>
							<span *ngIf="isDemo" class="pr-2">{{model.Code}}</span>
							<span *ngIf="isDemo" [innerHTML]="modelSize.Hbox"></span>
						</ng-container>
					</div>
				</th>
				<th>{{'COMMON.DESCRIPTION'|translate}}</th>
				<th>{{'COMMON.SIZE'|translate}}</th>
				<th>{{'COMMON.COLOR'|translate}}</th>
			</tr>
			<tr *ngFor="let variant of variantsToShow">
				<!-- NAME COLUMN -->
				<td class="component-name" (click)="logVariantInfo(variant)">
					{{'CUSTOMIZER.COMPONENTS.' + variant.ComponentCode + '.NAME' | translate}}
				</td>

				<!-- DESCRIPTION -->
				<td>
					<ng-container *ngIf="!variant.ComponentVariantByPass">
						{{variant.ComponentDescription | dataTranslate | translate }}
						{{variant.ComponentDescription2 | dataTranslate | translate }}
					</ng-container>
					<ng-container *ngIf="variant.ComponentVariantOptional && (!variant.VariantSize)">
						{{'COMMON.NONE' | translate}}
					</ng-container>
				</td>

				<!-- SIZE COLUMN -->
				<td>
					<ng-container *ngIf="variant.ComponentVariantSizeDescription?.toLowerCase().indexOf('fixed') === -1">
						{{variant.ComponentVariantSizeDescription}}
					</ng-container>
					<ng-container *ngIf="variant.ComponentCode === 'TEMPLE'">
						({{templeInclination === 0 ? 'c' : 'l'}})
					</ng-container>
				</td>

				<!-- COLOR COLUMN -->
				<td class="component-color" title="{{variant.VariantColor?.Color.Description2 | dataTranslate | translate}}">
					<app-combined-color-name *ngIf="variant.ComponentCode !== 'BRIDGE_CLIP' && variant.VariantColor && variant.VariantColor.Color.ColorLine?.Code"
						[color]="variant.VariantColor.Color"
						[variantColor]="variant.VariantColor"
						[forceDescriptionOnly]="variant.VariantColor.Color.ColorLine.Code !== 'HORN'"
					></app-combined-color-name>

					<ng-container *ngIf="!variant.VariantColor && !variant.ComponentVariantByPass && !variant.ComponentVariantSizeBypass">
						{{'CUSTOMIZER.COMPONENTS.COLOR.NOCOLOR' | translate}}
					</ng-container>
				</td>
			</tr>
			<tr *ngIf="!isDisplay">
				<td class="component-name">{{'CUSTOMIZER.COMPONENTS.ENGRAVING.NAME' | translate}}</td>
				<td colspan="3">
					<ng-container *ngIf="engraving">
						{{engraving}}
					</ng-container>
				</td>
			</tr>
		</table>
	</div>

	<div class="text-center" *ngIf="brand === brandEnum.Precious">
		<span class="pointer view-link" *ngIf="showSummaryTable" (click)="showSummaryTable = !showSummaryTable">{{'VIEW.SUMMARY' | translate}}</span>
		<span class="pointer view-link" *ngIf="!showSummaryTable" (click)="showSummaryTable = !showSummaryTable">{{'VIEW.DETAILS' | translate}}</span>
	</div>
</ng-container>

<div class="summary-container compact" *ngIf="compactMode === 'compact' || compactMode === 'semi-compact' || compactMode === 'order-item'">
	<div>
		<ng-container *ngIf="compactMode === 'order-item'">
			<div *ngIf="!model.IsRimless">
				<span [innerHTML]="modelSize.Code | replaceHash"></span>
			</div>
			<div *ngIf="model.IsRimless">
				<span *ngIf="!isDemo">{{'ENDUSER.GARNITURE'|translate}}</span>
				<span *ngIf="isDemo" [innerHTML]="modelSize.Hbox"></span>
			</div>
		</ng-container>

		<ng-container *ngIf="compactMode === 'semi-compact'">
			<div *ngIf="!model.IsRimless">
				<span class="font-medium">{{model.Code}}</span>
			</div>
			<div *ngIf="model.IsRimless">
				<span *ngIf="!isDemo">{{'ENDUSER.GARNITURE'|translate}}</span>
				<span *ngIf="isDemo" class="font-medium">{{model.Code}}</span>
			</div>
		</ng-container>

		<ng-container *ngIf="compactMode !== 'order-item' && (!model.IsRimless || (model.IsRimless && isDemo))">
			<div [innerHTML]="modelSize.Hbox"></div>
		</ng-container>

		<ng-container *ngFor="let componentGroup of groupedComponentSummaries">
			<ng-container *ngFor="let componentText of componentGroup">
				<span>{{componentText}}</span>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="configuration">
			<span>{{configuration}}</span>
			<ng-container *ngFor="let item of variantColorsToShow">
				<span *ngIf="item.VariantColor">{{item.VariantColor.Color.Code}}</span>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="!configuration && variantColorsToShow.length > 0">
			<ng-container *ngFor="let item of variantColorsToShow">
				<span *ngIf="item.VariantColor">{{item.VariantColor.Color.Code}}</span>
			</ng-container>
		</ng-container>
	</div>
</div>