import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ComponentVariantModel } from '@models/component-variant';
import { ModelGroupModel } from '@models/model-group';
import { VariantSizeModel } from '@models/variant-size';
import { ConfigurationService } from '@services/configuration.service';
import { LanguageService } from '@services/language.service';
import { SessionService } from '@services/session.service';
import { ComponentCodeConstants } from '@shared/component-constants';
import { ModelgroupConstants } from '@shared/modelgroup-constants';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from '../../../../shared/base-customizer-component';
import { ComponentVariantsGrouped } from '../components-variants-grouped';

@Component({
	template: `    
    <ng-container *ngIf="contentGrouping">
        <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)"
            [overrideTranslateName]="groupsHeader" [className]="'group-parents'">
            <button *ngFor="let group of groupParents; let i = index"
                class="btn btn-secondary btn-short" (click)="selectParentGroup(group)"
                [ngClass]="{'toggled': parentCurrentGroup?.Design === group.Design}">
                <i class="fa-thin fa-check mr-1" *ngIf="parentCurrentGroup?.Design === group.Design"></i>
                {{group.Design | dataTranslate | translate}}
            </button>
        </customizer-component-header>
    </ng-container>
    
    <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)"
        [overrideTranslateName]="groupsHeader">
        <div class="component-variant-groups">
            <div class="customizer-item component-variant-group no-pointer"
                [ngClass]="{'is-selected': currentGroup?.Design === group.Design}" *ngFor="let group of groups">
                <div class="inner-column pointer pl-5" (click)="selectGroup(group)">
                    <div class="image" *ngIf="getImage">
                        <dcg-image class="dcg-image" [src]="getImage(group.ComponentVariants[0])"></dcg-image>
                    </div>
                    <h6 class="m-0">{{group.Design}}</h6>
                </div>
            </div>
        </div>
    </customizer-component-header>

        <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)" *ngIf="currentGroup"
            [overrideTranslateName]="currentGroupHeader">
            <div class="component-variant-current-group mt-4">
                <select-box *ngIf="currentGroup.ComponentVariants.length"
                            [editMode]="state === customizerStateEnum.EDITORDER"
                            [optionsImage]="getImage"
                            [options]="currentGroup.ComponentVariants"
                            optionsText="Description"
                            optionsId="Id"
                            [selected]="getSelectedVariant"
                            (selectedChange)="onSelected($event)"
                            [optionsDiscontinued]="'Discontinued'"
                            (matchColors)="matchColors(null)"
                            [colorMatchMode]="true"
                            [colorsAvailableForMatching]="allPossibleColorsOnOtherSelectedVariants"
                            [currentColor]="getSelectedVariant.VariantColor"
                            [variantOrModelIsNew]="modelIsNew"
                            [matchColor]="menu.matchColor"
                            >
                </select-box>
            </div>
        </customizer-component-header>
    `,
	//styleUrls: ['./component-variant-group.scss']
})
export class ComponentVariantGroupComponent extends BaseCustomizerComponent implements OnInit {

	public designs: Array<ComponentVariantFromApiModel> = [];
	public groups: Array<ComponentVariantsGrouped> = [];
	public groupParents: Array<ComponentVariantsGrouped> = [];
	public parentCurrentGroup: ComponentVariantsGrouped;
	public currentGroup: ComponentVariantsGrouped;
	public groupsHeader: string = '';
	public currentGroupHeader: string = '';
	public contentGrouping: boolean = false;
	public sizes: Array<VariantSizeModel> = [];

	constructor(
		public events: EventsService,
		private imageService: ImageService,
		public apiService: DataApiService,
		public sorter: SorterService,
		public languageService: LanguageService,
		private sessionService: SessionService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		this.sizes = await this.fetchSizeData();
		console.warn('this.sizes', this.sizes);

		await this.fetchData();
		this.setImageSliderByName(AngleConstants.Perspective);

		//this.menu.menuDetails.expanded = true;
		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	getImage = (variant: ComponentVariantFromApiModel) => this.imageService.GetVariantImage(variant.ToComponentVariantModel(""));
	//getFinishImage = (variant: ComponentVariantFromApiModel) => this.imageService.GetFinishImage(variant.ToComponentVariantModel(""));

	async fetchData() {
		this.getSelectedVariant.VariantViewed = true;

		let apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, this.getSelectedVariant.Component.Uuid);
		await this.RunDisablingSystem(apidata);
		this.designs = apidata.sort(this.sorter.sortBy(ComponentVariantFromApiModel));
		this.getSelectedVariant.VariantColorViewed = true;

		this.groupsHeader = this.languageService.instant('CUSTOMIZER.COMPONENTS.FINISH.NAME');
		this.currentGroupHeader = this.languageService.instant('CUSTOMIZER.COMPONENTS.DESIGN.NAME');
		const modelgroup = this.sessionService.get<ModelGroupModel>("modelGroup");

		if (modelgroup.Code == ModelgroupConstants.SPIRIT || modelgroup.Code == ModelgroupConstants.STRIP3P
			|| modelgroup.Code == ModelgroupConstants.TREEBUFFALO || modelgroup.Code == ModelgroupConstants.BUFFALO) {
			this.groupsHeader = this.languageService.instant('CUSTOMIZER.COMPONENTS.DESIGN.NAME');
			this.currentGroupHeader = this.languageService.instant('CUSTOMIZER.COMPONENTS.FINISH.NAME');
		}

		this.contentGrouping = this.menu.contentGrouping;
		this.setGroups(this.designs, this.contentGrouping);

		// console.warn('apidata this.variants2', this.variants);
		this.currentGroup = this.setCurrentGroup(this.getSelectedVariant);
	}

	async fetchSizeData(): Promise<Array<VariantSizeModel>> {
		const variant = this.getSelectedVariant;
		variant.VariantSizeViewed = true;

		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid);
		await this.RunDisablingSystem(apidata);

		const variantSizes = apidata[0].VariantSizes.filter(x => !x.isDisabled).sort(this.sorter.sortBy(VariantSizeModel));
		variantSizes.forEach(variantSize => {
			variantSize.ComponentCode = ComponentCodeConstants.Gemstone;
		});

		return variantSizes;
	}

	async onSelected(selected: ComponentVariantFromApiModel) {
		this.events.showLoader(true);
		const newVariant = selected.ToComponentVariantModel(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true);
		this.updateVariantDesign(new ComponentVariantChangeModel(this.getSelectedVariant, newVariant, selected.VariantSizes));
	}

	selectGroup(selected: ComponentVariantsGrouped) {
		this.events.showLoader(true);
		this.currentGroup = selected;

		const selectComponentVariant = selected.ComponentVariants[0];
		this.onSelected(selectComponentVariant);
	}

	selectParentGroup(parentGroup: ComponentVariantsGrouped) {
		if (parentGroup.Design === this.parentCurrentGroup.Design) {
			return;
		}

		this.parentCurrentGroup = parentGroup;
		this.setGroups(this.designs, this.contentGrouping, parentGroup);
	}

	setGroups(designs: Array<ComponentVariantFromApiModel>, contentGrouping: boolean, parentGroup?: ComponentVariantsGrouped) {
		let groups: Array<ComponentVariantsGrouped> = [];

		if (contentGrouping) {
			const groupedDesigns: { [key: string]: ComponentVariantFromApiModel[]; } = designs.reduce(function (r, a) {
				r[a.GroupDescription.Fallback] = r[a.GroupDescription.Fallback] || [];
				r[a.GroupDescription.Fallback].push(a);
				return r;
			}, Object.create(null));

			groups = Object.entries(groupedDesigns).map(data => {
				return new ComponentVariantsGrouped(data[0], data[1], this.menu.contentGrouping);
			})

			this.groupParents = groups;

			if (!parentGroup) {
				parentGroup = this.groupParents[0];
				this.parentCurrentGroup = parentGroup;
			}
		}

		if (parentGroup) {
			designs = designs.filter(d => d.GroupDescription.Fallback === parentGroup.Design)
		}

		//make sure to restrict designs to the ones that are defined in menu structure (ComponentVariantUuids)
		const componentVariantUuids = this.menu.menuComponents.map(c => c.ComponentVariantUuids).filter(n => n);

		if (componentVariantUuids.length > 0) {
			this.designs = this.designs.filter(d => componentVariantUuids.findIndex(c => c === d.Uuid) > 0);
		}

		const groupedDesigns: { [key: string]: ComponentVariantFromApiModel[]; } = this.designs.reduce(function (r, a) {
			r[a.Group.Fallback] = r[a.Group.Fallback] || [];
			r[a.Group.Fallback].push(a);
			return r;
		}, Object.create(null));

		this.groups = Object.entries(groupedDesigns).map(data => {
			return new ComponentVariantsGrouped(data[0], data[1], this.menu.contentGrouping);
		});

		//this.selectGroup(this.groups[0]);
	}

	setCurrentGroup(selectedVariant: ComponentVariantModel): ComponentVariantsGrouped {
		let currentGroup: ComponentVariantsGrouped;

		if (selectedVariant.Group) {
			currentGroup = this.groups.filter(g => g.Design === selectedVariant.Group.Fallback)[0];
		}

		if (!currentGroup) {
			currentGroup = this.groups[0];
		}

		return currentGroup;
	}

}
