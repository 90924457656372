import { Type } from "class-transformer";
import { BindingConstants, ColorJunctionConstants } from "../shared/dependency-constants";
import { ComponentModel } from './component';

export class ComponentDependencyModel {
    Id: number;
	ConfigurationId: number;
    Uuid: string;
    Binding: BindingConstants;
    ColorJunction: ColorJunctionConstants;

    @Type(() => ComponentModel)
    Component1: ComponentModel;

    @Type(() => ComponentModel)
    Component2: ComponentModel;
	
    IsReversible: boolean;
}
