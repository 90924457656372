export enum FeaturedGroupConstant {
    Common = 1,
    ConsultantCollection = 2,
    Favorite = 3
}

export enum ManualConfigGroupConstant {
	All = 0,
    Screenshows = 1,
    Inspiration = 2,
    Campaign = 3,
    ConsultantCollection = 4,
    Favorites = 5
}