<div class="storage page container-fluid">

    <div class="row">
        <div class="col-sm-6">
            <h4>{{'STORAGE.INSIGHTS' | translate}}
                <span *ngIf="totalSize">Total size: {{(totalSize/1000000).toFixed(2) | number}} Mb</span>
            </h4>
        </div>
        <div class="col-sm-6 text-right">
            
            <div class="mt-2" style="font-size: 0.9em;" *ngIf="totalSpace && usedSpace">
                <div class="text-right">{{'BROWSER-INFO.QUOTA.TOTAL' | translate}} : {{totalSpace.toFixed(0) | number}} Mb</div>
                <div class="text-right">{{'BROWSER-INFO.QUOTA.USED' | translate}} : {{usedSpace.toFixed(2) | number}} Mb</div>
                <div class="text-right">{{'BROWSER-INFO.QUOTA.PERCENTAGE' | translate}} : {{percentUsed.toFixed(2) | number}}%</div>
            </div>
        </div>
    </div>
    

    <div class="row">
        <div class="col-sm-12 col-md-5 col-lg-4 keys-container">
            <section>
                
                <p *ngIf="!tableContent || tableContent.length === 0">{{'STORAGE.INDEXEDDB.IS.EMPTY' | translate}}</p>
                
                <ng-container *ngFor="let table of tableContent">
                    <header>
                        <div class="col">
                            <strong>{{table.table.name}}</strong>
                            <i class="fa-solid fa-trash pointer ml-2" *ngIf="showAdminActions"
                            (click)="deleteFromStore(table.table.name, null)"></i>
                            <span *ngIf="table.table.name === 'storageTestStore' && showAdminActions">
                                <i class="fa-solid fa-plus pointer ml-5" (click)="testDb()"></i>
                            </span>
                        </div>
                    </header>
                    <div class="key-row" *ngFor="let key of table.keys">
                        <div class="col" title="{{key.name}}">
                            <a href="javascript:void(0)" [ngClass]="{'disabled': loading}"
                            class="link-to-content"
                            (click)="showContent(table.table.name, key.name)" *ngIf="key.size < 1000000">{{key.name}}</a>
                            <span *ngIf="key.size >= 1000000">{{key.name}}</span>
                        </div>
                        <div class="size-col">{{(key.size/1000 | number)}} Kb</div>
                        <div class="delete-col" *ngIf="showAdminActions">
                            <i class="fa-solid fa-trash pointer"
                            (click)="deleteFromStore(table.table.name, key.name)"></i>
                        </div>
                    </div>
                </ng-container>
              </section>

        </div>
        <div class="col-sm-12 col-md-7 col-lg-8">
            <div *ngIf="currentTableContent">
                <strong>{{currentTableContent.currentKey.name}}</strong>
                <i class="fa-solid pointer ml-2" [ngClass]="{'fa-paper-planetext-info': !dataWasSend, 'fa-check text-success': dataWasSend}"
                (click)="sendToLog(currentTableContent.table.name, currentTableContent.currentKey.name)"></i>
                <div class="p-2 json-viewer">
                    <!-- <ngx-json-viewer [json]="currentTableContent.currentKey.content"></ngx-json-viewer> -->
                </div>
            </div>
        </div>
    </div>
</div>