import { ComponentVariantFromApiModel } from "@models/api-models/component-variant-fromapi";
import { ComponentModel } from "@models/component";

export class ComponentVariantsGrouped {
    constructor(design: string, componentVariants: Array<ComponentVariantFromApiModel>, contentGrouping: boolean) {
        this.Design = design;
        this.ComponentVariants = componentVariants;
        this.ContentGrouping = contentGrouping;
    }
    Design: string;
    ContentGrouping: boolean;
    ComponentVariants: Array<ComponentVariantFromApiModel>
}
export class FinishModel {
    code: string;
    name: string;
    finishIndicator: string;
    components: Array<ComponentModel>;
}