import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SubscribingComponent } from '@infrastructure/subscribing-component';
import { StartKitModel } from '@models/start-kit-model';
import { DataApiService } from '@services/data-api.service';
import { EventsService } from '@services/events.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';

@Component({
	selector: 'app-start-kit-chooser',
	templateUrl: './start-kit-chooser.component.html',
	styleUrls: ['./start-kit-chooser.component.scss']
})
export class StartKitChooserComponent extends SubscribingComponent implements OnInit, OnChanges {

	@Input() productLineCode: string;
	@Input() kidTeen: boolean;

	@Output() resultChange = new EventEmitter<StartKitModel>();

	public startkits: Array<StartKitModel> = [];
	public isReady = false;

	constructor(
		public orderOnBehalfService: OrderOnBehalfService,
		private dataApi: DataApiService,
		private eventService: EventsService
	) {
		super();
	}

	async ngOnInit() {
		this.subscriptions.push(this.orderOnBehalfService.customerAsObservable().subscribe(async x => {
			this.startkits = await this.getStartKits(this.productLineCode, this.kidTeen);
			this.isReady = true;
		}));

		this.eventService.showLoader(false);
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (changes.productLineCode) {
			this.startkits = await this.getStartKits(this.productLineCode, this.kidTeen);
		}
	}

	public addStartkit(startkit: StartKitModel) {
		this.resultChange.next(startkit);
	}

	private async getStartKits(productLineCode: string, isKidTeen: boolean) {
		let startKitsOnProductLine = await this.dataApi.getStartKits();
		startKitsOnProductLine = startKitsOnProductLine.filter(x => x.ProductLineCode == productLineCode);

		if (isKidTeen && startKitsOnProductLine.some(x => x.KidTeen === this.kidTeen)) {
			return startKitsOnProductLine.filter(x => x.KidTeen);
		}
		else {
			return startKitsOnProductLine.filter(x => !x.KidTeen);
		}
	}

}
