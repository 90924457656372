import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppVersionModel } from '@models/version-model';
import { environment } from './../environments/environment';
import { LocalstorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root'
})

export class EnvService {
    // gets overridden from env.js by CI/CD through env.service.factory.ts
    // https://www.jvandemo.com/how-to-use-environment-variables-to-configure-your-angular-application-without-a-rebuild/

    private url: string = null;
    private imageCdnUrl: string = null;
    private imageUrl: string = null;
    private applicationInsightsKey: string = null;
    private version: string = null;
    private readonly urlName = "dataUrl";
    private readonly imageCdnUrlName = "imageCdnUrl";
    private readonly imageUrlName = "imageUrl";
    private readonly applicationInsightsKeyName = "applicationInsightsKey";
    private readonly versionKeyName = "version";

    public baseUrl: () => Promise<string> = async () => await this.DataApiurl();

    constructor(private http: HttpClient,
        private storageService: LocalstorageService
    ) {
    }


    public async DataApiurl() {
        if (this.url === null) {
            this.url = this.storageService.get<string>(this.urlName);
        }

        if (this.url === null) {
            this.url = await this.getDataUrlFromEnvFile();
            if (this.url.startsWith('http')) {
                this.storageService.set(this.urlName, this.url);
            }
        }

        const url = (environment.dataUrl as string) || this.url;
        if (!url || url.length == 0 || !url.startsWith('http')) {
            throw new Error("data api url is null or empty");
        }
        return url;
    }

    public async ImageCdnUrl() {
        if (this.imageCdnUrl === null) {
            this.imageCdnUrl = this.storageService.get<string>(this.imageCdnUrlName);
        }

        if (this.imageCdnUrl === null) {
            this.imageCdnUrl = await this.getImageCdnUrlFromEnvFile();
            if (this.imageCdnUrl.startsWith('http')) {
                this.storageService.set(this.imageCdnUrlName, this.imageCdnUrl);
            }
        }

        const imageCdnUrl = (environment.imageCDNUrl as string) || this.imageCdnUrl;
        if (!imageCdnUrl || imageCdnUrl.length == 0 || !imageCdnUrl.startsWith('http')) {
            throw new Error("data api url is null or empty");
        }
        return imageCdnUrl;
    }

    public async ImageUrl() {
        if (this.imageUrl === null) {
            this.imageUrl = this.storageService.get<string>(this.imageUrlName);
        }

        if (this.imageUrl === null) {
            this.imageUrl = await this.getImageUrlFromEnvFile();
            if (this.imageUrl.startsWith('http')) {
                this.storageService.set(this.imageUrlName, this.imageUrl);
            }
        }

        const imageUrl = (environment.imageUrl as string) || this.imageUrl;
        if (!imageUrl || imageUrl.length == 0 || !imageUrl.startsWith('http')) {
            throw new Error("data api url is null or empty");
        }
        return imageUrl;
    }

    public async ApplicationInsightsKey(): Promise<string> {
        if (this.applicationInsightsKey === null) {
            this.applicationInsightsKey = this.storageService.get<string>(this.applicationInsightsKeyName);
        }

        if (this.applicationInsightsKey === null) {
            this.applicationInsightsKey = await this.getAppInsightsKeyEnvFile();

            if (!this.applicationInsightsKey.startsWith('#')) {
                this.storageService.set(this.applicationInsightsKeyName, this.applicationInsightsKey);
            }
        }

        const insightsKey = (environment.appInsightsKey as string) || this.applicationInsightsKey;
        if (!insightsKey || insightsKey.length == 0) {
            throw new Error("application insights key is null or empty");
        }
        return insightsKey;
    }

    public async Version() {

        if (this.version === null) {
            this.version = this.storageService.get<string>(this.versionKeyName);
        }

        if (this.version === null) {
            this.version = await this.getVersionFromEnvFile();
            this.storageService.set(this.versionKeyName, this.version);
        }

        if (!this.version || this.version.length == 0) {
            throw new Error("version is null or empty");
        }
        return this.version;
    }

    public getVersion = () => this.storageService.get<string>(this.versionKeyName);

    public async ClearEnvironmentVariables(): Promise<void> {
        this.url = null;
        this.imageCdnUrl = null;
        this.imageUrl = null;
        this.applicationInsightsKey = null;
        this.version = null;
        this.storageService.clear(this.urlName);
        this.storageService.clear(this.imageCdnUrlName);
        this.storageService.clear(this.imageUrlName);
        this.storageService.clear(this.applicationInsightsKeyName);
        this.storageService.clear(this.versionKeyName);
    }

    public async UpdateVersion(freshVersion: string = null): Promise<void> {
        if (!freshVersion) {
            freshVersion = await this.getFreshVersionFromEnvFile();
        }
        await this.ClearEnvironmentVariables();

        this.version = freshVersion;
        this.storageService.set(this.versionKeyName, this.version);
    }

    public async NewerVersionExist(): Promise<AppVersionModel> {
        let freshVersion = await this.getFreshVersionFromEnvFile();

        console.warn('this.version: ', this.version, 'freshVersion: ', freshVersion, 'appbuild: ', environment.version);

        if (this.version !== freshVersion) {

            const appVersionModel = new AppVersionModel();
            appVersionModel.freshVersion = freshVersion;
            appVersionModel.version = this.version;

            return appVersionModel;

        }
        return null;
    }

    private async getDataUrlFromEnvFile(): Promise<string> {
        return (await this.http.get<{ dataUrl: string }>(this.getEnvPath()).toPromise()).dataUrl;
    }

    private async getImageCdnUrlFromEnvFile(): Promise<string> {
        return (await this.http.get<{ imageCdnUrl: string }>(this.getEnvPath()).toPromise()).imageCdnUrl;
    }


    private async getImageUrlFromEnvFile(): Promise<string> {
        return (await this.http.get<{ imageUrl: string }>(this.getEnvPath()).toPromise()).imageUrl;
    }

    private async getAppInsightsKeyEnvFile(): Promise<string> {
        return (await this.http.get<{ appInsightsKey: string }>(this.getEnvPath()).toPromise()).appInsightsKey;
    }

    private async getVersionFromEnvFile(): Promise<string> {
        return (await this.http.get<{ version: string }>(this.getEnvPath()).toPromise()).version;
    }
    private async getFreshVersionFromEnvFile(): Promise<string> {
        const ticks = new Date().getTime().toString();
        return (await this.http.get<{ version: string }>(this.getEnvPath(ticks)).toPromise()).version;
    }
    private getEnvPath(randomString: string = null) {
        let envPath = "/env.json";
        if (randomString) {
            envPath = "/env.json?v=" + randomString;
        }
        else if (environment.production) {
            if (this.version) {
                envPath = "/env.json?v=" + this.version;
            } else {
                randomString = new Date().getTime().toString();
                envPath = "/env.json?v=" + randomString;
            }
        }
        return envPath;
    }

}
