
export default class QueuedOrderItemStateChangedData {

    constructor(itemFinished: boolean, orderId: number, isMiscItem: boolean){
        this.itemFinished = itemFinished;
        this.orderId = orderId;
        this.isMiscItem = isMiscItem;
    }

    public itemFinished: boolean
    public orderId: number
    public isMiscItem: boolean
}
