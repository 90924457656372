import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Observable, Subscription } from 'rxjs';

@Component({
	selector: "notification",
	styleUrls: ["./notification.component.scss"],
	template: `
        <div [@displayAnim]="state" (click)="showModal()">
            <ng-content select="[text]"></ng-content>
        </div>
    `,
	animations: [
		trigger("displayAnim", [
			state(
				"collapsed",
				style({
					opacity: 0,
					"margin-top": "-44px"
				})
			),
			state(
				"expanded",
				style({
					opacity: 1,
				})
			),
			transition("collapsed => expanded", [
				animate(
					'0.3s'
				)
			]),
			transition("expanded => collapsed", [
				animate(
					'0.6s ease-in'
				)
			])
		])
	]
})
export class NotificationComponent implements OnInit, OnDestroy {

	@Input() notifier: Observable<any>;
	@Input() modal: any;

	public state: "collapsed" | "expanded" = "collapsed";
	private subscription: Subscription;

	constructor(
		private modalService: ModalService
	) { }

	ngOnInit() {
		this.subscription = this.notifier?.subscribe(() => {
			this.state = "expanded";
			setTimeout(() => {
				this.state = "collapsed";
			}, 3000);
		});
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	showModal() {
		if (this.modal) {
			this.modalService.create(this.modal);
		}
	}

}
