export enum ProductlineConstants {
    SUN = "SUN",
    AIR = "AIR",
    MOF = "MØF",
    ACETANIUM = "ACETANIUM",
    STRIP = "STRIP",
    NOW = "NOW",
    TT = "TT",
    HORN = "HORN",
    RIM = "RIM"
}
