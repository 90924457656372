
import { Component, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { ModelSizeModel } from 'src/_models/model-size';
import { VariantColorModel } from 'src/_models/variant-color';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ComponentCodeConstants } from '../../../../shared/component-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
    styleUrls: ['./_bridge-design-size-clip-length.scss'],
    template: `
        <div class="flex flex-1 flex-column bridge-container">
            <bridge-size
                class="bridge-size"
                [configuration]="configuration"
                [modelGroup]="modelGroup"
                [model]="model"
                [modelSize]="modelSize"
                [variants]="variants"
                [menu]="menu"
                [state]="state"
                [currentVariantConstant]="ComponentCodeConstants.Bridge"
                (setImageSliderByNameChange)="onSetImageSliderByName($event)"
                (updateSizeChange)="onUpdateSizeChange($event)"
                (updateVariantChange)="onUpdateVariantChange($event)"
                (updateComponentSizeChange)="onUpdateComponentSizeChange($event)"
                (updateVariantDesignChange)="onUpdateVariantDesignChange($event)"
            ></bridge-size>
            <bridge-design
                class="bridge-design"
                [configuration]="configuration"
                [modelGroup]="modelGroup"
                [model]="model"
                [modelSize]="modelSize"
                [variants]="variants"
                [menu]="menu"
                [state]="state"
                [currentVariantConstant]="ComponentCodeConstants.Bridge"
                (setImageSliderByNameChange)="onSetImageSliderByName($event)"
                (updateSizeChange)="onUpdateSizeChange($event)"
                (updateVariantChange)="onUpdateVariantChange($event)"
                (updateComponentSizeChange)="onUpdateComponentSizeChange($event)"
                (updateVariantDesignChange)="onUpdateVariantDesignChange($event)"
                (matchColorsChange)="onMatchColors(null)"
            ></bridge-design>
            <clip-length
                class="clip-length"
                [configuration]="configuration"
                [modelGroup]="modelGroup"
                [model]="model"
                [state]="state"
                [modelSize]="modelSize"
                [variants]="variants"
                [menu]="menu"
                [currentVariantConstant]="ComponentCodeConstants.BridgeClip"
                (onSetImageSliderByNameChange)="onSetImageSliderByName($event)"
                (updateSizeChange)="onUpdateSizeChange($event)"
                (updateVariantChange)="onUpdateVariantChange($event)"
                (updateComponentSizeChange)="onUpdateComponentSizeChange($event)"
                (updateVariantDesignChange)="onUpdateVariantDesignChange($event)"
            ></clip-length>
        </div>
    `
})
export class BridgeDesignSizeClipLengthComponent extends BaseCustomizerComponent {
    public ComponentCodeConstants = ComponentCodeConstants;

    constructor(
        public events: EventsService,
        public apiService: DataApiService,
        public configurationService: ConfigurationService
    ) {
        super(events, apiService, configurationService);
    }

    initData() {
    }

    onChanges(changes: SimpleChanges): void {
    }

    onSetImageSliderByName(e: string) {
        this.setImageSliderByName(e);
    }

    onUpdateSizeChange(e: ModelSizeModel) {
        this.updateSize(e);
    }

    onUpdateVariantChange(e: ComponentVariantChangeModel) {
        this.updateVariant(e);
    }

    onUpdateComponentSizeChange(e: ComponentVariantChangeModel) {
        this.updateComponentSize(e);
    }

    onUpdateVariantDesignChange(e: ComponentVariantChangeModel) {
        this.updateVariantDesign(e);
    }

    onMatchColors(e: VariantColorModel) {
        this.matchColors(e);
    }
}
