import { Component } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@shared/app-state';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { NavigateService } from '../_services/navigate.service';
import { BaseModal } from './base-modal';

@Component({
    selector: 'notfound-modal',
    styleUrls: ['./notfound-modal.scss'],
    template: `
        <button class="close" (click)="closeAndDestroy()">
            <i class="fa-light fa-xmark"></i>
        </button>
        <div class="error-message">
            <h3><i class="icon-info-red icon" (click)="onCancel(false)"></i>Not found</h3>
            <p>{{errorMessage}}</p>
            <br />
            <div class="text-center">
                <button (click)="onCancel()" class="btn btn-secondary">
                    home
                </button>
                <button (click)="closeAndDestroy()" class="btn btn-secondary ml-3">
                    close
                </button>
            </div>
        </div>
        <a href="https://beian.miit.gov.cn/" target="_blank" *ngIf="appState.showChineseLicenseId" class="chineseLicense">沪ICP备18032619号-1</a>
    `
})

export class NotfoundComponent extends BaseModal {
    errorMessage: string = null;
    errorMessageTechnical: string = null;
    errorKey: string = null;

    constructor(public router: Router, public navigateService: NavigateService, breadcrumbService: BreadcrumbService, public zone: NgZone, public appState: AppState) {
        super(breadcrumbService);
     }

    async onCancel(gotoFrontpage: boolean = true) {
        this.closeAndDestroy();
        if(gotoFrontpage){
            await this.router.navigate(["/"]);
            window.location.reload();
        }
    }

}
