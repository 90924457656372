import { Component, SimpleChanges } from '@angular/core';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { ModelGroupModel } from 'src/_models/model-group';
import { ModelSizeModel } from 'src/_models/model-size';
import { EventsService } from 'src/_services/events.service';
import { DataApiService } from '../../../../_services/data-api.service';
import { SorterService } from '../../../../_services/sorter.service';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	template: `
        <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
            <customizer-list *ngIf="boxList.length"
                class="bordered"
                [isModelSize]="true"
                (selectedChange)="onSelect($event)"
                [items]="boxList"
                [name]="'Code'"
                [discontinued]="'Discontinued'"
                [selectedIndex]="selectedIndex"
                [editMode]="state === customizerStateEnum.EDITORDER"
                [variantOrModelIsNew]="variantOrModelIsNew"
                [(scrollPosition)]="menu.menuDetails.scrollPosition"
                [image]="getVoid"
            ></customizer-list>
        </customizer-component-header>
    `
})
export class ModelSizeComponent extends BaseCustomizerComponent {

	public boxList: Array<ModelSizeModel> = [];
	public selectedIndex: number = 0;

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		return true;
	}

	constructor(
		public events: EventsService,
		private apiService: DataApiService,
		private sorter: SorterService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		this.boxList = await this.fetchSizes();
		this.modelSize = this.boxList.find(x => x.Uuid == this.modelSize.Uuid);
		this.selectedIndex = this.boxList.findIndex(x => x.Uuid == this.modelSize.Uuid);

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges) {
		this.selectedIndex = this.boxList.findIndex(x => x.Uuid == this.modelSize.Uuid);
	}

	onSelect(item: number) {
		this.events.showLoader(true);
		this.updateSize(this.boxList[item]);
	}

	async fetchSizes(): Promise<Array<ModelSizeModel>> {
		let modelSizes: Array<ModelSizeModel>;

		const apidata = await this.apiService.getSizes(this.model.ProductLineUuid, this.modelGroup.modelGroupDataUuid, this.model.Uuid);
		modelSizes = apidata.sort(this.sorter.sortBy(ModelSizeModel));

		return modelSizes;
	}

	getVoid = () => {
		return null;
	}

}
