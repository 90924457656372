import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { CustomerModel } from '@models/customer';
import { MiscModel } from '@models/misc-model';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { UserService } from '@services/user.service';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: "misc-buttons",
    templateUrl: 'misc-buttons.component.html',
    styleUrls: ['./misc-buttons.component.scss'],
    animations: [
        FadeInOutTransition()
    ]
})
export class MiscButtonsComponent implements OnInit, OnChanges, OnDestroy {
    @Output() removeChange: EventEmitter<void> = new EventEmitter<void>();
    @Output() amountChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() referenceChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() isOffline: BehaviorSubject<boolean>;
    @Input() showAdd: boolean = false;
    @Input() showRemove: boolean = false;
    @Input() addToBagDisabled: boolean = false;
    @Input() isUnsyncedItem: boolean = false;
    @Input() reference: string;
    @Input() amount: number = 1;
    @Input() miscItem: MiscModel;
    @Input() maxQuantity: number;
    @Input() addfunc: (ref: string, amount: number, misc: MiscModel) => Promise<boolean>;
    @Input() amountDebounce: number = 700;

    public disableAddToBag: boolean = false;
    public referenceControl = new UntypedFormControl();
    public canAdd: boolean = false;
    public itemExistsInBag: boolean = false;
    public customer: Observable<CustomerModel>;

    private subscriptions: Array<Subscription> = [];

    constructor(
        public userService: UserService,
        public onBehalfOfService: OrderOnBehalfService) {
            this.customer = onBehalfOfService.customerAsObservable();
    }

    async ngOnInit() {
        this.subscriptions.push(this.referenceControl.valueChanges.pipe(debounceTime(300)).subscribe(newValue => this.updateReference(newValue)));
        this.canAdd = await this.userService.UserHasAddToBagPermission();
        this.itemExistsInBag = this.miscItem?.ItemExistsInBag;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reference) {
            this.reference = changes.reference.currentValue;
            this.referenceControl.setValue(this.reference);
        }

        if (changes.amount) {
            this.amount = changes.amount.currentValue;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    public updateReference(reference: string) {
        this.referenceChange.emit(reference);
    }

    public async updateAmount(amount: number) {
        this.amountChange.emit(amount);
    }

    public async addToBag() {
        const success = await this.addfunc(this.reference, this.amount, this.miscItem);
        if (success) {
            await this.ShowAddedToBag();
        }
    }

    private async ShowAddedToBag() {
        this.disableAddToBag = true;

        await new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, 2000);
        });

        this.disableAddToBag = false;
    }

    public remove() {
        this.removeChange.emit();
    }
}
