import { Type } from 'class-transformer';
import { AssortmentModel } from './assortment-model';

export class AssortmentLineModel {
    public Id: number;
    @Type(() => AssortmentModel)
    public Assortment: AssortmentModel;
    public ModelGroupId: number;
    public ModelId: number;
    public ModelSizeId: number;
    public ConfigurationId: number;
    public ModelconfigId: number;
    public ComponentId: number;
    public ComponentVariantId: number;
    public ComponentSizeId: number;
    public ComponentColorId: number;
    public ColorSelectionId: number;
    public ColorId: number;

    public IsOnlyRelatedToModel() : boolean{
        return this.ModelId && !this.ModelSizeId && !this.ConfigurationId && !this.ModelconfigId && !this.ComponentId &&
                !this.ComponentVariantId && !this.ComponentSizeId && !this.ComponentColorId && !this.ColorSelectionId && !this.ColorId
    }

    static createCopy(assortmentLineModel: AssortmentLineModel) : AssortmentLineModel
    {
        const newObj = new AssortmentLineModel();
        newObj.Id = assortmentLineModel.Id;
        newObj.ModelGroupId = assortmentLineModel.ModelGroupId;
        newObj.ModelId = assortmentLineModel.ModelId;
        newObj.ModelSizeId = assortmentLineModel.ModelSizeId;
        newObj.ConfigurationId = assortmentLineModel.ConfigurationId;
        newObj.ModelconfigId = assortmentLineModel.ModelconfigId;
        newObj.ComponentId = assortmentLineModel.ComponentId;
        newObj.ComponentVariantId = assortmentLineModel.ComponentVariantId;
        newObj.ComponentSizeId = assortmentLineModel.ComponentSizeId;
        newObj.ComponentColorId = assortmentLineModel.ComponentColorId;
        newObj.ColorSelectionId = assortmentLineModel.ColorSelectionId;
        newObj.ColorId = assortmentLineModel.ColorId;
        newObj.Assortment = AssortmentModel.createCopy(assortmentLineModel.Assortment);;

        return newObj;
    }
}
