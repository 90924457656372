import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BaseModal } from '@modals/base-modal';
import { StartKitModel } from '@models/start-kit-model';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';

@Component({
  selector: 'app-start-kits-modal',
  templateUrl: './start-kits-modal.component.html',
  styleUrls: ['./start-kits-modal.component.scss']
})
export class StartKitsModalComponent extends BaseModal {
    @Output() resultChange = new EventEmitter<StartKitModel>();
    @Input() productLineCode: string;
    @Input() kidTeen: boolean;

    constructor(
        breadcrumbService: BreadcrumbService,
        public orderOnBehalfService: OrderOnBehalfService
    ) {
        super(breadcrumbService);
    }

    public setAndDestroy(startKit: StartKitModel) {
        this.resultChange.emit(startKit);
        this.closeAndDestroy();
    }
}
