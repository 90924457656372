export enum ErrorResponseConstants {
    NoDeliveryEmailFound = "NoDeliveryEmailFound",
    OrderConcurrencyChanged = "OrderConcurrencyChanged",
    OrderItemNotExistInBag = "OrderItemNotExistInBag",
    OrderItemsChanged = "OrderItemsChanged",
    IDBNotSupportedMessage = "A mutation operation was attempted on a database that did not allow mutations.",
    EdgeDBNotSupportedMessage = "indexedDB API not found. If using IE10+, make sure to run your code on a server URL (not locally). If using old Safari versions, make sure to include indexedDB polyfill.",
    OrderAlreadyCompleted = "OrderAlreadyCompleted",
    OrderInvalid = "OrderInvalid",
    OrderItemInvalid = "OrderItemInvalid",
    OpenFailedError = "OpenFailedError",
    InvalidStateError = "InvalidStateError",
    DatabaseClosedError = "DatabaseClosedError",

}
