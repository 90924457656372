import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";

export function SubmenuTransition() {
  return trigger("submenuAnim", [
    state("expand", style({
      height: "initial",
      opacity: 1,
      padding: "10px 0 10px 0",
      flex: 1,
      minHeight: "100px",
      'border-width': '1px'
    })),
    state("collapse", style({
      height: 0,
      opacity: 0,
      padding: 0,
      flex: 0,
      minHeight: 0
    })),
    transition("* => expand", [
      animate(200, keyframes([
        style({
          height: "0px",
          opacity: 0,
          padding: 0,
          margin: 0,
          flex: 0,
          minHeight: 0,
          'border-width': '1px'
        }),
        style({
          height: "initial",
          opacity: 1,
          padding: "10px 0 10px 0",
          flex: 1,
          minHeight: "100px"
        })
      ]))
    ]),
    transition("expand => collapse", [
      animate(200, keyframes([
        style({
          height: "initial",
          opacity: 1,
          padding: "10px 0 10px 0",
          flex: 1,
          minHeight: "100px"
        }),
        style({
          height: "0px",
          opacity: 0,
          padding: 0,
          flex: 1,
          minHeight: 0
        })
      ]))
    ])
  ]);
}
