import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrandEnum } from '@infrastructure/brand.enum';
import { TagFilterModalComponent } from '@modals/tag-filter/tag-filter.component';
import { BrandModeService } from '@services/brandmode.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { ManualConfigService } from '@services/manual-config.service';
import { UserService } from '@services/user.service';
import { ModelsService } from '@services/v2/models.service';
import { AppState } from '@shared/app-state';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConfigurationMode, ImageService, ImageSize } from 'src/_services/image.service';
import { LoginService } from 'src/_services/login.service';
import { TitleService } from 'src/_services/title.service';
import { AppConstants } from 'src/app.constants';
import { DisplayEnduserModalComponent } from '../../_modals/display-enduser/display-enduser.component';
import { InspirationGroupModel } from '../../_models/inspiration-group';
import { MiscModelCategory } from '../../_models/misc-model-category';
import { ModelGroupModel } from '../../_models/model-group';
import { EnduserDisplayService } from '../../_services/enduserdisplay.service';
import { ModalService } from '../../_services/modal.service';
import { NavigateService } from '../../_services/navigate.service';
import { OrderOnBehalfService } from '../../_services/order-on-behalf.service';
import { BasePage } from '../../infrastructure/base-page';
import { PermissionConstants } from '../../shared/permission-constants';
import { ProductlineConstants } from '../../shared/productline-constants';

@Component({
	selector: "home",
	templateUrl: "home.html",
	styleUrls: ['./home.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class HomePageComponent extends BasePage implements OnInit {

	public modelGroups: Array<ModelGroupModel> = [];
	public inspirationGroups: Array<InspirationGroupModel> = [];
	public miscCategories: Array<MiscModelCategory> = [];

	public userCanOrderOnBehalf: boolean = false;
	public userCanOrderOnBehalfOfChain: boolean = false;

	public ProductlineConstants = ProductlineConstants;

	public doShowAir: boolean;
	public doShowKidTeen: boolean;
	public doShowLimited: boolean = false;
	public doShowParts: boolean;

	canOrderAir: boolean;

	public enduserDisplayClickedObserveable: Observable<ConfigurationMode>;
	public displayEnduserModalComponent: any = DisplayEnduserModalComponent;

	public modelGroupsLoaded: boolean = false;
	public kidTeenLoaded: boolean = false;
	public partsHasLoaded: boolean = false;
	public limitedHasLoaded: boolean = false;
	public miscCategoriesLoaded: boolean = false;
	public inspirationGroupsLoaded: boolean = false;

	public skeletonScreenCount: number = 0;

	public modeChangedObservable: Observable<BrandEnum>;
	public modeChangedSubscription: Subscription;

	adminModeSubscription: Subscription;
	adminModeEnabled: boolean;

	mode: string;
	public isTitanium: boolean;
	public isPrecious: boolean;
	miscCats: MiscCat[] = [];

	ImageSize = ImageSize;

	constructor(
		loginService: LoginService,
		navigateService: NavigateService,
		titleService: TitleService,
		modalService: ModalService,
		private enduserDisplayService: EnduserDisplayService,
		public userService: UserService,
		public imageService: ImageService,
		public onBehalfOfService: OrderOnBehalfService,
		private modelsService: ModelsService,
		private manualConfigService: ManualConfigService,
		public brandModeService: BrandModeService,
		public googleAnalyticsService: GoogleAnalyticsService,
		public appState: AppState,
		private router: Router

	) {
		super(loginService, titleService, modalService, navigateService);
		this.titleService.setTitleFromI18N("HOME.CUSTOMIZEYOUR");
	}

	async ngOnInit() {
		this.modelGroups = [];
		this.inspirationGroups = [];
		this.miscCategories = [];

		this.mode = this.router.url.replace('/', '').toLowerCase().split('?')[0];

		this.enduserDisplayClickedObserveable = this.enduserDisplayService.enduserDisplayClickedObservable();

		const user = await this.userService.GetUser();

		this.userCanOrderOnBehalf = user.UserPermissions.some(x => x === PermissionConstants.CanOrderOnBehalf);
		this.userCanOrderOnBehalfOfChain = user.UserPermissions.some(x => x === PermissionConstants.CanOrderOnBehalfOfChainCustomer);

		this.doShowParts = user.UserPermissions.some(x => x == PermissionConstants.PartsEnabled);
		this.canOrderAir = user.UserPermissions.some(x => x == PermissionConstants.CanOrderAir);

		this.modeChangedObservable = this.brandModeService.brandModeChangedObservable();
		this.modeChangedSubscription = this.modeChangedObservable.pipe(debounceTime(100)).subscribe(x => {
			if (this.mode === 'titanium') {
				this.doShowLimited = false;
				this.loadModelContent();
				this.loadInspirationGroups();
			}
			else if (this.mode === 'precious') {
				this.loadModelContent();
				this.loadInspirationGroups();
			}
			else if (this.mode === 'inspiration') {
				this.loadInspirationGroups();
			}
			else {
				this.loadMiscCategories();
			}
		});

		this.skeletonScreenCount = 25;

		this.adminModeSubscription = this.userService.adminModeEnabled.subscribe(value => {
			this.adminModeEnabled = value;
		});

		this.isTitanium = this.brandModeService.isTitanium;
		this.isPrecious = this.brandModeService.isPrecious;

		this.handleActiveTabChanged(this.mode);
	}

	async loadModelContent() {
		await this.getModelGroups()
			.finally(() => {
				this.modelGroupsLoaded = true;

				if (this.isPrecious) {
					this.limitedHasLoaded = true;
					this.doShowLimited = true;
				}
				else {
					this.limitedHasLoaded = false;
					this.doShowLimited = false;
				}
			});

		if (this.isTitanium) {
			await this.modelsService.getKidsModelGroups().then(kidsModelGroups => {
				this.doShowKidTeen = kidsModelGroups.length > 0
			})
				.finally(() => {
					this.kidTeenLoaded = true;
				});
		}
	}

	async getModelGroups() {
		const allModelGroups = await this.modelsService.getAdultModelGroups();

		this.modelGroups = allModelGroups.filter(modelGroup => !modelGroup.ParentModelGroupId &&
			(modelGroup.Brand === BrandEnum.TitaniumAndPrecious || modelGroup.Brand === this.brandModeService.currentBrandValue())
		);
	}

	async loadMiscCategories() {
		let miscModels = await this.modelsService.getMiscModels();

		const locationCodeFilter = this.brandModeService.isPrecious ? "WEBP" : "WEB";
		miscModels = miscModels.filter(t => t.LocationCode === locationCodeFilter);

		miscModels.forEach(m => {
			let category = this.miscCats.find(mc => mc.name === m.ItemCategoryDescription);

			if (!category) {
				category = new MiscCat(m.ItemCategoryDescription)
				this.miscCats.push(category);
			}

			let group = category.groups.find(g => g.name === m.ItemGroupDescription);

			if (!group) {
				group = new MiscGroup(m.ItemGroupDescription);
				group.category = category;
				category.groups.push(group);
			}
		});

		// BAD PATTERN. Relying on string recognition. If categories changes names these elements will not be shown... Bad horsie...

		if (this.doShowParts) {
			const toolsAndParts = this.miscCats.find(c => c.name.toLowerCase() === 'tools and parts');

			if (toolsAndParts) {
				const oldPartsIndex = toolsAndParts.groups.findIndex(g => g.name === 'parts');

				if (oldPartsIndex) {
					toolsAndParts.groups.splice(oldPartsIndex, 1);
				}

				const group = new MiscGroup('parts');
				group.special = true;
				group.link = 'parts'

				toolsAndParts.groups.push(group)
			}
		}

		const other = this.miscCats.find(c => c.name.toLowerCase() === 'other');

		if (other) {
			const group = new MiscGroup('technical info');
			group.special = true;
			group.link = AppConstants.technicalInfoUrl; //'https://partner.lindberg.com/login/technical-info'

			other.groups.push(group)
		}

		this.miscCategoriesLoaded = true;
	}

	async loadInspirationGroups() {
		let allInspirationGroups = await this.manualConfigService.getInspirationGroups();

		if (allInspirationGroups) {
			this.inspirationGroups = allInspirationGroups;

			// HACK: Hardcoded filter - should be data driven; on titanium and precious tabs only show collections 
			if (this.mode !== 'inspiration') {
				this.inspirationGroups = allInspirationGroups.filter(ig => ig.Id === 4 || ig.Id === 5);
			}

			// move hidden groups to the back
			this.inspirationGroups.sort((a, b) => {
				if (a.IsHidden === b.IsHidden) {
					return 0;
				}

				if (a.IsHidden) {
					return 1;
				}

				if (b.IsHidden) {
					return -1;
				}
			});
		}

		this.inspirationGroupsLoaded = true;
	}

	async goToGroup(modelGroup: ModelGroupModel) {
		if (!this.canOrderAir && modelGroup.Code == ProductlineConstants.AIR) {
			this.doShowAir = true;

			new Promise(resolve => setTimeout(resolve, 5000)).then(x => {
				this.doShowAir = false;
			});

			return;
		}

		await this.navigateService.navigateToModelList(modelGroup);
	}

	async goToKidTeen() {
		await this.navigateService.navigateToKidTeen();
	}

	goToTechInfo() {
		this.navigateService.navigateToTechnicalInfo();
	}

	async goToMiscGroup(categoryName: string, groupName: string) {
		if (categoryName.toLowerCase() === 'tools' && groupName.toLowerCase() === 'parts') {
			await this.navigateService.navigateToParts();
		}
		else {
			await this.navigateService.navigateToMiscGroupByName(categoryName, groupName);
		}
	}

	async goToMiscGroup2(group: MiscGroup) {
		this.navigateService.navigateToMiscGroup2(group);
	}

	async goToKering() {
		await this.navigateService.navigateToKeringEyewareBrands();
	}

	async goToLimited() {
		await this.navigateService.navigateToLimited();
	}

	async goToInspiration(group: InspirationGroupModel) {
		this.brandModeService.toggleBrandMode(BrandEnum.Titanium);

		const user = await this.userService.GetUser();
		await this.googleAnalyticsService.eventEmitter('inspirations', 'engagement', `${user.Uuid} clicked ${group.Name}`, 30);

		await this.navigateService.navigateToFeatured(group);
	}

	triggerFilterModal() {
		this.modalService.create(TagFilterModalComponent, {});
	}

	getInspirationGroupImages(name: string) {
		return this.imageService.GetInspirationGroupImages(name)
	}

	getMiscCategoryImage(name: string, cat: string) {
		return this.imageService.GetMiscGroupImage(name, cat)
	}

	getPartsTileImage() {
		return this.imageService.GetPartsTileImage()
	}

	getInitialTab() {
		return this.router.url.replace('/', '').toLowerCase().split('?')[0];
	}

	async handleActiveTabChanged($event: any) {
		if ($event === 'precious') {
			if (this.brandModeService.currentBrand.getValue() !== BrandEnum.Precious) {
				this.brandModeService.toggleBrandMode(BrandEnum.Precious);
			}
		}
		else {
			if (this.brandModeService.currentBrand.getValue() !== BrandEnum.Titanium) {
				this.brandModeService.toggleBrandMode(BrandEnum.Titanium);
			}
		}

		await this.navigateService.navigate(['/' + $event], { replaceUrl: false });
	}

}

export class MiscCat {

	groups: MiscGroup[] = [];

	constructor(public name: string) {
	}

}

export class MiscGroup {

	category: MiscCat;
	special: boolean = false;
	link: string = '';

	constructor(public name: string) {
	}

}