import { EventEmitter, Injectable } from '@angular/core';
import { UserTypeConstant } from '@shared/user-type-constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigurationMode } from './image.service';
import { IEnduserDisplayService } from './interfaces/enduserdisplay.service.interface';
import { SessionService } from './session.service';
import { UserService } from './user.service';

@Injectable()
export class EnduserDisplayService implements IEnduserDisplayService {
    private enduserDisplayChanged: BehaviorSubject<ConfigurationMode>;
    private enduserDisplayClicked = new Subject<ConfigurationMode>();
    private modalOkEvent: EventEmitter<void> = new EventEmitter();
    private disabled: boolean = false;
    private readonly sessionName = "enduserdisplay";

    constructor(sessionService: SessionService, private userService: UserService) {
        this.enduserDisplayChanged = new BehaviorSubject<ConfigurationMode>(sessionService.get<ConfigurationMode>(this.sessionName) || ConfigurationMode.enduser);

        this.enduserDisplayChanged.subscribe((x) => {
            sessionService.set(this.sessionName, x);
        });

        this.userService.currentUser.subscribe(user => {
            const currentConfiguration = sessionService.get<ConfigurationMode>(this.sessionName);
            if(user && !currentConfiguration) {
                this.setConfigurationMode(!(user.Type === UserTypeConstant.Customer || user.Type === UserTypeConstant.Lab));
            }
        });

        this.modalOkEvent.subscribe(() => {
			if (!this.IsDisabled()) {
                const configurationMode = this.enduserDisplayChanged.value == ConfigurationMode.enduser ? ConfigurationMode.display : ConfigurationMode.enduser;
                this.enduserDisplayChanged.next(configurationMode);
            }

            this.enduserDisplayClicked.next(null);
        });
    }

    public enduserDisplayChangedObservable(): Observable<ConfigurationMode> {
        return this.enduserDisplayChanged.asObservable();
    }

    public enduserDisplayClickedObservable(): Observable<ConfigurationMode> {
        return this.enduserDisplayClicked.asObservable();
    }

    public toggleConfigurationMode(): void {
        this.modalOkEvent.emit();
    }

    public setConfigurationMode(demo: boolean, disabled: boolean = false): void {
        this.disabled = disabled;

        if (demo != null) {
            const configurationMode = demo ? ConfigurationMode.display : ConfigurationMode.enduser;
            this.enduserDisplayChanged.next(configurationMode);
        }
    }

    public IsDisabled(): boolean {
        if (this.disabled) {
            return true;
        }

        return false;
    }

    public IsDisplay() {
        return this.enduserDisplayChanged.value === ConfigurationMode.display;
    }

    public IsClient() {
        return this.enduserDisplayChanged.value === ConfigurationMode.enduser;
    }

}
