import { ComponentRef, ErrorHandler, Injectable, Injector, OnDestroy } from '@angular/core';
import { LogLevelEnum } from '@infrastructure/logLevel.enum';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { NotfoundComponent } from '@modals/notfound.component';
import { ErrorResponseConstants } from '@shared/error-response-constants';
import { HttpPostOfflineError } from '@shared/httppost-offline-error';
import { OnlineStatusService, OnlineStatusType } from "ngx-online-status";
import { Observable, Subject, Subscription } from 'rxjs';
import { ErrorComponent } from '../_modals/error.component';
import { OfflineCustomiserModalComponent } from '../_modals/offline-customiser-modal/offline-customiser-modal.component';
import { OfflineModalComponent } from '../_modals/offline.component';
import { HttpOfflineError } from '../shared/http-offline-error';
import { ApplicationInsightsService } from './application-insights.service';
import { EventsService } from './events.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { LoggerService } from './logger.service';
import { LoginService } from './login.service';
import { ModalService } from './modal.service';
import { OfflineService } from './offline.service';
import { UserService } from './user.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler, OnDestroy {

	private errors: Subject<any> = new Subject();
	private modal: Observable<ComponentRef<{}>>;
	private subscriptions: Array<Subscription> = [];

	constructor(
		public injector: Injector,
		public eventService: EventsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private userService: UserService,
		private applicationInsightsService: ApplicationInsightsService,
		private onlineStatusService: OnlineStatusService,
		private loggerService: LoggerService,
		private offlineService: OfflineService
	) {
		this.subscriptions.push(this.errors.subscribe(async err => {
			const error = err.rejection || err.originalError || err; // find the real error message if wrapped from promise
			this.eventService.showLoader(false);

			const user = await this.userService.GetUser();
			let uuid = '<unknown user>';

			if (user) {
				uuid = user.Uuid;
			}

			if (/Loading chunk [\d]+ failed/.test(err.message)) {
				await this.googleAnalyticsService.eventEmitter('error', 'engagement', `${uuid} encountered an error: Loading chunk failed => reload page`, 0);

				window.location.reload();
			}
			else if (error.status && (error.status === 401 || error.status === 403)) { // user is not authenticated anymore
				await this.googleAnalyticsService.eventEmitter('error', 'engagement', `${uuid} is not authenticated anymore => Logout => /login`, 0);

				const loginService = this.injector.get(LoginService);

				if (!loginService.AllowUnauthenticatedAccess()) {
					await loginService.Logout(false);
					location.href = location.origin + "/login";
					return;
				}
			}
			else if (error.status && error.status === 404) { // endpoint responded with 404
				const errorKey = error.status || '[N/A]';
				const errorMessage: string = error?.error?.Message;
				const modalService = this.injector.get(ModalService);

				if (!this.modal && !this.errorIsindexedDbRelated(error)) {
					this.modal = modalService.create(NotfoundComponent, { errorKey, errorMessage });

					this.subscriptions.push(this.modal.subscribe((ref) => {
						this.eventService.showLoader(false);

						if (ref.hostView.destroyed) {
							this.modal = null;
						}
					}));
				}

			}
			else if (error instanceof HttpPostOfflineError) {
				await this.googleAnalyticsService.eventEmitter('error', 'engagement', `${uuid} seems to offline => offlineErrorOccured`, 0);
				this.applicationInsightsService.logException(error, SeverityLevel.Error);
				this.offlineService.offlineErrorOccured.next();
			}
			else if (error instanceof HttpOfflineError || error.status === 0) {

				const modalService = this.injector.get(ModalService);

				await this.googleAnalyticsService.eventEmitter('error', 'engagement', `${uuid} seems to offline => offlineModal`, 0);
				this.applicationInsightsService.logException(error, SeverityLevel.Error);


				if (!this.modal) {
					this.eventService.showLoader(false);
					modalService.closeAll();
					//when changing customer for order-on-behalf purposes an offline modal is displayed - probably because of an abortet api call => therefore this delay 
					setTimeout(() => {
						if (this.onlineStatusService.getStatus() === OnlineStatusType.ONLINE) {
							this.modal = modalService.create(OfflineCustomiserModalComponent);
						} else {
							this.modal = modalService.create(OfflineModalComponent);
						}
						this.subscriptions.push(this.modal.subscribe((ref) => {
							if (ref.hostView.destroyed) {
								this.modal = null;
							}
						}));
					}, 500);
				}
			}
			else {
				console.error("CustomErrorHandler", error);

				// no status == app error, status == api error                
				const errorKey = error.status || '[N/A]';
				const errorMessageTechnical: string = error?.message;

				const modalService = this.injector.get(ModalService);

				if (!this.modal && !this.errorIsindexedDbRelated(error)) {
					this.modal = modalService.create(ErrorComponent, { errorKey, errorMessageTechnical });

					let userId = 'Anonymous';

					if (user) {
						userId = user.CustomerNo + ' - ' + user.Name + ' (' + user.DeliveryEmail + ')';
						if (user.AliasImitator !== '') {
							userId = `${user.AliasImitator} @$ ${userId}`
						}
						await this.googleAnalyticsService.eventEmitter('error', 'engagement', `${user.Uuid} encountered an ${errorKey ? 'app error: ' + errorKey : 'api error'} (${window.location})`, 0);
					}

					try {
						let payload = {
							message: error.message,
							extraPropertiesToLog: {
								['appIdentifier']: 'Customiser Web Application',
								['errorOrigin']: window.location.href
							},
							logLevel: LogLevelEnum.Error,
							userId: userId
						};
						this.applicationInsightsService.logException(error, SeverityLevel.Error);
						await this.loggerService.sendClientLog(payload);
					}
					catch (comError) {
						console.error("Error in sendClientException", comError);
					}

					this.subscriptions.push(
						this.modal.subscribe((ref) => {
							this.eventService.showLoader(false);

							if (ref.hostView.destroyed) {
								this.modal = null;
							}
						})
					);
				}
			}
		}));
	}

	handleError(error: any) {
		if (error.url && error.url.includes('assets/')) {
			return;
		}

		this.errors.next(error);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	private errorIsindexedDbRelated(e: any) {
		return e.name == ErrorResponseConstants.OpenFailedError || (e.inner && e.inner.name === ErrorResponseConstants.OpenFailedError) ||
			e.name == ErrorResponseConstants.InvalidStateError || (e.inner && e.inner.name === ErrorResponseConstants.InvalidStateError) ||
			e.name == ErrorResponseConstants.DatabaseClosedError || (e.inner && e.inner.name === ErrorResponseConstants.DatabaseClosedError);
	}
}
