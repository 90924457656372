<div class="row discounts-element" [ngClass]="elemClass" *ngIf="!user?.IsDistributor">
    <div class="discounts" [ngClass]="widths" *ngFor="let d of discountsList">
        <ui-button [active]="d.key === discount?.key" [block]="true" [short]="true" (click)="setDiscount(d)"
            [buttonStyle]="'secondary'" [disabled]="syncState !== syncStateEnum.SyncedWithDatabase || (isOffline | async)">
            {{d.key}}
        </ui-button>
    </div>
    <div class="discount-percentage" [ngClass]="widths">
        <ui-input #discountPercentage *ngIf="discountsList.length" [disabled]="discount === null || syncState !== syncStateEnum.SyncedWithDatabase || (isOffline | async)"
            [hasError]="!discountErrors.isValid" [type]="'number'" [value]="percentage" [min]="1" [max]="100"
            (valueChange)="setPercentage($event)">
            <ui-input-addon post [disabled]="discount === null || (isOffline | async)">%</ui-input-addon>
        </ui-input>
    </div>
</div>