<div class="modal-body">

    <button class="close" (click)="closeAndDestroy()" *ngIf="(this.preloadService.loading$|async) === false">
        <i class="fa-light fa-xmark"></i>
    </button>

    <div class="offline-tour">

        <div class="pages">
            <div id="page-{{page}}" class="page" [ngClass]="{pageVisible: currentPage === i}" *ngFor="let page of offlineTourPages; index as i">
                <div class="header">
                    <h3 class="title">{{page.headline}}</h3>
                    <div class="text">{{page.text}}</div>
                </div>
                <div class="images" *ngIf="page.imageUrls?.length">
                    <div class="offline-image" *ngFor="let img of page.imageUrls" [style.background-image]="'url(' + img + ')'">
                    </div>
                </div>
            </div>
        </div>

        <div class="progress-pager">
            <div class="progress-container">
                <div class="loading-div" *ngIf="this.preloadService.loading$|async">
                    <dcg-image class="loader" [src]="'/assets/animations/Framespinner.gif'"></dcg-image>
                    <span [innerHTML]="'LOAD-DATA.LOADING.PREFIX' | translate: {modelcode: this.preloadService.loadingText$|async}"></span>
                </div>
                <div class="success-container alert alert-success" *ngIf="(this.preloadService.loading$|async) === false && (this.preloadService.indexedDbError$|async) === false">
                    <span>{{ "LOAD-DATA.DONE" | translate }}</span>
                </div>
                <div class="error-container alert alert-error" *ngIf="(this.preloadService.loading$|async) === false && (this.preloadService.indexedDbError$|async)">
                    <span>{{ "LOAD-DATA.ERROR" | translate }}</span>
                </div>
            </div>
            <div class="pager">
                <button class="btn-secondary btn" [disabled]="!(currentPage > 0)" (click)="currentPage = currentPage-1">Previous</button>
                <button class="btn-secondary btn next" [disabled]="!(currentPage < offlineTourPages.length-1)" (click)="currentPage = currentPage+1">Next</button>
            </div>
        </div>
    </div>
</div>