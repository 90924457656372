import { MenuComponentConstants} from './menu-component-contants';

export const MenuConstants = {
    MODELSIZE: ["ModelSize"],
    CONFIGURATION: ["Configuration", "ConfigurationPlus"],
    ACCESSORIES: ["Accessories"],
    COLOR: ["BridgeColor", "ClipColor", "Color", "FrontColor", "InnerRimColor", "LowerRimColor", "TempleClipColor", "TempleColor", "LensColor", "TempleCover", "UpperRimColor", "CrossbarColor", "FrameColor", "GrooveColor", "PreciousMetal"],
    FINISH: ["TempleFinish", "BridgeFinish", "NosepadFinish"],
    SIZE: ["Temple", "TempleLength", MenuComponentConstants.BridgeDesignSizeClipLength, MenuComponentConstants.TempleClipLength],
    DESIGN: ["TempleDesign", "InnerrimDesign", "NosepadDesign"],
    CLIPON: ["ClipOn"],
    GEMSTONE: ["Gemstone"],
    ENGRAVING: ["Engraving"],
    GROOVECOLOR: [ "GrooveColor"],
    ORDEROVERVIEW: ["OrderOverview"],
    REFERENCE: ["Reference"]
};
