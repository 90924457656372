import { Component, Input } from '@angular/core';
import { EnduserDisplayService } from '@services/enduserdisplay.service';

@Component({
	selector: 'display-enduser-button',
	templateUrl: 'display-enduser-button.component.html',
	styleUrls: ['./display-enduser-button.component.scss']
})
export class DisplayEnduserButtonComponent {

	@Input() useShorthand: boolean;
	@Input() clickable: boolean = true;

	constructor(
		private enduserDisplayService: EnduserDisplayService
	) { }

	public get getIsActive(): boolean {
		return this.enduserDisplayService.IsDisabled();
	}

	public get getSelected(): string {
		return this.enduserDisplayService.IsDisplay() ? "display" : "enduser";
	}

	public clicked(state: string) {
		if (this.clickable && this.getSelected !== state) {
			this.enduserDisplayService.toggleConfigurationMode();
		}
	}

}
