import { ModelModel } from "./api-models/model";
import { ComponentVariantModel } from "./component-variant";
import { ConfigurationModel } from "./configuration";
import { ManualConfigVariantCreateModel } from "./manual-config-variant-create";
import { ModelSizeModel } from "./model-size";
import { TempleInclinationEnum } from "./variant-temple-inclination";

export class ManualConfigCreateModel {

	public Group: number;
	public Release: Date;
	public ModelId: number;
	public ModelSizeId: number;
	public ModelConfigurationId: number;
	public ManualConfigVariants: Array<ManualConfigVariantCreateModel>;
	public TempleInclination?: TempleInclinationEnum;
	public IsPrecious: boolean;
	public ManualConfigCollectionId?: number;
	public ImageUrls: Array<string>;

	public static CreateFromData(group: number,
		releaseDate: Date,
		model: ModelModel,
		configuration: ConfigurationModel,
		modelSize: ModelSizeModel,
		variants: Array<ComponentVariantModel>,
		templeInclination?: TempleInclinationEnum,
		manualConfigCollectionId?: number,
		isPrecious: boolean = false,
		imageUrls: Array<string> = null): ManualConfigCreateModel {
		const manualConfigurationVariants: Array<ManualConfigVariantCreateModel> = [];

		variants.forEach(variant => {
			if (variant.VariantColor) {
				const manualConfigurationVariant = new ManualConfigVariantCreateModel();
				manualConfigurationVariant.ComponentVariantId = variant.Id;
				manualConfigurationVariant.ComponentSizeId = variant.VariantSize.Id;
				manualConfigurationVariant.ColorId = variant.VariantColor.Color.Id;
				manualConfigurationVariant.ComponentColorId = variant.VariantColor.Id;
				manualConfigurationVariants.push(manualConfigurationVariant);
			}
		});

		const manualConfiguration = new ManualConfigCreateModel();
		manualConfiguration.ManualConfigVariants = manualConfigurationVariants;
		manualConfiguration.ModelId = model.Id;
		manualConfiguration.ModelConfigurationId = configuration.ModelConfigId;
		manualConfiguration.ModelSizeId = modelSize.Id;
		manualConfiguration.Release = releaseDate;
		manualConfiguration.Group = group;
		manualConfiguration.TempleInclination = templeInclination;
		manualConfiguration.ManualConfigCollectionId = manualConfigCollectionId;
		manualConfiguration.IsPrecious = isPrecious;
		manualConfiguration.ImageUrls = imageUrls;

		return manualConfiguration;
	}

}
