import { ApplicationRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { NoNewVersionDetectedEvent, SwUpdate, VersionDetectedEvent, VersionInstallationFailedEvent, VersionReadyEvent } from '@angular/service-worker';
import { BrandConstants, BrandEnum } from '@infrastructure/brand.enum';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { PriceSettingModel } from '@models/price-setting.model';
import { ApplicationInsightsService } from '@services/application-insights.service';
import { BrandModeService } from '@services/brandmode.service';
import { EnvService } from '@services/env.service';
import { EventsService } from '@services/events.service';
import { ImageService } from '@services/image.service';
import { IndexDbService } from '@services/index-db.service';
import { LoginService } from '@services/login.service';
import { ManualConfigService } from '@services/manual-config.service';
import { NotificationService } from '@services/notification.service';
import { OrderSyncService } from '@services/ordersync.service';
import { PriceSettingsService } from '@services/price-settings.service';
import { SignalRService } from '@services/signal-r.service';
import { UserService } from '@services/user.service';
import { AppState } from '@shared/app-state';
import { UserTypeConstant } from '@shared/user-type-constants';
import { Guid } from 'guid-typescript';
import 'lazysizes';
import { Observable, Subscription, debounceTime, filter, first, interval, timer } from 'rxjs';
import { LanguageService } from '../_services/language.service';
import { AppConstants } from '../app.constants';
import { routerTransition } from '../shared/transitions/router.transition';
import "reflect-metadata";

@Component({
	selector: 'ldcg-app',
	animations: [routerTransition()],
	styleUrls: [],
	template: `
        <!-- <mini-profiler></mini-profiler> -->
        <loading-overlay></loading-overlay>
        <menu-header *ngIf="isReady" [ngClass]="{'precious': !isTitanium && (appState.showPreciousTheme | async), 'titanium': isTitanium || (appState.showPreciousTheme | async) === false}"></menu-header>

        <main *ngIf="isReady" [ngClass]="{'precious': !isTitanium && (appState.showPreciousTheme | async), 'titanium': isTitanium || (appState.showPreciousTheme | async) === false}">
			<modal></modal>
            <div *ngIf="showAnimation" id="page-wrapper" [@routerTransition]="routerState(page)" class="inline-scroll">
                <router-outlet #page="outlet"></router-outlet>
            </div>

            <div *ngIf="!showAnimation" id="page-wrapper" class="inline-scroll">
                <router-outlet></router-outlet>
            </div>
        </main>
        <alert></alert>
        <footer></footer>
        <a href="https://beian.miit.gov.cn/" target="_blank" *ngIf="appState.showChineseLicenseId" class="chineseLicense">沪ICP备18032619号-1</a>
    `
})
export class AppComponent implements OnInit, OnDestroy {

	public showAnimation = false;
	public isReady: boolean;
	public preciousModeChangedObservable: Observable<BrandEnum>;
	public preciousModeChangedSubscription: Subscription;
	public isTitanium: boolean;
	public orderUuid: string
	public userUuid: string

	private loginRegisterCheckInterval: number = 24 * 60 * 60 * 1000; // 24 hours in miliseconds

	constructor(
		private router: Router,
		private languageService: LanguageService,
		private renderer: Renderer2,
		private eventService: EventsService,
		appRef: ApplicationRef,
		updates: SwUpdate,
		public appState: AppState,
		public imageService: ImageService, // temp hack, for initializing imageurls early
		public orderSyncService: OrderSyncService,
		public manualConfigService: ManualConfigService,
		private applicationInsightsService: ApplicationInsightsService,
		private userService: UserService,
		private indexDbService: IndexDbService,
		private loginService: LoginService,
		private brandModeService: BrandModeService,
		private notificationService: NotificationService,
		public signalRService: SignalRService,
		public environmentService: EnvService,
		private priceSettingsService: PriceSettingsService
	) {
		if (updates.isEnabled) {
			this.checkForServiceWorkerUpdate(appRef, updates);
		}

		this.hasTouch();
	}

	async ngOnDestroy() {
		await this.signalRService.closeConnection();
	}

	async ngOnInit() {

		const searchParams = new URLSearchParams(window.location.search);
		var tokenFromUrl = searchParams.get('token');
		var usernameFromUrl = searchParams.get('username');
		if (tokenFromUrl && usernameFromUrl) {
			this.loginService.SetTokenAndUsername(tokenFromUrl, usernameFromUrl);
		}

		const userData = await this.userService.GetUser();

		if (this.loginService.AllowUnauthenticatedAccess()) {
			this.userUuid = await this.getUserUuidFromUrl(); //Would have preferred to get this value from params, but ActivatedRouter is not an option because this is AppComponent and subscription to Router.Events is too slow

			if (this.userUuid !== Guid.EMPTY) {
				if (!this.loginService.IsLoggedIn() || this.userUuid !== userData.Uuid) {
					const res = await this.loginService.postLiteLogin(this.userUuid);
					if (res.access_token) {
						await this.loginService.liteLogin(res.access_token);
					}
				}
			}
			else if (userData.Type !== UserTypeConstant.Employee) {
				await this.loginService.liteLogout();
				await this.loginService.Logout();
				this.router.navigate(['/login']);
			}
		}

		const isNotLoginPage = location.pathname !== "/login";

		if (isNotLoginPage && !userData?.IsLiteLogin) {
			await this.indexDbService.openDatabase();
		}

		await this.initializeLocalization();

		const isIE11 = !!window["MSInputMethodContext"] && !!document["documentMode"];

		if (!isIE11) {
			this.showAnimation = true;
		}
		else {
			this.renderer.addClass(document.body, 'is-ie');
		}

		this.appState.showChineseLicenseId = window.location.host.indexOf('.cn') > -1 || window.location.host.indexOf('cn.') > -1;

		if (userData) {
			this.applicationInsightsService.setUserId(userData.Alias);
			this.appState.priceSetting.next(new PriceSettingModel(userData.PriceSettingDisplay, this.priceSettingsService.getPriceText(userData.PriceSettingDisplay)));
		}

		this.isTitanium = this.brandModeService.isTitanium;

		this.preciousModeChangedObservable = this.brandModeService.brandModeChangedObservable();

		this.preciousModeChangedSubscription = this.preciousModeChangedObservable.pipe(debounceTime(100)).subscribe(x => {
			this.isTitanium = this.brandModeService.isTitanium;
		});

		this.appState.showPreciousTheme.next(!this.router.url.startsWith('/bag'));

		if (userData?.preciousModuleEnabled) {
			if (location.pathname.startsWith('/' + BrandConstants.PRECIOUS) && !this.brandModeService.isPrecious) {
				this.brandModeService.toggleBrandMode(BrandEnum.Precious);
			} else if (location.pathname.startsWith('/' + BrandConstants.TITANIUM) && !this.brandModeService.isTitanium) {
				this.brandModeService.toggleBrandMode(BrandEnum.Titanium);
			}
		}
		else if (location.pathname.startsWith('/' + BrandConstants.PRECIOUS)) {
			location.pathname = location.pathname.replace('/' + BrandConstants.PRECIOUS, '/' + BrandConstants.TITANIUM);
		}

		try {
			if (this.loginService.IsLoggedIn()) {
				await this.notificationService.initNotifications();
			}

			// Make sure to register user login every day (logins now last 14 days)
			if (isNotLoginPage && !userData?.IsLiteLogin) {
				if (!userData?.LoginRegistered || new Date(userData?.LoginRegistered).getTime() + this.loginRegisterCheckInterval < Date.now()) {
					await this.loginService.registerLogin();
					await this.loginService.updateLoginRegistered();
				}

				if (userData) {
					if (!this.appState.priceModuleEnabled.getValue()) {
						this.appState.priceModuleEnabled.next(userData.priceModuleEnabled && userData.PriceSettingAllowed !== PriceSettingEnum.AllowNone);
					}

					// await this.signalRService.addSignalrListener('pageload');
				}
			}
		}
		catch (error) {
		}
		finally {
			this.isReady = true;
		}

		await this.environmentService.Version();
	}

	private async getUserUuidFromUrl() {
		const urlParts = window.location.pathname.substring(1).split('/');

		return urlParts[1];
	}

	private async initializeLocalization() {
		try {
			const defaultLang = AppConstants.languages[0];
			this.languageService.addLanguages(AppConstants.languages);
			this.languageService.setDefaultLang(defaultLang);
			await this.setLanguage();
		}
		catch (error) {
		}
	}

	private async setLanguage() {
		const language = new URLSearchParams(window.location.search).get('language');
		var avaliableLanguages = await this.languageService.getLanguages();

		if(language && avaliableLanguages.includes(language)) {
			await this.languageService.useLanguage(language);
		}
		else{
			await this.languageService.setSessionOrBrowserLanguage();
		}
	}

	private async checkForServiceWorkerUpdate(appRef: ApplicationRef, updates: SwUpdate) {
		let version = await this.environmentService.Version();
		console.debug('sw - Checking for new version. Current version: ' + version);

		const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
		appIsStable$.subscribe(() => updates.checkForUpdate());

		const checkForUpdateSubscription = interval(60 * 1000).subscribe(() => updates.checkForUpdate());

		updates.versionUpdates.subscribe(async event => {
			switch (event.type) {
				case 'NO_NEW_VERSION_DETECTED':
					event = event as NoNewVersionDetectedEvent;
					//console.debug('sw - version is up to date. Current version: ' + environment.version, new Date().toTimeString());
					break;
				case 'VERSION_DETECTED':
					event = event as VersionDetectedEvent;
					this.eventService.showLoader(true, 0);

					const updateSuccess = await updates.activateUpdate();

					await this.environmentService.UpdateVersion();
					version = await this.environmentService.Version();
					console.debug('sw - new version detected. Version: ' + version + ' - updateSuccess: ' + updateSuccess);

					this.eventService.showLoader(false);

					if (document.location.pathname === '/login') {
						//deferring page refresh when on login-page and possibly typing in credentials => page refresh is deferred to after login instead
						this.appState.appWasUpdated = true;
					}
					else {
						checkForUpdateSubscription.unsubscribe();

						const ticks = new Date().getTime().toString();
						let delimiter = '/?';
						if (window.location.href.indexOf('?') > -1) {
							delimiter = "&";
						}

						if (!updateSuccess) {
							timer(0, 5000).subscribe(async () => {
								console.debug('sw - reload page with long timer');
								window.location.href = window.location.href + delimiter + 'v=' + ticks
							});
						}
						else {
							timer(0, 500).subscribe(async () => {
								console.debug('sw - reload page without short timer');
								location.reload();
								window.location.href = window.location.href + delimiter + 'v=' + ticks;
							});
						}
					}
					break;
				case 'VERSION_INSTALLATION_FAILED':
					event = event as VersionInstallationFailedEvent;
					console.debug('sw - Version installation failed. Version: ' + version);
					// console.debug(event.error);
					break;
				case 'VERSION_READY':
					event = event as VersionReadyEvent;
					console.debug('sw - Version ready. Version: ' + version, event.latestVersion);
					break;
			}
		});

		this.router.events.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(async () => {
				await updates.checkForUpdate();
			});
	}

	hasTouch() {
		if ("ontouchstart" in document.documentElement) {
			this.renderer.addClass(document.body, 'is-touchdevice');
		}

		if (window.navigator["standalone"]) {
			this.renderer.addClass(document.body, 'is-standalone');
		}
	}

	routerState(outlet: any) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
	}

}
