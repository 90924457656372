import { Component, Input, OnInit } from "@angular/core";
import { ImageZoomModalComponent } from '@modals/image/image-zoom.component';
import { MiscImageModel } from '@models/misc-image-model';
import { ImageService } from '@services/image.service';
import { ModalService } from '@services/modal.service';
import { ServerInfoService } from '@services/server-info.service';

@Component({
    selector: "misc-image",
    templateUrl: "misc-image.component.html",
    styleUrls: ["./misc-image.component.scss"]
})
export class MiscImageComponent implements OnInit {
    @Input() public eCommerceNo: string;

    constructor(private imageService: ImageService, private modalService: ModalService, private serverInfoService: ServerInfoService) { }

    public imageModelArrays: Array<Array<MiscImageModel>>;

    async ngOnInit() {
        const imageCount = await this.serverInfoService.getMiscImageCount(this.eCommerceNo);
        this.imageModelArrays = this.imageService.GetMiscImageModels(this.eCommerceNo, imageCount);
    }

    public zoom(eCommerNo: string, count: number) {
        const src = this.imageService.GetMiscImage(eCommerNo, count, "Large");
        this.modalService.create(ImageZoomModalComponent, { src });
    }
}
