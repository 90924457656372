export class CaseAlternativeGroupLineModel {

	constructor(
		public Id: number,
		public ItemNo: string,
		public ItemVariantCode: string,
		public Default: boolean,
		public ModelId: number,
		public ModelSizeId: number,
	) { }

	public static createCopy(caseAlternativeGroupLineModel: CaseAlternativeGroupLineModel): CaseAlternativeGroupLineModel {
		const newObj = new CaseAlternativeGroupLineModel(caseAlternativeGroupLineModel.Id,
			caseAlternativeGroupLineModel.ItemNo,
			caseAlternativeGroupLineModel.ItemVariantCode,
			caseAlternativeGroupLineModel.Default,
			caseAlternativeGroupLineModel.ModelId,
			caseAlternativeGroupLineModel.ModelSizeId);

		return newObj;
	}

}
