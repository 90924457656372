import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { BaseModal } from '../base-modal';

@Component({
    selector: 'generic-info-modal',
    styleUrls: ['./generic-info-modal.scss'],
    template: `
        <div class="modal-body">
            <button class="close" (click)="closeAndDestroy()">
                <i class="fa-light fa-xmark"></i>
            </button>
            <div class="information col-xs-12 col-sm-10 col-md-8 col-lg-6">
                <h3 [innerHTML]="title"></h3>
                <h4 *ngIf="contentPart1" [innerHTML]="contentPart1"></h4>
                <h4 *ngIf="contentPart2" [innerHTML]="contentPart2"></h4>
                <div *ngIf="contentPart3" [innerHTML]="contentPart3"></div>
                <button class="btn btn-primary" (click)="closeAndDestroy()">{{ "COMMON.OK" | translate }}</button>
            </div>
        </div>
    `
})
export class GenericInfoModalComponent extends BaseModal {
    title: string;
    contentPart1: string;
    contentPart2: string;
    contentPart3: string;

    constructor(breadcrumbService: BreadcrumbService) {
        super(breadcrumbService);
    }
}
