export enum ComponentCodeConstants {
    Bridge = "BRIDGE",
    BridgeClip = "BRIDGE_CLIP",
    Clip = "CLIP",
    ClipL = "CLIP (L)",
    ClipR = "CLIP (R)",
    Clipon = "CLIP-ON",
    Cover = "COVER",
    Engraving = "ENGRAVING",
    Front = "FRONT",
    Groove = "GROOVE",
    Innerrim = "INNERRIM",
    Lenses = "LENSES",
    Lowerrim = "LOWERRIM",
    Upperrim = "UPPERRIM",
    Temple = "TEMPLE",
    TempleClip = "TEMPLE_CLIP",
    Crossbar = "CROSSBAR",    
    Nosepad = "NOSEPAD",
    Gemstone = "GEMSTONE",
}
