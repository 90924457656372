import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { IBreadcrumb } from '../_models/ibreadcrumb';
import { UrlConstants } from '../shared/url-constants';
import { IBreadcrumbService } from './interfaces/breadcrumb.service.interface';

export interface Breadcrumb {
	label: string;
	url: string;
}

@Injectable()
export class BreadcrumbService implements IBreadcrumbService {

	public breadcrumbClicked$: EventEmitter<boolean>;

	constructor() {
		this.breadcrumbClicked$ = new EventEmitter<boolean>();
	}

	public breadcrumbClicked() {
		this.breadcrumbClicked$.emit(true);
	}

	public getParameterFromBreadcrumb(root: ActivatedRoute, parameter: string): string {
		const breadcrumbs = this.getBreadcrumbs(root);

		if (breadcrumbs && breadcrumbs.length > 0) {
			const params = breadcrumbs.map(x => x.params).map(x => x);
			const param = params.find(x => x[parameter]);

			if (param) {
				return param[parameter];
			}
		}

		return null;
	}

	public getModelgroupFromBreadcrumb(root: ActivatedRoute): string {
		const breadcrumbs = this.getBreadcrumbs(root);

		if (breadcrumbs && breadcrumbs.length > 0) {
			const params = breadcrumbs.map(x => x.params).map(x => x);
			const modelgroupParam = params.find(x => x[UrlConstants.MODELGROUP]);

			if (modelgroupParam) {
				const modelgroup = modelgroupParam[UrlConstants.MODELGROUP];

				return modelgroup;
			}
		}

		return null;
	}

	public getModelFromBreadcrumb(root: ActivatedRoute): string {
		const breadcrumbs = this.getBreadcrumbs(root);

		if (breadcrumbs && breadcrumbs.length > 0) {
			const params = breadcrumbs.map(x => x.params).map(x => x);
			const modelParam = params.find(x => x[UrlConstants.MODEL]);

			if (modelParam) {
				const model = modelParam[UrlConstants.MODEL];

				return model;
			}
		}

		return null;
	}

	public getModelgroupName(root: ActivatedRoute): string {
		const breadcrumbs = this.getBreadcrumbs(root);

		if (breadcrumbs && breadcrumbs.length > 0) {
			const params = breadcrumbs.map(x => x.params).map(x => x);

			const modelGroupParam = params.find(x => x[UrlConstants.MODELGROUPNAME]);
			if (modelGroupParam) {
				const model = modelGroupParam[UrlConstants.MODELGROUPNAME];
				return model;
			}
		}

		return null;
	}

	public getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: Array<IBreadcrumb> = []): Array<IBreadcrumb> {
		const routeDataBreadcrumb: string = "breadcrumb";
		const routeDataBrandMode: string = "brandMode";

		const children: Array<ActivatedRoute> = route.children;

		if (children.length === 0) {
			return breadcrumbs;
		}

		for (const child of children) {
			if (child.outlet !== PRIMARY_OUTLET) {
				continue;
			}

			if (!child.snapshot.data.hasOwnProperty(routeDataBreadcrumb)) {
				return this.getBreadcrumbs(child, url, breadcrumbs);
			}

			const r: string = child.snapshot.data[routeDataBreadcrumb];

			if (r) {
				const routeUrl: string = child.snapshot.url.map(segment => segment.path.replace("/", "-").replace(/\s/g, " ")).join('/');

				url += `/${routeUrl}`;

				if (routeUrl.length > 0 || child.parent.parent == null) {
					const label = r.startsWith(":") ? child.snapshot.params[r.substring(1)] : r;

					const breadcrumb: IBreadcrumb = {
						label,
						params: child.snapshot.params,
						url
					};

					const b: string = child.snapshot.data[routeDataBrandMode];

					if (b) {
						const labelBrandmode = b.startsWith(":") ? child.snapshot.params[b.substring(1)] : b;

						const breadcrumbBrandmode: IBreadcrumb = {
							label: labelBrandmode,
							params: {},
							url: ''
						};

						if (!breadcrumbs.some(b => b.label === labelBrandmode)) {
							breadcrumbs.push(breadcrumbBrandmode);
						}
					}

					if (!breadcrumbs.some(b => b.label === label)) {
						breadcrumbs.push(breadcrumb);
					}
				}
			}

			return this.getBreadcrumbs(child, url, breadcrumbs);
		}

		return [];
	}

}
