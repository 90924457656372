import { Type } from "class-transformer";
import { AllAssortmentsModel } from './all-assortments-model.interface';
import { ComponentVariantFromApiModel } from "./api-models/component-variant-fromapi";
import { AssortmentLineModel } from './assortment-line-model';
import { AssortmentModel } from './assortment-model';
import { AvailabilityModel } from "./availability-model";
import { ColorModel } from './color';
import { ComponentVariantModel } from './component-variant';
import { ComponentVariantChangeModel } from './component-variant-change';

export class VariantColorModel extends AvailabilityModel implements AllAssortmentsModel {

	Id: number;
	Uuid: string;
	Status: number;
	ColorUuid: string;
	Default: boolean;
	Order: string;
	AvailableForDisplay: boolean;
	ComponentCode: string;
	AvailableOnModelgroups: Array<string>;

	@Type(() => AssortmentLineModel)
	AssortmentLines: Array<AssortmentLineModel>;

	@Type(() => ColorModel)
	Color: ColorModel;

	public MatchColors(chosenVariants: Array<ComponentVariantModel>, selectableVariantsData: Array<ComponentVariantFromApiModel>): Array<ComponentVariantChangeModel> {
		const changeModels = new Array<ComponentVariantChangeModel>();

		chosenVariants.forEach(variant => {
			const newVariant: ComponentVariantModel = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
			newVariant.VariantColorViewed = true;

			const variantData = selectableVariantsData.find(x => x.Uuid == variant.Uuid);

			const firstSplitColorCode = this.Color.FirstSplitColor;
			const lastSplitColorCode = this.Color.LastSplitColor;
			const fullColorCode = this.Color.Code;

			// If color is already used in the current variant data, ignore.
			if (!variant.VariantColor || variant.NotSelected || variant.VariantColor.Color.Code == fullColorCode ||
				variant.VariantColor.Color.FirstSplitColor == firstSplitColorCode ||
				variant.VariantColor.Color.LastSplitColor == firstSplitColorCode ||
				variant.VariantColor.Color.FirstSplitColor == lastSplitColorCode ||
				variant.VariantColor.Color.LastSplitColor == lastSplitColorCode) {
				return;
			}

			const newSelectedSize = variantData.VariantSizes.find(size => size.Uuid == variant.VariantSize?.Uuid);

			if (newSelectedSize) {
				const newSelectedColor = newSelectedSize.VariantColors.find(clr => clr.Color.IsAlike(this.Color));

				if (newSelectedColor) {
					newVariant.VariantColor = newSelectedColor;
					changeModels.push(new ComponentVariantChangeModel(variant, newVariant, null));
				}
			}
		});

		return changeModels;
	}

	get AllAssortments(): Array<AssortmentModel> {
		return this.AssortmentLines.map(x => x.Assortment).distinct((x, y) => x.Code === y.Code);
	}

	public static createCopy(fromModel: VariantColorModel): VariantColorModel {
		if (fromModel) {
			const newModel = new VariantColorModel(fromModel.Discontinued, fromModel.New);
			newModel.Id = fromModel.Id;
			newModel.Uuid = fromModel.Uuid;
			newModel.Status = fromModel.Status;
			newModel.ColorUuid = fromModel.ColorUuid;
			newModel.Default = fromModel.Default;
			newModel.Order = fromModel.Order;
			newModel.AvailableForDisplay = fromModel.AvailableForDisplay;
			newModel.ComponentCode = fromModel.ComponentCode;
			newModel.AvailableOnModelgroups = fromModel.AvailableOnModelgroups;
			newModel.AssortmentLines = fromModel.AssortmentLines.map(x => AssortmentLineModel.createCopy(x));
			newModel.Color = ColorModel.createCopy(fromModel.Color);
			return newModel;
		}
		else {
			return null;
		}

	}

}
