<!-- Hidden notification for the client/display toggle button -->
<div class='home-container'>
	<notification [notifier]="enduserDisplayClickedObserveable" [modal]="displayEnduserModalComponent">
		<div text>
			<span class="font-medium">{{'HOME.ENDUSERDISPLAY.READMORE1' | translate}} </span>
			<span>{{'HOME.ENDUSERDISPLAY.READMORE2' | translate}}</span>
		</div>
	</notification>

	<div class="tabs-plus-kering">
		<cstmsr-customiser-tab-bar [activeTab]="getInitialTab()" (activeTabChanged)="handleActiveTabChanged($event)"></cstmsr-customiser-tab-bar>
		<div class="kering-button-container"><button (click)='goToKering()'>{{'HOME.EXPLORE_KERING_BIG' | translate}}</button></div>
	</div>

	<!-- Main container for model groups frontpage -->
	<div class="container-fluid frontpage dark" [@fadeInOutTransition]>
		<div class="landing-content-container">
			<div *ngIf="modelGroups && modelGroups.length > 0" style="margin-bottom: 4rem;">
				<skeleton-screen class='mobile-grid' *ngIf="modelGroups && modelGroups.length > 0" [hasLoaded]="modelGroupsLoaded" [screens]="13" screenClass="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item">
					<div *ngFor="let modelGroup of modelGroups" class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item" (click)="goToGroup(modelGroup)">
						<div>
							<availability-text [date]="modelGroup.Discontinued" [new]="modelGroup.New" [useNewDetails]="false"></availability-text>
							<ordering-info [productlineCode]="modelGroup.ProductLineCode"></ordering-info>
							<dcg-image class="product-image" [src]="modelGroup.Code | modelGrpToProductlineImg" [alt]="isTitanium ? (modelGroup.Description | dataTranslate | translate) : (modelGroup.PreciousDescription | dataTranslate | translate)"></dcg-image>
							<model-information *ngIf="modelGroup.Code === ProductlineConstants.AIR && doShowAir" class="product-overlay" [ModelCodeNotAvailable]="isTitanium ? (modelGroup.Description | dataTranslate | translate) : (modelGroup.PreciousDescription | dataTranslate | translate)"></model-information>
							<app-product-title [isProductLine]="true" [isPrecious]="isPrecious" [title]="isTitanium ? (modelGroup.Description | dataTranslate | translate) : (modelGroup.PreciousDescription | dataTranslate | translate)" [code]="modelGroup.ProductLineCode"></app-product-title>
						</div>
					</div>
					<!-- Kids model group is handled separately -->
					<skeleton-screen class='mobile-grid-kids-limited' *ngIf="doShowKidTeen" [hasLoaded]="kidTeenLoaded" [screens]="1" screenClass="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item">
						<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item" (click)="goToKidTeen()" [ngClass]="{'precious': isPrecious}">
							<div>
								<dcg-image class="product-image" [src]="'kid_teen' | modelGrpToProductlineImg" [alt]="'HOME.KIDTEEN' | translate"></dcg-image>
								<app-product-title [title]="'HOME.KIDTEEN' | translate"></app-product-title>
							</div>
						</div>
					</skeleton-screen>

					<skeleton-screen class='mobile-grid-kids-limited' *ngIf="doShowLimited" [hasLoaded]="limitedHasLoaded" [screens]=1 screenClass="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item">
						<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item" (click)="goToLimited()">
							<div>
								<dcg-image class="product-image" [src]="'limited'| modelGrpToProductlineImg" [alt]="'HOME.TILES.LIMITED' | translate"></dcg-image>
								<app-product-title [title]="'HOME.TILES.LIMITED' | translate"></app-product-title>
							</div>
						</div>
					</skeleton-screen>
				</skeleton-screen>
			</div>

			<div *ngIf="inspirationGroups && inspirationGroups.length > 0">
				<skeleton-screen class='mobile-grid' *ngIf="inspirationGroups && inspirationGroups.length > 0" [hasLoaded]="inspirationGroupsLoaded" screenClass="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item">
					<div *ngFor="let group of inspirationGroups">
						<div *ngIf="!group.IsHidden || (adminModeEnabled && group.IsHidden)" [ngClass]="{'flag-hidden': group.IsHidden && adminModeEnabled}" class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item" (click)="goToInspiration(group)">
							<div>
								<dcg-image class="product-image" [src]="getInspirationGroupImages(group.ImageId)"></dcg-image>
								<app-product-title [title]="group.Name"></app-product-title>
							</div>
						</div>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item" (click)="triggerFilterModal()" *ngIf="isTitanium">
						<div>
							<dcg-image class="product-image" [src]="getInspirationGroupImages('filters')" [alt]="'HOME.FILTERS' | translate"></dcg-image>
							<app-product-title [title]="'HOME.FILTERS' | translate"></app-product-title>
						</div>
					</div>
					<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item" (click)="goToTechInfo()">
						<div>
							<dcg-image class="product-image" [src]="imageService.GetInspirationGroupImages('technical info', ImageSize.Low)" [alt]="'HOME.TECHNICALINFO' | translate"></dcg-image>
							<app-product-title [title]="'HOME.TECHNICALINFO' | translate"></app-product-title>
						</div>
					</div>
				</skeleton-screen>
			</div>

			<div *ngIf="miscCats && miscCats.length > 0">
				<div *ngFor="let category of miscCats" style="display: grid;">
					<h5>{{'BREADCRUMBS.' + category.name.toUpperCase() | removewhitespace | translate}}</h5>

					<skeleton-screen class='mobile-grid' [hasLoaded]="miscCategoriesLoaded" [screens]=10 screenClass="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item">
						<ng-container *ngFor="let group of category.groups">
							<div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-2 product-item" (click)="goToMiscGroup2(group)">
								<div>
									<dcg-image class="product-image" [src]="getMiscCategoryImage(group.name.toLowerCase(), category.name.toLowerCase())" [alt]="group.name"></dcg-image>
									<app-product-title [title]="group.name"></app-product-title>
								</div>
							</div>
						</ng-container>
					</skeleton-screen>
					<br>
				</div>
			</div>
		</div>
	</div>
	<copyright></copyright>
</div>