<div class="dialog">
	<div class="content">
		<h5>confirm your choice</h5>
		<div>
			click ok to confirm your action, or click cancel to abort
		</div>
	</div>
	<div class="actions">
		<button (click)="ok()">ok</button>
		<button (click)="cancel()">cancel</button>
	</div>
</div>