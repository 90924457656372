import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { LoginService } from 'src/_services/login.service';
import { BaseModal } from './base-modal';

@Component({
    selector: 'logout-modal',
    template: `
        <div class="modal-body">
        <button class="close" (click)="closeAndDestroy()" *ngIf="!isMandatoryLogout">
            <i class="fa-light fa-xmark"></i>
        </button>
        <div class="log-out">
            <h3 class="text-center" *ngIf="!isMandatoryLogout">{{ "HOMEFUNCTIONS.LOGOUTHEADING" | translate }}</h3>
            <h3 class="text-center" *ngIf="isMandatoryLogout">{{ "HOMEFUNCTIONS.MANDATORYLOGOUTHEADING" | translate }}</h3>
            <p class="text-center" *ngIf="isMandatoryLogout" [innerHTML]="'HOMEFUNCTIONS.MANDATORYLOGOUT' | translate"></p>
            <div class="form-group text-center">
                <button class="btn btn-secondary mr-3" (click)="closeAndDestroy()" *ngIf="!isMandatoryLogout">{{ "COMMON.CANCEL" | translate }}</button>
                <button class="btn btn-primary" (click)="logOut()">{{ "HOMEFUNCTIONS.LOGOUT" | translate }}</button>
            </div>
        </div>
        </div>
    `
})
export class LogOutComponent extends BaseModal {
    public isMandatoryLogout: boolean;
    constructor(
        protected router: Router,
        protected loginService: LoginService,
        breadCrumbService: BreadcrumbService
    ) {
        super(breadCrumbService);
    }

    async logOut() {
        await this.loginService.Logout();
        location.href = location.origin + "/login";
        this.closeAndDestroy();
    }
}
