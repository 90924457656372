import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { BaseModal } from '../base-modal';

@Component({
    selector: 'image-zoom-modal',
    templateUrl:'image-zoom.component.html',
    styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomModalComponent extends BaseModal {

    src: string;

    constructor(breadcrumbService: BreadcrumbService) {
        super(breadcrumbService);
     }

}
