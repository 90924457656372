import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ModelModel } from 'src/_models/api-models/model';
import { ModelGroupModel } from 'src/_models/model-group';
import { UserDataModel } from 'src/_models/user-data-model';
import { VariantColorModel } from 'src/_models/variant-color';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ConfigurationMode, ImageService } from 'src/_services/image.service';
import { UserService } from 'src/_services/user.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { ManualConfigComparer } from '@infrastructure/manualConfig/manualConfigComparer';
import { SearchTabEnum } from '@infrastructure/search-tab-enum';
import { CustomizerParams } from '@models/customizer-params';
import { ManualConfigCollectionModel } from '@models/manual-config-collection-model';
import { Paging } from '@models/paging.model';
import { BrandModeService } from '@services/brandmode.service';
import { EnvService } from '@services/env.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { IndexDbService } from '@services/index-db.service';
import { ManualConfigService } from '@services/manual-config.service';
import { OfflineService } from '@services/offline.service';
import { SessionService } from '@services/session.service';
import { ModelsService } from '@services/v2/models.service';
import { FeaturedGroupConstant, ManualConfigGroupConstant } from '@shared/featured-group-constant';

import { InspirationGroupModel } from '../../_models/inspiration-group';
import { ManualConfig } from '../../_models/manualconfig';
import { MiscModel } from '../../_models/misc-model';
import { EnduserDisplayService } from '../../_services/enduserdisplay.service';
import { NavigateService } from '../../_services/navigate.service';
import { SorterService } from '../../_services/sorter.service';
import { PermissionConstants } from '../../shared/permission-constants';
import { ProductlineConstants } from '../../shared/productline-constants';
import { FadeInOutTransition } from '../../shared/transitions/fade-in-out.transition';
import { BaseModal } from '../base-modal';

@Component({
	selector: 'model-search-modal',
	templateUrl: 'model-search.modal.html',
	styleUrls: ['./model-search.modal.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class ModelSearchModalComponent extends BaseModal implements OnInit, OnDestroy {

	content: BehaviorSubject<string>;

	public userPermissions: UserDataModel;
	public canOrderOnBehalf: boolean;
	public manualConfigCollections: Array<ManualConfigCollectionModel>;

	public consultantGroup: InspirationGroupModel;
	public enduserDisplayClickedObserveable: Observable<ConfigurationMode>;
	public enduserDisplaySubscription: Subscription;
	public isDisplay: boolean;
	public colorsInSearch: Array<VariantColorModel> = [];
	public colorsInSearchToShow: Array<VariantColorModel> = [];

	public modelsInSearch: Array<ModelModel> = [];
	public modelsInSearchToShow: Array<ModelModel> = [];

	public miscModelsInSearch: Array<MiscModel> = [];
	public miscModelsInSearchToShow: Array<MiscModel> = [];

	public consultantCollectionsInSearch: Array<ManualConfig> = [];
	public consultantCollectionsInSearchToShow: Array<ManualConfig> = [];

	public ProductlineConstants = ProductlineConstants;
	public allManualConfigGroups: Array<InspirationGroupModel>;

	private readonly amountToShowPrStep = 40;

	private endIndexShownModels = 0;
	private endIndexShownMisc = 0;
	private endIndexShownColors = 0;
	private endIndexShownConsultantCollections = 0;
	public hasInspirationEditPermission: boolean;
	public adminModeEnabled: boolean = false;
	public manualConfigComparer: ManualConfigComparer;
	public selectedTab: SearchTabEnum;
	public selectedBrand: BrandEnum;
	public searchTabEnum = SearchTabEnum;
	public modelsSearching: boolean = true;
	public modelsOnlineSearching: boolean = true;
	public modelsOfflineSearching: boolean = true;
	public collectionsSearching: boolean = false;
	public miscSearching: boolean = true;
	public colorsSearching: boolean = true;
	private selectedTabSessionKey: string = "selectedTab";
	private selectedBrandSessionKey: string = "selectedBrand";
	private searchTerm: string;
	private baseUrl: string;
	public errorDuringSearch: boolean = false;
	public brandEnum: typeof BrandEnum = BrandEnum;
	public currentBrandEnum: BrandEnum = this.brandModeService.currentBrandValue();

	constructor(
		public imageService: ImageService,
		private dataApi: DataApiService,
		private events: EventsService,
		private navigateService: NavigateService,
		private sorterService: SorterService,
		private userService: UserService,
		private displayEnduserService: EnduserDisplayService,
		private sessionService: SessionService,
		public offlineService: OfflineService,
		private indexedDbService: IndexDbService,
		private manualConfigService: ManualConfigService,
		public breadcrumbService: BreadcrumbService,
		private googleAnalyticsService: GoogleAnalyticsService,
		public brandModeService: BrandModeService,
		private env: EnvService,
		private modelsService: ModelsService
	) {
		super(breadcrumbService);
		this.manualConfigComparer = new ManualConfigComparer();
	}

	public static readonly preloadedModelsStoreName = "preload";
	private static readonly modelsKeyPrefix = "/models?requestModel.modelGroupUuid=";

	async ngOnInit() {
		this.selectedTab = this.sessionService.get<SearchTabEnum>(this.selectedTabSessionKey) ?? SearchTabEnum.models;
		this.selectedBrand = this.brandModeService.currentBrandValue();
		this.baseUrl = await this.env.baseUrl();

		this.subscriptions.push(this.content.pipe(debounceTime(300)).subscribe(x => {
			this.search(x);
		}));

		this.subscriptions.push(this.userService.currentUser.subscribe(t => {
			this.userPermissions = t;
			this.canOrderOnBehalf = t.canOrderOnBehalf;
			this.hasInspirationEditPermission = t.UserPermissions.some(x => x == PermissionConstants.CanEditManualConfigurations);
		}));

		this.subscriptions.push(this.userService.adminModeEnabled.subscribe(value => {
			this.adminModeEnabled = value;
		}));

		this.isDisplay = this.displayEnduserService.IsDisplay();
		this.enduserDisplayClickedObserveable = this.displayEnduserService.enduserDisplayClickedObservable();
		this.enduserDisplaySubscription = this.enduserDisplayClickedObserveable.pipe(debounceTime(100)).subscribe(x => {
			this.isDisplay = this.displayEnduserService.IsDisplay();
		});
	}

	ngOnDestroy() {
		this.enduserDisplaySubscription.unsubscribe();
		super.ngOnDestroy();
	}

	public async searchTabClicked(tab: SearchTabEnum) {
		this.selectedTab = tab;

		const user = await this.userService.GetUser();
		await this.googleAnalyticsService.eventEmitter('search_tab_change', 'engagement', `${user.Uuid} clicked tab '${SearchTabEnum[tab]}'`, 10);

		this.sessionService.set(this.selectedTabSessionKey, tab);
	}

	public async preciousTabClicked(brandEnum: BrandEnum) {
		this.selectedBrand = brandEnum;

		const user = await this.userService.GetUser();
		await this.googleAnalyticsService.eventEmitter('search_tab_change', 'engagement', `${user.Uuid} clicked tab '${BrandEnum[brandEnum]}'`, 10);

		this.sessionService.set(this.selectedBrandSessionKey, brandEnum);

		this.modelsInSearchToShow = [];
		this.searchModels();
		this.searchCollections();
	}

	public async search(searchTerm: string) {
		this.flush();

		this.searchTerm = searchTerm?.trim().toLowerCase();
		if (this.searchTerm && this.searchTerm.length > 0) {

			this.searchModels();
			this.searchCollections();
			this.searchColors();
			this.searchMisc();
			this.insertNextModelBatch();

			const user = this.userService.currentUser$.getValue();

			if (user) {
				await this.googleAnalyticsService.eventEmitter('search', 'engagement', `${user.Uuid} searched for '${searchTerm}'`, 20);
			}
		}
	}

	private async searchModels() {
		this.modelsOfflineSearching = true;

		var cachedModels = await this.searchModelsIndexedDb();
		this.modelsInSearch = cachedModels.sort((a, b) => b.Order.localeCompare(a.Order));

		this.modelsInSearch = this.modelsInSearch.filter(m => m.Brand === this.selectedBrand || m.Brand === BrandEnum.TitaniumAndPrecious);

		this.showModels();

		this.modelsOfflineSearching = false;

		if (!this.offlineService.isOnlineValue) {
			this.modelsOnlineSearching = false;
			return;
		}

		this.modelsOnlineSearching = true;
		this.modelsSearching = true;
		await this.modelsService.searchModels(this.searchTerm).then(onlineModels => {
			const cachedModelIds = cachedModels.map(x => x.Id);
			const newOnlineModels = onlineModels.filter(model => !cachedModelIds.includes(model.Id));

			const combinedModelList = newOnlineModels.concat(cachedModels);
			this.flushModels();

			this.modelsInSearch = combinedModelList.sort((a, b) => b.Order.localeCompare(a.Order));
			this.modelsInSearch = this.modelsInSearch.filter(m => m.Brand === this.selectedBrand || m.Brand === BrandEnum.TitaniumAndPrecious);

			this.showModels();
			this.modelsOnlineSearching = false;
			this.modelsSearching = false;
		}).catch(t => this.modelsOnlineSearching = false);
	}

	private async searchModelsIndexedDb(): Promise<ModelModel[]> {
		const cachedModels = await this.indexedDbService.getByKeyPrefix<any>(ModelSearchModalComponent.preloadedModelsStoreName, this.baseUrl + ModelSearchModalComponent.modelsKeyPrefix);
		return cachedModels
			.selectMany(x => x.data)
			.map(model => plainToClass(ModelModel, model))
			.filter(model => model.Code?.toLowerCase().startsWith(this.searchTerm));
	}

	private async searchCollections() {
		if (!this.offlineService.isOnlineValue) return;

		this.manualConfigService.getInspirationGroups().then(async inspirationGroups => {
			this.allManualConfigGroups = inspirationGroups;
			this.consultantGroup = this.allManualConfigGroups.find(groups => groups.Type == FeaturedGroupConstant.ConsultantCollection);
		});

		this.collectionsSearching = true;
		const allFavorites = await this.manualConfigService.getFavorites(this.searchTerm);
		this.manualConfigCollections = await this.manualConfigService.getManualConfigCollections();

		let consultantsConfigs: ManualConfig[] = [];
		this.flushConsultantCollections();

		if (this.canOrderOnBehalf) {
			const paging = new Paging(100);
			consultantsConfigs = await this.manualConfigService.getManualConfigurations(ManualConfigGroupConstant.ConsultantCollection, this.searchTerm, null, null, null, null, paging);
			this.errorDuringSearch = !consultantsConfigs && this.manualConfigService.errorDuringSearch;

			if (consultantsConfigs) {
				const favoritesToShowWithConsultantConfigs: Array<ManualConfig> = [];

				allFavorites?.forEach(favorite => {
					const existInCollection = this.manualConfigComparer.existIn(consultantsConfigs, favorite.ModelSize, favorite.ModelConfiguration, favorite.ManualConfigVariants.map(x => x.GetVariant()), favorite.TempleInclination);
					if (!existInCollection) {
						favoritesToShowWithConsultantConfigs.push(favorite);
					}
				});

				consultantsConfigs = consultantsConfigs.concat(favoritesToShowWithConsultantConfigs);
				this.consultantCollectionsInSearch = consultantsConfigs.sort((a, b) => this.sorterService.sortByConsultantCollection(a, b));
			}
			else {
				this.consultantCollectionsInSearch = [];
			}
		}
		else {
			this.consultantCollectionsInSearch = allFavorites?.sort((a, b) => this.sorterService.sortByConsultantCollection(a, b));
		}

		this.consultantCollectionsInSearch = this.consultantCollectionsInSearch.filter(m => m.IsPrecious == (this.selectedBrand === BrandEnum.Precious));
		this.consultantCollectionsInSearch.forEach(async manualConfig => {
			manualConfig.IsInCollection = await this.manualConfigService.isInCollection(manualConfig.Model, manualConfig.ManualConfigVariants.map(x => x.GetVariant()));
		});
		this.showManualConfigModels();
		this.collectionsSearching = false;
	}

	private async searchColors() {
		this.colorsSearching = true;
		this.dataApi.SearchColors(this.searchTerm).then(colors => {
			this.colorsInSearch = colors.sort((a, b) => a.Order.localeCompare(b.Order));
			this.showColors();
			this.colorsSearching = false;
		}).catch(t => {
			this.colorsSearching = false;
		});
	}

	private async searchMisc() {
		this.miscSearching = true;
		this.modelsService.getMiscModels().then(miscModels => {
			miscModels = miscModels.filter(k => k.EcommerceNoUnique?.toLowerCase().startsWith(this.searchTerm)
				|| k.Description?.toLowerCase().includes(this.searchTerm));

			this.miscModelsInSearch = miscModels.sort((a, b) => this.sorterService.sortByMisc(a, b));
			this.showMiscModels();
			this.miscSearching = false;
		}).catch(t => {
			this.miscSearching = false;
		});
	}

	public async onNavigateToCustomizer(arg: { featuredConfiguration: ManualConfig, modelgroup: ModelGroupModel, customizerParams: CustomizerParams }) {
		this.events.showLoader(true);

		const isUserCollection = arg.featuredConfiguration.Group == ManualConfigGroupConstant.ConsultantCollection || arg.featuredConfiguration.Group == ManualConfigGroupConstant.Favorites;
		arg.featuredConfiguration.Model.DefaultVariantModels = arg.featuredConfiguration.ManualConfigVariants.map(t => t.GetVariant());

		const itemIsPrecious = arg.featuredConfiguration.IsPrecious

		if (isUserCollection && this.canOrderOnBehalf && this.brandModeService.isTitanium && !itemIsPrecious) {
			await this.navigateService.navigateToCondensedCustomizer(arg.modelgroup, arg.featuredConfiguration.Model, arg.featuredConfiguration.ModelSize, arg.featuredConfiguration.TempleInclination,
				arg.featuredConfiguration.ModelConfiguration, null, this.content.value, null, arg.customizerParams);
		}
		else {
			await this.navigateService.navigateToCustomizer(
				arg.modelgroup,
				arg.featuredConfiguration.Model,
				arg.featuredConfiguration.ModelSize,
				arg.featuredConfiguration.TempleInclination,
				arg.featuredConfiguration.ModelConfiguration,
				null,
				null,
				arg.customizerParams);
		}

		if (itemIsPrecious && this.brandModeService.currentBrandValue() !== BrandEnum.Precious) {
			this.brandModeService.toggleBrandMode(BrandEnum.Precious);
		}
		else if (!itemIsPrecious && this.brandModeService.currentBrandValue() !== BrandEnum.Titanium) {
			this.brandModeService.toggleBrandMode(BrandEnum.Titanium);
		}

		this.closeAndDestroy();
	}

	public async onSearchSelected(model: ModelModel) {
		if (model.PresentAsPrecious && this.brandModeService.currentBrandValue() !== BrandEnum.Precious) {
			this.brandModeService.toggleBrandMode(BrandEnum.Precious);
		}
		else if (!model.PresentAsPrecious && this.brandModeService.currentBrandValue() !== BrandEnum.Titanium) {
			this.brandModeService.toggleBrandMode(BrandEnum.Titanium);
		}

		const modelgroup = await this.modelsService.getModelGroup(model.ModelGroupUuid);
		this.navigateService.navigateToCustomizer(modelgroup.ModelGroupChildren && modelgroup.ModelGroupChildren.length ? modelgroup.ModelGroupChildren.find(x => x.FullFrame) : modelgroup, model, null, null);
		this.closeAndDestroy();
	}

	onScroll() {
		this.insertNextModelBatch();
	}

	private showManualConfigModels() {
		if (this.consultantCollectionsInSearchToShow?.length < this.consultantCollectionsInSearch?.length) {
			const start = this.endIndexShownConsultantCollections;
			const end = this.endIndexShownConsultantCollections + this.amountToShowPrStep;
			const toAdd = this.consultantCollectionsInSearch.slice(start, end);

			this.consultantCollectionsInSearchToShow = this.consultantCollectionsInSearchToShow.concat(toAdd);
			this.endIndexShownConsultantCollections += this.amountToShowPrStep;
		}
	}

	private showModels() {
		if (this.modelsInSearchToShow.length < this.modelsInSearch.length) {

			this.modelsInSearch.forEach(model => {
				model.DefaultVariantModels = model.DefaultVariantModels.filter(v => v.Brand === this.selectedBrand);
				model.PresentAsPrecious = this.selectedBrand === BrandEnum.Precious;
			});

			const start = this.endIndexShownModels;
			const end = this.endIndexShownModels + this.amountToShowPrStep;
			const toAdd = this.modelsInSearch.slice(start, end);

			this.modelsInSearchToShow = this.modelsInSearchToShow.concat(toAdd);

			this.endIndexShownModels += this.amountToShowPrStep;
		}
	}

	private showMiscModels() {
		if (this.miscModelsInSearchToShow.length < this.miscModelsInSearch.length) { // gør det kun hvis der er flere at vise
			const start = this.endIndexShownMisc;
			const end = this.endIndexShownMisc + this.amountToShowPrStep;

			const toAdd = this.miscModelsInSearch.slice(start, end);

			this.miscModelsInSearchToShow = this.miscModelsInSearchToShow.concat(toAdd);
			this.endIndexShownMisc += this.amountToShowPrStep;
		}
	}

	private showColors() {
		if (this.colorsInSearchToShow.length < this.colorsInSearch.length) {
			const start = this.endIndexShownColors;
			const end = this.endIndexShownColors + this.amountToShowPrStep;
			const toAdd = this.colorsInSearch.slice(start, end);

			this.colorsInSearchToShow = this.colorsInSearchToShow.concat(toAdd);
			this.endIndexShownColors += this.amountToShowPrStep;
		}
	}

	private insertNextModelBatch() {
		this.showManualConfigModels();
		this.showModels();
		this.showMiscModels();
		this.showColors();
	}

	deleteConsultantConfiguration(deletedConsultantConfiguration: ManualConfig) {
		this.consultantCollectionsInSearch = this.consultantCollectionsInSearch.filter(t => t.Uuid !== deletedConsultantConfiguration.Uuid);
		this.consultantCollectionsInSearchToShow = this.consultantCollectionsInSearchToShow.filter(t => t.Uuid !== deletedConsultantConfiguration.Uuid);
		--this.endIndexShownConsultantCollections;
	}

	private flush() {
		this.flushModels();
		this.flushConsultantCollections();
		this.miscModelsInSearch = [];
		this.miscModelsInSearchToShow = [];
		this.colorsInSearch = [];
		this.colorsInSearchToShow = [];
		this.endIndexShownMisc = 0;
		this.endIndexShownColors = 0;
	}

	private flushModels() {
		this.modelsInSearch = [];
		this.modelsInSearchToShow = [];
		this.endIndexShownModels = 0;
	}

	private flushConsultantCollections() {
		this.consultantCollectionsInSearch = [];
		this.consultantCollectionsInSearchToShow = [];
		this.endIndexShownConsultantCollections = 0;
	}
}
