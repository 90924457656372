import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { BaseModal } from '../base-modal';

@Component({
    selector: 'display-enduser-modal',
    templateUrl:'display-enduser.component.html',
    styleUrls: ['./display-enduser.component.scss']
})
export class DisplayEnduserModalComponent extends BaseModal {

    constructor(breadcrumbService: BreadcrumbService) {
        super(breadcrumbService);
     }

    onConfirm(): void {
        this.closeAndDestroy();
    }
}
