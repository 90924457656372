import { Injectable } from '@angular/core';
import { VersionType } from '@infrastructure/versionType.enum';
import { AppConstants } from 'src/app.constants';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { ServerInfoService } from './server-info.service';
import { UserService } from './user.service';

@Injectable()
export class ErrorReportService {

	constructor(
		private userService: UserService,
		private env: EnvService,
		private indexDbService: IndexDbService,
		private serverInfoService: ServerInfoService
	) { }

	async openWebSupport(errorKey: string, errorMessage: string, errorUrl: string) {
		try {
			const currentDataVersion = this.indexDbService.getLocalVersion(VersionType.Frames);
			const user = await this.userService.GetUser();

			let dataserverregion = '';
			let imageurlregion = '';
			let imagecdnurlregion = '';

			try {
				dataserverregion = (await this.serverInfoService.getServerInfo()).Region;
				imageurlregion = (await this.serverInfoService.getImageServerInfo()).region;
				imagecdnurlregion = (await this.serverInfoService.getImageCdnServerInfo()).region;
			}
			catch (error) {
				console.error('getServerInfo failed', error)
			}

			let url = AppConstants.websupportUrl;
			url += `&alias=${user?.Alias}`;
			url += `&accountnumber=${user?.CustomerNo}`;
			url += `&appversion=${this.env.getVersion()}`;
			url += `&dataversion=${currentDataVersion ? currentDataVersion.Id + "" : ""}`;
			url += `&dataserver=${await this.env.DataApiurl()}`;
			url += `&dataserverregion=${dataserverregion}`;
			url += `&imageurl=${await this.env.ImageUrl()}`;
			url += `&imageurlregion=${imageurlregion}`;
			url += `&imageurlCDN=${await this.env.ImageCdnUrl()}`;
			url += `&imageurlCDNregion=${imagecdnurlregion}`;

			if (errorUrl)
				url += `&error_url=${errorUrl ?? ''}`;

			if (errorKey)
				url += `&errorKey=${errorKey ?? ''}`;

			if (errorMessage)
				url += `&errorMessage=${errorMessage ?? ''}`;

			window.open(url, '_blank');
		}
		catch (error) {
			console.error(`An error occurred while trying to open web support page (${error})`);
		}
	}

}
