import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { HomePageComponent } from 'src/app/home/home';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModelInformationModule } from '../Information/model-information.module';
import { CustomiserTabBarComponent } from './customiser-tab-bar/customiser-tab-bar.component';
import { BreadcrumbComponent } from './header/components/breadcrumb/breadcrumb';
import { DisplayEnduserButtonComponent } from './header/components/display-enduser-button/display-enduser-button.component';
import { OnBehalfOfBarComponent } from './header/components/on-behalf-of-bar/on-behalf-of-bar';
import { OptionsDropdownComponent } from './header/components/options-dropdown/options-dropdown.component';
import { ModelFilterComponent } from './header/components/tag-header/model-filter/model-filter.component';
import { StaticTagFilterComponent } from './header/components/tag-header/static-tag-filter/static-tag-filter.component';
import { TagComponent } from './header/components/tag-header/tags/tag.component';
import { HeaderComponent } from './header/header';
import { routes } from './home.routes';

@NgModule({
	declarations: [
		HomePageComponent,
		CustomiserTabBarComponent,
		HeaderComponent,
		BreadcrumbComponent,
		OptionsDropdownComponent,
		DisplayEnduserButtonComponent,
		OnBehalfOfBarComponent,
		StaticTagFilterComponent,
		ModelFilterComponent,
		TagComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		MomentModule,
		RouterModule.forChild(routes),
		SharedModule,
		ModelInformationModule
	],
	providers: [

	],
	bootstrap: [
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
	exports: [
		BreadcrumbComponent,
		DisplayEnduserButtonComponent,
		HeaderComponent,
		OptionsDropdownComponent
	]
})
export class HomeModule { }
