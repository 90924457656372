import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ModelInformationModule } from '@app/Information/model-information.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { CustomizerSharedModule } from './../app/customizer-shared/customizer-shared.module';
import { ApiResponseModalComponent } from './api-response/api-response.component';
import { BrowserInfoComponent } from './browser-info/browser-info.component';
import { ConnectedUsersComponent } from './connected-users/connected-users.component';
import { CustomerLocationCheckInModalComponent } from './customer-location-checkin/customer-location-checkin.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { DiscontinuedOrderModalComponent } from './discontinued-order/discontinued-order.modal';
import { DisplayEnduserModalComponent } from './display-enduser/display-enduser.component';
import { ErrorComponent } from './error.component';
import { FavoriteCollectionModalComponent } from './favorite-collection-modal/favorite-collection-modal.component';
import { GenericConfirmModalComponent } from './generic-confirm/generic-confirm.modal';
import { GenericInfoModalComponent } from './generic/generic-info.modal';
import { ImageZoomModalComponent } from './image/image-zoom.component';
import { LegalComponent } from './legal/legal.component';
import { LoadDataComponent } from './load-data/load-data.component';
import { LogOutComponent } from './logout.component';
import { ModalComponent } from './modal.component';
import { ModelSearchModalComponent } from './model-search/model-search.modal';
import { NotfoundComponent } from './notfound.component';
import { OfflineCustomiserModalComponent } from './offline-customiser-modal/offline-customiser-modal.component';
import { OfflineModalComponent } from './offline.component';
import { OrderChooserModalComponent } from './order-chooser-modal/order-chooser-modal.component';
import { OrderOnBehalfModalComponent } from './order-on-behalf/order-on-behalf.component';
import { OrderPlacedModalComponent } from './order-placed/order-placed.component';
import { PlaceOrderModalComponent } from './place-order/place-order.component';
import { RemoveInspirationFramesComponent } from './remove-inspiration-frames/remove-inspiration-frames.component';
import { RequestOrderPlacedModalComponent } from './request-order-placed/request-order-placed.component';
import { StartKitsMappingChangeModalComponent } from './start-kits-mapping-change-modal/start-kits-mapping-change-modal.component';
import { SubmitRequestModalComponent } from './submit-request/submit-request.component';
import { TagFilterModalComponent } from './tag-filter/tag-filter.component';

@NgModule({
	declarations: [
		PlaceOrderModalComponent,
		DisplayEnduserModalComponent,
		DiscontinuedOrderModalComponent,
		ImageZoomModalComponent,
		GenericInfoModalComponent,
		GenericConfirmModalComponent,
		OrderOnBehalfModalComponent,
		DeliveryComponent,
		LegalComponent,
		BrowserInfoComponent,
		LogOutComponent,
		LoadDataComponent,
		RemoveInspirationFramesComponent,
		OrderPlacedModalComponent,
		ErrorComponent,
		NotfoundComponent,
		ModelSearchModalComponent,
		OfflineModalComponent,
		TagFilterModalComponent,
		ApiResponseModalComponent,
		ModalComponent,
		OrderChooserModalComponent,
		CustomerLocationCheckInModalComponent,
		SubmitRequestModalComponent,
		RequestOrderPlacedModalComponent,
		ConnectedUsersComponent,
		FavoriteCollectionModalComponent,
		OfflineCustomiserModalComponent,
		StartKitsMappingChangeModalComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		TranslateModule,
		SharedModule,
		CustomizerSharedModule,
		ModelInformationModule,
		RouterModule,
		MomentModule,
		InfiniteScrollModule
	],
	providers: [],
	exports: [
		PlaceOrderModalComponent,
		DisplayEnduserModalComponent,
		DiscontinuedOrderModalComponent,
		ImageZoomModalComponent,
		GenericInfoModalComponent,
		OrderOnBehalfModalComponent,
		DeliveryComponent,
		LegalComponent,
		BrowserInfoComponent,
		LogOutComponent,
		LoadDataComponent,
		RemoveInspirationFramesComponent,
		OrderPlacedModalComponent,
		ErrorComponent,
		NotfoundComponent,
		ModelSearchModalComponent,
		OfflineModalComponent,
		TagFilterModalComponent,
		ApiResponseModalComponent,
		ModalComponent,
		GenericConfirmModalComponent,
		SubmitRequestModalComponent,
		RequestOrderPlacedModalComponent,
		StartKitsMappingChangeModalComponent
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class ModalModule { }
