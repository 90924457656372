// node modules
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';

// // interceptors
import { AuthInterceptor, MiniProfilerInterceptor } from '../_inteceptors/http.interceptors';

// guards
import { AuthGuard } from '../_guards/auth.guard';
import { CanCustomizeGuard } from '../_guards/can-customize.guard';
import { IsAdminGuard } from '../_guards/isAdmin.guard';

// // services
import { LocationCheckInService } from '@services/location-checkin.service';
import { LocationService } from '@services/location.service';
import { CookieService } from 'ngx-cookie-service';
import { BagCountService } from '../_services/bag-count.service';
import { BreadcrumbService } from '../_services/breadcrumb.service';
import { EnduserDisplayService } from '../_services/enduserdisplay.service';
import { EngravingService } from '../_services/engraving.service';
import { CustomErrorHandler } from '../_services/error-handler.service';
import { EventsService } from '../_services/events.service';
import { ExternalSearchService } from '../_services/external-search.service';
import { ImageService } from '../_services/image.service';
import { LanguageService } from '../_services/language.service';
import { LocalstorageService } from '../_services/localstorage.service';
import { LoginService } from '../_services/login.service';
import { MenuService } from '../_services/menu.service';
import { ModalService } from '../_services/modal.service';
import { NavigateService } from '../_services/navigate.service';
import { NewsService } from '../_services/news.service';
import { OptionsDropdownService } from '../_services/options-dropdown.service';
import { OrderOnBehalfService } from '../_services/order-on-behalf.service';
import { SessionService } from '../_services/session.service';
import { SorterService } from '../_services/sorter.service';
import { TagsService } from '../_services/tags.service';
import { TitleService } from '../_services/title.service';
import { UserService } from '../_services/user.service';

import { ErrorReportService } from '@services/error-report.service';

// modules
import { UiSwitchModule } from 'ngx-ui-switch';
import { SharedModule } from './shared/shared.module';

// bootstrap
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { AdvancecustomizeGuard } from '../_guards/can-advancecustomize.guard';
import { ModalModule } from '../_modals/modal.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

import { of } from 'rxjs';

import de from './../i18n/de.json';
import en from './../i18n/en.json';
import es from './../i18n/es.json';
import fr from './../i18n/fr.json';
import it from './../i18n/it.json';
import jpn from './../i18n/jpn.json';
import ko from './../i18n/ko.json';
import zh from './../i18n/zh.json';

import { HasInspirationDataGuard } from '@guards/has-inspiration-data.guard';
import { HasModelGroupDataGuard } from '@guards/has-modelgroup-data.guard';
import { ApplicationInsightsService } from '@services/application-insights.service';
import { BrandModeService } from '@services/brandmode.service';
import { ConfigurationService } from '@services/configuration.service';
import { CustomizerService } from '@services/customizer.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { NotificationService } from '@services/notification.service';
import { AppState } from '@shared/app-state';
import { FeaturedModule } from './featured/featured.module';
import { HomeModule } from './home/home.module';
import { MatSliderModule } from '@angular/material/slider';
import { MiscModule } from './misc/misc.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

class CustomTranslateLoader implements TranslateLoader {

	private languages = new Array<{ key: string, langs: any }>();

	constructor() {
		this.languages.push({ key: 'en', langs: en });
		this.languages.push({ key: 'ja', langs: jpn });
		this.languages.push({ key: 'ko', langs: ko });
		this.languages.push({ key: 'zh', langs: zh });
		this.languages.push({ key: 'de', langs: de });
		this.languages.push({ key: 'es', langs: es });
		this.languages.push({ key: 'fr', langs: fr });
		this.languages.push({ key: 'it', langs: it });
	}

	getTranslation(lang: string): import("rxjs").Observable<any> {
		const t = this.languages.find(x => x.key == lang);
		return of(t?.langs);
	}

}

export function windowFactory() {
	return window;
}

export function navigatorFactory() {
	return navigator;
}

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: CustomTranslateLoader
			}
		}),
		HttpClientXsrfModule.withOptions({
			cookieName: 'XSRF-TOKEN',
			headerName: 'X-XSRF-TOKEN',
		}),
		CommonModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		NgxWebstorageModule.forRoot(),
		ModalModule,
		SharedModule,
		HomeModule,
		FeaturedModule,
		UiSwitchModule.forRoot({}),
		ServiceWorkerModule.register('ngsw-worker.js'),
		MatSliderModule,
		MiscModule,
		InfiniteScrollModule
	],
	providers: [
		{ provide: 'window', useFactory: windowFactory },
		{ provide: 'navigator', useFactory: navigatorFactory },
		ModalService,
		SorterService,
		AuthGuard,
		IsAdminGuard,
		HasModelGroupDataGuard,
		HasInspirationDataGuard,
		AdvancecustomizeGuard,
		OrderOnBehalfService,
		CanCustomizeGuard,
		ImageService,
		EventsService,
		BreadcrumbService,
		LocationService,
		LocationCheckInService,
		NewsService,
		EngravingService,
		MenuService,
		SessionService,
		LanguageService,
		CookieService,
		LoginService,
		TagsService,
		UserService,
		LocalstorageService,
		TitleService,
		BagCountService,
		NavigateService,
		EnduserDisplayService,
		BrandModeService,
		OptionsDropdownService,
		ErrorReportService,
		ExternalSearchService,
		AppState,
		ApplicationInsightsService,
		CustomizerService,
		GoogleAnalyticsService,
		ConfigurationService,
		NotificationService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MiniProfilerInterceptor,
			multi: true
		},
		{
			provide: ErrorHandler,
			useClass: CustomErrorHandler
		},
		{
			provide: DeviceDetectorService,
		},
		{
			provide: SwRegistrationOptions,
			useFactory: () => ({ enabled: environment.production }),
		},
		InfiniteScrollModule
	],
	bootstrap: [
		AppComponent
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
	exports: [
	]
})
export class AppModule { }
