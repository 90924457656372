<customizer-component-header class="clip-on-component" *ngIf="designs.length" [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
    <div class="has-padding-horizontal-30px clip-on-switch" *ngIf="state !== isEditOrder">
        <ui-switch size='small' [checked]="onlyClipOnChecked" (change)="setClipOnOnly($event)" [disabled]="getSelectedVariant?.VariantColor === null"></ui-switch>
        <span [ngClass]="{'disabled':getSelectedVariant === null}">{{'CUSTOMIZER.COMPONENTS.CLIP-ON.CLIP-ONONLY' | translate}}</span>
    </div>
    <select-box
        *ngIf="!noDesign"
        [optionsCaption]="onlyClipOnChecked? '' : 'CUSTOMIZER.COMPONENTS.CLIPON.NOCLIPON'"
        [optionsImage]="getImage"
        [options]="designs"
        [editMode]="state === customizerStateEnum.EDITORDER"
        [colorMatchMode]="false"
        [colorsAvailableForMatching]="allPossibleColorsOnOtherSelectedVariants"
        [currentColor]="getSelectedVariant?.VariantColor"
        (matchColors)="matchColors(null)"
        optionsText="Description"
        optionsId="Id"
        [selected]="getSelectedVariant?.NotSelected ? null : getSelectedVariant"
        (selectedChange)="onSelected($event)"
        [optionsDiscontinued]="'Discontinued'"
        [optionsDescription2]="'Description2'"
        [variantOrModelIsNew]="variantOrModelIsNew"
        [matchColor]="menu.matchColor"
    ></select-box>
    <h4 *ngIf="noDesign">{{'CUSTOMIZER.COMPONENTS.CLIPON.NOTAVAILABLE'|translate}}</h4>
</customizer-component-header>