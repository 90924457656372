import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { SorterService } from '../../../../_services/sorter.service';
import { PreciousFinishService } from '../precious-finish.service';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'bridge-design',
	template: `
  <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)" [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.BRIDGEDESIGN.NAME'">
        <select-box *ngIf="designs.length"
        [imageClass]="'bridge'"
        [optionsImage]="getImage"
        [options]="designs"
        [editMode]="state === customizerStateEnum.EDITORDER"
        optionsText="Description"
        optionsId="Id"
        [selected]="getSelectedVariant"
        (selectedChange)="onSelect($event)"
        [optionsDiscontinued]="'Discontinued'"
        (matchColors)="matchColors(null)"
        [colorMatchMode]="true"
        [colorsAvailableForMatching]="allPossibleColorsOnOtherSelectedVariants"
        [currentColor]="getSelectedVariant.VariantColor"
        [variantOrModelIsNew]="modelIsNew"
        [matchColor]="menu.matchColor"
        ></select-box>
    </customizer-component-header>
  `
})
export class BridgeDesignComponent extends BaseCustomizerComponent implements OnInit {

	public designs: Array<ComponentVariantFromApiModel> = [];
	public variant: ComponentVariantModel;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		public imageService: ImageService,
		public sorter: SorterService,
		private preciousFinishService: PreciousFinishService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);
		await this.fetchData();
		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	getImage = (variant: ComponentVariantFromApiModel) => this.imageService.GetVariantImage(variant.ToComponentVariantModel());

	onSelect(selectedTemple: ComponentVariantFromApiModel) {
		this.events.showLoader(true);

		const current = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
		const selectedMapped = selectedTemple.ToComponentVariantModel("", this.getSelectedVariant.OrderLineUuid, true);
		this.updateVariantDesign(new ComponentVariantChangeModel(current, selectedMapped, selectedTemple.VariantSizes));
	}

	async fetchData() {
		const variant = this.getSelectedVariant;

		if (variant) {
			variant.VariantViewed = true;

			const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, variant.Component.Uuid);
			await this.RunDisablingSystem(apidata);

			let newList = apidata;

			if (apidata.some(cv => cv.Code.includes(';'))) {
				newList = this.preciousFinishService.removeDuplicatesInVariantsList(apidata);
			}

			this.designs = newList.filter(x => !x.isDisabled).sort(this.sorter.sortBy(ComponentVariantFromApiModel));
		}
	}

}
