import { Component, Input } from '@angular/core';

@Component({
  selector: 'skeleton-screen',
  templateUrl: "skeleton-screen.component.html",
  styleUrls: ['./skeleton-screen.component.scss']
})

export class SkeletonScreenComponent {
  @Input() screens: number;
  @Input() screenClass: string;
  @Input() hasLoaded: boolean;

  constructor() {
  }
}
