import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { ModelModel } from '@models/api-models/model';
import { CustomizerMenuPartModel } from '@models/customizer-menu-part';
import { CustomizerSummaryModel } from '@models/customizer-summary-model';
import { MenuComponentModel } from '@models/menu-component';
import { ModelGroupModel } from '@models/model-group';
import { ModelSizeModel } from '@models/model-size';
import { TempleInclinationEnum, getTempleInclinationEnumTranslation } from '@models/variant-temple-inclination';
import { BrandModeService } from '@services/brandmode.service';
import { EnduserDisplayService } from '@services/enduserdisplay.service';
import { EngravingService } from '@services/engraving.service';
import { LanguageService } from '@services/language.service';
import { ComponentCodeConstants } from '@shared/component-constants';
import { ProductlineConstants } from '@shared/productline-constants';

@Component({
	selector: 'customizer-summary',
	templateUrl: 'customizer-summary.html',
	styleUrls: ['./customizer-summary.scss']
})
export class CustomizerSummaryComponent implements OnChanges {

	@Input() model: ModelModel;
	@Input() modelGroup: ModelGroupModel;
	@Input() modelSize: ModelSizeModel;
	@Input() variants: Array<CustomizerSummaryModel>;
	@Input() configuration?: string;
	@Input() menu: Array<CustomizerMenuPartModel>;
	@Input() isDemo: boolean;
	@Input() compactMode: 'compact' | 'semi-compact' | 'default' | 'order-item';
	@Input() templeInclination: TempleInclinationEnum;

	variantsToShow: Array<CustomizerSummaryModel> = [];
	variantColorsToShow: Array<CustomizerSummaryModel> = [];
	menuComponents: Array<MenuComponentModel> = [];
	subMenuComponents: Array<MenuComponentModel> = [];
	groupedComponentSummaries: Array<Array<string>>;
	brand: BrandEnum
	engraving: string
	isDisplay: boolean = false
	public brandEnum: typeof BrandEnum = BrandEnum;
	public showSummaryTable: boolean = this.brandModeService.isPrecious;

	constructor(private languageService: LanguageService,
		private brandModeService: BrandModeService,
		private engravingService: EngravingService,
		private enduserDisplayService: EnduserDisplayService,
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.brand = this.brandModeService.currentBrandValue();
		this.engraving = this.engravingService.engraving;
		this.isDisplay = this.enduserDisplayService.IsDisplay();

		if (changes.menu && changes.menu.currentValue) {
			this.menuComponents = (changes.menu.currentValue as Array<CustomizerMenuPartModel>).selectMany(x => x.menuComponents);
			this.subMenuComponents = (changes.menu.currentValue as Array<CustomizerMenuPartModel>).selectMany(x => x.subComponents.selectMany(y => y.menuComponents));
		}

		if (changes.variants && changes.variants.currentValue) {
			this.variantsToShow = (changes.variants.currentValue as Array<CustomizerSummaryModel>)
				.filter(a => this.menuComponents.some(x => (x.ComponentUuids && x.ComponentUuids == a.ComponentUuid) || (x.ComponentVariantUuids && x.ComponentVariantUuids == a.ComponentUuid)) ||
					this.subMenuComponents.some(x => (x.ComponentUuids && x.ComponentUuids == a.ComponentUuid) || (x.ComponentVariantUuids && x.ComponentVariantUuids == a.ComponentUuid)))
				.sort(this.sortComponents);
		}

		// GROSS HACK TO DISPLAY SEPARATE LEFT AND RIGHT TEMPLE CLIP LENGTHS
		let templeClipVariant = this.variantsToShow.find(v => v.ComponentCode === 'TEMPLE_CLIP' && v.SplittingAllowed);
		if (templeClipVariant && templeClipVariant.ComponentSplittingDataLeft && templeClipVariant.ComponentSplittingDataRight) {
			templeClipVariant.ComponentVariantSizeDescription = templeClipVariant.ComponentSplittingDataLeft + ' (' + this.languageService.instant('COMMON.LEFT') + ') / ' + templeClipVariant.ComponentSplittingDataRight + ' (' + this.languageService.instant('COMMON.RIGHT') + ')';
		}

		const mainComponentTypes = this.variantsToShow.filter(x => !x.ComponentVariantByPass && !x.NotSelected && x.ComponentCode != ComponentCodeConstants.Clipon);

		const groupedComponentSummaries: Array<Array<string>> = [];

		mainComponentTypes.forEach(mainComponent => {
			let componentSummary: Array<string> = [];
			componentSummary = componentSummary.concat(this.extractComponentSummaryInformation(mainComponent));

			this.variantsToShow.filter(variant => variant.ComponentCode.indexOf(mainComponent.ComponentCode) > -1 && variant.ComponentCode != mainComponent.ComponentCode).forEach(subComponent => {
				componentSummary = componentSummary.concat(this.extractComponentSummaryInformation(subComponent));
			});

			groupedComponentSummaries.push(componentSummary);
		});

		this.groupedComponentSummaries = groupedComponentSummaries;

		if (this.model.ProductLineCode == ProductlineConstants.SUN) {
			this.variantColorsToShow = this.variantsToShow.filter(x => x.ComponentCode == ComponentCodeConstants.Lenses || x.ComponentCode == ComponentCodeConstants.Cover).sort(this.sortSubVariants);
		}
		else if (this.configuration) {
			this.variantColorsToShow = this.variantsToShow.filter(x => x.ComponentCode == ComponentCodeConstants.Lenses).sort(this.sortSubVariants);
		}
		else {
			this.variantColorsToShow = this.variantsToShow.filter(x => x.ComponentCode != ComponentCodeConstants.BridgeClip).sort(this.sortSubVariants);
		}
	}

	private extractComponentSummaryInformation(component: CustomizerSummaryModel): Array<string> {
		const componentSummary: Array<string> = [];

		if (!component.ComponentVariantByPass) {
			componentSummary.push(this.languageService.instantTranslateData(component.ComponentDescription));
		}

		if (component.VariantSize && !component.ComponentVariantSizeBypass) {
			if (component.SplittingAllowed && component.ComponentSplittingDataLeft && component.ComponentSplittingDataRight) {
				componentSummary.push(component.ComponentSplittingDataLeft);
				componentSummary.push(component.ComponentSplittingDataRight);
			}
			else {
				componentSummary.push(component.ComponentVariantSizeDescription);

				if (this.model.LowTempleInclinationPossible && component.ComponentCode === ComponentCodeConstants.Temple) {
					componentSummary.push(getTempleInclinationEnumTranslation(this.templeInclination ?? TempleInclinationEnum.standard, this.languageService));
				}
			}
		}

		return componentSummary;
	}

	public logVariantInfo(variant: CustomizerSummaryModel) {
		console.warn(variant);
	}

	private sortComponents(a: CustomizerSummaryModel, b: CustomizerSummaryModel): number {
		let sortingArr: string[] = ['front', 'upperrim', 'lowerrim', 'bridge', 'bridge_clip', 'temple', 'temple_clip', 'innerrim', 'nosepad', 'gemstone', 'clipon'];

		let r = 1;

		if (sortingArr.includes(a.ComponentCode?.toLowerCase()) && !sortingArr.includes(b.ComponentCode?.toLowerCase())) {
			r = -1;
		}
		else if (sortingArr.includes(b.ComponentCode?.toLowerCase()) && !sortingArr.includes(a.ComponentCode?.toLowerCase())) {
			r = 1;
		}
		else if (sortingArr.includes(a.ComponentCode?.toLowerCase()) && sortingArr.includes(b.ComponentCode?.toLowerCase())) {
			r = sortingArr.indexOf(a.ComponentCode?.toLowerCase()) - sortingArr.indexOf(b.ComponentCode?.toLowerCase());
		}

		return r;
	}

	private sortSubVariants = (a: CustomizerSummaryModel, b: CustomizerSummaryModel): number => {
		const submenuComponents = this.subMenuComponents.map(x => x.ComponentUuids);

		const aLastIndex = submenuComponents.lastIndexOf(a.ComponentUuid);
		const bLastIndex = submenuComponents.lastIndexOf(b.ComponentUuid);

		return aLastIndex < bLastIndex ? -1 : 1;
	}

}
