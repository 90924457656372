<item-template class="item-template">
    
    <!-- Knapper, Amount, slet og rediger -->

    <misc-buttons images class="image-header-row-mobile visible-xs"
        [addToBagDisabled]="!discountValidationErrors.isValid"
        [(amount)]="amount"
        (amountChange)="amount=$event"
        [maxQuantity]="model.MaximumOrderQty"
        [miscItem]="model"
        [showAdd]="false"
        [addfunc]="addTobag"
        [amountDebounce]="0"
        >
    </misc-buttons>

    <!-- Billeder -->
    <div class="images" images>
        <misc-image  [eCommerceNo]="model.EcommerceNoUnique"></misc-image>
    </div>

    <!-- Indhold, overskrift,  data -->
    <div class="content-header-container" content>

        <div class="content-header">
            <div>
                <div class="font-medium" [innerHTML]="model.Description | lindbergFont: true"></div>
            </div>

            <!-- Knapper, Amount, slet og rediger -->
            <misc-buttons images class="content-actions hidden-xs"
                [miscItem]="model"
                [maxQuantity]="model.MaximumOrderQty"
                [showRemove]="false" [showAdd]="false"
                [amount]="amount" (amountChange)="amount=$event" [amountDebounce]="0">
            </misc-buttons>
        </div>

        <div class="content-row">

            <div class="font-medium">{{model.EcommerceNoUnique}}</div>
            <div class="extendedContainer">
                <div *ngFor="let stockKeepingUnit of model.StockKeepingUnitExtendedTexts" [ngClass]="{specialExtended : stockKeepingUnit.TextStyle === 'Bold', 'font-medium': stockKeepingUnit.TextStyle === 'Bold'}">
                    <span [innerHTML]="stockKeepingUnit.Text | lindbergFont"></span>
                    <br *ngIf="!stockKeepingUnit.Text"/>
                </div>
            </div>

        </div>
    </div>

    <div class="bottom-content" bottom-content>

        <div>
            <span>{{'CUSTOMIZER.COMPONENTS.REFERENCE.NAME' | translate}}</span>
        </div>

        <div class="reference-addtobag">
            <div class="flex-3">

                <input class="form-control form-group" type="text" [ngModel]="reference"
                    (ngModelChange)="updateReference($event)" [placeholder]="'CUSTOMIZER.COMPONENTS.REFERENCE.NAME' | translate"
                    [maxLength]=50 />
            </div>
            <div class="flex-1">
                <button class="addtobag btn btn btn-primary btn-short pull-right mr-3"
                    *ngIf="canAdd" (click)="addTobag()"
                    title="{{ model.ItemExistsInBag || wasAddedToBag ? ('ITEM.ALREADY.EXISTS.IN.BAG' | translate) : ''}}"
                    [disabled]="this.wasAddedToBag || this.showAddedToBag || !canAdd || !discountValidationErrors.isValid || model.ItemExistsInBag">
                        <label *ngIf="this.showAddedToBag">{{"CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDTOBAG" | translate}}</label>
                        <label *ngIf="!this.showAddedToBag">{{"CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDTOBAG" | translate}}</label>
                </button>
            </div>

        </div>

        <div class="discounts-container-orderitem margin-top-20" *ngIf="(orderOnBehalfService.customerAsObservable() | async)?.isConsultantOrderOnBehalf">
            <customizer-discounts
                class="flex"
                [discount]="discountModel" [discountsList]="discountsList"
                [discountErrors]="discountValidationErrors" (discountChange)="updateDiscount($event)">
            </customizer-discounts>
        </div>

        <div class="summary-container" *ngIf="!discountValidationErrors.isValid">
            <error-summary [errors]="discountValidationErrors"></error-summary>
        </div>
    </div>

</item-template>

<div class="overlay-addedtobag" *ngIf="this.showAddedToBag" [@fadeInOutTransition]>
    <ng-container *ngIf="isAddingToTestBag">
        <span>{{'CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDTO' | translate}}</span>
        <span class='test-bag-warning'>{{'CUSTOMIZER.COMPONENTS.CONFIGURATION.YOURTESTBAG' | translate: {count: amount} }}</span>
    </ng-container>
    <span *ngIf="!isAddingToTestBag">{{'CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDTOBAGCOUNT' | translate: {count: amount} }}</span>
</div>