import { Injectable } from "@angular/core";
import { ComponentVariantModel } from "@models/component-variant";
import { CustomiserNotification } from "@models/customiser-notification-model";
import { SignalrUser } from "@models/user-data-model";
import { ModelModel } from 'src/_models/api-models/model';
import { ColorGroupModel } from 'src/_models/color-group';
import { ConfigurationModel } from 'src/_models/configuration';
import { ModelSizeModel } from 'src/_models/model-size';
import { VariantColorModel } from 'src/_models/variant-color';
import { VariantSizeModel } from 'src/_models/variant-size';
import { ComponentVariantFromApiModel } from "../_models/api-models/component-variant-fromapi";
import { ManualConfig } from '../_models/manualconfig';
import { MiscModel } from "../_models/misc-model";
import { TagGroupModel, TagModel, TagTypeModel } from './../_models/tag';
import { LanguageService } from './language.service';

@Injectable()
export class SorterService {
    constructor(private languageService: LanguageService) {
    }

    static sortByFunc(a: string | number, b: string | number, order: string = "asc", lastValue: string = ""): number {
        const varA = (typeof a === "string") ? (!isNaN(Number(a)) ? parseInt(a, 10) : a.toUpperCase()) : a;
        const varB = (typeof b === "string") ? (!isNaN(Number(b)) ? parseInt(b, 10) : b.toUpperCase()) : b;

        let comparison = 0;
        if (lastValue && String(varA).includes(lastValue) && !String(varB).includes(lastValue)) {
            comparison = 1;
        } else if (lastValue && String(varB).includes(lastValue) && !String(varA).includes(lastValue)) {
            comparison = -1;
        } else if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }

        return (order == 'desc' && comparison != 0) ? (comparison * -1) : comparison;
    }

    sortByConfiguration(a: ConfigurationModel, b: ConfigurationModel, order: string = "asc"): number {

        return SorterService.sortByFunc(a.ModelConfigurationCode, b.ModelConfigurationCode);
    }

    sortByTagGroup(a: TagGroupModel, b: TagGroupModel, order: string = "asc"): number {

        return SorterService.sortByFunc(a.Order, b.Order);
    }

    sortByCustomiserNotification(a: CustomiserNotification, b: CustomiserNotification, order: string = "desc"): number {

        return SorterService.sortByFunc(new Date(a.CreatedAt).getTime(), new Date(b.CreatedAt).getTime(), order);
    }

    sortBySignalrUser(a: SignalrUser, b: SignalrUser, order: string = "desc"): number {

        return SorterService.sortByFunc(new Date(a.LastActivity).getTime(), new Date(b.LastActivity).getTime(), order);
    }

    sortByComponentId(a: ComponentVariantModel, b: ComponentVariantModel, order: string = "asc"): number {

        return SorterService.sortByFunc(a.Component.Id, b.Component.Id, order);
        
    }

    sortByTagType(a: TagTypeModel, b: TagTypeModel, order: string = "asc"): number {

        return SorterService.sortByFunc(a.Order, b.Order);
    }

    sortByTag(a: TagModel, b: TagModel, order: string = "asc"): number {

        return SorterService.sortByFunc(a.Order, b.Order);
    }

    sortByColor = (a: VariantColorModel, b: VariantColorModel, order: string = "asc"): number => {
        if (a.Order == b.Order) {
            return SorterService.sortByFunc(this.languageService.instantTranslateData(a.Color.Description), this.languageService.instantTranslateData(b.Color.Description));
        }

        return SorterService.sortByFunc(a.Order, b.Order);
    }

    sortByColorGroup = (a: ColorGroupModel, b: ColorGroupModel, order: string = "asc"): number => {
        if (a.Order == b.Order) {
            return SorterService.sortByFunc(this.languageService.instantTranslateData(a.Description), this.languageService.instantTranslateData(b.Description));
        }

        return SorterService.sortByFunc(a.Order, b.Order);
    }

    sortBySize = (a: VariantSizeModel, b: VariantSizeModel, order: string = "asc"): number => {
        if (a.Order == b.Order) {
            return SorterService.sortByFunc(a.Size, b.Size);
        }

        return SorterService.sortByFunc(a.Order, b.Order);
    }

    sortByVariant = (a: ComponentVariantFromApiModel, b: ComponentVariantFromApiModel, order: string = "asc"): number => {
        if (a.Order == b.Order) {
            return SorterService.sortByFunc(this.languageService.instantTranslateData(a.Description), this.languageService.instantTranslateData(b.Description));
        }

        return SorterService.sortByFunc(a.Order, b.Order);
    }

    sortByVariantGroup = (a: ComponentVariantFromApiModel, b: ComponentVariantFromApiModel, order: string = "asc"): number => {
        if (a.Order == b.Order) {
            return SorterService.sortByFunc(this.languageService.instantTranslateData(a.Group), this.languageService.instantTranslateData(b.Group));
        }

        return SorterService.sortByFunc(a.Order, b.Order);
    }


    sortByModelsize(a: ModelSizeModel, b: ModelSizeModel, order: string = "asc"): number {
        return SorterService.sortByFunc(a.Code, b.Code, order, "ASIA");
    }

    sortByMisc(a: MiscModel, b: MiscModel, order: string = "asc"): number {
        let sortResult = SorterService.sortByFunc(a.BinCode, b.BinCode, order);

        if (sortResult == 0) {
            sortResult = SorterService.sortByFunc(a.ShelfNo, b.ShelfNo, order);
        }

        return sortResult;
    }

    sortByConsultantCollection = (a: ManualConfig, b: ManualConfig, order: string = "asc"): number => {
        let sortResult = this.sortByModel(a.Model, b.Model);
        if (sortResult == 0) {
            sortResult = SorterService.sortByFunc(a.Release.valueOf(), b.Release.valueOf(), "desc");
        }

        return sortResult;
    }

    sortByModel(a: ModelModel, b: ModelModel): number {
        const sortResult = SorterService.sortByFunc(a.ModelGroupDescription, b.ModelGroupDescription, "asc");
        const sortResult1 = SorterService.sortByFunc(a.Order, b.Order, "desc");

        if (sortResult != 0 ) {
            return sortResult;
        } else {
            return sortResult1;
        }
    }

    public sortBy = (type: any, order: string = 'asc') => {        
        const self = this;
        return (a: VariantColorModel | VariantSizeModel | ComponentVariantFromApiModel | ModelSizeModel | ConfigurationModel | MiscModel | TagGroupModel | TagModel | TagTypeModel | CustomiserNotification | SignalrUser | ComponentVariantModel,
            b: VariantColorModel | VariantSizeModel | ComponentVariantFromApiModel | ModelSizeModel | ConfigurationModel | MiscModel | TagGroupModel | TagModel | TagTypeModel | CustomiserNotification | SignalrUser | ComponentVariantModel) => {
            if (type == VariantColorModel) {
                return self.sortByColor(a as VariantColorModel, b as VariantColorModel, order);
            } else if (type == VariantSizeModel) {
                return self.sortBySize(a as VariantSizeModel, b as VariantSizeModel, order);
            } else if (type == ComponentVariantFromApiModel && (!(a as ComponentVariantFromApiModel).Group || !(b as ComponentVariantFromApiModel).Group)) {
                return self.sortByVariant(a as ComponentVariantFromApiModel, b as ComponentVariantFromApiModel, order);
            } else if (type == ComponentVariantFromApiModel && ((a as ComponentVariantFromApiModel).Group && (b as ComponentVariantFromApiModel).Group)) {
                return self.sortByVariantGroup(a as ComponentVariantFromApiModel, b as ComponentVariantFromApiModel, order);
            } else if (type == ModelSizeModel) {
                return self.sortByModelsize(a as ModelSizeModel, b as ModelSizeModel, order);
            } else if (type == MiscModel) {
                return self.sortByMisc(a as MiscModel, b as MiscModel, order);
            } else if (type == ConfigurationModel) {
                return self.sortByConfiguration(a as ConfigurationModel, b as ConfigurationModel, order);
            } else if (type == TagTypeModel) {
                return self.sortByTagType(a as TagTypeModel, b as TagTypeModel, order);
            } else if (type == TagModel) {
                return self.sortByTag(a as TagModel, b as TagModel, order);
            } else if (type == TagGroupModel) {
                return self.sortByTagGroup(a as TagGroupModel, b as TagGroupModel, order);
            } else if (type == CustomiserNotification) {
                return self.sortByCustomiserNotification(a as CustomiserNotification, b as CustomiserNotification, order);
            } else if (type == SignalrUser) {
                return self.sortBySignalrUser(a as SignalrUser, b as SignalrUser, order);
            } else if (type == ComponentVariantModel) {
                return self.sortByComponentId(a as ComponentVariantModel, b as ComponentVariantModel, order);
            }

            return -1;
        };
    }
}
