export const MenuComponentConstants = {
    BridgeDesignSizeClipLength: "BridgeDesignSizeClipLength",
    TempleClipLength: "TempleClipLength",
    InnerrimDesign: "InnerrimDesign",
    Lenses: "Lenses",
    ClipOn: "ClipOn",
    Configuration: "Configuration",
    ConfigurationPlus: "ConfigurationPlus",
    ModelSize: "ModelSize"
};
