import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { OrderItemAskForOrderIdError } from '@infrastructure/order-item-ask-for-order-id-error';
import { SubscribingComponent } from '@infrastructure/subscribing-component';
import ValidationResult from '@infrastructure/validation-result';
import { OrderChooserModalComponent } from '@modals/order-chooser-modal/order-chooser-modal.component';
import { CCDiscountModel, DiscountModel } from '@models/discounts/discount-model';
import { MiscModel } from '@models/misc-model';
import { OrderItemMiscModel } from "@models/order-item-misc-model";
import { OrderItemMiscType } from "@models/order-item-misc-type";
import { EventsService } from '@services/events.service';
import { ModalService } from '@services/modal.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { OrderService } from "@services/order.service";
import { UserService } from '@services/user.service';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { UserTypeConstant } from "@shared/user-type-constants";

@Component({
    selector: "misc-list-item",
    templateUrl: "misc-list-item.html",
    styleUrls: ["./misc-list-item.scss"],
    animations: [
        FadeInOutTransition(0.8)
    ]
})
export class MiscListItemComponent extends SubscribingComponent implements OnInit {
    @Input() public model: MiscModel;

    public reference: string = "";
    public amount: number = 1;
    public canAdd: boolean = false;

    public showAddedToBag: boolean = false;
    public wasAddedToBag: boolean = false;

    public discountValidationErrors: ValidationResult = new ValidationResult();
    public discountModel: DiscountModel = null;
    public discountsList: Array<DiscountModel> = [new CCDiscountModel()];
    public isAddingToTestBag: boolean = false;

    private orderSelectEmitter = new EventEmitter<number>();
    private orderId: number = null;

    constructor(private userService: UserService, private addtobagService: OrderService, private modalService: ModalService, private eventService: EventsService, public orderOnBehalfService: OrderOnBehalfService) {
        super();
        this.subscriptions.push(this.orderSelectEmitter.subscribe(async (x) => {
            this.orderId = x;
            await this.addTobag();
            this.orderId = null;
        }));
    }

    async ngOnInit() {
        this.canAdd = await this.userService.UserHasAddToBagPermission();
    }

    public addTobag = async () => {
        try {

            const createModel = OrderItemMiscModel.Create(
                this.orderId,
                this.reference,
                this.amount,
                null,
                false,
                null,
                false,
                OrderItemMiscType.StockKeepingUnit,
                this.discountModel?.key,
                this.discountModel?.percentage,
                this.model);

            const success = await this.addtobagService.AddMiscToBag(createModel);

            if (success)
                this.ShowAddedToBag();

            return success;
        } catch (error) {
            if (error instanceof OrderItemAskForOrderIdError) {
                this.modalService.create(OrderChooserModalComponent, { orderSelect: this.orderSelectEmitter });
            } else {
                throw error;
            }
        } finally {
            this.eventService.showLoader(false);
        }
    }

    private async ShowAddedToBag() {
        const customer = this.orderOnBehalfService.customerSnapshot();
        const user = await this.userService.GetUser();
        this.isAddingToTestBag = !customer && user.canOrderOnBehalf && user.Type === UserTypeConstant.Consultant;
        this.showAddedToBag = true;

        await new Promise<void>((resolve) => {
            setTimeout(() => {
                resolve();
            }, 2000);
        });

        this.showAddedToBag = false;
        this.wasAddedToBag = true;
    }

    updateReference(reference: string) {
        this.reference = reference;
    }
    updateDiscount(model: DiscountModel) {
        this.discountModel = model;
        this.discountValidationErrors = this.discountModel ? this.discountModel.validate(null) : new ValidationResult();
    }
}
