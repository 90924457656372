<div *ngIf="color" [ngClass]="{'color-container' : true }" class="color-container">
  <ng-container>
    <availability-text [date]="color.Discontinued" [new]="color.New" [useNewDetails]="false"></availability-text>
  </ng-container>
  <div class="image" [ngClass]="{'extra-tall-color': extraTallColor }">
      <dcg-image [src]="imageService.GetColorImages(color.Color.ColorLine.Code, color.Color.Code)" [alt]="color.Color.Code"></dcg-image>
  </div>
  <div class="descriptions">
    <div class="color-descriptions">
        <div *ngIf="showComponentName">
            <h6>{{color.ComponentCode.toLowerCase()}}</h6>
        </div>

        <h6 *ngIf="color.Color.Description && color.Color.Description.Fallback.toLocaleLowerCase() !== color.Color.Code.toLocaleLowerCase()" [innerHTML]="color.Color.Description || '' | dataTranslate | translate"></h6>
        <h6 *ngIf="color.Color.ColorGroup && color.Color.ColorGroup.Description" [innerHTML]="color.Color.ColorGroup.Description || '' | dataTranslate | translate"></h6>
        <h6 *ngIf="color.Color.Description2" [innerHTML]="color.Color.Description2 || '' | dataTranslate | translate"></h6>

        <div *ngIf="modelGroups">
            <h6>{{modelGroups}}</h6>
        </div>
    </div>
    <div class="color">
        <h6 *ngIf="color.Color.Code.toLowerCase() === color.Color.Description.Fallback.toLowerCase()" [innerHTML]="color.Color.Description || '' | dataTranslate | translate"></h6>
        <h6 *ngIf="color.Color.Code.toLowerCase() !== color.Color.Description.Fallback.toLowerCase()" [innerHTML]="color.Color.Code || '' | dataTranslate | translate"></h6>
    </div>
  </div>
</div>