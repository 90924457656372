import { Component, Input } from "@angular/core";
import { ModelModel } from '@models/api-models/model';

@Component({
	templateUrl: "model-item.component.html",
	selector: "model-item",
	styleUrls: ['./model-item.component.scss']
})
export class ModelItemComponent {

	@Input() imageClass: string;
	@Input() new: boolean;
	@Input() model: ModelModel;
	@Input() discontinued: Date;
	@Input() imageUrl: string;
	@Input() title: string;
	@Input() description: string;
	@Input() codes: Array<string>;
	@Input() ModelCodeNotAvailable: string;

}
