import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConstants } from 'src/app.constants';
import { EnvService } from '../_services/env.service';
import { LoginService } from '../_services/login.service';
import { OrderOnBehalfService } from '../_services/order-on-behalf.service';
declare const MiniProfiler: any;

@Injectable() export class AuthInterceptor implements HttpInterceptor {
	private readonly customerHeaderKey = 'customer';

	constructor(private loginService: LoginService, private orderOnBehalfService: OrderOnBehalfService, private envService: EnvService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers: { [name: string]: string | string[]; } = {};

		const accessToken = this.loginService.GetToken();

		if (accessToken) {
			headers['Authorization'] = `Bearer ${accessToken}`;
		}

		let appVersion = this.envService.getVersion();

		if (!appVersion) {
			appVersion = AppConstants.version;
		}

		if (appVersion) {
			if (appVersion.indexOf('#') > -1) {
				appVersion = AppConstants.version;
			}

			headers["appVersion"] = appVersion;
		}

		const customer = this.orderOnBehalfService.customerSnapshot();

		if (customer?.Token) {
			headers[this.customerHeaderKey] = customer.Token;
		}

		// if (!req.url.includes('dcgcontent') && !req.url.includes('customiser-images') && accessToken) {
		if (!req.url.includes('dcgcontent') && !req.url.includes('customiser-images')) {
			req = req.clone({
				setHeaders: headers
			});
		}

		return next.handle(req);
	}
}

@Injectable() export class MiniProfilerInterceptor implements HttpInterceptor {
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(tap(evt => {
			if (evt instanceof HttpResponse) {
				if (typeof MiniProfiler !== 'undefined' && evt && evt.headers) {
					this.makeMiniProfilerRequests(evt.headers);
				}
			}
		}));
	}

	private makeMiniProfilerRequests(headers: HttpHeaders) {
		const miniProfilerHeaders = headers.getAll('x-miniprofiler-ids');

		if (!miniProfilerHeaders) {
			return;
		}

		miniProfilerHeaders.forEach(miniProfilerIdHeaderValue => {
			const ids = JSON.parse(miniProfilerIdHeaderValue) as Array<string>;
			MiniProfiler.fetchResults(ids);
		});
	}
}
