import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InspirationGroupModel } from '@models/inspiration-group';
import { ManualConfigCreateModel } from '@models/manual-config-create';
import { ManualConfig } from '@models/manualconfig';
import { VariantStringModel } from '@models/variant-string-model';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import { BrandModeService } from '@services/brandmode.service';
import { EventsService } from '@services/events.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { Background, ImageService, ImageSize } from '@services/image.service';
import { ManualConfigService } from '@services/manual-config.service';
import { UserService } from '@services/user.service';
import { FeaturedGroupConstant, ManualConfigGroupConstant } from '@shared/featured-group-constant';
import { PermissionConstants } from '@shared/permission-constants';
import { BehaviorSubject } from 'rxjs';
import { ModelModel } from 'src/_models/api-models/model';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { ModelSizeModel } from 'src/_models/model-size';

@Component({
    selector: 'add-to-favorite',
    templateUrl: 'add-to-favorite.html',
    styleUrls: ['./add-to-favorite.scss']
})
export class AddToFavoriteComponent implements OnInit, OnChanges {
    @Input() configuration: ConfigurationModel;
    @Input() modelSize: ModelSizeModel;
    @Input() model: ModelModel;
    @Input() variants: Array<ComponentVariantModel>;
    @Input() manualconfigGroups: Array<InspirationGroupModel>;
    @Input() favoriteUuid: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    @Input() isInCollection: boolean = false;
    @Input() templeInclination: TempleInclinationEnum;
    @Output() hasBeenDeleted: EventEmitter<ManualConfig> = new EventEmitter();
    @Output() public applyFavoriteChange: EventEmitter<BehaviorSubject<string>> = new EventEmitter();
    @Output() public applyFavoriteCollectionChange: EventEmitter<boolean> = new EventEmitter();
    @Output() public showMyCollectionsOverlay: EventEmitter<void> = new EventEmitter();
    @Output() public applyFavoriteCollectionChangeEmitter: EventEmitter<boolean> = new EventEmitter();

    public userCanUseFavorites: boolean;
    public initialised: boolean = false;
    public variantStringModel: VariantStringModel = null;

    public isFavorite: boolean = false;

    constructor(
        private eventsService: EventsService,
        private userservice: UserService,
        private manualConfigService: ManualConfigService,
        private brandModeService: BrandModeService,
        private imageService: ImageService,
        private googleAnalyticsService: GoogleAnalyticsService,
    ) {
    }

    async ngOnChanges() {
        if (this.initialised) {
            if (this.userCanUseFavorites) {
                this.favoriteUuid.next(await this.getCurrentConfigurationFavoriteUuid());
            }
        }
    }

    async ngOnInit() {
        const permissions = (await this.userservice.GetUser()).UserPermissions;
        this.userCanUseFavorites = permissions.some(x => x == PermissionConstants.CanUserFavorites);

        if (this.userCanUseFavorites) {
            this.favoriteUuid.next(await this.getCurrentConfigurationFavoriteUuid());
            //this.isInCollection = await this.setIsInCollection();
        }

        if (this.favoriteUuid && this.favoriteUuid.value) {
            this.isFavorite = true;
        }
        else {
            this.isFavorite = false;
        }

        this.favoriteUuid.subscribe(f => {
            if (this.favoriteUuid && this.favoriteUuid.value) {
                this.isFavorite = true;
            }
            else {
                this.isFavorite = false;
            }
        });

        this.initialised = true;
    }

    public async toggleFavorite() {
        if (this.favoriteUuid.getValue()) {
            this.RemoveFavorite();
        } else {
            this.MakeFavorite();
        }
    }

    public async showMyCollections() {
        this.showMyCollectionsOverlay.emit();
    }

    public async MakeFavorite() {
        this.eventsService.showLoader(true);
        const now = new Date();
        const releasedate = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
        releasedate.setUTCFullYear(now.getFullYear(), now.getUTCMonth(), now.getUTCDate());

        const manualconfigGroupId = this.manualconfigGroups.find(x => x.Type == FeaturedGroupConstant.Favorite).Id;

        let imageUrls: Array<string> = [];

        if (this.model.IsRimless) {
            imageUrls.push(this.imageService.GetFrontImage(this.model, this.model.ModelGroupCode, this.configuration.ModelConfigurationCode,
                this.variants, ImageSize.Medium, Background.Default));
            imageUrls.push(this.imageService.GetSideImage(this.model, this.model.ModelGroupCode, this.configuration.ModelConfigurationCode,
                this.variants, ImageSize.Medium, Background.Default));
        }
        else {
            imageUrls.push(this.imageService.GetPerspectiveImage(this.model, this.model.ModelGroupCode,
                this.configuration.ModelConfigurationCode, this.variants, ImageSize.Medium, Background.Default));
        }

        const manualConfiguration = ManualConfigCreateModel.CreateFromData(
            manualconfigGroupId,
            releasedate,
            this.model,
            this.configuration,
            this.modelSize,
            this.variants,
            this.templeInclination,
            0,
            this.brandModeService.isPrecious,
            imageUrls
        );

        const newFavoriteUuid = await this.manualConfigService.createInspirationConfiguration(manualConfiguration);

        this.favoriteUuid.next(newFavoriteUuid);
        this.applyFavoriteChange.emit(this.favoriteUuid);

        this.eventsService.showLoader(false);

        const user = this.userservice.currentUser$.getValue();

        if (user) {
            let message = `${user.Uuid} hearted a ${this.model.Code} frame`;
            await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', message, 30);
        }
    }

    public async RemoveFavorite(currentFavoriteUuid: string = null) {
        this.eventsService.showLoader(true);

        let favoriteUuid = this.favoriteUuid.getValue();

        if (currentFavoriteUuid) {
            favoriteUuid = currentFavoriteUuid;
        }

        await this.manualConfigService.deleteInspirationConfigurationFromUuid(favoriteUuid);
        await this.manualConfigService.clearManualConfig(ManualConfigGroupConstant.Favorites);

        if (!currentFavoriteUuid) {
            this.favoriteUuid.next(null);
            this.applyFavoriteChange.emit(this.favoriteUuid);
        }

        this.eventsService.showLoader(false);

        const user = this.userservice.currentUser$.getValue();

        if (user) {
            let message = `${user.Uuid} unhearted a frame`;
            await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', message, 10);
        }
    }

    private async getCurrentConfigurationFavoriteUuid(): Promise<string> {
        const variantStrings = (await this.manualConfigService.getVariantStrings()).filter(m => m.ManualConfigCollectionId === 0);
        const currentVariantString = await this.manualConfigService.getVariantString(this.model, this.variants);
        return variantStrings.find(v => v.VariantString === currentVariantString)?.ManualConfigurationUuid;
    }

}
