export enum PermissionConstants {
	ConfigureProduct = "ConfigureProduct",
	PlaceOrder = "PlaceOrder",
	PlaceTestOrder = "PlaceTestOrder",
	CanSeeAndOrderAllProducts = "CanSeeAndOrderAllProducts",
	IsAdmin = "IsAdmin",
	CanAddToBag = "CanAddToBag",
	CanOrderOnBehalf = "CanOrderOnBehalf",
	CanEditManualConfigurations = "CanEditManualConfigurations",
	CanUserFavorites = "CanUserFavorites",
	CanUseLoadData = "CanUseLoadData",
	CanOrderOnBehalfOfChainCustomer = "CanOrderOnBehalfOfChainCustomer",
	CanUseNonBlockingOfflineAddToBag = "CanUseNonBlockingOfflineAddToBag",
	NewCustomerModuleEnabled = "NewCustomerModuleEnabled",
	PreciousModuleEnabled = "PreciousModuleEnabled",
	PriceModuleEnabled = "PriceModuleEnabled",
	PartsEnabled = "PartsEnabled",
	CanOrderAir = "CanOrderAir"
}
