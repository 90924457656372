import { SimpleModelModel } from '@models/api-models/simple-model-model';
import { Type } from 'class-transformer';
import { FeaturedGroupConstant } from './../shared/featured-group-constant';

export class InspirationGroupModel {

	Id: number;
    Uuid: string;
    Name: string;
    IsHidden: boolean;
    Type: FeaturedGroupConstant;
    ImageId: string;

    @Type(() => SimpleModelModel)
    Models: Array<SimpleModelModel>;

	hideOnFrontpage: boolean;
}

/**
 * @deprecated Should be changed to the type instead
 */
export enum InspirationGroupEnum {
    "screenshows" = 1,
    "inspiration" = 2,
    "campaign" = 3,
    "consultantcollection" = 4,
}
