import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ImageServerInfoModel, ServerInfoModel } from '@models/server-info-model';
import { HttpOfflineError } from '@shared/http-offline-error';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { OfflineService } from './offline.service';
import { StoreService } from './store.service';


@Injectable({
    providedIn: 'root'
})
export class ServerInfoService {
    public baseUrlImageCdnServer: () => Promise<string> = async () => await this.env.ImageCdnUrl();
    public baseUrlImageServer: () => Promise<string> = async () => await this.env.ImageUrl();

    constructor(

        private offlineService: OfflineService,
        private env: EnvService,
        private indexedDbService: IndexDbService


    ) {

    }

    public async getServerInfo(): Promise<ServerInfoModel> {
        const endpoint = `${await this.env.baseUrl()}/serverinfo`;
        const serverinfo = await this.getNetworkFirst<ServerInfoModel>(endpoint);
        return serverinfo;
    }

    public async getImageServerInfo(): Promise<ImageServerInfoModel> {
        const endpoint = `${await this.baseUrlImageServer()}/hostinfo.ashx`;
        const serverinfo = await this.getNetworkFirst<ImageServerInfoModel>(endpoint, this.getOptionsForImageServerRequest());
        return serverinfo;
    }

    public async getImageCdnServerInfo(): Promise<ImageServerInfoModel> {
        const endpoint = `${await this.baseUrlImageCdnServer()}/hostinfo.ashx`;
        const serverinfo = await this.getNetworkFirst<ImageServerInfoModel>(endpoint, this.getOptionsForImageServerRequest());
        return serverinfo;
    }

    public async getMiscImageCount(eCommerNo: string): Promise<number> {
        const imageUrl = await this.env.ImageUrl();

        return this.getNetworkFirst<number>(`${imageUrl}/product/${eCommerNo}/List`, this.getOptionsForImageServerRequest());
    }
    private getOptionsForImageServerRequest(): any {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set("Content-Type", "text/plain");
        httpHeaders.set("Accept", "*/*");
        httpHeaders.delete("Origin");
        const options = { headers: httpHeaders, withCredentials: false };
        return options;
    }

    public async getNetworkFirst<T>(url: string, options: any = undefined): Promise<T> {
        let result: T = null;

        try {
            result = await this.offlineService.getDataWithOfflineCheck<T>(url, options);

            if (this.indexedDbService.databaseIsOpen) {
                await this.indexedDbService.replaceAndSave<T>(StoreService.storeName, result, url);
            }

            return result;
        }
        catch (e) {
            if (e instanceof HttpOfflineError) {
                return await this.indexedDbService.get<any>(StoreService.storeName, url);
            }

            throw e;
        }
    }

}
