export class SalesDistrictModel {
    code: string;
    partnerEntryLevel: number;
    salesPersons: SalesDistrictSalesPersonModel[];
}

export class SalesDistrictSalesPersonModel {
    salespersonCode: string;
    validFrom: string;
    validTo: string;
    isPrimary: boolean;
    salesDistrictType: SalesDistrictType;
}
export enum SalesDistrictType {
    None = 0,
    OwnDistrict = 1,
    OtherDistricts = 2
}