import { DataTranslationModel } from '@models/data-translation-model';
import { SorterService } from '../../_services/sorter.service';
import IDisableable from "../../infrastructure/disableable.interface";
import { AvailabilityModel } from '../availability-model';
import { ColorModel } from '../color';
import { ComponentModel } from '../component';
import { ComponentVariantModel } from '../component-variant';
import { ComponentVariantChangeModel } from '../component-variant-change';
import { VariantColorModel } from '../variant-color';
import { VariantSizeModel } from '../variant-size';
import { VariantSizeFromApiModel } from "../variant-size-from-api";
import { CaseAlternativeGroupLineModel } from './case-alternative-groupline';
import { BrandEnum } from '@infrastructure/brand.enum';
import { LimitedEditionData } from '@models/limited-edition-data';

export class ComponentVariantFromApiModel extends AvailabilityModel implements IDisableable {

	constructor(
        public Id: number,
        public Uuid: string,
        public Code: string,
        public Bypass: boolean,
        public Description: DataTranslationModel,
        public Description2: DataTranslationModel,
        public Status: number,
        public Default: boolean,
        public ConfigurationId: number,
        public ModelConfigUuid: string,
        public Group: DataTranslationModel,
        public GroupDescription: DataTranslationModel,
        public GroupOrder: string,
        public AvailableForDisplay: boolean,
        public Component: ComponentModel,
        public EngravingPossible: number,
        public Order: string,
        public SplittingAllowed: boolean,
        public Optional: boolean,
        public VariantSizes: Array<VariantSizeFromApiModel>,
        public Cases: Array<CaseAlternativeGroupLineModel>,
        isDisabled: boolean,
        public Discontinued: Date,
        public OmitDiscontinuedLabel: boolean,
        public New: boolean,
        public Brand: BrandEnum
    ) {
        super(Discontinued, New);
        this._isDisabled = isDisabled;

    }
    public isDisabledByVariants = new Array<{ component: string; variant: string; size: string; color: string; }>();
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _isDisabled: boolean;

    public set isDisabled(value: boolean) {
        this._isDisabled = value;
        this.VariantSizes?.forEach(x => x.isDisabled = value);
    }

    public get isDisabled() {
        return this._isDisabled;
    }

    get getVariantSizeCodes() {
        let sizeCodes = this.VariantSizes.map(x => x.Size).sort((a, b) => SorterService.sortByFunc(a, b));
        return sizeCodes;
    }

    public static GetNextColor(currentColor: VariantColorModel, availableColors: Array<VariantColorModel>): VariantColorModel {
        if (availableColors.length < 1) {
            return null;
        }

        if (!currentColor) {
            return availableColors.some(clr => clr.Default) ? availableColors.find(clr => clr.Default) : availableColors[0];
        }

        const matchingColor = availableColors.find(clr => clr.Color.Code == currentColor.Color.Code);
        if (matchingColor) {
            return matchingColor;
        } else {
            return availableColors.some(clr => clr.Default) ? availableColors.find(clr => clr.Default) : availableColors[0];
        }
    }

    public static ToStaticComponentVariantModel(model: ComponentVariantFromApiModel, engraving: string = "", orderlineUuid: string = "", variantViewed: boolean = false) {
        const variantModel = new ComponentVariantModel(model.Discontinued, model.New);
        variantModel.Id = model.Id;
        variantModel.Uuid = model.Uuid;
        variantModel.Code = model.Code;
        variantModel.Bypass = model.Bypass;
        variantModel.Description = model.Description;
        variantModel.Description2 = model.Description2;
        variantModel.Group = model.Group;
        variantModel.GroupDescription = model.GroupDescription;
        variantModel.GroupOrder = model.GroupOrder;
        variantModel.Status = model.Status;
        variantModel.Default = model.Default;
        variantModel.Component = model.Component;
        variantModel.EngravingPossible = model.EngravingPossible;
        variantModel.Order = model.Order;
        variantModel.Optional = model.Optional;
        variantModel.SplittingAllowed = model.SplittingAllowed;
        variantModel.Discontinued = model.Discontinued;
        variantModel.New = model.New;
        variantModel.AvailableForDisplay = model.AvailableForDisplay;

        variantModel.Engraving = engraving;
        variantModel.OrderLineUuid = orderlineUuid;
        variantModel.VariantViewed = variantViewed;
        return variantModel;
    }

    public ToComponentVariantModel = (engraving: string = "", orderlineUuid: string = "", variantViewed: boolean = false) => {
        return ComponentVariantFromApiModel.ToStaticComponentVariantModel(this, engraving, orderlineUuid, variantViewed);
    }

    public ToComponentVariantModelWithColors = (engraving: string = "", orderlineUuid: string = "", variantViewed: boolean = false, variantSizeCode: string = null, ColorCode: string = null) => {
        const variantModel = this.ToComponentVariantModel(engraving, orderlineUuid, variantViewed);

        if (variantSizeCode) {
            variantModel.VariantSize = this.VariantSizes.find(x => x.Size == variantSizeCode) ? this.VariantSizes.find(x => x.Size == variantSizeCode) : this.VariantSizes[0];

            if (!variantModel.Optional) {
                variantModel.VariantColor = this.VariantSizes[0].VariantColors[0];
            }
        }

        if (ColorCode) {
            const color = this.VariantSizes.find(x => x.Size == variantModel.VariantSize.Size)
                                            .VariantColors.find(x => x.Color.Code == ColorCode);
            if (color) {
                variantModel.VariantColor = color;
            }
        }

        if (!variantModel.VariantSize) {
            variantModel.VariantSize = this.VariantSizes[0];
            variantModel.VariantColor = this.VariantSizes[0].VariantColors[0];
        }

        return variantModel;
    }

    public ToComponentVariantModelFromVariantModel(from: ComponentVariantModel) {
        const variantModel = this.ToComponentVariantModel(from.Engraving);
        if (from.VariantSize) {
            const size = this.VariantSizes.find(x => from.VariantSize.Size == x.Size) || this.FindSizeReplacement(from);
            variantModel.VariantSize = size;

            if (from.VariantColor) {
                const color = size.VariantColors.find(x => x.Color.Code == from.VariantColor.Color.Code);
                variantModel.VariantColor = color || this.FindColorReplacement(from.VariantColor.Color);
            }
        }

        return variantModel;
    }

    // converts api model to variant model and transfers size/color if possible
    public ConvertToChangeModel(oldVariant: ComponentVariantModel, sorter: SorterService): ComponentVariantChangeModel {
        const newVariant = this.ToComponentVariantModel();
        newVariant.Engraving = this.EngravingPossible ? oldVariant.Engraving : "";
        newVariant.OrderLineUuid = oldVariant.OrderLineUuid;
        newVariant.VariantViewed = oldVariant.VariantViewed;

        if (oldVariant.VariantSize) {
            newVariant.VariantSize = this.FindSizeReplacement(oldVariant);
        }

        if (newVariant.SplittingAllowed && oldVariant.SplittingData) {
            this.FindSplittingDataReplacement(newVariant, oldVariant, sorter);
        }

        if (oldVariant.VariantColor) {
            newVariant.VariantColor = this.VariantSizes.find(x => x.Id == newVariant.VariantSize.Id).FindColorReplacement(oldVariant.VariantColor, sorter);
        }

        return new ComponentVariantChangeModel(oldVariant, newVariant, null);
    }

    public ConvertToListOfAllPossibleChoices() {
        const result = new Array<ComponentVariantModel>();

        if (this.VariantSizes.length == 0) {
            result.push(this.ToComponentVariantModel());
        } else {
            for (const size of this.VariantSizes) {
                if (size.VariantColors.length == 0) {
                    const variant = this.ToComponentVariantModel();
                    variant.VariantSize = size.ToVariantSizeModel();
                    variant.VariantColor = null;
                    result.push(variant);
                } else {
                    for (const color of size.VariantColors) {
                        const variant = this.ToComponentVariantModel();
                        variant.VariantSize = size.ToVariantSizeModel();
                        variant.VariantColor = color.ToVariantColor();
                        result.push(variant);
                    }
                }
            }
        }
        return result;
    }

    public FindSizeReplacement(oldVariant: ComponentVariantModel) {
        let size = this.VariantSizes.find(x => x.Size == oldVariant.VariantSize.Size);
        if (!size) {
            size = this.VariantSizes.sortBy(x => x.Order)[0];
        }

        return size;
    }

    public clearDisabled() {
        this.isDisabled = false;

        this.VariantSizes.forEach(size => {
            size.clearDisabled();
        });
    }

    private FindSplittingDataReplacement(newVariant: ComponentVariantModel, oldVariant: ComponentVariantModel, sorter: SorterService) {
        let left = this.VariantSizes.find(x => x.Size == oldVariant.SplittingData.left);
        let right = this.VariantSizes.find(x => x.Size == oldVariant.SplittingData.right);
        if (!left) {
            left = this.VariantSizes.sort(sorter.sortBy(VariantSizeModel))[0];
        }
        if (!right) {
            right = this.VariantSizes.sort(sorter.sortBy(VariantSizeModel))[0];
        }
        newVariant.SplittingData = { left: left.Size, right: right.Size };
    }

    public FindColorReplacement(color: ColorModel): VariantColorModel {
        if (this.VariantSizes.length == 0) {
            throw new Error("Cannot find any color replacement because there are no sizes");
        }
        if (this.VariantSizes[0].VariantColors.length == 0) {
            throw new Error("Cannot find any color replacement because there are no sizes");
        }
        let result = this.VariantSizes[0].VariantColors.find(x => x.Color.Uuid == color.Uuid);
        if (!result) {
            result = this.VariantSizes[0].VariantColors[0];
        }
        return result;
    }

    public FindColorGroupReplacement(variant: ComponentVariantModel, opposite: ComponentVariantModel): VariantColorModel {
        if (this.VariantSizes.length == 0) {
            throw new Error("Cannot find any color replacement because there are no sizes");
        }
        if (this.VariantSizes[0].VariantColors.length == 0) {
            throw new Error("Cannot find any color replacement because there are no sizes");
        }
        const chooseableColors = this.VariantSizes[0].VariantColors.filter(x => x.Color.ColorGroup.Uuid == opposite.VariantColor.Color.ColorGroup.Uuid);

        return chooseableColors.find(x => x.Color.Uuid == variant.VariantColor.Color.Uuid) || chooseableColors.find(x => x.Color.Uuid == opposite.VariantColor.Color.Uuid) || chooseableColors[0];
    }

	limitedEditionData: LimitedEditionData;
}
