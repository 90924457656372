import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormControl, Validators } from '@angular/forms';
import { LanguageService } from "@services/language.service";
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
    templateUrl: "plus-minus.component.html",
    selector: "plus-minus",
    styleUrls: ["./plus-minus.component.scss"]
})
export class PlusMinusComponent implements OnInit, OnChanges, OnDestroy {
    @Input() disabled: boolean = false;

    @Input() itemExistsInBag: boolean = false;
    @Input() value: number;
    @Input() maxLength: number;
    @Input() minValue: number;
    @Input() maxValue: number;
    @Input() isOffline: BehaviorSubject<boolean>;
    @Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

    public internalValue: UntypedFormControl;
    private valueChanger: Subject<number> = new Subject<number>();

    private subscriptions: Array<Subscription> = [];

    public toolip: string = "";

    constructor(private languageService: LanguageService) { }
    ngOnInit() {
        this.subscriptions.push(this.valueChanger.pipe(distinctUntilChanged()).subscribe(x => {
            this.valueChange.emit(x);
        }));

        this.internalValue = new UntypedFormControl(this.value, { validators: [Validators.required, Validators.min(this.minValue), Validators.max(this.maxValue)], updateOn: 'change' });
        if (this.disabled) {
            this.internalValue.disable();
        }

        if (this.itemExistsInBag) {
            this.toolip = this.languageService.instant("ITEM.ALREADY.EXISTS.IN.BAG");
        }

        this.subscriptions.push(this.internalValue.valueChanges.subscribe(newValue => {
            if (this.value == newValue) { return; } // prevent valuechange firing when no changes has been made

            if (this.internalValue.invalid) {
                if (this.internalValue.hasError("min")) {
                    this.internalValue.setValue(this.minValue);
                } else if (this.internalValue.hasError("max")) {
                    this.internalValue.setValue(this.maxValue);
                } else {
                    this.internalValue.setValue(this.value);
                }
            } else {
                this.valueChanger.next(newValue);
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value && this.internalValue) {
            this.internalValue.setValue(changes.value.currentValue);
        }
        if (changes.disabled && this.internalValue) {
            changes.disabled.currentValue ? this.internalValue.disable() : this.internalValue.enable();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    public selectAllInput(event: any) {
        // select() does not work on safari and setSelectionRange() throws error on chrome because the input is of type 'number'.
        try {
            event.target.setSelectionRange(0, this.internalValue.value.toString().length);
        } catch (e) {
            if (e instanceof DOMException) {
                event.target.select();
            } else {
                throw e;
            }
        }

    }

    public plus() {
        if (this.disabled) {
            return;
        }

        this.internalValue.setValue(this.internalValue.value + 1);
    }

    public minus() {
        if (this.disabled) {
            return;
        }

        this.internalValue.setValue(this.internalValue.value - 1);
    }
}
