import { AllAssortmentsModel } from '@models/all-assortments-model.interface';
import { AssortmentLineModel } from '@models/assortment-line-model';
import { AssortmentModel } from '@models/assortment-model';
import { DataTranslationModel } from '@models/data-translation-model';
import { Type } from 'class-transformer';
import { AvailabilityModel } from "../availability-model";

export class ModelgroupOrderApimodel extends AvailabilityModel implements AllAssortmentsModel {
    Id: number;
    Uuid: string;
    Code: string;

    @Type(() => DataTranslationModel)
    Description: DataTranslationModel;

    @Type(() => DataTranslationModel)
    ShortDescription: DataTranslationModel;

    KidTeen: boolean;
    Status: number;
    IsRimless: boolean;
    HasForms: boolean;
    HasFixtures: boolean;
    SortDescription: string;
    FullFrame: boolean;
    ParentModelGroupId?: number;
    ParentModelGroupUuid?: string;
    ProductLineCode: string;

    @Type(() => AssortmentLineModel)
    public AssortmentLines: Array<AssortmentLineModel>;

    public get AllAssortments(): Array<AssortmentModel> {
        return this.AssortmentLines.map(x => x.Assortment).distinct((x,y) => x.Code === y.Code);
    }
}
