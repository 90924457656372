import { ColorModel } from "@models/color";
import { ComponentVariantModel } from "@models/component-variant";
import { CustomizerSummaryModel } from "@models/customizer-summary-model";
import { OrderLine } from "@models/order-line";
import { VariantColorModel } from "@models/variant-color";
import { SimpleVariantSizeModel } from "@models/variant-size";

export class CustomizerSummaryModelFactory {

    static buildSummaryModels(variantArr: ComponentVariantModel[]): CustomizerSummaryModel[] {

        const variants = variantArr ?? [];
        

        const summaryModels = variants.map(variant => {
            
            const model = new CustomizerSummaryModel();

            model.ComponentUuid = variant.Component.Uuid;
            model.ComponentCode = variant.Component.Code;
            model.ComponentDescription = variant.Description;
            model.ComponentDescription2 = variant.Description2;
            model.ComponentVariantByPass = variant.Bypass;
            model.ComponentVariantCode = variant.Code;
            model.NotSelected = variant.NotSelected || false;
            model.ComponentVariantOptional = variant.Optional;

            model.ComponentVariantSizeBypass = variant.VariantSize?.Bypass ?? true;
            model.VariantColor = variant.Optional && variant.NotSelected ? null : variant.VariantColor;
            model.VariantSize = variant.Optional && variant.NotSelected ? null : variant.VariantSize;
            model.SplittingAllowed = variant.SplittingAllowed;
            model.ComponentVariantSizeDescription = model.VariantSize?.Description;

            if (variant.SplittingAllowed && variant.SplittingData) {
                model.ComponentSplittingDataLeft = variant.SplittingData.left;
                model.ComponentSplittingDataRight = variant.SplittingData.right;
            }

            return model;
        });

        return summaryModels;

    }

    static buildSummaryModelsFromOrderlines(orderlinesArr: OrderLine[]) : CustomizerSummaryModel[] {

        const orderlines = orderlinesArr ?? [];

        const summaryModels = orderlines.map(line => {

            const model = new CustomizerSummaryModel();

            model.ComponentUuid = line.ComponentUuid;
            model.ComponentCode = line.ComponentCode;
            model.ComponentDescription = line.ComponentVariantDescription;
            model.ComponentDescription2 = line.ComponentVariantDescription;
            model.ComponentVariantByPass = line.ComponentVariantBypass;
            model.NotSelected = false;

            model.ComponentVariantSizeBypass = line.ComponentSizeBypass;
            model.ComponentVariantSizeDescription = line.ComponentSizeDescription;
            if(line.OriginalComponentColor){
                model.VariantColor = line.OriginalComponentColor;
            } else{
                const variantColorModel: VariantColorModel = new VariantColorModel(null, null);
                const colorModel = new ColorModel();
                colorModel.Code = line.ColorCode;
                variantColorModel.Color = colorModel; 
                model.VariantColor = variantColorModel;
            }
            if(line.OriginalComponentSize){
                model.VariantSize = line.OriginalComponentSize;
            } else{
                const variantSizeModel: SimpleVariantSizeModel = new SimpleVariantSizeModel(null, null);
                variantSizeModel.Description = line.ComponentSizeCode;                
                model.VariantSize = variantSizeModel;
            }
            model.SplittingAllowed = line.SplittingData;

            if (line.SplittingData) {
                const splittingData = line.ComponentSizeDescription.split("/");
                const left = splittingData.find(y => y.substring(0, 1) == "L" ).substring(2);
                const right = splittingData.find(y => y.substring(0, 1) == "R" ).substring(2);
                model.ComponentSplittingDataLeft = left;
                model.ComponentSplittingDataRight = right;
            }

            return model;
        });

        return summaryModels;
    }

}