import { Component, OnInit } from '@angular/core';
import { BaseModal } from '@modals/base-modal';
import { OrderOnBehalfModalComponent } from '@modals/order-on-behalf/order-on-behalf.component';
import { CustomerModel } from '@models/customer';
import { LocationCheckInModel } from '@models/location-checkin-model';
import { LocationError } from '@models/location/location-error';
import { LocationPermissionError } from '@models/location/location-permission-error';
import { LocationUpdate } from '@models/location/location-update';
import { UserDataModel } from '@models/user-data-model';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { EventsService } from '@services/events.service';
import { LocationCheckInService } from '@services/location-checkin.service';
import { LocationService } from '@services/location.service';
import { ModalService } from '@services/modal.service';
import { UserService } from '@services/user.service';

@Component({
    selector: "customer-location-checkin-modal",
    templateUrl: "./customer-location-checkin.component.html",
    styleUrls: ["./customer-location-checkin.component.scss"]
})
export class CustomerLocationCheckInModalComponent extends BaseModal implements OnInit {
    public isReady: boolean = false;
    public customer: CustomerModel;
    public user: UserDataModel;
    public locationError: boolean = false;
    public isVisitingCustomer: boolean = false;

    constructor(breadcrumbService: BreadcrumbService,
        private userService: UserService,
        private events: EventsService,
        private locationService: LocationService,
        private locationCheckInService: LocationCheckInService,
        private modalService: ModalService) {
        super(breadcrumbService);
    }

    public async ngOnInit() {
        this.user = await this.userService.GetUser();
        this.isReady = true;
        await this.initLocationPermissionCheck();
    }

    public async initLocationPermissionCheck() {
        try { await this.getDeviceLocation(); } catch { }
    }

    public async continueWithoutLocation() {
        this.events.showLoader(true);
        await this.sendLocationCheckIn(null, this.isVisitingCustomer);
        this.events.showLoader(false);
        this.closeAndDestroy();
        this.modalService.close(OrderOnBehalfModalComponent);
    }

    public async locationCheckIn(visitingCustomer: boolean): Promise<void> {
        try {
            await this.TryCheckingIn(visitingCustomer);
        } catch (error) {
            this.handleKnownErrors(error);
        } finally {
            this.events.showLoader(false);
        }
    }

    private handleKnownErrors(error: any) {
        if (error instanceof LocationPermissionError) {
            this.locationError = true;
        } else if (error instanceof LocationError) {
            this.locationError = true;
        } else {
            throw error;
        }
    }

    private async TryCheckingIn(visitingCustomer: boolean) {
        this.events.showLoader(true);
        this.isVisitingCustomer = visitingCustomer;
        const currentDeviceLocation = await this.getDeviceLocation();

        if (currentDeviceLocation != null) {
            await this.checkInLocation(currentDeviceLocation, visitingCustomer);
        }
    }

    private async checkInLocation(currentDeviceLocation: LocationUpdate, visitingCustomer: boolean) {
        await this.sendLocationCheckIn(currentDeviceLocation, this.isVisitingCustomer);

        if (visitingCustomer) {
            this.locationCheckInService.addToLocationCheckInListStorage(this.customer.No);
        }

        this.closeAndDestroy();
        this.modalService.close(OrderOnBehalfModalComponent);
    }

    private async sendLocationCheckIn(deviceLocation: LocationUpdate, visisting: boolean) {
        const checkInModel = deviceLocation != null
            ? new LocationCheckInModel(new Date(), this.customer.No, this.user.Alias, deviceLocation.latitude.toString(), deviceLocation.longitude.toString(), visisting)
            : LocationCheckInModel.createEmptyLocationModel(new Date(), this.customer.No, this.user.Alias, visisting);

        await this.locationCheckInService.locationCheckIn(checkInModel);
    }

    private async getDeviceLocation(): Promise<LocationUpdate> {
        const currentDeviceLocation = await this.locationService.getDeviceLocation();
        return currentDeviceLocation;
    }
}
