export { };

declare global {
   interface Date {
      isToday(): boolean;
      isDateMonthYearEqual(date: Date): boolean;
   }
}

Date.prototype.isDateMonthYearEqual = function(date: Date): boolean {
    return this.getDate() == date.getDate() &&
        this.getMonth() == date.getMonth() &&
        this.getFullYear() == date.getFullYear();
};

Date.prototype.isToday = function(): boolean {
    const today = new Date();
    return this.isDateMonthYearEqual(today);
};
