import { ComponentModel } from "@models/component";
import { ComponentBindingModel } from "@models/component-binding";
import { ComponentVariantModel } from "@models/component-variant";

export class BindingMatcherMethods {

	public findByComponentMethod(component: ComponentModel) {
		return (bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel): Array<ComponentBindingModel> => {
			const result = this.filterOnComponent(bindings, component, variant);

			if (variant.NotSelected || result.length === 0) {
				return [];
			}

			return result;
		};
	}

	private filterOnComponent(bindings: Array<ComponentBindingModel>, component: ComponentModel, variant: ComponentVariantModel) {
		return bindings.filter(binding =>
			binding.ComponentId == component.Id
			&& binding.ComponentId === variant.Component.Id
			&& binding.isMissingVariantVariantSizeAndVariantColor()
		);
	}

	public findByVariantMethod(component: ComponentModel) {
		return (bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel): Array<ComponentBindingModel> => {
			const result = this.filterOnVariant(bindings, component, variant);

			if (!result.some(x => x.ComponentVariant.Id === variant.Id) || result.length === 0) {
				return [];
			}

			return result;
		};
	}

	public findByVariantNotEqualsMethod(component: ComponentModel) {
		return (bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel): Array<ComponentBindingModel> => {
			const result = this.filterOnVariant(bindings, component, variant);

			if (result.some(binding => binding.ComponentVariant.Id === variant.Id) || result.length === 0) {
				return [];
			}

			return result;
		};
	}

	private filterOnVariant(bindings: Array<ComponentBindingModel>, component: ComponentModel, variant: ComponentVariantModel) {
		return bindings.filter(binding =>
			binding.ComponentId == component.Id
			&& binding.ComponentId === variant.Component.Id
			&& binding.isMissingVariantSizeAndVariantColor()
		);
	}

	public findByVariantSizeMethod(component: ComponentModel) {
		return (bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel): Array<ComponentBindingModel> => {
			const result = this.filterOnSize(bindings, component, variant);

			if (!result.some(binding => binding.ComponentVariant.Id === variant.Id && binding.VariantSize.Id === variant.VariantSize?.Id) || result.length === 0) {
				return [];
			}

			return result;
		};
	}

	public findByVariantSizeNotEqualsMethod(component: ComponentModel) {
		return (bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel): Array<ComponentBindingModel> => {
			const result = this.filterOnSize(bindings, component, variant);

			if (result.some(binding => binding.ComponentVariant.Id === variant.Id && binding.VariantSize.Id === variant.VariantSize?.Id) || result.length === 0) {
				return [];
			}

			return result;
		};
	}

	private filterOnSize(bindings: Array<ComponentBindingModel>, component: ComponentModel, variant: ComponentVariantModel) {
		return bindings.filter(binding =>
			binding.ComponentId == component.Id
			&& binding.ComponentId === variant.Component.Id
			&& binding.isMissingVariantColor()
		);
	}

	public findByVariantSizeColorMethod(component: ComponentModel) {
		return (bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel): Array<ComponentBindingModel> => {
			const result = this.filterOnColor(bindings, component, variant);

			if (!result.some(binding =>
				binding.ComponentVariant.Id === variant.Id
				&& variant.VariantSize
				&& variant.VariantColor
				&& binding.VariantSize.Id === variant.VariantSize.Id
				&& binding.VariantColor.Id === variant.VariantColor.Id
			) || result.length === 0
			) {
				return [];
			}

			return result;
		};
	}

	public findByVariantSizeColorNotEqualsMethod(component: ComponentModel) {
		return (bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel): Array<ComponentBindingModel> => {
			const result = this.filterOnColor(bindings, component, variant);

			if (result.some(binding =>
				binding.ComponentVariant.Id === variant.Id
				&& variant.VariantSize
				&& variant.VariantColor
				&& binding.VariantSize.Id === variant.VariantSize?.Id
				&& binding.VariantColor.Id === variant.VariantColor?.Id
			) || result.length === 0
			) {
				return [];
			}

			return result;
		};
	}

	private filterOnColor(bindings: Array<ComponentBindingModel>, component: ComponentModel, variant: ComponentVariantModel) {
		return bindings.filter(binding =>
			binding.ComponentId == component.Id
			&& binding.ComponentId === variant.Component.Id
			&& binding.hasVariantVariantSizeAndVariantColor()
		);
	}

}
