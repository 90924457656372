<div class="modal-body">
    <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6">
        <ng-container *ngIf="!discontinuedData.length">
            <h1>{{ 'BAG.ITEMDISCONTINUED' | translate }}</h1>
            <div class="col-xs-8 col-xs-offset-2">
                <p [innerHTML]="'BAG.ITEMDISCONTINUEDTEXT' | translate"></p>
                <p [innerHTML]="'BAG.ITEMDISCONTINUEDTOPROCEED' | translate"></p>
                <div class="buttons">
                    <button class="btn btn-primary" (click)="closeAndDestroy()">{{ 'COMMON.OK' | translate }}</button>
                </div> 
            </div>
        </ng-container>

        <ng-container *ngIf="discontinuedData.length">
            <h1>{{ 'BAG.MODELDISCONTINUED' | translate }}</h1>
            <div class="col-xs-8 col-xs-offset-2">
                <p [innerHTML]="'BAG.MODELDISCONTINUEDTEXT' | translate "></p>
                <p [innerHTML]="'BAG.MODELDISCONTINUEDTOPROCEED' | translate"></p>
            </div>
            <div class="clearfix"></div>
            <div class="buttons">
                <button class="btn btn-primary" (click)="closeAndDestroy()">{{ 'COMMON.OK' | translate }}</button>
            </div>
            <div class="row">
                <div class="flex-center">
                    <div *ngFor="let order of discontinuedData" class="col-xs-3">
                        <div class="white-circle img-circle">
                            <dcg-image [src]="getImage(order)" [alt]="'IMAGEALTS.DISCONTINUEDMODEL' | translate"></dcg-image>
                            <p>{{order.ModelCode}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>