import { ComponentVariantFromApiModel } from './api-models/component-variant-fromapi';
import { AvailabilityModel } from "./availability-model";
import { ComponentVariantModel } from './component-variant';
import { ConfigurationModel } from './configuration';

export class ConfigurationPlusModel extends AvailabilityModel {
    constructor(configurationModel: ConfigurationModel) {
        super(configurationModel.Discontinued, configurationModel.New);

        if (configurationModel == null)
            throw new Error(`argument${typeof(configurationModel)} is null`)

        this.configurationModel = configurationModel;
    }

    configurationModel: ConfigurationModel;
    variants: Array<ComponentVariantModel>;
    variantsFromApi: Array<ComponentVariantFromApiModel>;
}
