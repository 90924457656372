export enum ColorJunctionConstants {
    "All" = 0,
    "ColorGroup" = 1,
    "Color" = 2
}

export enum BindingConstants {
    "None" = 0,
    "Block" = 1,
    "PermitOnly" = 2
}
