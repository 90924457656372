import { Component, Input } from "@angular/core";
import { IconClickTransition } from '@shared/transitions/icon-click.transition';

@Component({
    selector: 'icon-tag-filter',
    styleUrls: ['./icon-tag-filter.scss'],
    animations: [
        IconClickTransition()
    ],
	template:
	`
	<i class="fa-light fa-filter fa-xl"></i>
	`
})
export class IconTagFilterComponent {
    @Input() clickCount: number;
}
