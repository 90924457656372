import { Injectable } from "@angular/core";
import { EnvService } from './env.service';
import { OfflineService } from './offline.service';

@Injectable({
    providedIn: "root"
})
export class LoggerService {

    constructor(
        private offlineService: OfflineService,
        private env: EnvService
    ) { }

    async sendClientLog(logEvent: any) {
        try {
            return await this.offlineService.postWithOfflineHandling<string>(`${await this.env.baseUrl()}/log/addclientexception`, logEvent).catch((error) => {
                console.error("sendClientLog failed", logEvent, error);
            });
        } catch (error) {
            console.error("Error in sendClientException", logEvent);
        }
    }
}
