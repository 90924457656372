import { Component, OnDestroy, OnInit } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { CustomizerParams } from '@models/customizer-params';
import { DataTranslationModel } from '@models/data-translation-model';
import { ManualConfigCollectionModel } from '@models/manual-config-collection-model';
import { OrderItemModel } from '@models/order-item';
import { Paging } from '@models/paging.model';
import { BrandModeService } from '@services/brandmode.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { LanguageService } from '@services/language.service';
import { ManualConfigService } from '@services/manual-config.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { OrderService } from '@services/order.service';
import { SessionService } from '@services/session.service';
import { UserService } from '@services/user.service';
import { ModelsService } from '@services/v2/models.service';
import { ProductlineConstants } from '@shared/productline-constants';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { UserTypeConstant } from '@shared/user-type-constants';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ModelGroupModel } from 'src/_models/model-group';
import { EventsService } from 'src/_services/events.service';
import { ConfigurationMode } from 'src/_services/image.service';
import { LoginService } from 'src/_services/login.service';
import { TitleService } from 'src/_services/title.service';
import { DisplayEnduserModalComponent } from '../../_modals/display-enduser/display-enduser.component';
import { InspirationGroupModel } from '../../_models/inspiration-group';
import { ManualConfig } from '../../_models/manualconfig';
import { UserDataModel } from '../../_models/user-data-model';
import { EnduserDisplayService } from '../../_services/enduserdisplay.service';
import { ModalService } from '../../_services/modal.service';
import { NavigateService } from '../../_services/navigate.service';
import { BasePage } from '../../infrastructure/base-page';
import { FeaturedGroupConstant, ManualConfigGroupConstant } from '../../shared/featured-group-constant';

@Component({
	templateUrl: "featured.html",
	selector: "featured-page",
	styleUrls: ['./featured.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class FeaturedPageComponent extends BasePage implements OnInit, OnDestroy {

	public isUserCollection: boolean = false;
	public isFavorites: boolean;
	public selectedFavoriteCollection: ManualConfigCollectionModel;
	public selectedModelGroup: ModelGroupModel;
	public favoriteCollections: Array<ManualConfigCollectionModel> = [];
	public inspirationGroup: InspirationGroupModel;
	public manualConfigsFuture: Array<ManualConfig> = [];
	public manualConfigs: Array<ManualConfig> = [];
	public modelgroups: Array<ModelGroupModel> = [];
	public allInspirationGroups: Array<InspirationGroupModel>;
	public userData: UserDataModel;
	public hasInspirationEditPermission: boolean;
	public adminModeSubscription: Subscription;
	public adminModeEnabled: boolean = false;
	public enduserDisplayClickedObserveable: Observable<ConfigurationMode>;
	public enduserDisplaySubscription: Subscription;
	public isDisplay: boolean;
	public hasLoadedModels: boolean = false;
	public fetchingNewItems: boolean = false;
	public skeletonScreens: number;
	public showAsFrame: boolean;
	public showPageInitializingSkeleton: boolean = false;
	public displayEnduserModalComponent: any = DisplayEnduserModalComponent;
	public editFavoriteCollection: boolean = false;
	public deleteFavoriteCollectionPrompt: boolean = false;
	public refreshOptionText: string;
	public maxRunCheckDone: boolean;
	private readonly favoriteCollectionKey = "featured_favoriteCollection";
	private readonly modelGroupKey = "featured_modelGroup";
	private readonly favoriteSearchKey = "featured_searchword";
	public paging: Paging = new Paging();
	public freeTextSearchword: string;
	public searching: boolean = false;

	constructor(
		navigateService: NavigateService,
		loginService: LoginService,
		titleService: TitleService,
		modalService: ModalService,
		private events: EventsService,
		private enduserDisplayService: EnduserDisplayService,
		private sessionService: SessionService,
		private userService: UserService,
		private orderOnBehalfService: OrderOnBehalfService,
		private languageService: LanguageService,
		private manualConfigService: ManualConfigService,
		private brandModeService: BrandModeService,
		private addtobagService: OrderService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private modelsService: ModelsService
	) {
		super(loginService, titleService, modalService, navigateService);
	}

	async ngOnInit() {
		const inspirationGroupInput = this.navigateService.getInspirationGroupData();
		this.initLoaders(inspirationGroupInput);

		this.allInspirationGroups = await this.manualConfigService.getInspirationGroups();
		this.inspirationGroup = this.allInspirationGroups.find(e => e.Name == inspirationGroupInput.Name);
		this.userData = await this.userService.GetUser();
		this.hasInspirationEditPermission = this.userData.canEditConfigs;

		this.paging.size = 24;
		this.isFavorites = this.inspirationGroup.Type == FeaturedGroupConstant.Favorite;
		this.isUserCollection = this.inspirationGroup.Type == FeaturedGroupConstant.ConsultantCollection || this.isFavorites;

		if (this.isFavorites) {
			await this.InitFavoriteCollections();
		}
		else {
			this.sessionService.set(this.favoriteCollectionKey, null);
			await this.getInspirations();
		}

		this.isReady = true;

		this.titleService.setTitle(this.languageService.instant(inspirationGroupInput.Name));

		this.isDisplay = this.enduserDisplayService.IsDisplay();
		this.enduserDisplayClickedObserveable = this.enduserDisplayService.enduserDisplayClickedObservable();
		this.enduserDisplaySubscription = this.enduserDisplayClickedObserveable.pipe(debounceTime(100)).subscribe(x => {
			this.isDisplay = this.enduserDisplayService.IsDisplay();
		});

		this.adminModeSubscription = this.userService.adminModeEnabled.subscribe(value => {
			this.adminModeEnabled = value;
		});

		this.showAsFrame = this.shouldShowAsFrame();
	}

	ngOnDestroy() {
		if (this.enduserDisplaySubscription) {
			this.enduserDisplaySubscription.unsubscribe();
		}

		if (this.adminModeSubscription) {
			this.adminModeSubscription.unsubscribe();
		}
	}

	async InitFavoriteCollections() {
		this.favoriteCollections = await this.manualConfigService.getManualConfigCollections(true);
		this.selectedFavoriteCollection = this.sessionService.get(this.favoriteCollectionKey);
		this.freeTextSearchword = this.sessionService.get(this.favoriteSearchKey);
		this.selectedModelGroup = this.sessionService.get(this.modelGroupKey);

		if (!this.selectedFavoriteCollection) {
			this.selectedFavoriteCollection = this.favoriteCollections[0];
		}

		this.selectFavoriteCollection(this.selectedFavoriteCollection);
	}

	private setLoading(isLoading: boolean) {
		this.hasLoadedModels = !isLoading;

		if (!this.showPageInitializingSkeleton) {
			this.events.showLoader(isLoading)
		}
	}

	private initLoaders(inspirationGroup: InspirationGroupModel) {
		this.setupSkeletonScreens(inspirationGroup.ImageId);
	}

	private setupSkeletonScreens(imageId: string) {
		if (imageId === "consultant collection" || imageId === "favorites") {
			this.skeletonScreens = 12;
			this.showPageInitializingSkeleton = true;
		}
		else if (imageId === "screenshows" || imageId === "campaign") {
			this.skeletonScreens = 8;
		}
		else if (imageId === "inspiration") {
			this.skeletonScreens = 12;
		}
	}

	public async getInspirations(page: number = 1) {
		if (page === 1) {
			this.setLoading(true);
			this.manualConfigs = new Array();
			this.maxRunCheckDone = false
		}
		else {
			this.fetchingNewItems = true;

			if (this.maxRunCheckDone) {
				this.fetchingNewItems = false;
				this.setLoading(false);
				return;
			}
		}

		this.paging.page = page;
		this.refreshOptionText = null;
		this.selectedModelGroup = this.sessionService.get(this.modelGroupKey);
		const modelGroupId = this.selectedModelGroup?.Id;

		this.selectedFavoriteCollection = this.sessionService.get(this.favoriteCollectionKey);
		let favoriteCollectionId = 0;

		if (this.selectedFavoriteCollection) {
			favoriteCollectionId = this.selectedFavoriteCollection.Id;
		}

		let manualConfigs = await this.manualConfigService.getManualConfigurations(
			this.inspirationGroup.Id,
			this.freeTextSearchword,
			this.selectedModelGroup?.Code,
			null,
			null,
			favoriteCollectionId,
			this.paging
		);

		if (manualConfigs) {
			let runCount = 0;
			let maxLookup = 10;

			while (manualConfigs?.length === 0 && runCount < maxLookup) {
				this.paging.page += 1;

				manualConfigs = await this.manualConfigService.getManualConfigurations(
					this.inspirationGroup.Id,
					this.freeTextSearchword ?? '',
					this.selectedModelGroup?.Code,
					null,
					null,
					favoriteCollectionId,
					this.paging
				);

				runCount++;
			}

			if (maxLookup == runCount) {
				this.maxRunCheckDone = true;
				this.fetchingNewItems = false;
				this.setLoading(false);
				return;
			}

			const manualConfigsFuture = manualConfigs?.filter(m => m.Release > new Date());

			if (manualConfigsFuture?.length > 0) {
				this.manualConfigsFuture.push(...manualConfigsFuture);
			}

			const manualConfigsPast = manualConfigs?.filter(m => m.Release < new Date());

			if (manualConfigsPast.length > 0) {
				this.manualConfigs.push(...manualConfigsPast);
			}

			this.fetchingNewItems = false;

			if (favoriteCollectionId === 0) {
				if (this.isFavorites) {
					this.manualConfigs.forEach(manualConfig => {
						manualConfig.FavoriteUuid.next(manualConfig.Uuid);
					});
				}

				this.manualConfigs.forEach(async manualConfig => {
					manualConfig.IsInCollection = await this.manualConfigService.isInCollection(manualConfig.Model, manualConfig.ManualConfigVariants.map(x => x.GetVariant()));
				});
			}
			else {
				this.manualConfigs.forEach(manualConfig => {
					manualConfig.IsInCollection = true;
				});
			}

			if (page === 1) {
				await this.getAndHandleHitCount(favoriteCollectionId, modelGroupId);
				this.setLoading(false);
			}
		}
		else {
			this.setLoading(false);
		}
	}

	async getAndHandleHitCount(favoriteCollectionId: number, modelGroupId: number) {
		let manualConfigsHitCounts = await this.manualConfigService.getManualConfigurationHitCount(
			this.inspirationGroup.Id,
			this.freeTextSearchword,
			null,
			null,
			null
		);

		this.favoriteCollections.forEach(favoriteCollection => {
			favoriteCollection.HitCount = manualConfigsHitCounts.filter(m => m.ManualConfigCollectionId === favoriteCollection.Id).sum(m => m.HitCount)
		});

		this.modelgroups = (await this.getModelGroups()).filter(m => manualConfigsHitCounts.some(x => x.ModelGroupId === m.Id
			&& x.ManualConfigCollectionId === favoriteCollectionId));

		this.modelgroups.forEach(modelgroup => {
			modelgroup.Selected = modelgroup.Id === this.selectedModelGroup?.Id ? true : false;
			modelgroup.HitCount = manualConfigsHitCounts.filter(m => m.ModelGroupId === modelgroup.Id
				&& m.ManualConfigCollectionId === favoriteCollectionId).sum(m => m.HitCount)
		});

		if (this.modelgroups.length > 1) {
			const all = new ModelGroupModel(null, false);
			all.Description = new DataTranslationModel('COMMON.ALL', this.languageService.instant('COMMON.ALL'));
			all.HitCount = manualConfigsHitCounts.filter(m => m.ManualConfigCollectionId === favoriteCollectionId).sum(m => m.HitCount)
			all.Id = 0;
			all.KidTeen = false;
			all.Selected = this.selectedModelGroup ? false : true;

			this.modelgroups.unshift(all);
		}

		manualConfigsHitCounts = manualConfigsHitCounts.filter(m => m.ManualConfigCollectionId === favoriteCollectionId);

		if (modelGroupId && modelGroupId > 0) {
			manualConfigsHitCounts = manualConfigsHitCounts.filter(m => m.ModelGroupId === modelGroupId);
		}

		let hitCount = manualConfigsHitCounts.sum(x => x.HitCount);
		this.paging.update(hitCount);

		if (this.paging.totalCount > 0 && this.paging.totalCount !== hitCount) {
			this.paging.page = 1;
		}
	}

	async getModelGroups() {
		let modelconfigModelgroups = (await this.modelsService.getAllModelGroups()).filter(x => x.Code != ProductlineConstants.AIR && x.ParentModelGroupUuid == null);
		modelconfigModelgroups = modelconfigModelgroups.sort((a, b) => Number(a.KidTeen) - Number(b.KidTeen));
		return modelconfigModelgroups;
	}

	async onScroll() {
		if (this.fetchingNewItems) {
			return;
		}

		this.paging.page += 1;

		await this.getInspirations(this.paging.page);
	}

	public async deleteFavoriteCollection(favoriteCollection: ManualConfigCollectionModel) {
		await this.manualConfigService.deleteManualConfigCollection(favoriteCollection);

		this.sessionService.set(this.favoriteCollectionKey, null);
		this.resetFavoriteCollectionActions();
		await this.InitFavoriteCollections();

		const user = this.userService.currentUser$.getValue();

		if (user) {
			await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', `${user.Uuid} deleted a favorite collection: '${favoriteCollection.Name}'`, 20);
		}
	}

	public async saveFavoriteCollection(favoriteCollection) {
		const user = this.userService.currentUser$.getValue();

		if (user) {
			await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', `${user.Uuid} saved a favorite collection: '${favoriteCollection.Name}'`, 20);
		}

		await this.manualConfigService.saveFavoriteCollection(favoriteCollection)
		await this.InitFavoriteCollections();

		this.resetFavoriteCollectionActions();
	}

	public async addAllToBag() {
		const customer = this.orderOnBehalfService.customerSnapshot();
		const user = await this.userService.GetUser();
		const isDisplay = this.enduserDisplayService.IsDisplay();
		const isPrecious = this.brandModeService.isPrecious;

		let orderId: number = await this.addtobagService.getIdOnCurrentOrder();

		for (let index = 0; index < this.manualConfigs.length; index++) {
			const favorite = this.manualConfigs[index];

			favorite.IsSavingToBag = true;

			var element = document.getElementsByTagName('featured-model')[index];

			if (element) {
				element.scrollIntoView();
			}

			const orderItem = OrderItemModel.MapFromVariants(
				null,
				orderId,
				isDisplay,
				1,
				"",
				"",
				"",
				favorite.ModelConfiguration,
				favorite.ModelGroup,
				favorite.Model,
				favorite.ModelSize,
				favorite.ManualConfigVariants.map(t => t.GetVariant()),
				false,
				false,
				false,
				null,
				null,
				favorite.TempleInclination,
				isPrecious);

			await this.addtobagService.AddFramesToOrder(orderItem, favorite.ModelGroup.KidTeen, true);

			if (orderId < 1) {
				orderId = orderItem.OrderId;
			}

			favorite.IsAddingToTestBag = !customer && user.canOrderOnBehalf && user.Type === UserTypeConstant.Consultant;
			favorite.IsSavingToBag = false;
			favorite.ShowAddedToBag = true;

			await new Promise<void>((resolve) => {
				setTimeout(() => {
					resolve();
				}, 1000);
			});

			favorite.ShowAddedToBag = false;
			this.events.showLoader(false);
		};

		if (user && this.manualConfigs.length > 0) {
			await this.googleAnalyticsService.eventEmitter('my collections', 'engagement', `${user.Uuid} added '${this.manualConfigs.length} favorite items to bag'`, 20);
		}
	}

	public async toggleHide(hide: boolean) {
		this.events.showLoader(true);

		await this.manualConfigService.updateInspirationGroup(this.inspirationGroup.Id, hide);
		await this.manualConfigService.clearManualConfig(ManualConfigGroupConstant.All);
		this.events.showLoader(false);
		this.inspirationGroup.IsHidden = hide;
	}

	public async onNavigateToCustomizer(arg: { featuredConfiguration: ManualConfig, modelgroup: ModelGroupModel, customizerParams: CustomizerParams }) {
		arg.featuredConfiguration.Model.DefaultVariantModels = arg.featuredConfiguration.ManualConfigVariants.map(t => t.GetVariant());
		arg.featuredConfiguration.Model.DefaultVariantModels.forEach(variantModel => {
			variantModel.Brand = arg.featuredConfiguration.IsPrecious ? BrandEnum.Precious : BrandEnum.Titanium;
		});

		const allVariants = (await this.modelsService.getModel(this.userData, arg.modelgroup, arg.featuredConfiguration.Model.Uuid)).DefaultVariantModels;

		const currentCustomer = this.orderOnBehalfService.customerSnapshot();

		if (this.isUserCollection && currentCustomer && currentCustomer.isConsultantOrderOnBehalf) {
			await this.navigateService.navigateToCondensedCustomizer(arg.modelgroup, arg.featuredConfiguration.Model, arg.featuredConfiguration.ModelSize,
				arg.featuredConfiguration.TempleInclination, arg.featuredConfiguration.ModelConfiguration, null, null, this.inspirationGroup, arg.customizerParams);
		}
		else {
			await this.navigateService.navigateToCustomizer(arg.modelgroup, arg.featuredConfiguration.Model, arg.featuredConfiguration.ModelSize,
				arg.featuredConfiguration.TempleInclination, arg.featuredConfiguration.ModelConfiguration, null, allVariants, arg.customizerParams);
		}
	}

	deleteInspirationConfiguration(deletedManualInspirationConfig: ManualConfig) {
		let itemToRemoveIndex = this.manualConfigs.findIndex(m => m.Uuid === deletedManualInspirationConfig.Uuid);

		if (itemToRemoveIndex > -1) {
			this.manualConfigs.splice(itemToRemoveIndex, 1);
		}

		itemToRemoveIndex = this.manualConfigsFuture.findIndex(m => m.Uuid === deletedManualInspirationConfig.Uuid);

		if (itemToRemoveIndex > -1) {
			this.manualConfigsFuture.splice(itemToRemoveIndex, 1);
		}
	}

	public async selectFavoriteCollection(favoriteCollection: ManualConfigCollectionModel) {
		this.resetFavoriteCollectionActions();

		const currentFavoriteCollection = this.favoriteCollections.find(x => x.Id == favoriteCollection.Id);

		if (currentFavoriteCollection) {
			currentFavoriteCollection.Selected = true;
		}

		this.sessionService.set(this.favoriteCollectionKey, favoriteCollection);

		this.selectedFavoriteCollection = favoriteCollection;
		await this.selectModelGroup(null);
	}

	public async selectModelGroup(modelGroup: ModelGroupModel) {
		if (this.selectedModelGroup?.Id === modelGroup?.Id) {
			modelGroup = null;
		}

		this.selectedModelGroup = modelGroup;
		this.sessionService.set(this.modelGroupKey, modelGroup);

		this.modelgroups.forEach(modelgroup => {
			modelgroup.Selected = false;
		});

		await this.getInspirations();

		if (this.modelgroups.length > 0 && modelGroup) {
			this.modelgroups.find(x => x.Id == modelGroup.Id).Selected = true;
		}
	}

	public async resetFavoriteCollectionActions() {
		this.editFavoriteCollection = false;
		this.deleteFavoriteCollectionPrompt = false;
		this.favoriteCollections.forEach(favoriteCollection => {
			favoriteCollection.Selected = false;
		});
		this.modelgroups = [];
	}

	public async applyFavoriteChange() {
		if (this.isFavorites) {
			this.refreshOptionText = this.languageService.instant('MY.COLLECTIONS.REFRESH1');
			await this.getAndHandleHitCount(this.selectedFavoriteCollection.Id, this.selectedModelGroup?.Id);
		}
	}

	public async applyFavoriteCollectionChange() {
		if (this.isFavorites) {
			this.refreshOptionText = this.languageService.instant('MY.COLLECTIONS.REFRESH1');
			await this.getAndHandleHitCount(this.selectedFavoriteCollection.Id, this.selectedModelGroup?.Id);
		}
	}

	public async refresh() {
		this.refreshOptionText = null;

		await this.manualConfigService.clearManualConfigCacheIfNeeded(ManualConfigGroupConstant.Favorites);
		await this.getInspirations(this.paging.page);
	}

	public onKeyPress(event: KeyboardEvent) {
		if (event.key == "Enter") {
			this.search();
		}
	}

	public async search() {
		this.refreshOptionText = null;

		if (this.searching) {
			return;
		}

		this.searching = true;

		if (this.freeTextSearchword === '') {
			this.freeTextSearchword = null;
		}

		this.sessionService.set(this.favoriteSearchKey, this.freeTextSearchword);

		await this.getInspirations(this.paging.page);

		this.searching = false;
	}

	private shouldShowAsFrame() {
		return this.inspirationGroup.Id == ManualConfigGroupConstant.Inspiration ||
			this.inspirationGroup.Id == ManualConfigGroupConstant.ConsultantCollection ||
			this.inspirationGroup.Id == ManualConfigGroupConstant.Favorites;
	}

}
