import { Type } from "class-transformer";

export abstract class AvailabilityModel {
    @Type(() => Date)
    public Discontinued: Date;
    public New: boolean;

    constructor(
        discontinued: Date,
        New: boolean)
    {
        this.Discontinued = discontinued;
        this.New = New;
    }

    isDiscontinued(date?: Date): boolean {
        const today = date || new Date();
        return this.Discontinued != null && this.Discontinued <= today;
    }
}
