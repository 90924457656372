import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@shared/app-state';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { EventsService } from 'src/_services/events.service';
import { BaseModal } from '../base-modal';

@Component({
	selector: 'app-offline-customiser-modal',
	styleUrls: ['./offline-customiser-modal.component.scss'],
	template: `
        <div class="error-message">
        	<h3>
        		<i class="icon-info-red icon" (click)="onCancel(false)"></i>customiser is offline
        	</h3>
        	<p>the data source that customiser relies on appears to be offline</p>
        	<p>we are working hard to fix this - please check back soon</p>
        	<br />
        	<div class="text-center">
        		<button (click)="onRetry()" class="btn btn-primary mr-2">try again</button>
        		<button (click)="onCancel()" class="btn btn-secondary">home</button>
        	</div>
        </div>
        <p class="mail-link">
        	<a href="mailto:collectionguide@lindberg.com">collectionguide&#64;<span class="lindbergfont">LINDBERG</span>.com</a>
        </p>
        <a href="https://beian.miit.gov.cn/" target="_blank" *ngIf="appState.showChineseLicenseId" class="chineseLicense">沪ICP备18032619号-1</a>`
})
export class OfflineCustomiserModalComponent extends BaseModal implements OnInit {

	constructor(
		breadcrumbService: BreadcrumbService,
		public router: Router,
		public appState: AppState,
		private eventService: EventsService
	) {
		super(breadcrumbService);
	}

	ngOnInit() {
		this.eventService.showLoader(false);
	}

	onRetry(): void {
		this.closeAndDestroy();
		window.location.reload();
	}

	async onCancel(gotoFrontpage: boolean = true) {
		this.closeAndDestroy();
		if (gotoFrontpage) {
			await this.router.navigate(["/"]);
			window.location.reload();
		}
	}

}
