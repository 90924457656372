import { animate, group, query, style , transition, trigger} from "@angular/animations";
import { } from "@angular/core";

export function routerTransition() {
    return trigger('routerTransition', [
      transition("* <=> *", [
        group([
          query(':enter, :leave', [
            style({ position: 'fixed', width: '100%' })
          ], { optional: true }),
          query(':enter', [
            style({opacity: 0}),
            animate(200, style({opacity: 1}))
          ], { optional: true}),
          query(':leave', [
            animate(200, style({opacity: 0}))
          ], { optional: true})
        ])
      ]),

    ]);
  }
