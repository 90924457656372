import { Order } from '@models/order';
import { OrderItemModel } from '@models/order-item';
import { OrderItemMiscModel } from '@models/order-item-misc-model';
import { SalesDistrictType } from '@models/sales-district';
import { toDateStringWithUserOffset } from '@shared/utils/date-helper';

export class OrderCreateModel {

	constructor(testOrder: boolean) {
		this.TestOrder = testOrder;
		this.AllowPartialShipment = true;
		this.OrderItems = new Array<OrderItemModel>();
		this.OrderItemMiscs = new Array<OrderItemMiscModel>();
		this.AllOrderItemUuids = new Array<string>();
		this.AllOrderItemMiscUuids = new Array<string>();
	}

	public FullAddress: string;

	public TestOrder: boolean;

	public ShipToName: string;

	public ShipToId?: number;

	public OrderItems: Array<OrderItemModel>;
	public OrderItemMiscs: Array<OrderItemMiscModel>;

	public AllOrderItemUuids: Array<string>;
	public AllOrderItemMiscUuids: Array<string>;

	public CustomerNo: string;

	public VisitDate: string;

	public VisitDateWithUserOffset: string;

	public EarliestDeliveryDate?: string;
	public EarliestDeliveryDateWithUserOffset?: string;

	public FairId?: number;
	public FairName: string;

	public AllowPartialShipment: boolean;

	public PaymentTerms: string;

	public CustomerEmails: Array<string>;
	public CheckedOrderConfirmationEmailAddresses: string[];
	public Notes: string;
	public CustomerAlias: string;
	public NoVisit: boolean;
	public SalesDistrictType: SalesDistrictType;

	public signature: string;

	public static OrderOnBehalfCreateModel(customerNo: string, existingOrder: Order) {
		const newModel = new OrderCreateModel(false);
		newModel.CustomerNo = customerNo;
		newModel.OrderItems = new Array<OrderItemModel>();
		newModel.OrderItemMiscs = new Array<OrderItemMiscModel>();
		newModel.AllOrderItemUuids = new Array<string>();
		newModel.AllOrderItemMiscUuids = new Array<string>();
		newModel.AllowPartialShipment = existingOrder?.AllowPartialShipment ?? true;
		newModel.ShipToName = existingOrder?.ShipToName;
		newModel.FullAddress = existingOrder?.FullAddress;
		newModel.ShipToId = existingOrder?.ShipToId;
		newModel.PaymentTerms = existingOrder?.PaymentTerms;

		if (existingOrder?.VisitDate) {
			newModel.VisitDate = new Date(existingOrder?.VisitDate.getTime() - (existingOrder?.VisitDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		}

		newModel.VisitDateWithUserOffset = toDateStringWithUserOffset(existingOrder?.VisitDate)

		if (existingOrder?.EarliestDeliveryDate) {
			newModel.EarliestDeliveryDate = new Date(existingOrder?.EarliestDeliveryDate.getTime() - (existingOrder?.EarliestDeliveryDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
		}

		newModel.EarliestDeliveryDateWithUserOffset = toDateStringWithUserOffset(existingOrder?.EarliestDeliveryDate)
		newModel.CheckedOrderConfirmationEmailAddresses = existingOrder?.ConfirmationEmails?.filter(t => t.Checked).map(o => o.Email);
		newModel.NoVisit = existingOrder?.NoVisit;
		newModel.SalesDistrictType = existingOrder?.SalesDistrictType;
		newModel.FairId = existingOrder?.FairId;
		newModel.FairName = existingOrder?.FairName;
		newModel.signature = existingOrder?.Signature;

		return newModel;
	}
}
