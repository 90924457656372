import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { VersionType } from '@infrastructure/versionType.enum';
import { ImageServerInfoModel, ServerInfoModel } from '@models/server-info-model';
import { EnvService } from '@services/env.service';
import { ErrorReportService } from '@services/error-report.service';
import { IndexDbService } from '@services/index-db.service';
import { ServerInfoService } from '@services/server-info.service';
import { AppState } from '@shared/app-state';
import { UserTypeConstant } from '@shared/user-type-constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { UserService } from 'src/_services/user.service';
import { BaseModal } from '../base-modal';

@Component({
    selector: 'browser-info-modal',
    styleUrls: ['./browser-info.component.scss'],
    template: `
        <div class="modal-body">
        <button class="close" (click)="closeAndDestroy()">
            <i class="fa-light fa-xmark"></i>
        </button>
        <div class="browser-info-item">
            <div>
                <h3>{{'BROWSER-INFO.HEADER' | translate}}</h3>
                <div>
                    <p><b>{{'BROWSER-INFO.ALIAS' | translate}}</b>: <span>{{accountAlias}}</span></p>
                    <p><b>{{'BROWSER-INFO.NAME' | translate}}</b>: <span>{{accountName}}</span></p>
                    <p><b>{{'BROWSER-INFO.NUMBER' | translate}}</b>: <span>{{accountNumber}}</span></p>
                    <p><b>{{'BROWSER-INFO.BROWSER' | translate}}</b>: <span>{{browser}} {{'BROWSER-INFO.VERSION' | translate}} {{browserVersion}}</span></p>
                    <p *ngIf="device"><b>{{'BROWSER-INFO.DEVICE' | translate}}</b>: <span>{{device}}</span></p>
                    <p><b>{{'BROWSER-INFO.OS' | translate}}</b>: <span>{{os}} {{ osVersion ? ('BROWSER-INFO.VERSION' | translate) : '' }} {{osVersion}}</span></p>
                    <p><b>{{'BROWSER-INFO.USERAGENT' | translate}}</b>:<br /> <span>{{userAgent}}</span></p>
                    <p>
                        <b>{{'BROWSER-INFO.QUOTA.USAGE' | translate}}
                        <a *ngIf="showLinkToStoragePage" [routerLink]="['storage']" (click)="closeAndDestroy()"><i class="fa-solid fa-circle-info"></i></a></b><br /> 
                         <span *ngIf="totalSpace">
                            {{'BROWSER-INFO.QUOTA.TOTAL' | translate}} : {{totalSpace.toFixed(0) | number}} Mb<br>
                            {{'BROWSER-INFO.QUOTA.USED' | translate}} : {{usedSpace.toFixed(2) | number}} Mb<br>
                            {{'BROWSER-INFO.QUOTA.PERCENTAGE' | translate}} : {{percentUsed.toFixed(2) | number}}%<br>
                        </span>
                        <span *ngIf="!totalSpace">
                            {{'BROWSER-INFO.QUOTA.NOT.SUPPORTED' | translate}}
                        </span>
                    </p>
                    <ng-container *ngIf="version">
                        <p><b>{{'BROWSER-INFO.BUILD' | translate}}</b>: <span>{{appBuild}}</span></p>
                        <p><b>{{'BROWSER-INFO.VERSION' | translate}}</b>: <span>{{version}}</span></p>
                    </ng-container>
                    <p><b>{{'BROWSER-INFO.DATAVERSION' | translate}}</b>: <span>{{dataVersion}}</span></p>
                    <p><b>{{'BROWSER-INFO.DATASERVER' | translate}}</b>: <span>{{dataServer}}</span></p>
                    <ng-container *ngIf="serverInfo">
                        <p class="ml-5"><b>{{'BROWSER-INFO.SERVER.REGION' | translate}}</b>: <span>{{serverInfo.Region}}</span></p>
                        <p class="ml-5"><b>{{'BROWSER-INFO.SERVER.MACHINENAME' | translate}}</b>: <span>{{serverInfo.MachineName}}</span></p>
                        <p class="ml-5"><b>{{'BROWSER-INFO.SERVER.BUILD' | translate}}</b>: <span>{{serverInfo.Build | date:'mediumDate' }} {{serverInfo.Build | date:'HH:mm:ss'}}</span></p>
                    </ng-container>

                    <p><b>{{'BROWSER-INFO.IMAGEURL' | translate}}</b>: <span>{{imageUrl}}</span></p>                    
                    <ng-container *ngIf="imageServerInfo">
                        <p class="ml-5"><b>{{'BROWSER-INFO.SERVER.REGION' | translate}}</b>: <span>{{imageServerInfo.region}}</span></p>
                        <p class="ml-5"><b>{{'BROWSER-INFO.SERVER.BUILD' | translate}}</b>: <span>{{imageServerInfo.build | date:'mediumDate' }} {{imageServerInfo.build | date:'HH:mm:ss'}}</span></p>
                    </ng-container>

                    <p *ngIf="imageUrl !== imageUrlCDN"><b>{{'BROWSER-INFO.IMAGEURLCDN' | translate}}</b>: <span>{{imageUrlCDN}}</span></p>                    
                    <ng-container *ngIf="imageUrl !== imageUrlCDN && imageCdnServerInfo">
                        <p class="ml-5"><b>{{'BROWSER-INFO.SERVER.REGION' | translate}}</b>: <span>{{imageCdnServerInfo.region}}</span></p>
                        <p class="ml-5"><b>{{'BROWSER-INFO.SERVER.BUILD' | translate}}</b>: <span>{{imageCdnServerInfo.build | date:'mediumDate' }} {{imageCdnServerInfo.build | date:'HH:mm:ss'}}</span></p>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="create-error-report">
            <div>
                <button class="btn btn-primary" (click)="openWebSupport()">{{"COMMON.CREATEERRORREPORT" | translate}}</button>
            </div>
        </div>
        </div>
    `
})
export class BrowserInfoComponent extends BaseModal implements OnInit {
    public accountAlias: string;
    public accountName: string;
    public accountNumber: string;
    public browser: string;
    public browserVersion: string;
    public os: string;
    public osVersion: string;
    public device: string;
    public userAgent: string;
    public totalSpace: number;
    public usedSpace: number;
    public percentUsed: number;
    public version: string;
    public appBuild: string;
    public dataVersion: string;
    public serverInfo: ServerInfoModel;
    public imageServerInfo: ImageServerInfoModel;
    public imageCdnServerInfo: ImageServerInfoModel;
    public dataServer: string;
    public imageUrlCDN: string;
    public imageUrlCDNRegion: string;
    public imageUrl: string;
    public imageUrlRegion: string;
    public showLinkToStoragePage: boolean = false;

    constructor(
        breadcrumbService: BreadcrumbService,
        private userService: UserService,
        private deviceDetector: DeviceDetectorService,
        private env: EnvService,
        private indexDbService: IndexDbService,
        private errorReportService: ErrorReportService,
        private appState: AppState,
        private serverInfoService: ServerInfoService) {
        super(breadcrumbService);
    }

    async ngOnInit() {
        const user = await this.userService.GetUser();
        const currentDataVersion = this.indexDbService.getLocalVersion(VersionType.Frames);

        this.accountName = user.CustomerName;
        this.accountNumber = user.CustomerNo;
        this.accountAlias = user.Alias;
        this.showLinkToStoragePage = user.Type === UserTypeConstant.Employee || this.appState.showLinkToStoragePage;

        const device = this.deviceDetector.getDeviceInfo();

        this.browser = device.browser;
        this.browserVersion = device.browser_version;
        this.device = device.device != "Unknown" ? device.device : null;
        this.os = device.os;
        this.osVersion = device.os_version;
        this.userAgent = device.userAgent;

        if ('storage' in navigator && 'estimate' in navigator.storage) {
            const estimate = await navigator.storage.estimate();

            this.totalSpace = estimate?.quota / 1000000;
            this.usedSpace = estimate?.usage / 1000000;
            this.percentUsed = this.usedSpace / this.totalSpace * 100;
        }

        this.version = this.env.getVersion();
        this.appBuild = environment.version;
        this.dataVersion = currentDataVersion ? currentDataVersion.Id + "" : "";

        this.serverInfo = await this.serverInfoService.getServerInfo();
        this.dataServer = await this.env.DataApiurl();

        this.imageUrl = await this.env.ImageUrl();
        this.imageUrlCDN = await this.env.ImageCdnUrl();

        this.imageServerInfo = await this.serverInfoService.getImageServerInfo();
        if (this.imageUrl === this.imageUrlCDN) {
            this.imageCdnServerInfo = this.imageServerInfo;
        }
        else {
            this.imageCdnServerInfo = await this.serverInfoService.getImageCdnServerInfo();
        }
    }

    openWebSupport() {
        this.errorReportService.openWebSupport(null, null, null);
    }

}
