<div [ngClass]="{'inspiration-group': showAsFrame}" [@fadeInOutTransition] *ngIf="(featuredConfiguration.HasBeenDeleted | async) === false">
    <favorite-collection-overlay *ngIf="showMyCollectionsOverlay" 
        (applyRemovedFromFavoriteCollectionChange)="applyRemovedFromFavoriteCollectionChange()" 
        (applyFavoriteCollectionChange)="applyFavoriteCollectionChange($event)"
        (applyFavoriteCollectionChangeEmitter)="applyFavoriteCollectionChange($event)"
        (cancelChange)="cancelFavorite()"
        [variants]="variants"
        [model]="featuredConfiguration.Model"
        [configuration]="featuredConfiguration.ModelConfiguration"
        [modelSize]="featuredConfiguration.ModelSize"
        [manualconfigGroups]="allManualConfigGroups"
        [templeInclination]="featuredConfiguration.TempleInclination"
        [showClose]="true" class="from-featured-model"
        >
        <button class="btn btn-secondary mt-2 cancel-collection-overlay" (click)="cancelFavorite()">
            {{ 'COMMON.CLOSE' | translate }}
        </button>
    </favorite-collection-overlay>
    <favorite-collection-save-overlay *ngIf="isSavingToBag">
        
    </favorite-collection-save-overlay>

    <featured-model-overlay *ngIf="showDeletePrompt" (applyChange)="deleteInspirationConfiguration()" (cancelChange)="cancelDeleteInspirationConfiguration()">
        <div class="delete-text-container overlay">
            <span>{{'FEATURED.REMOVEFRAME.TEXT1' | translate}}</span>
            <span>{{'FEATURED.REMOVEFRAME.TEXT2' | translate}}</span>
        </div>
    </featured-model-overlay>
    <featured-model-overlay *ngIf="!availableForDisplay && isDisplay && isReady">
        <div class="clientonly-container overlay">
            <div class="font-medium">{{'FEATURED.MODEL.CLIENTONLY' | translate}}</div>
            <span [innerHTML]="'FEATURED.MODEL.UNAVAILABLEFORDISPLAY' | translate"></span>
            <br />
            <span [innerHTML]="'FEATURED.MODEL.CHANGETOCLIENT' | translate"></span>
            <br />
            <display-enduser-button [clickable]="false"></display-enduser-button>
            <br />
            <span>
                <span [innerHTML]="'FEATURED.MODEL.SWITCH' | translate"></span>
            </span>
        </div>
    </featured-model-overlay>
    <featured-model-overlay *ngIf="showNoStatus">
        <div class="linestatus-text-container overlay">
            <span [innerHTML]="'CUSTOMIZER.UPGRADE-COLLECTION.HEADER' | translate"></span>
            <span>{{'CUSTOMIZER.UPGRADE-COLLECTION.BODY' | translate}}</span>
        </div>
    </featured-model-overlay>
    <featured-model-overlay *ngIf="showAddedToBag">
        <div class="addtobag-container overlay">
            <ng-container *ngIf="isAddingToTestBag">
                <span>{{'CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDTO' | translate}}</span>
                <span class='test-bag-warning'>{{'CUSTOMIZER.COMPONENTS.CONFIGURATION.YOURTESTBAG' | translate: {count: 1} }}</span>
            </ng-container>
            <span *ngIf="!isAddingToTestBag">{{'CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDFRAMETOBAG' | translate: {count: 1} }}</span>
            <br />
            <span *ngIf="showStartKitAddedToBag">{{ 'CUSTOMIZER.COMPONENTS.CONFIGURATION.ADDEDSTARTKITTOBAG' | translate : {count: 1} }}</span>
        </div>
    </featured-model-overlay>
    <featured-model-overlay class="start-kit-overlay" *ngIf="showStartKitModal">
        <app-start-kit-chooser
            [productLineCode]="modelGroup.ProductLineCode"
            [kidTeen]="modelGroup.KidTeen"
            (resultChange)="startKitChosen($event)"
        ></app-start-kit-chooser>
    </featured-model-overlay>

    <featured-model-overlay *ngIf="showCustomizerParamsOverlay">
        <div class="overlay">
            <div>
                <ui-button [buttonStyle]="'secondary'" [block]="true" *ngIf="modelGroup.HasForms" [active]="customizerParams.hasForms" (click)="customizerParams.hasForms = !customizerParams.hasForms;">{{'FEATURED.ADDFORMS' | translate}}</ui-button>
                <ui-button [buttonStyle]="'secondary'" [block]="true" *ngIf="modelGroup.HasFixtures" [active]="customizerParams.hasFixtures" (click)="customizerParams.hasFixtures = !customizerParams.hasFixtures;">{{'FEATURED.ADDFIXTURE' | translate}}</ui-button>
            </div>
        </div>
    </featured-model-overlay>

    <featured-model-overlay *ngIf="showOrderChooserModal">
        <app-order-chooser
            [customer]="orderOnBehalfService.customerAsObservable()|async"
            (orderIdChange)="addToBagOrderSelected($event)"
        ></app-order-chooser>
        <ui-button
            [buttonType]="'button'"
            [buttonStyle]="'secondary'"
            [block]="true"
            (click)="cancelOrderChooser()"
        >{{ 'COMMON.CANCEL' | translate }}</ui-button>
    </featured-model-overlay>

    <div class="content-container">
        <div class="top-menu flex">
            <add-to-favorite *ngIf="isReady"
                class="favorite-button"
                (hasBeenDeleted)="favoriteDeleted()"
                [variants]="variants"
                [model]="featuredConfiguration.Model"
                [configuration]="featuredConfiguration.ModelConfiguration"
                [modelSize]="featuredConfiguration.ModelSize"
                [manualconfigGroups]="allManualConfigGroups"
                [templeInclination]="featuredConfiguration.TempleInclination"
                [favoriteUuid]="featuredConfiguration.FavoriteUuid"
                [isInCollection]="featuredConfiguration.IsInCollection"
                (showMyCollectionsOverlay)="showMyCollections()" 
                (applyFavoriteChange)="applyFavoriteChange(featuredConfiguration.FavoriteUuid)"
                (applyFavoriteCollectionChange)="applyFavoriteCollectionChange(featuredConfiguration.IsInCollection)"
                >
            </add-to-favorite>

            <div class="flex flex-row flex-grow flex-end buttons-right">
                <button class="btn btn-secondary btn-icon" *ngIf="canSetCustomizerParams() && !showCustomizerParamsOverlay" (click)="toggleCustomizerParamsOverlay()">
					<div class="button-content">
						<i class="fa-light fa-plus fa-lg"></i>
					</div>
                </button>
                <button class="btn btn-secondary btn-icon" *ngIf="canSetCustomizerParams() && showCustomizerParamsOverlay" (click)="toggleCustomizerParamsOverlay()">
					<div class="button-content">
						<i class="fa-light fa-xmark fa-lg"></i>
					</div>
                </button>
                <button *ngIf="hasInspirationEditPermission && IsGlobal" class="btn btn-secondary remove-inspiration" (click)="removeInspirationConfiguration()">
					<div class="button-content">
						<i class="fa-light fa-plus fa-lg"></i>
					</div>
                </button>
            </div>
        </div>
        <div class="flex flex-column flex-center image-text-container">
            <div class="row availability-container">
                <availability-text *ngIf="modelGroup"
                    [model]="featuredConfiguration.Model"
                    [modelgroup]="modelGroup"
                    [modelSize]="featuredConfiguration.ModelSize"
                    [configuration]="featuredConfiguration.ModelConfiguration"
                    [variants]="variants"
                    [showAllNewflags]="true"
                    [new]="featuredConfiguration.Model.New"
                    [newLargeText]="true"
                    [useNewDetails]="true"
                ></availability-text>
                <span *ngIf="hasInspirationEditPermission && IsGlobal">
                    <ng-container *ngIf="!isReleased">
                            {{ 'FEATURED.VISIBLEFROM' | translate: {date: featuredConfiguration.Release | dateToLocal} }}
                    </ng-container>
                    <ng-container *ngIf="isReleased">
                            {{ 'FEATURED.VISIBLENOW' | translate }}
                    </ng-container>
                </span>
            </div>
            <div class="row imagecontainer">
                <dcg-image *ngIf="imageUrl" [src]="imageUrl"></dcg-image>
                <dcg-image *ngIf="imageUrlRimlessSide" [src]="imageUrlRimlessSide"></dcg-image>
            </div>
        </div>
        <div class="row model-row">
            <h3>{{featuredConfiguration.Model.Code}}</h3>
            <customizer-summary
                class="center"
                *ngIf="modelGroup"
                [model]="featuredConfiguration.Model"
                [modelGroup]="modelGroup"
                [modelSize]="featuredConfiguration.ModelSize"
                [variants]="summaryModels"
                [configuration]="(modelGroup.IsConfigurationBased || modelGroup.IsBothConfigAndVariantBased) ? featuredConfiguration.ModelConfiguration.ColorNameAndCode : null"
                [menu]="menuComponents"
                [isDemo]="isDisplay"
                [templeInclination]="featuredConfiguration.TempleInclination"
                [compactMode]="'compact'">
            </customizer-summary>
        </div>
        <div class="row button-row" [ngClass]="{'topFunctions': showCustomizerParamsOverlay}">
            <button *ngIf="availableForDisplay || !isDisplay" class="btn btn-secondary mr-3 inspiration-btn" (click)="goToCustomise()">
                {{'FEATURED.CUSTOMISE' | translate}}
            </button>
            <button *ngIf="availableForDisplay && isDisplay" class="btn btn-primary inspiration-btn" (click)="addToBag()">
                <ng-container>
                    {{'FEATURED.ADDTOBAG' | translate}}
                </ng-container>
            </button>
        </div>
    </div>
</div>