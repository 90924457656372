import { Injectable } from '@angular/core';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { PriceSettingModel } from '@models/price-setting.model';
import { UserDataModel } from '@models/user-data-model';
import { UserPatchModel } from '@models/user-patch-model';
import { AppState } from '@shared/app-state';
import { UserTypeConstant } from '@shared/user-type-constants';
import { LanguageService } from './language.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class PriceSettingsService {

	private allPriceSettings: PriceSettingModel[];

	constructor(
		private languageService: LanguageService,
		private userService: UserService,
		private appState: AppState,
	) {
		this.allPriceSettings = [];

		this.allPriceSettings.push(new PriceSettingModel(PriceSettingEnum.AllowAll, this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.BOTH")));
		this.allPriceSettings.push(new PriceSettingModel(PriceSettingEnum.AllowNetPrice, this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.NET.PRICE")));
		this.allPriceSettings.push(new PriceSettingModel(PriceSettingEnum.AllowSuggestedRetailPrice, this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.SUGGESTED.RETAIL.PRICE")));
		this.allPriceSettings.push(new PriceSettingModel(PriceSettingEnum.AllowNone, this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.NONE")));
	}

	public getPriceText(index: number) {
		let priceText: string = '';

		if (!index) {
			priceText = this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.NONE");

			return priceText;
		}

		switch (index) {
			case PriceSettingEnum.AllowNone:
				priceText = this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.NONE")
				break;
			case PriceSettingEnum.AllowNetPrice:
				priceText = this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.NET.PRICE")
				break;
			case PriceSettingEnum.AllowSuggestedRetailPrice:
				priceText = this.languageService.instant("OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.SUGGESTED.RETAIL.PRICE")
				break;
			case PriceSettingEnum.AllowAll:
				priceText = this.languageService.instant("BAG.PRICE.NET") + ' (' + this.languageService.instant("BAG.PRICE.RETAIL") + ')';
				break;
		}

		return priceText;
	}

	public getAllowedPriceSettings(user: UserDataModel) {
		let allowedPriceSettings: PriceSettingModel[] = [];

		if (user.PriceSettingAllowed && user.PriceSettingAllowed > 0) { //!== PriceSetting.AllowNone) {

			switch (user.PriceSettingAllowed) {
				case PriceSettingEnum.AllowNetPrice:
					allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowNone));
					break;

				case PriceSettingEnum.AllowSuggestedRetailPrice:
					allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowSuggestedRetailPrice));
					allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowNone));
					break;

				case PriceSettingEnum.AllowAll:
					allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowNetPrice));
					allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowSuggestedRetailPrice));
					allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowNone));

					if (user.Type === UserTypeConstant.Employee) {
						allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowAll));
					}

					break;

				default:
					// ERROR
					break;
			}

			allowedPriceSettings.sort((a, b) => a.PriceSetting - b.PriceSetting);
		} else {
			allowedPriceSettings.push(this.allPriceSettings.find(ps => ps.PriceSetting === PriceSettingEnum.AllowNone));
		}

		return allowedPriceSettings;
	}

	public async patchUserPriceSettings(user: UserDataModel, priceSetting: PriceSettingModel) {
		await new Promise<void>(async (resolve) => {
			user.PriceSettingDisplay = priceSetting.PriceSetting;
			this.userService.setUser(user);

			const userPatchModel = new UserPatchModel(user.RecordId, user.Uuid);
			userPatchModel.PriceSettingDisplay = priceSetting.PriceSetting;
			await this.userService.patchUser(userPatchModel);

			this.appState.priceSetting.next(new PriceSettingModel(priceSetting.PriceSetting, this.getPriceText(priceSetting.PriceSetting)));

			resolve();
		});
	}

}
