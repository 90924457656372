import { Injectable } from "@angular/core";
import { PriceSettingEnum } from "@infrastructure/price-setting.enum";
import { PriceSettingModel } from "@models/price-setting.model";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class AppState {

    public showChineseLicenseId = false;
    public showLinkToStoragePage = false;
    public appWasUpdated = false;
    public storageErrorOccured: Subject<void> = new Subject<void>();
    public newNotificationsCount = new BehaviorSubject<number>(0);
    public priceModuleEnabled = new BehaviorSubject<boolean>(false);
    public showPreciousTheme = new BehaviorSubject<boolean>(true);
    public priceSetting: BehaviorSubject<PriceSettingModel>;
    public priceWasPossiblyInfluenced: Subject<any> = new Subject();
    public signalrConnectionId = new BehaviorSubject<string>(null);

	constructor(
		translateService: TranslateService
	)
	{
		this.priceSetting = new BehaviorSubject<PriceSettingModel>(new PriceSettingModel(PriceSettingEnum.AllowNone, translateService.instant('OPTIONS-DROPDOWN.PRICESETTINGS.SHOW.NONE')));
	}

}
