import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { LoginService } from 'src/_services/login.service';
import { PermissionConstants } from '../shared/permission-constants';

@Injectable()
export class AdvancecustomizeGuard  {
    constructor(private loginService: LoginService, private router: Router, private userService: UserService) { }

    async canActivate() {
        const user = await this.userService.GetUser();

        if (this.loginService.IsLoggedIn()) {
            if(user.UserPermissions.some(x => x == PermissionConstants.IsAdmin || x == PermissionConstants.CanOrderOnBehalf || x === PermissionConstants.CanOrderOnBehalfOfChainCustomer)) {
                return true;
            } else {
                return false;
            }
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
