import { Component, SimpleChanges } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { MiscModel } from '@models/misc-model';
import { BrandModeService } from '@services/brandmode.service';
import { ConfigurationService } from '@services/configuration.service';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { ModelGroupModel } from 'src/_models/model-group';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ComponentVariantModel } from '../../../../_models/component-variant';
import { EngravingService } from '../../../../_services/engraving.service';
import { ComponentCodeConstants } from '../../../../shared/component-constants';
import { ProductlineConstants } from '../../../../shared/productline-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'engraving',
	styleUrls: ['./engraving.scss'],
	template: `
        <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
            <div class="has-padding-horizontal-30px" *ngIf="engravingPossible === 0">
                {{'CUSTOMIZER.COMPONENTS.ENGRAVING.NOTPOSSIBLE' | translate}}
            </div>

            <div class="has-padding-horizontal-30px engraving-container" *ngIf="engravingPossible > 0">
                <div class="engraving-input customizer-input" [class.has-error]="engravingError">
                    <ng-container *ngIf="showOverlay">
                        <menu-overlay
                            [mainOverlayText]="null"
                            [secondOverlayText]="'CUSTOMIZER.CHANGES.BAGUPDATED' | translate"
                            (overlayClicked)="null"
                        >
                        </menu-overlay>
                    </ng-container>
                    <input class="form-control"
                        [ngModel]="engravingFirst"
                        (ngModelChange)="onUpdateEngraving($event)"
                        [maxlength]="engravingPossible"
                        type="text"
                        [placeholder]="'CUSTOMIZER.COMPONENTS.ENGRAVING.ENTERUSERNAMEPLACEHOLDER' | translate"
                    >
                    <input class="form-control"
                        [ngModel]="engravingRepeat"
                        (ngModelChange)="onUpdateEngravingRepeat($event)"
                        [maxlength]="engravingPossible"
                        type="text"
                        [placeholder]="'CUSTOMIZER.COMPONENTS.ENGRAVING.REENTERUSERNAMEPLACEHOLDER' | translate"
                    >
                </div>
                <div class="engraving-error">
                    <span *ngIf="engravingError">{{engravingError | translate}}</span>
                </div>
                <div class="engraving-instructions">
                    <p>- {{'CUSTOMIZER.COMPONENTS.ENGRAVING.DIRECTIONS1' | translate:{ maxlength: engravingPossible } }}</p>
                    <p>- {{'CUSTOMIZER.COMPONENTS.ENGRAVING.DIRECTIONS2' | translate:{ maxlength: engravingPossible } }}</p>
                </div>
                <div *ngIf="brandMode !== brandEnumType.Precious && ProductlineConstants.SUN !== modelGroup.ProductLineCode && !modelGroup.IsRimless" class="engraving-info">
                    <p>{{'CUSTOMIZER.COMPONENTS.ENGRAVING.INFORMATION' | translate }}</p>
                    
                    <p>{{'CUSTOMIZER.COMPONENTS.ENGRAVING.INFORMATION.RIGHT.OF.RETURN' | translate }}</p>
                </div>
            </div>
        </customizer-component-header>

    `
})
export class EngravingComponent extends BaseCustomizerComponent {

	brandMode: BrandEnum;
	brandEnumType = BrandEnum;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private engravingService: EngravingService,
		brandModeService: BrandModeService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
		brandModeService.currentBrand.subscribe(x => this.brandMode = x);
	}

	public engravingPossible: number;
	public engravingFirst: string = "";
	public engravingRepeat: string = "";
	public ProductlineConstants = ProductlineConstants;
	public showOverlay: boolean = false;
	public engravingError: string;

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		if (demo) {
			return false;
		}
		else {
			return EngravingComponent.variantWithEngravingPossible(variants);
		}
	}

	private static variantWithEngravingPossible(variants: Array<ComponentVariantModel>): boolean {
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < variants.length; i++) {
			if (variants[i].EngravingPossible) {
				return true;
			}
		}

		return false;
	}

	initData() {
		this.engravingFirst = this.engravingService.engraving;
		this.engravingRepeat = this.engravingService.engravingRepeat;
		this.engravingError = this.engravingService.engravingError();
		this.fetchEngravingPossible();
	}

	onChanges(changes: SimpleChanges): void {
	}

	onUpdateEngraving(engraving: string) {
		this.engravingFirst = engraving.toUpperCase();
		this.engravingService.engraving = this.engravingFirst;
		this.checkEngraving();
	}

	onUpdateEngravingRepeat(engraving: string) {
		this.engravingRepeat = engraving.toUpperCase();
		this.engravingService.engravingRepeat = this.engravingRepeat;
		this.checkEngraving();
	}

	checkEngraving() {
		this.engravingError = this.engravingService.engravingError();
		this.menu.error = this.engravingError.length > 0;

		if (!this.engravingError) {
			this.events.showLoader(true);
			this.updateEngraving(this.engravingService.engraving);

			if (this.state == this.customizerStateEnum.EDITORDER) {
				this.showOverlay = true;
				new Promise(resolve => setTimeout(resolve, 2500)).then(x => {
					this.showOverlay = false;
				});
			}
		}
	}

	fetchEngravingPossible() {
		const chosenTemple = this.getVariant();
		this.engravingPossible = chosenTemple.EngravingPossible;
	}

	private getVariant(): ComponentVariantModel {
		return this.variants.find((obj) => obj.Component.Code == ComponentCodeConstants.Temple);
	}

}
