<div class="modal-body">
    <button class="close" (click)="closeAndDestroy()">
        <i class="fa-light fa-xmark"></i>
    </button>
    <div class="header-row row">
        <div class="header col-xs-12">
            <h1>{{ 'DISPLAYENDUSER.MODALHEADER' | translate }}</h1>
        </div>
    </div>
    <div class="row info-row">
            <div class="info-content col-xs-12 col-sm-6">
                <div class="font-medium">
                    {{ 'DISPLAYENDUSER.ENDUSERHEADER' | translate }}
                </div>
                <br />
                <div [innerHTML]="'DISPLAYENDUSER.ENDUSERINFO' | translate">
                </div>
            </div>
            <div class="info-content col-xs-12 col-sm-6">
                <div class="font-medium">
                    {{ 'DISPLAYENDUSER.DISPLAYHEADER' | translate }}
                </div>
                <br />
                <div [innerHTML]="'DISPLAYENDUSER.DISPLAYINFO' | translate">
                </div>
            </div>
    </div>
    <div class="ok-div row">
        <div class="col-xs-12 flex flex-h-center">
            <button (click)="onConfirm()" class="btn btn-primary">
                    {{ 'COMMON.OK' | translate }}
            </button>
        </div>
    </div>
</div>