import { Component, Input } from '@angular/core';

@Component({
	selector: 'icon-favorite',
	styleUrls: ['./icon-favorite.component.scss'],
	template: `
        <div *ngIf="isFavorite === true" class="favorite">
            <i class="fa-solid fa-heart fa-xl"></i>
        </div>

        <div *ngIf="isFavorite === false" class="not-favorite">
            <i class="fa-light fa-heart fa-xl"></i>
        </div>
	`
})
export class IconFavoritComponent {

	@Input() isFavorite: boolean;

}
