import { SimpleModelModel } from "@models/api-models/simple-model-model";
import { IValueTag } from '../value-tag.interface';

export interface ITagHandler {
    getTags(models: Array<SimpleModelModel>): Array<IValueTag<Array<SimpleModelModel>>>;
    setNext(tagHandler: ITagHandler): ITagHandler;
}

export class AbstractTagHandler implements ITagHandler {
    private nextHandler: ITagHandler;

    getTags(models: Array<SimpleModelModel>): Array<IValueTag<Array<SimpleModelModel>>> {
        return this.nextHandler?.getTags(models);
    }

    setNext(tagHandler: ITagHandler): ITagHandler {
        this.nextHandler = tagHandler;
        return this.nextHandler;
    }
}
