import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericInfoModalComponent } from '@modals/generic/generic-info.modal';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';

@Component({
    selector: 'generic-confirm-modal',
    styleUrls: ['./generic-confirm-modal.scss'],
    template: `
        <div class="modal-body">
            <button class="close" (click)="closeAndDestroy()">
                <i class="fa-light fa-xmark"></i>
            </button>
            <div class="information col-xs-12 col-sm-10 col-md-8 col-lg-6">
                <h3 [innerHTML]="title | translate"></h3>
                <h4 *ngIf="contentPart1" [innerHTML]="contentPart1 | translate"></h4>
                <h4 *ngIf="contentPart2" [innerHTML]="contentPart2 | translate"></h4>
                <div *ngIf="contentPart3" [innerHTML]="contentPart3 | translate"></div>
                <div class="buttons">
                    <ui-button [buttonStyle]="'primary'" (click)="doAction()">{{ actionText | translate }}</ui-button>
                    <ui-button [buttonStyle]="'secondary'" (click)="closeAndDestroy()">{{ "COMMON.CANCEL" | translate }}</ui-button>
                </div>
            </div>
        </div>
    `
})
export class GenericConfirmModalComponent extends GenericInfoModalComponent {
    @Input()  actionText = "COMMON.OK";
    @Output() emitter = new EventEmitter();

    constructor(breadcrumbService: BreadcrumbService) {
        super(breadcrumbService);
    }

    doAction() {
        this.emitter.emit();
        this.closeAndDestroy();
    }
}
