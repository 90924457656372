import { BrandEnum } from "@infrastructure/brand.enum";
import { Type } from "class-transformer";
import { ComponentVariantFromApiModel } from "./api-models/component-variant-fromapi";
import { ComponentVariantModel } from "./component-variant";
import { MenuComponentModel } from "./menu-component";

export class MenuModel {
    Id: number;
    Uuid: string;
    Name: string;
    TranslateName: string;
    TranslateDescription: string;
    ParentMenuId: number;
    Icon: string;
    Components: Array<string>;
    Order: string;
    ContentGrouping: boolean;
    ModelGroupUuid: string;
    Brand: BrandEnum;
    MatchColor: number;

    @Type(() => MenuModel)
    SubMenus: Array<MenuModel>;

    @Type(() => MenuComponentModel)
    MenuComponentModels: Array<MenuComponentModel>;

    public getVariantsOnMenu(variants: Array<ComponentVariantModel>) {
        const variantsOnMenu = new Array<ComponentVariantModel>();
        this.MenuComponentModels.forEach(mc => {
            variants.forEach(v => {
                if(v.Component.Uuid == mc.ComponentUuids || v.Uuid == mc.ComponentVariantUuids) {
                    variantsOnMenu.push(v);
                }
            });
        });

        return variantsOnMenu;
    }

    public getApiVariantsOnMenu(variants: Array<ComponentVariantFromApiModel>) {
        const variantsOnMenu = new Array<ComponentVariantFromApiModel>();
        this.MenuComponentModels.forEach(mc => {
            variants.forEach(v => {
                if(v.Component.Uuid == mc.ComponentUuids || v.Uuid == mc.ComponentVariantUuids) {
                    variantsOnMenu.push(v);
                }
            });
        });

        return variantsOnMenu;
    }

    public hasVariantOrComponent(componentUuids: Array<string>, variantUuids: Array<string>) {
        return this.MenuComponentModels
            .some(mc =>
                (mc.ComponentUuids != null && componentUuids.some(x => mc.ComponentUuids == x))
            || (mc.ComponentVariantUuids != null && variantUuids.some(x => mc.ComponentVariantUuids == x))
        );
    }
}
