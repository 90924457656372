import { Component, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DiscountModel } from '@models/discounts/discount-model';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { EnduserDisplayService } from '@services/enduserdisplay.service';
import { EventsService } from '@services/events.service';
import { ConfigurationMode } from '@services/image.service';
import { LanguageService } from '@services/language.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { UserService } from '@services/user.service';
import { BaseCustomizerComponent } from '@shared/base-customizer-component';
import { UserTypeConstant } from '@shared/user-type-constants';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { ModelGroupModel } from 'src/_models/model-group';
import { DataApiService } from 'src/_services/data-api.service';

@Component({
    selector: 'reference',
    templateUrl: 'reference.html',
    styleUrls: ['./reference.scss']
})
export class ReferenceComponent extends BaseCustomizerComponent implements OnDestroy {
    public discountsList: Array<DiscountModel> = [];
    public maxlength: number = 30;
    public showUpdate: boolean;
    public showMandatoryMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public referenceControl = new UntypedFormControl();
    public commentControl = new UntypedFormControl();
    public showOverlay: boolean = false;
    public subscriptions: Array<Subscription> = [];
    public clientConfigurationMode = ConfigurationMode.enduser;

    constructor(
        public events: EventsService,
        public translateService: LanguageService,
        public onBehalfOfService: OrderOnBehalfService,
        public userService: UserService,
        public enduserService: EnduserDisplayService,
        public apiService: DataApiService,
        public configurationService: ConfigurationService
    ) {
        super(events, apiService, configurationService);

    }

    public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
        return true;
    }


    async initData() {
        let delay = 0;
        if (this.state == this.customizerStateEnum.EDITORDER) {
            delay = 1500;
        }

        this.subscriptions.push(this.referenceControl.valueChanges.pipe(debounceTime(delay)).subscribe(newValue => this.onUpdateReference(newValue)));
        this.subscriptions.push(this.commentControl.valueChanges.pipe(debounceTime(delay)).subscribe(newValue => this.onUpdateComment(newValue)));

        this.subscriptions.push(combineLatest([this.onBehalfOfService.customerAsObservable(), this.enduserService.enduserDisplayChangedObservable()])
            .subscribe(async ([customer, display]) => {
                this.discountsList = await this.dataApiService.getDiscounts(customer, display === ConfigurationMode.enduser);

                if (this.discount && !this.discountsList.some(x => x.key === this.discount.key)) {
                    this.updateDiscount(null);
                }
            })
        );
        this.showMandatoryMessage.next(await this.doShowMandatoryMessage());
    }

    async onChanges(changes: SimpleChanges): Promise<void> {
        if (changes.reference && changes.reference.currentValue != changes.reference.previousValue && this.referenceControl.value != changes.reference.currentValue) {
            this.referenceControl.setValue(changes.reference.currentValue);
        }
        if (changes.comment && changes.comment.currentValue != changes.comment.previousValue && this.commentControl.value != changes.comment.currentValue) {
            this.commentControl.setValue(changes.comment.currentValue);
        }
        this.showMandatoryMessage.next(await this.doShowMandatoryMessage());

    }

    onUpdateReference(reference: string) {
        this.events.showLoader(true);
        this.updateReference(reference);

        if (this.state == this.customizerStateEnum.EDITORDER) {
            this.showOverlay = true;
            new Promise(resolve => setTimeout(resolve, 2500)).then(x => {
                this.showOverlay = false;
            });
        }
    }

    onUpdateComment(comment: string) {
        this.events.showLoader(true);
        this.updateComment(comment);

        if (this.state == this.customizerStateEnum.EDITORDER) {
            this.showOverlay = true;
            new Promise(resolve => setTimeout(resolve, 2500)).then(x => {
                this.showOverlay = false;
            });
        }
    }

    onUpdateStatistics() {

    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    private async doShowMandatoryMessage(): Promise<boolean> {

        const user = await this.userService.GetUser();

        const doShowMandatoryMessage = user.WebResources &&
            user.hasMandatoryClientInfo &&
            user.Type === UserTypeConstant.Customer &&
            this.enduserService.IsClient() &&
            this.model.IsRimless &&
            this.engraving !== '' &&
            (!this.age || !this.gender || !this.firstPair);

        return doShowMandatoryMessage;
    }
}
