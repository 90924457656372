import { Routes } from '@angular/router';
import { FeaturedExpiredPageComponentComponent } from '@app/featured-expired-page-component/featured-expired-page-component.component';
import { HasInspirationDataGuard } from '@guards/has-inspiration-data.guard';
import { AuthGuard } from 'src/_guards/auth.guard';
import { UrlConstants } from './../../shared/url-constants';
import { FeaturedPageComponent } from './featured';

export const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard, HasInspirationDataGuard],
        data: {
            breadcrumb: ':' + UrlConstants.INSPIRATIONGROUP,
        },
        component: FeaturedPageComponent
    },
    {
        path: 'expired',
        canActivate: [AuthGuard, HasInspirationDataGuard],
        data: {
            breadcrumb: 'BREADCRUMBS.EXPIRED',
        },
        component: FeaturedExpiredPageComponentComponent
    },
];
