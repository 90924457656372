import { Directive, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { LoginService } from "../_services/login.service";
import { ModalService } from '../_services/modal.service';
import { NavigateService } from "../_services/navigate.service";
import { TitleService } from "../_services/title.service";

@Directive()
export abstract class BasePage implements OnDestroy {

	public isReady: boolean = false;

	public subscriptions: Array<Subscription> = [];

	constructor(
		protected loginService: LoginService,
		protected titleService: TitleService,
		protected modalService: ModalService,
		protected navigateService: NavigateService
	) { }

	ngOnDestroy(): void {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

}
