import { OrderItemModel } from './order-item';
import { OrderItemMiscModel } from './order-item-misc-model';

export class BagItemModel {

	public MiscOrderItem: OrderItemMiscModel;
	public FrameOrderItem: OrderItemModel;
	public CreatedAt: Date;

	public get AnySelected() {
		return this.MiscOrderItem ? this.MiscOrderItem.Selected : this.FrameOrderItem.Selected;
	}

	public static FromOrderItem(orderItem: OrderItemModel | OrderItemMiscModel) {
		const newBagItem = new BagItemModel();

		if (orderItem instanceof OrderItemModel) {
			newBagItem.FrameOrderItem = orderItem;
		}
		else if (orderItem instanceof OrderItemMiscModel) {
			newBagItem.MiscOrderItem = orderItem;
		}
		else {
			throw new Error("orderItem is null or wrong type");
		}

		newBagItem.CreatedAt = orderItem.CreatedAt;

		return newBagItem;
	}

}
