import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ExternalSearchService {
    private inboundSearchTerm: EventEmitter<string> = new EventEmitter();

    public inboundSearchTermObserveable(): Observable<string> {
        return this.inboundSearchTerm.asObservable();
    }

    public search(term: string): void {
        this.inboundSearchTerm.emit(term);
    }
}
