import ValidationError from '@infrastructure/validation-error';
import ValidationResult from '@infrastructure/validation-result';
import { IOrderItemDiscount } from '@models/iorderitem-discountvalidation';
import { DiscountConstants } from '@shared/discount-constants';
import { nameof } from 'ts-simple-nameof';

export abstract class DiscountModel {
    public abstract readonly key: string;
    public percentage: number;

    public validate(orderItem: IOrderItemDiscount): ValidationResult {
        const isNumber = new RegExp(/^\d*$/);
        const result = new ValidationResult();
        if(this.isNotAPercentage(isNumber)) {
            result.addError(new ValidationError(nameof(x => this.percentage), "DISCOUNT.ERRORS.PERCENTAGEINCORRECT"));
        }
        return result;
    }

    private isNotAPercentage(isNumber: RegExp) {
        return this.percentage == null || !isNumber.test(this.percentage.toString()) || this.percentage < 1 || this.percentage > 100;
    }
}

export class DiscountModelFactory {
    public static CreateDiscountModel(key: string, percentage: number): DiscountModel {
        switch(key) {
            case DiscountConstants.CC:
                return DiscountModelFactory.getCCDiscount(percentage);
            case DiscountConstants.SF:
                return DiscountModelFactory.getSFDiscount(percentage);
            case DiscountConstants.RC:
                return DiscountModelFactory.getRCDiscount(percentage);
            default:
                DiscountModelFactory.throwWrongDiscountError(key);
        }
    }

    static throwWrongDiscountError(key: string) {
        throw new Error(`${key} is not an available discount`);
    }

    private static getRCDiscount(percentage: number) {
        const model = new RCDiscountModel();
        model.percentage = percentage;
        return model;
    }

    private static getSFDiscount(percentage: number) {
        const model = new SFDiscountModel();
        model.percentage = percentage;
        return model;
    }

    private static getCCDiscount(percentage: number) {
        const model = new CCDiscountModel();
        model.percentage = percentage;
        return model;
    }
}

export class CCDiscountModel extends DiscountModel {
    public readonly key: string = DiscountConstants.CC;
    public percentage: number;

    public validate(orderItem: IOrderItemDiscount) {
        const result = super.validate(orderItem);
        return result;
    }
}

export class RCDiscountModel extends DiscountModel {
    public static readonly RC100PercentMinFramesCount = 5;
    public readonly key: string = DiscountConstants.RC;
    public percentage: number;

    public validate(orderItem: IOrderItemDiscount) {
        const result = super.validate(orderItem);
        return result;
    }
}

export class SFDiscountModel extends DiscountModel {
    public readonly key: string = DiscountConstants.SF;
    public percentage: number;

    public validate(orderItem: IOrderItemDiscount) {
        const result = super.validate(orderItem);

        const possible = orderItem.Variants.some(x => x && x.EngravingPossible > 0);

        if(!orderItem.Engraving || orderItem.Engraving === "") {
            if(possible) {
                result.addError(new ValidationError(nameof(x => this.key), "DISCOUNT.ERRORS.SFNOENGRAVING"));
            } else if(!orderItem.Reference || orderItem.Reference === "") {
                result.addError(new ValidationError(nameof(x => this.key), "DISCOUNT.ERRORS.SFNOREFERENCE"));
            }
        }
        return result;
    }
}
