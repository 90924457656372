import { Type } from "class-transformer";
import { DataTranslationModel } from './data-translation-model';

export class TagModel {
    public Id: number;
    public Code: string;

    @Type(() => DataTranslationModel)
    public Description: DataTranslationModel;
    public IsKid: boolean;
    @Type(() => TagGroupModel)
    public TagGroup: TagGroupModel;
    @Type(() => TagTypeModel)
    public TagType: TagTypeModel;
    public Order: string;
    public Selected: boolean;
}

export class TagGroupModel {
    public Id: number;
    public Name: string;
    public Order: string;
}

export class TagTypeModel {
    public Id: number;
    @Type(() => DataTranslationModel)
    public Name: DataTranslationModel;
    public Order: string;
}
