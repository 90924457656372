import { ComponentBindingModel } from 'src/_models/component-binding';
import { ComponentDependencyModel } from 'src/_models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { BindingConstants } from "../../../shared/dependency-constants";
import { BindingMatcherMethods } from '../binding-matcher-methods';
import { BindingMatchingDependencyCalculator } from '../binding-matching-dependency-calculator';
import { IDependencyCalculator } from "../dependency-calculator.interface";

export class BlockBindingsDependencyCalculator implements IDependencyCalculator {
    private matcherMethods = new BindingMatcherMethods();

    constructor(private findBy: Array<IDependencyCalculator>) {
    }

    async getChanges(
        dependency: ComponentDependencyModel,
        allBindings: Array<ComponentBindingModel>,
        currentVariant: ComponentVariantModel,
        allVariants: Array<ComponentVariantModel>,
        variantSelectionToCheckAgainst: Array<ComponentVariantModel>,
        lastBindings: Array<ComponentBindingModel>
    ): Promise<Array<ComponentVariantChangeModel>> {
        return dependency.Binding === BindingConstants.Block ? await this.getBlockChanges(dependency, allBindings, currentVariant, allVariants, variantSelectionToCheckAgainst) : [];
    }

    private async getBlockChanges(
        dependency: ComponentDependencyModel,
        allBindings: Array<ComponentBindingModel>,
        currentVariant: ComponentVariantModel,
        allVariants: Array<ComponentVariantModel>,
        variantSelectionToCheckAgainst: Array<ComponentVariantModel>
    ): Promise<Array<ComponentVariantChangeModel>> {
        for (const calculator of this.getCalculators(dependency)) {
            const changes = await calculator.getChanges(dependency, allBindings, currentVariant, allVariants, variantSelectionToCheckAgainst, []);
            if(changes.length) {
                return changes;
            }
        }
        return [];
    }

    private getCalculators(dependency: ComponentDependencyModel): Array<IDependencyCalculator> {
        const result = [];

        const component2Calculators = [
            new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeColorMethod(dependency.Component2), this.findBy),
            new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeMethod(dependency.Component2), this.findBy),
            new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantMethod(dependency.Component2), this.findBy),
        ];

        result.push(...[
            new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeColorMethod(dependency.Component1), component2Calculators),
            new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeMethod(dependency.Component1), component2Calculators),
            new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantMethod(dependency.Component1), component2Calculators),
            new BindingMatchingDependencyCalculator(this.matcherMethods.findByComponentMethod(dependency.Component1), component2Calculators)
        ]);

        if(dependency.IsReversible) {
            const component2CalculatorsReversed = [
                new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeColorMethod(dependency.Component1), this.findBy),
                new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeMethod(dependency.Component1), this.findBy),
                new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantMethod(dependency.Component1), this.findBy),
            ];

            result.push(...[
                new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeColorMethod(dependency.Component2), component2CalculatorsReversed),
                new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantSizeMethod(dependency.Component2), component2CalculatorsReversed),
                new BindingMatchingDependencyCalculator(this.matcherMethods.findByVariantMethod(dependency.Component2), component2CalculatorsReversed),
                new BindingMatchingDependencyCalculator(this.matcherMethods.findByComponentMethod(dependency.Component2), component2CalculatorsReversed)
            ]);
        }

        return result;
    }
}
