import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService, ImageSize } from 'src/_services/image.service';
import { ComponentVariantFromApiModel } from '../../../../_models/api-models/component-variant-fromapi';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	template: `
    <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
        <div *ngIf="designs.length">
            <select-box
                [editMode]="state === customizerStateEnum.EDITORDER"
                [imageClass]="'innerrim'"
                [optionsImage]="getImage"
                [options]="designs"
                optionsText="Description"
                optionsId="Id"
                [selected]="getSelectedVariant"
                [colorMatchMode]="false"
                (selectedChange)="onSelected($event)"
                [optionsDiscontinued]="'Discontinued'"
                [variantOrModelIsNew]="modelIsNew"
                [matchColor]="menu.matchColor">
            </select-box>
        </div>
    `
})
export class InnerrimDesignComponent extends BaseCustomizerComponent implements OnInit {

	public designs: Array<ComponentVariantFromApiModel> = [];

	constructor(
		public events: EventsService,
		private imageService: ImageService,
		public apiService: DataApiService,
		public sorter: SorterService,
		public configurationService: ConfigurationService

	) {
		super(events, apiService, configurationService);
	}

	async initData() {
		this.events.showLoader(true);

		await this.fetchData();
		this.setImageSliderByName(AngleConstants.Perspective);

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
	}

	getImage = (variant: ComponentVariantFromApiModel) => {
		const innerrimImageVariant = variant.ToComponentVariantModel();
		innerrimImageVariant.VariantColor = this.getSelectedVariant.VariantColor;
		const imageVariants = this.variants.concat(this.imageOnlyVariants).filter(x => x.Component.Code != variant.Component.Code);
		imageVariants.push(innerrimImageVariant);

		return this.imageService.GetDetailImage(this.model, this.model.ModelGroupCode, this.configuration.ModelConfigurationCode, imageVariants, ImageSize.Low);
	}

	onSelected(selected: ComponentVariantFromApiModel) {
		this.events.showLoader(true);

		const newVariant = selected.ToComponentVariantModel(this.getSelectedVariant.Engraving, this.getSelectedVariant.OrderLineUuid, true);
		this.updateVariantDesign(new ComponentVariantChangeModel(this.getSelectedVariant, newVariant, selected.VariantSizes));
	}

	async fetchData() {
		this.getSelectedVariant.VariantViewed = true;

		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, this.getSelectedVariant.Component.Uuid);
		await this.RunDisablingSystem(apidata);
		this.designs = apidata.sort(this.sorter.sortBy(ComponentVariantFromApiModel));
	}

}
