import { Component, SimpleChanges } from "@angular/core";
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { VariantColorModel } from 'src/_models/variant-color';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { AngleConstants } from "../../../../shared/angle-constants";
import { BaseCustomizerComponent } from "./../../../../shared/base-customizer-component";

@Component({
    template: `
        <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
            <ng-container *ngIf="selectableVariants.length">
                <customizer-colors-auto-update
                    [translateName]="'COVER.NOCOVER'"
                    [selected]="getSelectedVariant.VariantColor"
                    [selectedColorGroup]="currentColorGroup"
                    [variants]="selectableVariants"
                    [editMode]="state === customizerStateEnum.EDITORDER"
                    [matchableColors]="matchableColors"
                    [useColorGroup]="menu.contentGrouping"
                    (colorMatchChange)="matchColors($event)"
                    (selectedColorGroupChange)="onSelectGroup($event)"
                    (selectedColorChange)="onSelect($event)"
                    [variantOrModelIsNew]="variantOrModelIsNew"
                    [matchColor]="menu.matchColor"
                >
                </customizer-colors-auto-update>
            </ng-container>
        </customizer-component-header>
        <precious-metal-info></precious-metal-info>
    `
})
export class TempleCoverComponent extends BaseCustomizerComponent {

    public selectableVariants: Array<ComponentVariantFromApiModel> = [];
    public availableOnVariants: string = null;
    public notAvailableOnColors: string = null;

    constructor(
        public events: EventsService,
        public apiService: DataApiService,
        public configurationService: ConfigurationService
    ) {
        super(events, apiService, configurationService);
    }

    async initData() {
        this.events.showLoader(true);

        this.selectableVariants = await this.fetchData();
        this.setImageSliderByName(AngleConstants.Perspective);

        this.events.showLoader(false);
    }

    onChanges(changes: SimpleChanges): void { }

    async fetchData(): Promise<Array<ComponentVariantFromApiModel>> {
        this.availableOnVariants = null;
        this.notAvailableOnColors = null;

        const variant = this.getSelectedVariant;
        variant.VariantColorViewed = true;

        const apidata = await this.configurationService.getVariants(
            this.configuration.ModelConfigurationUuid,
            this.modelGroup,
            null,
            variant.Uuid,
            variant.VariantSize.Uuid
        );

        await this.RunDisablingSystem(apidata);

        return apidata;
    }

    onSelect(color: VariantColorModel) {
        this.events.showLoader(true);

        const newVariant = Object.assign(
            new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New),
            this.getSelectedVariant
        );

        const oldVariant = Object.assign(
            new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New),
            this.getSelectedVariant
        );

        newVariant.VariantColor = color;
        newVariant.NotSelected = color == null;

        this.updateVariant(
            new ComponentVariantChangeModel(oldVariant, newVariant, null)
        );
    }

}
