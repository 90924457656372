import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogLevelEnum } from '@infrastructure/logLevel.enum';
import { CustomizerParams } from '@models/customizer-params';
import { CustomizerRouteParams } from '@models/customizer-route-params';
import { MiscModel } from '@models/misc-model';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import { ComponentCodeConstants } from '@shared/component-constants';
import { plainToClass } from 'class-transformer';
import { ModelModel } from 'src/_models/api-models/model';
import { ConfigurationModel } from 'src/_models/configuration';
import { ModelGroupModel } from 'src/_models/model-group';
import { ModelSizeModel } from 'src/_models/model-size';
import { ComponentVariantModel } from '../_models/component-variant';
import { OrderItemModel } from '../_models/order-item';
import { ConfigurationService } from './configuration.service';
import { DataApiService } from './data-api.service';
import { EnduserDisplayService } from './enduserdisplay.service';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';
import { ModelsService } from './v2/models.service';

@Injectable()
export class CustomizerService {

	constructor(
		private sessionService: SessionService,
		private dataApiService: DataApiService,
		private enduserDisplayService: EnduserDisplayService,
		private userService: UserService,
		private loggerService: LoggerService,
		private location: Location,
		private router: Router,
		private route: ActivatedRoute,
		private modelsService: ModelsService,
		private configurationService: ConfigurationService
	) { }

	private set modelGroupData(modelGroup: ModelGroupModel) {
		this.sessionService.set("modelGroup", modelGroup);
	}

	public getModelGroupData(): ModelGroupModel {
		const modelgroup = this.sessionService.get<ModelGroupModel>("modelGroup");
		return modelgroup ? plainToClass(ModelGroupModel, modelgroup) : null;
	}

	private set modelData(model: ModelModel) {
		this.sessionService.set('model', model);
	}

	public getModelData(): ModelModel {
		const model = this.sessionService.get<ModelModel>("model");
		return model ? plainToClass(ModelModel, model) : null;
	}

	public set allVariantsData(variants: Array<ComponentVariantModel>) {
		this.sessionService.set('allVariants', variants);
	}

	public getAllVariants(): Array<ComponentVariantModel> {
		const variants = this.sessionService.get<Array<ComponentVariantModel>>("allVariants");
		return variants ? plainToClass(ComponentVariantModel, variants) : null;
	}

	private set modelSize(modelSize: ModelSizeModel) {
		this.sessionService.set('size', modelSize);
	}

	public getModelSize(): ModelSizeModel {
		const size = this.sessionService.get<ModelSizeModel>("size");
		return size ? plainToClass(ModelSizeModel, size) : null;
	}

	private set configuration(configuration: ConfigurationModel) {
		this.sessionService.set('configuration', configuration);
	}

	public getConfiguration(): ConfigurationModel {
		const configuration = this.sessionService.get<ConfigurationModel>("configuration");
		return configuration ? plainToClass(ConfigurationModel, configuration) : null;
	}

	private set orderItemData(orderItem: OrderItemModel) {
		this.sessionService.set('orderItem', orderItem);
	}

	public getOrderItemData(): OrderItemModel {
		const orderItem = this.sessionService.get<OrderItemModel>("orderItem");
		return orderItem ? plainToClass(OrderItemModel, orderItem) : null;
	}

	public getCase(): MiscModel {
		const altCase = this.sessionService.get<MiscModel>("case");
		return altCase ? plainToClass(MiscModel, altCase) : null;
	}

	private set case(altCase: MiscModel) {
		this.sessionService.set('case', altCase);
	}

	private set templeInclination(templeInclination: TempleInclinationEnum) {
		this.sessionService.set('templeInclination', templeInclination);
	}

	public getTempleInclination(): TempleInclinationEnum {
		const templeInclination = this.sessionService.get<TempleInclinationEnum>("templeInclination");
		return templeInclination;
	}

	private set customizerParams(params: CustomizerParams) {
		this.sessionService.set('customizerParams', params);
	}

	private set customizerRouteParams(customizerRouteParams: CustomizerRouteParams) {
		this.sessionService.set('customizerRouteParams', customizerRouteParams);
	}

	public getCustomizerRouteParams(): CustomizerRouteParams {
		const routeParams = this.sessionService.get<CustomizerRouteParams>("customizerRouteParams");
		return routeParams ? plainToClass(CustomizerRouteParams, routeParams) : null;
	}

	public getCustomizerParams(): CustomizerParams {
		const params = this.sessionService.get<CustomizerParams>("customizerParams");
		return params ? plainToClass(CustomizerParams, params) : null;
	}

	public setCustomizerRouteParams(modelGroup: ModelGroupModel,
		model?: ModelModel,
		modelsize?: ModelSizeModel,
		templeInclination?: TempleInclinationEnum,
		orderItem?: OrderItemModel,
		allVariants?: Array<ComponentVariantModel>,
		modelconfigCode?: string,
		customizerParams?: CustomizerParams
	) {
		this.customizerRouteParams = new CustomizerRouteParams(modelGroup, model, modelsize, templeInclination, orderItem, allVariants, modelconfigCode, customizerParams);
	}

	public async fetchCustomizerData() {
		const customizerRouteParams = this.getCustomizerRouteParams();

		// SET LOCAL VARS TO ROUTE PARAMS

		const { modelGroup, templeInclination, orderItem, allVariants, modelconfigCode, customizerParams } = customizerRouteParams;

		let { modelsize, model } = customizerRouteParams;

		// IF WE HAVE A RIMLESS MODEL GROUP BUT NO SPECIFIC MODEL THEN GET THE DEFAULT MODEL FOR THIS GROUP AND NAVIGATE THERE

		if (modelGroup.IsRimless && !model) {
			const user = await this.userService.GetUser();
			model = await this.modelsService.getDefaultModel(user, modelGroup);

			if (model) {
				this.route.queryParams.subscribe(qp => {
					if (qp && qp.p) {
						let p = this.location.path().substring(0, this.location.path().lastIndexOf('?'))

						this.location.replaceState(p + "/" + model.Code + '?p=true');
					}
					else {
						this.location.replaceState(this.location.path() + "/" + model.Code);
					}
				});
			}
		}

		// ELSE IF WE HAVE A MODEL BUT NO SIZES GET THESE

		if (model) {
			if (!modelsize) {
				const modelsizes = await this.dataApiService.getSizes(model.ProductLineUuid, model.ModelGroupUuid, model.Uuid);
				modelsize = modelsizes.find(x => x.Id == model.DefaultModelSizeId) ?? modelsizes.find(s => s.Default) ?? modelsizes[0];
			}

			let configuration;
			let configurations;

			// IF WE GOT THE SIZES, GET THE CONFIGS

			if (modelsize) {
				configurations = await this.configurationService.getConfigurations(modelsize.Uuid, null);
			}

			if (configurations) {
				const currentModelconfigCode = modelconfigCode ?? model.DefaultModelConfigurationCode;
				configuration = configurations.find(x => x.ModelConfigurationCode == currentModelconfigCode) || configurations[0];

				if (configuration) {
					await this.configurationService.getVariantsWithDisplayFilter(configuration.ModelConfigurationUuid, modelGroup, null, null, null, orderItem?.Demo ?? this.enduserDisplayService.IsDisplay());
				}
			}

			if (allVariants && allVariants.length > 0) {
				this.allVariantsData = allVariants;
			} else {
				this.allVariantsData = model?.DefaultVariantModels;
			}

			const currentTemple = model?.DefaultVariantModels.find(x => x.Component.Code == ComponentCodeConstants.Temple);
			if (!currentTemple) {
				let logEvent = {
					message: `currentTemple is null on model ${model?.Uuid}`,
					extraPropertiesToLog: {
						['possibleDataError']: JSON.stringify(model.DefaultVariantModels)
					},
					logLevel: LogLevelEnum.Warning,
					eventId: `model.Uuid [${model?.Uuid}]`
				}
				this.loggerService.sendClientLog(logEvent);
			}

			if (configuration) {
				const cases = await this.configurationService.getCases(model.Id, modelsize, configuration.ModelConfigurationUuid, modelGroup, currentTemple?.Component?.Uuid, currentTemple?.Uuid, currentTemple?.VariantSize?.Uuid);
				this.case = cases?.find(t => t.Default)?.MiscModel ?? cases?.first()?.MiscModel;
			}

			this.modelGroupData = modelGroup;
			this.modelData = model;
			this.modelSize = modelsize;
			this.configuration = configuration;
			this.orderItemData = orderItem;
			this.templeInclination = templeInclination;
			this.customizerParams = customizerParams;
		}

		if (model == null || modelGroup == null) {
			await this.router.navigate(['/']);
		}
	}

}
