export class MiscModel {

	public EcommerceNoUnique: string;
	public EcommerceNo: string;
	public EcommerceVariantCode: string;
	public Description: string;
	public ShelfNo: string;
	public ItemCategoryDescription: string;
	public ItemGroupDescription: string;
	public BinCode: string;
	public MaximumOrderQty: number;
	public NeedApprovalOnWebOrder: boolean;
	public LocationCode: string;
	public WebResourceCodes: Array<string>;
	public ItemExistsInBag: boolean = false;

	public StockKeepingUnitExtendedTexts: Array<MiscExtendedText>;

	public get LastSplitEcommerceNoUnique(): string {
		const split = this.EcommerceNoUnique.split('-');
		return split[split.length - 1];
	}

	public get FirstSplitEcommerceNoUnique(): string {
		const split = this.EcommerceNoUnique.split('-');
		return split[0];
	}

	static createCopy(fromModel: MiscModel) {
		if (fromModel) {
			const toModel = new MiscModel();
			toModel.EcommerceNoUnique = fromModel.EcommerceNoUnique;
			toModel.EcommerceNo = fromModel.EcommerceNo;
			toModel.EcommerceVariantCode = fromModel.EcommerceVariantCode;
			toModel.Description = fromModel.Description;
			toModel.ShelfNo = fromModel.ShelfNo;
			toModel.ItemCategoryDescription = fromModel.ItemCategoryDescription;
			toModel.ItemGroupDescription = fromModel.ItemGroupDescription;
			toModel.BinCode = fromModel.BinCode;
			toModel.MaximumOrderQty = fromModel.MaximumOrderQty;
			toModel.NeedApprovalOnWebOrder = fromModel.NeedApprovalOnWebOrder;
			toModel.LocationCode = fromModel.LocationCode;
			toModel.WebResourceCodes = fromModel.WebResourceCodes;
			toModel.ItemExistsInBag = fromModel.ItemExistsInBag;
			toModel.StockKeepingUnitExtendedTexts = fromModel.StockKeepingUnitExtendedTexts?.map(x => MiscExtendedText.createCopy(x as MiscExtendedText)) ?? [];
			return toModel;
		}
		else {
			return null;
		}
	}

}

export class MiscExtendedText {

	public RecordId: string;
	public LineNo: string;
	public TextCode: string;
	public Text: string;
	public TextStyle: string;
	public TextHEXCode: string;
	public ModId: string;

	static createCopy(fromModel: MiscExtendedText) {
		if (fromModel) {
			const toModel = new MiscExtendedText();
			toModel.RecordId = fromModel.RecordId;
			toModel.LineNo = fromModel.LineNo;
			toModel.TextCode = fromModel.TextCode;
			toModel.Text = fromModel.Text;
			toModel.TextStyle = fromModel.TextStyle;
			toModel.TextHEXCode = fromModel.TextHEXCode;
			toModel.ModId = fromModel.ModId;
			return toModel;
		}
		else {
			return null;
		}
	}

}
