<div class="modal-body" [@fadeInOutTransition]>
	<div *ngIf=show class="flex flex-column">

		<h1>{{ 'TAGFILTER.FILTERS' | translate }}</h1>

		<button class="close" (click)="cancel()">
			<i class="fa-light fa-xmark"></i>
		</button>

		<div class="filter-groups">
			<div class="filter-group" *ngFor="let type of data">
				<h6 class="filter-group-header">{{type.type.Name | dataTranslate | translate}}</h6>
				<div class="filter-subgroup" *ngFor="let group of type.groups">
					<div clas="row">
						<div class="row tag-row">
							<div class="col-xs-4 tag" *ngFor="let tag of group.tags">
								<button [ngClass]="{disabled: isDisabled(tag)}" (click)="addTag(tag)" class="filter-button">{{tag.Description | dataTranslate | translate}}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="slider">
			<div class="col-xs-12 col-sm-12 col-md-8 col-lg-4">
				<div class="row">
					<mat-slider #slider class="alternate-theme" [max]="maximum" [min]="minimum" [step]="1" [discrete]="false" [showTickMarks]="true" disableRipple="true">
						<input #sliderStart matSliderStartThumb [(value)]="selectedMinimum" thumbLabel (valueChange)="onHboxChange()">
						<input #sliderEnd matSliderEndThumb [(value)]="selectedMaximum" thumbLabel (valueChange)="onHboxChange()">
					</mat-slider>

					<div class="value-display">
						<div>min: {{sliderStart.value}}</div>
						<div>max: {{sliderEnd.value}}</div>
					</div>

					<div class=" text-center">
						<cstmsr-slider></cstmsr-slider>
						<small class="lenssize-header">{{'TAGFILTER.LENSSIZE' | translate}}</small>
					</div>
				</div>
			</div>
		</div>

		<div class="buttons row">
			<div class="col-xs-12">
				<button class="btn btn-primary pull-right" [disabled]="selected.length === 0 && selectedMinimum === minimum && selectedMaximum === maximum" (click)="goToTagFilterPage()">{{'TAGFILTER.APPLYFILTERS' | translate}}</button>
				<button class="btn btn-secondary pull-right" (click)="cancel()">{{'COMMON.CANCEL' | translate}}</button>
			</div>
		</div>

	</div>
</div>