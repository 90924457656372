import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { IObservableStore } from './interfaces/observable-store.interface';

@Injectable()
export class SessionService implements IObservableStore {

	constructor(
		private session: SessionStorageService
	) { }

	public get<T>(key: string): T {
		const obj = this.session.retrieve(key);

		if (!obj) {
			return null;
		}

		if (obj.ttl != null) {
			const objDate = new Date(obj.ttl as Date).getTime();
			const date = new Date().getTime();

			if ((date - objDate) > 0) {
				this.clear(key);
				return null;
			}
		}

		let result = obj.data as T;

		return result;
	}

	public set(key: string, data: any, ttl?: Date) {
		this.session.store(key, { ttl, data });
	}

	public clear(key?: string) {
		this.session.clear(key);
	}

	public onChange<T>(key: string): Observable<T> {
		return this.session.observe(key);
	}

}
