<div *ngIf="isLoggedIn | async" class="top-menu-container">
	<div class="xyz">
		<div class="navigation-elements">
			<div class="navigation-buttons">
				<a class="navigation-button" href="https://partner.lindberg.com">
					<div>
						<img src="/assets/icons/dashboard.svg">
					</div>
					<div>
						{{'HOME.BUTTONS.DASHBOARD' | translate }}
					</div>
				</a>
				<div class="navigation-button" [routerLink]="['/']" (click)="closeModals()">
					<div>
						<img src="/assets/icons/front.svg">
					</div>
					<div>
						{{'HOME.BUTTONS.CUSTOMISER' | translate }}
					</div>
				</div>
			</div>
			<div class="breadcrumb-trail">
				<breadcrumb></breadcrumb>
			</div>
		</div>
		<div class="functional-elements divider-mobile">
			<div class='client-partner-container'>
				<div class="client-display">
					<div class="default-cd">
						<display-enduser-button [useShorthand]="false"></display-enduser-button>
					</div>
					<div class="shorthand-cd">
						<display-enduser-button [useShorthand]="true"></display-enduser-button>
					</div>
				</div>
				<div class="select-partner">
					<button class="select-partner-button" (click)="openNewPartnerModal()">
						{{ (userCanOrderOnBehalf ? 'HOME.NEWORDERFORPARTNER' : 'HOME.NEWORDERFORSHOP') | translate }}
					</button>
				</div>
			</div>
			<div class="navigation-icons">
				<div class="xnavigation-icon" (click)="bagClicked()" [routerLink]="['/bag']">
					<div [@pulse]="animateIcon('bag')" class="notifications">
						<icon-bag [syncState]="syncState"></icon-bag>
						<span *ngIf="bagCount" class="badge bag" [@newOrderTransition]="bagCount"><span>{{bagCountSpecified}}</span></span>
					</div>
				</div>
				<!-- <div class="xnavigation-icon" (click)="openNotificationDropdown()">
					<div [@pulse]="animateIcon('notifications')" class="notifications">
						<i class="super-class fa-light fa-bell fa-xl"></i>
						<span class="badge" *ngIf="(appState.newNotificationsCount | async) > 0"><span>{{appState.newNotificationsCount | async}}</span></span>
					</div>
				</div> -->
				<div *ngIf="brandModeService.isTitanium" class="xnavigation-icon" (click)="openTagFilter()">
					<div [@pulse]="animateIcon('filter')">
						<icon-tag-filter></icon-tag-filter>
					</div>
				</div>
				<div class="xnavigation-icon" (click)="startSearch()">
					<div [@pulse]="animateIcon('search')">
						<icon-search [ngClass]="{'disabled': showSearch | async}"></icon-search>
					</div>
				</div>
				<div class="xnavigation-icon" (click)="openMenu()">
					<div [@pulse]="animateIcon('options')">
						<i class="fa-light fa-ellipsis-vertical fa-xl"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="logo-search">
		<div *ngIf="(showSearch | async) === false">
			<img [@fadeInOutTransition] class="logo" src="/assets/images/logo.svg" (click)="goHome()">
		</div>

		<div *ngIf="(showSearch | async) === true">
			<search-input [@fadeInOutTransition] class="search-field placeholder"
				[placeholder]="'HOME.SEARCHMODEL' | translate"
				[externalSearchTerm]="searchedOn"
				[searchInFocusTrigger]="searchFocusTrigger"
				[searchFocused]="searchFocused"
				(focusLostChange)="searchLostFocus()"
				(searchChange)="searchTerm.next($event)">
			</search-input>
		</div>
	</div>

	<div *ngIf="breadcrumbsNumber === 2" class="partner-plus-kering">
		<button (click)='goToKering()'>{{'HOME.EXPLORE_KERING_SMALL' | translate}}</button>
		<button (click)="openNewPartnerModal()">
			{{ (userCanOrderOnBehalf ? 'HOME.NEWORDERFORPARTNER' : 'HOME.NEWORDERFORSHOP') | translate }}
		</button>
	</div>

	<div *ngIf="breadcrumbsNumber > 2" class="breadcrumb-collection">
		<breadcrumb></breadcrumb>
	</div>
</div>

<!-- <notifications-dropdown *ngIf="isLoggedIn | async"></notifications-dropdown> -->

<options-dropdown *ngIf="isLoggedIn | async"></options-dropdown>

<on-behalf-of-bar *ngIf="showOrderOnBehalf && orderOnBehalfService.customerAsObservable() | async"></on-behalf-of-bar>

<div *ngIf="showFilterBar" class="filter-bar">
	<app-model-filter ></app-model-filter>
</div>

<div *ngIf="(isLoggedIn | async) === false" class="notlogged">
	<img [@fadeInOutTransition] src="/assets/images/logo.svg">
</div>