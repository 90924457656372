import { SimpleModelModel } from "@models/api-models/simple-model-model";
import { IValueTag } from "../value-tag.interface";
import { AbstractTagHandler } from "./tag-handler.interface";
import { Tag } from "../Tag";
export class AlphabeticalTagHandler extends AbstractTagHandler {
    private modelsToLoopThrough: Array<SimpleModelModel>;

    public getTags(modelsToLoopThrough: Array<SimpleModelModel>): Array<IValueTag<Array<SimpleModelModel>>> {
        this.modelsToLoopThrough = modelsToLoopThrough.filter(x => !x.IsKid && isNaN(parseInt(x.Code, 10)));
        const parentTags = super.getTags(modelsToLoopThrough) || new Array();
        return [...this.generateTags(), ...parentTags];
    }

    public generateTags(): Array<IValueTag<Array<SimpleModelModel>>> {
        const result = new Array<Tag<Array<SimpleModelModel>>>();

        this.modelsToLoopThrough.forEach(x => {
            let m = result.find(t => t.id === x.Code[0]);

            if (!m) {
                m = new Tag<Array<SimpleModelModel>>(x.Code[0], x.Code[0]);
                m.setData(new Array());
                result.push(m);
            }

            m.setData([...m.getData(), x]);
        });

        return result.sortBy(x => x.name);
    }
}
