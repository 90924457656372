import { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { VersionType } from '@infrastructure/versionType.enum';
import { IndexDbService } from '@services/index-db.service';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app.constants';

@Component({
    selector: 'dcg-image',
    styleUrls: ['./_dcg-image.scss'],
    template: `
        <div *ngIf="fade">
            <img [alt]="alt" onload="this.style.opacity=1" class="fade-in dcg-image" crossorigin="anonymous" [src]="realUrl | safeHtml" />
        </div>

        <div *ngIf="!fade">
            <img [alt]="alt" crossorigin="anonymous" [src]="realUrl | safeHtml" />
        </div>
    `
})

export class DCGImageComponent implements OnInit, OnDestroy {

    private subscriptions: Array<Subscription> = [];
    @Input() src: string;
    @Input() alt?: string;
    @Input() fade?: boolean = true;

    public realUrl: string;

    constructor(private indexDbService: IndexDbService) {
    }

    ngOnInit() {
        this.realUrl = this.initUrl();
    }

    private initUrl(): string {
        return this.src ? this.src + ((this.src as string).indexOf("?") != -1 ? "&" : "?") + "v=" + this.getVersion() : null;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    private getVersion() {
        const currentVersion = this.indexDbService.getLocalVersion(VersionType.Frames);
        return ((currentVersion ? currentVersion.Id : null) || AppConstants.version);
    }

}
