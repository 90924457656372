import { EventEmitter, Injectable } from '@angular/core';
import {  Observable } from 'rxjs';

@Injectable()
export class OptionsDropdownService {
    private optionsDropdownButtonClicked: EventEmitter<void> = new EventEmitter();

    constructor() { }

    public optionsDropdownButtonClickedAsObserveable(): Observable<void> {
        return this.optionsDropdownButtonClicked.asObservable();
    }

    public toggleDropdownState(): void {
        this.optionsDropdownButtonClicked.emit();
    }
}
