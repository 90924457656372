<div *ngIf="isReady">
    <div class="text-center">
        <b>{{(orderOnBehalfService.customerAsObservable() | async).Name}}</b>
        <p>{{ 'STARTKITS.DOESNOTCARRYPRODUCTLINE' | translate }}</p>
    </div>
    <div class="col-xs-8 col-xs-offset-2">
        <p class="text-center">{{ 'STARTKITS.WOULDYOULIKESTARTKIT' | translate }}</p>
        <div class="flex flex-column flex-center">
            <div class="flex flex-column buttons">
                <button *ngFor="let startKit of startkits" class="btn btn-primary" (click)="addStartkit(startKit)">{{ startKit.Description }}</button>
                <button class="btn btn-secondary" (click)="addStartkit(undefined)">{{ 'COMMON.CANCEL' | translate }}</button>
            </div>
        </div>
    </div>
</div>