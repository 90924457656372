import { Component, Input } from '@angular/core';
@Component({
    selector: 'tag',
    styleUrls: ['./tag.component.scss'],
    template: `
        <button class="btn btn-secondary">{{code}}<span *ngIf="removeable" class="remove">x</span></button>
    `
})
export class TagComponent {
    @Input() public code: string;
    @Input() public removeable: boolean = true;
}
