import { Component, HostBinding, Input } from "@angular/core";
import { FadeInOutTransition } from "../../shared/transitions/fade-in-out.transition";

@Component({
    selector: "model-information",
    templateUrl: 'model-infomation-component.html',
    styleUrls: ['./model-infomation-component.scss'],
    animations: [
        FadeInOutTransition(0.8)
    ]
})
export class ModelInformationComponent {
    @Input() ModelCodeNotAvailable: string;
    @Input() DemoModelInfo: boolean;
    @Input() EnduserModelInfo: boolean;
    @HostBinding('@fadeInOutTransition') fadeTransition = "";
}
