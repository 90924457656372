<div class="flex">
	<input #searchfield
		type="search"
		class="form-control input-sm"
		[placeholder]="placeholder"
		[ngModel]="internalSearch"
		(ngModelChange)="setSearch($event)"
		(click)="onSearchTriggered(true)"
		(keydown)="catchEnter($event)"
	/>
</div>