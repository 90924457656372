import { Component, OnDestroy, OnInit } from '@angular/core';
import { BasePage } from '@infrastructure/base-page';
import { CameraEnum } from '@infrastructure/camera.enum';
import { SourceTypeEnum } from '@infrastructure/sourcetype.enum';
import { ComponentVariantModel } from '@models/component-variant';
import { InspirationGroupModel } from '@models/inspiration-group';
import { ManualConfig } from '@models/manualconfig';
import { ManualConfigVariants } from '@models/manualconfigvariant';
import { Paging } from '@models/paging.model';
import { UserDataModel } from '@models/user-data-model';
import { VariantColorModel } from '@models/variant-color';
import { VariantSizeModel } from '@models/variant-size';
import { EnduserDisplayService } from '@services/enduserdisplay.service';
import { EventsService } from '@services/events.service';
import { ImageService } from '@services/image.service';
import { LanguageService } from '@services/language.service';
import { LoginService } from '@services/login.service';
import { ManualConfigService } from '@services/manual-config.service';
import { ModalService } from '@services/modal.service';
import { NavigateService } from '@services/navigate.service';
import { TitleService } from '@services/title.service';
import { UserService } from '@services/user.service';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { CustomizerSummaryModelFactory } from 'src/_factories/CustomizerSummaryModelFactory';

@Component({
	selector: 'app-featured-expired-page-component',
	templateUrl: './featured-expired-page-component.component.html',
	styleUrls: ['./featured-expired-page-component.component.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class FeaturedExpiredPageComponentComponent extends BasePage implements OnInit, OnDestroy {

	public hasLoadedModels: boolean = false;
	public skeletonScreens: number;
	public showPageInitializingSkeleton: boolean = false;
	public expiredManualConfigs: Array<ManualConfig> = [];
	public paging: Paging = new Paging(24, 1);
	public userData: UserDataModel;
	public inspirationGroup: InspirationGroupModel;
	public isDisplay: boolean;

	constructor(
		navigateService: NavigateService,
		loginService: LoginService,
		titleService: TitleService,
		modalService: ModalService,
		private languageService: LanguageService,
		private manualConfigService: ManualConfigService,
		private userService: UserService,
		private events: EventsService,
		private enduserDisplayService: EnduserDisplayService,
		private imageService: ImageService,
	) {
		super(loginService, titleService, modalService, navigateService);
	}

	async ngOnInit(): Promise<void> {
		const inspirationGroupInput = this.navigateService.getInspirationGroupData();
		this.initLoaders(inspirationGroupInput);

		const allInspirationGroups = await this.manualConfigService.getInspirationGroups();
		this.inspirationGroup = allInspirationGroups.find(e => e.Name == inspirationGroupInput.Name);

		this.userData = await this.userService.GetUser();

		this.titleService.setTitle(this.languageService.instant(this.inspirationGroup.Name));

		this.isDisplay = this.enduserDisplayService.IsDisplay();

		await this.getExpiredManualConfigurations();

		this.isReady = true;
	}

	async getExpiredManualConfigurations(page: number = 1) {
		this.setLoading(true);
		this.paging.page = page;
		this.expiredManualConfigs = await this.manualConfigService.getExpiredManualConfigurations(this.inspirationGroup.Id, this.paging);

		if (this.expiredManualConfigs.length > 0 && this.expiredManualConfigs[0].HitCount > 0) {
			this.paging.update(this.expiredManualConfigs[0].HitCount);
		}

		this.expiredManualConfigs.forEach(expiredManualConfig => {
			this.setImageUrls(expiredManualConfig)

			let variants: Array<ComponentVariantModel> = expiredManualConfig.ManualConfigVariants.map(t => this.ConvertToComponentVariantModel(t));
			variants = variants.filter(v => v != null);
			expiredManualConfig.SummaryModels = this.buildSummaryModels(variants);
		});

		this.setLoading(false);
	}

	async gotoPage(page: number) {
		await this.getExpiredManualConfigurations(page);
	}

	private setImageUrls(manualConfig: ManualConfig) {
		if (manualConfig.ModelGroup.IsRimless) {
			manualConfig.imageUrl = this.imageService.GetPersistedImageUrl(manualConfig.Uuid, SourceTypeEnum.MANUALCONFIGS, CameraEnum.FRONT);
			manualConfig.imageUrlRimlessSide = this.imageService.GetPersistedImageUrl(manualConfig.Uuid, SourceTypeEnum.MANUALCONFIGS, CameraEnum.SIDE);
		}
		else {
			manualConfig.imageUrl = this.imageService.GetPersistedImageUrl(manualConfig.Uuid, SourceTypeEnum.MANUALCONFIGS, CameraEnum.PERSPECTIVE);
		}
	}

	public ConvertToComponentVariantModel(manuConfigVariants: ManualConfigVariants): ComponentVariantModel {
		if (!manuConfigVariants.ComponentVariantModel) {
			return null;
		}

		const componentVariant = Object.assign(new ComponentVariantModel(manuConfigVariants.ComponentVariantModel.Discontinued, manuConfigVariants.ComponentVariantModel.New), manuConfigVariants.ComponentVariantModel);

		if (manuConfigVariants.ComponentSizeModel) {
			const componentSize = Object.assign(new VariantSizeModel(manuConfigVariants.ComponentSizeModel.Discontinued, manuConfigVariants.ComponentSizeModel.New), manuConfigVariants.ComponentSizeModel);
			componentVariant.VariantSize = componentSize;
		}

		if (manuConfigVariants.ComponentColorModel) {
			const componentColor = Object.assign(new VariantColorModel(manuConfigVariants.ComponentColorModel?.Discontinued, manuConfigVariants.ComponentColorModel?.New), manuConfigVariants.ComponentColorModel);
			componentVariant.VariantColor = componentColor;
		}

		return componentVariant;
	}

	private setLoading(isLoading: boolean) {
		this.hasLoadedModels = !isLoading;

		if (!this.showPageInitializingSkeleton) {
			this.events.showLoader(isLoading)
		}
	}

	private initLoaders(inspirationGroup: InspirationGroupModel) {
		this.setupSkeletonScreens(inspirationGroup.ImageId);
	}

	public buildSummaryModels(variants: Array<ComponentVariantModel>) {
		return CustomizerSummaryModelFactory.buildSummaryModels(variants);
	}

	private setupSkeletonScreens(imageId: string) {
		if (imageId === "consultant collection" || imageId === "favorites") {
			this.skeletonScreens = 12;
			this.showPageInitializingSkeleton = true;
		}
		else if (imageId === "screenshows" || imageId === "campaign") {
			this.skeletonScreens = 8;
		}
		else if (imageId === "inspiration") {
			this.skeletonScreens = 12;
		}
	}

}
