import { Type } from "class-transformer";

export class FairModel {

    public FairId: number;
    public FairName: string;
    @Type(() => Date)
    public Days: Array<Date>;
    public ConsultantAttendanceDays: Array<Date>;
}
