import { pulseAnimation } from 'angular-animations';
import { BehaviorSubject, debounceTime, filter, Observable, Subject, Subscription } from 'rxjs';

import { Component, ComponentRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BrandEnum } from '@infrastructure/brand.enum';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { ModelSearchModalComponent } from '@modals/model-search/model-search.modal';
import { TagFilterModalComponent } from '@modals/tag-filter/tag-filter.component';
import { BagUpdateModel } from '@models/bag-update-model';
import { BagCountService } from '@services/bag-count.service';
import { BrandModeService } from '@services/brandmode.service';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ExternalSearchService } from '@services/external-search.service';
import { LoginService } from '@services/login.service';
import { ModalService } from '@services/modal.service';
import { NavigateService } from '@services/navigate.service';
import { OptionsDropdownService } from '@services/options-dropdown.service';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { OrderSyncService } from '@services/ordersync.service';
import { QueueItemService } from '@services/queue-item.service';
import { TagsService } from '@services/tags.service';
import { UserService } from '@services/user.service';
import { AppState } from '@shared/app-state';
import { PermissionConstants } from '@shared/permission-constants';
import { BadgePopTransition } from '@shared/transitions/badge-pop.transition';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';

@Component({
	selector: 'menu-header',
	templateUrl: 'header.html',
	styleUrls: ["./header.scss"],
	animations: [
		FadeInOutTransition(),
		BadgePopTransition(),
		pulseAnimation({ duration: 300, scale: 0.85 })
	],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		"[@fadeInOutTransition]": ''
	}
})
export class HeaderComponent implements OnInit, OnDestroy {

	@Output() search = new EventEmitter();

	public showSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public searchedOn: string = null;
	public searchTerm: Subject<string> = new Subject<string>();
	public searchTermSubjectForModal: BehaviorSubject<string> = new BehaviorSubject<string>("");
	public searchFocused: Subject<boolean> = new Subject();
	public searchFocusTrigger = false;
	public searchIconClickCount: number = 0;

	public userCanOrderOnBehalf: boolean = false;
	public showOrderOnBehalf: boolean = true;

	public isLoggedIn: Observable<boolean>;

	private subscriptions: Array<Subscription> = [];
	private bagSubscriptions = new Array<Subscription>();

	activeIcon: string;

	syncState: SyncStateEnum;
	isSyncing: boolean;

	bagCount: number;
	bagCountSpecified: string;

	private modal: Observable<ComponentRef<{ content: BehaviorSubject<string> }>>;
	public breadcrumbsNumber: number;


	refreshing: boolean;
	showDisplayEnduser: boolean;
	showFilterBar: boolean;

	constructor(
		public appState: AppState,
		public brandModeService: BrandModeService,
		public orderOnBehalfService: OrderOnBehalfService,
		private bagCountService: BagCountService,
		private loginService: LoginService,
		private modalService: ModalService,
		private navigateService: NavigateService,
		private optionsDropDownService: OptionsDropdownService,
		private orderSyncService: OrderSyncService,
		private router: Router,
		private queueItemService: QueueItemService,
		private userService: UserService,
		private activatedRoute: ActivatedRoute,
		private breadcrumbService: BreadcrumbService,
		private tagsService: TagsService,
		private externalSearchService: ExternalSearchService
	) {
		this.isLoggedIn = this.loginService.IsLoggedInObservable();
		const root: ActivatedRoute = this.activatedRoute.root;

		this.breadcrumbsNumber = this.breadcrumbService.getBreadcrumbs(root).length;

		this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
			const root: ActivatedRoute = this.activatedRoute.root;
			this.breadcrumbsNumber = this.breadcrumbService.getBreadcrumbs(root).length;
		}));

		this.subscriptions.push(this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(event => {
			this.showFilterBar = event.url === "/filterlist";
		}));

		this.tagsService.shouldShowObservable().subscribe(value => this.showFilterBar = value);
	}

	async ngOnInit() {
		this.loginService.IsLoggedInObservable().subscribe(async l => {
			const user = await this.userService.GetUser();
			if (user) {
				this.userCanOrderOnBehalf = user.UserPermissions.some(x => x === PermissionConstants.CanOrderOnBehalf);
			}
		});

		const user = await this.userService.GetUser();

		if (user) {
			this.userCanOrderOnBehalf = user.UserPermissions.some(x => x === PermissionConstants.CanOrderOnBehalf);
		}

		this.bagSubscriptions.push(this.queueItemService.orderItemPutInStore.asObservable().pipe(debounceTime(2000))
			.subscribe(async () => {
				await this.setSyncState();
			}));

		this.bagSubscriptions.push(this.bagCountService.bagCountUpdatedSubject.asObservable().subscribe(bagUpdatemodel => {
			this.setBagCount(bagUpdatemodel);
		}));

		this.bagSubscriptions.push(this.bagCountService.bagCountUpdatedSubject.asObservable().pipe(debounceTime(2000)).subscribe(async () => {
			await this.setSyncState();
		}));

		this.bagSubscriptions.push(this.orderSyncService.QueuedOrderItemStateChangedObservable().pipe(debounceTime(2000))
			.subscribe(async () => {
				await this.setSyncState();
			}));

		this.bagSubscriptions.push(this.orderOnBehalfService.customerAsObservable().subscribe(async customer => {
			await this.setSyncState();
		}));

		await this.setSyncState();

		this.subscriptions.push(this.searchTerm.pipe(debounceTime(800)).subscribe(this.onNewSearch));
		this.subscriptions.push(this.externalSearchService.inboundSearchTermObserveable().subscribe(this.onNewSearch));

	}

	private setBagCount(bagUpdate: BagUpdateModel) {
		const framesCount = bagUpdate.FullFrames;
		const miscCount = bagUpdate.AccessoriesAndTools;
		const plusStr = framesCount > 0 && miscCount > 0 ? "+" : "";

		const framesCountStr = framesCount > 0 ? "" + framesCount : "";
		const miscCountStr = miscCount > 0 ? "" + miscCount : "";

		this.bagCountSpecified = (framesCountStr + plusStr + miscCountStr);
		this.bagCount = framesCount + miscCount;
	}

	private async setSyncState() {
		const customerNo = await this.orderOnBehalfService.getCustomerOrUserNo();

		this.syncState = await this.queueItemService.getSyncStateOnCustomer(customerNo);;
		this.isSyncing = this.syncState != SyncStateEnum.SyncedWithDatabase;
	}

	ngOnDestroy() {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	openNewPartnerModal() {
		this.orderOnBehalfService.triggerSetCustomerModal();
	}

	openTagFilter() {
		setTimeout(() => {
			this.modalService.create(TagFilterModalComponent, {});
			this.showFilterBar = true;
		}, 300);

		this.handleAnim('filter');
	}

	// openNotificationDropdown() {
	// 	this.notificationService.toggleNotificationDropdown();

	// 	this.handleAnim('notifications');
	// }

	openMenu() {
		this.optionsDropDownService.toggleDropdownState();

		this.handleAnim('options');
	}

	bagClicked() {
		if (this.brandModeService.currentBrand.getValue() !== BrandEnum.Titanium) {
			this.brandModeService.toggleBrandMode(BrandEnum.Titanium);
		}

		this.modalService.closeAll();

		this.handleAnim('bag');
	}

	startSearch() {
		// if search is already showing, hide it again
		if (this.showSearch.getValue()) {
			this.showSearch.next(false);
		}
		else {
			this.searchFocusTrigger = true;
			this.searchIconClickCount++;
			this.showSearch.next(true);
			this.searchFocused.next(true);

			this.handleAnim('search');
		}
	}

	searchLostFocus() {
		if (!this.searchedOn || this.searchedOn == "") {
			this.resetSearch();
		}
	}

	resetSearch() {
		this.searchedOn = "";
		this.showSearch.next(false);
	}

	private onNewSearch = async (x: string) => {
		// const oldValue = this.searchedOn;
		this.searchedOn = x;

		// if (oldValue != this.searchedOn) {
		this.searchTermSubjectForModal.next(x);

		if (this.searchedOn != "") {
			console.debug("Opening model search modal");
			this.showSearchModal();
		}
		// }
	}

	private showSearchModal() {
		this.appState.showPreciousTheme.next(false);

		this.modal = this.modalService.create(ModelSearchModalComponent, { content: this.searchTermSubjectForModal });

		this.showDisplayEnduser = true;

		this.subscriptions.push(this.modal.subscribe((ref) => {
			if (ref.hostView.destroyed) {
				this.resetSearch();
				this.modal = null;
				this.showDisplayEnduser = this.shouldShowDisplayEnduser();
				this.appState.showPreciousTheme.next(this.brandModeService.isPrecious);
			}
		}));
	}

	shouldShowDisplayEnduser(): boolean {
		return this.router.url != "/bag";
	}

	handleAnim(icon: string) {
		setTimeout(() => {
			this.activeIcon = icon;
		}, 1);

		setTimeout(() => {
			this.activeIcon = '';
		}, 1);
	}

	animateIcon(icon: string) {
		let result = icon === this.activeIcon;

		return result;
	}

	async goToKering() {
		await this.navigateService.navigateToKeringEyewareBrands();
	}

	async goHome() {
		if (!this.refreshing) {
			this.refreshing = true;

			if (this.brandModeService.currentBrand.getValue() !== BrandEnum.Titanium) {
				this.brandModeService.toggleBrandMode(BrandEnum.Titanium);
			}

			const userRefreshData = await this.userService.getUserDataFromLindberg();

			// 	const appVersionModel = await this.env.NewerVersionExist();

			// 	if (appVersionModel) {
			// 		await this.env.UpdateVersion();
			// 		const user = this.userService.currentUser$.value;
			// 		let body = this.languageService.instant("NOTIFICATIONS.HEADER.NEWER.VERSIONINFO", {
			// 			'currentVersion': appVersionModel.version,
			// 			'newestVersion': appVersionModel.freshVersion
			// 		});

			// 		const customiserNotification = new CustomiserNotification(
			// 			Guid.create().toString(),
			// 			user.Uuid,
			// 			this.languageService.instant("NOTIFICATIONS.HEADER.NEWER.VERSION"),
			// 			body,
			// 			NotificationState.New,
			// 			NotificationType.SimpleMessage,
			// 			true,
			// 			true
			// 		);

			// 		const ticks = new Date().getTime().toString();
			// 		customiserNotification.Link = new Link('/?v=' + ticks, this.languageService.instant("NOTIFICATIONS.HEADER.NEWER.REFRESHPAGE"));

			// 		console.warn('customiserNotification', customiserNotification);

			// 		await this.notificationService.saveNotification(customiserNotification);
			// 	}

			// 	this.appState.priceModuleEnabled.next(userRefreshData.User.priceModuleEnabled && userRefreshData.User.PriceSettingAllowed !== PriceSettingEnum.AllowNone)

			this.loginService.createLoginCookie(userRefreshData.token);

			// 	if (userRefreshData.User.Active) {
			// 		this.userService.setUser(userRefreshData.User);
			// 	}
			// 	else { // FileMaker says user is no longer active => Log user out
			// 		this.loginService.Logout(true);
			// 	}

			this.closeModals();

			await this.navigateService.navigateToHome();

			this.refreshing = false;
		}
	}

	closeModals() {
		this.modal = null;
		this.modalService.closeAll();
		this.resetSearch();
	}

}
