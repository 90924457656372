import { Component} from "@angular/core";

@Component({
    selector: "item-template",
    templateUrl: "item-template.component.html",
    styleUrls: ['./item-template.component.scss']
})
export class ItemTemplateComponent {
    constructor() { }
}
