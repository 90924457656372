import { BrandEnum } from "@infrastructure/brand.enum";
import { Type } from "class-transformer";
import { nameof } from 'ts-simple-nameof';
import { isLatinCharacters } from "../infrastructure/latin-character-test";
import ValidationError from "../infrastructure/validation-error";
import ValidationResult from "../infrastructure/validation-result";
import { AllAssortmentsModel } from './all-assortments-model.interface';
import { AssortmentLineModel } from './assortment-line-model';
import { AssortmentModel } from './assortment-model';
import { AvailabilityModel } from "./availability-model";
import { ComponentModel } from './component';
import { DataTranslationModel } from './data-translation-model';
import { OrderLine } from "./order-line";
import { VariantColorModel } from "./variant-color";
import { VariantSizeModel } from './variant-size';

export class SimpleComponentVariantModel extends AvailabilityModel implements AllAssortmentsModel {
    Id: number;
    Uuid: string;
    Code: string;
    Bypass: boolean;

    @Type(() => DataTranslationModel)
    Description: DataTranslationModel;

    @Type(() => DataTranslationModel)
    Description2: DataTranslationModel;
    Status: number;
    EngravingPossible: number;
    Order: string;
    SplittingAllowed: boolean;
    AvailableForDisplay: boolean;

    @Type(() => Date)
    Release: Date;

    @Type(() => AssortmentLineModel)
    AssortmentLines: Array<AssortmentLineModel>;

    get AllAssortments(): Array<AssortmentModel> {
        return this.AssortmentLines.map(x => x.Assortment).distinct((x, y) => x.Code == y.Code);
    }
}

export class ComponentVariantModel extends SimpleComponentVariantModel {
    @Type(() => ComponentModel)
    Component: ComponentModel;
    Default: boolean;
    Engraving: string = "";
    SplittingData: { left: string, right: string };
    Optional: boolean;
    VariantViewed: boolean = false;
    VariantSizeViewed: boolean = false;
    VariantColorViewed: boolean = false;

    @Type(() => VariantSizeModel)
    VariantSize: VariantSizeModel;

    @Type(() => VariantColorModel)
    VariantColor: VariantColorModel;

    OmitDiscontinuedLabel: boolean;
    NotSelected: boolean;
    OrderLineUuid: string;
    LowTempleInclination: boolean;
    Brand: BrandEnum;

    @Type(() => DataTranslationModel)
    Group: DataTranslationModel;

    @Type(() => DataTranslationModel)
    GroupDescription: DataTranslationModel;
    GroupOrder: string;

    public static IsEqualVariants(variants: Array<ComponentVariantModel>, otherVariants: Array<ComponentVariantModel>): boolean {
        let isEqual = true;
        if (variants.length != otherVariants.length) {
            return false;
        } else {
            variants.forEach(t => {
                const match = otherVariants.find(k => k.Uuid == t.Uuid);

                if (!match) {
                    isEqual = false;
                } else if (match.VariantSize && !t.VariantSize || !match.VariantSize && t.VariantSize ||
                    match.VariantColor && !t.VariantColor || !match.VariantColor && t.VariantColor) {
                    isEqual = false;
                } else {
                    if (match.VariantColor) {
                        if (match.VariantColor.Color && !t.VariantColor.Color || !match.VariantColor.Color && t.VariantColor.Color) {
                            isEqual = false;
                        } else {
                            if (match.VariantColor.Color) {
                                if (match.VariantColor.Color.Uuid != t.VariantColor.Color.Uuid) {
                                    isEqual = false;
                                }
                            }

                        }
                    }
                    if (match.VariantSize) {
                        if (match.VariantSize.Uuid != t.VariantSize.Uuid) {
                            isEqual = false;
                        }
                    }
                }
            });
        }
        return isEqual;
    }

    public setEngraving(text: string) {
        const errors = this.validateEngraving(text);
        if (!errors.isValid) { throw new Error(""); }

        this.Engraving = text;
    }

    public validateEngraving(text: string): ValidationResult {
        const result = new ValidationResult();
        if (!this.EngravingPossible && text && text.length > 0) {
            result.addError(new ValidationError(nameof<ComponentVariantModel>(x => x.Engraving), "CUSTOMIZER.COMPONENTS.ENGRAVING.NOTPOSSIBLE"));
        }

        if (text && text.length > this.EngravingPossible) {
            result.addError(new ValidationError(nameof<ComponentVariantModel>(x => x.Engraving), "CUSTOMIZER.COMPONENTS.ENGRAVING.DIRECTIONS1"));
        }

        if (!isLatinCharacters(text)) {
            result.addError(new ValidationError(nameof<ComponentVariantModel>(x => x.Engraving), "CUSTOMIZER.COMPONENTS.ENGRAVING.DIRECTIONS2"));
        }

        if (text?.startsWithEmptyString()) {
            result.addError(new ValidationError(nameof<ComponentVariantModel>(x => x.Engraving), "BAG.ENGRAVINGEMPTYSTRING"));
        }

        return result;
    }

    public CreateCopy() {
        return Object.assign(new ComponentVariantModel(this.Discontinued, this.New), this);
    }

    public toString() {
        return `Variant ${this.Code} (size: ${this.VariantSize?.Size ?? 'null'}, color: ${this.VariantColor?.Color.Code ?? 'null'})`;
    }

    public get AllAssortments(): Array<AssortmentModel> {
        const result = new Array<AssortmentModel>();

        this.AssortmentLines?.map(x => x.Assortment).distinct((x, y) => x.Code === y.Code).forEach(x => result.push(x));

        if (this.VariantSize) {
            this.VariantSize.AllAssortments.forEach(x => result.push(x));
        }

        if (this.VariantColor) {
            this.VariantColor.AllAssortments.forEach(x => result.push(x));
        }

        return result.distinct((x, y) => x.Code === y.Code);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static createCopyFromPlain(fromModel: ComponentVariantModel): ComponentVariantModel {
        const newModel = new ComponentVariantModel(fromModel.Discontinued, fromModel.New);

        newModel.Id = fromModel.Id;
        newModel.Uuid = fromModel.Uuid;
        newModel.Code = fromModel.Code;
        newModel.Bypass = fromModel.Bypass;

        newModel.Description = new DataTranslationModel(
            fromModel.Description.TranslationKey,
            fromModel.Description.Fallback);
        if (fromModel.Description2) {
            newModel.Description2 = new DataTranslationModel(
                fromModel.Description2.TranslationKey,
                fromModel.Description2.Fallback);
        }
        newModel.Group = new DataTranslationModel(
            fromModel.Group.TranslationKey,
            fromModel.Group.Fallback);
        newModel.GroupDescription = new DataTranslationModel(
            fromModel.GroupDescription.TranslationKey,
            fromModel.GroupDescription.Fallback);
        newModel.GroupOrder = fromModel.GroupOrder;
        newModel.Status = fromModel.Status;
        newModel.EngravingPossible = fromModel.EngravingPossible;
        newModel.Order = fromModel.Order;
        newModel.SplittingAllowed = fromModel.SplittingAllowed;
        newModel.Release = fromModel.Release ? new Date(fromModel.Release) : null;

        newModel.AssortmentLines = fromModel.AssortmentLines?.map(x => AssortmentLineModel.createCopy(x));

        const componentModel = new ComponentModel(
            fromModel.Component.Id,
            fromModel.Component.Uuid,
            fromModel.Component.Code,
            fromModel.Component.Description,
            fromModel.Component.Status,
            fromModel.Component.Release ? new Date(fromModel.Component.Release) : null,
            fromModel.Component.Discontinued ? new Date(fromModel.Component.Discontinued) : null,
            fromModel.Component.New);

        newModel.Component = componentModel;
        newModel.Default = fromModel.Default;
        newModel.Engraving = fromModel.Engraving;
        newModel.SplittingData = fromModel.SplittingData;
        newModel.Optional = fromModel.Optional;
        newModel.VariantViewed = fromModel.VariantViewed;
        newModel.VariantSizeViewed = fromModel.VariantSizeViewed;
        newModel.VariantColorViewed = fromModel.VariantColorViewed;
        newModel.VariantSize = VariantSizeModel.createCopy(fromModel.VariantSize, fromModel.Component.Code);
        newModel.VariantColor = VariantColorModel.createCopy(fromModel.VariantColor);
        newModel.AvailableForDisplay = fromModel.AvailableForDisplay;
        newModel.OmitDiscontinuedLabel = fromModel.OmitDiscontinuedLabel;
        newModel.NotSelected = fromModel.NotSelected;
        newModel.OrderLineUuid = fromModel.OrderLineUuid;
        newModel.Brand = fromModel.Brand;
        newModel.Group = fromModel.Group;

        return newModel;
    }

    public static createComponentVariantModelFromOrderLine(orderLine: OrderLine, brand: BrandEnum): ComponentVariantModel {
        const newModel = new ComponentVariantModel(null, false);

        const variantSizeModel = new VariantSizeModel(null, false);
        variantSizeModel.Id = orderLine.OriginalComponentSize.Id;

        newModel.VariantColor = orderLine.OriginalComponentColor;
        newModel.VariantSize = variantSizeModel;
        newModel.Id = orderLine.OriginalComponentVariant.Id;
        newModel.Brand = brand;

        const componentModel = new ComponentModel(
            orderLine.OriginalComponent.Id,
            orderLine.OriginalComponent.Uuid,
            orderLine.OriginalComponent.Code,
            orderLine.OriginalComponent.Description,
            orderLine.OriginalComponent.Status,
            orderLine.OriginalComponent.Release ? new Date(orderLine.OriginalComponent.Release) : null,
            orderLine.OriginalComponent.Discontinued ? new Date(orderLine.OriginalComponent.Discontinued) : null,
            orderLine.OriginalComponent.New);
        newModel.Component = componentModel

        return newModel;
    }
}
