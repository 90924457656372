import { ComponentCodeConstants } from '../shared/component-constants';
import { AvailabilityModel } from "./availability-model";
import { ComponentVariantViewmodel } from './component-variant-viewmodel';
import { ConfigurationPlusModel } from './configuration-plus-model';
import { ModelGroupModel } from './model-group';
import { DataTranslationModel } from './data-translation-model';

export class ConfigurationViewModel extends AvailabilityModel {
    public configurationModel: ConfigurationPlusModel;
    public modelConfigurationDescription: string;
    public modelConfigurationDescription2: string;
    public modelConfigurationDescription2Prefix: string;
    public containDetails: boolean;
    public colorDescriptions: Array<DataTranslationModel> = [];
    public variantsToShow: Array<ComponentVariantViewmodel> = [];
    public selected: boolean = false;

    constructor(modelgroup: ModelGroupModel, configurationPlusModel: ConfigurationPlusModel, usePrefix: boolean, useDetails: boolean = true) {
        super(configurationPlusModel.Discontinued, configurationPlusModel.New);

        if (configurationPlusModel.variants && configurationPlusModel.variants.length > 0) {
            configurationPlusModel.variants.forEach(variant => {
                let newVariantToShow: ComponentVariantViewmodel;

                if (variant.Component.Code == ComponentCodeConstants.Upperrim ||
                    variant.Component.Code == ComponentCodeConstants.Lowerrim ||
                    variant.Component.Code == ComponentCodeConstants.Temple) {

                    const colorCode = variant.VariantColor.Color.Code;
                    if (configurationPlusModel.variants.find(vari => vari.Component.Code == ComponentCodeConstants.Upperrim && vari.VariantColor.Color.Code == colorCode) &&
                        configurationPlusModel.variants.find(vari => vari.Component.Code == ComponentCodeConstants.Lowerrim && vari.VariantColor.Color.Code == colorCode) &&
                        configurationPlusModel.variants.find(vari => vari.Component.Code == ComponentCodeConstants.Temple && vari.VariantColor.Color.Code == colorCode)) {
                        newVariantToShow = new ComponentVariantViewmodel(variant, "CUSTOMIZER.COMPONENTS.FRAME.NAME");
                    }
                }

                if (!newVariantToShow) {
                    newVariantToShow = new ComponentVariantViewmodel(variant, "CUSTOMIZER.COMPONENTS." + variant.Component.Code + ".NAME");
                }

                if (!this.variantsToShow.some(x => x.prefixTranslate == newVariantToShow.prefixTranslate)) {
                    this.variantsToShow.push(newVariantToShow);
                }
            });

            this.colorDescriptions = configurationPlusModel.variants.filter(x => x.Component.Code != ComponentCodeConstants.Lenses)
                                                                    .map(x => x.VariantColor.Color.Description2)
                                                                    .filter((value, index, self) => self.indexOf(value) == index);
        }

        this.configurationModel = configurationPlusModel;
        this.Discontinued = configurationPlusModel.Discontinued;

        if (modelgroup.IsConfigurationBased) {
                this.modelConfigurationDescription2 = configurationPlusModel.configurationModel.ModelConfigurationDescription2;
                this.modelConfigurationDescription = configurationPlusModel.configurationModel.ModelConfigurationDescription;
                this.variantsToShow = [];
        } else if (modelgroup.IsBothConfigAndVariantBased) {
            this.modelConfigurationDescription2 = configurationPlusModel.configurationModel.ModelConfigurationDescription2;
            this.modelConfigurationDescription = configurationPlusModel.configurationModel.ModelConfigurationDescription;
            if (usePrefix) {
                this.modelConfigurationDescription2Prefix = "CUSTOMIZER.COMPONENTS.FRAMECOLOR.NAME";
                this.variantsToShow = this.variantsToShow.filter(x => x.componentCode == ComponentCodeConstants.Lenses);
            } else {
                this.variantsToShow = [];
            }

            if (useDetails) {
                this.containDetails = true;
            }
        } else {
            this.colorDescriptions = [];
        }
    }
}
