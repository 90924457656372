import { OrderItemState } from './order-item-state';
import { OrderItemMiscState } from './order-item-misc-model';

export class OrderCompleteModel {

	public Id: number;
	public Uuid: string;
	public TestOrder: boolean;
	public AllOrderItemUuids: Array<string>;
	public AllOrderItemMiscUuids: Array<string>;
	public Notes: string;
	public NotesCustomerService: string;

	public OrderItems: Array<OrderItemState>;

	public OrderItemMiscs: Array<OrderItemMiscState>;
	public FullAddress: string;
	public ShipToName: string;
	public ConfirmationEmails: Array<string>;
	public Caution: string;

	public VisitDate: string;
	public VisitDateWithUserOffset: string;
	public EarliestDeliveryDate: string;
	public EarliestDeliveryDateWithUserOffset: string;
	public ShipToId: number;

	public FairName: string;
	public FairId: number;
	public AllowPartialShipment: boolean;
	public PaymentTerms: string;
	public NoVisit: boolean;
	public IsNewPartner: boolean;

	public signature: string;

}
