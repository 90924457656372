import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Paging } from '@models/paging.model';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})
export class PagerComponent {
  @Input() pagingContext: Paging;
  @Output() paged = new EventEmitter();

  public constructor() {}

  public gotoPage(pageIndex: number) {
    this.pagingContext.page = pageIndex;
    this.paged.emit(pageIndex);
  }
}
