<div class="component">
	<div class="image">
		<img [src]="imageUrl" [alt]="'IMAGEALTS.ORDERITEM' | translate" />
	</div>

	<div class="content" *ngIf="frameRequestAndDataContainer && frameRequestAndDataContainer.frame">

		<div class="header">
			<h5>{{ 'CUSTOMISER.MENUS.PARTS.PARTS_FOR_ID' | translate }} {{frameRequestAndDataContainer.frame.SerialNumber}}</h5>
			<div class="font-medium">{{productDescription}}</div>
			<div class="info">
				{{ 'CUSTOMISER.MENUS.PARTS.GUIDE' | translate }}
			</div>
		</div>

		<div *ngIf="!loading" class="parts-list">
			<ng-container *ngIf="partsAndPrices && partsAndPrices.length > 0">
				<div class='checkboxes' *ngFor="let part of partsAndPrices">
					<checkbox [id]="part.name" [label]="'PARTS.' + part.name | translate" [icon]="imageService.getPartsIcon(part.name)" [checked]="selectedParts.includes(part.name)" (click)="togglePart(part.name)" [disabled]="disableCheckbox(part)" />

					<div *ngIf="showPrices" class="pv">
						<ng-container *ngIf="!pricesLoaded">
							<div>{{ 'CUSTOMISER.MENUS.PARTS.LOADING_PRICES' | translate }}</div>
						</ng-container>
						<ng-container *ngIf="pricesLoaded">
							<app-price-viewer [priceItem]="part.priceItem"></app-price-viewer>
						</ng-container>
					</div>

					<div *ngIf="showEngaving(part)" class="engraving-input">
						<div>engraving</div>
						<input [(ngModel)]="engraving">
					</div>
				</div>

				<ng-container *ngIf="!partsAndPrices || partsAndPrices.length === 0">
					<div class="smaller">{{ 'CUSTOMISER.MENUS.PARTS.NO_PARTS' | translate }}</div>
				</ng-container>

				<button class="btn btn-cta btn-block mt2" [disabled]="selectedParts.length === 0" (click)="addToBag()">
					<ng-container *ngIf="selectedParts.length === 0">
						{{ 'CUSTOMISER.MENUS.PARTS.SELECT_PARTS' | translate }}
					</ng-container>
					<ng-container *ngIf="selectedParts.length === 1">
						{{ 'CUSTOMISER.MENUS.PARTS.ADD_PART_TO_BAG' | translate }}
					</ng-container>
					<ng-container *ngIf="selectedParts.length > 1">
						{{ 'CUSTOMISER.MENUS.PARTS.ADD_PARTS_TO_BAG' | translate }}
					</ng-container>
				</button>

				<div class="info smaller">{{ 'CUSTOMISER.MENUS.PARTS.HEADS_UP' | translate }}</div>

			</ng-container>
		</div>
	</div>
</div>

<ng-container *ngIf="showAddedOverlay">
	<div [@fadeInOutLowOpacity] class="overlay" [innerHTML]="addedOverlay"></div>
</ng-container>