import { VersionType } from "@infrastructure/versionType.enum";

export class VersionModel {
    Id: number;
    Uuid: string;
    VersionDate: Date;
    VersionType: VersionType;
}

export class AppVersionModel {
    version: string;
    freshVersion: string;
}
