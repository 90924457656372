import { Injectable, Injector } from '@angular/core';
import { StorageComponent } from '@app/storage/storage.component';
import { VersionType } from '@infrastructure/versionType.enum';
import { VersionModel } from '@models/version-model';
import { AppState } from '@shared/app-state';
import { ErrorResponseConstants } from '@shared/error-response-constants';
import { ManualConfigGroupConstant } from '@shared/featured-group-constant';
import { IndexeddbError } from '@shared/indexeddb-error';
import Dexie, { IndexableType, Table } from 'dexie';
import { CookieService } from 'ngx-cookie-service';
import { AppConstants } from 'src/app.constants';
import { CustomerDataService } from './customer-data.service';
import { DataApiService } from './data-api.service';
import { EnvService } from './env.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { LocalstorageService } from './localstorage.service';
import { ManualConfigService } from './manual-config.service';
import { OrdersDataService } from './orders-data.service';
import { QueueItemService } from './queue-item.service';
import { StoreService } from './store.service';
import { UserService } from './user.service';


@Injectable({
	providedIn: 'root'
})
export class IndexDbService {
	private db: Dexie;

	private static readonly versionTableNamePrefix = "version";
	private static readonly manualConfigTableName = "manualConfigNewestUuid";
	private baseUrl: () => Promise<string> = async () => await this.env.DataApiurl();
	private stockKeepingUnitsEndpoint: string = 'StockKeepingUnits';
	private casesEndpoint: string = 'cases';
	private startKitsEndpoint: string = 'StartKits';
	private translationsEndpoint: string = 'Translations';

	public databaseIsOpen: boolean = false;
	public versionLookUpCount = 0;

	public ignoreIndexedDb: boolean = true;


	private readonly indexDbVersions =
		{
			version: 7,
			stores: {
				[StoreService.storeName]: "&key",
				[QueueItemService.orderQueueStoreName]: "&key",
				[OrdersDataService.ordersStoreName]: "&key",
				[CustomerDataService.customerStoreName]: "&key",
				[ManualConfigService.manualConfigStoreName]: "&key",
				[StorageComponent.storeName]: "&key",
			}
		};

	constructor(
		private localStorage: LocalstorageService,
		private env: EnvService,
		private injector: Injector,
		private appState: AppState,
		private googleAnalyticsService: GoogleAnalyticsService,
		private userService: UserService,
		private cookieService: CookieService,
	) {
	}

	public async openDatabase() {
		let activeStorageAlert = this.cookieService.get('storageAlert') === 'activeStorageAlert';
		if (activeStorageAlert) {
			return; // Cookie says storage can't be used right now => Therefore don't even try
		}
		try {
			this.db = new Dexie("data");

			this.db.on("blocked", function () {
				console.error("IndexedDb upgrading/deleting was blocked by another window. " +
					"Please close down any other tabs or windows that has this page open");
			});


			this.db.version(this.indexDbVersions.version).stores(this.indexDbVersions.stores);
			let that = this;
			await this.db.open();

			that.databaseIsOpen = true;
			console.debug("IndexedDb was opened");

		} catch (e) {
			console.warn("catch openDatabase error", e);

			const user = await this.userService.GetUser();
			let uuid = '<unknown user>';
			if (user) {
				uuid = user.Uuid;
			}

			if (this.errorIsindexedDbRelated(e)) {
				await this.googleAnalyticsService.eventEmitter('error', 'engagement', `${uuid} experiences ${e?.name}`, 0);
				this.appState.showLinkToStoragePage = true;
				this.appState.storageErrorOccured.next();
			} else {
				this.appState.showLinkToStoragePage = true;
				console.warn("For Help: Menu > Support > click info under 'Quota usage'", "e.name: " + e?.name);
				if (this.errorIsUnsupportedIndexedDb(e)) {
					this.delete();
				}

			}
			this.databaseIsOpen = false;
			//throw e;
		}
		finally {
			/*
			* Refactor time used!
			* We cannot inject dataapiservice because it creates circular dependency. This should be removed and handeled in another way. 
			*/

			if (this.versionLookUpCount === 0) {
				const dataApiService = this.injector.get(DataApiService);

				if (this.cookieService.get('dcg-token')) {
					dataApiService.getVersions().then(versionsFromApi => {
						if (!this.databaseIsOpen) {
							this.initLocalVersionFromBackend(versionsFromApi);
						}
						else {
							this.checkDatabaseValidityVersion(versionsFromApi);
						}
					});
					this.versionLookUpCount++;
				}
			}
		}
	}

	public async initLocalVersionFromBackend(versionsFromApi: VersionModel[]) {
		console.debug('versions (getVersion)', versionsFromApi);

		for (const versionFromApi of versionsFromApi) {
			this.setLocalVersion(versionFromApi);
		}
	}

	public async isDataExpired(version: VersionModel): Promise<boolean> {
		const localVersion = this.getLocalVersion(version.VersionType);

		const appExpired = localVersion === null || version.Id != localVersion.Id;

		console.debug('**IndexedDb (' + VersionType[version.VersionType] + ') is ' + (appExpired ? 'expired !' : 'up to date :: ') + 'current: ' + localVersion?.Id + ' new: ' + version?.Id);

		return appExpired;
	}

	public async clearCache(versionType: VersionType): Promise<void> {
		await this.ensureDbOpen();

		let key = null;
		switch (versionType) {
			case VersionType.Frames:
				await this.clear(StoreService.storeName, null);
				break;

			case VersionType.ToolsAndAccessories:
				key = `${await this.baseUrl()}/${this.stockKeepingUnitsEndpoint}`;
				await this.clear(StoreService.storeName, key);
				break;

			case VersionType.Customers:
				await this.clear(CustomerDataService.customerStoreName, null);
				break;

			case VersionType.QueueOrderItems:
				await this.clear(QueueItemService.orderQueueStoreName, null);
				break;

			case VersionType.Orders:
				await this.clear(OrdersDataService.ordersStoreName, null);
				break;

			case VersionType.ManualConfig:
				await this.clear(ManualConfigService.manualConfigStoreName, null);
				break;

			case VersionType.StartKits:
				key = `${await this.baseUrl()}/${this.startKitsEndpoint}`;
				await this.clear(StoreService.storeName, key);
				break;

			case VersionType.Cases:
				key = `${await this.baseUrl()}/${this.casesEndpoint}`;
				await this.clear(StoreService.storeName, key);
				break;

			case VersionType.Translations:
				AppConstants.languages.forEach(async languageCode => {
					key = `${await this.baseUrl()}/${this.translationsEndpoint}/${languageCode.toUpperCase()}`;
					await this.clear(StoreService.storeName, key);
				});
				break;
		}
	}

	public getLocalVersion(versionType: VersionType): VersionModel {
		const key = this.getVersionTableName(versionType);
		return this.localStorage.get<VersionModel>(key);
	}

	public setLocalVersion(version: VersionModel) {
		this.localStorage.set(this.getVersionTableName(version.VersionType), version);
	}
	public clearLocalVersion(versionType: VersionType): void {
		const key = this.getVersionTableName(versionType);
		//console.debug('localStorage - clear: ', key);
		return this.localStorage.clear(key);
	}

	private getVersionTableName(versionType: VersionType = VersionType.Frames) {
		var isValueProperty = parseInt(versionType.toString(), 10) >= 0
		if (isValueProperty) {
			return IndexDbService.versionTableNamePrefix + VersionType[versionType].toString();
		}
	}


	public async getTable<T>(storeName: string): Promise<Table<any, IndexableType>> {
		await this.ensureDbOpen();

		try {
			let result = await this.db.table(storeName);
			return result;
		} catch (error) {
			this.databaseIsOpen = false;
			this.onSaveToStoreError<T>(error);
		}
	}

	public async getKeys<T>(storeName: string): Promise<Array<string>> {
		await this.ensureDbOpen();


		try {
			let table = await this.getTable(storeName);
			let keys: Array<string> = [];
			await table.orderBy('key').keys(function (k) {
				for (let index = 0; index < k.length; index++) {
					const key = k[index];
					keys.push(key.toString());
				}
			});
			return keys;
		} catch (error) {
			this.databaseIsOpen = false;
			this.onSaveToStoreError<T>(error);
		}

	}
	public async get<T>(storeName: string, key: any): Promise<T> {
		await this.ensureDbOpen();

		try {
			let result = await this.db?.table(storeName).get({ key });
			return result ? result.data : null;
		} catch (error) {
			this.databaseIsOpen = false;
			this.onSaveToStoreError<T>(error);
		}
	}

	public async getByKeyPrefix<T>(storeName: string, key: any): Promise<T[]> {
		await this.ensureDbOpen();
		const result = this.db?.table(storeName).where("key").startsWith(key).toArray();
		return result ? result : null;
	}

	public async replaceAndSave<T>(storeName: string, data: T, key: string) {
		try {
			await this.ensureDbOpen();

			if (await this.exists(storeName, key)) {
				await this.db[storeName]?.delete(key);
			}

			return await this.db?.table(storeName).add({ key, data });
		} catch (e) {
			this.onSaveToStoreError<T>(e);
		}

	}

	public async exists(storeName: string, key: any): Promise<boolean> {
		await this.ensureDbOpen();

		const data = await this.get(storeName, key);
		return data != null && data !== undefined;
	}

	public async clear<T>(storeName: string, key?: string): Promise<void> {
		await this.ensureDbOpen();

		if (key && this.db) {
			return await this.db[storeName]?.delete(key);
		} else {
			await this.db?.table(storeName).clear();
		}
	}

	public async delete(): Promise<void> {
		if (this.db) {
			console.debug("Deleting data from IndexedDb");
			this.db.close();
			await this.db.delete();
		}
	}

	public close() {
		if (this.db) {
			this.db.close();
			this.db = null;
		}
	}

	public async getTables() {
		return this.db?.tables;
	}

	private async ensureDbOpen() {
		if (!this.databaseIsOpen) {
			await this.openDatabase();
		}
	}


	private async clearCacheIfNeeded(version: VersionModel): Promise<boolean> {
		let wasExpired = false;
		if (version && await this.isDataExpired(version)) {
			console.debug('clearing ' + VersionType[version.VersionType]);
			await this.clearCache(version.VersionType);
			this.setLocalVersion(version);
			wasExpired = true;
		}
		return wasExpired
	}

	public getLocalManualConfigNewestUuid(manualConfigGroup: ManualConfigGroupConstant): string {
		return this.localStorage.get<string>(IndexDbService.manualConfigTableName + '_' + manualConfigGroup.toString());
	}


	public setNewestManualConfigUuid(manualConfigGroup: ManualConfigGroupConstant,
		newestManualConfigUuid: string) {
		this.localStorage.set(IndexDbService.manualConfigTableName + '_' + manualConfigGroup.toString(), newestManualConfigUuid);
	}

	public async checkDatabaseValidityVersion(versions: VersionModel[]): Promise<boolean> {
		console.debug("Checking if IndexedDb is old");
		let wasAnyExpired = false;
		if (versions) {
			for (const version of versions) {
				const wasExpired = await this.clearCacheIfNeeded(version);
				if (wasExpired) {
					wasAnyExpired = true;
				}
			}
		}
		return wasAnyExpired
	}

	private onSaveToStoreError<T>(e: any) {
		if (this.errorIsQuotaIsExceeded(e)) {
			this.appState.showLinkToStoragePage = true;
			console.error("Maximum quota received");
		} else if (this.errorIsDataOnKeyExistsError(e)) {
			this.appState.showLinkToStoragePage = true;
			console.error("errorIsDataOnKeyExistsError", e.message);
		}

		if (!this.ignoreIndexedDb) {
			throw new IndexeddbError();
		}
	}

	private errorIsDataOnKeyExistsError(e: Error) {
		return e.name == "ConstraintError";
	}

	private errorIsQuotaIsExceeded(e: any) {
		return e.name == "QuotaExceededError" || (e.inner && e.inner.name === 'QuotaExceededError');
	}
	private errorIsUnsupportedIndexedDb(e: any) {
		return e.message != ErrorResponseConstants.IDBNotSupportedMessage && e.message != ErrorResponseConstants.EdgeDBNotSupportedMessage; // firefox specific error (IDB is not supported in private browsing)
	}
	private errorIsindexedDbRelated(e: any) {
		return e.name == ErrorResponseConstants.OpenFailedError || (e.inner && e.inner.name === ErrorResponseConstants.OpenFailedError) ||
			e.name == ErrorResponseConstants.InvalidStateError || (e.inner && e.inner.name === ErrorResponseConstants.InvalidStateError) ||
			e.name == ErrorResponseConstants.DatabaseClosedError || (e.inner && e.inner.name === ErrorResponseConstants.DatabaseClosedError);
	}
}
export interface DatabaseTables {
	[name: string]: string;
}

export interface DatabaseVersion {
	version: number;
	stores: DatabaseTables;
	upgradeFunction(trans: any): void;
}
