import { animate, animation, style, transition, trigger, useAnimation } from "@angular/animations";

export function FadeInOutTransition(opacity = 1, fadein = 300, fadeout = 200, triggerName = 'fadeInOutTransition') {
	return trigger(triggerName, [
		transition(':enter', [
			useAnimation(FadeInAnimation, { params: { timing: fadein, opacity } })
		]),
		transition(':leave', [
			useAnimation(FadeOutAnimation, { params: { timing: fadeout } })
		])
	]);
}

export const FadeInAnimation = animation([
	style({ opacity: 0 }),
	animate("{{ timing }}ms", style({ opacity: "{{ opacity }}" }))
]);

export const FadeOutAnimation = animation([
	animate("{{ timing }}ms", style({ opacity: 0 }))
]);