import { Component, OnInit } from '@angular/core';
import { LogLevelEnum } from '@infrastructure/logLevel.enum';
import { EventsService } from '@services/events.service';
import { IndexDbService } from '@services/index-db.service';
import { LoggerService } from '@services/logger.service';
import { Content, StorageErrorsService, TableContent } from '@services/storage-errors.service';
import { UserService } from '@services/user.service';
import { UserTypeConstant } from '@shared/user-type-constants';
import { Table } from 'dexie';

@Component({
  selector: 'app-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss']
})
export class StorageComponent implements OnInit {

  public tableContent: Array<TableContent> = [];
  public currentTableContent: TableContent = null;
  public tables: Array<Table> = [];
  public totalSize: number = 0;
  public loading: boolean = false;
  public dataWasSend: boolean = false;
  public dataIsSending: boolean = false;
  public imgurl = "/assets/2455-test.png";
  public static readonly storeName = "storageTestStore";
  private readonly Key = "storageTest";
  public totalSpace: number;
  public usedSpace: number;
  public percentUsed: number;
  public showAdminActions: boolean = false;
  constructor(
    public indexedDbService: IndexDbService,
    private events: EventsService,
    private loggerService: LoggerService,
    public userService: UserService,
    public storageErrorsService: StorageErrorsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.initCalculations();
    await this.sendToLog();

    const user = await this.userService.GetUser();
    this.showAdminActions = user.Type === UserTypeConstant.Employee;
  }

  public async initCalculations() {
    this.tableContent = [];
    this.totalSize = 0;
    let tablesObject = await this.indexedDbService.getTables();

    console.debug('tablesObject', tablesObject);

    for (let index = 0; index < tablesObject?.length; index++) {
      const store = tablesObject[index];
      let tableName = store.name;
      let table = await this.indexedDbService.getTable(store.name);
      let keys = await this.indexedDbService.getKeys(store.name);
      if (keys && keys.length > 0) {
        let content: Array<Content> = [];
        keys.forEach(async key => {
          let data = await this.indexedDbService.get(tableName, key);
          content.push(
            {
              content: data,
              name: key,
              size: JSON.stringify(data).length
            });

          this.totalSize = this.totalSize + JSON.stringify(data).length;
        });

        this.tableContent.push({ table: table, keys: content });

      } else {
        this.tableContent.push({
          table: table, keys: [
            {
              content: "No data in " + tableName,
              name: tableName,
              size: 0
            }
          ]
        });
      }
    };

    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const estimate = await navigator.storage.estimate();

      this.totalSpace = estimate?.quota / 1000000;
      this.usedSpace = estimate?.usage / 1000000;
      this.percentUsed = this.usedSpace / this.totalSpace * 100;
    }
  }

  public async showContent(tableName, keyName) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.dataWasSend = false;
    this.events.showLoader(true);

    setTimeout(() => {

      let tableContentCopy = [...this.tableContent];
      this.currentTableContent = tableContentCopy.filter(t => t.table.name === tableName)[0];
      this.currentTableContent.currentKey = this.currentTableContent.keys.filter(k => k.name === keyName)[0];

      this.loading = false;
      this.events.showLoader(false);
    }, 2000);
  }

  public async deleteFromStore(tableName, keyName) {
    await this.indexedDbService.clear(tableName, keyName);
    await this.initCalculations();
  }

  public async sendToLog(tableName = null, keyName = null) {
    if (!this.dataIsSending) {
      this.dataIsSending = true
      const user = await this.userService.GetUser();
      let eventId = user.Uuid;
      let userId = `${user.Name}(${user.DeliveryEmail}) - ${user.CustomerNo}`;
      if (user.AliasImitator !== '') {
        userId = `${user.AliasImitator} @$ ${userId}`
      }
      let logEvent = {};
      if (!tableName) {
        this.storageErrorsService.sendToLog();
      } else {
        logEvent = {
          message: `${user.Name} has send data from indexedDb (${tableName}/${keyName})`,
          logLevel: LogLevelEnum.Warning,
          extraPropertiesToLog: {
            ['indexedDb Store/key content']: JSON.stringify(this.currentTableContent.currentKey)
          },
          eventId: eventId,
          userId: userId
        }
      }
      this.loggerService.sendClientLog(logEvent);

      setTimeout(() => {
        this.dataWasSend = true;
        this.dataIsSending = false
      }, 1000);
    }
  }

  public async testDb() {
    let that = this;
    this.urlTo64(this.imgurl, async function (s) {
      var data = {
        img: s
      }
      await that.indexedDbService.replaceAndSave(StorageComponent.storeName, data, that.Key + '_' + (Math.random() + 1).toString(36).substring(7));
      await that.initCalculations();
    });

  }

  public urlTo64(u, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', this.imgurl, true);
    xhr.responseType = 'blob';

    xhr.onload = function (e) {
      if (this.status == 200) {
        // get binary data as a response
        var blob = this.response;
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          cb(base64data);
        }
      }
    };
    xhr.send();

  }

}
