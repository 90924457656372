import { Type } from 'class-transformer';
import { DataTranslationModel } from './data-translation-model';

export class ColorGroupModel {
    Id: number;
    Uuid: string;
    Code: string;

    @Type(() => DataTranslationModel)
    Description: DataTranslationModel;
    Order: string;

    public static createCopy(fromModel: ColorGroupModel): ColorGroupModel {
        const colorGroupModel = new ColorGroupModel();
        colorGroupModel.Id = fromModel?.Id;
        colorGroupModel.Uuid = fromModel?.Uuid;
        colorGroupModel.Code = fromModel?.Code;
        colorGroupModel.Order = fromModel?.Order;
        colorGroupModel.Description = fromModel?.Description ? new DataTranslationModel(fromModel.Description.TranslationKey, fromModel.Description.Fallback) : null;
        return fromModel ? colorGroupModel : null;
    }
}
