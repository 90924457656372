import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable()

export class GoogleAnalyticsService {


  constructor() { }

  public async eventEmitter(
    eventAction: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null) {
    // console.log('event', eventAction, {
    //   'event_category': eventCategory,
    //   'event_label': eventLabel,
    //   'value': eventValue
    // });

    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel.toLowerCase(),
      'value': eventValue
    })
  }
}
