<div *ngIf="noneMatchingFinishSelected" class="customizer-errors" [ngClass]="{'highlightChangesOverlay': false}">
	<customizer-changes [changes]="finishErrors" (changesChange)="save($event)" (resetErrors)="rejectFinishChange($event)"></customizer-changes>
</div>

<customizer-component-header2 [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)" style="user-select: none; min-height: 100%;">

	<div additional class="temple-type-buttons">
		<button *ngFor="let templeType of templeTypes" (click)="filterTempleTypeDisplay(templeType)" class="btn btn-secondary mr-1 mb-3" [ngClass]="{'toggled': templeType === activeTempleType}">{{templeType.replace(' temples', '')}}</button>
	</div>
	
	<div main id="select-box-container">
		<ng-container *ngFor="let group of groups">
			<ng-container *ngIf="getState(group) === 'collapse'">
				<div *ngIf="group.designs.length > 1">
					<select-box-item
						class="customizer-item"
						[attr.id]="group.selectedDesign.Uuid"
						[ngClass]="{'is-selected': getSelectedVariant !== null && group.selectedDesign.Id === getSelectedVariant.Id}"
						[optionsDiscontinued]="optionsDiscontinued"
						[optionsText]="optionsText"
						[option]="group.selectedDesign"
						[selected]="getSelectedVariant !== null && group.selectedDesign.Id === getSelectedVariant.Id"
						[optionsImage]="getImage"
						[imageClass]="imageClass"
						[currentColor]="currentColor" 
						[variantOrModelIsNew]="variantOrModelIsNew"
						(click)="expandGroup(group)"
						[hasSubItems]="true"
					>
					</select-box-item>
				</div>
				<ng-container *ngIf="group.designs.length === 1">
					<select-box-item
						class="customizer-item"
						[attr.id]="group.selectedDesign.Uuid"
						[ngClass]="{'is-selected': getSelectedVariant !== null && group.selectedDesign.Id === getSelectedVariant.Id}"
						[optionsDiscontinued]="optionsDiscontinued"
						[optionsText]="optionsText"
						[option]="group.selectedDesign"
						[selected]="getSelectedVariant !== null && group.selectedDesign.Id === getSelectedVariant.Id"
						[optionsImage]="getImage"
						[imageClass]="imageClass"
						[currentColor]="currentColor" 
						[variantOrModelIsNew]="variantOrModelIsNew"
						(click)="onSelected(group.selectedDesign, group)"
					>
					</select-box-item>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="group.designs.length > 0">
				<div class="back">
					<div [@submenuAnim]="getState(group)">
						<div class="temple-group-header" (click)="collapseGroup(group)">
							{{group.name}}
							<div class="small">
								{{ 'COMMON.ACTIONS.COLLAPSE' | translate }}
								<i class="fa-light fa-angle-up"></i>
							</div>
						</div>
						<select-box 
							[editMode]="state === customizerStateEnum.EDITORDER"
							[imageClass]="'temple'"
							[optionsImage]="getImage"
							[options]="group.designs"
							optionsText="Description"
							optionsId="Id"
							[selected]="getSelectedVariant"
							(selectedChange)="onSelected($event, group)"
							[optionsDiscontinued]="'Discontinued'"
							[colorMatchMode]="true"
							[colorsAvailableForMatching]="allPossibleColorsOnOtherSelectedVariants"
							[currentColor]="getSelectedVariant.VariantColor"
							[variantOrModelIsNew]="modelIsNew"
							[matchColor]="menu.matchColor"
							[isAccordion]="true"
						>
						</select-box>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>

</customizer-component-header2>


