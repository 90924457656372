import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceHash'
})
export class ReplaceHashPipe implements PipeTransform {

    transform(str: string) {
        return str.replace('#', `<span class="box-size"></span>`).replace('¤', `<span class="box-size circle"></span>`);
    }
}
