import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from "@angular/core";
import { PriceSettingEnum } from "@infrastructure/price-setting.enum";
import { VersionType } from "@infrastructure/versionType.enum";
import { CustomiserNotification } from "@models/customiser-notification-model";
import { PriceSettingModel } from "@models/price-setting.model";
import { UserDataModel } from "@models/user-data-model";
import { AppState } from "@shared/app-state";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConstants } from "src/app.constants";
import { environment } from './../environments/environment';
import { CustomerDataService } from "./customer-data.service";
import { EnvService } from './env.service';
import { GoogleAnalyticsService } from "./google-analytics.service";
import { IndexDbService } from "./index-db.service";
import { LanguageService } from "./language.service";
import { LocalstorageService } from './localstorage.service';
import { NotificationService } from "./notification.service";
import { OrderOnBehalfService } from './order-on-behalf.service';
import { PriceSettingsService } from "./price-settings.service";
import { SessionService } from './session.service';
import { SignalRService } from "./signal-r.service";
import { UserService } from './user.service';

@Injectable()
export class LoginService {

	constructor(private storage: CookieService,
		private sessionStorage: SessionService,
		private localStorage: LocalstorageService,
		private userservice: UserService,
		private orderOnBehalfService: OrderOnBehalfService,
		private indexDbService: IndexDbService,
		private customerDataService: CustomerDataService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private notificationService: NotificationService,
		private languageService: LanguageService,
		private sessionService: SessionService,
		private injector: Injector,
		private appState: AppState,
		private priceSettingsService: PriceSettingsService,
		private env: EnvService,
		private http: HttpClient,
	) {
		this.isLoggedInSubject = new BehaviorSubject<boolean>(this.storage.get(this.tokenId) && this.userservice.currentUser$.value ? true : false);
	}

	private readonly tokenId: string = "dcg-token";

	private isLoggedInSubject: BehaviorSubject<boolean>;
	private loggedInUserSubject = new BehaviorSubject<UserDataModel>(null);
	private existingNotifications: Array<CustomiserNotification> = [];
	private notificationKey = "notificationdata";

	public async Login(token: string) {
		await this.Logout(false);
		this.createLoginCookie(token);
		await this.setUserData();

		try {
			await this.registerLogin();

			const user = await this.userservice.GetUser();
			if (user) {
				await this.googleAnalyticsService.eventEmitter('login', 'engagement', `${user.Uuid} has logged in`, 40);

				this.appState.priceModuleEnabled.next(user.priceModuleEnabled && user.PriceSettingAllowed !== PriceSettingEnum.AllowNone);

				this.appState.priceSetting.next(new PriceSettingModel(user.PriceSettingDisplay, this.priceSettingsService.getPriceText(user.PriceSettingDisplay)));
			}

			// const signalRService = this.injector.get(SignalRService);
			// await signalRService.addSignalrListener('login');

			await this.notificationService.initNotifications();
			await this.initializeLocalization();
		}
		catch (error) {
		}

		this.isLoggedInSubject.next(true);
	}

	public async SetTokenAndUsername(token, username) {
		if (token) {
			await this.Login(token);
		}
	}

	public async liteLogin(token: string) {
		await this.Logout(false);

		this.createLoginCookie(token, true);

		await this.setUserData(true);

		setTimeout(async () => {
			console.warn('timed logout for liteLogin');
			await this.Logout();
		}, 3000);
	}

	public createLoginCookie(token: string, isLiteLogin: boolean = false) {
		const ttl = new Date();

		if (isLiteLogin) {
			ttl.setTime(new Date().getTime() + 3 * 1000); // add 30 second cookie
		} else {
			ttl.setTime(new Date().getTime() + 14 * 24 * 60 * 60 * 1000); // add 14 day cookie
		}

		const useSecure = environment.production;
		this.storage.set(this.tokenId, token, ttl, '/', this.getHostname, useSecure, "Lax");
	}

	private async setUserData(isLiteLogin: boolean = false): Promise<void> {
		const user = await this.userservice.getUserData();

		user.IsLiteLogin = isLiteLogin;
		user.LoginRegistered = new Date();
		this.userservice.setUser(user);
		this.orderOnBehalfService.updateOrderRelevantData();
		this.customerDataService.removeAllCustomersInStore();
		this.loggedInUserSubject.next(user);
	}

	public async updateLoginRegistered() {
		const user = await this.userservice.getUserData();
		user.LoginRegistered = new Date();
		await this.userservice.setUser(user);
	}

	public async liteLogout() {
		this.isLoggedInSubject.next(false);

		if (this.storage.get(this.tokenId) != null) {
			this.storage.delete(this.tokenId, '/', this.getHostname);
		}

		this.loggedInUserSubject.next(null);
		this.sessionStorage.clear();
	}

	public async Logout(forceClearAll = true) {
		const user = await this.userservice.currentUser$.getValue();

		this.isLoggedInSubject.next(false);
		this.loggedInUserSubject.next(null);

		if (this.storage.get(this.tokenId) != null) {
			this.storage.delete(this.tokenId, '/', this.getHostname);
		}

		if (forceClearAll) {
			await this.indexDbService.clearCache(VersionType.Frames);
			await this.indexDbService.clearCache(VersionType.ToolsAndAccessories);
			await this.indexDbService.clearCache(VersionType.Customers);
			await this.indexDbService.clearCache(VersionType.QueueOrderItems);
			await this.indexDbService.clearCache(VersionType.Orders);
			await this.indexDbService.clearCache(VersionType.ManualConfig);

			this.existingNotifications = await this.notificationService.getNotifications();
			this.localStorage.clear();
			this.localStorage.set(this.notificationKey, this.existingNotifications);
		}

		this.orderOnBehalfService.resetCustomer();
		this.sessionStorage.clear();

		if (user) {
			await this.googleAnalyticsService.eventEmitter('logout', 'engagement', `${user.Uuid} has logged out`, 20);
		}

		const signalRService = this.injector.get(SignalRService);
		await signalRService.closeConnection();
	}

	public GetToken(): string {
		return this.storage.get(this.tokenId);
	}

	public async PreserveNotifications(): Promise<void> {
	}

	public IsLoggedInObservable(): Observable<boolean> {
		return this.isLoggedInSubject.asObservable();
	}

	public LoggedInUserObservable() {
		return this.loggedInUserSubject.asObservable();
	}

	public IsLoggedIn() {
		return this.isLoggedInSubject.value;
	}

	public AllowUnauthenticatedAccess() {
		let allowUnauthenticatedAccess = false;

		AppConstants.allowedUnauthenticatedUrlParts.forEach(urlPart => {
			if (window.location.href.indexOf(urlPart) > -1) {
				allowUnauthenticatedAccess = true;
			}
		});

		return allowUnauthenticatedAccess;
	}

	private get getHostname() {
		return window.location.hostname == "localhost" ? "" : window.location.hostname;
	}

	private async initializeLocalization() {
		try {
			const sessionLanguage = this.sessionService.get<string>("language");

			if (!sessionLanguage) {
				const defaultLang = AppConstants.languages[0];
				this.languageService.addLanguages(AppConstants.languages);
				this.languageService.setDefaultLang(defaultLang);
				await this.languageService.setSessionOrBrowserLanguage();
			}
		}
		catch (error) {
		}
	}

	public async postLogin(username: string, password: string): Promise<any> {

		const body = `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;

		return await this.http.post<any>(`${await this.env.baseUrl()}/token`, body, {
			headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8')
		}).toPromise();
	}

	public async registerLogin(): Promise<any> {
		return this.http.post(`${await this.env.baseUrl()}/registerlogin`, {}).toPromise();
	}


	public async postLiteLogin(userUuid: string): Promise<any> {
		const body = `"${encodeURIComponent(userUuid)}"`;
		return await this.http.post<any>(`${await this.env.baseUrl()}/litetoken`, body, {
			headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
		}).toPromise();
	}

}
