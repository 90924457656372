import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { EventsService } from '@services/events.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'loading-overlay',
	styleUrls: ['./_loading-overlay.scss'],
	template: `
        <div #contentPlaceholder class="loading-overlay" *ngIf="showLoader" [ngClass]="{'show-loader': showLoader, 'not-show-loader': !showLoader}">
            <dcg-image class="loader" [src]="'/assets/animations/Framespinner.gif'"></dcg-image>
        </div>
    `
})
export class LoadingOverlayComponent implements OnDestroy {

	public showLoader: boolean = false;

	private subscription: Subscription;

	constructor(
		private eventsService: EventsService,
		private changeDetector: ChangeDetectorRef
	) {
		this.subscription = this.eventsService.showLoadingOverlay$.subscribe((mode) => {
			if (mode !== null) {
				this.showLoader = mode;
				this.changeDetector.detectChanges(); //necessary when error occurs
			}
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

}
