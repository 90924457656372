import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { DataTranslationModel } from '@models/data-translation-model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language.service';
import { Subscription } from 'rxjs';

@Pipe({
  name: 'dataTranslate', pure: false
})
export class DataTranslatePipe implements PipeTransform, OnDestroy {

	private subscription: Subscription;

    constructor(private languageService: LanguageService, translationService: TranslateService, ref: ChangeDetectorRef) {
        this.subscription = translationService.onLangChange.subscribe((x: LangChangeEvent) => {
            ref.markForCheck();
        });
    }

    transform(value: DataTranslationModel|string, ...args: any): string {
        let translateKey = "";

		if(value) {
            if(value instanceof DataTranslationModel) {
                if(value.TranslationKey && this.languageService.hasTranslation(value.TranslationKey)) {
                    translateKey = value.TranslationKey;
                }
				else {
                    translateKey = value.Fallback;
                }
            }
			else {
                translateKey = value;
            }
        }

        return translateKey;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
