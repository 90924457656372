import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { BaseModal } from '../_modals/base-modal';

@Injectable()
export class ModalService {

	private vcRef: ViewContainerRef;
	public activeInstances: Array<ComponentRef<any>> = [];

	constructor() { }

	registerViewContainerRef(vcRef: ViewContainerRef): void {
		this.vcRef = vcRef;
	}

	create<T>(component: Type<BaseModal>, parameters?: any): Observable<ComponentRef<T>> {
		this.closeAll();

		const componentRef$ = new ReplaySubject();

		if (this.vcRef) {
			let exist = this.activeInstances.find(ai => ai.componentType.name === component.name);

			if (exist) {
				let idx = this.activeInstances.findIndex(ai => ai.componentType.name === component.name);
				this.activeInstances.splice(idx, 1);
			}

			const componentRef = this.vcRef?.createComponent(component);
			Object.assign(componentRef.instance, parameters, { ReplaySubject: componentRef$ });

			this.activeInstances.push(componentRef);

			componentRef.instance.destroy = (all: boolean) => {
				if (all) {
					this.closeAll();
				}
				else {
					this.activeInstances = this.activeInstances.filter(x => x != componentRef);
					componentRef.destroy();
					componentRef$.next(componentRef);
					componentRef$.complete();
				}
			};
		}

		return componentRef$.asObservable() as Observable<ComponentRef<T>>;
	}

	activateInstance<T>(component: Type<BaseModal>, parameters?: any): Observable<ComponentRef<T>> {
		const componentRef$ = new ReplaySubject();

		if (this.vcRef) {
			let exist = this.activeInstances.find(ai => ai.componentType.name === component.name);

			if (exist) {
				let idx = this.activeInstances.findIndex(ai => ai.componentType.name === component.name);
				this.activeInstances.splice(idx, 1);
			}

			const componentRef = this.vcRef?.createComponent(component);
			Object.assign(componentRef.instance, parameters, { ReplaySubject: componentRef$ });

			this.activeInstances.push(componentRef);

			componentRef.instance.destroy = (all: boolean) => {
				if (all) {
					this.closeAll();
				}
				else {
					this.activeInstances = this.activeInstances.filter(x => x != componentRef);
					componentRef.destroy();
					componentRef$.next(componentRef);
					componentRef$.complete();
				}
			};
		}

		return componentRef$.asObservable() as Observable<ComponentRef<T>>;
	}

	closeAll() {
		this.activeInstances.forEach(element => {
			element.destroy();
			element.instance.replaySubject?.next(element);
			element.instance.replaySubject?.complete();
		});

		this.activeInstances = [];
	}

	close(closingType: Type<BaseModal>) {
		this.activeInstances.filter(x => x.instance instanceof closingType).forEach(element => {
			element.destroy();
			element.instance.ReplaySubject.next(element);
			element.instance.ReplaySubject.complete();
		});
	}

}
