import { Component } from "@angular/core";
import { SubscribingComponent } from '@infrastructure/subscribing-component';
import { CustomerType } from '@models/customer';
import { SalesDistrictType } from "@models/sales-district";
import { GoogleAnalyticsService } from "@services/google-analytics.service";
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { UserService } from "@services/user.service";
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';

@Component({
	selector: 'on-behalf-of-bar',
	templateUrl: 'on-behalf-of-bar.html',
	styleUrls: ["./on-behalf-of-bar.scss"],
	animations: [
		FadeInOutTransition()
	],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		"[@fadeInOutTransition]": ''
	}
})
export class OnBehalfOfBarComponent extends SubscribingComponent {

	public customerType: string;
	public customerName: string;

	constructor(
		private onBehalfOfService: OrderOnBehalfService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private userService: UserService
	) {
		super();

		this.subscriptions.push(onBehalfOfService.customerAsObservable().subscribe(async x => {
			if (x) {
				this.customerType = x ? x.Type === CustomerType.OrderOnBehalf ? 'ORDERONBEHALF.CHANGEPARTNER' : 'ORDERONBEHALF.CHANGESHOP' : null;
				this.customerName = x ? `${x.Name}${x.Street ? ', ' + x.Street : ''}` : null;

				if (!x.IsNewPartner || x.SalesDistrictType === SalesDistrictType.OwnDistrict) {
					this.customerName += ` (${x.No})`;
				}

				const user = await this.userService.GetUser();

				if (user) {
					await this.googleAnalyticsService.eventEmitter('partner_change', 'engagement', `${user.Uuid} changed partner to ${x.No} (${x.Name})`, 10);
				}
			}
		}));
	}

	public async changeCustomer() {
		await this.onBehalfOfService.triggerSetCustomerModal();
	}

}
