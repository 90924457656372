import { Component, Input } from '@angular/core';
import ValidationResult from '@infrastructure/validation-result';

@Component({
  selector: 'error-summary',
  template: `
    <div class="text-danger" *ngFor="let item of errors.errors">{{item.error | translate}}</div>
  `,
  styleUrls: ['./error-summary.component.scss']
})
export class ErrorSummaryComponent {
    @Input() errors: ValidationResult;
}
