import { Injectable } from '@angular/core';
import { LogLevelEnum } from '@infrastructure/logLevel.enum';
import { SyncStateEnum } from '@infrastructure/sync-state.enum';
import { WhichOrderEnum } from '@infrastructure/which-order-enum';
import { FMResponseModel, LimitedEditionFieldDataModel } from '@models/api-models/FMResponseModel';
import { OrderCreateModel } from '@models/api-models/order-create-model';
import { CustomerModel, CustomerOrderRelevantModel } from '@models/customer';
import { Order } from '@models/order';
import { OrderCompleteModel } from '@models/order-complete-model';
import { IOrderConfirmationEmail } from '@models/order-confirmation-email';
import { OrderItemModel } from '@models/order-item';
import { OrderItemMiscModel, OrderItemMiscState } from '@models/order-item-misc-model';
import { OrderItemState } from '@models/order-item-state';
import { OrderItemType } from '@models/order-item-type';
import { OrderState } from '@models/order-state';
import { OrderUpdateModel } from '@models/order-update-model';
import { OrderIdsModel } from '@models/orderIds-model';
import { OrdersModel } from '@models/orders';
import { PriceItem, PriceResponse } from '@models/price-model';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import { Mutex, MutexInterface } from 'async-mutex';
import { plainToClass } from 'class-transformer';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { CustomerDataService } from './customer-data.service';
import { DataApiService } from './data-api.service';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { LoggerService } from './logger.service';
import { OfflineService } from './offline.service';
import { OrderItemService } from './order-item.service';
import { QueueItemService } from './queue-item.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';
import { ServiceUtils } from './utils/service.utils';
import { ModelsService } from './v2/models.service';

@Injectable({
	providedIn: 'root'
})
export class OrdersDataService {

	public orderDataChanged = new Subject<string>();
	public orderItemDataChanged = new Subject<OrderItemModel>();
	public orderItemMiscDataChanged = new Subject<OrderItemMiscModel>();
	public orderItemPriceLookup = new Subject<PriceItem>();
	public orderItemIsDoingPriceLookup = new Subject<boolean>();
	public orderItemMiscPriceLookup = new Subject<PriceItem>();
	public orderItemMiscIsDoingPriceLookup = new Subject<boolean>();

	/*
	* IMPORTAMT that we use mutex. GetOrders is getting called everytime a dirty state occurs (example is a item is deleted).
	* We have to make sure, we dont post anyting to an order (example orderitem on order), at the same time we is getting the order (deadlock chance).
	*/
	private ordersDataMutex = new Mutex();
	private mutexReleaser: MutexInterface.Releaser;

	constructor(
		private indexedDbService: IndexDbService,
		private dataApiService: DataApiService,
		private queueItemService: QueueItemService,
		private customerDataService: CustomerDataService,
		private sessionService: SessionService,
		private userService: UserService,
		private offlineService: OfflineService,
		private loggerService: LoggerService,
		private serviceUtils: ServiceUtils,
		private orderItemService: OrderItemService,
		private env: EnvService,
		private modelsService: ModelsService
	) { }

	public static readonly ordersStoreName = "orders";

	public async createOrderMiscItem(orderItemMiscModel: OrderItemMiscModel): Promise<void> {
		const userCanAddToBagNonBlocking = this.userService.UserCanUseNonBlockingOfflineAddTobag();

		if (userCanAddToBagNonBlocking) {
			try {
				const orderItemMiscModelState = orderItemMiscModel.getState();

				orderItemMiscModelState.SyncState = SyncStateEnum.Pending;
				orderItemMiscModelState.SyncOrderItemGuid = Guid.create().toString();

				await this.queueItemService.createMiscOrderItemInQueue(orderItemMiscModelState);

				this.queueItemService.orderItemPutInStore.next(true);
			}
			catch (error) {
				await this.postMiscOrderItem(orderItemMiscModel);
			}
		}
		else {
			await this.postMiscOrderItem(orderItemMiscModel);
		}
	}

	public async createOrderItem(orderItemCreateModel: OrderItemState): Promise<OrderItemState> {
		await this.postOrderItem(orderItemCreateModel);

		return orderItemCreateModel;
	}

	public async editOrderMiscItem(orderItem: OrderItemMiscModel): Promise<void> {
		const result = await this.orderItemService.editOrderMiscItem(orderItem);
		this.orderItemMiscDataChanged.next(orderItem);
		this.reloadOrders(orderItem.CustomerNo) // non blocking on purpose

		return result;
	}

	public async editOrderItem(orderItem: OrderItemModel): Promise<OrderItemModel> {
		await this.lowTempleInclinationCheckOnEdit(orderItem);

		const orderItemModel = await this.orderItemService.editOrderItem(orderItem);
		this.orderItemDataChanged.next(orderItem);
		this.reloadOrders(orderItem.CustomerNo) // non blocking on purpose

		return orderItemModel;
	}

	public async moveOrderMiscItem(Uuid: string, orderId: number, customerNo: string, skipOrderReload: boolean = false) {
		await this.orderItemService.moveOrderMiscItem(Uuid, orderId);

		if (!skipOrderReload) {
			this.reloadOrders(customerNo) // non blocking on purpose
		}
	}

	public async moveOrderItem(Uuid: string, orderId: number, customerNo: string, skipOrderReload: boolean = false) {
		await this.orderItemService.moveOrderItem(Uuid, orderId);

		if (!skipOrderReload) {
			this.reloadOrders(customerNo) // non blocking on purpose
		}
	}

	public async deleteOrderMiscItem(uuid: string, customerNo: string, skipOrderReload: boolean = false): Promise<void> {
		const result = await this.dataApiService.deleteOrderMiscItem(uuid);

		if (!skipOrderReload) {
			this.reloadOrders(customerNo) // non blocking on purpose
		}

		return result;
	}

	public async deleteOrderItem(uuid: string, customerNo: string, skipOrderReload: boolean = false): Promise<void> {
		const result = await this.orderItemService.deleteOrderItem(uuid);

		if (!skipOrderReload) {
			this.reloadOrders(customerNo) // non blocking on purpose
		}

		return result;
	}

	public async editOrderData(orderId: number, model: OrderUpdateModel, customerNo: string): Promise<void> {
		await this.dataApiService.setOrderData(orderId, model);

		this.reloadOrders(customerNo) // non blocking on purpose
	}

	public async deleteOrder(id: number, customerNo: string): Promise<void> {
		const result = await this.dataApiService.deleteOrder(id);

		await this.customerDataService.reloadActiveCustomersInStore();

		this.reloadOrders(customerNo) // non blocking on purpose

		return result;
	}

	public async requestOrder(orders: Array<OrderCompleteModel>, customerNo: string): Promise<void> {
		await this.dataApiService.requestOrder(orders);

		this.reloadOrders(customerNo) // non blocking on purpose
	}

	public async completeOrder(orders: Array<OrderCompleteModel>, customerNo: string): Promise<void> {
		await this.dataApiService.completeOrder(orders);

		this.reloadOrders(customerNo) // non blocking on purpose
	}

	public async createNewOrder(order: OrderCreateModel, customerNo: string, skipOrderReload: boolean = false): Promise<number> {
		const result = await this.dataApiService.createNewOrder(order);

		await this.customerDataService.reloadActiveCustomersInStore();

		//vi skal lave skip reload Option. Skal bruges i flere tilfælde!!!!
		if (!skipOrderReload) {
			this.reloadOrders(customerNo) // non blocking on purpose
		}

		return result;
	}

	public async postOrderItem(orderItemCreateModel: OrderItemState, skipOrderReload: boolean = false): Promise<OrderItemState> {
		this.mutexReleaser = await this.ordersDataMutex.acquire();

		try {
			await this.lowTempleInclinationCheckOnCreate(orderItemCreateModel);

			const result = await this.orderItemService.postItem(orderItemCreateModel);

			if (!skipOrderReload) {
				this.reloadOrders(orderItemCreateModel.CustomerNo) // non blocking on purpose
			}

			return result;
		}
		finally {
			this.mutexReleaser();
		}
	}

	public async postMiscOrderItem(orderItemMiscModel: OrderItemMiscModel) {
		this.mutexReleaser = await this.ordersDataMutex.acquire();

		try {
			const result = await this.dataApiService.postMiscItem(orderItemMiscModel);

			this.reloadOrders(orderItemMiscModel.CustomerNo) // non blocking on purpose

			return result;
		}
		finally {
			this.mutexReleaser();
		}
	}

	public async postMiscItemFromState(orderItemMiscModelState: OrderItemMiscState, skipOrderReload: boolean = false) {
		this.mutexReleaser = await this.ordersDataMutex.acquire();

		try {
			const result = await this.dataApiService.postMiscItemFromState(orderItemMiscModelState);

			if (!skipOrderReload) {
				this.reloadOrders(orderItemMiscModelState.CustomerNo) // non blocking on purpose
			}

			return result;
		}
		finally {
			this.mutexReleaser();
		}
	}

	private async lowTempleInclinationCheckOnCreate(orderItemCreateModel: OrderItemState) {
		if (orderItemCreateModel.LowTempleInclinationPossible !== undefined
			&& !orderItemCreateModel.LowTempleInclinationPossible
			&& orderItemCreateModel.TempleInclination === TempleInclinationEnum.low) {
			await this.logInvalidTempleInclination(orderItemCreateModel, orderItemCreateModel.OrderId, 'onCreate')
			orderItemCreateModel.TempleInclination = TempleInclinationEnum.standard;
		}
	}

	private async lowTempleInclinationCheckOnEdit(orderItem: OrderItemModel) {
		if (orderItem.LowTempleInclinationPossible !== undefined
			&& !orderItem.LowTempleInclinationPossible
			&& orderItem.TempleInclination === TempleInclinationEnum.low) {
			await this.logInvalidTempleInclination(orderItem, orderItem.OrderId, 'onEdit')
			orderItem.TempleInclination = TempleInclinationEnum.standard;
		}
	}

	private async logInvalidTempleInclination(orderItem, orderId, context) {
		let logEvent = {
			message: `OrderItem contained invalid temple inclination (${context})`,
			extraPropertiesToLog: {
				['templeInclinationError']: JSON.stringify(orderItem)
			},
			logLevel: LogLevelEnum.Warning,
			eventId: `OrderId [${orderId}]`
		}

		this.loggerService.sendClientLog(logEvent);
	}

	public async getCustomerOrderRelevantData(customerNo: string): Promise<CustomerOrderRelevantModel> {
		const orderOnUser = await this.getFromOrdersStore(customerNo);

		let orderRelevantData: CustomerOrderRelevantModel;

		if (orderOnUser) {
			orderRelevantData = new CustomerOrderRelevantModel(orderOnUser);
		}
		else {
			try {
				orderRelevantData = await this.getCustomerOrderRelevantData(customerNo);
			}
			catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					return new CustomerOrderRelevantModel(null);
				}
				else {
					throw error;
				}
			}
		}

		return orderRelevantData;
	}

	public async getOrderIds(customerNo: string): Promise<Array<OrderIdsModel>> {
		const orders = await this.getFromOrdersStore(customerNo);

		let orderIdsModel: Array<OrderIdsModel> = [];

		if (orders) {
			orderIdsModel = orders.map(o => new OrderIdsModel(o));
		}
		else {
			orderIdsModel = await this.dataApiService.getOrderIds(customerNo);
		}

		return orderIdsModel;
	}

	public async getOrdersData(customerNo: string): Promise<Array<OrderState>> {
		this.mutexReleaser = await this.ordersDataMutex.acquire();

		try {
			const orders = await this.getFromOrdersStore<Array<OrderState>>(customerNo);
			return orders;
		}
		finally {
			this.mutexReleaser();
		}
	}

	public async addOrderItemToOrderStore(newOrderItem: OrderItemState) {
		const currentOrders = await this.getOrdersOnCustomerFromStore(newOrderItem.CustomerNo);

		if (currentOrders) {
			const order = currentOrders.find(ord => ord.Id === newOrderItem.OrderId);

			if (order) {
				order.OrderItems.push(newOrderItem);

				await this.createOrdersOnCustomerInStore(currentOrders, newOrderItem.CustomerNo);
			}
		}
	}

	public async addMiscOrderItemToOrderStore(newMiscOrderItem: OrderItemMiscState) {
		const currentOrders = await this.getOrdersOnCustomerFromStore(newMiscOrderItem.CustomerNo);

		if (currentOrders) {
			const order = currentOrders.find(ord => ord.Id === newMiscOrderItem.OrderId);

			if (order) {
				order.OrderItemMiscs.push(newMiscOrderItem);

				await this.createOrdersOnCustomerInStore(currentOrders, newMiscOrderItem.CustomerNo);
			}
		}
	}

	public async getOrders(customerNo: string, customer: CustomerModel, consultantDeliveryEmail: string): Promise<OrdersModel> {
		let orders: OrderState[];
		orders = await this.dataApiService.getOrders(customerNo);
		let ordersFromStorage = await this.getOrdersData(customerNo);

		if (orders.length !== ordersFromStorage.length) {
			orders = await this.reloadOrders(customerNo);
		}

		if (orders.length > 0) {
			var isConsultant = customer != null;

			if (isConsultant && consultantDeliveryEmail.indexOf(',') > 0) {
				consultantDeliveryEmail = consultantDeliveryEmail.split(',')[0];
			}

			let emailAddresses: Array<string> = [consultantDeliveryEmail];

			if (customer && customer.EmailAddresses) {
				for (let index = 0; index < customer.EmailAddresses.length; index++) {
					const customerEmailAddress = customer.EmailAddresses[index];

					if (emailAddresses.indexOf(customerEmailAddress) < 0) {
						emailAddresses.push(customerEmailAddress);
					}
				}
			}

			for (let index = 0; index < orders.length; index++) {
				const order = orders[index];

				let emailAddressesOnOrder = order?.CheckedOrderConfirmationEmailAddresses;

				let confirmationEmails: Array<IOrderConfirmationEmail> = emailAddresses.map(x =>
					({ Email: x, Checked: emailAddressesOnOrder?.some(t => t == x) })
				);

				if (!emailAddressesOnOrder || emailAddressesOnOrder[0] === '') {
					//When CheckedOrderConfirmationEmailAddresses is null or empty string => Brand new order => Set all email adresses to checked
					confirmationEmails = emailAddresses.map(x =>
						({ Email: x, Checked: emailAddressesOnOrder && emailAddressesOnOrder[0] === '' ? false : true })
					);
				}

				order.ConfirmationEmails = confirmationEmails;
				order.CheckedOrderConfirmationEmailAddresses = confirmationEmails?.filter(t => t.Checked).map(c => c.Email);
				order.CustomerEmail = order?.CheckedOrderConfirmationEmailAddresses?.filter(e => e).join(',')
			}
		}

		const miscItems = await this.modelsService.getMiscModels(true);

		for (let index = 0; index < orders.length; index++) {
			let currentOrder = orders[index];

			currentOrder.OrderItems.forEach(y => {
				y.SyncState = SyncStateEnum.SyncedWithDatabase
			})

			currentOrder.OrderItemMiscs.forEach(y => {
				y.MiscModel = miscItems.find(item => item.EcommerceNoUnique == y.EcommerceNoUnique);
				y.SyncState = SyncStateEnum.SyncedWithDatabase
			})

			const salesPersonsCustomers = (await this.customerDataService.getCustomersData(true)).filter(c => c.No === currentOrder.CustomerNo);

			if (salesPersonsCustomers.length) {
				currentOrder.SalesDistrictType = salesPersonsCustomers[0].SalesDistrictType;
			}

			const orderItemsFromIndexedDb = await this.queueItemService.getQueuedOrderItemsFromOrder(customerNo, currentOrder.Id);

			if (orderItemsFromIndexedDb?.length > 0) {
				currentOrder.OrderItems = currentOrder.OrderItems.concat(orderItemsFromIndexedDb.filter(oi => oi !== null && oi.SyncState !== SyncStateEnum.SyncedWithDatabase));
			}

			const orderMiscItemsFromIndexedDb = await this.queueItemService.getQueuedMiscOrderItemsFromOrder(customerNo, currentOrder.Id);

			if (orderMiscItemsFromIndexedDb?.length > 0) {
				currentOrder.OrderItemMiscs = currentOrder.OrderItemMiscs.concat(orderMiscItemsFromIndexedDb.filter(oi => oi !== null && oi.SyncState !== SyncStateEnum.SyncedWithDatabase));
			}
		}

		const queuedOrderItemsWithoutOrder = await this.queueItemService.getQueuedOrderItemsFromOrder(customerNo, WhichOrderEnum.ChooseFirstOrder);
		const queuedMiscItemsWithoutOrder = await this.queueItemService.getQueuedMiscOrderItemsFromOrder(customerNo, WhichOrderEnum.ChooseFirstOrder);

		if (queuedOrderItemsWithoutOrder?.length > 0 || queuedMiscItemsWithoutOrder?.length > 0) {
			var virtualOrder = new OrderState();
			virtualOrder.Virtual = true;
			virtualOrder.OrderItems = queuedOrderItemsWithoutOrder ? queuedOrderItemsWithoutOrder : [];
			virtualOrder.OrderItemMiscs = queuedMiscItemsWithoutOrder ? queuedMiscItemsWithoutOrder : [];
			orders.push(virtualOrder);
		}

		const queuedOrderItemsNewOrder = await this.queueItemService.getQueuedOrderItemsFromOrder(customerNo, WhichOrderEnum.CreateNewOrder);
		const queuedMiscItemsNewOrder = await this.queueItemService.getQueuedMiscOrderItemsFromOrder(customerNo, WhichOrderEnum.CreateNewOrder);

		queuedOrderItemsNewOrder?.forEach(item => {
			var virtualOrder = new OrderState();
			virtualOrder.Virtual = true;
			virtualOrder.OrderItems = [item]
			virtualOrder.OrderItemMiscs = [];
			orders.push(virtualOrder);
		});

		queuedMiscItemsNewOrder?.forEach(item => {
			var virtualOrder = new OrderState();
			virtualOrder.Virtual = true;
			virtualOrder.OrderItems = [];
			virtualOrder.OrderItemMiscs = [item]
			orders.push(virtualOrder);
		});

		const orderResult = orders.map(x => new Order(plainToClass(OrderState, x)));

		const orderitemsCollapsedMap = this.sessionService.get<Array<{ uuid: string, collapsed: boolean }>>('orderitemsCollapsedMap');
		const isOrderingForCustomer = customer != null;

		orderResult.forEach(order => {
			order.OrderItems.forEach(bagItem => {
				if (bagItem.getState() != null) {
					const element = orderitemsCollapsedMap?.find(x => x.uuid == bagItem.Uuid);
					const elementSync = orderitemsCollapsedMap?.find(x => x.uuid == bagItem.SyncOrderItemGuid);

					if (element?.uuid) {
						bagItem.Collapsed = element.collapsed;
					}
					else if (elementSync?.uuid) {
						bagItem.Collapsed = elementSync.collapsed;
					}
					else if (bagItem.SyncState !== SyncStateEnum.SyncedWithDatabase && isOrderingForCustomer && !(bagItem.Engraving || bagItem.Reference
						|| bagItem.Comment || bagItem.HasForms || bagItem.HasFixtures)) {
						bagItem.Collapsed = true;
					}
					else if (bagItem.IsFullFrame && isOrderingForCustomer && !(bagItem.Engraving || bagItem.Reference
						|| bagItem.Comment || bagItem.containsDiscount() || bagItem.HasForms || bagItem.HasFixtures)) {

						bagItem.Collapsed = true;
					}

					if (!bagItem.Price && bagItem.PriceJson) {
						const priceResponse: PriceItem = new PriceItem(bagItem.PriceJson);

						if (priceResponse) {
							priceResponse.isDoingPriceQuery = false;
							bagItem.Price = priceResponse;
						}
					}
				}
			});

			order.OrderItemMiscs.forEach(miscItem => {
				if (!miscItem.Price && miscItem.PriceJson) {
					const priceResponse: PriceItem = new PriceItem(miscItem.PriceJson);
					priceResponse.isDoingPriceQuery = false;
					miscItem.Price = priceResponse;
				}
			});
		});

		return new OrdersModel(orderResult);
	}

	public async reloadOrders(customerNo: string) {
		await this.removeOrdersOnCustomerInStore(customerNo);

		this.orderDataChanged.next(customerNo);

		const orders = await this.getOrdersData(customerNo);

		return orders;
	}

	public async updatePrice(frameOrderItem: OrderItemModel, orderItemMisc: OrderItemMiscModel): Promise<PriceResponse> {
		let priceResponse: PriceResponse

		if (frameOrderItem) {
			priceResponse = (await this.dataApiService.getPrice(OrderItemType.OrderItem, frameOrderItem.Uuid));
			let priceItem = new PriceItem(JSON.stringify(priceResponse.Data?.ResponseItems[0]));
			frameOrderItem.Price = priceItem;

			if (frameOrderItem.Price?.priceFound) {
				await this.orderItemService.editOrderItem(frameOrderItem);
				await this.reloadOrders(frameOrderItem.CustomerNo) // non blocking on purpose
			}
		}
		else if (orderItemMisc) {
			priceResponse = (await this.dataApiService.getPrice(OrderItemType.OrderItemMisc, orderItemMisc.Uuid));
			let priceItem = new PriceItem(JSON.stringify(priceResponse.Data?.ResponseItems[0]));
			orderItemMisc.Price = priceItem;

			if (orderItemMisc.Price?.priceFound) {
				orderItemMisc.Price.lookupTimestamp = new Date();
				await this.orderItemService.editOrderMiscItem(orderItemMisc);
				await this.reloadOrders(orderItemMisc.CustomerNo) // non blocking on purpose
			}
		}

		return priceResponse;
	}

	private getOrdersKey(customerNo: string) {
		if (customerNo) {
			return OrdersDataService.ordersStoreName + '_' + customerNo;
		}
		else {
			return OrdersDataService.ordersStoreName + '_';
		}
	}

	private async getOrdersOnCustomerFromStore(customerNo: string): Promise<OrderState[]> {
		let key = this.getOrdersKey(customerNo);
		return await this.indexedDbService.get<any>(OrdersDataService.ordersStoreName, key);
	}

	private async createOrdersOnCustomerInStore(orders: Array<OrderState>, customerNo: string): Promise<void> {
		let key = this.getOrdersKey(customerNo);
		await this.indexedDbService.replaceAndSave(OrdersDataService.ordersStoreName, orders, key);
	}

	public async removeOrdersOnCustomerInStore(customerNo: string): Promise<void> {
		this.mutexReleaser = await this.ordersDataMutex.acquire();

		try {
			let key = this.getOrdersKey(customerNo);
			await this.indexedDbService.clear(OrdersDataService.ordersStoreName, key);
		}
		finally {
			this.mutexReleaser();
		}
	}

	public async getFromOrdersStore<T>(customerNo: string): Promise<OrderState[]> {
		let result = await this.getOrdersOnCustomerFromStore(customerNo);

		if (!result || result.length === 0) {
			try {
				result = await this.dataApiService.getOrders(customerNo);
				await this.createOrdersOnCustomerInStore(result, customerNo);
			}
			catch (error) {
				// ignore offline error
			}
		}

		return result;
	}

	async getLimitedEditionData() {
		let result: FMResponseModel<LimitedEditionFieldDataModel> = await this.offlineService.getDataWithOfflineCheck<FMResponseModel<LimitedEditionFieldDataModel>>(`${await this.env.baseUrl()}/limitededitionprecioustemple`);
		return result;
	}
}
