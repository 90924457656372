import { Injectable } from '@angular/core';
import { StartkitMappingResponse } from '@models/api-models/startkit-mapping-response-model';
import { StartkitMapping } from '@models/api-models/startkit-mapping.interface';
import { CustomiserApiService } from './customiser-api.service';

@Injectable({
	providedIn: 'root'
})
export class StartkitMappingService {

	constructor(
		private customiserApi: CustomiserApiService
	) { }

	public async postStartkitMapping(startkitMapping: StartkitMapping) {
		return await this.customiserApi.postJson('StartkitMapping/updateOrCreateStartkitMapping', startkitMapping);
	}

	public async getStartkitMappings(customerNo: string) {

		return await this.customiserApi.get<StartkitMappingResponse[]>(`StartkitMapping/getStartkitMapping?customerNo=${customerNo}`);

	}

	public async deleteStartkitMapping(customerNo: string, productLineCode: string) {

		return await this.customiserApi.delete<StartkitMappingResponse[]>(`StartkitMapping/deleteStartkitMapping?customerNo=${customerNo}&productLineCode=${productLineCode}`);

	}

	public async updateOrderedStatus(customerNo: string, productLineCode: string, ordered: boolean) {

		const body = {
			customerNo, productLineCode, ordered
		}

		return await this.customiserApi.patch<StartkitMappingResponse[]>(`StartkitMapping/updateOrderedStatus`, body);

	}
}

