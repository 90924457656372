import { ComponentVariantFromApiModel } from "@models/api-models/component-variant-fromapi";
import { BlockBindingsDependencyCalculator } from "./block/block-bindings-dependency-calculator";
import { ColorJunctionDependencyCalculator } from "./color-junction/color-junction-dependency-calculator";
import { FindNewChangeByColorDependencyCalculator } from "./data-finder/find-new-change-by-color-dependency-calculator";
import { FindNewChangeByComponentDependencyCalculator } from "./data-finder/find-new-change-by-component-dependency-calculator";
// import { FindNewChangeByFinishDependencyCalculator } from "./data-finder/find-new-change-by-finish-dependency-calculator";
import { FindNewChangeBySizeDependencyCalculator } from "./data-finder/find-new-change-by-size-dependency-calculator";
import { FindNewChangeByVariantDependencyCalculator } from "./data-finder/find-new-change-by-variant-dependency-calculator";
import { DependencyCalculator } from "./dependency-calculator";
import { IDependencyCalculator } from "./dependency-calculator.interface";
import { DisableVariantsFromApiDependencyCalculator } from "./disable-dependency-calculator";
import { PermitOnlyBindingsDependencyCalculator } from "./permit-only/permitonly-bindings-dependency-calculator";

export class CustomizerDependencyCalculatorFactory {

	static getDependencyCalculator(
		getChooseableVariant: (componentUuid: string, variantUuid: string, variantSizeUuid: string) => Promise<Array<ComponentVariantFromApiModel>>
	) {
		const dependencyCalculators = new Array<IDependencyCalculator>(
			new ColorJunctionDependencyCalculator(getChooseableVariant),
			new PermitOnlyBindingsDependencyCalculator(
				[
					new FindNewChangeByColorDependencyCalculator(getChooseableVariant),
					// new FindNewChangeByFinishDependencyCalculator(getChooseableVariant),
					new FindNewChangeBySizeDependencyCalculator(getChooseableVariant),
					new FindNewChangeByVariantDependencyCalculator(getChooseableVariant),
					new FindNewChangeByComponentDependencyCalculator()
				]
			),
			new BlockBindingsDependencyCalculator(
				[
					new FindNewChangeByColorDependencyCalculator(getChooseableVariant),
					// new FindNewChangeByFinishDependencyCalculator(getChooseableVariant),
					new FindNewChangeBySizeDependencyCalculator(getChooseableVariant),
					new FindNewChangeByVariantDependencyCalculator(getChooseableVariant),
					new FindNewChangeByComponentDependencyCalculator()
				]
			)
		);
		return new DependencyCalculator(dependencyCalculators);
	}

	static getDisableCalculator(variants: Array<ComponentVariantFromApiModel>) {
		const calculator = new PermitOnlyBindingsDependencyCalculator(
			[
				new DisableVariantsFromApiDependencyCalculator(variants)
			]
		);

		const calculator2 = new BlockBindingsDependencyCalculator(
			[
				new DisableVariantsFromApiDependencyCalculator(variants)
			]
		);

		return new DependencyCalculator([calculator, calculator2]);
	}

}
