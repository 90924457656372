import { Type } from "class-transformer";
import { IOrderConfirmationEmail } from './order-confirmation-email';
import { OrderItemMiscState } from "./order-item-misc-model";
import { OrderItemState } from "./order-item-state";
import { OrderStatus } from './order-status';
import { SalesDistrictType } from './sales-district';

export class OrderState {

	public Id: number;
	public Uuid: string;
	public TestOrder: boolean;
	public CustomerNo: string;
	public CustomerName: string;
	public Alias: string;
	public CustomerEmail: string;
	public AxId: number;
	public Notes: string;
	public NotesCustomerService: string;
	public Status: OrderStatus;

	@Type(() => OrderItemState)
	public OrderItems: Array<OrderItemState>;

	@Type(() => OrderItemMiscState)
	public OrderItemMiscs: Array<OrderItemMiscState>;
	public Address: string;
	public FullAddress: string;
	public ShipToName: string;
	public ConsultantUuid: string;

	@Type(() => Date)
	public VisitDate: Date;
	@Type(() => Date)
	public EarliestDeliveryDate: Date;

	@Type(() => Date)
	public CompletedDate: Date;
	public ShipToId: number;

	public FairName: string;
	public FairId: number;
	AllowPartialShipment: boolean;
	PaymentTerms: string;
	public NoVisit: boolean;
	public IsNewPartner: boolean;
	public SalesDistrictType: SalesDistrictType;
	public ConfirmationEmails: Array<IOrderConfirmationEmail>;
	public CheckedOrderConfirmationEmailAddresses: Array<string>;
	public CheckedOrderConfirmationEmailAddressesString: string;
	public Virtual: boolean;
	public ShowOrderConfirmation: boolean;

	public Signature: string;
}
