import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output, QueryList, ViewChild } from '@angular/core';

import { InputAddonComponent } from './../input-addon/input-addon.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'ui-input',
    styleUrls: ['./input.component.scss'],
    template: `
        <ng-content select="[pre]"></ng-content>
        <input #input [ngModel]="value" [disabled]="disabled" (ngModelChange)="valueChanged($event)" [min]="min" [max]="max" [pattern]="pattern" [maxlength]="maxlength" [type]="type" class="form-control" />
        <ng-content></ng-content>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent implements AfterContentInit {
    @HostBinding('class.input-group') hasAddon = false;
    @HostBinding('class.has-error') @Input() hasError = false;
    @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;

    @ContentChildren(InputAddonComponent) content: QueryList<InputAddonComponent>;

    @Input() type: 'text'|'number' = 'text';
    @Input() value: any;
    @Input() placeholder: string;
    @Input() maxlength: number;
    @Input() disabled: boolean;
    @Input() pattern: string;
    @Input() min: number;
    @Input() max: number;

    @Output() valueChange = new EventEmitter<any>();

    /**
     * Tells the textfield inside to have focus.
     * This bypasses the @disabled input due to safari not wanting to play along nicely.
     */
    @HostListener('focus') setFocus() {
        // kinda a hack because of safari.
        // we can't wait for disabled change to false and update the UI,
        // because then it would count as an async operation
        // which makes safari go: Uhm nah, imma pass this one.
        this.inputRef.nativeElement.disabled = false;
        this.inputRef.nativeElement.focus();
    }

    public debouncer: Subject<string> = new Subject<string>();

    ngAfterContentInit(): void {
        if(this.content.length) {
            this.hasAddon = true;
        }

        this.debouncer
        .pipe(debounceTime(800))
        .subscribe((value) => this.valueChange.emit(value));
    }

    valueChanged(value) {
        this.debouncer.next(value);
    }
}
