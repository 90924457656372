import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';


export class ComponentVariantGroup {
	name: string;
	isExpanded: boolean;
	selectedDesign: ComponentVariantFromApiModel;
	allDesigns: Array<ComponentVariantFromApiModel> = [];
	designs: Array<ComponentVariantFromApiModel> = [];
}
