import { Component, Input, OnInit } from '@angular/core';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { PriceItem } from '@models/price-model';
import { PriceSettingModel } from '@models/price-setting.model';
import { LanguageService } from '@services/language.service';
import { AppState } from '@shared/app-state';

@Component({
  selector: 'app-price-viewer',
  templateUrl: './price-viewer.component.html',
  styleUrls: ['./price-viewer.component.scss']
})
export class PriceViewerComponent implements OnInit {

	@Input() priceItem: PriceItem;

	showNetPrice: boolean;
	showSuggestedRetailPrice: boolean;
	showBoth: boolean;
	currentLanguage: string;

	constructor(
		private appState: AppState,
		private languageService: LanguageService
	) {}

	ngOnInit() {
		this.appState.priceSetting.subscribe(ps => {
			this.applySettings(ps);
		});

		let x: PriceSettingModel = this.appState.priceSetting.getValue();
		this.applySettings(x);
		
		this.currentLanguage = this.languageService.currentLanguage
	}

	applySettings(x: PriceSettingModel) {
		this.showNetPrice = x.PriceSetting === PriceSettingEnum.AllowNetPrice;
		this.showSuggestedRetailPrice = x.PriceSetting === PriceSettingEnum.AllowSuggestedRetailPrice;
		this.showBoth = x.PriceSetting === PriceSettingEnum.AllowAll;
	}
}
