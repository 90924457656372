<div class="center">
        <div class="center-items">
            <div *ngIf="!DemoModelInfo && !EnduserModelInfo">
                <div [innerHTML]="'COMMON.NOTAVAILABLE' | translate: { modelCode: ModelCodeNotAvailable ? ModelCodeNotAvailable + ' ' : ''}">
                </div>
                <br />
                <div [innerHTML]="'COMMON.NOTAVAILABLECONTACT' | translate">
                </div>
            </div>

            <div *ngIf="DemoModelInfo">
                <p class="font-medium">{{ 'COMMON.FORDISPLAY' | translate }}</p>
                <br />
                <p>{{ 'COMMON.FORDISPLAYIINFO' | translate }}</p>
                <p>{{ 'COMMON.FORDISPLAYIINFO2' | translate }}</p>
                <br />
                <p>{{ 'COMMON.FORDISPLAYIINFO3' | translate }}</p>
            </div>

            <div *ngIf="EnduserModelInfo">
                <p class="font-medium">{{ 'COMMON.FORENDUSER' | translate }}</p>
                <br />
                <p>{{ 'COMMON.FORENDUSERINFO' | translate }}</p>
                <p>{{ 'COMMON.FORENDUSERINFO2' | translate }}</p>
                <br />
                <p>{{ 'COMMON.FORENDUSERINFO3' | translate }}</p>
            </div>
        </div>
</div>