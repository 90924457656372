import { BrandEnum } from "@infrastructure/brand.enum";
import { Type } from "class-transformer";
import { ProductlineConstants } from './../shared/productline-constants';
import { ModelModel } from './api-models/model';
import { ModelgroupOrderApimodel } from "./api-models/modelgroup-order-apimodel";
import { AssortmentLineModel } from './assortment-line-model';
import { AvailabilityModel } from './availability-model';
import { DataTranslationModel } from './data-translation-model';
import { OrderItemModel } from "./order-item";

export class ModelGroupModel extends AvailabilityModel {
    Id: number;
    Uuid: string;
    Code: string;

    @Type(() => DataTranslationModel)
    Description: DataTranslationModel;

    @Type(() => DataTranslationModel)
    ShortDescription: DataTranslationModel;
    KidTeen: boolean;
    Status: number;
    IsRimless: boolean;
    SortDescription: string;
    FullFrame: boolean;
    ParentModelGroupId?: number;
    CanOrder: boolean;
    ProductLineCode: string;
    @Type(() => DataTranslationModel)
    ProductLineDescription: DataTranslationModel;
    ProductLineUuid: string;
    ParentModelGroupUuid?: string;

    @Type(() => DataTranslationModel)
    ParentModelGroupDescription: DataTranslationModel;

    @Type(() => DataTranslationModel)
    ParentModelGroupShortDescription: DataTranslationModel;
    HasForms: boolean;
    HasFixtures: boolean;

    @Type(() => ModelGroupModel)
    ModelGroupChildren: Array<ModelGroupModel>;

    @Type(() => AssortmentLineModel)
    AssortmentLines: Array<AssortmentLineModel>;

    @Type(() => ModelModel)
    DefaultModel: ModelModel;
    Brand: BrandEnum;
    
    @Type(() => DataTranslationModel)
    PreciousDescription: DataTranslationModel;
    HitCount: number;
    Selected: boolean;
    get getModelGroupShortDescription(): DataTranslationModel {
        return this.ShortDescription.Fallback ? this.ShortDescription: this.Description;
    }

    public get IsConfigurationBased() {
        return this.ProductLineCode == ProductlineConstants.ACETANIUM;
    }

    public get IsBothConfigAndVariantBased() {
        return this.ProductLineCode == ProductlineConstants.SUN;
    }

    public get modelGroupDataUuid() {
        return this.ParentModelGroupUuid || this.Uuid; // If modelgroup has a parent, the parent is responsible for the data
    }

    public get modelGroupId() {
        return this.ParentModelGroupId || this.Id; // If modelgroup has a parent, the parent is responsible for the data
    }

    public get FullFrameChildModelGroup() {
        return this.ModelGroupChildren.find(x => x.FullFrame);
    }

    public static createCopy(fromModel: ModelGroupModel): ModelGroupModel {
        if(fromModel) {
            const newModel = new ModelGroupModel(fromModel.Discontinued ? new Date(fromModel.Discontinued) : null, fromModel.New);
            newModel.Id = fromModel.Id;
            newModel.Uuid = fromModel.Uuid;
            newModel.Code = fromModel.Code;
            newModel.Description = fromModel.Description ? new DataTranslationModel(fromModel.Description.TranslationKey, fromModel.Description.Fallback) : null;
            newModel.ShortDescription = fromModel.ShortDescription ? new DataTranslationModel(fromModel.ShortDescription.TranslationKey, fromModel.ShortDescription.Fallback) : null;
            newModel.KidTeen = fromModel.KidTeen;
            newModel.Status = fromModel.Status;
            newModel.IsRimless = fromModel.IsRimless;
            newModel.SortDescription = fromModel.SortDescription;
            newModel.FullFrame = fromModel.FullFrame;
            newModel.ParentModelGroupId = fromModel.ParentModelGroupId;
            newModel.CanOrder = fromModel.CanOrder;
            newModel.ProductLineCode = fromModel.ProductLineCode;
            newModel.ProductLineDescription = fromModel.ProductLineDescription ? new DataTranslationModel(fromModel.ProductLineDescription.TranslationKey, fromModel.ProductLineDescription.Fallback) : null;
            newModel.ProductLineUuid = fromModel.ProductLineUuid;
            newModel.ParentModelGroupUuid = fromModel.ParentModelGroupUuid;
            newModel.ParentModelGroupDescription = fromModel.ParentModelGroupDescription ? new DataTranslationModel(fromModel.ParentModelGroupDescription.TranslationKey, fromModel.ParentModelGroupDescription.Fallback) : null;
            newModel.ParentModelGroupShortDescription = fromModel.ParentModelGroupShortDescription ? new DataTranslationModel(fromModel.ParentModelGroupShortDescription.TranslationKey, fromModel.ParentModelGroupShortDescription.Fallback) : null;
            newModel.HasForms = fromModel.HasForms;
            newModel.HasFixtures = fromModel.HasFixtures;
            newModel.Brand = fromModel.Brand;
            newModel.PreciousDescription = fromModel.PreciousDescription ? new DataTranslationModel(fromModel.PreciousDescription.TranslationKey, fromModel.PreciousDescription.Fallback) : null;

            newModel.ModelGroupChildren = fromModel.ModelGroupChildren?.map(x => ModelGroupModel.createCopy(x));
            newModel.AssortmentLines = fromModel.AssortmentLines?.map(x => AssortmentLineModel.createCopy(x));
            newModel.DefaultModel = ModelModel.createCopy(fromModel.DefaultModel);

            return newModel;
        }
        else {
            return null;
        }

    }

    public static mapFromApiModel(fromModel: ModelgroupOrderApimodel): ModelGroupModel {
        if(fromModel) {
            const newModel = new ModelGroupModel(fromModel.Discontinued ? new Date(fromModel.Discontinued) : null, fromModel.New);
            newModel.Id = fromModel.Id;
            newModel.Uuid = fromModel.Uuid;
            newModel.Code = fromModel.Code;
            newModel.Description = fromModel.Description ? new DataTranslationModel(fromModel.Description.TranslationKey, fromModel.Description.Fallback) : null;
            newModel.ShortDescription = fromModel.ShortDescription ? new DataTranslationModel(fromModel.ShortDescription.TranslationKey, fromModel.ShortDescription.Fallback) : null;
            newModel.KidTeen = fromModel.KidTeen;
            newModel.Status = fromModel.Status;
            newModel.IsRimless = fromModel.IsRimless;
            newModel.SortDescription = fromModel.SortDescription;
            newModel.FullFrame = fromModel.FullFrame;
            newModel.ParentModelGroupId = fromModel.ParentModelGroupId;
            newModel.ParentModelGroupUuid = fromModel.ParentModelGroupUuid;
            newModel.ProductLineCode = fromModel.ProductLineCode;
            newModel.HasFixtures = fromModel.HasFixtures;
            newModel.HasForms = fromModel.HasForms;

            newModel.AssortmentLines = fromModel.AssortmentLines?.map(x => AssortmentLineModel.createCopy(x));

            return newModel;
        }
        else {
            return null;
        }
    }
    public static mapFromStoreModel(fromModel: OrderItemModel): ModelGroupModel {
        if(fromModel) {
            const newModel = new ModelGroupModel(null, fromModel.New);
            newModel.Id = fromModel.Id;
            newModel.Uuid = fromModel.Uuid;
            newModel.Code = fromModel.ModelCode;
            newModel.IsRimless = fromModel.IsRimless;
            newModel.FullFrame = fromModel.IsFullFrame;
            newModel.ProductLineCode = fromModel.ProductLineCode;
            newModel.HasFixtures = fromModel.HasFixtures;
            newModel.HasForms = fromModel.HasForms;
            newModel.Brand = fromModel.Brand;

            return newModel;
        }
        else {
            return null;
        }
    }
    
}
