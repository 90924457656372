import { Injectable } from '@angular/core';
import { CountryModel } from '@models/country-model';
import { CustomerModel } from '@models/customer';
import { OrderInfoModel } from '@models/order-info.model';
import { HttpOfflineError } from '@shared/http-offline-error';
import { plainToClass } from 'class-transformer';
import { Subject } from 'rxjs';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { OfflineService } from './offline.service';
import { StoreService } from './store.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class CustomerDataService {

	public customersWithOrdersDataChanged = new Subject<Array<CustomerModel>>();

	constructor(
		private indexedDbService: IndexDbService,
		private userService: UserService,
		private offlineService: OfflineService,
		private env: EnvService,
		private storeService: StoreService
	) {
	}

	public static readonly customerStoreName = "customers";
	private readonly CustomersKey = "customers";

	private getCustomersKey(onlyCustomersWithOrders: boolean) {
		return this.CustomersKey + "OnlyCustomersWithOrders-" + onlyCustomersWithOrders
	}

	private getNewCustomersKey() {
		return this.CustomersKey + "NewCustomers"
	}

	private async getCustomersFromStore(onlyCustomersWithOrders: boolean): Promise<Array<CustomerModel>> {
		let key = this.getCustomersKey(onlyCustomersWithOrders);
		return await this.indexedDbService.get<any>(CustomerDataService.customerStoreName, key);
	}

	public async createCustomersInStore(customers: Array<CustomerModel>, onlyCustomersWithOrders: boolean): Promise<void> {
		let key = this.getCustomersKey(onlyCustomersWithOrders);
		await this.indexedDbService.replaceAndSave(CustomerDataService.customerStoreName, customers, key);
	}

	public async createNewCustomerInStore(customer: CustomerModel): Promise<void> {
		let key = this.getNewCustomersKey();
		let existingCustomers = await this.getNewCustomersFromStore();

		if (!existingCustomers) {
			existingCustomers = [];
		}

		let matchingCustomer = existingCustomers?.filter(c => c.Name === customer.Name && (c.Address === customer.Address || !c.Address));

		if (matchingCustomer?.length === 0) {
			existingCustomers.push(customer);
		}

		await this.indexedDbService.replaceAndSave(CustomerDataService.customerStoreName, existingCustomers, key);
	}
	private async getNewCustomersFromStore(): Promise<Array<CustomerModel>> {
		let key = this.getNewCustomersKey();
		return await this.indexedDbService.get<any>(CustomerDataService.customerStoreName, key);
	}

	public async removeAllCustomersInStore(): Promise<void> {
		await this.removeCustomersInStore(true)
		await this.removeCustomersInStore(false)
		await this.removeNewCustomersInStore()
	}

	public async removeCustomersInStore(onlyCustomersWithOrders: boolean) {
		const key = this.getCustomersKey(onlyCustomersWithOrders);
		await this.indexedDbService.clear(CustomerDataService.customerStoreName, key);
	}

	public async removeNewCustomersInStore() {
		let key = this.getNewCustomersKey();
		await this.indexedDbService.clear(CustomerDataService.customerStoreName, key);
	}

	public async reloadActiveCustomersInStore() {
		await this.removeCustomersInStore(true);
		await this.removeNewCustomersInStore();
		let customers = await this.getCustomersData(true);
		customers = await this.addNewCustomersToList(customers);
		this.customersWithOrdersDataChanged.next(customers);
	}

	public async getCustomersData(onlyCustomersWithOrders: boolean): Promise<Array<CustomerModel>> {

		const user = await this.userService.GetUser();

		if (user.canOrderOnBehalf || user.canOrderOnBehalfOfChainCustomer) {
			const result = await this.getOrSetFromOrdersStore<Array<CustomerModel>>(onlyCustomersWithOrders);
			return result.map(x => plainToClass(CustomerModel, x));
		}
		else {
			return [];
		}
	}

	public async getShopInfoCustomers(): Promise<Array<CustomerModel>> {
		let result = await this._getShopInfoCustomers();
		return result.map(x => plainToClass(CustomerModel, x));
	}

	public async getCountryCodes(): Promise<Array<CountryModel>> {
		let result = await this._getCountryCodes();
		return result.map(x => plainToClass(CountryModel, x));
	}

	public async getNewCustomers(): Promise<Array<CustomerModel>> {
		let result = await this._getNewCustomers();
		return result.map(x => plainToClass(CustomerModel, x));
	}

	public async GetCustomerToken(customerModel: CustomerModel): Promise<string> {
		return (await this.offlineService.postWithOfflineHandling<any>(`${await this.env.baseUrl()}/token/newcustomer`, customerModel)).AccessToken;
	}

	public async getCustomerData(customerNo: string): Promise<CustomerModel> {
		const allCustomers = await this.getCustomersData(false);
		const customer = allCustomers.find(customer => customer.No == customerNo);
		return plainToClass(CustomerModel, customer);
	}

	public async addNewCustomersToList(customers): Promise<Array<CustomerModel>> {
		const user = await this.userService.GetUser();

		if (user.canOrderOnBehalf || user.canOrderOnBehalfOfChainCustomer) {
			const newCustomers = await this.getNewCustomers();

			if (newCustomers && newCustomers.length > 0) {
				newCustomers.forEach(newCustomer => {
					if (customers.filter(c => c.No === newCustomer.No).length === 0) {
						customers.push(newCustomer);
					}
				});
			}
		}

		return customers;
	}

	private async getOrSetFromOrdersStore<T>(onlyCustomersWithOrders: boolean): Promise<Array<CustomerModel>> {
		let result = await this.getCustomersFromStore(onlyCustomersWithOrders);

		if (!result) {
			result = await this.getCustomers(onlyCustomersWithOrders);

			if (result.length > 0) {
				await this.createCustomersInStore(result, onlyCustomersWithOrders);
			}
		}

		let newCustomer = await this.getNewCustomersFromStore();

		if (newCustomer) {
			result.push(...newCustomer);
		}

		return result;
	}

	public async getCustomers(onlyOnesWithOrderItemsOn: boolean = false, useServerCache: boolean = true): Promise<Array<CustomerModel>> {
		let result: Array<CustomerModel>;

		result = await this.offlineService.getDataWithOfflineCheck<Array<CustomerModel>>(`${await this.env.baseUrl()}/Account/customers/?OnlyWithCurrentOrders=${onlyOnesWithOrderItemsOn}${!useServerCache ? '&useServerCache=false' : ''}`);

		if (!result) {
			throw new HttpOfflineError();
		}

		return result.map(x => plainToClass(CustomerModel, x));
	}

	private async _getShopInfoCustomers(): Promise<Array<CustomerModel>> {
		let result: Array<CustomerModel>;

		result = await this.offlineService.getDataWithOfflineCheck<Array<CustomerModel>>(`${await this.env.baseUrl()}/Account/ShopInfoCustomers`);

		if (!result) {
			throw new HttpOfflineError();
		}

		return result.map(x => plainToClass(CustomerModel, x));
	}

	private async _getNewCustomers(): Promise<Array<CustomerModel>> {
		let result: Array<CustomerModel>;

		result = await this.storeService.getOrSetFromStore<Array<CustomerModel>>(`${await this.env.baseUrl()}/Account/NewCustomers`);

		if (!result) {
			throw new HttpOfflineError();
		}

		return result.map(x => plainToClass(CustomerModel, x));
	}
	private async _getCountryCodes(): Promise<Array<CountryModel>> {
		let result: Array<CountryModel>;

		result = await this.offlineService.getDataWithOfflineCheck<Array<CountryModel>>(`${await this.env.baseUrl()}/Account/CustomersCountryCodes`);

		if (!result) {
			throw new HttpOfflineError();
		}

		return result.map(x => plainToClass(CountryModel, x));
	}

	public async getCustomersOrders(customerNo: string): Promise<Array<OrderInfoModel>> {
		return await this.offlineService.getDataWithOfflineCheck<Array<OrderInfoModel>>(`${await this.env.baseUrl()}/ordersbycustomerno/${customerNo}`);
	}
}
