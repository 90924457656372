import moment from "moment";

/**
 * Convert your year, month and date into a Date() and pass that and the original variables into this method.
 * It will check if they are the same.
 */
export function validateDate(date: Date, chosenYear: number, chosenMonth: number, chosenDay: number): boolean {
    if (date.getFullYear() !== chosenYear) {
        return false;
    } else if (date.getMonth() !== chosenMonth) {
        return false;
    } else if (date.getDate() !== chosenDay) {
        return false;
    } else {
        return true;
    }
}

export function validateYear(date: Date, chosenYear: number) {
    return date.getFullYear() === chosenYear;
}

export function validateMonth(date: Date, chosenMonth: number) {
    return date.getMonth() === chosenMonth;
}

export function validateDay(date: Date, chosenDay: number) {
    return date.getDate() === chosenDay;
}

export function toLocalDateString(date: Date) {
    if(date) {
        return moment(date).hours(0).format("MM-DD-YYYY HH:mm:ss");
    } else {
        return null;
    }
}

export function toDateStringWithUserOffset(date: Date) {
    if(date) {
        return moment(date).toISOString(true);
    } else {
        return null;
    }
}

export function invalidDate(date: Date): boolean {
    return isNaN(date.getTime());
}
