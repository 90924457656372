import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { ModelReponseWithVariantsModelFromApi } from '@models/api-models/ModelReponseWithVariantsModelFromApi';
import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';
import { ModelModel } from '@models/api-models/model';
import { ComponentVariantModel } from '@models/component-variant';
import { ManualDefaultModel } from '@models/manual-default';
import { MiscModel } from '@models/misc-model';
import { ModelGroupModel } from '@models/model-group';
import { ModelSizeMinMaxModel } from '@models/model-size-min-max';
import { TagModel } from '@models/tag';
import { UserDataModel } from '@models/user-data-model';
import { BrandModeService } from '@services/brandmode.service';
import { EnduserDisplayService } from '@services/enduserdisplay.service';
import { EnvService } from '@services/env.service';
import { IndexDbService } from '@services/index-db.service';
import { OfflineService } from '@services/offline.service';
import { StoreService } from '@services/store.service';
import { UserService } from '@services/user.service';
import { ServiceUtils } from '@services/utils/service.utils';
import { PermissionConstants } from '@shared/permission-constants';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { ComponentVariantFromApiFactory } from 'src/_factories/ComponentVariantFromApiFactory';
import { CustomiserApiService } from './customiser-api.service';

@Injectable({
	providedIn: 'root'
})
export class ModelsService {

	private modelDataUrl: string = `/models?requestModel.modelGroupUuid=`;
	private variantDataUrl: string = `/componentvariant?`;
	private modelWithAllDataUrl: string = `/allModelData/`;
	private stockKeepingUnitsEndpoint: string = 'StockKeepingUnits';

	constructor(
		private customiserApi: CustomiserApiService,
		private serviceUtils: ServiceUtils,
		private brandModeService: BrandModeService,
		private userService: UserService,
		private storeService: StoreService,
		private enduserDisplayService: EnduserDisplayService,
		private offlineService: OfflineService,
		private env: EnvService,
		private http: HttpClient,
		private indexedDbService: IndexDbService
	) { }

	public async getModelsByLine(lineId: string) {
		return await this.customiserApi.get<ModelsResponse[]>('models/list?id=' + lineId);
	}

	private async adjustVariantsToCurrentBrand(models: ModelModel[]): Promise<void> {
		models?.map(x => x.DefaultVariantModels = x.DefaultVariantModels.filter(v => v.Brand === this.brandModeService.currentBrandValue()));
	}

	public async getModels(user: UserDataModel, modelGroup: ModelGroupModel, modelUuid: string = null, defaultModel: boolean = false): Promise<Array<ModelModel>> {
		let result = await this.storeService.getOrSetFromStore<Array<ModelModel>>((await this.env.baseUrl()) + this.modelDataUrl + modelGroup.modelGroupDataUuid);

		this.adjustVariantsToCurrentBrand(result);

		if (this.enduserDisplayService.IsDisplay() && modelGroup.IsRimless) {
			result.forEach(model => {
				model.ModelSizeCodeModels = model.ModelSizeCodeModels.filter(x => x.Default);
			});
		}

		if (this.brandModeService.isPrecious) {
			result = result.filter(model => model.Brand == BrandEnum.Precious || model.Brand == BrandEnum.TitaniumAndPrecious);
		}
		else {
			result = result.filter(model => model.Brand == BrandEnum.Titanium || model.Brand == BrandEnum.TitaniumAndPrecious);
		}

		if (modelUuid) {
			result = result.filter(model => model.Uuid == modelUuid);
		}
		else if (defaultModel) {
			result = result.sort((a, b) => b.Order.localeCompare(a.Order));
			result.length = 1;
		}

		if (this.userService.AdminModeEnabled() && user.UserPermissions.some(x => x == PermissionConstants.IsAdmin)) {
			try {
				const allManualDefaultsData = (await this.offlineService.getDataWithOfflineCheck<Array<ManualDefaultModel>>(`${await this.env.baseUrl()}/ModelGroup/${modelGroup.modelGroupId}/ManualDefaults/${this.brandModeService.currentBrandValue()}`));
				const allManualDefaults = allManualDefaultsData.map((x) => plainToClass(ManualDefaultModel, x));

				for (let x of result) {
					const manualDefault = allManualDefaults.find(y => y.ModelId == x.Id && y.Brand === this.brandModeService.currentBrandValue());

					if (manualDefault) {
						x = await this.overwriteWithManualDefaults(x, modelGroup, manualDefault);
					}
				}
			}
			catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					// ignore
				}
				else {
					throw error;
				}
			}
		}

		if (this.userService.AdminModeEnabled() && user.UserPermissions.some(x => x == PermissionConstants.IsAdmin)) {
			try {
				const allManualDefaults = (await this.offlineService.getDataWithOfflineCheck<Array<ManualDefaultModel>>(`${await this.env.baseUrl()}/ModelGroup/${modelGroup.modelGroupId}/ManualDefaults/${this.brandModeService.currentBrandValue()}`)).map((x) => plainToClass(ManualDefaultModel, x));

				for (let x of result) {
					const manualDefault = allManualDefaults.find(y => y.ModelId == x.Id && y.Brand === this.brandModeService.currentBrandValue());
					if (manualDefault) {
						x = await this.overwriteWithManualDefaults(x, modelGroup, manualDefault);
					}
				}
			}
			catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					// ignore
				}
				else {
					throw error;
				}
			}
		}

		const mapResult = result.map((x) => ModelModel.createCopy(x));

		return mapResult;
	}

	private async overwriteWithManualDefaults(model: ModelModel, modelgroup: ModelGroupModel, manualDefault: ManualDefaultModel): Promise<ModelModel> {
		model.DefaultModelConfigurationCode = manualDefault.ModelConfigCode;
		model.DefaultModelSizeId = manualDefault.ModelSizeId;
		const allVariants = await this.getVariants(manualDefault.ModelConfigUuid, modelgroup);

		let defaults = new Array<ComponentVariantModel>();

		manualDefault.ManualDefaultVariants.forEach(defaultVariant => {
			const variant = allVariants.find(x => x.Id == defaultVariant.ComponentVariantId);

			const vari = variant.ToComponentVariantModel("", null, false);

			const size = variant.VariantSizes.find(x => x.Id == defaultVariant.ComponentSizeId);
			vari.VariantSize = size;
			const color = size.VariantColors.find(x => x.Color.Id == defaultVariant.ColorId);
			vari.VariantColor = color;
			defaults.push(vari);
		});

		// to accompany optionals because we arent setting them currently
		defaults = defaults.concat(model.DefaultVariantModels.filter(x => x.Optional));

		model.DefaultVariantModels = defaults;

		return model;
	}

	private async getVariants(modelconfigUuid: string, modelgroup: ModelGroupModel, componentUuid?: string, componentVariantUuid?: string, componentSizeUuid?: string): Promise<Array<ComponentVariantFromApiModel>> {
		return this.getVariantsForModel(modelconfigUuid, modelgroup, componentUuid, componentVariantUuid, componentSizeUuid, this.enduserDisplayService.IsDisplay());
	}

	private async getVariantsForModel(modelconfigUuid: string, modelgroup: ModelGroupModel, componentUuid: string, componentVariantUuid: string, componentSizeUuid: string, isDisplay: boolean): Promise<Array<ComponentVariantFromApiModel>> {
		const url = (await this.env.baseUrl()) + this.variantDataUrl + `requestModel.configurationUuid=${modelconfigUuid}&requestModel.ModelgroupUuid=${modelgroup.modelGroupDataUuid}`;
		let variants = await this.storeService.getOrSetFromStore<Array<ComponentVariantFromApiModel>>(url);

		if (componentUuid) {
			variants = variants.filter(x => x.Component.Uuid == componentUuid);
		}

		if (componentVariantUuid) {
			variants = variants.filter(x => x.Uuid == componentVariantUuid);
		}

		if (componentSizeUuid) {
			variants.forEach(x => x.VariantSizes = x.VariantSizes.filter(size => size.Uuid == componentSizeUuid));
		}

		if (this.brandModeService.isPrecious) {
			variants = variants.filter(model => model.Brand == BrandEnum.Precious || model.Brand == BrandEnum.TitaniumAndPrecious);
		}
		else {
			variants = variants.filter(model => model.Brand == BrandEnum.Titanium || model.Brand == BrandEnum.TitaniumAndPrecious);
		}

		if (isDisplay) {
			variants = variants.filter(x => x.AvailableForDisplay == isDisplay);

			variants.forEach(variant => {
				variant.VariantSizes = variant.VariantSizes.filter(x => x.AvailableForDisplay == isDisplay);

				variant.VariantSizes.forEach(size => {
					size.VariantColors = size.VariantColors.filter(x => x.AvailableForDisplay == isDisplay);
				});
			});
		}

		const result = variants.map((x) => ComponentVariantFromApiFactory.createCopy(x));

		return result;
	}

	public async getVariantsOnModelgroup(modelgroup: ModelGroupModel): Promise<Array<ComponentVariantFromApiModel>> {
		const result = await this.storeService.getOrSetFromStore<Array<ComponentVariantFromApiModel>>(`${await this.env.baseUrl()}/componentvariantdistinct?requestModel.modelgroupUuid=${modelgroup.modelGroupDataUuid}`);

		return result.map((x) => ComponentVariantFromApiFactory.createCopy(x));
	}

	public async getCaseMiscModel(caseItemNo: string, caseItemVariantCode: string) {
		const miscModels = await this.getMiscModels(true);
		const miscCase = miscModels.find(t => t.EcommerceNo == caseItemNo && t.EcommerceVariantCode == caseItemVariantCode);

		return miscCase;
	}

	public async getModelGroup(uuid: string): Promise<ModelGroupModel> {
		const result = await (this.storeService.getOrSetFromStore<Array<ModelGroupModel>>(`${await this.env.baseUrl()}/modelgroups`));
		const resultModelgroup = result.find(x => x.Uuid == uuid);
		return ModelGroupModel.createCopy(resultModelgroup as ModelGroupModel);
	}

	public async getModelGroupFromName(name: string): Promise<ModelGroupModel> {
		const result = await (this.storeService.getOrSetFromStore<Array<ModelGroupModel>>(`${await this.env.baseUrl()}/modelgroups`));
		const modelGroup = result.find(x => x.ShortDescription.Fallback.toLowerCase() === name.toLowerCase() || x.Description.Fallback.toLowerCase() === name.toLowerCase() || x.ProductLineCode.toLowerCase() === name.toLowerCase());
		return ModelGroupModel.createCopy(modelGroup as ModelGroupModel);
	}

	public async getModelGroupFromProductLineCode(code: string): Promise<ModelGroupModel> {
		const result = await (this.storeService.getOrSetFromStore<Array<ModelGroupModel>>(`${await this.env.baseUrl()}/modelgroups`));
		const modelGroup = result.find(x => x.ProductLineCode === code);
		return ModelGroupModel.createCopy(modelGroup as ModelGroupModel);
	}

	public async getDefaultModel(user: UserDataModel, modelGroupModel: ModelGroupModel): Promise<ModelModel> {
		const result = await this.getModels(user, modelGroupModel, null, true);
		return plainToClass(ModelModel, result[0]);
	}

	public async getModelSizeMinMax(): Promise<ModelSizeMinMaxModel> {
		const result = await this.storeService.getOrSetFromStore<ModelModel>(`${await this.env.baseUrl()}/modelsize/minmax`);
		return plainToClass(ModelSizeMinMaxModel, result);
	}

	public async getAllModelGroups(): Promise<Array<ModelGroupModel>> {
		const result = await (this.storeService.getOrSetFromStore<Array<ModelGroupModel>>(`${await this.env.baseUrl()}/modelgroups`));
		return result.map((x) => ModelGroupModel.createCopy(x as ModelGroupModel));
	}

	public async getKidsModelGroups(): Promise<Array<ModelGroupModel>> {
		let result = await this.storeService.getOrSetFromStore<Array<ModelGroupModel>>(`${await this.env.baseUrl()}/modelgroups`);
		result = result.filter(x => x.KidTeen == true);
		return result.map((x) => ModelGroupModel.createCopy(x as ModelGroupModel));
	}

	public async getAdultModelGroups(): Promise<Array<ModelGroupModel>> {
		let result = await (this.storeService.getOrSetFromStore<Array<ModelGroupModel>>(`${await this.env.baseUrl()}/modelgroups`));
		result = result.filter(x => x.KidTeen == false && x.ParentModelGroupUuid == null);

		return result.map((x) => ModelGroupModel.createCopy(x as ModelGroupModel));
	}

	public async getModelFromName(user: UserDataModel, modelGroup: ModelGroupModel, name: string): Promise<ModelModel> {
		const result = await this.getModels(user, modelGroup);
		return result.find(x => x.Code == name);
	}

	public async getModel(user: UserDataModel, modelGroup: ModelGroupModel, modelUuid: string): Promise<ModelModel> {
		const result = await this.getModels(user, modelGroup, modelUuid);
		return result.find(x => x.Uuid == modelUuid);
	}

	public async getModelsWithAllDataRequest(modelGroup: ModelGroupModel): Promise<Observable<HttpEvent<ModelReponseWithVariantsModelFromApi>>> {
		const existsInIndexedDb = await this.exists((await this.env.baseUrl()) + this.modelWithAllDataUrl + modelGroup.Uuid);
		if (existsInIndexedDb) {
			return;
		}

		const req = new HttpRequest('GET', `${await this.env.baseUrl()}/allModelData/${modelGroup.Uuid}`, {
			reportProgress: true
		});

		return this.http.request<ModelReponseWithVariantsModelFromApi>(req);
	}

	public async setModelsWithAllData(modelGroup: ModelGroupModel, model: ModelReponseWithVariantsModelFromApi) {
		await this.storeService.getOrSetFromStore<Array<ModelModel>>((await this.env.baseUrl()) + this.modelDataUrl + modelGroup.modelGroupDataUuid, model.ModelReponseModels);

		this.adjustVariantsToCurrentBrand(model.ModelReponseModels);

		const groupedVariants = model.ComponentVariantModels.reduce((r, a) => {
			r[a.ModelConfigUuid] = r[a.ModelConfigUuid] || [];
			r[a.ModelConfigUuid].push(a);
			return r;
		}, Object.create(null));

		for (const modelconfigUuid in groupedVariants) {
			const variants = groupedVariants[modelconfigUuid];
			const url = (await this.env.baseUrl()) + this.variantDataUrl + `requestModel.configurationUuid=${modelconfigUuid}&requestModel.ModelgroupUuid=${modelGroup.modelGroupDataUuid}`;
			await this.storeService.getOrSetFromStore<Array<ComponentVariantFromApiModel>>(url, variants);
		}

		await this.storeService.getOrSetFromStore<string>((await this.env.baseUrl()) + this.modelWithAllDataUrl + modelGroup.Uuid, "saved");
	}

	public async getMiscModels(includeItemsWithZeroMaxQuantity: boolean = false): Promise<Array<MiscModel>> {
		const result = await this.storeService.getOrSetFromStore<Array<MiscModel>>(`${await this.env.baseUrl()}/${this.stockKeepingUnitsEndpoint}`);
		let mappedResult = result.map((x) => MiscModel.createCopy(x as MiscModel));

		const user = await this.userService.GetUser();

		if (!includeItemsWithZeroMaxQuantity) {
			// casting skyldes at vi fra API'et returnerer en string, men typen her er et number.
			// "" fra API'et betyder "ingen begrænsning", men "0" betyder at den ikke skal vises
			mappedResult = mappedResult.filter(t => (t.MaximumOrderQty as unknown as string) !== "0")
		}

		// *** If a model has web resource restraints (if there is any data in the WebResourceCodes list),
		// *** the corresponding web resources must be present in the users list of of web resource claims

		let resultFilteredByWebResources: MiscModel[] = [];

		for (const m of mappedResult) {
			if (m.WebResourceCodes && m.WebResourceCodes.length > 0) {
				let commonWebResources = m.WebResourceCodes.some(c => user.WebResources.includes(c))

				if (commonWebResources) {
					resultFilteredByWebResources.push(m)
				}
			}
			else {
				resultFilteredByWebResources.push(m)
			}
		}

		return resultFilteredByWebResources;
	}

	private async exists(key: string): Promise<boolean> {
		return await this.indexedDbService.exists(StoreService.storeName, key);
	}

	public async loadAllModelgroups(): Promise<Array<ModelGroupModel>> {
		try {
			this.indexedDbService.ignoreIndexedDb = false;
			const modelgroups = await this.getAdultModelGroups();
			const modelgroupsKids = await this.getKidsModelGroups();

			const allModelgroups = modelgroups.concat(modelgroupsKids);
			return allModelgroups;
		}
		catch (error) {
			throw error;
		}
		finally {
			this.indexedDbService.ignoreIndexedDb = true;
		}
	}

	public async searchModels(search?: string, tags?: Array<TagModel>, hboxFrom?: number, hboxTo?: number): Promise<Array<ModelModel>> {
		let tagUrl = `&requestModel.hboxFrom=${hboxFrom || ""}&requestModel.hboxTo=${hboxTo || ""}`;

		tags = tags || [];
		tags.forEach(tag => {
			tagUrl += "&requestModel.tags=" + tag.Id;
		});

		const result = await this.storeService.getOrSetFromStore<Array<ModelModel>>(`${await this.env.baseUrl()}/models/search?requestModel.searchCriteria=${search || ""}${tagUrl}`);
		return result.map((x) => ModelModel.createCopy(x));
	}
}

export class ModelsResponse {

	Id: number;
	ModelLineId: number;
	Name: string;
	Release: Date;
	Discontinuation?: Date;
	DefaultBoxSize: string;
	ImageUrl: string;

}
