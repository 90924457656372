import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';
import { VariantColorFromApiModel } from '@models/variant-color-from-api';
import { BindingConstants } from '@shared/dependency-constants';
import { MenuConstants } from '@shared/menu-constants';
import { ComponentBindingModel } from 'src/_models/component-binding';
import { ComponentDependencyModel } from 'src/_models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { IDependencyCalculator } from "../dependency-calculator.interface";

export class FindNewChangeByColorDependencyCalculator implements IDependencyCalculator {

	constructor(
		private getChooseableVariant: (componentUuid: string, variantUuid: string, variantSizeUuid: string) => Promise<Array<ComponentVariantFromApiModel>>
	) {
	}

	async getChanges(
		dependency: ComponentDependencyModel,
		bindings: Array<ComponentBindingModel>,
		variant: ComponentVariantModel,
		oppositChosenVariants: Array<ComponentVariantModel>,
		variantSelectionToCheckAgainst: Array<ComponentVariantModel>,
		lastBindings: Array<ComponentBindingModel>
	): Promise<Array<ComponentVariantChangeModel>> {
		const b = bindings.filter(x => x.ComponentId === variant.Component.Id && x.VariantColor);

		if (!b.length) {
			return [];
		}

		console.debug(`Finding new change by color for variant: ${variant.Code}`);

		const possibleVariants = await this.getChooseableVariant(variant.Component.Uuid, variant.Uuid, variant.VariantSize?.Uuid);

		const allColors = possibleVariants[0].VariantSizes[0].VariantColors;

		let colorResult: VariantColorFromApiModel;

		if (dependency.Binding === BindingConstants.Block) {
			const colors = allColors.filter(x => x.Uuid !== variant.VariantColor.Uuid);
			colorResult = colors.find(x => x.Default) ?? colors[0];
		}
		else if (dependency.Binding === BindingConstants.PermitOnly) {
			colorResult = allColors.find(x => b.some(t => t.VariantColorUuid === x.Uuid));
		}

		const res = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
		res.NotSelected = false;
		const result = new ComponentVariantChangeModel(variant, res, null);
		result.NewComponentVariant.VariantColor = colorResult;
		result.MenuTypesWithWarning.push(MenuConstants.COLOR);

		return [result];
	}

}
