import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandConstants } from '@infrastructure/brand.enum';
import { AuthGuard } from '../_guards/auth.guard';
import { AdvancecustomizeGuard } from '../_guards/can-advancecustomize.guard';
import { UrlConstants } from '../shared/url-constants';
import { MiscGroupPageComponent } from './misc/misc-group/misc-group.component';
import { ModelViewerComponent } from './parts/model-viewer/model-viewer.component';
import { ServiceSettingsComponent } from './administration/service-settings/service-settings.component';
import { IsAdminGuard } from '@guards/isAdmin.guard';

/*
	The paths defined below use a string-based system to determine the route endpoints
	EG when you see a colon : followed by a UrlConstant (UrlConstants.MODELGROUP) it is simply a string ("modelGroupCode")
	that the service uses to identify which function to use to decipher the route/path
*/

const appRoutes: Routes = [
	{
		path: '',
		data: {
			breadcrumb: 'BREADCRUMBS.HOME'
		},
		children: [
			{
				path: 'login',
				loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
			},
			{
				path: '',
				redirectTo: '/titanium',
				pathMatch: 'full'
			},
			{
				path: 'titanium',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.TITANIUM'
				},
				loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
			},
			{
				path: 'precious',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.PRECIOUS'
				},
				loadChildren: () => import('./home/home.module').then(x => x.HomeModule),
			},
			{
				path: 'inspiration',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.INSPIRATION'
				},
				loadChildren: () => import('./home/home.module').then(x => x.HomeModule),
			},
			{
				path: 'tools',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.TOOLS'
				},
				loadChildren: () => import('./home/home.module').then(x => x.HomeModule)
			},
			{
				path: 'kering-eyeware-brands',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.KERING'
				},
				loadChildren: () => import('./kering-eyeware-brand/kering-eyeware-brand.module').then(m => m.KeringEyewareBrandModule)
			},
			{
				path: 'group/:' + UrlConstants.MODELGROUP + '/models',
				redirectTo: BrandConstants.TITANIUM + '/group/:' + UrlConstants.MODELGROUP + '/models'
			},
			{
				path: ':' + UrlConstants.BRANDMODE + '/group/:' + UrlConstants.MODELGROUP + '/models',
				canActivate: [AuthGuard],
				data: {
					brandMode: ':' + UrlConstants.BRANDMODE,
					breadcrumb: ':' + UrlConstants.MODELGROUP,
				},
				loadChildren: () => import('./models/models.module').then(x => x.ModelsModule)
			},
			{
				path: 'filterlist',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.FILTERLIST'
				},
				loadChildren: () => import('./filter-list/filter-list.module').then(x => x.FilterListModule)
			},
			{
				path: 'bag',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.BAG'
				},
				loadChildren: () => import('./bag/bag.module').then(x => x.BagModule)
			},
			{
				path: 'orderconfirmation/:userUuid/:orderUuid/:customerNo',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'HOME.ORDER'
				},
				loadChildren: () => import('./bag/bag.module').then(x => x.BagModule)
			},
			{
				path: `searchresults/:${UrlConstants.MODEL}`,
				canActivate: [AdvancecustomizeGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.SEARCHRESULTS'
				},
				loadChildren: () => import('./condensed-customizer/condensed-customizer.module').then(x => x.CondensedCustomizerModule)
			},
			{
				path: 'featured/:' + UrlConstants.INSPIRATIONGROUP,
				canActivate: [AuthGuard],
				data: {
					breadcrumb: ':' + UrlConstants.INSPIRATIONGROUP,
				},
				loadChildren: () => import('./featured/featured.module').then(x => x.FeaturedModule)
			},
			{
				path: 'kid-teen',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.KIDTEEN',
				},
				loadChildren: () => import('./kid-teen/kidteen.module').then(x => x.KidTeenModule)
			},
			{
				path: `group/:${UrlConstants.MODELGROUP}`,
				redirectTo: 'titanium/group/:' + UrlConstants.MODELGROUP
			},
			{
				path: `:${UrlConstants.BRANDMODE}/group/:${UrlConstants.MODELGROUP}`,
				canActivate: [AuthGuard],
				data: {
					brandMode: ':' + UrlConstants.BRANDMODE,
					breadcrumb: ':' + UrlConstants.MODELGROUP
				},
				loadChildren: () => import('./modelgroup-children/modelgroup-children.module').then(x => x.ModelGroupChildrenModule)
			},
			{
				path: `:${UrlConstants.BRANDMODE}/group/:${UrlConstants.MODELGROUP}/rimless`,
				canActivate: [AuthGuard],
				data: {
					brandMode: ':' + UrlConstants.BRANDMODE,
					breadcrumb: ':' + UrlConstants.MODELGROUP,
				},
				loadChildren: () => import('./customizer/customizer.module').then(x => x.CustomizerModule)
			},
			{
				path: `:${UrlConstants.BRANDMODE}/group/:${UrlConstants.MODELGROUP}/rimless/:${UrlConstants.MODEL}`,
				canActivate: [AuthGuard],
				data: {
					brandMode: ':' + UrlConstants.BRANDMODE,
					breadcrumb: ':' + UrlConstants.MODELGROUP,
				},
				loadChildren: () => import('./customizer/customizer.module').then(x => x.CustomizerModule)
			},
			{
				path: 'tools/:categoryCode',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: ':categoryCode'
				},
				component: MiscGroupPageComponent,
				children: [
					{
						path: 'group/:groupCode',
						data: {
							breadcrumb: ':groupCode'
						},
						component: MiscGroupPageComponent
						// loadChildren: () => import('./misc/misc.module').then(x => x.MiscModule)
					}
				]
			},
			{
				path: 'storage',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.STORAGE'
				},
				loadChildren: () => import('./storage/storage.module').then(x => x.StorageModule)
			},
			{
				path: 'limited',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.LIMITED'
				},
				loadChildren: () => import('./limited/limited.module').then(x => x.LimitedModule)
			},
			{
				path: 'parts',
				canActivate: [AuthGuard],
				data: {
					breadcrumb: 'BREADCRUMBS.PARTS'
				},
				loadChildren: () => import('./parts/parts.module').then(x => x.PartsModule),
			},
			{
				path: 'parts/model',
				canActivate: [AuthGuard],
				component: ModelViewerComponent,
				data: {
					breadcrumb: 'BREADCRUMBS.PARTS'
				},
			},
			{
				path: 'administration',
				canActivate: [AuthGuard, IsAdminGuard],
				component: ServiceSettingsComponent,
				data: {
					breadcrumb: 'BREADCRUMBS.ADMINISTRATION'
				},
			}
		]
	},
	{
		path: '**',
		data: {
			breadcrumb: 'BREADCRUMBS.NOTFOUND'
		},
		loadChildren: () => import('./not-found/not-found.module').then(x => x.NotFoundModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule { }
