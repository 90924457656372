<div class="modal-body">
    <button class="close" (click)="close()">
        <i class="fa-light fa-xmark"></i>
    </button>

    <div class="favorite-collection">
        <favorite-collection-overlay [savingBagItems]="savingBagItems" [bagItemsToSaveToFavoriteCollection]="bagItemsToSaveToFavoriteCollection"
            [customer]="customer" [variants]="variants" [model]="model" [configuration]="configuration"
            [modelSize]="modelSize" [manualconfigGroups]="allManualConfigGroups" [templeInclination]="templeInclination"
            (cancelChange)="closeAndDestroy()" class="from-modal"
            (applyFavoriteCollectionChangeEmitter)="applyFavoriteCollectionChange($event)">
            <button class="btn btn-secondary mt-2 cancel-collection-overlay" (click)="closeAndDestroy()">
                {{ 'COMMON.CLOSE' | translate }}
            </button>
        </favorite-collection-overlay>
    </div>
</div>