import { Injectable } from '@angular/core';
import { CustomerModel } from '@models/customer';
import { LocationCheckInModel } from '@models/location-checkin-model';
import { LocationCheckInListModel } from '@models/location-checkinlist-model';
import { plainToClass } from 'class-transformer';
import { EnvService } from './env.service';
import { LocalstorageService } from './localstorage.service';
import { OfflineService } from './offline.service';
import { ServiceUtils } from './utils/service.utils';

@Injectable()
export class LocationCheckInService {
    constructor(
        private localStorageService: LocalstorageService,
        private env: EnvService,
        private offlineService: OfflineService,
        private serviceUtils: ServiceUtils
    ) {

    }

    public locationCheckInExistsForToday(customer: CustomerModel): boolean {
        try {
            const currentLocationCheckInList = this.getCurrentLocationCheckedInList();

            if (this.shouldResetSavedLocationCheckInData(currentLocationCheckInList)) {
                this.clearSavedLocationCheckInData();
                return false;
            } else if (!this.customerExistsInList(customer.No, currentLocationCheckInList)) {
                return false;
            }

            return true;

        } catch (error) {
            this.clearSavedLocationCheckInData();
            return false;
        }
    }

    public addToLocationCheckInListStorage(customerNo: string) {
        const checkInList = this.getCurrentLocationCheckedInList();
        if (customerNo && checkInList && !checkInList.CheckedInList.some(elem => elem == customerNo)) {
            checkInList.CheckedInList.push(customerNo);
            this.localStorageService.set("locationCheckInList", checkInList);
        }
    }

    public getCurrentLocationCheckedInList(): LocationCheckInListModel {
        const locationCheckInListJSON = this.localStorageService.get<string>("locationCheckInList");
        const savedplainToClass = plainToClass(LocationCheckInListModel, locationCheckInListJSON);
        if (savedplainToClass) {
            return savedplainToClass;
        }
        return null;
    }

    private customerExistsInList(customerNo: string, currentLocationCheckInList: LocationCheckInListModel): boolean {
        if (currentLocationCheckInList && currentLocationCheckInList.Created.isToday()) {
            if (currentLocationCheckInList.CheckedInList.some(elem => elem == customerNo)) {
                return true;
            }
        }
        return false;
    }

    private shouldResetSavedLocationCheckInData(currentLocationCheckInList: LocationCheckInListModel): boolean {
        return !currentLocationCheckInList || !(currentLocationCheckInList?.Created || new Date()).isToday();
    }

    private clearSavedLocationCheckInData(): void {
        this.localStorageService.clear("locationCheckInList");
        const locationListEmpty = new LocationCheckInListModel();
        locationListEmpty.Created = new Date();
        locationListEmpty.CheckedInList = new Array<string>();
        this.localStorageService.set("locationCheckInList", locationListEmpty);
    }

    public async locationCheckIn(model: LocationCheckInModel) {
        try {
            return await this.offlineService.postWithOfflineHandling(`${await this.env.baseUrl()}/LocationCheckIn/`, model);
        }
        catch (error) {
            if (this.serviceUtils.errorIsOffline(error)) {
                // ignore
            }
            else {
                throw error;
            }
        }
    }
}