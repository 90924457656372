import { Type } from "class-transformer";
import { AssortmentLineModel } from './assortment-line-model';
import { AvailabilityModel } from "./availability-model";
import { ComponentBindingModel } from './component-binding';
import { ComponentDependencyModel } from './component-dependency';

export class ConfigurationModelall {

    configsOnModelsize: Array<ConfigurationModel>;
    modelSizeUuid: string;
}

export class ConfigurationModel extends AvailabilityModel {
    Id: number;
    Uuid: string;
    @Type(() => Date)
    Release: Date;
    Code: string;

    @Type(() => ComponentDependencyModel)
    Dependencies: Array<ComponentDependencyModel>;

    @Type(() => ComponentBindingModel)
    Bindings: Array<ComponentBindingModel>;

    @Type(() => AssortmentLineModel)
    AssortmentLines: Array<AssortmentLineModel>;

    Default: boolean;
    Fixed: boolean;

    ColorDescriptions: Array<string> = [];
    ModelSizeUuid: string;
    ModelSizeId: number;
    ModelConfigId: number;
    ModelConfigurationUuid: string;
    ModelConfigurationCode: string;
    ModelConfigurationDescription: string;
    ModelConfigurationDescription2: string;
    IsPrecious: boolean;

    public get ColorNameAndCode(): string {
        return (this.ModelConfigurationDescription ? this.ModelConfigurationDescription + ": " : "") + this.ModelConfigurationDescription2;
    }

    public getBindingsForDependency(dependencyId: number) {
        return this.Bindings.filter(x => x.ComponentDependencyId == dependencyId);
    }
}
