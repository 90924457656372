import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MiscCategoryPageComponent } from './misc-category/misc-category.component';
import { MiscGroupPageComponent } from './misc-group/misc-group.component';
import { routes } from './misc.routes';

@NgModule({
	declarations: [
		MiscCategoryPageComponent,
		MiscGroupPageComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		SharedModule,
		RouterModule.forChild(routes)
	],
	providers: [
	],
	bootstrap: [
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
	exports: [
	]
})
export class MiscModule { }
