import { ComponentDependencyModel } from '@models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { ConfigurationModel } from 'src/_models/configuration';
import { IDependencyCalculator } from "./dependency-calculator.interface";

export class DependencyCalculator {
    constructor(private dependencyCalculators: Array<IDependencyCalculator>) {
    }

    public async GetDependencyChanges(variantSelectionsToCheck: Array<ComponentVariantModel>, variantSelectionToCheckAgainst: Array<ComponentVariantModel>, configuration: ConfigurationModel): Promise<Array<ComponentVariantChangeModel>> {
        const changes = await this.RunDependenciesInternal(variantSelectionsToCheck, variantSelectionToCheckAgainst, configuration, 0);
        const updateModels: Array<ComponentVariantChangeModel> = [];

        // todo: Delete this when block has been rewritten.
        changes.filter(y => y).forEach(updateModel => {
            const newVariant = updateModel.NewComponentVariant;

            if (updateModels.some(t => t.NewComponentVariant.Code == newVariant.Code
                && (
                    t.NewComponentVariant.VariantColor == null && newVariant.VariantColor == null
                    || t.NewComponentVariant.VariantColor.Id == newVariant.VariantColor.Id
                    )
                && (
                    t.NewComponentVariant.VariantSize == null && newVariant.VariantSize == null
                    || t.NewComponentVariant.VariantSize.Id == newVariant.VariantSize.Id)
                )) {
            } else {
               updateModels.push(updateModel);
           }
        });

        return updateModels;
    }

    private async RunDependenciesInternal(variantSelectionToCheck: Array<ComponentVariantModel>, variantSelectionToCheckAgainst: Array<ComponentVariantModel>, configuration: ConfigurationModel, count: number): Promise<Array<ComponentVariantChangeModel>> {
        const result = new Array<ComponentVariantChangeModel>();
        for (const variant of variantSelectionToCheck) {
            const dependencies = this.getDependenciesForVariant(variant, configuration);

            const changePromises = await this.getChanges(dependencies, variant, configuration, variantSelectionToCheck, variantSelectionToCheckAgainst);

            const variantchanges = changePromises.filter(x => x);
            result.push(...variantchanges);
        }

        // if there are no more changes left to add or if it reaches a max it should just stop
        if (count > 2 || result.length == 0) {
            // console.debug("Max runDependencies reached", count);

            return result;
        }

        if (result.length) {
            console.debug("runDependencies: New dependencies discovered, rerunning", result);
        }

        // else it goes through each change and finds changes that has to occur on behalf of those changes.
        // Imagine a color-binding between upper rim and temple, but at the same time theres a binding between temple and a cover
        // (Rim temple color U33 for instance)
        // so if the user selects a U33 upper rim, temple must be U33 too. But because U33 on temple means no cover on temple,
        // both dependencies has to be corrected for

        const subDependencies: Array<Promise<Array<ComponentVariantChangeModel>>> = [];
        const newVariants = this.combineVariantsWithChanges(variantSelectionToCheck, result);

        result.forEach(vari => {
            subDependencies.push(this.RunDependenciesInternal(newVariants, variantSelectionToCheckAgainst, configuration, count + 1));
        });

        const subDependencyChanges = await Promise.all(subDependencies);

        console.debug("result:", subDependencyChanges);
        subDependencyChanges.forEach(x => {
            result.push(...x);
        });

        return result;
    }

    private combineVariantsWithChanges(variants: Array<ComponentVariantModel>, variantchanges: Array<ComponentVariantChangeModel>) {
        const newVariants = Object.assign([], variants);
        variantchanges.forEach(vari => {
            newVariants[newVariants.findIndex(x => vari.OldComponentVariant.Id == x.Id)] = vari.NewComponentVariant;
        });

        return newVariants;
    }

    private getDependenciesForVariant(variant: ComponentVariantModel, configuration: ConfigurationModel) {
        if(!variant) {
            return [];
        }

        const result = new Array<ComponentDependencyModel>();

        for (const dependency of this.getDependencies(configuration)) {
            if(this.getDependencyForVariant(variant, dependency)) {
                result.push(dependency);
            }
        }

        return result;
    }

    private getDependencies(configuration: ConfigurationModel) {
        const result: Array<ComponentDependencyModel> = [];

        configuration.Dependencies.forEach(dependency => {
            result.push(dependency);
        });

        return result;
    }

    private getDependencyForVariant(variant: ComponentVariantModel, dependency: ComponentDependencyModel) {
        return (variant.Component.Id === dependency.Component1.Id) || variant.Component.Id === dependency.Component2.Id;
    }

    private async getChanges(dependencies: Array<ComponentDependencyModel>, variant: ComponentVariantModel, configuration: ConfigurationModel, variantSelectionToCheck: Array<ComponentVariantModel>, variantSelectionToCheckAgainst: Array<ComponentVariantModel>) {
        const changePromises = new Array<ComponentVariantChangeModel>();
        for (const dependency of dependencies) {
            // find the opposite variants that gets affected
            const bindings = configuration.getBindingsForDependency(dependency.Id);

            for(const element of this.dependencyCalculators) {
                const changes = await element.getChanges(dependency, bindings, variant, variantSelectionToCheck, variantSelectionToCheckAgainst, []);
                changePromises.push(...changes);
            }
        }
        return changePromises;
    }
}
