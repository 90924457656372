import { SimpleModelModel } from "@models/api-models/simple-model-model";
import { IntervalTag } from '../interval-tag';
import { IValueTag } from '../value-tag.interface';
import { AbstractTagHandler } from './tag-handler.interface';

export class ReverseIntervalTagHandler extends AbstractTagHandler {
    private readonly intervalLength = 8;
    private modelsToLoopThrough: Array<SimpleModelModel>;
    private result = new Array<IntervalTag<SimpleModelModel>>();

    public getTags(modelsToLoopThrough: Array<SimpleModelModel>): Array<IValueTag<Array<SimpleModelModel>>> {
        this.modelsToLoopThrough = modelsToLoopThrough.filter(x => !x.IsKid && !isNaN(parseInt(x.Code, 10)));
        const parentTags = super.getTags(modelsToLoopThrough) || new Array();
        return [...this.generateTags(), ...parentTags];
    }

    private generateTags() {
        this.result = new Array<IntervalTag<SimpleModelModel>>();
        let currentInterval = new Array<SimpleModelModel>();

        for (let index = this.modelsToLoopThrough.length-1; index >= 0; index -= this.intervalLength) {
            currentInterval = this.calculateIntervals(index, currentInterval);
        }

        return this.result.sortBy(x => x.name);
    }

    private calculateIntervals(index: number, currentInterval: Array<SimpleModelModel>) {
        const modelsInInterval = this.getModelsInInterval(index);

        const hasChangesInCurrentInterval = this.checkForSecondLastDigitChanges(currentInterval.map(x => x.Id));

        if (hasChangesInCurrentInterval) {
            const currentIntervalNumber = this.getSecondLastDigit(this.getPaddedCode(parseInt(currentInterval.last().Code, 10)));
            const currentIntervalElements: Array<SimpleModelModel> = this.getElementsInCurrentInterval(currentInterval, currentIntervalNumber);
            const nextIntervalElements: Array<SimpleModelModel> = this.getElementsOutsideInterval(currentInterval, currentIntervalNumber);

            const newTag = this.CreateIntervalTag(currentIntervalElements);
            this.result.push(newTag);
            currentInterval = nextIntervalElements;
        }

        const hasChanges = this.checkForSecondLastDigitChanges([...currentInterval, ...modelsInInterval].map(x => x.Id));

        if (hasChanges || this.isLastForRound(index)) {

            const currentIntervalNumber = this.getSecondLastDigit(this.getPaddedCode(parseInt(currentInterval.length ? currentInterval.first().Code : modelsInInterval.first().Code, 10)));
            const elementsInCurrentIntervalToKeep: Array<SimpleModelModel> = this.getElementsInCurrentInterval(modelsInInterval, currentIntervalNumber);
            const elementsToMoveToNextInterval: Array<SimpleModelModel> = this.getElementsOutsideInterval(modelsInInterval, currentIntervalNumber);

            elementsInCurrentIntervalToKeep.forEach(x => currentInterval.unshift(x));

            const newTag = this.CreateIntervalTag(currentInterval);
            this.result.push(newTag);

            currentInterval = new Array<SimpleModelModel>();
            elementsToMoveToNextInterval.forEach(x => currentInterval.unshift(x));

            if(this.isLastForRound(index) && elementsToMoveToNextInterval?.length > 0)
            {
                const newTag = this.CreateIntervalTag(currentInterval);
                this.result.push(newTag);
            }

        } else {
            modelsInInterval.forEach(x => currentInterval.unshift(x));
        }
        return currentInterval;
    }

    private isLastForRound(index: number): boolean {
        return index <= this.intervalLength;
    }

    private getElementsInCurrentInterval(modelsInInterval: Array<SimpleModelModel>, currentIntervalNumber: string): Array<SimpleModelModel> {
        return modelsInInterval.filter(x => this.getSecondLastDigit(this.getPaddedCode(parseInt(x.Code, 10))) === currentIntervalNumber);
    }

    private getElementsOutsideInterval(modelsInInterval: Array<SimpleModelModel>, currentIntervalNumber: string): Array<SimpleModelModel> {
        return modelsInInterval.filter(x => this.getSecondLastDigit(this.getPaddedCode(parseInt(x.Code, 10))) !== currentIntervalNumber);
    }

    private CreateIntervalTag(currentIntervalElements: Array<SimpleModelModel>) {
        const to: number = parseInt(currentIntervalElements.last().Code, 10);
        const from: number = parseInt(currentIntervalElements.first().Code, 10);

        const newTag = new IntervalTag<SimpleModelModel>(this.replaceLastDigit(from, 0), this.replaceLastDigit(to, 9));

        newTag.setData(currentIntervalElements);
        return newTag;

    }

    private getModelsInInterval(index: number): Array<SimpleModelModel> {
        const result = new Array<SimpleModelModel>();

        for (let i = index; i > index - this.intervalLength; i--) {
            const element = this.modelsToLoopThrough[i];

            if (element) {
                result.push(element);
            }
        }

        return result.map(x => x);
    }

    private checkForSecondLastDigitChanges(currentInterval: Array<number>): boolean {
        if(currentInterval.length === 0) {
            return false;
        }

        const paddedCodes = this.getPaddedCodes(currentInterval);
        const chars = paddedCodes.map(x => this.getSecondLastDigit(x));
        return !this.checkIfCharsAreEqual(chars);
    }

    private getSecondLastDigit(num: string) {
        return num.substr(num.length-2, 1);
    }

    private getPaddedCodes(currentInterval: Array<number>) {
        const models = this.modelsToLoopThrough.filter(x => currentInterval.some(t => t === x.Id));
        const paddedCodes = models.map(x => this.getPaddedCode(parseInt(x.Code, 10)));
        return paddedCodes;
    }

    private getPaddedCode = (no: number) => no.toString().padStart(4, "0");

    private checkIfCharsAreEqual = (paddedCodes: Array<string>): boolean => {
        const padded = paddedCodes.distinct((x, y) => x === y);

        return padded.length === 1;
    }

    private replaceLastDigit(no: number, digit: number) {
        const numberAsString = no.toString();

        const newString = numberAsString.substr(0, numberAsString.length - 1) + digit.toString();

        return parseInt(newString, 10);
    }
}
