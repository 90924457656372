import { Component, EventEmitter } from '@angular/core';
import { BaseModal } from '@modals/base-modal';
import { ModelModel } from '@models/api-models/model';
import { ComponentVariantModel } from '@models/component-variant';
import { ConfigurationModel } from '@models/configuration';
import { CustomerModel } from '@models/customer';
import { InspirationGroupModel } from '@models/inspiration-group';
import { ModelSizeModel } from '@models/model-size';
import { OrderItemModel } from '@models/order-item';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-favorite-collection-modal',
	templateUrl: './favorite-collection-modal.component.html',
	styleUrls: ['./favorite-collection-modal.component.scss']
})
export class FavoriteCollectionModalComponent extends BaseModal {

	public savingBagItems: boolean = false;
	public bagItemsToSaveToFavoriteCollection: Array<OrderItemModel> = [];
	public customer: CustomerModel;
	public configuration: ConfigurationModel;
	public modelSize: ModelSizeModel;
	public model: ModelModel;
	public variants: Array<ComponentVariantModel>;
	public allManualConfigGroups: Array<InspirationGroupModel>;

	public favoriteUuid: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	public isInCollection: boolean = false;
	public templeInclination: TempleInclinationEnum = null;
	public applyFavoriteCollectionChangeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		breadCrumbService: BreadcrumbService
	) {
		super(breadCrumbService);
	}

	public applyFavoriteCollectionChange(isInCollection: boolean) {
		console.warn('FavoriteCollectionModalComponent applyFavoriteCollectionChange', isInCollection);
		this.applyFavoriteCollectionChangeEmitter.emit(isInCollection)
	}

	close(): void {
		this.closeAndDestroy();
	}

}
