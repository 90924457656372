import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ui-input-addon',
  styleUrls: ['./input-addon.component.scss'],
  template: `
        <ng-content></ng-content>
  `
})
export class InputAddonComponent {
    @HostBinding('class.input-group-addon') addon = true;
    @Input() disabled: boolean;

    @HostBinding('attr.disabled')
    public get isDisabled(): string {
        return this.disabled? "disabled" : null;
    }
}
