import { Component, SimpleChanges } from '@angular/core';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { UserService } from '@services/user.service';
import { ModelsService } from '@services/v2/models.service';
import { ModelModel } from 'src/_models/api-models/model';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { ModelGroupModel } from 'src/_models/model-group';
import { EventsService } from 'src/_services/events.service';
import { Background, ImageService, ImageSize } from 'src/_services/image.service';
import { DataApiService } from '../../../../_services/data-api.service';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	selector: 'model',
	styleUrls: ['./model.scss'],
	template: `
    <div class="flex flex-column flex-overflow-off">
      <div class="flex flex-align-end flex-shrink-off">
        <div class="flex-grow">
            <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)"></customizer-component-header>
        </div>
        <div class="expand-header has-padding-horizontal-30px">
          <button (click)="toggleExpanded()" class="btn btn-secondary btn-short hidden-xs">{{"CUSTOMIZER.COMPONENTS.MODEL." +  (menu.menuDetails.expanded ? "MINIMIZE" : 'EXPAND') | translate}}</button>
        </div>
      </div>
      <select-box
        [imageClass]="'model'"
        [options]="models"
        optionsText="Code"
        optionsDescription2="Description2"
        optionsId="Uuid"
        [editMode]="state === customizerStateEnum.EDITORDER"
        [selected]="model"
        [optionsImage]="getImage"
        (selectedChange)="onSelect($event)"
        [optionsDiscontinued]="'Discontinued'"
        [expanded]="this.menu.menuDetails.expanded"
        [colorMatchMode]="false"
        [matchColor]="menu.matchColor"
      ></select-box>
    </div>
  `
})
export class ModelComponent extends BaseCustomizerComponent {

	public models: Array<ModelModel>;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private userService: UserService,
		private imageService: ImageService,
		private modelsService: ModelsService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		return true;
	}

	onChanges(changes: SimpleChanges) {
	}

	async initData() {
		this.events.showLoader(true, 0);

		this.models = await this.fetchData();

		this.events.showLoader(false, 0);
	}

	onSelect(model: ModelModel) {
		this.events.showLoader(true, 0);
		this.updateModel(model);

		if (this.menu.menuDetails.expanded) {
			this.menu.menuDetails.expanded = false;
			setTimeout(() => {
				var element = document.getElementsByClassName('is-selected')
				if (element && element[0]) {
					element[0].scrollIntoView();
				}
			}, 1500);
		}
	}

	public getImage = (model: ModelModel) => this.imageService.GetFrontImage(model, model.ModelGroupCode, model.DefaultModelConfigurationCode, model.DefaultVariantModels, ImageSize.Medium, Background.None);

	async fetchData(): Promise<Array<ModelModel>> {
		let models: Array<ModelModel>;
		const user = await this.userService.GetUser();
		const apidata = await this.modelsService.getModels(user, this.modelGroup);
		models = apidata.sort((a, b) => b.Order.localeCompare(a.Order));

		return models;
	}

}
