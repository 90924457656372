import { Type } from "class-transformer";
import { ComponentVariantModel } from './component-variant';
import { VariantSizeModel } from "./variant-size";

export class ComponentVariantChangeModel {
    @Type(() => ComponentVariantModel)
    public OldComponentVariant: ComponentVariantModel;

    @Type(() => ComponentVariantModel)
    public NewComponentVariant: ComponentVariantModel;

    @Type(() => VariantSizeModel)
    public NewSizes: Array<VariantSizeModel>;

    public NewEngraving: string;

    public MenuTypesWithWarning: Array<Array<string>> = new Array<Array<string>>();

    public MatchOtherColors: boolean;

    constructor(oldComponentVariant: ComponentVariantModel, newComponentVariant: ComponentVariantModel, newSizes: Array<VariantSizeModel>, matchColors: boolean = false) {
        this.OldComponentVariant = oldComponentVariant;
        this.NewComponentVariant = newComponentVariant;
        this.NewSizes = newSizes;
        this.MatchOtherColors = matchColors;
    }
}
