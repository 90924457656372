import { ComponentVariantModel } from '@models/component-variant';
import { ConfigurationModel } from '@models/configuration';
import { ManualConfig } from '@models/manualconfig';
import { ManualConfigVariants } from '@models/manualconfigvariant';
import { ModelSizeModel } from '@models/model-size';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';

export class ManualConfigComparer {
    public existIn(
        manualConfigs: Array<ManualConfig>,
        modelSize: ModelSizeModel,
        modelConfiguration: ConfigurationModel,
        variants: Array<ComponentVariantModel>,
        templeInclination?: TempleInclinationEnum,
        manualConfigCollectionId?: number
    ): ManualConfig {
        let existing: ManualConfig = null;
        if(!manualConfigCollectionId){
            manualConfigCollectionId = 0;
        }
        manualConfigs.forEach(manualConfig => {
            if(!existing){
                existing = this.findExistingConfig(manualConfig, modelConfiguration, modelSize, variants, existing, templeInclination, manualConfigCollectionId);
            }
        });

        return existing;
    }

    private findExistingConfig(manualConfig: ManualConfig, modelConfiguration: ConfigurationModel, modelSize: ModelSizeModel, 
        variants: Array<ComponentVariantModel>, existing: ManualConfig, 
        templeInclination?: TempleInclinationEnum, manualConfigCollectionId?: number) {
        if (manualConfig.ModelConfiguration.Code == modelConfiguration.Code && manualConfig.ModelSize.Code == modelSize.Code) {
            let allVariantsMatch: boolean = null;

            if(variants.filter(t => !t.NotSelected).length !== manualConfig.ManualConfigVariants.filter(t => !t.ComponentVariantModel.NotSelected).length) {
                return null;
            }

            variants.forEach(variant => {
                let existingFavoriteVariant: ManualConfigVariants;
                ({ existingFavoriteVariant, allVariantsMatch } = this.match(variant, existingFavoriteVariant, allVariantsMatch, manualConfig));
            });

            const templeInclinationMatch = manualConfig.TempleInclination == templeInclination;
            let manualConfigCollectionIdMatch = manualConfig.ManualConfigCollectionId == manualConfigCollectionId;
                //console.warn('existing', allVariantsMatch, templeInclinationMatch, manualConfigCollectionIdMatch, manualConfig);
            
            if (allVariantsMatch && templeInclinationMatch && manualConfigCollectionIdMatch) {
                existing = manualConfig;
            }
        }

        return existing;
    }

    private match(variant: ComponentVariantModel, existingFavoriteVariant: ManualConfigVariants, allVariantsMatch: boolean, manualConfig: ManualConfig) {
        if (variant.NotSelected) {
            existingFavoriteVariant = this.findExistingVariant(manualConfig, variant);
            return this.matchWithVariantNotSelected(existingFavoriteVariant, allVariantsMatch);
        } else {
            return this.matchWithVariant(existingFavoriteVariant, manualConfig, variant, allVariantsMatch);
        }
    }

    private findExistingVariant(manualConfig: ManualConfig, variant: ComponentVariantModel): ManualConfigVariants {
        return manualConfig.ManualConfigVariants.find(x => x.ComponentVariantModel.Code == variant.Code);
    }

    private matchWithVariant(existingFavoriteVariant: ManualConfigVariants, manualConfig: ManualConfig, variant: ComponentVariantModel, allVariantsMatch: boolean) {
        existingFavoriteVariant = manualConfig.ManualConfigVariants.find(x => this.findWithCodes(x, variant));

        if (existingFavoriteVariant && allVariantsMatch != false) {
            allVariantsMatch = true;
        } else {
            allVariantsMatch = false;
        }
        return { existingFavoriteVariant, allVariantsMatch };
    }

    private findWithCodes(x: ManualConfigVariants, variant: ComponentVariantModel): unknown {
        return x.ComponentVariantModel.Code == variant.Code &&
            x.ComponentSizeModel.Size == variant.VariantSize.Size &&
            x.ComponentColorModel.Color.Code == variant.VariantColor?.Color.Code;
    }

    private matchWithVariantNotSelected(existingFavoriteVariant: ManualConfigVariants, allVariantsMatch: boolean) {
        if (!existingFavoriteVariant && allVariantsMatch != false) {
            allVariantsMatch = true;
        } else {
            allVariantsMatch = false;
        }
        return { existingFavoriteVariant, allVariantsMatch };
    }
}
