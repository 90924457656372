import { Type } from 'class-transformer';
import { AssortmentLineModel } from './assortment-line-model';
import { AssortmentModel } from './assortment-model';
import { AvailabilityModel } from "./availability-model";
export class ModelSizeModel extends AvailabilityModel {
    Id: number;
    Uuid: string;
    Code: string;
    Hbox: number;
    Vbox: number;
    Dbl: string;
    Pd: number;

    @Type(() => Date)
    Release: Date;
    Default: boolean;
    ProductLineUuid: string;
    ModelGroupUuid: string;
    ModelUuid: string;
    @Type(() => AssortmentLineModel)
    AssortmentLines: Array<AssortmentLineModel>;

    get Assortments(): Array<AssortmentModel> {
        const result = new Array<AssortmentModel>();
        this.AssortmentLines.map(x => x.Assortment).distinct((x,y) => x.Code === y.Code).forEach(x => result.push(x));
        return result.distinct((x,y) => x.Code === y.Code);
    }
}
