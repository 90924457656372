import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@shared/app-state';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { EventsService } from 'src/_services/events.service';
import { BaseModal } from './base-modal';

@Component({
    selector: 'offline-modal',
    styleUrls: ['./offline-modal.scss'],
    template: `
        <div class="error-message">
            <h3><i class="icon-info-red icon" (click)="onCancel(false)"></i>OFFLINE</h3>
            <p>You need to be online to use the customiser.</p>
            <br />
            <div class="text-center">
                <button (click)="onRetry()" class="btn btn-primary mr-2">
                    try again
                </button>
                <button (click)="onCancel()" class="btn btn-secondary">
                    home
                </button>
            </div>
        </div>
        <p class="mail-link"><a href="mailto:collectionguide@lindberg.com">collectionguide&#64;<span class="lindbergfont">LINDBERG</span>.com</a></p>
        <a href="https://beian.miit.gov.cn/" target="_blank" *ngIf="appState.showChineseLicenseId" class="chineseLicense">沪ICP备18032619号-1</a>
    `
})
export class OfflineModalComponent extends BaseModal implements OnInit {

    constructor(
        public router: Router,
        
        breadcrumbService: BreadcrumbService,
        private eventService: EventsService,
        public appState: AppState) {
        super(breadcrumbService);
    }

    ngOnInit() {
        this.eventService.showLoader(false);
    }

    onRetry(): void {
        this.closeAndDestroy();
        window.location.reload();
    }

    async onCancel(gotoFrontpage: boolean = true) {
        this.closeAndDestroy();
        if(gotoFrontpage){
            await this.router.navigate(["/"]);
            window.location.reload();
        }
    }
}
