<div class="modal-body">
    <div class="flex flex-center">
        <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-sm-offset-1 col-md-offset-2 col-lg-offset-3">
            <app-start-kit-chooser
                [productLineCode]="productLineCode"
                [kidTeen]="kidTeen"
                (resultChange)="setAndDestroy($event)"
            ></app-start-kit-chooser>
        </div>
    </div>
</div>
