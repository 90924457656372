import { EventEmitter, Injectable } from '@angular/core';
import { CustomiserNotification, NotificationState } from '@models/customiser-notification-model';
import { AppState } from '@shared/app-state';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { EnvService } from './env.service';
import { LocalstorageService } from './localstorage.service';
import { OfflineService } from './offline.service';
import { SorterService } from './sorter.service';

@Injectable()
export class NotificationService extends BaseService {
    private notificationDropdownButtonClicked: EventEmitter<void> = new EventEmitter();
    private notificationUpdate: EventEmitter<void> = new EventEmitter();
    private notificationKey = "notificationdata";

    constructor(private localStorage: LocalstorageService,
        private appState: AppState,
        private sorterService: SorterService,
        private offlineService: OfflineService,
        private env: EnvService
    ) {
        super();
    }

    public async initNotifications(): Promise<boolean> {
        await this.getNotifications();
        await this.updateBadgeCount();
        return new Promise((resolve) => {
            resolve(true);
        });
    }

    public async updateBadgeCount(): Promise<void> {
        this.appState.newNotificationsCount.next(await this.getUnclickedNotificationCount());
    }

    public async saveNotification(customiserNotification: CustomiserNotification): Promise<void> {
        const existingNotifications: Array<CustomiserNotification> = await this.getNotifications();
        if (existingNotifications.filter(n => n.Uuid === customiserNotification.Uuid).length > 0) {
            return;
        }
        if (customiserNotification.DoUniquenessCheck) {
            const existingNotificationsPropertiesAsJson = existingNotifications.map(n => JSON.stringify({ 'body': n.Body, 'header': n.Header, 'uuid': n.UserUuid }));
            const newCustomiserNotificationProperties = JSON.stringify({ 'body': customiserNotification.Body, 'header': customiserNotification.Header, 'uuid': customiserNotification.UserUuid });

            if (existingNotificationsPropertiesAsJson.findIndex(n => n === newCustomiserNotificationProperties) > -1) {
                return;
            }
        }
        existingNotifications.push(customiserNotification);
        this.localStorage.set(this.notificationKey, existingNotifications);
        this.updateNotifications();
        await this.updateBadgeCount();
    }

    public async sendNotification(customiserNotification: CustomiserNotification): Promise<CustomiserNotification> {
        const result = await this.offlineService.postWithOfflineHandling<CustomiserNotification>(`${await this.env.baseUrl()}/signalr/notify`, customiserNotification);
        return plainToClass(CustomiserNotification, result);
    }


    public async getUnclickedNotificationCount(): Promise<number> {
        const existingNotifications: Array<CustomiserNotification> = this.localStorage.get<Array<CustomiserNotification>>(this.notificationKey);
        return existingNotifications?.filter(n => n.NotificationState !== NotificationState.WasClicked).length;
    }

    public async getNotifications(): Promise<Array<CustomiserNotification>> {

        let notifications: Array<CustomiserNotification> = this.localStorage.get<Array<CustomiserNotification>>(this.notificationKey);
        if (!notifications) {
            notifications = [];
        }
        notifications = notifications.sort(this.sorterService.sortBy(CustomiserNotification, "desc"));
        return notifications;
    }

    public getNotification(uuid: string): CustomiserNotification {
        const notifications: Array<CustomiserNotification> = this.localStorage.get<Array<CustomiserNotification>>(this.notificationKey);
        return notifications.filter(n => n.Uuid === uuid)[0];
    }

    public async markAsRead(notificationUuid: string): Promise<void> {
        const existingNotifications: Array<CustomiserNotification> = this.localStorage.get<Array<CustomiserNotification>>(this.notificationKey);
        existingNotifications.forEach(notification => {
            if (notification.Uuid === notificationUuid) {
                notification.NotificationState = NotificationState.WasClicked
            }
        });
        this.localStorage.set(this.notificationKey, existingNotifications);
        this.updateNotifications();
        await this.updateBadgeCount();
    }
    public async markAllAsRead(): Promise<void> {
        const existingNotifications: Array<CustomiserNotification> = this.localStorage.get<Array<CustomiserNotification>>(this.notificationKey);
        existingNotifications.forEach(notification => {
            notification.NotificationState = NotificationState.WasClicked
        });
        this.localStorage.set(this.notificationKey, existingNotifications);
        this.updateNotifications();
        await this.updateBadgeCount();
    }

    public async deleteNotification(notificationUuid: string): Promise<void> {
        let existingNotifications: Array<CustomiserNotification> = this.localStorage.get<Array<CustomiserNotification>>(this.notificationKey);
        existingNotifications = existingNotifications.filter(n => n.Uuid !== notificationUuid);
        this.localStorage.set(this.notificationKey, existingNotifications);
        this.updateNotifications();
        await this.updateBadgeCount();
    }
    public notificationUpdateAsObserveable(): Observable<void> {
        return this.notificationUpdate.asObservable();
    }
    public notificationDropdownButtonClickedAsObserveable(): Observable<void> {
        return this.notificationDropdownButtonClicked.asObservable();
    }

    public toggleNotificationDropdown(): void {
        this.notificationDropdownButtonClicked.emit();
    }

    public updateNotifications(): void {
        this.notificationUpdate.emit();
    }
}
