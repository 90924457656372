export class CustomiserNotification {
    Uuid: string;
    UserUuid: string;
    Header: string;
    Body: string;
    Link: Link;
    Links: Link[];
    NotificationState: NotificationState;
    NotificationType: NotificationType;
    CreatedAt: Date;
    Clicked: Date;
    OpenInstantly: boolean;
    DoUniquenessCheck: boolean;

    
    constructor(uuid: string, userUuid: string, header: string, body: string,
        notificationState: NotificationState = NotificationState.New,
        notificationType: NotificationType = NotificationType.SimpleMessage,
        openInstantly: boolean = false,
        doUniquenessCheck: boolean = false){
        this.Uuid = uuid,
        this.UserUuid = userUuid;
        this.Header = header;
        this.Body = body;
        this.NotificationState = notificationState;
        this.NotificationType = notificationType;
        this.CreatedAt = new Date()
        this.OpenInstantly = openInstantly
        this.DoUniquenessCheck = doUniquenessCheck
    }
}

export class Link {
    constructor(url: string, linkText: string = url, target: string = null) {
        
        this.Url = url,
        this.LinkText = linkText;
        this.Target = target;
    }
    Url: string;
    LinkText: string;
    Target: string;
}

export enum NotificationState{
    New = 0,
    WasSeen = 1,
    WasClicked = 2
}
export enum NotificationType {
    SimpleMessage = 0,
    UnfinishedOrders = 1,
    FramesUpdate = 2,
    ToolsAndAccessoriesUpdate = 3,
    CustomiserVersionUpdate = 4,
    CustomiserServiceMessage = 5
}