import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "@services/google-analytics.service";
import { UserService } from "@services/user.service";
import { ModelsService } from '@services/v2/models.service';
import { FadeInOutTransition } from "@shared/transitions/fade-in-out.transition";
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { TagGroupModel, TagModel, TagTypeModel } from "../../_models/tag";
import { SorterService } from "../../_services/sorter.service";
import { TagsService } from "../../_services/tags.service";
import { BaseModal } from "../base-modal";

@Component({
	selector: 'tag-filter-modal',
	templateUrl: "tag-filter.component.html",
	styleUrls: ['./tag-filter.component.scss'],
	animations: [
		FadeInOutTransition()
	]
})
export class TagFilterModalComponent extends BaseModal implements OnInit {

	public data: Array<{ type: TagTypeModel, groups: Array<{ group: TagGroupModel, tags: Array<TagModel> }> }>;
	public minimum: number = null;
	public maximum: number = null;
	public selectedMinimum: number = null;
	public selectedMaximum: number = null;
	public selected: Array<TagModel>;
	public show = false;

	private dataBackup: Array<TagModel>;
	private hboxFromBackup: number;
	private hboxToBackup: number;

	minValue: number = 10;
	maxValue: number = 20;

	constructor(breadcrumbService: BreadcrumbService,
		protected router: Router,
		private tagsService: TagsService,
		private dataApiService: DataApiService,
		private sorterService: SorterService,
		private userService: UserService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private modelsService: ModelsService,
		private events: EventsService
	) {
		super(breadcrumbService);
	}

	async ngOnInit() {
		this.events.showLoader(true);

		let tags = await this.dataApiService.getTags();
		const minmax = await this.modelsService.getModelSizeMinMax();

		this.minimum = minmax.Minimum;
		this.maximum = minmax.Maximum;

		this.selectedMinimum = this.tagsService.getHboxFromSnapshot() || minmax.Minimum;
		this.selectedMaximum = this.tagsService.getHboxToSnapshot() || minmax.Maximum;

		this.hboxFromBackup = this.tagsService.getHboxFromSnapshot();
		this.hboxToBackup = this.tagsService.getHboxToSnapshot();

		tags = tags.sort(this.sorterService.sortBy(TagModel));
		const tagTypes = tags.map(x => x.TagType).sort(this.sorterService.sortBy(TagTypeModel));
		const distinctTypeIds = new Set(tagTypes.map(x => x.Id));
		const distinctGroupIds = new Set(tags.map(x => x.TagGroup.Id));
		const result: Array<{ type: TagTypeModel, groups: Array<{ group: TagGroupModel, tags: Array<TagModel> }> }> = [];

		distinctTypeIds.forEach(typeId => {
			const tagType = tags.find(x => x.TagType.Id == typeId).TagType;

			const typeRes = { type: tagType, groups: [] as Array<{ group: TagGroupModel, tags: Array<TagModel> }> };

			let distinctGroups: Array<TagGroupModel> = [];

			distinctGroupIds.forEach(groupId => {
				const tagGroup = tags.find(x => x.TagGroup.Id == groupId).TagGroup;
				distinctGroups.push(tagGroup);
			});

			distinctGroups = distinctGroups.sort(this.sorterService.sortBy(TagGroupModel));

			distinctGroups.forEach(group => {
				const groupedTags = tags.filter(x => x.TagType.Id == tagType.Id && x.TagGroup.Id == group.Id);
				if (groupedTags.length) {
					typeRes.groups.push({ group, tags: groupedTags });
				}
			});

			result.push(typeRes);
		});

		this.data = result;

		this.selected = this.tagsService.getTagsSnapshot();
		this.dataBackup = Object.assign(new Array<TagModel>(), this.tagsService.getTagsSnapshot());

		this.subscriptions.push(this.tagsService.tagsObservable().subscribe((x) => {
			this.selected = x;
		}));

		this.subscriptions.push(this.tagsService.HboxFromObservable().subscribe((x) => {
			if (!x || x < this.minimum) {
				this.selectedMinimum = this.minimum;
			}
			else {
				this.selectedMinimum = x;
			}
		}));

		this.subscriptions.push(this.tagsService.HboxToObservable().subscribe((x) => {
			if (!x || x > this.maximum) {
				this.selectedMaximum = this.maximum;
			}
			else {
				this.selectedMaximum = x;
			}
		}));

		this.tagsService.showTags(true);
		this.show = true;
		this.tagsService.triggerUpdate(false);

		this.tagsService.modalIsOpen.next(true);

		this.events.showLoader(false);
	}

	isDisabled(tag: TagModel) {
		return this.selected.some(x => x.Id == tag.Id);
	}

	cancel() {
		if (this.router.url != "/filterlist") {
			this.tagsService.showTags(false);
		}
		else {
			this.tagsService.setTags(this.dataBackup);
			this.tagsService.setHboxFrom(this.hboxFromBackup);
			this.tagsService.setHboxTo(this.hboxToBackup);
		}

		this.tagsService.modalIsOpen.next(false);

		this.closeAndDestroy();
	}

	addTag(tag: TagModel) {
		if (!this.tagsService.getTagsSnapshot().find(x => x.Id == tag.Id)) {
			this.tagsService.addTag(tag);
		}
		else {
			this.tagsService.removeTag(tag.Id);
		}
	}

	onHboxChange() {
		if (this.selectedMinimum === this.minimum && this.selectedMaximum === this.maximum) {
			this.tagsService.setHboxFrom(null);
			this.tagsService.setHboxTo(null);
		}
		else {
			this.tagsService.setHboxFrom(this.selectedMinimum);
			this.tagsService.setHboxTo(this.selectedMaximum);
		}
	}

	public async goToTagFilterPage() {
		this.tagsService.modalIsOpen.next(false);
		this.closeAndDestroy(true);

		this.tagsService.showTags(true);
		this.tagsService.triggerUpdate(true);
		this.router.navigate(["/", "filterlist"]);

		const user = await this.userService.GetUser();
		await this.googleAnalyticsService.eventEmitter('filter', 'engagement', `${user.Uuid} applied filter`, 20);
	}

}
