import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PriceSettingEnum } from '@infrastructure/price-setting.enum';
import { BrowserInfoComponent } from "@modals/browser-info/browser-info.component";
import { LegalComponent } from "@modals/legal/legal.component";
import { LoadDataComponent } from '@modals/load-data/load-data.component';
import { PriceSettingModel } from '@models/price-setting.model';
import { LanguageService } from "@services/language.service";
import { ModalService } from '@services/modal.service';
import { OptionsDropdownService } from "@services/options-dropdown.service";
import { PriceSettingsService } from '@services/price-settings.service';
import { UserService } from '@services/user.service';
import { AppState } from '@shared/app-state';
import { PermissionConstants } from '@shared/permission-constants';
import { Observable, Subscription } from "rxjs";
import { DeliveryComponent } from 'src/_modals/delivery/delivery.component';
import { LogOutComponent } from 'src/_modals/logout.component';
import { EventsService } from 'src/_services/events.service';
import { NewsService } from 'src/_services/news.service';

@Component({
	selector: "options-dropdown",
	templateUrl: "options-dropdown.component.html",
	styleUrls: ["./options-dropdown.component.scss"],
	animations: [
		trigger("displayAnim", [
			state(
				"collapsed",
				style({
					top: "-550px"
				})
			),
			state(
				"expanded",
				style({

				})
			),
			transition("collapsed => expanded", [
				animate(
					'0.3s'
				)
			]),
			transition("expanded => collapsed", [
				animate(
					'0.3s'
				)
			])
		])
	]
})
export class OptionsDropdownComponent implements OnInit, OnDestroy {

	private dropdownClickedSubscription: Subscription;
	private dropDownLostFocusSubscription: Subscription;
	private adminModeEnabledSubscription: Subscription;
	private orderSyncDisabledSubscription: Subscription;

	private dropdownClickedNotifier: Observable<void>;
	private onDocumentClickEvent: EventEmitter<void> = new EventEmitter();

	public currentMenu: "container" | "languages" | "price" = "container";

	public state: "collapsed" | "expanded" = "collapsed";
	public initExpanded: boolean;
	public currentLanguage: string;
	public currentPriceSetting: PriceSettingModel;
	public availableLanguages: Array<string>;
	public priceSettings: Array<PriceSettingModel>;
	public showdataLoad: boolean = false;
	public showAdminModeToggle: boolean = false;
	public canToogleOrderItemSync: boolean = false;
	public adminModelEnabled: boolean = false;
	public orderSyncDisabled: boolean = false;

	constructor(
		private newsService: NewsService,
		private modalService: ModalService,
		private eventsService: EventsService,
		private optionsDropdownButtonService: OptionsDropdownService,
		private languageService: LanguageService,
		private userService: UserService,
		private router: Router,
		public appState: AppState,
		private priceSettingsService: PriceSettingsService
	) {
	}

	@HostListener('document:click', ['$event']) onStart(event: any) {
		if (!this.initExpanded) {
			this.onDocumentClickEvent.emit();
		}
		else {
			this.initExpanded = false;
		}
	}

	async ngOnInit() {
		const user = await this.userService.GetUser();
		this.showdataLoad = user?.UserPermissions.some(per => per == PermissionConstants.CanUseLoadData);
		this.showAdminModeToggle = user?.UserPermissions.some(per => per == PermissionConstants.IsAdmin);

		this.dropdownClickedNotifier = this.optionsDropdownButtonService.optionsDropdownButtonClickedAsObserveable();

		this.dropdownClickedSubscription = this.dropdownClickedNotifier.subscribe(async () => {
			if (this.state == "collapsed") {
				this.currentMenu = "container";
				this.state = "expanded";
				this.initExpanded = true;
				this.priceSettings = await this.initPriceSettingsForCustomer();
			}
		});

		this.dropDownLostFocusSubscription = this.onDocumentClickEvent.subscribe(() => {
			this.state = "collapsed";
		});

		this.adminModeEnabledSubscription = this.userService.adminModeEnabled.subscribe(async (value) => {
			this.adminModelEnabled = value

			this.canToogleOrderItemSync = user.canUseNonBlockingOfflineAddToBag && value;
		});

		this.orderSyncDisabledSubscription = this.userService.OrderSyncDisabledObservable().subscribe((value) => {
			this.orderSyncDisabled = value;
		});

		this.currentLanguage = this.languageService.currentLanguage ? this.languageService.currentLanguage : this.languageService.defaultLanguage;

		this.availableLanguages = this.languageService.getLanguages();

		this.priceSettings = await this.initPriceSettingsForCustomer();
	}

	ngOnDestroy() {
		if (this.dropdownClickedSubscription) {
			this.dropdownClickedSubscription.unsubscribe();
		}

		if (this.dropDownLostFocusSubscription) {
			this.dropDownLostFocusSubscription.unsubscribe();
		}

		if (this.adminModeEnabledSubscription) {
			this.adminModeEnabledSubscription.unsubscribe();
		}

		if (this.orderSyncDisabledSubscription) {
			this.orderSyncDisabledSubscription.unsubscribe();
		}
	}

	public ShowDelivery() {
		this.newsService.getDeliveryNews().then(res => {
			const newsJson = res;
			newsJson.content = newsJson.content
				.replace(/<strong>/gi, '<span class="font-medium">')
				.replace(/strong/gi, "span");

			this.modalService.create(DeliveryComponent, {
				content: newsJson
			});

			this.eventsService.showLoader(false);
		});
	}

	public ShowLegal() {
		this.newsService.getLegalNews().then(res => {
			const newsJson = res;
			newsJson.content = newsJson.content
				.replace(/<strong>/gi, '<span class="font-medium">')
				.replace(/strong/gi, "span");

			this.modalService.create(LegalComponent, {
				content: newsJson
			});

			this.eventsService.showLoader(false);
		});
	}

	public ShowLanguages() {
		this.initExpanded = true;
		this.currentMenu = this.currentMenu === "languages" ? "container" : "languages";
	}

	public ShowPriceSettings() {
		this.initExpanded = true;
		this.currentMenu = this.currentMenu === "price" ? "container" : "price";
	}

	public ShowSupport() {
		this.modalService.create(BrowserInfoComponent);
		this.eventsService.showLoader(false);
	}

	public AdminModeToggle() {
		this.userService.adminModeToggle();
	}

	public OrderSyncToggle() {
		this.userService.orderSyncToggle();
	}

	public ShowLoadData() {
		this.modalService.create(LoadDataComponent, { content: "" });
	}

	public ShowLogOut() {
		this.modalService.create(LogOutComponent, { content: "" });
		this.eventsService.showLoader(false);
	}

	public async changeLanguage(language: string, event: MouseEvent) {
		if (language === this.languageService.currentLanguage) {
			event.stopPropagation();
		}
		else {
			await this.languageService.useLanguage(language);
			this.currentLanguage = language;
		}
	}

	public gotoOrderInfo() {
		this.router.navigate(['/bag', 'order-info']);
	}

	public async initPriceSettingsForCustomer(): Promise<Array<PriceSettingModel>> {
		if (!this.appState.priceModuleEnabled.getValue()) {
			return;
		}

		const user = this.userService.currentUser$.value;

		let priceSettings = this.priceSettingsService.getAllowedPriceSettings(user);

		this.currentPriceSetting = priceSettings.find(p => p.PriceSetting === user.PriceSettingDisplay);

		if (!this.currentPriceSetting) {
			const currentPriceSetting = priceSettings.find(p => p.PriceSetting === PriceSettingEnum.AllowNone);
			this.changePriceSetting(currentPriceSetting);
		}

		return priceSettings;
	}

	public async changePriceSetting(priceSetting: PriceSettingModel, event: MouseEvent = null) {
		if (priceSetting === this.currentPriceSetting) {
			event?.stopPropagation();
		}
		else {
			this.currentPriceSetting = priceSetting;

			const user = this.userService.currentUser$.value;

			await this.priceSettingsService.patchUserPriceSettings(user, priceSetting);

			this.onDocumentClickEvent.emit();
		}
	}

	public async goToServiceManager() {
		this.router.navigate(['administration']);
	}

}
