import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { isLatinCharacters } from '../infrastructure/latin-character-test';
import { SessionService } from './session.service';

/**
 * @deprecated should be written out at some point
 */
@Injectable()
export class EngravingService {
    public engravingChanged: Subject<void> = new Subject<void>();

    constructor(private sessionService: SessionService) { }

    init(engraving: string) {
        this.engraving = engraving;
        this.engravingRepeat = engraving;
    }

    public set engraving(engraving: string) {
        this.sessionService.set('engraving', engraving);
        this.engravingChanged.next();
    }

    public get engraving(): string {
        return this.sessionService.get<string>("engraving");
    }

    public set engravingRepeat(engraving: string) {
        this.sessionService.set('engravingRepeat', engraving);
        this.engravingChanged.next();
    }

    public get engravingRepeat(): string {
        return this.sessionService.get<string>("engravingRepeat");
    }

    public engravingError(): string {
        let engravingError = "";

        if (this.engraving == null || this.engravingRepeat == null) {
            engravingError = "CUSTOMIZER.COMPONENTS.ENGRAVING.NOTUNIFORMERROR";
        } else if (this.engraving.length || this.engravingRepeat.length) {
            engravingError = this.engraving != this.engravingRepeat ? "CUSTOMIZER.COMPONENTS.ENGRAVING.NOTUNIFORMERROR" : "";
        }

        if(this.engraving?.startsWithEmptyString()) {
            engravingError = "BAG.ENGRAVINGEMPTYSTRING";
        }

        if (!isLatinCharacters(this.engraving)) {
            engravingError = "CUSTOMIZER.COMPONENTS.ENGRAVING.NOTLATINERROR";
        }

        return engravingError;
    }
}
