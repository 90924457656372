import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TagFilterModalComponent } from '@modals/tag-filter/tag-filter.component';
import { ManualConfigCollectionModel } from '@models/manual-config-collection-model';
import { TagModel } from '@models/tag';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { ModalService } from '@services/modal.service';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';
import { TagsService } from 'src/_services/tags.service';

@Directive()
export abstract class TagFilterComponent implements OnInit, OnChanges {

	@Input() inputTags: TagModel[] = [];
	@Input() selectedTag: TagModel;
	@Input() selectedFavoriteCollections: Array<ManualConfigCollectionModel>;
	@Output() tagClickedEmitter: EventEmitter<TagModel> = new EventEmitter();

	public tags: Observable<TagModel[]>;

	constructor(
		public tagsService: TagsService,
		private router: Router,
		private userService: UserService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private modalService: ModalService
	) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.inputTags && changes.inputTags.currentValue != changes.inputTags.previousValue) {
			this.init();
		}
	}

	ngOnInit() {
		this.init();
	}

	public abstract init(): void;

	async onClear() {
		// todo: move this to filterlist page
		this.tagsService.clearTags();

		if (this.router.url == "/filterlist") {
			this.modalService.create(TagFilterModalComponent, {});
		}

		const user = await this.userService.GetUser();

		await this.googleAnalyticsService.eventEmitter('filter', 'engagement', `${user.Uuid} cleared filters`, 20);
	}

	async tagClicked(tag: TagModel) {
		this.tagsService.removeTag(tag.Id);

		const user = await this.userService.GetUser();

		await this.googleAnalyticsService.eventEmitter('filter', 'engagement', `${user.Uuid} removed filter`, 20);
	}

	tagAdded(tag: TagModel) {
		console.log(tag);

		this.tagsService.SelectedTag = tag;
		this.selectedTag = tag;
		this.tagClickedEmitter.emit(tag);
	}

	async onEdit() {
		setTimeout(() => {
			this.modalService.create(TagFilterModalComponent, {});
		}, 300);
	}

}
