import { Type } from "class-transformer";
import { ModelModel } from "./api-models/model";
import { ComponentVariantModel } from "./component-variant";
import { CustomizerParams } from "./customizer-params";
import { ModelGroupModel } from "./model-group";
import { ModelSizeModel } from "./model-size";
import { OrderItemModel } from "./order-item";
import { TempleInclinationEnum } from "./variant-temple-inclination";

export class CustomizerRouteParams {

	@Type(() => ModelGroupModel)
	public modelGroup: ModelGroupModel;
	public model: ModelModel;
	public modelsize?: ModelSizeModel;
	public templeInclination?: TempleInclinationEnum;
	public orderItem?: OrderItemModel;
	public allVariants?: Array<ComponentVariantModel>;
	public modelconfigCode?: string;
	public customizerParams?: CustomizerParams;

	constructor(
		modelGroup: ModelGroupModel,
		model: ModelModel,
		modelsize?: ModelSizeModel,
		templeInclination?: TempleInclinationEnum,
		orderItem?: OrderItemModel,
		allVariants?: Array<ComponentVariantModel>,
		modelconfigCode?: string,
		customizerParams?: CustomizerParams
	) {
		this.modelGroup = modelGroup;
		this.model = model;
		this.modelsize = modelsize;
		this.templeInclination = templeInclination;
		this.orderItem = orderItem;
		this.allVariants = allVariants;
		this.modelconfigCode = modelconfigCode;
		this.customizerParams = customizerParams;
	}

}
