
export class DateResult {

    selectedDate: Date;
    errorText = "";
    validationError = false;
    day: number;
    month: number;
    year: number;

    constructor(date: Date) {
        this.selectedDate = date;
        if(date) {
            this.day = date.getDate();
            this.month = date.getMonth();
            this.year = date.getFullYear();
        }
    }

}