import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../app.constants';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class NewsService {

    private newsUrlBaseUrl: string = AppConstants.newsUrl;

    constructor(private http: HttpClient) { }

    public async getDeliveryNews(): Promise<any> {
        const res = await this.http.get<any>(`${this.newsUrlBaseUrl}`).toPromise();
        return res[0];
    }

    public async getLegalNews(): Promise<any> {
        const res = await this.http.get<any>(`${this.newsUrlBaseUrl}`).toPromise();
        return res[1];
    }

	public async getLimited() {
		return JSON.parse(JSON.stringify(limitedMockData));
		const res = await firstValueFrom(this.http.get(`${this.newsUrlBaseUrl}`));

		if (res) {
			return res[2];
		}
	}

}

export const limitedMockData = [
	{
		'id': '042',
		'name': '\'The Oval\'',
		'description': '\'The Oval\' is a beautiful temple designed with the feeling of the timeless peace in mind...',
		'technicalDescription': 'gold, platinum, polished, awesome',
		'numImages': 3
	}
];

export class LimitedData {
	id: string;
	name: string;
	description: string;
	technicalDescription: string;
	numImages: number;
}