import { DataTranslationModel } from "./data-translation-model";
import { VariantColorModel } from "./variant-color";
import { SimpleVariantSizeModel } from "./variant-size";

export class CustomizerSummaryModel {

	public ComponentUuid: string;
	public ComponentCode: string;
	public ComponentDescription: DataTranslationModel;
	public ComponentDescription2: DataTranslationModel;
	public ComponentVariantByPass: boolean;
	public ComponentVariantCode: string;
	public ComponentVariantOptional: boolean;
	public NotSelected: boolean;

	public SplittingAllowed: boolean;
	public ComponentSplittingDataLeft: string;
	public ComponentSplittingDataRight: string;

	public ComponentVariantSizeBypass: boolean;
	public ComponentVariantSizeDescription: string;

	public VariantColor: VariantColorModel;
	public VariantSize: SimpleVariantSizeModel;

}