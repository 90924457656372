<!-- pagination start -->

<nav class="small mb-3" *ngIf="pagingContext && pagingContext.showPaging()">

	<ul class="pagination m-1">
		<li class="mr-1" *ngIf="pagingContext.page > 1">
			<ui-button [buttonType]="'button'" [buttonStyle]="'secondary'" [size]="'xs'" [block]="false" (click)="gotoPage(1)">
				<i class="fa-solid fa-backward-fast"></i>
			</ui-button>
		</li>
		<li class="mr-1" *ngIf="pagingContext.page > 1">
			<ui-button [buttonType]="'button'" [buttonStyle]="'secondary'" [size]="'xs'" [block]="false" (click)="gotoPage(pagingContext.page - 1)">
				<i class="fa-solid fa-backward-step"></i>
			</ui-button>
		</li>
		<li class="mr-1" *ngFor="let page of pagingContext.pageArray">
			<ui-button [buttonType]="'button'" [buttonStyle]="'secondary'" [size]="'xs'" [block]="false" [active]="pagingContext.page === page" (click)="gotoPage(page)">{{ page }}</ui-button>
		</li>
		<li class="mr-1" *ngIf="pagingContext.page < pagingContext.totalPages && pagingContext.totalPages > 1">
			<ui-button [buttonType]="'button'" [buttonStyle]="'secondary'" [size]="'xs'" [block]="false" (click)="gotoPage(pagingContext.page + 1)">
				<i class="fa-solid fa-forward-step"></i>
			</ui-button>
		</li>
		<li class="mr-1" *ngIf="pagingContext.page < pagingContext.totalPages && pagingContext.totalPages > 1">
			<ui-button [buttonType]="'button'" [buttonStyle]="'secondary'" [size]="'xs'" [block]="false" (click)="gotoPage(pagingContext.totalPages)">
				<i class="fa-solid fa.forward-fast"></i>
			</ui-button>
		</li>
	</ul>
	<p class="small text-center mb-0">
		{{'COMMON.PAGE' | translate}} {{ pagingContext.page }}
		{{'COMMON.OF' | translate}} {{ pagingContext.totalPages }}
	</p>
</nav>
<!-- pagination end -->