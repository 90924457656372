<notification [notifier]="enduserDisplayClickedObserveable" [modal]="displayEnduserModalComponent">
    <div text>
        <span class="font-medium">{{'HOME.ENDUSERDISPLAY.READMORE1' | translate}} </span>
        <span>{{'HOME.ENDUSERDISPLAY.READMORE2' | translate}}</span>
    </div>
</notification>
<div class="container-fluid misc-category" *ngIf="isReady" [@fadeInOutTransition]>
    <page-header>{{'HOME.ORDER' | translate}} {{chosenMiscCategory.CategoryForDisplay}}</page-header>
    <div class="items">
        <skeleton-screen [hasLoaded]="miscModelsLoaded" [screens]="skeletonScreens" screenClass="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 product-item">
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 product-item" *ngFor="let group of miscGroups" (click)="goToGroup(group)">
                <div>
                    <dcg-image class="product-image" [src]="productImage(group)" [alt]="group.GroupForDisplay"></dcg-image>
                    <app-product-title [title]="group.GroupForDisplay"></app-product-title>
                </div>
            </div>
        </skeleton-screen>

    </div>
    <div>
        <copyright></copyright>
    </div>
</div>