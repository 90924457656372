import IDisableable from "../infrastructure/disableable.interface";
import { AssortmentLineModel } from './assortment-line-model';
import { ColorModel } from './color';
import { VariantColorModel } from "./variant-color";

export class VariantColorFromApiModel extends VariantColorModel implements IDisableable {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _isDisabled: boolean;

    public isDisabledByVariants = new Array<{ component: string; variant: string; size: string; color: string; }>();

    public set isDisabled(value: boolean) {
        this._isDisabled = value;
    }

    public get isDisabled() {
        return this._isDisabled;
    }

    public clearDisabled() {
        this.isDisabledByVariants = [];
        this.isDisabled = false;
    }

    public ToVariantColor(): VariantColorModel {
        return Object.assign(new VariantColorModel(this.Discontinued, this.New), this);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static createCopy(variantColorModel: VariantColorFromApiModel): VariantColorFromApiModel {

        const newObj = new VariantColorFromApiModel(variantColorModel.Discontinued ? new Date(variantColorModel.Discontinued) : null, variantColorModel.New);
        newObj.isDisabled = variantColorModel.isDisabled;

        newObj.Id = variantColorModel.Id;
        newObj.Uuid = variantColorModel.Uuid;
        newObj.Status = variantColorModel.Status;
        newObj.ColorUuid = variantColorModel.ColorUuid;
        newObj.Default = variantColorModel.Default;
        newObj.Order = variantColorModel.Order;
        newObj.AvailableForDisplay = variantColorModel.AvailableForDisplay;
        newObj.ComponentCode = variantColorModel.ComponentCode;
        newObj.AvailableOnModelgroups = variantColorModel.AvailableOnModelgroups;

        newObj.AssortmentLines = variantColorModel.AssortmentLines.map(x => AssortmentLineModel.createCopy(x));
        newObj.Color = ColorModel.createCopy(variantColorModel.Color);

        return newObj;
    }
}
