<div class="featured-container" *ngIf="isReady || showPageInitializingSkeleton" [@fadeInOutTransition]>
    <ng-container *ngIf="isReady">
        <div class="tag-header p-3">
            <h1>{{'HEADER.EXPIRED' | translate}}</h1>
        </div>
    </ng-container>

    <ng-container *ngIf="!isReady && showPageInitializingSkeleton">
        <div class="tag-skeleton">
        </div>
    </ng-container>

    <div class="container-fluid group">
        <div class="content" *ngIf="!showPageInitializingSkeleton || isReady">
            <div class="row mt-3">
                <div class="col-xs-4">
                    <h2 [innerHTML]="inspirationGroup.Name | translate | lindbergFont"></h2>
                </div>
                <div class="col-xs-8">
                    <div class="admin-functions-container">
                        <div>
                            <app-pager [pagingContext]="paging" (paged)="gotoPage($event)"></app-pager>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xs-12">

                    <ng-container *ngIf="expiredManualConfigs.length === 0 && hasLoadedModels">
                        <div class="favorites-empty">
                            <span>{{'FEATURED.NOEXPIRED' | translate}}</span>
                        </div>
                    </ng-container>
                    <skeleton-screen [hasLoaded]="!skeletonScreens || hasLoadedModels" [screens]="skeletonScreens">
                        <div class="inspirations-container">
                            <ng-container *ngFor="let expiredManualConfig of expiredManualConfigs">
                                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 expired-manual-config">
                                    <div class="inspiration-group">
                                        <div class="content-container">
                                            <div class="flex flex-column flex-center image-text-container">
                                                <div class="row availability-container">
                                                    <span>
                                                        {{ 'FEATURED.ITEM.INVALIDATED' | translate: {date: expiredManualConfig.InvalidatedAt | dateToLocal} }}
                                                    </span>
                                                    <p class="small muted">{{expiredManualConfig.InvalidatedReason}}</p>
                                                </div>
                                                <div class="row imagecontainer">
                                                    <dcg-image *ngIf="expiredManualConfig.imageUrl"
                                                        [src]="expiredManualConfig.imageUrl"></dcg-image>
                                                    <dcg-image *ngIf="expiredManualConfig.imageUrlRimlessSide"
                                                        [src]="expiredManualConfig.imageUrlRimlessSide"></dcg-image>
                                                </div>
                                                <div class="row model-row">
                                                    <div class="summary-container spacious">
                                                        <table class="table m-5">
                                                            <tr>
                                                                <th>
                                                                    <h5 class="mb-0">
                                                                        <ng-container
                                                                            *ngIf="!expiredManualConfig.Model?.IsRimless">
                                                                            <span
                                                                                class="font-medium pr-2">{{expiredManualConfig.Model?.Code}}</span>
                                                                            <span *ngIf="expiredManualConfig.ModelSize"
                                                                                [innerHTML]="expiredManualConfig.ModelSize?.Code | replaceHash"></span>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="expiredManualConfig.Model?.IsRimless">
                                                                            <span
                                                                                *ngIf="!isDisplay">{{'ENDUSER.GARNITURE'|translate}}</span>
                                                                            <span *ngIf="isDisplay"
                                                                                class="font-medium pr-2">{{expiredManualConfig.Model?.Code}}</span>
                                                                            <span *ngIf="isDisplay"
                                                                                [innerHTML]="expiredManualConfig.ModelSize?.Hbox"></span>
                                                                        </ng-container>
                                                                    </h5>
                                                                </th>
                                                                <th>{{'COMMON.DESCRIPTION'|translate}}</th>
                                                                <th>{{'COMMON.SIZE'|translate}}</th>
                                                                <th>{{'COMMON.COLOR'|translate}}</th>
                                                            </tr>
                                                            <tr
                                                                *ngFor="let variant of expiredManualConfig.SummaryModels">
                                                                <td>{{'CUSTOMIZER.COMPONENTS.' + variant.ComponentCode +
                                                                    '.NAME' | translate}}:</td>
                                                                <td>
                                                                    <ng-container
                                                                        *ngIf="!variant.ComponentVariantByPass">

                                                                        <ng-container *ngIf="variant.ComponentDescription">
                                                                            {{variant.ComponentDescription | dataTranslate | translate }}
                                                                        </ng-container>
                                                                        <ng-container *ngIf="variant.ComponentDescription2">
                                                                            {{variant.ComponentDescription2 | dataTranslate| translate}}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="!variant.ComponentVariantSizeBypass || variant.NotSelected">
                                                                        <ng-container
                                                                            *ngIf="(variant?.ComponentVariantOptional && variant?.NotSelected)">
                                                                            {{'COMMON.NONE' | translate}}</ng-container>
                                                                    </ng-container>
                                                                </td>
                                                                <td>
                                                                    <ng-container
                                                                        *ngIf="!variant.ComponentVariantSizeBypass">
                                                                        <ng-container
                                                                            *ngIf="(!variant?.ComponentVariantOptional || !variant?.NotSelected)">
                                                                            {{variant.ComponentVariantSizeDescription}}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </td>
                                                                <td>

                                                                    <app-combined-color-name
                                                                        *ngIf="variant.VariantColor"
                                                                        [color]="variant.VariantColor.Color"
                                                                        [variantColor]="variant.VariantColor">
                                                                    </app-combined-color-name>

                                                                    <ng-container
                                                                        *ngIf="!variant.VariantColor && !variant.ComponentVariantByPass && !variant.ComponentVariantSizeBypass">
                                                                        {{'CUSTOMIZER.COMPONENTS.COLOR.NOCOLOR' |
                                                                        translate}}
                                                                    </ng-container>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </skeleton-screen>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 mt-3">

                    <div class="admin-functions-container">
                        <app-pager [pagingContext]="paging" (paged)="gotoPage($event)"></app-pager>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="isReady">
            <copyright></copyright>
        </div>
    </div>
</div>