import { BrandEnum } from "@infrastructure/brand.enum";
import { ManualDefaultVariantModel } from "./manual-default-variant";

export class ManualDefaultModel {
    Id: number;
    ModelId: number;
    ModelUuid: string;
    ModelSizeId: number;
    ModelSizeUuid: string;
    ModelConfigId: number;
    ModelConfigUuid: string;
    ModelConfigCode: string;
    Brand: BrandEnum;
    ManualDefaultVariants: Array<ManualDefaultVariantModel>;
}
