import { Injectable } from '@angular/core';
import { HttpOfflineError } from '@shared/http-offline-error';
import { HttpPostOfflineError } from '@shared/httppost-offline-error';

@Injectable({
    providedIn: 'root'
})
export class ServiceUtils {

    constructor() {
    }

    public errorIsOffline(error: any) {
        // the serviceworker acts as an proxy server and will return 504 if offline
        const isOffline = error.error == "Offline" || error.status == 0 || error.status == 504 || error instanceof HttpOfflineError || error instanceof HttpPostOfflineError;
        return isOffline;
    }
}
