import { Injectable, OnDestroy } from '@angular/core';
import { UserPatchModel } from '@models/user-patch-model';
import { UserRefreshModel } from '@models/user-refresh-model';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { UserDataModel } from '../_models/user-data-model';
import { PermissionConstants } from '../shared/permission-constants';
import { EngravingService } from './engraving.service';
import { EnvService } from './env.service';
import { LocalstorageService } from './localstorage.service';
import { OfflineService } from './offline.service';

@Injectable()
export class UserService implements OnDestroy {

	private readonly userSessionKey = "userdata";
	private readonly adminModeSessionKey = "adminModeEnabled";
	private readonly orderSyncDisabledSessionKey = "orderSyncDisabled";
	private subscriptions = new Array<Subscription>();
	public currentUser$: BehaviorSubject<UserDataModel>;
	private adminModeEnabled$: BehaviorSubject<boolean>;
	private orderSyncDisabled$: BehaviorSubject<boolean>;

	public get currentUser() {
		return this.currentUser$.asObservable();
	}

	public get adminModeEnabled() {
		return this.adminModeEnabled$.asObservable();
	}

	public userCanAddChanges: Subject<void> = new Subject<void>();
	public engravingSubscription: Subscription = new Subscription();

	constructor(
		private localStorageService: LocalstorageService,
		private engravingService: EngravingService,
		private env: EnvService,
		private offlineService: OfflineService
	) {
		const user = this.localStorageService.get<UserDataModel>(this.userSessionKey);

		this.currentUser$ = new BehaviorSubject<UserDataModel>(user ? plainToClass(UserDataModel, user) : null);

		const adminModeEnabled = this.localStorageService.get<boolean>(this.adminModeSessionKey);
		this.adminModeEnabled$ = new BehaviorSubject<boolean>(this.currentUser$.value != null && this.currentUser$.value.isAdmin && (adminModeEnabled === true || adminModeEnabled == null));

		let orderSyncDisabled = this.localStorageService.get<boolean>(this.orderSyncDisabledSessionKey);

		if (orderSyncDisabled == null) {
			orderSyncDisabled = false;
			this.localStorageService.set(this.orderSyncDisabledSessionKey, orderSyncDisabled);
		}

		this.orderSyncDisabled$ = new BehaviorSubject<boolean>(orderSyncDisabled);

		this.subscriptions.push(this.engravingSubscription = this.engravingService.engravingChanged.subscribe(() => {
			this.userCanAddChanges.next();
		}));
	}

	ngOnDestroy(): void {
		this.engravingSubscription.unsubscribe();
	}

	public setUser(user: UserDataModel) {
		const currentUser = this.currentUser$.value;

		if (currentUser) {
			if (currentUser.LoginRegistered) {
				user.LoginRegistered = currentUser.LoginRegistered;
			}

			if (currentUser.IsLiteLogin) {
				user.IsLiteLogin = currentUser.IsLiteLogin;
			}
		}

		this.localStorageService.set(this.userSessionKey, user);
		this.currentUser$.next(user);
		this.validateAdminMode();
	}

	public async GetUser(): Promise<UserDataModel> {
		return new Promise<UserDataModel>((resolve, reject) => {
			resolve(this.currentUser$.value);
		});
	}

	async UserCanOnlyPlaceTestorder(customerChosen: boolean): Promise<boolean> {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return user.canOnlyTestOrder(customerChosen);
	}

	async UserHasAddToBagPermission(): Promise<boolean> {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return new Promise<boolean>((resolve) => {
			if (user.UserPermissions.some(y => y == PermissionConstants.CanAddToBag)) {
				resolve(true);
			}

			resolve(false);
		});
	};

	UserHasCanAddToBagPermission(): boolean {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return user.UserPermissions.some(y => y == PermissionConstants.CanAddToBag);
	};

	async UserCanAddToBag(): Promise<boolean> {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		const userHasAddToBagPermission = await this.UserHasAddToBagPermission();
		const engravngError = this.engravingService.engravingError();
		return userHasAddToBagPermission && engravngError.length == 0;
	}

	async HasProductlineStatus(productLineCodes: Array<string>): Promise<boolean> {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return user.hasAllProductlines(productLineCodes);
	}

	UserCanUseNonBlockingOfflineAddTobag(): boolean {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return user.canUseNonBlockingOfflineAddToBag && !user.OrderingOnBehalfOfNewPartner;
	}

	NewCustomerModuleEnabled(): boolean {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return user.newCustomerModuleEnabled;
	}

	PreciousModuleEnabled(): boolean {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return user.preciousModuleEnabled;
	}

	public PriceModuleEnabled(): boolean {
		const user = this.currentUser$.value;

		if (user === null) {
			return false;
		}

		return user.priceModuleEnabled;
	}

	private validateAdminMode() {
		const adminModeEnabled = this.localStorageService.get<boolean>(this.adminModeSessionKey);

		if (adminModeEnabled == null) {
			const newValue = this.currentUser$.value != null && this.currentUser$.value.isAdmin && (adminModeEnabled === true || adminModeEnabled == null);
			this.localStorageService.set(this.adminModeSessionKey, newValue);
			this.adminModeChanged();
		}
	}

	public adminModeToggle() {
		const adminModeEnabled = this.localStorageService.get<boolean>(this.adminModeSessionKey);
		this.localStorageService.set(this.adminModeSessionKey, adminModeEnabled == null ? false : !adminModeEnabled);
		this.adminModeChanged();
	}

	public adminModeChanged() {
		const adminModeEnabled = this.localStorageService.get<boolean>(this.adminModeSessionKey);
		this.adminModeEnabled$.next(adminModeEnabled === false ? false : this.currentUser$.value.isAdmin);
	}

	public AdminModeEnabled(): boolean {
		return this.currentUser$.value.isAdmin && this.adminModeEnabled$.value;
	}

	public OrderSyncDisabled(): boolean {
		return this.orderSyncDisabled$.value;
	}

	public OrderSyncDisabledObservable(): Observable<boolean> {
		return this.orderSyncDisabled$.asObservable();
	}

	public orderSyncToggle() {
		const orderSyncDisabled = this.localStorageService.get<boolean>(this.orderSyncDisabledSessionKey);
		this.localStorageService.set(this.orderSyncDisabledSessionKey, !orderSyncDisabled);
		this.orderSyncDisabled$.next(!orderSyncDisabled);
	}

	public async patchUser(user: UserPatchModel): Promise<void> {
		let url = `${await this.env.baseUrl()}/PatchUser`;
		return this.offlineService.patchWithOfflineHandling<void>(url, user);
	}

	public async getUserData(): Promise<UserDataModel> {
		let url = `${await this.env.baseUrl()}/Userdata`;
		const result = await this.offlineService.getDataWithOfflineCheck<UserDataModel>(url);
		return plainToClass(UserDataModel, result);
	}

	public async getUserDataFromLindberg(): Promise<UserRefreshModel> {
		let url = `${await this.env.baseUrl()}/GetUserDataFromLindberg`;
		const result = await this.offlineService.getDataWithOfflineCheck<UserRefreshModel>(url);
		const userFreshModel = plainToClass(UserRefreshModel, result)
		return userFreshModel;
	}
}
