import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { WhichOrderEnum } from '@infrastructure/which-order-enum';
import { CustomerModel } from '@models/customer';
import { OrderIdsModel } from '@models/orderIds-model';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';

@Component({
  selector: 'app-order-chooser',
  templateUrl: './order-chooser.component.html',
  styleUrls: ['./order-chooser.component.scss']
})
export class OrderChooserComponent implements OnChanges {
    @Input() customer: CustomerModel;
    @Output() orderIdChange = new EventEmitter<number>();
    public isReady: boolean;
    public orders: Array<OrderIdsModel>;

    constructor(private orderOnBehalfService : OrderOnBehalfService) { }

    async ngOnChanges(changes: SimpleChanges) {
        this.orders = await this.orderOnBehalfService.getCurrentActiveOrderIds();
        this.isReady = true;
    }

    async onOrderChosen(orderId: number) {
        const newOrderId = orderId || WhichOrderEnum.CreateNewOrder;
        this.orderIdChange.next(newOrderId);
    }

    cancel() {
        this.orderIdChange.next(null);
    }

    getOrderName(order: OrderIdsModel, index): number {
        const name = order.AxId > 0 ? order.AxId : index + 1;
        return name;
    }
}
