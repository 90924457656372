import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SelectableInputBaseComponent } from '../selectable-input-base.component';

@Component({
    selector: 'selectable-input',
    styleUrls: ['./selectable-input.scss'],
    template: `
        <input
            (focusin)="onFocus()"
            (focusout)="onFocusOut()"
            [class.open]="searches.length"
            type="text"
            [ngModel]="value"
            (input)="startSearch($event.target.value)"
            (keypress)="onKeyPress($event)"
            class="form-control"
            #input
            *ngIf="showSearchField"
        />
        <i (click)="onFocus()" class="fa-light fa-angle-down input-arrow" *ngIf="!showList"></i>
        <list-group
            (listClicked)="onSelect($event)"
            [optionalText]="optionalText"
            [optionsText]="optionsText"
            [defaultValue]="defaultValue"
            [stayOpen]="stayOpen"
            [showList]="showList"
            [searches]="searches"
            [districtClass]="districtClass"
            [optionGroupHeader]="optionGroupHeader"
            [optionGroupCount]="optionGroupCount"
        ></list-group>
    `
})
export class SelectableInputComponent<T> extends SelectableInputBaseComponent<T> implements AfterViewInit, OnChanges {
    @Input() resetOnFocus = false;
    @Input() showSearchField = true;
    @Input() optionGroupHeader;
    @Input() optionGroupCount;
    @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement>;

    public ngAfterViewInit() {
        if (this.startWithFocus) {
            this.onFocus();

            setTimeout(() => {
                this.setSearchData("");
            });
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if(changes.options){
            setTimeout(() => {
                this.value ? this.setSearchData(this.value) : this.setSearchData("");
            })
        }

        if (this.stayOpen && this.input) {
           this.onFocus();
        } else {            
            this.showList = this.startWithFocus;
        }
    }

    public onFocusOut() {
        if(this.resetOnFocus) {
            this.resetSearchValueToSelectedValue();
            this.showList = false;
        }
    }

    public onFocus() {
        setTimeout(() => {
            if(this.input){
                this.input.nativeElement.select();
                this.input.nativeElement.setSelectionRange(0, this.input.nativeElement.value.length);
            }
            if(this.resetOnFocus) {
                this.setSearchData("");
            }

            this.showList = true;
        });
    }

    public onSelect(search: T) {
        this.value = search ? this.optionsText(search) : this.optionalText;
        this.defaultValue = search;
        this.valueChange.emit(search);

        if (this.stayOpen) {
            return;
        }

        this.searches = [];
        this.showList = false;
    }

    public onKeyPress(event: KeyboardEvent) {
        if (event.key == "Enter") {
            if (this.searches.length) {
                this.onSelect(this.searches[0]);
            }
        }
    }
}
