import { Component, Input, OnInit } from "@angular/core";
import { SubscribingComponent } from '@infrastructure/subscribing-component';
import { AssortmentModel } from '@models/assortment-model';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { UserService } from '@services/user.service';
import { combineLatest } from 'rxjs';
import { AssortmentCalculator } from '@infrastructure/assortment-calculator';

@Component({
    selector: "app-first-time-delivery",
    templateUrl: 'first-time-delivery.component.html',
    styleUrls: ['./first-time-delivery.component.scss'],
})
export class FirstTimeDeliveryComponent extends SubscribingComponent implements OnInit {
    @Input() model: Array<AssortmentModel>;
    @Input() inline: boolean = false;

    public missingAssortments : boolean;

    constructor(private orderOnBehalfService: OrderOnBehalfService, private userService: UserService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.push(combineLatest([this.orderOnBehalfService.customerAsObservable(), this.userService.currentUser]).subscribe(([customer, user]) => {
            this.missingAssortments = AssortmentCalculator.FindMissingAssortments(this.model, customer ? customer.Assortments : user.Assortments).length > 0;
        }));
    }
}
