<div class="flex flex-column">
    <button class="close" *ngIf="showClose" (click)="onCancel()">
        <i class="fa-light fa-xmark"></i>
    </button>

    <ng-container *ngIf="!savingBagItems">
        <h3 class="mb-2">
            {{ 'MY.COLLECTIONS' | translate }}
            
            <span *ngIf="feedbackAfterSaving">
                <i class="fa-solid fa-check mr-1"></i> {{feedbackAfterSaving}}
            </span>
        </h3>


        <div class="collections-container mb-2">
            <checkbox *ngFor="let favoriteCollection of favoriteCollections"
                (checkedChange)="toggleFavoriteCollection(favoriteCollection)"
                [checked]="favoriteCollection.CurrentFavoriteUuid !== null && favoriteCollection.CurrentFavoriteUuid !== undefined" [label]="favoriteCollection.Name"
                class="m-2">
            </checkbox>
        </div>

        <span class="text-cta pointer ml-4 mb-1" (click)="showNameInput = !showNameInput">
            + {{ 'MY.COLLECTIONS.CREATE' | translate }}
        </span>

        <div class="favorite-collection-name" *ngIf="showNameInput">
            <input type="text" [(ngModel)]="favoriteCollectionName" maxlength="20"
                placeholder="{{ 'MY.COLLECTIONS.NAME' | translate }}" class="form-control mr-2" (keydown.enter)="toggleFavoriteCollection(null);" />
            <button (click)="toggleFavoriteCollection(null); " class="btn btn-primary btn-xs">
                {{ 'CREATE' | translate }}
            </button>
        </div>
        <ng-content></ng-content>
    </ng-container>

    <ng-container *ngIf="savingBagItems">
        <h4 class="mb-4">{{ 'MY.COLLECTIONS.SAVE.FRAMES' | translate }}</h4>

        <div class="favorite-collection-name mb-3" *ngIf="!bagItemsWereSaved">
            <input type="text" [(ngModel)]="favoriteCollectionName" maxlength="30"
                placeholder="favorite collection name" class="form-control mb-3" />

            <div class="buttons">
                <button (click)="saveBagItemsToFavoriteCollection(); " class="btn btn-cta mr-2">
                    {{ 'CUSTOMIZER.COMPONENTS.ADDTOINSPIRATION.ADD' | translate }}
                </button>
                <button (click)="onCancel()" class="btn btn-secondary ml-2">
                    {{ 'COMMON.CANCEL' | translate }}
                </button>
            </div>
        </div>
        <div class="favorite-collection-name mb-3" *ngIf="bagItemsWereSaved && favoriteCollection">
            <p class="pb-3" [innerHTML]="('MY.COLLECTIONS.BAG.ITEMS.SAVED' | translate: {favoriteCollectionName: favoriteCollection.Name})"></p>

            <div class="buttons text-center">
                <button class="btn btn-cta" (click)="gotoFavoriteCollection(favoriteCollection)">
                    {{ 'COMMON.GOTO' | translate }} '{{favoriteCollection.Name}}'</button>
                    <br>
                    <br>
                <button (click)="onCancel()" class="btn btn-secondary ml-2">
                    {{ 'COMMON.CLOSE' | translate }}
                </button>
            </div>

        </div>
    </ng-container>

</div>