import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LanguageService } from './language.service';

@Injectable()
export class TitleService {
    constructor(private title: Title, private translate: LanguageService) {}

    setTitle(title: string) {
        title = title ? String(title).replace(/<[^>]+>/gm, '') : "";
        this.title.setTitle(title);
        console.debug("PageView", title, window.location.href);
    }

    setTitleFromI18N(title: string | Array<string>, params?: object) {
        this.translate.get(title).subscribe((x) => {
            this.setTitle(x);
        });
    }

    getTitle(): string {
        return this.title.getTitle();
    }
}
