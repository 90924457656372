<div class="Images">
	<div class="image" *ngFor="let imageModels of imageModelArrays">
		<div *ngFor="let model of imageModels">
			<dcg-image class="misc-dcg-image" [src]="model.Src" [alt]="model.ECommerceNo" (click)="zoom(model.ECommerceNo, model.ImageCount)"></dcg-image>
			<!-- <dcg-image class="zoom" [alt]="'IMAGEALTS.ZOOM' | translate" [src]="'assets/icons/zoom_icon.svg'" (click)="zoom(model.ECommerceNo, model.ImageCount)"></dcg-image> -->
			<span class="zoom back" (click)="zoom(model.ECommerceNo, model.ImageCount)">
				<i class="fa-light fa-magnifying-glass"></i>
			</span>
		</div>
	</div>
</div>