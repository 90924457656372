import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { VariantSizeModel } from 'src/_models/variant-size';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ImageService } from 'src/_services/image.service';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { SorterService } from '../../../../_services/sorter.service';
import { FadeInOutTransition } from '../../../../shared/transitions/fade-in-out.transition';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
    selector: 'clip-length',
    styleUrls: ['./clip-length.component.scss'],
    animations: [
        FadeInOutTransition()
    ],
    template: `

        <div class="flex flex-align-end" >
            <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)" [overrideTranslateName]="'CUSTOMIZER.COMPONENTS.CLIPLENGTH.NAME'"></customizer-component-header>
            <icon-info (click)="showInfo = !showInfo" class="header-icon"></icon-info>
        </div>
        <div *ngIf="items.length">
            <div class="form-group has-padding-horizontal-30px" *ngIf="getSelectedVariant.SplittingAllowed && variants.length > 1">
                <small>{{"CUSTOMIZER.COMPONENTS.CLIPLENGTH.DIFFERENTLENGTHS" | translate}}</small>
                <ui-switch [checked]="getSelectedVariant.SplittingData !== null" (change)="toggle($event)" size='small' class="pull-right"></ui-switch>
            </div>
            <customizer-list
                class="bordered"
                *ngIf="!getSelectedVariant.SplittingData"
                [selectedIndex]="selectedIndex"
                (selectedChange)="onSelect($event)"
                [items]="items"
                [name]="'Size'"
                [discontinued]="'Discontinued'"
                [(scrollPosition)]="menu.menuDetails.scrollPosition"
                [editMode]="state === customizerStateEnum.EDITORDER"
                [variantOrModelIsNew]="variantOrModelIsNew"
                [image]="getVoid"
            ></customizer-list>
            <div *ngIf="getSelectedVariant.SplittingData" class="different-lengths">
                <div>
                <span class="side">Left</span>
                <div class="customizer-item" *ngFor="let size of items" (click)="setSize('left', size)">
                    <div class="snip" [ngClass]="{'is-selected': size.Size === getSelectedVariant.SplittingData.left}"></div>
                    <div class="name">{{size.Size}}</div>
                </div>
                </div>
                <div>
                <span class="side">Right</span>
                <div class="customizer-item" *ngFor="let size of items" (click)="setSize('right', size)">
                    <div class="snip" [ngClass]="{'is-selected': size.Size === getSelectedVariant.SplittingData.right}"></div>
                    <div class="name">{{size.Size}}</div>
                </div>
                </div>
            </div>
        </div>
        <div *ngIf="showInfo" [@fadeInOutTransition] class="spacer flex flex-column flex-center">
            <h6>{{ 'CUSTOMIZER.COMPONENTS.CLIPLENGTH.INFO' | translate }}</h6>
            <dcg-image class="illustration-image" [src]="infoImage()" [alt]="'CUSTOMIZER.COMPONENTS.CLIPLENGTH.INFO' | translate"></dcg-image>
        </div>
    `
})

export class ClipLengthComponent extends BaseCustomizerComponent implements OnInit {

    public items: Array<VariantSizeModel> = [];
    public selectedIndex: number = 0;
    public sizeGroups: Array<any> = [];
    public showInfo: boolean = false;

    constructor(
        public events: EventsService,
        public apiService: DataApiService,
        public imageService: ImageService,
        private sorter: SorterService,
        public configurationService: ConfigurationService
    ) {
        super(events, apiService, configurationService);
    }

    async initData() {
        this.events.showLoader(true);
        await this.fetchData();
        this.events.showLoader(false);
    }

    async onChanges(changes: SimpleChanges) {
        this.selectedIndex = this.items.findIndex(x => x.Uuid == this.getSelectedVariant.VariantSize.Uuid);

        this.events.showLoader(true);
        await this.fetchData();
        this.events.showLoader(false);
    }

    public async fetchData() {
        this.items = await this.fetch();
        this.selectedIndex = this.items.findIndex(x => x.Uuid == this.getSelectedVariant.VariantSize.Uuid);
    }

    public infoImage = () => this.imageService.GetComponentInfoImage(this.getSelectedVariant, 'size');

    setSize(side: string, size: VariantSizeModel) {
        this.events.showLoader(true);

        const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        newVariant.VariantSize = size;

        if (side == 'left') {
            newVariant.SplittingData = { left: size.Size, right: newVariant.SplittingData.right };
        } else {
            newVariant.SplittingData = { left: newVariant.SplittingData.left, right: size.Size };
        }

        this.updateComponentSize(new ComponentVariantChangeModel(this.getSelectedVariant, newVariant, null));
    }

    toggle(event: boolean) {
        this.events.showLoader(true);

        const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        newVariant.VariantSize = this.items[this.selectedIndex];

        if (event) {
            newVariant.SplittingData = { left: newVariant.VariantSize.Size, right: newVariant.VariantSize.Size };
        } else {
            newVariant.SplittingData = null;
        }

        this.updateComponentSize(new ComponentVariantChangeModel(this.getSelectedVariant, newVariant, null));
    }

    onSelect(index: number) {
        this.events.showLoader(true);

        const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        newVariant.VariantSize = this.items[index];
        this.updateComponentSize(new ComponentVariantChangeModel(oldVariant, newVariant, null));
    }

    async fetch(): Promise<Array<VariantSizeModel>> {
        const variant = this.getSelectedVariant;
        variant.VariantSizeViewed = true;
        const apiData = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid);
        await this.RunDisablingSystem(apiData);

        const sizes = apiData[0].VariantSizes.filter(x => !x.isDisabled);

        return sizes.sort(this.sorter.sortBy(VariantSizeModel));
    }
    getVoid = () => {
        return null;
    }

}
