import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModelInformationModule } from '@app/Information/model-information.module';
import { AddToFavoriteComponent } from '@app/customizer/components/add-to-favorite/add-to-favorite';
import { GetPriceComponent } from '@app/customizer/components/get-price/get-price.component';
import { StartKitsModalComponent } from '@modals/start-kits-modal/start-kits-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { SharedModule } from 'src/app/shared/shared.module';
import { CombinedColorNameComponent } from './components/combined-color-name/combined-color-name.component';
import { CustomizerColorViewItemComponent } from './components/customizer-color-view-item/customizer-color-view-item';
import { CustomizerFormsFixturesComponent } from './components/customizer-forms-fixtures/customizer-forms-fixtures.component';
import { CustomizerStatisticsComponent } from './components/customizer-statistics/customizer-statistics.component';
import { CustomizerSummaryComponent } from './components/customizer-summary/customizer-summary';
import { FeaturedModelComponent } from './components/featured-model/featured-model';
import { OrderChooserComponent } from './components/order-chooser/order-chooser.component';
import { RealtimeWrapperComponent } from './components/realtime-wrapper/realtime-wrapper';
import { StartKitChooserComponent } from './components/start-kit-chooser/start-kit-chooser.component';

@NgModule({
	declarations: [
		CustomizerFormsFixturesComponent,
		CustomizerSummaryComponent,
		CustomizerColorViewItemComponent,
		FeaturedModelComponent,
		AddToFavoriteComponent,
		StartKitChooserComponent,
		OrderChooserComponent,
		CombinedColorNameComponent,
		CustomizerStatisticsComponent,
		StartKitsModalComponent,
		GetPriceComponent,
		RealtimeWrapperComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		TranslateModule,
		MomentModule,
		ReactiveFormsModule,
		ModelInformationModule
	],
	exports: [
		CustomizerFormsFixturesComponent,
		CustomizerSummaryComponent,
		CustomizerColorViewItemComponent,
		FeaturedModelComponent,
		AddToFavoriteComponent,
		StartKitChooserComponent,
		OrderChooserComponent,
		CustomizerStatisticsComponent,
		StartKitsModalComponent,
		GetPriceComponent,
		RealtimeWrapperComponent
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class CustomizerSharedModule { }
