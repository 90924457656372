import { SimpleComponentVariantModel } from './component-variant';
import { OrderItemState } from './order-item-state';

export interface IOrderItemDiscount {
    Engraving: string;
    Reference: string;
    Variants: Array<SimpleComponentVariantModel>;
}

export interface IDiscountValidation {
    DisountValidationState(): IOrderItemDiscount;
}

export class VariantsToDiscountModel implements IOrderItemDiscount {
    constructor(
        public Engraving: string,
        public Reference: string,
        public Variants: Array<SimpleComponentVariantModel>
    ) {}
}

export class OrderItemToDiscountModel implements IOrderItemDiscount {
    public Engraving: string;
    public Reference: string;
    public Variants: Array<SimpleComponentVariantModel>;

    constructor(public orderItem: OrderItemState) {
        this.Engraving = orderItem.Engraving;
        this.Reference = orderItem.Reference;
        this.Variants = orderItem.OrderLines.map(x => x.OriginalComponentVariant);
    }
}

export class EmptyDiscountModel implements IOrderItemDiscount {
    public Engraving: string;
    public Reference: string;
    public Variants: Array<SimpleComponentVariantModel> = new Array<SimpleComponentVariantModel>();
}
