<div [ngClass]="{'is-temple': imageClass === 'temple'}">
	<div class="general-information">
		<availability-text [date]="discontinued" [new]="new" [newLargeText]="true" [useNewDetails]="true" [model]="model" *ngIf="!ModelCodeNotAvailable"></availability-text>
	</div>
	<img [ngClass]="{'is-temple': imageClass === 'temple', 'model-image': true}" class="" [src]="imageUrl" *ngIf="!ModelCodeNotAvailable" [alt]="title">
	<div *ngIf="!ModelCodeNotAvailable">
		<div class="model-item-text container-fluid">
			<span class="span-left">{{description}}
				<ul class=" list-unstyled" [title]="codes.join('&nbsp;')">
					<li *ngFor="let code of codes" [innerHTML]="code | replaceHash">
					<li></li> <!--dummy in case of missing codes (styling) -->
				</ul>
			</span>
			<span class="span-right pull-right">{{title}}</span>
		</div>
	</div>
	<model-information class="availability-information" *ngIf="ModelCodeNotAvailable" [ModelCodeNotAvailable]="ModelCodeNotAvailable"></model-information>
	<div *ngIf="ModelCodeNotAvailable" style="visibility: hidden">
		<div class="model-item-text container-fluid">
			<span class="span-left">dummy
				<ul class=" list-unstyled" [title]="'dummy'">
					<li>dummy</li>
				</ul>
			</span>
			<span class="span-right pull-right">dummy</span>
		</div>
	</div>
</div>