import { CaseAlternativeGroupLineModel } from '@models/api-models/case-alternative-groupline';
import { ComponentVariantFromApiModel } from '@models/api-models/component-variant-fromapi';
import { ComponentModel } from '@models/component';
import { DataTranslationModel } from '@models/data-translation-model';
import { VariantSizeFromApiModel } from '@models/variant-size-from-api';

export class ComponentVariantFromApiFactory {


    static createCopy(plainComponent: ComponentVariantFromApiModel) {

        const newComponent = new ComponentVariantFromApiModel(
            plainComponent.Id,
            plainComponent.Uuid,
            plainComponent.Code,
            plainComponent.Bypass,

            new DataTranslationModel(
                plainComponent.Description.TranslationKey,
                plainComponent.Description.Fallback),

            new DataTranslationModel(
                plainComponent.Description2?.TranslationKey,
                plainComponent.Description2?.Fallback),

            plainComponent.Status,
            plainComponent.Default,
            plainComponent.ConfigurationId,
            plainComponent.ModelConfigUuid,
            
            new DataTranslationModel(
                plainComponent.Group.TranslationKey,
                plainComponent.Group.Fallback),
                
            new DataTranslationModel(
                plainComponent.GroupDescription?.TranslationKey,
                plainComponent.GroupDescription?.Fallback),

            plainComponent.GroupOrder,

            plainComponent.AvailableForDisplay,

            new ComponentModel(
                plainComponent.Component.Id,
                plainComponent.Component.Uuid,
                plainComponent.Component.Code,
                plainComponent.Component.Description,
                plainComponent.Component.Status,
                this.createDateType(plainComponent.Component.Release),
                this.createDateType(plainComponent.Component.Discontinued),
                plainComponent.Component.New),

            plainComponent.EngravingPossible,
            plainComponent.Order,
            plainComponent.SplittingAllowed,
            plainComponent.Optional,

            plainComponent.VariantSizes
                .map(x => VariantSizeFromApiModel.createCopy(x)),

            plainComponent.Cases?.map(x => CaseAlternativeGroupLineModel.createCopy(x)),

            plainComponent.isDisabled,
            this.createDateType(plainComponent.Discontinued),
            plainComponent.OmitDiscontinuedLabel,
            plainComponent.New,
            plainComponent.Brand
        );

        return newComponent;

    }

    private static createDateType(fromDate: Date): Date {
        if(fromDate)
            return new Date(fromDate);
        else
            return null;
    }

}