import { AngleConstants } from "../shared/angle-constants";

export class ImageModel {
    constructor(public name: AngleConstants, public url: string) {
    }
}

export class ImageGetModel {
    public ModelGroupCode: string;
    public ModelCode: string;
    public Angle: string;
    public ComponentVariantCode: Array<{variantCode: string, componentCode: string}>;
    public ComponentColorList: Array<[string, string, string]>;
    public ConfigurationCode: string;
    public presentAsPrecious: boolean;
}
