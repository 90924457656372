import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@services/env.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CustomiserApiService {

	public baseUrl: () => Promise<string> = async () => (await this.env.DataApiurl()) + '/v2/';

	constructor(
		private http: HttpClient,
		private env: EnvService
	) { }

	public async get<T>(endpoint: string) {
		let result = await firstValueFrom(this.http.get<T>(await this.baseUrl() + endpoint));

		return result;
	}
	public async getWithQuery<T>(endpoint: string) {
		return await firstValueFrom(this.http.get<T>(await this.baseUrl() + endpoint));
	}

	public async post<T>(endpoint: string, payload: string) {

		return await firstValueFrom(this.http.post<T>(await this.baseUrl() + endpoint, payload,));
	}

	public async postJson<T>(endpoint: string, payload: any) {
		return await firstValueFrom(this.http.post<T>(await this.baseUrl() + endpoint, payload,
			{
				headers: new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
			}));
	}
	public async patch<T>(endpoint: string, payload: any) {
		return await firstValueFrom(this.http.patch<T>(await this.baseUrl() + endpoint, payload));
	}

	public async delete<T>(endpoint: string) {
		let result = await firstValueFrom(this.http.delete<T>(await this.baseUrl() + endpoint));

		return result;
	}
}
