import { Component, SimpleChanges } from "@angular/core";
import { ComponentVariantFromApiModel } from "@models/api-models/component-variant-fromapi";
import { VariantColorModel } from "@models/variant-color";
import { VariantSizeModel } from "@models/variant-size";
import { ConfigurationService } from '@services/configuration.service';
import { ImageService } from "@services/image.service";
import { SorterService } from "@services/sorter.service";
import { ComponentCodeConstants } from "@shared/component-constants";
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { AngleConstants } from "../../../../shared/angle-constants";
import { BaseCustomizerComponent } from "../../../../shared/base-customizer-component";

@Component({
	styleUrls: ['./gemstone.scss'],
	templateUrl: 'gemstone.html',
})
export class GemstoneComponent extends BaseCustomizerComponent {

	public sizes: Array<VariantSizeModel> = [];
	public selectedSizeIndex: number = -1;
	public selectedColor: VariantColorModel = null;
	public showInfo: boolean;
	public showNoneChoiceOption: boolean = false;
	public showGemstoneOptions: boolean = false;
	public noneChoiceTranslation: string;
	public selectableVariants: Array<ComponentVariantFromApiModel> = [];
	public availableOnVariants: string = null;
	public notAvailableOnColors: string = null;
	public variantCode: string = null;
	public noGemstoneImage: string = null;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private sorter: SorterService,
		private imageService: ImageService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	onChanges(changes: SimpleChanges): void {
		this.setSelectedSizeIndex(this.sizes);
	}

	async initData() {
		this.events.showLoader(true);

		this.setImageSliderByName(AngleConstants.Perspective);

		this.sizes = await this.fetchSizeData();
		this.selectableVariants = await this.fetchColorData();

		const gemstoneVariant = this.variants.filter(v => v.Component.Code == ComponentCodeConstants.Gemstone)[0];
		let chosenSizeIndex = -1; //no variant
		const isDefault = gemstoneVariant?.Default;

		if (gemstoneVariant.Optional && isDefault) {
			chosenSizeIndex = this.sizes.findIndex(s => s.Id === gemstoneVariant.VariantSize.Id);
		}
		else {
			chosenSizeIndex = -1; //choose none

			if (isDefault) {
				chosenSizeIndex = this.sizes.findIndex(s => s.Id === gemstoneVariant.VariantSize.Id);
			}
		}

		this.onSelect(chosenSizeIndex, null);

		this.noneChoiceTranslation = `CUSTOMIZER.COMPONENTS.GEMSTONE.NOGEMSTONE`;

		this.noGemstoneImage = this.imageService.GetNoGemstoneImage(this.modelGroup.ProductLineCode);

		this.events.showLoader(false);
	}

	toggleGemstoneOptions() {
		this.onSelect(-1, null)
	}

	setSelectedSizeIndex(sizes) {
		if (this.selectedSizeIndex === -1) {
			return;
		}

		if (this.getSelectedVariant.VariantSize) {
			this.selectedSizeIndex = sizes.findIndex(x => x?.Uuid == this.getSelectedVariant.VariantSize.Uuid);
		}
		else {
			this.selectedSizeIndex = 0;
		}
	}

	onSelect(index: number, color: VariantColorModel) {
		const newVariant = Object.assign(
			new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New),
			this.getSelectedVariant
		);

		const oldVariant = Object.assign(
			new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New),
			this.getSelectedVariant
		);

		let doSizeUpdate = this.selectedSizeIndex !== index;
		let doColorUpdate = this.selectedColor !== color;

		this.events.showLoader(true);

		this.selectedSizeIndex = index;
		let size = this.sizes[index];
		newVariant.VariantSize = size;
		newVariant.NotSelected = size == null;

		if (doSizeUpdate && !doColorUpdate) {
			this.updateComponentSize(new ComponentVariantChangeModel(oldVariant, newVariant, null));

			setTimeout(() => {
				if (newVariant.VariantColor) {
					color = newVariant.VariantColor;
					this.updateColor(oldVariant, newVariant, color)
				}
			}, 50);
		}
		else {
			this.updateColor(oldVariant, newVariant, color)
		}
	}

	updateColor(oldVariant: ComponentVariantModel, newVariant: ComponentVariantModel, color: VariantColorModel) {
		newVariant.VariantColor = color;
		newVariant.NotSelected = color == null;

		this.selectedColor = color;

		setTimeout(() => {
			this.updateVariant(new ComponentVariantChangeModel(oldVariant, newVariant, null));
		}, 50);
	}

	async fetchSizeData(): Promise<Array<VariantSizeModel>> {
		const variant = this.getSelectedVariant;
		variant.VariantSizeViewed = true;

		const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid);
		await this.RunDisablingSystem(apidata);

		this.variantCode = apidata[0].Code;

		const variantSizes = apidata[0].VariantSizes.filter(x => !x.isDisabled).sort(this.sorter.sortBy(VariantSizeModel));
		variantSizes.forEach(variantSize => {
			variantSize.ComponentCode = ComponentCodeConstants.Gemstone;
		});

		return variantSizes;
	}

	async fetchColorData(): Promise<Array<ComponentVariantFromApiModel>> {
		this.availableOnVariants = null;
		this.notAvailableOnColors = null;

		const variant = this.getSelectedVariant;
		variant.VariantColorViewed = true;

		const apidata = await this.configurationService.getVariants(
			this.configuration.ModelConfigurationUuid,
			this.modelGroup,
			null,
			variant.Uuid,
			variant.VariantSize?.Uuid
		);

		await this.RunDisablingSystem(apidata);

		return apidata.filter(x => !x.isDisabled);
	}

	getImage = (variantSizeModel: VariantSizeModel) => {
		return this.imageService.GetComponentDetailPreciousImage(this.getSelectedVariant.Component.Code, this.variantCode, variantSizeModel, this.modelGroup.ProductLineCode);
	}

}
