import { Component } from '@angular/core';
import { BreadcrumbService } from 'src/_services/breadcrumb.service';
import { BaseModal } from '../base-modal';

@Component({
	selector: 'delivery-modal',
	styleUrls: ['./delivery.component.scss'],
	template: `
        <div class="modal-body">
        	<button class="close" (click)="closeAndDestroy()">
        		<i class="fa-light fa-xmark"></i>
        	</button>
        	<div class="delivery-item">
        		<div>
        			<h3>{{content.name}}</h3>
        			<div [innerHTML]="content.content | lindbergFont"></div>
        		</div>
        	</div>
        </div>`
})
export class DeliveryComponent extends BaseModal {

	content: { name: string, header: string, content: string } = null;

	constructor(
		breadcrumbService: BreadcrumbService) {
		super(breadcrumbService);
	}

}
