export class ManualConfigCollectionModel {
    Id: number;
    Uuid: string;
    Name: string;
    Icon: string;
    CreatedAt: Date;
    LastChangedAt: Date;
    CreatedBy: string;
    LastChangedBy: string;
    CurrentFavoriteUuid: string;
    Selected: boolean;
    HitCount: number;
}