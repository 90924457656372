<customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">

	<ng-container *ngIf="loading">
		<div class="parts-list">
			<dcg-image class="spinner" [src]="'/assets/animations/Framespinner.gif'"></dcg-image>
			<div class="smaller">{{ 'CUSTOMISER.MENUS.PARTS.LOADING' | translate }}</div>
		</div>
	</ng-container>

	<div *ngIf="!loading" class="parts-list">
		<ng-container *ngIf="partsAndPrices && partsAndPrices.length > 0">
			<div *ngFor="let part of partsAndPrices">
				<checkbox ngClass="checkbox-menu" [isPrecious]="isPrecious" [id]="part.name" [label]="'PARTS.' + part.name | translate" [icon]="imageService.getPartsIcon(part.name)" [checked]="selectedParts?.includes(part.name)" (click)="togglePart(part.name)" />

				<ng-container *ngIf="showPrices">
					<ng-container *ngIf="!pricesLoaded">
						<div class="pv">{{ 'CUSTOMISER.MENUS.PARTS.LOADING_PRICES' | translate }}</div>
					</ng-container>
					<ng-container *ngIf="pricesLoaded">
						<app-price-viewer class="pv" [priceItem]="part.priceItem"></app-price-viewer>
					</ng-container>
				</ng-container>
			</div>

			<div class="info">{{ 'CUSTOMISER.MENUS.PARTS.HEADS_UP' | translate }}</div>
		</ng-container>

		<ng-container *ngIf="!partsAndPrices || partsAndPrices.length === 0">
			<div class="smaller">{{ 'CUSTOMISER.MENUS.PARTS.NO_PARTS' | translate }}</div>
		</ng-container>
	</div>

</customizer-component-header>