import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'nbsp'
})
export class NbspPipe implements PipeTransform {

	transform(value: string, ...args: unknown[]): string {
		let re = /\ /gi;
		let result = value.replace(re, '&nbsp;');

		return result;
	}

}
