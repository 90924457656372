import { Component, SimpleChanges } from '@angular/core';
import { VariantSizeFromApiModel } from '@models/variant-size-from-api';
import { ConfigurationService } from '@services/configuration.service';
import { ImageService } from '@services/image.service';
import { ComponentCodeConstants } from '@shared/component-constants';
import { CustomizerStateConstants } from '@shared/customizer-state-constants';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { VariantSizeModel } from 'src/_models/variant-size';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ComponentVariantChangeModel } from '../../../../_models/component-variant-change';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { BaseCustomizerComponent } from '../../../../shared/base-customizer-component';
@Component({
    selector: 'gemstone-size',
    templateUrl: 'gemstone-size.html',
    styleUrls: ['gemstone-size.scss'],
    animations: [
        FadeInOutTransition()
    ]
})

export class GemstoneSizeComponent extends BaseCustomizerComponent {
    public sizes: Array<VariantSizeFromApiModel> = [];
    public selectedSizeIndex: number = -1;
    public variantCode: string = null;
    public noneChoiceImage: string = null;
    public noneChoiceTranslation: string;
    public adjustMargin: boolean = false;

    constructor(
        public events: EventsService,
        public apiService: DataApiService,
        private sorter: SorterService,
        private imageService: ImageService,
        public configurationService: ConfigurationService
    ) {
        super(events, apiService, configurationService);
    }

    onChanges(changes: SimpleChanges): void {
    }

    async initData() {
        this.events.showLoader(true);
        this.adjustMargin = this.menu.parentMenu.translateName === ComponentCodeConstants.Nosepad;

        this.setImageSliderByName(AngleConstants.Perspective);
        this.sizes = await this.fetch();
        this.noneChoiceImage = this.imageService.GetNoGemstoneImage(this.modelGroup.ProductLineCode);
        this.noneChoiceTranslation = `CUSTOMIZER.COMPONENTS.GEMSTONE.NOGEMSTONE`;
        if (this.variants.filter(v => v.Component.Code === ComponentCodeConstants.Gemstone)[0].Optional) {
            this.sizes.unshift(null);
        }
        if (this.getSelectedVariant.Optional && this.state !== CustomizerStateConstants.EDITORDER) {
            this.selectedSizeIndex = 0;
        } else {
            this.selectedSizeIndex = this.sizes.findIndex(x => x?.Uuid == this.getSelectedVariant.VariantSize?.Uuid);
        }
        this.events.showLoader(false);
    }

    onSizeSelect(index: number) {

        this.events.showLoader(true);
        this.selectedSizeIndex = index;

        const variantSize = this.sizes[index];

        const newVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        const oldVariant = Object.assign(new ComponentVariantModel(this.getSelectedVariant.Discontinued, this.getSelectedVariant.New), this.getSelectedVariant);
        newVariant.VariantSize = variantSize;
        const hasNoSize = variantSize === null;
        newVariant.NotSelected = hasNoSize;
        if (hasNoSize) {
            newVariant.VariantColor = null;
        }

        this.updateComponentSize(new ComponentVariantChangeModel(oldVariant, newVariant, null));
    }

    async fetch(): Promise<Array<VariantSizeFromApiModel>> {
        const variant = this.getSelectedVariant;
        variant.VariantSizeViewed = true;



        const apidata = await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup, null, variant.Uuid);
        await this.RunDisablingSystem(apidata);
        const variantSizes = apidata[0].VariantSizes.sort(this.sorter.sortBy(VariantSizeModel));

        this.variantCode = apidata[0].Code;

        return variantSizes;
    }

    getImage = (variantSizeModel: VariantSizeModel) => {
        return this.imageService.GetComponentDetailPreciousImage(this.getSelectedVariant.Component.Code, this.variantCode, variantSizeModel, this.modelGroup.ProductLineCode);
    }
}
