import { Type } from "class-transformer";
import { BaseCustomizerComponent } from 'src/shared/base-customizer-component';
import { ColorGroupModel } from './color-group';
import { MenuComponentModel } from './menu-component';

export class CustomizerMenuPartModel {
    public id: number;
    public name: string;
    public translateName: string;
    public translateDescription: string;
    public icon: string;
    public useForUpselling: boolean;
    public isVisited: boolean = false;
    public isVisiting: boolean = false;
    public component: typeof BaseCustomizerComponent;
    public collapsed: boolean = false;
    public contentGrouping: boolean = true;
    public isVisible: boolean = true;
    public scrollPositionReady: boolean = false;
    public error: boolean = false;
    public matchColor: number = 0;

    public menuDetails: MenuDetails = new MenuDetails();

    @Type(() => CustomizerMenuPartModel)
    public subComponents: Array<CustomizerMenuPartModel>;

    @Type(() => CustomizerMenuPartModel)
    public subComponentsToShow: Array<CustomizerMenuPartModel> = [];

    @Type(() => CustomizerMenuPartModel)
    public parentMenu: CustomizerMenuPartModel;

    @Type(() => MenuComponentModel)
    public menuComponents: Array<MenuComponentModel>;
}
export class MenuDetails {
    public scrollPosition: number = 0;
    public selectedGroup: ColorGroupModel;
    public expanded: boolean;
}
