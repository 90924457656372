import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ModalService } from '../_services/modal.service';
// https://blog.strongbrew.io/Modals-in-angular2/
// Selve modalen.
@Component({
	selector: "modal",
	template: `<div #modal></div>`
})
export class ModalComponent implements AfterViewInit {
	@ViewChild('modal', { static: false, read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

	constructor(
		private modalService: ModalService,
	) { }

	ngAfterViewInit(): void {
		this.modalService.registerViewContainerRef(this.viewContainerRef);
	}
}

export class ModalContainer {
	destroy: (all: boolean) => void;
	ReplaySubject: ReplaySubject<any>;
	componentIndex: number;

	closeModal(all: boolean = false): void {
		this.destroy(all);
	}
}
