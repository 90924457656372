// http://brianflove.com/2016/10/23/angular2-breadcrumb-using-router/

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { IBreadcrumb } from '@models/ibreadcrumb';
import { BreadcrumbService } from '@services/breadcrumb.service';
import { ModalService } from '@services/modal.service';
import { NavigateService } from '@services/navigate.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'breadcrumb',
	styleUrls: ['./breadcrumb.scss'],
	templateUrl: 'breadcrumb.html'
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

	public breadcrumbs: Array<IBreadcrumb>;
	public isVisible = true;
	private subscriptions: Array<Subscription> = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private breadcrumbService: BreadcrumbService,
		private modalService: ModalService,
		private navigateService: NavigateService
	) {
		this.breadcrumbs = [];

		this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {

			this.getBreadCrumbs();

		}));
	}

	ngOnInit() {
		this.getBreadCrumbs();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(x => x.unsubscribe());
	}

	rootNavigated() {
		this.breadcrumbService.breadcrumbClicked();
	}

	getBreadCrumbs() {
		const root: ActivatedRoute = this.activatedRoute.root;

		this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(root);
		this.isVisible = this.breadcrumbs.length > 0;
	}

	async goToLink(breadcrumb: IBreadcrumb) {
		this.modalService.closeAll();

		if (breadcrumb.url === '/' || breadcrumb.url === '') {
			if (breadcrumb.label === 'precious') {
				return await this.navigateService.navigate([breadcrumb.label]);

			} else {
				return await this.navigateService.navigateToHome();
			}
		}

		const path = breadcrumb.url.split('/').filter(s => s !== '');

		if (path.first() === 'tools') {
			if (path.find(x => x === 'accessories')) {
				if (path.last() === 'accessories') {
					this.breadcrumbs.last().label === 'cases' && await this.navigateService.navigate([...path, 'group', 'accessories']);
				}

				return await this.navigateService.navigate(path);
			}

			return await this.navigateService.navigate([path[0]]);
		}

		return await this.navigateService.navigate(path);
	}

}
