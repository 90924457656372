import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrderOnBehalfService } from '@services/order-on-behalf.service';
import { UserService } from '@services/user.service';
import { UserTypeConstant } from '@shared/user-type-constants';
import { Subscription, combineLatest } from 'rxjs';

@Component({
	selector: 'ordering-info',
	template: `
        <span *ngIf="!hasProductlinesStatus">{{ 'COMMON.ORDERNOTPOSSIBLE' | translate }}</span>
    `
})
export class OrderingInfoComponent implements OnInit, OnDestroy {

	@Input() productlineCode: string;
	
	hasProductlinesStatus: boolean;
	private subscriptions = new Array<Subscription>();

	constructor(
		private userService: UserService,
		private orderOnBehalfService: OrderOnBehalfService
	) { }

	async ngOnInit() {
		this.subscriptions.push(combineLatest([this.orderOnBehalfService.customerAsObservable(), this.userService.currentUser])
			.subscribe(([customer, user]) => {
				if (customer && user.Type === UserTypeConstant.Customer) {
					this.hasProductlinesStatus = customer.ProductLinesPermissions?.some(x => x === this.productlineCode);
				} else if (customer && user.IsDistributor) {
					this.hasProductlinesStatus = user.ProductLinesPermissions?.some(x => x === this.productlineCode);
				} else if (user) {
					this.hasProductlinesStatus = user.ProductLinesPermissions?.some(x => x === this.productlineCode);
				} else {
					this.hasProductlinesStatus = false;
				}
			})
		);
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => {
			if (!sub.closed) {
				sub.unsubscribe();
			}
		});
	}

}
