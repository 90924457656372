export {};

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Array<T> {
        selectMany<TOut>(selectListFn: (t: T) => Array<TOut>): Array<TOut>;
        sortBy(sortByFunc: (t: T) => string|number|Date): Array<T>;
        sortByDescending(sortByFunc: (t: T) => string|number|Date): Array<T>;
        flatten(selectFn: (t: T) => Array<T>): Array<T>;
        distinct(selectFn?: (t: T, t2: T) => boolean): Array<T>;
        sum(selectFn: (t: T) => number): number;
        first(): T;
        last(): T;
    }
    interface ReadonlyArray<T> {
        selectMany<TOut>(selectListFn: (t: T) => Array<TOut>): Array<TOut>;
        sortBy(sortByFunc: (t: T) => string|number|Date): Array<T>;
        sortByDescending(sortByFunc: (t: T) => string|number|Date): Array<T>;
        flatten(selectFn: (t: T) => Array<T>): Array<T>;
        distinct(selectFn?: (t: T, t2: T) => boolean): Array<T>;
        sum(selectFn: (t: T) => number): number;
        first(): T;
        last(): T;
    }
}

Array.prototype.selectMany = function(x) {
    return this.map(x).reduce((a: any, b: any) => a ? a.concat(b) : [],  []);
};

Array.prototype.sortBy = function(x) {
    return this.sort((a: any, b: any) => x(a) < x(b) ? -1 : 1);
};

Array.prototype.sortBy = function(x) {
    return this.sort((a: any, b: any) => x(a) < x(b) ? -1 : 1);
};

Array.prototype.sortByDescending = function(x) {
    return this.sort((a: any, b: any) => x(a) > x(b) ? -1 : 1);
};

Array.prototype.distinct = function(fn?: (a: any, b: any) => boolean) {
    if(fn) {
        const newArr: Array<any> = [];
        this.forEach((val: any) => {
            if(newArr.length === 0) {
                newArr.push(val);
            } else {
                if(!newArr.some(x => fn(val, x))) {
                    newArr.push(val);
                }
            }
        });
        return newArr;
    }
    return this.filter((val: any, index: number, arr: Array<any>) => arr.indexOf(val) === index);
};

Array.prototype.flatten = function(x) {
    return this.reduce((acc: Array<any>, value: any) => {
        acc.push(value);
        const arrays = x(value);
        if (arrays && arrays.length) {
            acc = acc.concat(x(value).flatten(x));
        }
        return acc;
    }, []);
};

Array.prototype.sum = function(x) {
    return this.reduce((a: number, b: any) =>  a + x(b), 0);
};

Array.prototype.first = function() {
    return this[0];
};

Array.prototype.last = function() {
    return this[this.length-1];
};