import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpOfflineError } from '@shared/http-offline-error';
import { HttpPostOfflineError } from '@shared/httppost-offline-error';
import { OnlineStatusService, OnlineStatusType } from "ngx-online-status";
import { BehaviorSubject, Subject, debounceTime, firstValueFrom, fromEvent } from 'rxjs';
import { ServiceUtils } from './utils/service.utils';

@Injectable({
	providedIn: 'root'
})
export class OfflineService {

	public offlineErrorOccured: Subject<void> = new Subject<void>();
	public isOnlineSubject: BehaviorSubject<boolean>;

	public get isOnlineValue() {
		return this.isOnlineSubject.value || this.onlineStatusService.getStatus() === OnlineStatusType.ONLINE;
	};

	constructor(
		@Inject('window') window: Window,
		@Inject('navigator') navigator: Navigator,
		private onlineStatusService: OnlineStatusService,
		private http: HttpClient,
		private serviceUtils: ServiceUtils,
	) {
		this.isOnlineSubject = new BehaviorSubject<boolean>(navigator.onLine);

		fromEvent(window, 'offline').pipe(debounceTime(250)).subscribe(event => {
			this.isOnlineSubject.next(navigator.onLine);
		})

		fromEvent(window, 'online').subscribe(event => {
			this.isOnlineSubject.next(navigator.onLine);
		})

		this.isOnlineSubject.next(navigator.onLine);
	}

	public OfflineErrorOccured() {
		if (this.isOnlineValue != false) {
			this.isOnlineSubject.next(false);
		}
	}

	public OnlineRequestSuccesfullyOcurred() {
		if (this.isOnlineValue != true) {
			this.isOnlineSubject.next(true);
		}
	}

	public async getDataWithOfflineCheck<T>(url: string, options: any = undefined) {
		try {
			const promise = (await this.http.get<T>(url, options).toPromise() as any);
			this.OnlineRequestSuccesfullyOcurred();
			return promise;
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				this.OfflineErrorOccured();
				throw new HttpOfflineError();
			}
			else {
				throw error;
			}
		}
	}

	public getDataWithOfflineCheckNoAwait<T>(url: string, options: any = undefined): Promise<T> {
		return (this.http.get<T>(url, options).toPromise() as any).then(t => {
			this.OnlineRequestSuccesfullyOcurred();
			return t;
		})
			.catch(error => {
				if (this.serviceUtils.errorIsOffline(error)) {
					this.OfflineErrorOccured();
					throw new HttpOfflineError();
				}
				else {
					throw error;
				}
			});
	}

	public async postWithOfflineHandling<T>(url: string, postModel: any): Promise<T> {
		try {
			const promise = (await this.http.post<T>(url, postModel).toPromise() as any);
			this.OnlineRequestSuccesfullyOcurred();
			return promise;
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				this.OfflineErrorOccured();
				throw new HttpPostOfflineError();
			}
			else {
				throw error;
			}
		}
	}

	public async patchWithOfflineHandling<T>(url: string, patchModel: any): Promise<T> {
		try {
			const promise = await firstValueFrom(this.http.patch<T>(url, patchModel));
			this.OnlineRequestSuccesfullyOcurred();
			return promise;
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				this.OfflineErrorOccured();
				throw new HttpPostOfflineError();
			}
			else {
				throw error;
			}
		}
	}

	public async deleteWithOfflineHandling<T>(url: string, options: any = undefined): Promise<T> {
		try {
			const promise = await this.http.delete<T>(url, options).toPromise() as any;
			this.OnlineRequestSuccesfullyOcurred();
			return promise;
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				this.OfflineErrorOccured();
				throw new HttpPostOfflineError();
			}
			else {
				throw error;
			}
		}
	}

}
