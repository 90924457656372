import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { BrandEnum } from '@infrastructure/brand.enum';
import { ModelModel } from '@models/api-models/model';
import { AssortmentModel } from '@models/assortment-model';
import { ComponentVariantModel } from '@models/component-variant';
import { ConfigurationModel } from '@models/configuration';
import { ModelGroupModel } from '@models/model-group';
import { ModelSizeModel } from '@models/model-size';
import { BrandModeService } from '@services/brandmode.service';
import { LanguageService } from '@services/language.service';
import { ComponentCodeConstants } from '@shared/component-constants';
import { ModelgroupConstants } from '@shared/modelgroup-constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'availability-text',
    templateUrl: "availability-text.component.html",
    styleUrls: ['./availability-text.component.scss']
})
export class AvailabilityComponent implements OnChanges, OnInit, OnDestroy {
    @Input() model: ModelModel;

    // used for discontiuned
    @Input() date: Date;
    @Input() modelgroup: ModelGroupModel;
    @Input() modelSize: ModelSizeModel;
    @Input() configuration: ConfigurationModel;
    @Input() variants: Array<ComponentVariantModel>;

    // used for new fields
    @Input() new: boolean;
    @Input() omitDiscontinuedLabel: boolean;
    @Input() useNewDetails: boolean;
    @Input() newLargeText: boolean;
    @Input() variantOrModelIsNew: boolean;
    @Input() showAllNewflags: boolean;

    constructor(public translateService: LanguageService, private brandModeService: BrandModeService) {}

    public newText: string;
    public newDate: Date;

    public discontinuedModel: {discontinuedprefixText: string, date: Date, omitDiscontinuedLabel: boolean};

    private langSubscription: Subscription;
    private brand: BrandEnum = this.brandModeService.currentBrandValue();

    get AllAssortments(): Array<AssortmentModel> {
        const assortments = new Array<AssortmentModel>();
        this.model?.AllAssortments.forEach(x=> assortments.push(x));
        this.modelSize?.Assortments.forEach(x => assortments.push(x));
        this.variants?.forEach(x=> x.AllAssortments.forEach(t => assortments.push(t)));

        return assortments.distinct((x,y) => x.Code === y.Code);
    }

    ngOnInit(){
        this.langSubscription = this.translateService.$onLangChange.subscribe(x => {
            this.ngOnChanges();
        });
    }

    ngOnDestroy() {
        if(!this.langSubscription.closed) {
            this.langSubscription.unsubscribe();
        }
    }

    ngOnChanges() {
        this.newDate = null;
        this.newText = null;
        this.discontinuedModel = null;
        
        // set discontinued prefix
        if (!this.date && this.model && this.modelSize && this.configuration && this.variants) {
            if (this.modelgroup.Discontinued) {
                this.discontinuedModel = {discontinuedprefixText: "", date: this.modelgroup.Discontinued, omitDiscontinuedLabel: false};
            }

            if (this.model.Discontinued && this.discontinuedModel == null || (this.model.Discontinued && this.model.Discontinued < this.discontinuedModel?.date)) {
                this.discontinuedModel = {discontinuedprefixText: this.translateService.instant(`CUSTOMIZER.COMPONENTS.MODEL.NAME`), date: this.model.Discontinued, omitDiscontinuedLabel: false};
            }

            if (this.modelSize.Discontinued && this.discontinuedModel == null || (this.modelSize.Discontinued && this.modelSize.Discontinued < this.discontinuedModel?.date)) {
                this.discontinuedModel = {discontinuedprefixText: this.translateService.instant(`COMMON.SIZE`), date: this.modelSize.Discontinued, omitDiscontinuedLabel: false};
            }

            if (this.configuration.Discontinued && this.discontinuedModel == null || (this.configuration.Discontinued && this.configuration.Discontinued < this.discontinuedModel?.date)) {
                this.discontinuedModel = {discontinuedprefixText: this.translateService.instant(`COMMON.COLOR`), date: this.configuration.Discontinued, omitDiscontinuedLabel: false};
            }

            this.variants.forEach(variant => {
                if (variant.Discontinued && this.discontinuedModel == null || (variant.Discontinued && variant.Discontinued < this.discontinuedModel?.date)) {
                    this.discontinuedModel = {discontinuedprefixText: variant.Component.Description, date: variant.Discontinued, omitDiscontinuedLabel: variant.OmitDiscontinuedLabel};
                }

                if (variant.VariantColor.Discontinued && this.discontinuedModel == null || (variant.VariantColor.Discontinued && variant.VariantColor.Discontinued < this.discontinuedModel?.date)) {
                    this.discontinuedModel = {discontinuedprefixText: this.translateService.instant(`COMMON.COLOR`), date: variant.VariantColor.Discontinued, omitDiscontinuedLabel: variant.OmitDiscontinuedLabel};
                }

                if (variant.VariantSize.Discontinued && this.discontinuedModel == null || (variant.VariantSize.Discontinued && variant.VariantSize.Discontinued < this.discontinuedModel?.date)) {
                    this.discontinuedModel = {discontinuedprefixText: this.translateService.instant(`COMMON.SIZE`), date: variant.VariantSize.Discontinued, omitDiscontinuedLabel: variant.OmitDiscontinuedLabel};
                }
            });
            this.newDate = this.discontinuedModel?.date ? new Date(this.discontinuedModel.date) : null;
        } else if(!this.date && this.modelgroup){            
            if (this.modelgroup.Discontinued) {
                let discontinuedprefixText = this.modelgroup.Description.Fallback;
                
                if(this.modelgroup.ParentModelGroupDescription?.Fallback){
                    discontinuedprefixText = this.modelgroup.ParentModelGroupDescription.Fallback;
                }

                this.discontinuedModel = {discontinuedprefixText: discontinuedprefixText, date: this.modelgroup.Discontinued, omitDiscontinuedLabel: false};
            }
            this.newDate = this.discontinuedModel?.date ? new Date(this.discontinuedModel.date) : null;
        } 
        else {
            this.newDate = this.date ? new Date(this.date) : null;
        }

        // set new text
        if (!this.variantOrModelIsNew) {
            if (this.new) {
                this.newText = this.translateService.instant(`COMMON.NEW`);
            } else if (this.useNewDetails && this.model) {
                this.setSizeNewtext();
                this.SetColorNewtext();
                this.setComponentNewtext();
            }
        }
    }

    private setComponentNewtext() {
        if (this.brand === BrandEnum.Titanium && this.model.NewComponentCodes) {
            this.model.NewComponentCodes
                .filter(componentCode => componentCode == ComponentCodeConstants.Temple || componentCode == ComponentCodeConstants.Innerrim)
                .forEach(componentCode => {
                    if (!this.newText) {

                        this.newText = componentCode == ComponentCodeConstants.Temple
                            ? this.translateService.instant(`COMMON.NEWTEMPLE`)
                            : this.translateService.instant(`COMMON.NEWINNERRIM`);

                    } else {
                        this.newText += " + " + (componentCode == ComponentCodeConstants.Temple
                                                    ? this.translateService.instant(`COMMON.TEMPLE`)
                                                    : this.translateService.instant(`COMMON.INNERRIM`));
                    }
                });
        } else if (this.brand === BrandEnum.Precious && this.model.NewComponentCodesPrecious) {
            this.model.NewComponentCodesPrecious
                .filter(componentCode => componentCode == ComponentCodeConstants.Temple || componentCode == ComponentCodeConstants.Innerrim)
                .forEach(componentCode => {
                    if (!this.newText) {

                        this.newText = componentCode == ComponentCodeConstants.Temple
                            ? this.translateService.instant(`COMMON.NEWTEMPLE`)
                            : this.translateService.instant(`COMMON.NEWINNERRIM`);

                    } else {
                        this.newText += " + " + (componentCode == ComponentCodeConstants.Temple
                                                    ? this.translateService.instant(`COMMON.TEMPLE`)
                                                    : this.translateService.instant(`COMMON.INNERRIM`));
                    }
                });
        }
    }

    private SetColorNewtext() {
        if (this.brand === BrandEnum.Titanium && this.model.NewColorComponentCodes) {
            let newRelevantColors: Array<string> = [];

            if (this.showAllNewflags) {
                newRelevantColors = this.model.NewColorComponentCodes;
            } else {
                newRelevantColors = this.model.NewColorComponentCodes.filter(componentCode => componentCode == ComponentCodeConstants.Innerrim);

                if (this.model.ModelGroupCode == ModelgroupConstants.STRIP9800) {
                    newRelevantColors = newRelevantColors.concat(this.model.NewColorComponentCodes.filter(componentCode => componentCode == ComponentCodeConstants.Upperrim));
                }

                newRelevantColors = newRelevantColors.concat(this.model.NewColorComponentCodes.filter(x => x == "NewConfig"));
            }

            if (newRelevantColors.length > 0) {
                if (!this.newText) {
                    this.newText = this.translateService.instant(`COMMON.NEWCOLOR`);
                } else {
                    this.newText += " + " + this.translateService.instant(`COMMON.COLOR`);
                }
            }
        }
    }

    private setSizeNewtext() {
        
        if (this.brand === BrandEnum.Titanium && this.model.NewSizeComponentCodes) {
            this.model.NewSizeComponentCodes.forEach(newSizeComponentCode => {
                if (!this.model.NewComponentCodes.some(x => x == newSizeComponentCode)) {
                    this.newText = this.translateService.instant(`COMMON.NEWSIZE`);
                }
            });
        } else if (this.brand === BrandEnum.Precious && this.model.NewSizeComponentCodesPrecious) {
            this.model.NewSizeComponentCodesPrecious.forEach(newSizeComponentCode => {
                if (!this.model.NewComponentCodes.some(x => x == newSizeComponentCode)) {
                    this.newText = this.translateService.instant(`COMMON.NEWSIZE`);
                }
            });
        }
    }
}
