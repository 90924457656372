import { Component, Input } from '@angular/core';

@Component({
	selector: 'icon-my-collections',
	styleUrls: ['./icon-my-collections.component.scss'],
	template: `
        <div *ngIf="isInCollection" class="is-in-collection">
            <i class="fa-solid fa-rectangle-history fa-xl"></i>
        </div>

        <div *ngIf="!isInCollection" class="not-in-collection">
  	    	<i class="fa-light fa-rectangle-history fa-xl"></i>
        </div>
    `
})
export class IconMyCollectionsComponent {
	@Input() isInCollection: boolean;
}
