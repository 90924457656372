import { nameof } from "ts-simple-nameof";
import { DataTag } from "./data-tag";

export class IntervalTag<T> extends DataTag<Array<T>> {
    constructor(private from: number, private to: number) {
        super();
    }

    get name() {
        return `${this.from}-${this.to}`;
    }

    setFrom(from: number) {
        this.checkIfNumberAboveZero(from, nameof(() => from));
        this.from = from;
    }

    setTo(to: number) {
        this.checkIfNumberAboveZero(to, nameof(() => to));
        this.to = to;
    }

    private checkIfNumberAboveZero(variable: number, name: string) {
        if (isNaN(variable) || variable < 0) {
            throw new Error(`number "${name}" is not a number above or equal to 0`);
        }
    }
}
