import IDisableable from "@infrastructure/disableable.interface";
import { ComponentVariantFromApiModel } from "@models/api-models/component-variant-fromapi";
import { ComponentBindingModel } from "@models/component-binding";
import { ComponentDependencyModel } from "@models/component-dependency";
import { ComponentVariantModel } from "@models/component-variant";
import { ComponentVariantChangeModel } from "@models/component-variant-change";
import { BindingConstants } from "@shared/dependency-constants";
import { IDependencyCalculator } from "./dependency-calculator.interface";

export class DisableVariantsFromApiDependencyCalculator implements IDependencyCalculator {
    private disabledBy: ComponentVariantModel = null;

    constructor(private variantsFromApi: Array<ComponentVariantFromApiModel>) {

    }

    async getChanges(dependency: ComponentDependencyModel, bindings: Array<ComponentBindingModel>, variant: ComponentVariantModel, oppositChosenVariants: Array<ComponentVariantModel>): Promise<Array<ComponentVariantChangeModel>> {
        const variants = this.variantsFromApi.filter(x => x.Id === variant.Id);

        this.disabledBy = oppositChosenVariants.find(x => x.Id != variant.Id);

        const bindingsOnVariants = bindings.filter(x => variant.Component.Id === x.ComponentId);

        const byComponent = bindingsOnVariants.filter(x => x.isMissingVariantVariantSizeAndVariantColor());
        const byVariant = bindingsOnVariants.filter(x => x.isMissingVariantSizeAndVariantColor());
        const bySize = bindingsOnVariants.filter(x => x.isMissingVariantColor());
        const byColor = bindingsOnVariants.filter(x => x.hasVariantVariantSizeAndVariantColor());

        if(byColor.length) {
            if (dependency.Binding === BindingConstants.PermitOnly) {
                this.disableAllButColor(byColor, variants);
            } else if (dependency.Binding === BindingConstants.Block) {
                this.disableColor(byColor, variants);
            }
        } else if(bySize.length) {
            if (dependency.Binding === BindingConstants.PermitOnly) {
                this.disableAllButSize(bySize, variants);
            } else if (dependency.Binding === BindingConstants.Block) {
                this.disableSize(bySize, variants);
            }
        } else if(byVariant.length) {
            if (dependency.Binding === BindingConstants.PermitOnly) {
                this.disableAllButVariant(byVariant, variants);
            } else if (dependency.Binding === BindingConstants.Block) {
                this.disableVariant(byVariant, variants);
            }
        } else if(byComponent.length) {
            this.disableAll(variants);
        }

        return [];
    }

    private disableColor(bindings: Array<ComponentBindingModel>, variants: Array<ComponentVariantFromApiModel>) {
        variants.forEach(variant => {
            variant.VariantSizes.forEach(size => {
                size.VariantColors.forEach(color => {
                    if(bindings.some(x => x.VariantColorUuid === color.Uuid)) {
                        this.setDisabled(color);
                        color.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];
                    }
                });
            });
        });
    }

    private disableAllButColor(bindings: Array<ComponentBindingModel>, variants: Array<ComponentVariantFromApiModel>) {
        variants.forEach(variant => {
            variant.VariantSizes.forEach(size => {
                size.VariantColors.forEach(color => {
                    if(!bindings.some(x => x.VariantColorUuid === color.Uuid)) {
                        this.setDisabled(color);
                        color.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];
                    }
                });
            });
        });
    }

    private disableSize(bindings: Array<ComponentBindingModel>, variants: Array<ComponentVariantFromApiModel>) {
        variants.forEach(variant => {
            variant.VariantSizes.forEach(size => {
                if(bindings.some(x => x.VariantSizeUuid === size.Uuid)) {
                    this.setDisabled(size);
                    size.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];

                    size.VariantColors.forEach(color => {
                        this.setDisabled(color);
                        color.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];
                    });
                }
            });
        });
    }

    private disableAllButSize(bindings: Array<ComponentBindingModel>, variants: Array<ComponentVariantFromApiModel>) {
        variants.forEach(variant => {
            variant.VariantSizes.forEach(size => {
                if(!bindings.some(x => x.VariantSizeUuid === size.Uuid)) {
                    this.setDisabled(size);
                    size.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];

                    size.VariantColors.forEach(color => {
                        this.setDisabled(color);
                        color.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];
                    });
                }
            });
        });
    }

    private disableVariant(bindings: Array<ComponentBindingModel>, variants: Array<ComponentVariantFromApiModel>) {
        variants.forEach(variant => {
            if(bindings.some(x => x.ComponentVariantUuid === variant.Uuid)) {
                variant.VariantSizes.forEach(size => {
                    this.setDisabled(size);
                    size.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];

                    size.VariantColors.forEach(color => {
                        this.setDisabled(color);
                        color.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];
                    });
                });
            }
        });
    }

    private disableAllButVariant(bindings: Array<ComponentBindingModel>, variants: Array<ComponentVariantFromApiModel>) {
        variants.forEach(variant => {
            if(!bindings.some(x => x.ComponentVariantUuid === variant.Uuid)) {
                variant.VariantSizes.forEach(size => {
                    this.setDisabled(size);
                    size.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];

                    size.VariantColors.forEach(color => {
                        this.setDisabled(color);
                        color.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];
                    });
                });
            }
        });
    }

    private disableAll(variants: Array<ComponentVariantFromApiModel>) {
        variants.forEach(variant => {
            this.setDisabled(variant);
            variant.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];

            variant.VariantSizes.forEach(size => {
                this.setDisabled(size);
                size.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];

                size.VariantColors.forEach(color => {
                    this.setDisabled(color);
                    color.isDisabledByVariants = [this.getDisabledByObject(this.disabledBy)];
                });
            });
        });
    }

    private setDisabled(t: IDisableable) {
        t.isDisabled = true;
    }

    private getDisabledByObject(variant: ComponentVariantModel) {
        return { component: variant.Component.Code, variant: variant.Code, size: variant.VariantSize?.Size, color: variant.VariantColor?.Color.Code };
    }
}
