import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removewhitespace'
})
export class RemoveWhiteSpacePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
	let re = /\ /gi;
	let result = value.replace(re, '');
	
	return result;
}

}
