import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FirstPair } from '@infrastructure/firstPair.enum';
import { Gender } from '@infrastructure/gender.enum';
import ValidationResult from '@infrastructure/validation-result';
import { DiscountModel } from '@models/discounts/discount-model';
import { MenuModel } from '@models/menu';
import { MiscModel } from '@models/misc-model';
import { TempleInclinationEnum } from '@models/variant-temple-inclination';
import Variants from '@models/variants';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ModelModel } from 'src/_models/api-models/model';
import { ColorModel } from 'src/_models/color';
import { ColorGroupModel } from 'src/_models/color-group';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { ConfigurationModel } from 'src/_models/configuration';
import { CustomizerMenuPartModel } from 'src/_models/customizer-menu-part';
import { ModelGroupModel } from 'src/_models/model-group';
import { ModelSizeModel } from 'src/_models/model-size';
import { VariantColorModel } from 'src/_models/variant-color';
import { DataApiService } from 'src/_services/data-api.service';
import { EventsService } from 'src/_services/events.service';
import { ConfigurationPlusModel } from '../_models/configuration-plus-model';
import { CustomizerComponentInterface } from '../infrastructure/customizer.interface';
import { ComponentCodeConstants } from './component-constants';
import { CustomizerStateConstants } from './customizer-state-constants';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseCustomizerComponent implements CustomizerComponentInterface, OnInit, OnChanges {

	get getCurrentVariants() {
		return this.currentVariants;
	}

	get getSelectedVariant() {
		// a component can consist of more than one variant. Currently we use the first "[0]" when the selected variant has to be used
		return this.currentVariants[0];
	}

	@Input() reference: string;
	@Input() comment: string;
	@Input() hasForms: boolean;
	@Input() discount: DiscountModel;
	@Input() discountErrors: ValidationResult;
	@Input() hasFixtures: boolean;
	@Input() engraving: string;
	@Input() configurations: Array<ConfigurationModel>;
	@Input() configuration: ConfigurationModel = null;
	@Input() modelGroup: ModelGroupModel;
	@Input() model: ModelModel;
	@Input() modelSize: ModelSizeModel;
	@Input() variants: Array<ComponentVariantModel>; // all chooseable variants
	@Input() imageOnlyVariants: Array<ComponentVariantModel>;
	@Input() menu: CustomizerMenuPartModel;
	@Input() state: CustomizerStateConstants;
	@Input() currentVariantConstant: ComponentCodeConstants;
	@Input() age: string;
	@Input() gender: Gender;
	@Input() firstPair: FirstPair;
	@Input() selectedTempleInclination: TempleInclinationEnum;
	@Input() selectedCase: MiscModel;

	@Output() private setImageSliderByNameChange: EventEmitter<string> = new EventEmitter();
	@Output() private updateSizeChange: EventEmitter<ModelSizeModel> = new EventEmitter();
	@Output() private updateConfigurationChange: EventEmitter<ConfigurationPlusModel> = new EventEmitter();
	@Output() private updateVariantChange: EventEmitter<ComponentVariantChangeModel> = new EventEmitter();
	@Output() private updateVariantsChange: EventEmitter<Array<ComponentVariantChangeModel>> = new EventEmitter();
	@Output() private updateComponentSizeChange: EventEmitter<ComponentVariantChangeModel> = new EventEmitter();
	@Output() private updateVariantDesignChange: EventEmitter<ComponentVariantChangeModel> = new EventEmitter();
	@Output() private updateMenuVisitedChange: EventEmitter<MenuModel> = new EventEmitter();
	@Output() private updateModelChange: EventEmitter<ModelModel> = new EventEmitter();
	@Output() private updateCollapsedOnComponent: EventEmitter<boolean> = new EventEmitter();
	@Output() private updateReferenceChange: EventEmitter<string> = new EventEmitter();
	@Output() private updateCommentChange: EventEmitter<string> = new EventEmitter();
	@Output() private updateEngravingChange: EventEmitter<string> = new EventEmitter();
	@Output() private matchColorsChange: EventEmitter<VariantColorModel> = new EventEmitter();
	@Output() private setExpandedChange: EventEmitter<boolean> = new EventEmitter();
	@Output() private onlyClipOnChange: EventEmitter<boolean> = new EventEmitter();
	@Output() private currentMainComponentChange: EventEmitter<CustomizerMenuPartModel> = new EventEmitter();
	@Output() private updateHasFormsChange: EventEmitter<boolean> = new EventEmitter();
	@Output() private updateHasFixturesChange: EventEmitter<boolean> = new EventEmitter();
	@Output() private updateDiscountChange: EventEmitter<DiscountModel> = new EventEmitter();
	@Output() private updateAgeChange: EventEmitter<string> = new EventEmitter();
	@Output() private updateGenderChange: EventEmitter<Gender> = new EventEmitter();
	@Output() private updateFirstPairChange: EventEmitter<FirstPair> = new EventEmitter();
	@Output() private updateTempleInclinationChange: EventEmitter<TempleInclinationEnum> = new EventEmitter();
	@Output() private updateCaseChange: EventEmitter<MiscModel> = new EventEmitter();
	@Output() private updatePartsChange: EventEmitter<string[]> = new EventEmitter();

	currentColorGroup: ColorGroupModel = null;
	currentVariants: Array<ComponentVariantModel>;
	customizerStateEnum = CustomizerStateConstants;

	public matchableColors: Array<ColorModel>;
	public allPossibleColorsOnOtherSelectedVariants: Array<VariantColorModel>;
	public variantOrModelIsNew: boolean = false;
	public modelIsNew: boolean = false;

	constructor(
		public events: EventsService,
		public dataApiService: DataApiService,
		public configurationService: ConfigurationService
	) { }

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		const parentMenuComponentUuids = menuComponents.menuComponents.map(x => x.ComponentUuids);
		const subMenuComponentUuids = menuComponents.subComponents.selectMany(x => x.menuComponents.map(t => t.ComponentUuids));

		if (parentMenuComponentUuids.length < 1 && subMenuComponentUuids.length < 1) {
			return true;
		}
		else {
			const filteredVariants = demo ? variants.filter(x => x.AvailableForDisplay || x.Optional) : variants;
			return BaseCustomizerComponent.menuItemExistInVariants(parentMenuComponentUuids.concat(subMenuComponentUuids), filteredVariants.map(x => x.Component.Uuid));
		}
	}

	protected static menuItemExistInVariants(componentUuids: Array<string>, variantComponentUuids: Array<string>): boolean {
		let menuItemExistInVariants = false;

		variantComponentUuids.forEach(variantComponentUuid => {
			if (componentUuids.some(x => variantComponentUuid == x)) {
				menuItemExistInVariants = true;
			}
		});

		return menuItemExistInVariants;
	}

	async ngOnInit() {
		this.currentColorGroup = this.menu.menuDetails.selectedGroup;
		this.currentVariants = this.setCurrentVariants();
		await this.initData();

		this.matchableColors = await this.getMatchableColors();
	}

	ngOnChanges(changes: SimpleChanges) {
		this.currentVariants = this.setCurrentVariants();
		this.modelIsNew = (!this.modelGroup.IsRimless && this.model && this.model.New);
		this.variantOrModelIsNew = this.modelIsNew || (this.getSelectedVariant && this.getSelectedVariant.New);

		this.onChanges(changes);
	}

	abstract initData(): Promise<void> | void;

	abstract onChanges(changes: SimpleChanges): void;

	private setCurrentVariants(): Array<ComponentVariantModel> {
		const variants: Array<ComponentVariantModel> = [];

		for (let index = 0; index < this.variants.length; index++) {
			const variant = this.variants[index];
			const components = this.menu.menuComponents;

			if (components.findIndex(x => (x.ComponentVariantUuids == null && x.ComponentUuids == variant.Component.Uuid) || x.ComponentVariantUuids == variant.Uuid) > -1) {
				if (this.currentVariantConstant) {
					if (variant.Component.Code == this.currentVariantConstant) {
						variants.push(variant);
					}
				}
				else {
					variants.push(variant);
				}
			}
		}

		return variants;
	}

	getMatchableColors = async (): Promise<Array<ColorModel>> => {
		if (this.getSelectedVariant) {
			const selectedVariantFromAPI = (await this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup,
				this.getSelectedVariant.Component.Uuid, this.getSelectedVariant.Uuid, this.getSelectedVariant.VariantSize ? this.getSelectedVariant.VariantSize.Uuid : null))[0];

			const allColorsPossibleOnSelectedVariant = selectedVariantFromAPI?.VariantSizes.selectMany(t => t.VariantColors);

			const allColorsPossibleOnOtherSelectedVariants = await this.getAllColorsPossibleOnOtherSelectedVariants();

			const colorsWithPossibleMatch: Array<ColorModel> = [];
			const allPossibleColorsOnOtherSelectedVariants = allColorsPossibleOnOtherSelectedVariants;

			allColorsPossibleOnSelectedVariant?.forEach(color => {
				if (allPossibleColorsOnOtherSelectedVariants.some(t => t.Color.IsAlike(color.Color))) {
					colorsWithPossibleMatch.push(color.Color);
				}
			});

			return colorsWithPossibleMatch;
		}

		return [];
	}

	async getAllColorsPossibleOnOtherSelectedVariants(): Promise<Array<VariantColorModel>> {
		const allOtherChosenVariantsPromises: Array<Promise<Array<ComponentVariantFromApiModel>>> = [];

		this.variants.filter(v => v.VariantSize != null && v.VariantColor && !v.NotSelected && this.getCurrentVariants.every(cv => cv.Uuid != v.Uuid)).forEach(variant => {
			allOtherChosenVariantsPromises.push(this.configurationService.getVariants(this.configuration.ModelConfigurationUuid, this.modelGroup,
				variant.Component.Uuid, variant.Uuid, variant.VariantSize.Uuid));
		});

		let allOtherChosenVariants: Array<ComponentVariantFromApiModel> = [];

		await Promise.all(allOtherChosenVariantsPromises).then((val) => {
			val.forEach(element => {
				allOtherChosenVariants = allOtherChosenVariants.concat(element);
			});
		});

		return allOtherChosenVariants.selectMany(t => t.VariantSizes).selectMany(t => t.VariantColors);
	}

	async RunDisablingSystem(variantsFromApi: Array<ComponentVariantFromApiModel>) {
		const variants = new Variants(variantsFromApi);
		await variants.calculateDisabled(this.variants, this.configuration);
	}

	onSelectGroup(colorGroup: ColorGroupModel) {
		this.menu.menuDetails.selectedGroup = colorGroup;
	}

	toggleExpanded() {
		this.menu.menuDetails.expanded = !this.menu.menuDetails.expanded;
	}

	updateReference(reference: string) {
		this.updateReferenceChange.emit(reference);
	}

	updateComment(comment: string) {
		this.updateCommentChange.emit(comment);
	}

	updateHasForms(v: boolean) {
		this.updateHasFormsChange.emit(v);
	}

	updateHasFixtures(v: boolean) {
		this.updateHasFixturesChange.emit(v);
	}

	updateEngraving(engraving: string) {
		this.updateEngravingChange.emit(engraving);
	}

	updateModel(model: ModelModel) {
		this.updateModelChange.emit(model);
	}

	updateVariantDesign(variant: ComponentVariantChangeModel) {
		this.updateVariantDesignChange.emit(variant);
	}

	updateMenuVisited(menu: MenuModel) {
		this.updateMenuVisitedChange.emit(menu);
	}

	updateComponentSize(variant: ComponentVariantChangeModel) {
		this.updateComponentSizeChange.emit(variant);
	}

	updateVariant(variant: ComponentVariantChangeModel) {
		this.updateVariantChange.emit(variant);
	}

	matchColors(variant: VariantColorModel) {
		if (variant) {
			this.matchColorsChange.emit(variant);
		}
		else {
			this.matchColorsChange.emit(this.getSelectedVariant.VariantColor);
		}
	}

	updateVariants(variants: Array<ComponentVariantChangeModel>) {
		this.updateVariantsChange.emit(variants);
	}

	setImageSliderByName(name: string) {
		this.setImageSliderByNameChange.emit(name);
	}

	updateSize(size: ModelSizeModel) {
		this.updateSizeChange.emit(size);
	}

	updateConfiguration(model: ConfigurationPlusModel) {
		console.debug('updateConfiguration', model);
		this.updateConfigurationChange.emit(model);
	}

	updateDiscount(model: DiscountModel) {
		this.updateDiscountChange.emit(model);
	}

	updateCollapsed(collapsed: boolean) {
		this.updateCollapsedOnComponent.emit(collapsed);
	}

	updateClipOnOnly(onlyClipOn: boolean) {
		this.onlyClipOnChange.emit(onlyClipOn);
	}

	updateCurrentMainComponent(componentMenu: CustomizerMenuPartModel) {
		this.currentMainComponentChange.emit(componentMenu);
	}

	updateAge(age: string) {
		this.updateAgeChange.emit(age);
	}

	updateGender(gender: Gender) {
		this.updateGenderChange.emit(gender);
	}

	updateFirstPair(firstPair: FirstPair) {
		this.updateFirstPairChange.emit(firstPair);
	}

	setExpanded(isExpanded: boolean) {
		this.setExpandedChange.emit(isExpanded);
	}

	updateTempleInclination(inclination: TempleInclinationEnum) {
		this.updateTempleInclinationChange.emit(inclination);
	}

	updateCase(model: MiscModel) {
		this.updateCaseChange.emit(model);
	}

	updateParts(selectedParts: string[]) {
		this.updatePartsChange.emit(selectedParts);
	}

}
