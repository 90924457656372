import { Type } from "class-transformer";
import { AvailabilityModel } from "./availability-model";

export class ComponentModel extends AvailabilityModel {
    public Id: number;
    public Uuid: string;
    public Code: string;
    public Description: string;
    public Status: number;
    @Type(() => Date)
    public Release: Date;

    constructor(
        Id: number,
        Uuid: string,
        Code: string,
        Description: string,
        Status: number,
        Release: Date,
        Discontinued: Date,
        New: boolean
    ) {
        super(Discontinued, New);
        this.Id = Id;
        this.Uuid = Uuid;
        this.Code = Code;
        this.Description = Description;
        this.Status = Status;
        this.Release = Release;
    }
}
