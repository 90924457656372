import { AssortmentLineModel } from '@models/assortment-line-model';
import { AssortmentModel } from '@models/assortment-model';
import { Type } from 'class-transformer';
import { AvailabilityModel } from "../availability-model";
export class ModelOrderApimodel extends AvailabilityModel {
    Id: number;
    Uuid: string;
    Code: string;
    Order: string;
    ProductLineCode: string;
    LowTempleInclinationPossible: number;


    @Type(() => AssortmentLineModel)
    public AssortmentLines: Array<AssortmentLineModel>;

    public get AllAssortments(): Array<AssortmentModel> {
        return this.AssortmentLines.map(x => x.Assortment).distinct((x,y) => x.Code === y.Code);
    }
}
