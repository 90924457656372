import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrandModeService } from '@services/brandmode.service';
import { ModelsService } from '@services/v2/models.service';
import { FadeInOutTransition } from '@shared/transitions/fade-in-out.transition';
import { Observable } from 'rxjs';
import { ConfigurationMode, ImageService } from 'src/_services/image.service';
import { LoginService } from 'src/_services/login.service';
import { TitleService } from 'src/_services/title.service';
import { DisplayEnduserModalComponent } from '../../../_modals/display-enduser/display-enduser.component';
import { MiscModelCategory } from '../../../_models/misc-model-category';
import { MiscModelGroup } from '../../../_models/misc-model-group';
import { EnduserDisplayService } from '../../../_services/enduserdisplay.service';
import { ModalService } from '../../../_services/modal.service';
import { NavigateService } from '../../../_services/navigate.service';
import { BasePage } from '../../../infrastructure/base-page';
import { MiscCat, MiscGroup } from '@app/home/home';

@Component({
    templateUrl: "misc-category.component.html",
    styleUrls: ['./misc-category.component.scss'],
    animations: [
        FadeInOutTransition()
    ]
})
export class MiscCategoryPageComponent extends BasePage implements OnInit {

    public chosenMiscCategory: MiscModelCategory;
    public miscGroups: Array<MiscModelGroup> = [];

    public enduserDisplayClickedObserveable: Observable<ConfigurationMode>;
    public displayEnduserModalComponent: any = DisplayEnduserModalComponent;
    public miscModelsLoaded: boolean = false;
    public skeletonScreens: number;
	category: string;
	group: string;

    constructor(
        navigateService: NavigateService,
        loginService: LoginService,
        titleService: TitleService,
        modalService: ModalService,
        private router: Router,
        private modelsService: ModelsService,
        public imageService: ImageService,
        private enduserDisplayService: EnduserDisplayService,
        private brandModeService: BrandModeService
    ) {
        super(loginService, titleService, modalService, navigateService);
    }

    async ngOnInit() {
		console.log(this.router.routerState.snapshot);

		let segments = this.router.routerState.snapshot.url.split('/');
		console.log(segments);

		this.category = decodeURI(segments[2]);
		this.group = decodeURI(segments[4]);

		if (this.category === '' && this.group === '') {
			this.router.navigate(['/']);
			return;
		}

		let mc = new MiscCat(this.category);

		let mg = new MiscGroup(this.group);
		mg.category = mc;

		mc.groups.push(mg)

		this.router.navigate(['group', this.group]);

		// this.titleService.setTitle(this.group);

		// await this.getMiscModels();



        // this.isReady = true;
        // this.enduserDisplayClickedObserveable = this.enduserDisplayService.enduserDisplayClickedObservable();

        // this.chosenMiscCategory = this.navigateService.getMiscCategory();

        // if (this.chosenMiscCategory == null) {
        //     await this.router.navigate(["/"]);
        //     return;
        // }

        // this.skeletonScreens = this.chosenMiscCategory.Category === "Tools" ? 8 : 4;

        // this.titleService.setTitle(this.chosenMiscCategory.CategoryForDisplay);
        // await this.getMiscGroups();
    }

    async getMiscGroups() {
        const miscModels = await this.modelsService.getMiscModels();
        let miscModelsFiltered = miscModels.filter(model => model.ItemCategoryDescription?.toLowerCase() == this.chosenMiscCategory.Category?.toLowerCase());

        let locationCodeFilter = this.brandModeService.isPrecious ? "WEBP" : "WEB";
        miscModelsFiltered = miscModelsFiltered.filter(t => t.LocationCode === locationCodeFilter);

        miscModelsFiltered.forEach(model => {
            if (this.miscGroups.every(group => group.Group != model.ItemGroupDescription)) {
                this.miscGroups.push({ Group: model.ItemGroupDescription, GroupForDisplay: model.ItemGroupDescription.toLowerCase() });
            }
        });

        this.miscModelsLoaded = true;
    }

    async goToGroup(miscModelGroup: MiscModelGroup) {
        await this.navigateService.navigateToMiscGroup(this.chosenMiscCategory, miscModelGroup);
    }

    public productImage(group: MiscModelGroup): string {
        if (group) {
            return this.imageService.GetMiscGroupImage(group.Group.toLowerCase(), this.chosenMiscCategory.Category.toLowerCase())
        }
    }
}
