import { Component, SimpleChanges } from '@angular/core';
import { MiscModel } from '@models/misc-model';
import { ConfigurationService } from '@services/configuration.service';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ConfigurationModel } from 'src/_models/configuration';
import { ModelGroupModel } from 'src/_models/model-group';
import { EventsService } from 'src/_services/events.service';
import { ConfigurationPlusModel } from '../../../../_models/configuration-plus-model';
import { ConfigurationViewModel } from '../../../../_models/configuration-view-model';
import { DataApiService } from '../../../../_services/data-api.service';
import { ImageService, ImageSize } from '../../../../_services/image.service';
import { SorterService } from '../../../../_services/sorter.service';
import { AngleConstants } from '../../../../shared/angle-constants';
import { ComponentCodeConstants } from '../../../../shared/component-constants';
import { CustomizerMenuPartModel } from './../../../../_models/customizer-menu-part';
import { BaseCustomizerComponent } from './../../../../shared/base-customizer-component';

@Component({
	template: `
  <customizer-component-header [menu]="menu" (updateCollapsedOnComponent)="updateCollapsed($event)">
    <configuration-list
      [modelGroup]="modelGroup"
      [configs]="configPlusModel"
      [selected]="configuration"
      [chosenVariants]="variants"
      [image]="getImage"
      [editMode]="state === customizerStateEnum.EDITORDER"
      [(scrollPosition)]="menu.menuDetails.scrollPosition"
      (selectedChange)="onSelect($event)"
      [variantOrModelIsNew]="modelIsNew"
    ></configuration-list>
    </customizer-component-header>
  `
})
export class FrameConfigurationComponent extends BaseCustomizerComponent {

	public configPlusModel: Array<ConfigurationViewModel>;

	constructor(
		public events: EventsService,
		public apiService: DataApiService,
		private imageService: ImageService,
		private sorterService: SorterService,
		public configurationService: ConfigurationService
	) {
		super(events, apiService, configurationService);
	}

	public static shouldBeVisible = (modelGroup: ModelGroupModel, menuComponents: CustomizerMenuPartModel, variants: Array<ComponentVariantModel>, configurations: Array<ConfigurationModel>, demo: boolean, chosenCase: MiscModel): boolean => {
		return true;
	}

	async initData() {
		this.events.showLoader(true);

		this.configPlusModel = await this.fetchConfigurations();

		this.events.showLoader(false);
	}

	onChanges(changes: SimpleChanges): void {
		this.setImageSliderByName(AngleConstants.Perspective);
	}

	onSelect(model: ConfigurationPlusModel) {
		this.events.showLoader(true);
		this.updateConfiguration(model);
	}

	getImage = (configuration: ConfigurationPlusModel) => {
		const currentLenses = this.variants.find(x => x.Component.Code == ComponentCodeConstants.Lenses);
		const variants = configuration.variants.filter(x => x.Component.Code != ComponentCodeConstants.Lenses);
		if (currentLenses) {
			variants.push(currentLenses);
		}
		return this.imageService.GetDetailImage(this.model, this.model.ModelGroupCode, configuration.configurationModel.ModelConfigurationCode, variants, ImageSize.Low);
	}

	async fetchConfigurations(): Promise<Array<ConfigurationViewModel>> {
		const models = new Array<ConfigurationPlusModel>();

		const apidata = await this.configurationService.getConfigurations(this.modelSize.Uuid);

		for (const config of apidata) {
			const completeVariants = await this.configurationService.getVariantFromFixedConfiguration(config.ModelConfigurationUuid, this.configuration.ModelConfigurationUuid, this.modelGroup, this.variants, true);
			const variants = new Array<ComponentVariantModel>();
			completeVariants.forEach(completeVariant => {
				const selectedVariant = this.model.DefaultVariantModels.find(x => x.Component.Code == completeVariant.Component.Code);

				const componentToCopy = completeVariant.ToComponentVariantModel(
					selectedVariant ? selectedVariant.Engraving : "", selectedVariant ? selectedVariant.OrderLineUuid : "");

				let newSelectedVariant = Object.assign(new ComponentVariantModel(componentToCopy.Discontinued, componentToCopy.New), componentToCopy);
				newSelectedVariant.VariantSize = completeVariant.VariantSizes[0];
				newSelectedVariant.VariantColor = completeVariant.VariantSizes[0].VariantColors[0];

				if (newSelectedVariant.VariantColor) {
					variants.push(newSelectedVariant);
				}
			});

			const configPlusModel = new ConfigurationPlusModel(config);
			configPlusModel.variants = variants;
			models.push(configPlusModel);

			models.sort((a, b) => this.sorterService.sortByConfiguration(a.configurationModel, b.configurationModel));
		}

		return models.map(x => new ConfigurationViewModel(this.modelGroup, x, false, false));
	}

}
