<div class="modal-body" [@fadeInOutTransition]>
	<button class="close" (click)="closeAndDestroy()">
		<i class="fa-light fa-xmark"></i>
	</button>
	<page-header class="page-header">{{'HOME.SEARCHRESULTS' | translate}}</page-header>
	<div *ngIf="!offlineService.isOnlineValue" class="row">
		<div class="col-xs-12">
			<p>{{'SEARCH.OFFLINE' | translate}}</p>
		</div>
	</div>
	<div class="row">
		<div class="col-xs-12 tabs">
			<span class="btn-group">
				<button class="btn btn-secondary" (click)="searchTabClicked(searchTabEnum.models)" [ngClass]="{'active': selectedTab === searchTabEnum.models}">
					<span class="search-tab-name">{{ 'BREADCRUMBS.MODELS' | translate }} </span>
					<span *ngIf="modelsOnlineSearching || modelsOfflineSearching" class="search-tab-info">
						(<span class="searching"></span>)
					</span>
					<span *ngIf="!modelsOnlineSearching && !modelsOfflineSearching" class="search-tab-info">
						({{modelsInSearch.length}})
					</span>
				</button>

				<button class="btn btn-secondary" (click)="searchTabClicked(searchTabEnum.collections)" [ngClass]="{'active': selectedTab === searchTabEnum.collections}">
					<span class="search-tab-name">{{ 'SEARCH.COLLECTIONS' | translate }} </span>
					<span *ngIf="collectionsSearching" class="search-tab-info">
						(<span class="searching"></span>)
					</span>
					<span *ngIf="!collectionsSearching" class="search-tab-info">
						({{offlineService.isOnlineValue || consultantCollectionsInSearch?.length > 0 ? consultantCollectionsInSearch?.length : "?"}})
					</span>
				</button>

				<button class="btn btn-secondary" (click)="searchTabClicked(searchTabEnum.misc)" [ngClass]="{'active': selectedTab === searchTabEnum.misc}">
					<span class="search-tab-name">{{ 'SEARCH.MISC' | translate }} </span>
					<span *ngIf="miscSearching" class="search-tab-info">
						(<span class="searching"></span>)
					</span>
					<span *ngIf="!miscSearching" class="search-tab-info">
						({{miscModelsInSearch.length}})
					</span>
				</button>

				<button class="btn btn-secondary" (click)="searchTabClicked(searchTabEnum.colours)" [ngClass]="{'active': selectedTab === searchTabEnum.colours}">
					<span class="search-tab-name">{{ 'SEARCH.COLORS' | translate }} </span>
					<span *ngIf="colorsSearching" class="search-tab-info">
						(<span class="searching"></span>)
					</span>
					<span *ngIf="!colorsSearching" class="search-tab-info">
						({{colorsInSearch.length}})
					</span>
				</button>
			</span>
			<span class="btn-group ml-3" *ngIf="brandModeService.showPreciousTabs | async">
				<button class="btn btn-secondary ml-5" (click)="preciousTabClicked(brandEnum.Titanium)" [ngClass]="{'active': brandEnum.Titanium === selectedBrand}">
					<span class="search-tab-name" [innerHTML]="'TITANIUM.COLLECTION' | translate"></span>
				</button>
				<button class="btn btn-secondary" (click)="preciousTabClicked(brandEnum.Precious)" [ngClass]="{'active': brandEnum.Precious === selectedBrand}">
					<span class="search-tab-name" [innerHTML]="'PRECIOUS.COLLECTION' | translate"></span>
				</button>
			</span>
		</div>
	</div>

	<div *ngIf="selectedTab === searchTabEnum.models"
		class="scroller-section"
		infinite-scroll
		[infiniteScrollDistance]="1"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScroll()"
		[infiniteScrollContainer]="'.modal-body'"
		[fromRoot]="true"
	>
		<div>
			<skeleton-screen [hasLoaded]="!modelsSearching" [screens]="6" screenClass="col-lg-3 col-md-4 col-sm-6 col-xl-3 col-xs-12 search-skeleton-screen model-item">
				<div *ngIf="modelsInSearchToShow.length === 0 && !modelsOfflineSearching && !modelsOnlineSearching">
					<p>{{'HOME.NORESULTS' | translate}}</p>
				</div>
				<div class="flex flex-column" *ngIf="(!modelsOfflineSearching && modelsInSearchToShow.length > 0) || (!modelsOfflineSearching && !modelsOnlineSearching)">
					<div class='regular-items'>
						<ng-container *ngFor="let model of modelsInSearchToShow">

							<ng-container *ngIf="model.Code !== ProductlineConstants.AIR">
								<model-item [@fadeInOutTransition] (click)="onSearchSelected(model)" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [ngClass]="{'precious': model.PresentAsPrecious }" [title]="model.Code" [description]="model.PresentAsPrecious ? model.ModelGroupDescriptionPrecious : model.ModelGroupDescription" [new]="model.New" [discontinued]="model.Discontinued" [codes]="model.ModelSizeCodes" [imageUrl]="model | modelToImageUrl">
								</model-item>
							</ng-container>
							<ng-container *ngIf="model.Code === ProductlineConstants.AIR">
								<model-item [@fadeInOutTransition] class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 model-item" [ModelCodeNotAvailable]="model.ModelGroupDescription">
								</model-item>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</skeleton-screen>
		</div>
	</div>

	<div *ngIf="selectedTab === searchTabEnum.collections"
		class="scroller-section"
		infinite-scroll
		[infiniteScrollContainer]="'.modal-body'"
		[infiniteScrollDistance]="1"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScroll()"
		[fromRoot]="true"
	>
		<div class="consultant-section flex flex-column">
			<skeleton-screen [hasLoaded]="!collectionsSearching" [screens]="6" screenClass="col-lg-3 col-md-4 col-sm-6 col-xl-3 col-xs-12 search-skeleton-screen">
				<ng-container *ngIf="!errorDuringSearch && !offlineService.isOnlineValue && consultantCollectionsInSearchToShow.length === 0">
					<p>{{'SEARCH.COLLECTIONS.OFFLINE' | translate}}</p>
				</ng-container>
				<div *ngIf="!errorDuringSearch && offlineService.isOnlineValue && !collectionsSearching && consultantCollectionsInSearchToShow.length === 0">
					<p>{{'HOME.NORESULTS' | translate}}</p>
				</div>
				<div *ngIf="errorDuringSearch">
					<p class="error text-bold">{{'COMMON.ERRORHAPPENED' | translate}}</p>
					<p class="error" [innerHTML]="'COMMON.IFPERSISTS' | translate"></p>
				</div>
				<div class="flex flex-column" *ngIf="!collectionsSearching && consultantCollectionsInSearchToShow.length">
					<div class="consultantcollection-items">
						<ng-container *ngFor="let collection of consultantCollectionsInSearchToShow">
							<featured-model class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [ngClass]="{'precious': collection.IsPrecious}" [userPermissions]="userPermissions" [presentAsPrecious]="collection.IsPrecious" [hasInspirationEditPermission]="hasInspirationEditPermission && adminModeEnabled" [featuredConfiguration]="collection" [inspirationGroup]="consultantGroup" [allManualConfigGroups]="allManualConfigGroups" [isDisplay]="isDisplay" [showAsFrame]="true" [isInCollection]="collection.IsInCollection" (hasBeenDeleted)="deleteConsultantConfiguration($event)" (toCustomizer)="onNavigateToCustomizer($event)">
							</featured-model>
						</ng-container>
					</div>
				</div>
			</skeleton-screen>
		</div>
	</div>

	<div *ngIf="selectedTab === searchTabEnum.misc" 
		class="scroller-section"
		infinite-scroll
		[infiniteScrollDistance]="1"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScroll()"
		[infiniteScrollContainer]="'.modal-body'"
		[fromRoot]="true"
	>
		<div class="model-section">
			<skeleton-screen [hasLoaded]="!miscSearching" [screens]="6" screenClass="misc-group misc-item">
				<div *ngIf="!miscSearching && miscModelsInSearchToShow.length === 0">
					<p>{{'HOME.NORESULTS' | translate}}</p>
				</div>
				<div class="flex flex-column" *ngIf="!miscSearching && miscModelsInSearchToShow.length">
					<div class="misc-items">
						<div class="misc-item" *ngFor="let miscModel of miscModelsInSearchToShow">
							<misc-list-item [@fadeInOutTransition] [model]="miscModel"></misc-list-item>
						</div>
					</div>
				</div>
			</skeleton-screen>
		</div>
	</div>

	<div *ngIf="selectedTab === searchTabEnum.colours" 
		class="scroller-section"
		infinite-scroll
		[infiniteScrollDistance]="1"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScroll()"
		[infiniteScrollContainer]="'.modal-body'"
		[fromRoot]="true"
	>
		<div class="model-section">
			<skeleton-screen [hasLoaded]="!colorsSearching" [screens]="6" screenClass="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 search-skeleton-screen skeleton-colors">
				<div *ngIf="!colorsSearching && colorsInSearchToShow.length === 0">
					<p>{{'HOME.NORESULTS' | translate}}</p>
				</div>
				<div class="flex flex-column" *ngIf="!colorsSearching && colorsInSearchToShow.length">
					<div class="color-items">
						<ng-container *ngFor="let color of colorsInSearchToShow">
							<customizer-color-view-item class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" [color]="color">
							</customizer-color-view-item>
						</ng-container>
					</div>
				</div>
			</skeleton-screen>
		</div>
	</div>

	<div>
		<copyright></copyright>
	</div>
</div>