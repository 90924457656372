import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogLevelEnum } from '@infrastructure/logLevel.enum';
import { VersionType } from '@infrastructure/versionType.enum';
import { ModelModel } from '@models/api-models/model';
import { ComponentVariantModel } from '@models/component-variant';
import { InspirationGroupModel } from '@models/inspiration-group';
import { ManualConfigCollectionModel } from '@models/manual-config-collection-model';
import { ManualConfigCreateModel } from '@models/manual-config-create';
import { ManualConfig, ManualConfigHitCountModel } from '@models/manualconfig';
import { Paging } from '@models/paging.model';
import { SetManualDefaultModel } from '@models/set-manual-default-model';
import { VariantStringModel } from '@models/variant-string-model';
import { FeaturedGroupConstant, ManualConfigGroupConstant } from '@shared/featured-group-constant';
import { Mutex } from 'async-mutex';
import { plainToClass } from 'class-transformer';
import { Observable, Subject, firstValueFrom, from } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseService } from './base.service';
import { EnvService } from './env.service';
import { IndexDbService } from './index-db.service';
import { LanguageService } from './language.service';
import { LoggerService } from './logger.service';
import { LoginService } from './login.service';
import { OfflineService } from './offline.service';
import { ServerInfoService } from './server-info.service';
import { StoreService } from './store.service';
import { ServiceUtils } from './utils/service.utils';

@Injectable({
	providedIn: 'root'
})
export class ManualConfigService extends BaseService {

	private reloadMutex = new Mutex();
	private manualConfigSearch: any = null;
	private searchCriteria: string = null;
	private page: number = 1;
	private manualConfigGroupId: number = 0;
	public errorDuringSearch: boolean = false;
	private variantStringsObservable = new Subject<Array<VariantStringModel>>();

	constructor(
		private indexedDbService: IndexDbService,
		private loginService: LoginService,
		private loggerService: LoggerService,
		private languageService: LanguageService,
		private offlineService: OfflineService,
		private serviceUtils: ServiceUtils,
		private env: EnvService,
		private http: HttpClient,
		private storeService: StoreService,
		private serverInfoService: ServerInfoService
	) {
		super();

		this.subscriptions.push(this.loginService.IsLoggedInObservable().subscribe(async (isLoggedIn) => {
			if (isLoggedIn) {
				await this.clearManualConfigCacheIfNeeded(ManualConfigGroupConstant.Favorites);
			}
		}));

		this.subscriptions.push(this.variantStringsObservable.pipe(debounceTime(500)).subscribe(async x => {
			try {
				const variantStrings = await this.getVariantStrings();
				console.error('ManualConfigService - variantStrings', variantStrings)
				this.variantStringsObservable.next(variantStrings);
			} catch (error) {
				console.error('error in ManualConfigService', error)
			}
		}));
	}

	public static readonly manualConfigStoreName = "manualConfigStore";
	private readonly manualConfigGroupsPrefix = "manualConfigGroups";
	private readonly manualConfigCollectionsPrefix = "manualConfigCollections";
	private readonly manualConfigGroupItemsPrefix = "manualConfigGroup_";
	private manualConfigGroupItemsKey = (groupId: number) => this.manualConfigGroupItemsPrefix + groupId;

	public async reloadManualConfigsToCache() {
		const release = await this.reloadMutex.acquire();

		try {
			this.indexedDbService.clearCache(VersionType.ManualConfig);
			const groups = await this.getInspirationGroups();

			for (const group of groups.filter(g => g.Type === FeaturedGroupConstant.Favorite)) {
				await this.reloadManualConfigsForGroup(group.Id)
			}
		}
		finally {
			release();
		}
	}

	public async createInspirationConfiguration(manualconfiguration: ManualConfigCreateModel): Promise<string> {
		const response = await this._createInspirationConfiguration(manualconfiguration);

		if (!manualconfiguration.ManualConfigCollectionId) {
			await this.clearManualConfigCacheIfNeeded(ManualConfigGroupConstant.Favorites);
		}

		return response;
	}

	public async deleteInspirationConfigurations(configurationsToDelete: Array<ManualConfig>): Promise<void> {
		await this._deleteInspirationConfigurations(configurationsToDelete);
		await this.clearManualConfigCacheIfNeeded(ManualConfigGroupConstant.Favorites);
	}

	public async deleteInspirationConfigurationFromUuid(uuid: string): Promise<void> {
		await this._deleteInspirationConfigurationFromUuid(uuid);
	}

	public async getFavorites(searchCriteria?: string): Promise<ManualConfig[]> {
		let manualConfigs: ManualConfig[] = [];

		return new Promise(async (resolve, reject) => {
			let storedFavorites: ManualConfig[] = await this.indexedDbService.get<ManualConfig[]>(ManualConfigService.manualConfigStoreName,
				this.manualConfigGroupItemsKey(ManualConfigGroupConstant.Favorites));

			if (storedFavorites && storedFavorites.length > 0) {
				manualConfigs = storedFavorites.filter(r => r.Model.Code.toLowerCase().startsWith(searchCriteria.toLowerCase()) ||
					r.ManualConfigVariants.some(v => v.ComponentVariantModel?.Description?.Fallback?.toLowerCase() == searchCriteria.toLowerCase()))
				return resolve(manualConfigs.map((x) => plainToClass(ManualConfig, x)));
			}

			let result: Observable<ManualConfig[]>;

			try {
				const paging = new Paging(100);

				result = await this._getManualConfigurations(ManualConfigGroupConstant.Favorites, searchCriteria, null, null, null, null, paging);

				result.subscribe(r => {
					manualConfigs = r.filter(r => r.Model.Code.toLowerCase().startsWith(searchCriteria.toLowerCase()) ||
						r.ManualConfigVariants.some(v => v.ComponentVariantModel?.Description?.Fallback?.toLowerCase() == searchCriteria.toLowerCase()))

					return resolve(manualConfigs.map((x) => plainToClass(ManualConfig, x)));
				})

				this.errorDuringSearch = false;
			}
			catch (error) {
				throw error;
			}
		});
	}

	public async deleteManualConfigCollection(collectionModel: ManualConfigCollectionModel): Promise<void> {
		await this._deleteManualConfigCollection(collectionModel.Uuid);
	}

	public async getManualConfigurationHitCount(manualConfigGroupId: number, searchCriteria?: string, modelgroupCode?: string, kidTeen?: boolean, modelIds?: Array<number>): Promise<ManualConfigHitCountModel[]> {
		return await this._getManualConfigurationHitCount(manualConfigGroupId, searchCriteria, modelgroupCode, kidTeen, modelIds);
	}

	public async getManualConfigurations(
		manualConfigGroupId: number,
		searchCriteria?: string,
		modelgroupCode?: string,
		kidTeen?: boolean,
		modelIds?: Array<number>,
		manualConfigCollectionId?: number,
		paging?: Paging
	): Promise<ManualConfig[]> {
		return new Promise<ManualConfig[]>(async (resolve, reject) => {
			let result: ManualConfig[];

			if (this.manualConfigSearch != null
				&& this.manualConfigGroupId === manualConfigGroupId
				&& (!searchCriteria || this.searchCriteria !== searchCriteria)
				&& (paging?.page !== this.page)) {
				//console.warn('unsubscribing search');

				this.manualConfigSearch = this.manualConfigSearch.unsubscribe();

				result = await this.getManualConfigurations(
					manualConfigGroupId,
					searchCriteria,
					modelgroupCode,
					kidTeen,
					modelIds,
					manualConfigCollectionId,
					paging
				);

				resolve(result?.map((x) => plainToClass(ManualConfig, x)));

				return;
			}
			else {
				this.searchCriteria = searchCriteria;
				this.page = paging?.page;
				this.manualConfigGroupId = manualConfigGroupId
				this.manualConfigSearch = (await this._getManualConfigurations(
					manualConfigGroupId,
					searchCriteria,
					modelgroupCode,
					kidTeen,
					modelIds,
					manualConfigCollectionId,
					paging
				)).subscribe(r => {
					result = r;
					resolve(result.map((x) => plainToClass(ManualConfig, x)));
					this.errorDuringSearch = false;
					return;
				}, (err) => {
					console.error('error in getManualConfigurations', err);
					let logEvent = {
						message: `error in getManualConfigurations`,
						extraPropertiesToLog: {
							['getManualConfigurations']: JSON.stringify(err)
						},
						logLevel: LogLevelEnum.Error,
						eventId: `manualConfigGroupId [${manualConfigGroupId}], searchCriteria [${searchCriteria}]`
					}
					this.loggerService.sendClientLog(logEvent);
					resolve(null);
					this.errorDuringSearch = true;
					return;
				});
			}
		});
	}

	public async getExpiredManualConfigurations(manualConfigGroupId: number, paging?: Paging): Promise<ManualConfig[]> {
		return await this._getExpiredManualConfigurations(manualConfigGroupId, paging);
	}

	public async getInspirationGroups(): Promise<Array<InspirationGroupModel>> {
		let result = await this.indexedDbService.get<Array<InspirationGroupModel>>(ManualConfigService.manualConfigStoreName, this.manualConfigGroupsPrefix);

		if (!result || result.length === 0) {
			try {
				result = await this._getInspirationGroups();
				await this.indexedDbService.replaceAndSave(ManualConfigService.manualConfigStoreName, result, this.manualConfigGroupsPrefix);
			}
			catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					return [];
				}
				else {
					throw error;
				}
			}
		}

		return result.map(ig => plainToClass(InspirationGroupModel, ig)) ?? [];
	}

	public async saveFavoriteCollection(favoriteCollection: ManualConfigCollectionModel): Promise<ManualConfigCollectionModel> {
		return await this._saveFavoriteCollection(favoriteCollection);
	}

	public async isInCollection(model: ModelModel, variants: Array<ComponentVariantModel>): Promise<boolean> {
		const variantStrings = (await this.getVariantStrings()).filter(m => m.ManualConfigCollectionId > 0);
		const currentVariantString = await this.getVariantString(model, variants);
		const indexInCollection = variantStrings.findIndex(v => v.VariantString === currentVariantString);
		//console.warn('setIsInCollection', variantStrings, currentVariantString, indexInCollection);
		return indexInCollection > -1;
	}

	public async getVariantString(model: ModelModel, variants: Array<ComponentVariantModel>): Promise<string> {
		let variantString: string = ''

		if (variants) {
			variants = variants.sort((a, b) => a.Id - b.Id);

			variants.forEach(variant => {
				if (variant.VariantColor) {
					variantString = `${variantString}&pl=${model?.ProductLineId.toString()}&mg=${model?.ModelGroupId.toString()}&mo=${model?.Id.toString()}`;
					variantString = `${variantString}&com=${variant.Component.Id.toString()}&comvar=${variant.Id.toString()}&comcol=${variant.VariantColor.Id.toString()}`;
					variantString = `${variantString}&col=${variant.VariantColor.Color.Id.toString()}&comsiz=${variant.VariantSize?.Id.toString()}#`;

				}
			});
		}

		return variantString;
	}

	private async reloadManualConfigsForGroup(manualConfigGroupId): Promise<Array<ManualConfig>> {
		try {
			const result = await (await this._getManualConfigurations(manualConfigGroupId)).toPromise();
			await this.indexedDbService.replaceAndSave(ManualConfigService.manualConfigStoreName, result, this.manualConfigGroupItemsKey(manualConfigGroupId));
			return result.map(x => plainToClass(ManualConfig, x));
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				return [];
			}
			else {
				throw error;
			}
		}
	}



	public async getNewestManualConfigUuid(manualConfigGroup: ManualConfigGroupConstant): Promise<string> {
		const endpoint = `${await this.env.baseUrl()}/manualconfiguration/newestManualConfigUuid/${manualConfigGroup}`;

		try {
			const newestManualConfigUuid = await firstValueFrom(this.http.get<string>(endpoint));
			this.indexedDbService.setNewestManualConfigUuid(manualConfigGroup, newestManualConfigUuid);
			return newestManualConfigUuid;
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				return this.indexedDbService.getLocalManualConfigNewestUuid(manualConfigGroup);
			}

			throw error;
		}
	}

	public async getManualConfigCollections(reload: boolean = false): Promise<Array<ManualConfigCollectionModel>> {
		let result = await this.indexedDbService.get<Array<ManualConfigCollectionModel>>(ManualConfigService.manualConfigStoreName,
			this.manualConfigCollectionsPrefix);

		if (!result || reload) {
			try {
				result = await this._getManualConfigCollections(this.languageService.instant('MY.FAVORITES'));

				if (result.length > 0) {
					await this.indexedDbService.replaceAndSave(ManualConfigService.manualConfigStoreName, result, this.manualConfigCollectionsPrefix);
				}
			}
			catch (error) {
				if (this.serviceUtils.errorIsOffline(error)) {
					return [];
				}
				else {
					throw error;
				}
			}
		}

		return result?.map((x) => plainToClass(ManualConfigCollectionModel, x)) ?? [];
	}

	public async clearManualConfigCacheIfNeeded(manualConfigGroup: ManualConfigGroupConstant) {
		const localManualConfigNewestUuid = this.indexedDbService.getLocalManualConfigNewestUuid(manualConfigGroup);

		const manualConfigNewestUuid = await this.getNewestManualConfigUuid(manualConfigGroup);

		if (localManualConfigNewestUuid !== manualConfigNewestUuid) {

			await this.getVariantStrings(true);

			this.indexedDbService.setNewestManualConfigUuid(manualConfigGroup, manualConfigNewestUuid);
		}
	}

	public async clearManualConfig(manualConfigGroup: ManualConfigGroupConstant) {
		console.warn('clearManualConfig');

		if (manualConfigGroup === ManualConfigGroupConstant.All) {
			this.indexedDbService.clearCache(VersionType.ManualConfig);
		}

		const manualConfigNewestUuid = await this.getNewestManualConfigUuid(manualConfigGroup);
		await this.getVariantStrings(true);
		this.indexedDbService.setNewestManualConfigUuid(manualConfigGroup, manualConfigNewestUuid);
	}
	public async getVariantStrings(reload: boolean = false): Promise<Array<VariantStringModel>> {
		let url = `${await this.env.baseUrl()}/manualconfiguration/variantstrings`;
		let variantStrings: Array<VariantStringModel> = [];

		if (reload) {
			variantStrings = await this.serverInfoService.getNetworkFirst<Array<VariantStringModel>>(url);
		}
		else {
			variantStrings = await this.storeService.getOrSetFromStore<Array<VariantStringModel>>(url);
		}

		const variantStringModels = variantStrings.map((x) => plainToClass(VariantStringModel, x));

		return variantStringModels;
	}


	private async _getManualConfigurations(
		manualConfigGroupId: number,
		searchCriteria?: string,
		modelgroupCode?: string,
		kidTeen?: boolean,
		modelIds?: Array<number>,
		manualConfigCollectionId?: number,
		paging?: Paging
	): Promise<Observable<Array<ManualConfig>>> {
		let url = `${await this.env.baseUrl()}/manualconfiguration?manualConfigGroupId=${manualConfigGroupId}`;

		if (searchCriteria) {
			url += `&searchCriteria=${searchCriteria}`;
		}

		if (modelgroupCode) {
			url += `&modelgroupCode=${modelgroupCode}`;
		}

		if (modelIds) {
			url += modelIds.map(x => `&modelIds=${x}`).join('');
		}

		if (kidTeen) {
			url += `&kidTeen=${kidTeen}`;
		}

		if (manualConfigCollectionId) {
			url += `&manualConfigCollectionId=${manualConfigCollectionId}`;
		}

		if (paging && (paging.page > 1 || new Paging().size !== paging.size)) {
			url += `&paging.page=${paging.page}&paging.size=${paging.size}`;
		}

		return from(this.offlineService.getDataWithOfflineCheckNoAwait<Array<ManualConfig>>(url));
	}

	private async _getManualConfigCollections(myFavoritesName: string): Promise<Array<ManualConfigCollectionModel>> {
		try {
			const result = await this.serverInfoService.getNetworkFirst<Array<ManualConfigCollectionModel>>(`${await this.env.baseUrl()}/manualconfiguration/favoriteCollections`);
			const myFavoritesIndex = result.findIndex(r => r.Id === 0);

			if (myFavoritesIndex > -1) {
				const myFavorites = result.splice(myFavoritesIndex, 1)[0];
				myFavorites.Name = myFavoritesName;
				myFavorites.Icon = 'heart';
				result.unshift(myFavorites);
			}

			return result?.map((x) => plainToClass(ManualConfigCollectionModel, x)) ?? [];
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				return [];
			}
			else {
				throw error;
			}
		}
	}

	public async setManualDefaults(model: SetManualDefaultModel) {
		return this.offlineService.postWithOfflineHandling(`${await this.env.baseUrl()}/Models/${model.ModelId}/ManualDefaults`, model);
	}


	public async updateInspirationGroup(groupId: number, hidden: boolean): Promise<void> {
		return this.offlineService.patchWithOfflineHandling<void>(`${await this.env.baseUrl()}/manualconfiguration/groups/${groupId}?hidden=${hidden}`, hidden);
	}



	private async _deleteManualConfigCollection(uuid: string): Promise<void> {
		return this.offlineService.deleteWithOfflineHandling<void>(`${await this.env.baseUrl()}/manualconfiguration/deleteFavoriteCollection/${uuid}`);
	}

	private async _getExpiredManualConfigurations(manualConfigGroupId: number, paging?: Paging): Promise<Array<ManualConfig>> {
		let url = `${await this.env.baseUrl()}/manualconfiguration/expired?manualConfigGroupId=${manualConfigGroupId}`;

		if (paging && paging.page > 1) {
			url += `&paging.page=${paging.page}&paging.size=${paging.size}`;
		}

		return this.offlineService.getDataWithOfflineCheck<Array<ManualConfig>>(url);
	}

	private async _getManualConfigurationHitCount(manualConfigGroupId: number, searchCriteria?: string, modelgroupCode?: string,
		kidTeen?: boolean, modelIds?: Array<number>): Promise<Array<ManualConfigHitCountModel>> {
		let url = `${await this.env.baseUrl()}/manualconfiguration/hitcount?manualConfigGroupId=${manualConfigGroupId}`;

		if (searchCriteria) {
			url += `&searchCriteria=${searchCriteria}`;
		}

		if (modelgroupCode) {
			url += `&modelgroupCode=${modelgroupCode}`;
		}

		if (modelIds) {
			url += modelIds.map(x => `&modelIds=${x}`).join('');
		}

		if (kidTeen) {
			url += `&kidTeen=${kidTeen}`;
		}

		return this.offlineService.getDataWithOfflineCheck<Array<ManualConfigHitCountModel>>(url);
	}

	private async _createInspirationConfiguration(manualconfiguration: ManualConfigCreateModel): Promise<string> {
		return this.offlineService.postWithOfflineHandling<string>(`${await this.env.baseUrl()}/manualconfiguration`, manualconfiguration);
	}

	private async _deleteInspirationConfigurations(configurationsToDelete: Array<ManualConfig>): Promise<void> {
		const configurationsToDeleteIds = configurationsToDelete.map(t => t.Id);

		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: configurationsToDeleteIds
		};

		return this.offlineService.deleteWithOfflineHandling<void>(`${await this.env.baseUrl()}/manualconfiguration/deleteConfigs`, httpOptions);
	}

	private async _deleteInspirationConfigurationFromUuid(uuid: string): Promise<void> {
		return this.offlineService.deleteWithOfflineHandling<void>(`${await this.env.baseUrl()}/manualconfiguration/deleteConfig/${uuid}`);
	}

	private async _saveFavoriteCollection(favoriteCollection: ManualConfigCollectionModel): Promise<ManualConfigCollectionModel> {
		return await this.offlineService.postWithOfflineHandling<ManualConfigCollectionModel>(`${await this.env.baseUrl()}/manualconfiguration/favoriteCollection`, favoriteCollection);
	}

	private async _getInspirationGroups(): Promise<Array<InspirationGroupModel>> {
		try {
			const result = await this.serverInfoService.getNetworkFirst<Array<InspirationGroupModel>>(`${await this.env.baseUrl()}/manualconfiguration/groups`);
			return result?.map((x) => plainToClass(InspirationGroupModel, x)) ?? [];
		}
		catch (error) {
			if (this.serviceUtils.errorIsOffline(error)) {
				return [];
			}
			else {
				throw error;
			}
		}
	}

}
