
import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '@services/image.service';

@Pipe({
    name: 'modelGrpToProductlineImg'
})
export class ModelGroupToProductlineImage implements PipeTransform {

    constructor(private imageService: ImageService) { }

    transform(name: string) {
        return this.imageService.GetProductlineImages(name)

    }
}
