<div class="main">
    <div class="row equal container-fluid">
        <div class="flex-1">
            <ng-content select=[images]></ng-content>
        </div>
        <div class="flex-2">
            <ng-content select=[content]></ng-content>
        </div>
    </div>
    <div class="row equal container-fluid">
        <div class="flex-1">
            <ng-content select=[bottom-content]></ng-content>
        </div>
    </div>
</div>
