import { CustomizerDependencyCalculatorFactory } from '@infrastructure/dependency/customizer-dependency-calculator';
import { ComponentVariantFromApiModel } from './api-models/component-variant-fromapi';
import { ComponentVariantModel } from './component-variant';
import { ConfigurationModel } from './configuration';

export default class Variants {
    constructor(private variants: Array<ComponentVariantFromApiModel>) {
    }

    public get getVariants() {
        return this.variants;
    }

    public async setVariants(variants: Array<ComponentVariantFromApiModel>, chosenVariants: Array<ComponentVariantModel>, configuration: ConfigurationModel) {
        this.variants = variants;
        await this.calculateDisabled(chosenVariants, configuration);
    }

    public async calculateDisabled(chosenVariants: Array<ComponentVariantModel>, configuration: ConfigurationModel) {
        this.variants.forEach(x => x.clearDisabled());
        const allPossibleVariantChoices = this.variants.map(x => x.ConvertToListOfAllPossibleChoices()).selectMany(x => x);
        const dependencyCalculator = CustomizerDependencyCalculatorFactory.getDisableCalculator(this.variants);

        await dependencyCalculator.GetDependencyChanges(allPossibleVariantChoices, chosenVariants, configuration);
    }
}
