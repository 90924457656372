import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@services/language.service';

@Pipe({
	name: 'dateToLocal'
})
export class DateToLocalPipe implements PipeTransform {

	public options: Intl.DateTimeFormatOptions = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' };

	constructor(
		public translateService: LanguageService,
		private cd: ChangeDetectorRef
	) {
		this.translateService.$onLangChange.subscribe(x => {
			this.cd.detectChanges();
			this.cd.markForCheck();
		});
	}

	transform(date: Date) {
		const newDate = date ? new Date(date) : null;

		if (newDate) {
			return newDate.toLocaleDateString(this.translateService.currentLanguage, this.options);
		}

		return '';
	}

}
