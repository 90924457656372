import { BindingConstants } from '@shared/dependency-constants';
import { MenuConstants } from '@shared/menu-constants';
import { ComponentVariantFromApiModel } from 'src/_models/api-models/component-variant-fromapi';
import { ComponentBindingModel } from 'src/_models/component-binding';
import { ComponentDependencyModel } from 'src/_models/component-dependency';
import { ComponentVariantModel } from 'src/_models/component-variant';
import { ComponentVariantChangeModel } from 'src/_models/component-variant-change';
import { IDependencyCalculator } from "../dependency-calculator.interface";

export class FindNewChangeBySizeDependencyCalculator implements IDependencyCalculator {
    constructor(private getChooseableVariant: (componentUuid: string, variantUuid: string, variantSizeUuid: string) => Promise<Array<ComponentVariantFromApiModel>>) {
    }

    async getChanges(
        dependency: ComponentDependencyModel,
        bindings: Array<ComponentBindingModel>,
        variant: ComponentVariantModel,
        oppositChosenVariants: Array<ComponentVariantModel>,
        variantSelectionToCheckAgainst: Array<ComponentVariantModel>): Promise<Array<ComponentVariantChangeModel>> {
        const b = bindings.filter(x => x.ComponentId === variant.Component.Id && x.VariantSize && !x.VariantColor);

        if(!b.length) {
            return [];
        }

        let newVariant: ComponentVariantModel;

        const possibleVariants = await this.getChooseableVariant(variant.Component.Uuid, variant.Uuid, null);

        const foundVariant = possibleVariants[0];

        if(dependency.Binding === BindingConstants.Block) {
            const sizes = foundVariant.VariantSizes.filter(x => variant.VariantSize.Size !== x.Size);
            const size = sizes.find(x => x.Default) ?? sizes[0];
            const color = size.VariantColors.find(x => variant.VariantColor?.Color.Code === x.Color.Code) ?? size.VariantColors.find(x => x.Default) ?? size.VariantColors[0];

            newVariant = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
            newVariant.NotSelected = false;
            newVariant.VariantSize = size.ToVariantSizeModel();
            newVariant.VariantColor = color.ToVariantColor();
        } else if (dependency.Binding === BindingConstants.PermitOnly) {
            let binding = b.find(x =>  x.ComponentVariantUuid == variant.Uuid && x.VariantSizeUuid == variant.VariantSize.Uuid);
            binding = binding ?? b.find(x =>x.ComponentVariantUuid == variant.Uuid);

            const res = Object.assign(new ComponentVariantModel(variant.Discontinued, variant.New), variant);
            const size = foundVariant.VariantSizes.find(x => variant.VariantSize.Size === x.Size) ?? possibleVariants[0].VariantSizes.find(x => x.Default) ?? possibleVariants[0].VariantSizes[0];
            const color = size.VariantColors.find(x => variant.VariantColor?.Color.Code === x.Color.Code) ?? size.VariantColors.find(x => x.Default) ?? size.VariantColors[0];

            res.NotSelected = false;
            res.VariantColor = color.ToVariantColor();
            res.VariantSize = size.ToVariantSizeModel();
            newVariant = res;
        }

        console.debug(`Finding new change by size for variant: ${variant.Code}`);
        const result = new ComponentVariantChangeModel(variant, newVariant, null);
        result.MenuTypesWithWarning.push(MenuConstants.COLOR);

        return [result];
    }
}
